import { Outcome } from "@ethossoftworks/outcome"
import { Bloc } from "@lib/bloc/Bloc"
import { arrayToObject, safeParseFloat, safeParseInt } from "@lib/TypeUtil"
import { Asset } from "@model/assets/Asset"
import { AsyncStatus } from "@model/AsyncStatus"
import { Step } from "@model/Step"
import { TagChange, tagChangeFromTag } from "@model/Tag"
import { CreateWorkOrderForm } from "@model/workOrders/CreateWorkOrderForm"
import { WorkOrder, WorkOrderStatus } from "@model/workOrders/WorkOrder"
import {
    AddWorkRequestToWorkOrderForm,
    newAddWorkRequestToWorkOrderForm,
} from "@model/workRequests/AddWorkRequestToWorkOrderForm"
import { WorkRequest } from "@model/workRequests/WorkRequest"
import { AssetService } from "@service/assets/AssetService"
import { CompanyService } from "@service/company/CompanyService"
import { WorkOrderService } from "@service/workOrders/WorkOrderService"
import { WorkRequestService } from "@service/workRequests/WorkRequestService"
import isEqual from "lodash.isequal"

export type AssetWorkRequestsState = {
    asset: Asset | null
    workRequests: WorkRequest[]
    workOrders: WorkOrder[]
    workOrderRequests: WorkRequest[]
    serviceSchedulesStepsInUse: Step[]
    selectedWorkRequests: number[]
    effectStatus: Record<AssetWorkRequestsEffect, AsyncStatus>
    workRequestToDismiss: {
        request: WorkRequest
        reason: string
        tagChange: TagChange
        tagReason: string
    } | null
    addToWorkOrderModalVisible: boolean
    createWorkOrderModalVisible: boolean
    rollbackModalVisible: boolean
    isRollbackMode: boolean
    createWorkRequestModalVisible: boolean
}

export enum AssetWorkRequestsEffect {
    FetchData = "fetchAssetWorkRequests",
    DismissWorkRequest = "dismissWorkRequest",
    AddWorkRequestsToWorkOrder = "addWorkRequestsToWorkOrder",
    CreateWorkOrder = "createWorkOrder",
}

export class AssetWorkRequestsBloc extends Bloc<AssetWorkRequestsState> {
    constructor(
        private assetService: AssetService,
        private workRequestService: WorkRequestService,
        private workOrderService: WorkOrderService,
        private companyService: CompanyService
    ) {
        super(newAssetWorkRequestsViewState(), { persistStateOnDispose: false })
    }

    fetchData = (assetId: number) =>
        this.effect({
            id: AssetWorkRequestsEffect.FetchData,
            block: async (job) => {
                this.updateEffectStatus(AssetWorkRequestsEffect.FetchData, AsyncStatus.busy())

                // Fetch asset, work requests, and work orders
                const [asset, workRequests, workOrders, serviceSchedulesStepsInUse] = await job.pause(
                    Promise.all([
                        this.assetService.fetchAsset(assetId),
                        this.workRequestService.fetchNotDismissedWorkRequestsForAsset(assetId),
                        this.workOrderService.fetchWorkOrdersForAsset(assetId),
                        this.companyService.fetchServiceScheduleItemsInUse(assetId),
                    ])
                )

                if (
                    asset.isError() ||
                    workRequests.isError() ||
                    workOrders.isError() ||
                    serviceSchedulesStepsInUse.isError()
                ) {
                    this.updateEffectStatus(AssetWorkRequestsEffect.FetchData, AsyncStatus.error(undefined))
                    return Outcome.error(undefined)
                }

                // Fetch work requests for existing work orders
                const workOrderRequests = await job.pause(this.fetchWorkOrderRequests(workOrders.value))

                if (workOrderRequests.isError()) {
                    this.updateEffectStatus(AssetWorkRequestsEffect.FetchData, AsyncStatus.error(undefined))
                    return Outcome.error(undefined)
                }

                //Calculate WorkRequest Until values
                if (asset) {
                    workRequests.value.map((it) => {
                        if (it.dueDate)
                            it.daysUntil =
                                it.dueDate != null
                                    ? Math.ceil((it.dueDate.getTime() - Date.now()) / (1000 * 3600 * 24))
                                    : null
                        if (it.daysUntil == -0)
                            //This is to fix the negative zeros returned for Math.ceil
                            it.daysUntil = 0
                        it.hoursUntil = it.dueHourMeter != null ? it.dueHourMeter - (asset.value.hourMeter ?? 0) : null
                        it.milesUntil = it.dueOdometer != null ? it.dueOdometer - (asset.value.odometer ?? 0) : null
                    })
                }

                // Update State
                this.assetChanged(asset.value)
                this.workRequestsChanged(
                    workRequests.value.filter(
                        (x) =>
                            !x.workOrderId ||
                            workOrders.value.some(
                                (wo) =>
                                    wo.id === x.workOrderId &&
                                    wo.status !== WorkOrderStatus.Closed &&
                                    wo.status !== WorkOrderStatus.Deleted
                            )
                    )
                )
                this.workOrdersChanged(workOrders.value)
                this.workOrderRequestsChanged(workOrderRequests.value)
                this.serviceSchedulesInUseChanged(serviceSchedulesStepsInUse.value)
                this.updateEffectStatus(AssetWorkRequestsEffect.FetchData, AsyncStatus.idle())
                return Outcome.ok(undefined)
            },
        })

    private async fetchWorkOrderRequests(workOrders: WorkOrder[]): Promise<Outcome<WorkRequest[]>> {
        const workOrderRequests = await Promise.all(
            workOrders.map((it) => this.workRequestService.fetchWorkRequestsForWorkOrder(it.id))
        )

        if (workOrderRequests.some((it) => it.isError())) return Outcome.error(undefined)
        return Outcome.ok(workOrderRequests.flatMap((it) => (it.isOk() ? it.value : [])))
    }

    createWorkOrder = (workOrderForm: CreateWorkOrderForm) =>
        this.effect({
            id: AssetWorkRequestsEffect.CreateWorkOrder,
            block: async (job) => {
                if (this.state.asset === null) return Outcome.error("No asset selected")

                // Create work order
                const createOutcome = await job.pause(
                    this.workOrderService.createWorkOrder(
                        this.state.asset.id,
                        workOrderForm,
                        this.state.selectedWorkRequests
                    )
                )
                if (createOutcome.isError()) return createOutcome

                // Update state
                this.workRequestsChanged(
                    this.state.workRequests.filter((it) => !this.state.selectedWorkRequests.includes(it.id))
                )
                this.selectedWorkRequestsChanged([])

                return createOutcome
            },
        })

    private effectStatusUpdated = (effect: AssetWorkRequestsEffect, status: AsyncStatus) =>
        this.update({
            effectStatus: {
                ...this.state.effectStatus,
                [effect]: status,
            },
        })

    addWorkRequestsToWorkOrder = (workOrder: WorkOrder, form: AddWorkRequestToWorkOrderForm) =>
        this.effect({
            id: AssetWorkRequestsEffect.AddWorkRequestsToWorkOrder,
            block: async (job) => {
                this.effectStatusUpdated(AssetWorkRequestsEffect.AddWorkRequestsToWorkOrder, AsyncStatus.busy())
                if (this.state.asset === null) return Outcome.error("No asset selected")
                // Add work requests to work order
                this.effectStatusUpdated(AssetWorkRequestsEffect.AddWorkRequestsToWorkOrder, AsyncStatus.idle())
                const workRequests = this.state.workRequests.filter((it) =>
                    this.state.selectedWorkRequests.includes(it.id)
                )

                const addOutcome = await this.workOrderService.addWorkRequestsToWorkOrder(
                    workOrder.id,
                    this.state.asset.id,
                    form,
                    this.state.selectedWorkRequests
                )

                if (addOutcome.isError()) {
                    this.effectStatusUpdated(AssetWorkRequestsEffect.AddWorkRequestsToWorkOrder, AsyncStatus.idle())
                    return addOutcome
                }

                // Update state
                this.workRequestsChanged(
                    this.state.workRequests.filter((it) => !this.state.selectedWorkRequests.includes(it.id))
                )
                this.selectedWorkRequestsChanged([])

                // Update workOrder
                const originalForm = newAddWorkRequestToWorkOrderForm(workOrder)
                if (isEqual(originalForm, form)) {
                    this.effectStatusUpdated(AssetWorkRequestsEffect.AddWorkRequestsToWorkOrder, AsyncStatus.idle())
                    return addOutcome
                }

                const updateWorkOrderOutcome = await this.workOrderService.updateWorkOrder({
                    ...workOrder,
                    urgency: form.urgency,
                    dueDate: form.dueDate,
                    dueHourMeter: safeParseFloat(form.dueHourMeter),
                    dueOdometer: safeParseInt(form.dueOdometer),
                    specialInstructions: form.specialInstructions,
                })

                if (updateWorkOrderOutcome.isError()) {
                    this.effectStatusUpdated(AssetWorkRequestsEffect.AddWorkRequestsToWorkOrder, AsyncStatus.idle())
                    return updateWorkOrderOutcome
                }

                this.effectStatusUpdated(AssetWorkRequestsEffect.AddWorkRequestsToWorkOrder, AsyncStatus.idle())
                return Outcome.ok(undefined)
            },
        })

    showCreateWorkOrderModal = () => this.update({ createWorkOrderModalVisible: true })
    hideCreateWorkOrderModal = () => this.update({ createWorkOrderModalVisible: false })

    showAddToWorkOrderModal = () => this.update({ addToWorkOrderModalVisible: true })
    hideAddToWorkOrderModal = () => this.update({ addToWorkOrderModalVisible: false })

    showRollbackModal = () => this.update({ rollbackModalVisible: true })
    hideRollbackModal = () => this.update({ rollbackModalVisible: false })

    startRollbackMode = () => this.update({ isRollbackMode: true })
    stopRollbackMode = () => this.update({ isRollbackMode: false })

    showCreateWorkRequestModal = () => this.update({ createWorkRequestModalVisible: true })
    hideCreateWorkRequestModal = () => this.update({ createWorkRequestModalVisible: false })

    toggleSelectedWorkRequestChanged = (id: number) =>
        this.update({
            selectedWorkRequests: this.state.selectedWorkRequests.includes(id)
                ? this.state.selectedWorkRequests.filter((item) => item !== id)
                : [...this.state.selectedWorkRequests, id],
        })

    toggleCheckAllWorkRequests = () => {
        let selectableWorkRequests = this.state.workRequests.filter((x) => !x.workOrderId)
        this.update({
            selectedWorkRequests:
                this.state.selectedWorkRequests.length !== selectableWorkRequests.length
                    ? selectableWorkRequests.map((workRequest) => workRequest.id)
                    : [],
        })
    }

    private selectedWorkRequestsChanged = (selectedWorkRequests: number[]) => this.update({ selectedWorkRequests })

    workRequestToDismissChanged = (
        request: WorkRequest | null,
        change: { reason?: string; tagChange?: TagChange; tagReason?: string }
    ) =>
        this.update({
            workRequestToDismiss:
                request === null
                    ? null
                    : {
                          request: request,
                          reason: change.reason ?? this.state.workRequestToDismiss?.reason ?? "",
                          tagChange:
                              change.tagChange ??
                              this.state.workRequestToDismiss?.tagChange ??
                              tagChangeFromTag(this.state.asset?.tag ?? null),
                          tagReason:
                              change.tagReason ??
                              this.state.workRequestToDismiss?.tagReason ??
                              this.state.asset?.tag?.reason ??
                              "",
                      },
        })

    dismissWorkRequest = (
        workRequest: WorkRequest,
        reason: string,
        userId: number,
        tagChange: TagChange,
        tagReason: string
    ) =>
        this.effect({
            id: AssetWorkRequestsEffect.DismissWorkRequest,
            block: async (job) => {
                const asset = this.state.asset
                const originalTagChange = tagChangeFromTag(asset?.tag ?? null)
                var updatedAsset = null

                if (!(originalTagChange === tagChange || asset === null)) {
                    updatedAsset = await job.pause(this.assetService.updateTag(asset.id, tagChange, tagReason))
                    if (updatedAsset.isError()) return updatedAsset

                    this.assetChanged(updatedAsset.value)
                }

                const response = await job.pause(
                    this.workRequestService.dismissWorkRequest(
                        workRequest.id,
                        reason,
                        userId,
                        updatedAsset ? updatedAsset.value.tag : updatedAsset
                    )
                )
                if (response.isError()) return response
                this.workRequestsChanged(this.state.workRequests.filter((it) => it.id !== workRequest.id))

                return Outcome.ok(undefined)
            },
        })

    workRequestUpdated = (workRequest: WorkRequest) =>
        this.update({
            workRequests: [...this.state.workRequests.filter((it) => it.id !== workRequest.id), workRequest],
        })

    assetChanged = (asset: Asset) => this.update({ asset: asset })

    private workRequestsChanged = (workRequests: WorkRequest[]) => this.update({ workRequests: workRequests })

    private workOrdersChanged = (workOrders: WorkOrder[]) => this.update({ workOrders: workOrders })

    private serviceSchedulesInUseChanged = (steps: Step[]) => this.update({ serviceSchedulesStepsInUse: steps })

    private workOrderRequestsChanged = (workRequests: WorkRequest[]) => this.update({ workOrderRequests: workRequests })

    private updateEffectStatus = (effect: AssetWorkRequestsEffect, status: AsyncStatus) =>
        this.update({
            effectStatus: {
                ...this.state.effectStatus,
                [effect]: status,
            },
        })
}

function newAssetWorkRequestsViewState(): AssetWorkRequestsState {
    return {
        asset: null,
        workRequests: [],
        workOrders: [],
        workOrderRequests: [],
        serviceSchedulesStepsInUse: [],
        selectedWorkRequests: [],
        workRequestToDismiss: null,
        addToWorkOrderModalVisible: false,
        createWorkOrderModalVisible: false,
        effectStatus: arrayToObject(Object.values(AssetWorkRequestsEffect), (value) => [value, AsyncStatus.idle()]),
        rollbackModalVisible: false,
        isRollbackMode: false,
        createWorkRequestModalVisible: false,
    }
}
