import { Bloc } from "@lib/bloc/Bloc"

export type StatusState = {
    infoMessages: { message: string; id: number }[]
    errorMessages: string[]
    atomicId: number
}

export class StatusBloc extends Bloc<StatusState> {
    private timeout: number | undefined = undefined

    constructor() {
        super({ infoMessages: [], errorMessages: [], atomicId: 0 }, { persistStateOnDispose: true })
    }

    enqueueInfoMessage = (message: string): number =>
        this.update({
            atomicId: this.state.atomicId + 1,
            infoMessages: [...this.state.infoMessages, { id: this.state.atomicId + 1, message }],
        }).atomicId

    hideInfoMessage = (id?: number) =>
        this.update({
            infoMessages: id !== undefined ? this.state.infoMessages.filter((item) => item.id !== id) : [],
        })

    enqueueErrorMessage = (error: string) => {
        this.update({
            errorMessages: this.state.errorMessages !== null ? [...this.state.errorMessages, error] : [error],
        })

        this.resetTimeout()
    }

    closeErrorMessage = () => {
        this.update({
            errorMessages: this.state.errorMessages.length === 1 ? [] : this.state.errorMessages.slice(1),
        })

        if ((this.state.errorMessages?.length ?? 0) > 0) {
            clearTimeout(this.timeout)
            this.resetTimeout()
        } else {
            this.timeout = undefined
        }
    }

    private resetTimeout = () => {
        this.timeout = window.setTimeout(() => this.closeErrorMessage(), 5000)
    }
}
