import { Outcome } from "@ethossoftworks/outcome"
import { SortOrder } from "@lib/Comparable"
import { Filter } from "@model/filters/Filter"
import { PagedResponse, Pagination } from "@model/Pagination"
import {
    defaultServiceQuotesTableColumns,
    ServiceQuoteFilter,
    ServiceQuoteQuickFilterCount,
    ServiceQuotesTableColumn,
    ServiceQuoteTableRow,
} from "@model/serviceQuotes/ServiceQuote"
import { ServiceQuoteFilterDefinitions } from "@model/serviceQuotes/ServiceQuoteFilterDefinitions"
import { ServiceQuoteService } from "@service/serviceQuotes/ServiceQuoteService"
import { SortableTableBloc, SortableTableBlocEffect, SortableTableState } from "@state/SortableTableBloc"
import { EffectStatus } from "@lib/bloc/Bloc"
import { AsyncStatus } from "@model/AsyncStatus"
import { downloadBlob } from "@service/ApiService"

export type ServiceQuotesTableState = SortableTableState<
    ServiceQuotesTableColumn,
    ServiceQuoteTableRow,
    ServiceQuoteFilter,
    ExtraState
>

type ExtraState = {
    workOrderToPrint: number | null
    workOrderToPrintIsLoading: boolean
    counts: Record<ServiceQuoteQuickFilterCount, number>
    ignoreUserPresetFilterOnLoad: boolean
    selectedServiceQuote?: ServiceQuoteTableRow | null
    showVerifyAssetModal: boolean
    showServiceQuoteListModal: boolean
}

export enum ServiceQuoteTableEffect {
    Print = "print",
}

export class ServiceQuotesTableBloc extends SortableTableBloc<
    ServiceQuotesTableColumn,
    ServiceQuoteTableRow,
    ServiceQuoteFilter,
    ExtraState
> {
    constructor(private serviceQuoteService: ServiceQuoteService) {
        super({
            filters: Object.values(ServiceQuoteFilter),

            filterDefinitions: ServiceQuoteFilterDefinitions,

            defaultManagedColumns: defaultServiceQuotesTableColumns,

            readOnlyManagedColumns: [ServiceQuotesTableColumn.ServiceQuote],

            dataFetcher: async (
                filters?: Filter<ServiceQuoteFilter>[],
                sort?: { column: ServiceQuotesTableColumn; order: SortOrder },
                pagination?: Pagination
            ): Promise<Outcome<PagedResponse<ServiceQuoteTableRow[]>>> => {
                if (!sort) sort = { column: ServiceQuotesTableColumn.ServiceQuote, order: SortOrder.Asc }
                const results = await serviceQuoteService.fetchServiceQuotes(filters, sort, pagination)
                if (results.isOk()) this.update({ counts: results.value.counts })
                return results
            },
            initialExtraState: {
                workOrderToPrint: null,
                workOrderToPrintIsLoading: false,
                counts: {
                    [ServiceQuoteQuickFilterCount.ServiceQuotes]: 0,
                    [ServiceQuoteQuickFilterCount.DaysOpenSubSeven]: 0,
                    [ServiceQuoteQuickFilterCount.DaysOpenSevenToThirty]: 0,
                    [ServiceQuoteQuickFilterCount.DaysOpenThirtyToNinety]: 0,
                    [ServiceQuoteQuickFilterCount.DaysOpenOverNinety]: 0,
                    [ServiceQuoteQuickFilterCount.TotalCostSubOneThousand]: 0,
                    [ServiceQuoteQuickFilterCount.TotalCostOneToTenThousand]: 0,
                    [ServiceQuoteQuickFilterCount.TotalCostTenToTwentyFiveThousand]: 0,
                    [ServiceQuoteQuickFilterCount.TotalCostOverTentyFiveThousand]: 0,
                },
                ignoreUserPresetFilterOnLoad: false,
                showVerifyAssetModal: false,
                showServiceQuoteListModal: false,
            },

            persistStateOnDispose: true,
        })
    }

    selectedServiceQuoteChanged = (serviceQuote: ServiceQuoteTableRow) => {
        this.update({ selectedServiceQuote: serviceQuote })
    }

    showServiceQuoteListModalChanged = (value: boolean) => {
        this.update({ showServiceQuoteListModal: value })
    }

    exportDataToExcel = (serviceQuoteId: number) => {
        this.effect({
            id: EffectStatus.Running,
            block: async (job) => {
                this.effectStatusChanged(SortableTableBlocEffect.Fetch, AsyncStatus.busy())
                
                const dataOutcome = await job.pause(this.serviceQuoteService.fetchServiceQuoteXls(serviceQuoteId))
                if (!dataOutcome.isOk()) {
                    this.effectStatusChanged(SortableTableBlocEffect.Fetch, AsyncStatus.error(dataOutcome))
                    return dataOutcome
                }
                const blob = dataOutcome.value
                if (blob) {
                    downloadBlob(blob, "ServiceQuoteDetails.xlsx")
                }

                this.effectStatusChanged(SortableTableBlocEffect.Fetch, AsyncStatus.idle())
                return dataOutcome
            },
        })
       
    }
}
