import { classNames } from "@lib/HtmlUtil"
import React, { useCallback, useEffect, useRef } from "react"

export type CollapsibleProps = {
    className?: string
    isCollapsed: boolean
    children?: React.ReactNode
}

export function Collapsible({ className, children, isCollapsed }: CollapsibleProps) {
    const ref = useRef<HTMLDivElement>(null)
    const hasRendered = useRef(false)

    useEffect(() => {
        hasRendered.current = true

        if (!ref.current) return
        if (isCollapsed) {
            ref.current.style.height = `${ref.current.scrollHeight}px`
            ref.current.offsetWidth
            ref.current.style.height = "0px"
        } else {
            ref.current.style.height = `${ref.current.scrollHeight}px`
        }
    }, [ref.current, isCollapsed])

    const handleTransitionEnd: React.TransitionEventHandler<HTMLDivElement> = useCallback(
        (ev) => {
            if (ev.currentTarget.getAttribute("data-collapsed") === "true") return
            ev.currentTarget.style.height = "auto"
        },
        [ref.current]
    )

    return (
        <div
            ref={ref}
            className={classNames("component--collapsible", className)}
            data-collapsed={isCollapsed}
            onTransitionEnd={handleTransitionEnd}
            // Prevent transition from 0px to scrollHeight if default is !collapsed
            {...(!hasRendered.current && !isCollapsed ? { style: { height: "auto" } } : undefined)}
        >
            {children}
        </div>
    )
}
