import { TableColumnAlignment, TableColumnDef, TableColumnSize } from "@model/tables/Table"
import { AssetWorkRequestsTableColumn } from "@model/workRequests/WorkRequest"

export const AssetWorkRequestsTableColumnDefinitions: Record<
    AssetWorkRequestsTableColumn,
    TableColumnDef<AssetWorkRequestsTableColumn>
> = {
    [AssetWorkRequestsTableColumn.Tag]: {
        type: AssetWorkRequestsTableColumn.Tag,
        label: "",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Start,
        className: "table-cell--tag",
        isRequired: true,
    },
    [AssetWorkRequestsTableColumn.Name]: {
        type: AssetWorkRequestsTableColumn.Name,
        label: "Service Request",
        size: TableColumnSize.Large,
        alignment: TableColumnAlignment.Start,
        className: "table-cell--name",
        isRequired: true,
    },
    [AssetWorkRequestsTableColumn.Status]: {
        type: AssetWorkRequestsTableColumn.Status,
        label: "Status",
        size: TableColumnSize.FitContentGrow,
        alignment: TableColumnAlignment.Center,
        className: "table-cell--name",
        isRequired: true,
    },
    [AssetWorkRequestsTableColumn.Type]: {
        type: AssetWorkRequestsTableColumn.Type,
        label: "Type",
        size: TableColumnSize.FitContentGrow,
        alignment: TableColumnAlignment.Center,
        className: "table-cell--type",
        isRequired: true,
    },
    [AssetWorkRequestsTableColumn.Urgency]: {
        type: AssetWorkRequestsTableColumn.Urgency,
        label: "Urgency",
        size: TableColumnSize.FitContentGrow,
        alignment: TableColumnAlignment.Center,
        className: "table-cell--urgency",
        isRequired: true,
    },
    [AssetWorkRequestsTableColumn.HoursUntil]: {
        type: AssetWorkRequestsTableColumn.HoursUntil,
        label: "Hours Until",
        size: TableColumnSize.FitContentGrow,
        alignment: TableColumnAlignment.Center,
        className: "table-cell--labor-hours",
        isRequired: true,
    },
    [AssetWorkRequestsTableColumn.MilesUntil]: {
        type: AssetWorkRequestsTableColumn.MilesUntil,
        label: "Miles Until",
        size: TableColumnSize.FitContentGrow,
        alignment: TableColumnAlignment.Center,
        className: "table-cell--miles-until",
        isRequired: true,
    },
    [AssetWorkRequestsTableColumn.DaysUntil]: {
        type: AssetWorkRequestsTableColumn.DaysUntil,
        label: "Days Until",
        size: TableColumnSize.FitContentGrow,
        alignment: TableColumnAlignment.Center,
        className: "table-cell--days-until",
        isRequired: true,
    },
}
