export class Debouncer {
    private timeout = 0
    private timestamp = 0

    constructor(private duration = 250, private maxTimeout = -1) {}

    emit(block: () => void): void {
        window.clearTimeout(this.timeout)
        this.timeout = window.setTimeout(block, this.duration)

        if (this.maxTimeout > -1 && performance.now() - this.timestamp >= this.maxTimeout) {
            window.clearTimeout(this.timeout)
            this.timestamp = performance.now()
            block()
        }
    }

    cancel() {
        window.clearTimeout(this.timeout)
    }
}

export function debounce<T extends (...props: any[]) => void>(callback: T, timeout: number): T {
    let timeoutHandle = 0
    return ((...props: any[]) => {
        window.clearTimeout(timeoutHandle)
        timeoutHandle = window.setTimeout(() => callback(...props), timeout)
    }) as T
}

export class Throttler {
    private timeout = 0
    private lastEmit = 0

    constructor(private threshold = 250, private emitLast: boolean = false) {}

    emit(block: () => void) {
        window.clearTimeout(this.timeout)
        if (performance.now() - this.lastEmit < this.threshold) {
            if (this.emitLast) this.timeout = window.setTimeout(() => block(), this.threshold)
            return
        }
        this.lastEmit = performance.now()
        block()
    }

    cancel() {
        window.clearTimeout(this.timeout)
    }
}

export function throttle(callback: () => void, threshold: number, emitLast: boolean = false): () => void {
    let timeout = 0
    let lastEmit = 0

    return () => {
        window.clearTimeout(timeout)
        if (Date.now() - lastEmit < threshold) {
            if (emitLast) timeout = window.setTimeout(() => callback(), threshold)
            return
        }
        lastEmit = Date.now()
        callback()
    }
}
