import { ServiceQuoteStatus } from "@model/serviceQuotes/ServiceQuote"
import React from "react"

export function ServiceQuoteStatusIndicator({
    status
}: {
    status: ServiceQuoteStatus
}): JSX.Element {
    return <div className="work-request-status-indicator" data-status={status}></div>
}
