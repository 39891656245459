import { AssetSite } from "@model/assets/AssetSite"
import { ExternalRoutes } from "@lib/Routes"
import React from "react"

export function AssetLocation({ location, site, hasLinks }: { location: string | null; site: AssetSite | null; hasLinks: boolean | null }): JSX.Element {
    if (!location && !site) return <></>
    if (!site) return <>{location}</>
    if (!location && site) return hasLinks ? (
        <a target="_blank" href={ExternalRoutes.SmartHubSite(site.id.toString())}>
            {site.name}
        </a>
    ) : (<>{site.name}</>)

    return hasLinks ? (
        <a target="_blank" href={ExternalRoutes.SmartHubSite(site.id.toString())}>
            {site.name}, {location}
        </a>
    ) : (<>{site.name}, {location}</>)
}
