import { Outcome } from "@ethossoftworks/outcome"
import { BlocCoordinator } from "@lib/bloc/BlocCoordinator"
import { ExternalRoutes } from "@lib/Routes"
import { AppBloc, AppState } from "@state/app/AppBloc"
import { CompanyBloc } from "@state/company/CompanyBloc"
import { UserBloc } from "@state/user/UserBloc"
import { UserPreferencesBloc } from "@state/UserPreferencesBloc"

export class AppViewModel extends BlocCoordinator<[AppState], AppState> {
    constructor(
        private appBloc: AppBloc,
        private userPreferencesBloc: UserPreferencesBloc,
        private userBloc: UserBloc,
        private companyBloc: CompanyBloc
    ) {
        super([appBloc])
    }

    protected transform = ([appState]: [AppState]) => appState

    getUser = () => this.userBloc.state.user

    getCompanyLogo = () => this.companyBloc.state.companyLogoUrl
    async onMounted() {
        const userOutcome = await this.userBloc.authenticateUser()
        if (userOutcome.isError()) {
            //window.location.replace(
            ExternalRoutes.SmartHubLogin(
                `${window.location.pathname === "/" ? "/" : window.location.pathname.substr(1)}${
                    window.location.search
                }`
            )
            //)
            return
        }

        this.companyBloc.companyIdChanged(userOutcome.value.companyId)
        this.companyBloc.companyNameChanged(userOutcome.value.companyName)

        await Promise.all([
            this.userPreferencesBloc.fetchWorkRequestsTableColumns(),
            this.userPreferencesBloc.fetchWorkOrdersTableColumns(),
            this.companyBloc.fetchUrgencies(),
            this.companyBloc.fetchServiceCodes(),
            this.companyBloc.fetchCbaCodes(),
            this.companyBloc.fetchServiceScheduleFilterOptions(),
            this.companyBloc.fetchSettings(),
            this.companyBloc.fetchContacts(),
            this.companyBloc.fetchMakes(),
            this.companyBloc.fetchModels(),
            this.companyBloc.fetchTypes(),
            this.companyBloc.fetchCategories(),
            this.companyBloc.fetchClasses(),
            this.companyBloc.fetchSubCompanies(),
            this.companyBloc.fetchGroups(),
            this.companyBloc.fetchDistricts(),
            this.companyBloc.fetchSubDistricts(),
            this.companyBloc.fetchUnits(),
            this.companyBloc.fetchAliases(),
            this.companyBloc.fetchSitesInUse(),
            this.companyBloc.fetchAllSites(),
            this.companyBloc.fetchCompanyLogo(),
            this.companyBloc.fetchVendors(userOutcome.value.companyId),
        ] as Promise<Outcome<unknown>>[])

        // Wait for company Ids to be populated before populated before fetching filter presets
        await this.userPreferencesBloc.fetchFilterPresets()

        this.appBloc.hasHydratedChanged(true)
    }
}
