import { SortOrder } from "@lib/Comparable"

export enum TableColumnSize {
    MinContent = "min",
    FitContent = "fitContent",
    FitContentGrow = "fitContentGrow",
    Normal = "normal",
    Medium = "medium",
    Large = "large",
}

export enum TableColumnAlignment {
    Start = "start",
    Center = "center",
    End = "end",
}

export type TableColumnDef<T extends string = string> = {
    type: T
    label: string
    size: TableColumnSize
    mobileSize?: TableColumnSize
    alignment: TableColumnAlignment
    isRequired: boolean // Defines if the column is able to be hidden in a table view
    className?: string
}

export type TableRow = {
    entryId: string | number
    columns: TableColumn[]
    onClick?: () => void
}

export type TableColumn = {
    size?: TableColumnSize
    columnSpanOverride?: number
    alignment?: TableColumnAlignment
    className?: string
    content?: string | JSX.Element | null
    noHoverBackground?: boolean
    onClick?: () => void
}

export type SortableTableColumnDef<T extends string = string> = TableColumnDef<T> & {
    isSortable: boolean
}

export type SortableTableHeadColumn = TableColumn & {
    isSortable: boolean
    sortOrder: SortOrder
    onClick?: (column: SortableTableHeadColumn) => void
}
