import { Button } from "@ui/common/buttons/Button"
import { Grid, GridGap } from "@ui/common/grids/Grid"
import { Modal, ModalBody, ModalContent, ModalHeader, ModalHeaderTitle, ModalSize } from "@ui/common/Modal"
import ReactToPrint from "react-to-print";
import React, { useRef } from "react"
import { PartsListPDF } from "@lib/pdf/PartsListPDF";
import { WorkRequestsPDF } from "@lib/pdf/WorkRequestsPDF";
import { PDFAsset } from "@lib/pdf/models/PDFAsset";
import { PDFAssetPart } from "@lib/pdf/models/PDFAssetPart";
import { WorkRequestPrintOption, labelForWorkRequestPrintOption } from "@model/workRequests/WorkRequestPrintOption";
import { PDFWorkRequest } from  "@lib/pdf/models/PDFWorkRequest";

export type PrintWorkRequestProps = {
    isVisible: boolean
    isLoading: boolean
    onOptionSelected?: (option: WorkRequestPrintOption) => void
    onClose: () => void
    asset?: PDFAsset
    assetParts?: PDFAssetPart[]
    workRequests?: PDFWorkRequest[]
    companyName?: string
    selectedPrintOption?: WorkRequestPrintOption
    companyLogoUrl: string | null
}

export function PrintWorkRequestModal(props: PrintWorkRequestProps): JSX.Element {
    let componentRef = useRef<HTMLDivElement>(null)

    return (
        <Modal isVisible={props.isVisible} useContentTag={true} size={ModalSize.Small} onClose={props.onClose}>
            <ModalContent isLoading={props.isLoading}>
                <ModalHeader>
                    <ModalHeaderTitle>Print Service Requests</ModalHeaderTitle>
                </ModalHeader>
                <ModalBody>
                    <Grid columns={1} gap={GridGap.Small}>
                        {Object.values(WorkRequestPrintOption).map((it) => (
                            <ReactToPrint
                                key={it.toString()}
                                trigger={() => <Button>{labelForWorkRequestPrintOption(it)}</Button>}
                                content={() => componentRef.current}
                                onBeforeGetContent={() => props.onOptionSelected?.(it)
                                }
                            />
                        ))}
                        {(props.asset! && props.selectedPrintOption!) &&
                            <>
                                <div style={{ display: "none" }}>
                                    <div ref={componentRef}>
                                        {getPdfComponent(props)}
                                    </div>
                                </div>
                            </>
                        }
                    </Grid>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
function getPdfComponent(props: PrintWorkRequestProps): JSX.Element {
    if (props.selectedPrintOption == WorkRequestPrintOption.PendingWorkRequest) {
        return <>
            <WorkRequestsPDF companyName={props.companyName!} asset={props.asset!} workRequests={props.workRequests!} companyLogoUrl={props.companyLogoUrl} />
        </>
    }
    if (props.selectedPrintOption == WorkRequestPrintOption.Parts) {
        return <>
            <PartsListPDF companyName={props.companyName!} asset={props.asset!} parts={props.assetParts!} companyLogoUrl={props.companyLogoUrl}/>
        </>
    }
    else {
        return <></>
    }
}