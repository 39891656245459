import { useBloc } from "@lib/bloc/hooks"
import { labelForMonthIndex, monthIndexForDelta } from "@lib/DateUtil"
import { labelForWorkRequestType, WorkRequestType } from "@model/workRequests/WorkRequest"
import { DashboardDataType } from "@state/dashboard/DashboardScreenBloc"
import { WidgetSelect } from "@ui/common/form/WidgetSelect"
import { LoadingIndicator, LoadingIndicatorContainer } from "@ui/common/LoadingIndicator"
import { DI } from "@ui/DI"
import React, { useEffect } from "react"
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from "recharts"
import { Widget, WidgetContent, WidgetLabel } from "./DashboardWidget"

export function WorkRequestsCompletedWidget(): JSX.Element {
    const [state, viewModel] = useBloc(() => DI.dashboardScreenBloc())
    const currentMonth = new Date().getMonth()

    const chartData = state.workRequestCompletedWidgetData.map((it, i) => ({
        value: it,
        month: labelForMonthIndex(monthIndexForDelta(currentMonth, i - (state.redYellowTagsWidgetData.length - 1))),
    }))

    return (
        <Widget className="work-requests-completed-widget">
            <WidgetLabel label="Service Requests Completed by Month" />
            <LoadingIndicatorContainer>
                <LoadingIndicator isLoading={!state.hasLoaded[DashboardDataType.WorkRequestsCompletedWidget]} />
                <WidgetContent>
                    <div className="graph-cont" style={{ flex: "1 1 0px", maxWidth: "100%" }}>
                        <div className="y-axis-label">Requests</div>
                        <ResponsiveContainer width={"100%"} height={"100%"}>
                            <AreaChart data={chartData} margin={{ top: 5, right: 10, bottom: 5, left: 5 }}>
                                <defs>
                                    <linearGradient id="gradient" x1={0} x2={0} y1={0} y2={1}>
                                        <stop offset="0%" stopColor="#7BC131" stopOpacity=".15" />
                                        <stop offset="100%" stopColor="#7BC131" stopOpacity=".0" />
                                    </linearGradient>
                                </defs>
                                <CartesianGrid horizontal={true} vertical={false} strokeWidth="1" stroke="#F1F3F4" />
                                <Area
                                    type="linear"
                                    dataKey="value"
                                    stroke="#7BC131"
                                    strokeWidth="2px"
                                    dot={{ fill: "#7BC131", strokeWidth: "5" }}
                                    {...{ label: Label }}
                                    fill="url(#gradient)"
                                    isAnimationActive={false}
                                />
                                <YAxis
                                    dataKey="value"
                                    fontSize="12px"
                                    width={22}
                                    axisLine={false}
                                    tickLine={false}
                                    padding={{ bottom: 0 }}
                                    stroke="#000000"
                                />
                                <XAxis
                                    dataKey="month"
                                    fontSize="10px"
                                    axisLine={false}
                                    tickLine={false}
                                    interval={0}
                                    minTickGap={0}
                                    height={11}
                                    stroke="#000000"
                                />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                    <div className="work-requests-completed-widget-legend">
                        <div
                            className="work-requests-completed-widget-legend-indicator"
                            style={{ background: "#7BC131" }}
                        />
                        <div className="work-requests-completed-widget-legend-text">Service Requests Completed</div>
                    </div>
                </WidgetContent>
            </LoadingIndicatorContainer>
            <WidgetSelect
                onChange={(value) => viewModel.selectedWorkTypeFilterChanged(value)}
                options={[{ label: "All Service Requests", value: "all" }].concat(
                    Object.values(WorkRequestType).map((it) => ({ label: labelForWorkRequestType(it), value: it }))
                )}
            />
        </Widget>
    )
}

function Label(props: any): JSX.Element {
    const { x, y, stroke, value } = props

    return (
        <text x={x} y={y} dy={-10} fill={stroke} fontSize={10} textAnchor="middle">
            {value}
        </text>
    )
}
