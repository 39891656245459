import React from "react"
import { PDFMinEntryHeight, PDFSectionBorder } from "./PDF"
import { PDFHeadedSection } from "./PDFSection"

const lines = 5
const maxCharsPerLine = 115

export function NotesPDFSection({ text}: { text?: string;}): JSX.Element {
    let textInLines = Array<string>(lines).fill("")
    let words = text?.split(" ")
    if (words)
        for (let line = 0; line < lines; line++) {
            while (words.length > 0 && words[0].length + textInLines[line].length + 1 <= maxCharsPerLine)
                textInLines[line] += ` ${words.shift()}`
        }
    return (
        <PDFHeadedSection title={"Mechanic's Notes"}>
            {Array(lines)
                .fill(null)
                .map((_, i) => (
                    <div key={i} style={{ height: PDFMinEntryHeight, borderBottom: PDFSectionBorder, paddingTop: '5px' }}>
                        {text && textInLines[i]}
                    </div>
                ))}
        </PDFHeadedSection>
    )
}
