export const MAX_INT_32 = Math.pow(2, 31) - 1

export const isBoolean = (value?: unknown): value is boolean => typeof value === "boolean"

export const isNumber = (value?: unknown): value is number => typeof value === "number" && !isNaN(value)

export const isString = (value?: unknown): value is string => typeof value === "string" || value instanceof String

export const notEmpty = <T>(value: T | null | undefined): value is T => value !== null && value !== undefined

export function arrayToObject<T, K extends string | number, R>(array: T[], block: (item: T) => [K, R]): Record<K, R> {
    const accum: Record<K, R> = {} as Record<K, R>
    array.forEach((item) => {
        const [key, value] = block(item)
        accum[key] = value
    })
    return accum
}

export const arrayOfNotNull = <T>(array: (T | null | undefined)[]) => array.filter(notEmpty)

export function safeParseFloat(value?: unknown): number {
    if (isNumber(value)) {
        return value
    } else if (isString(value)) {
        const newValue = parseFloat(value.replace(/[^0-9\.\-]/g, ""))
        return isNaN(newValue) ? 0.0 : newValue
    }
    return 0.0
}

export function safeParseAndRoundFloat(value?: unknown, decimalPlaces?: number): number {
    if (isNumber(value)) {
        const floatValue = value.toFixed(decimalPlaces ?? 2)

        return safeParseFloat(floatValue)
    } else if (isString(value)) {
        const floatValue = safeParseFloat(value).toFixed(decimalPlaces ?? 2)
        return safeParseFloat(floatValue)
    }
    return 0.0
}

export function isFloat(value?: unknown): boolean {
    if (isNumber(value)) {
        return true
    }

    if (isString(value)) {
        const newValue = parseFloat(value.replace(/[^0-9\.\-]/g, ""))
        return isNaN(newValue) ? false : true
    }
    return false
}
export function safeParseInt(value?: unknown): number {
    if (isNumber(value)) {
        return Math.round(value)
    } else if (isString(value)) {
        const newValue = parseInt(value.replace(/[^0-9\.\-]/g, ""))
        return isNaN(newValue) ? 0 : newValue
    }
    return 0
}

export function safeParseDate(value?: unknown): Date {
    if (!isString(value) && !isNumber(value)) return new Date()
    const date = new Date(value)
    return !isNaN(date as any) ? date : new Date()
}

export function isValidDate(value: any): boolean {
    return value instanceof Date && !isNaN(value.getTime())
}

export function numOrDefault(value: unknown, defaultValue: number): number {
    return isNumber(value) ? value : defaultValue
}

export function numArrayOrDefault(value: unknown, defaultValue: number[] = []): number[] {
    return Array.isArray(value) ? value.filter((it) => isNumber(it)) : defaultValue
}

export function stringOrDefault(value: unknown, defaultValue: string): string {
    return isString(value) ? value : defaultValue
}

export function stringArrayOrDefault(value: unknown, defaultValue: string[] = []): string[] {
    return Array.isArray(value) ? value.filter((it) => isString(it)) : defaultValue
}

export function boolOrDefault(value: unknown, defaultValue: boolean): boolean {
    return isBoolean(value) ? value : defaultValue
}

export function nullStringOrDefault(value: unknown, defaultValue: string | null): string | null {
    return value === null || isString(value) ? value : defaultValue
}

export function nullNumOrDefault(value: unknown, defaultValue: number | null): number | null {
    return value === null || isNumber(value) ? value : defaultValue
}

export function nullBoolOrDefault(value: unknown, defaultValue: boolean | null): boolean | null {
    return value === null || isBoolean(value) ? value : defaultValue
}
