import React, { CSSProperties } from "react"
import { PDFHeaderBackground, PDFSectionBorder } from "./PDF"

export function PDFSection({ children, style }: { children: React.ReactNode; style?: CSSProperties }): JSX.Element {
    return (
        <div className="pdf-section" style={{ breakInside: "avoid", ...style }}>
            {children}
        </div>
    )
}

export function PDFHeadedSection({
    title,
    children,
    style,
}: {
    title: React.ReactNode
    children?: React.ReactNode
    style?: React.CSSProperties
}): JSX.Element {
    return (
        <div
            className="pdf-section pdf-headed-section"
            style={{ border: PDFSectionBorder, breakInside: "avoid", ...style }}
        >
            <div
                style={{
                    padding: "4px 8px",
                    borderBottom: PDFSectionBorder,
                    background: PDFHeaderBackground,
                    fontWeight: 700,
                    fontSize: "1.4rem",
                }}
            >
                {title}
            </div>
            <div style={{ padding: 16, fontSize: "1.4rem", lineHeight: "1.25em" }}>{children}</div>
        </div>
    )
}

export function PDFSectionSubTitle({ children, style }: { children?: React.ReactNode, style?: React.CSSProperties }): JSX.Element {
    return <div style={{ fontSize: "1.2rem", textAlign: "center", ...style}}>{children}</div>
}
