import { classNames } from "@lib/HtmlUtil"
import React from "react"

export function ChipCont({ children, className }: { children?: React.ReactNode; className?: string }): JSX.Element {
    return <div className={classNames("chip-cont", className)}>{children}</div>
}

export function Chip({
    category,
    values,
    onRemoveClicked,
    maxValueLength = -1,
    isEnabled = true,
}: {
    category?: string
    values: string[]
    onRemoveClicked: () => void
    maxValueLength?: number
    isEnabled: boolean
}): JSX.Element {
    return (
        <div className={classNames("chip", !isEnabled ? "disabled" : "")}>
            {category && <span className="chip-category">{category}</span>}
            {values.length > 0 && <span className="chip-value">{createTruncatedValue(values, maxValueLength)}</span>}
            <button className="chip-remove" onClick={onRemoveClicked}>
                Remove {category}
            </button>
            {values.length > 1 && (
                <div className="chip-tooltip">
                    <ul>
                        {values.map((value, i) => (
                            <li key={`${i}-${value}`}>{value}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    )
}

function createTruncatedValue(values: string[], maxValueLength: number): string {
    if (maxValueLength === -1) return values.join(", ")
    if (values.length === 0) return ""
    if (values.length === 1) return values[0]

    let joined = values.join(", ")
    if (joined.length > maxValueLength) {
        joined = joined.substring(0, maxValueLength)
        if (/(,\s?)|(\s)$/.test(joined)) {
            joined = joined.slice(0, -2)
        }
        return `${joined}... (${values.length})`
    }
    return `${joined} (${values.length})`
}
