import { classNames } from "@lib/HtmlUtil"
import React from "react"

export type QuickFiltersProps = {
    children?: React.ReactNode
    className?: string
}

export function QuickFilters({ children, className }: QuickFiltersProps): JSX.Element {
    return <div className={classNames("quick-filters", className)}>{children}</div>
}

export function QuickFiltersButtons({ children }: { children?: React.ReactNode }): JSX.Element {
    return <div className="quick-filters__buttons">{children}</div>
}

export function QuickFiltersActions({ children }: { children?: React.ReactNode }): JSX.Element {
    return <div className="quick-filters__actions">{children}</div>
}
