import React from "react"

const style = `
    :root {
        --bg-color: #ffffff;
        --font-family: helvetica;
        --font-color: #000000;
        --font-size: 1.6rem;
    }

    *,
    *:before,
    *:after {
        box-sizing: inherit;
        margin: 0;
        padding: 0;
    }
    html {
        font-size: 62.5%;
        box-sizing: border-box;
    }

    body {
        font-size: var(--font-size);
        font-family: var(--font-family);
        color: var(--font-color);
        background: var(--bg-color);
        overflow: visible;
        -webkit-print-color-adjust: exact;
    }

    @page {
        size: 8.5in 11in;
        margin: 10px 25px 50px 25px;
        padding: 40px 0px 0px 0px;
    }

    .pdf-section:nth-child(n+1):nth-last-child(n+2) {
        margin-bottom: 16px;
    }
`

export const PDFHeaderBackground = "#e6e6e6"
export const PDFSectionBorder = "1px solid #BBBBBB"
export const PDFMinEntryHeight = "24px"

export function PDF({ children }: { children?: React.ReactNode }) {
    return (
        <div className="pdf">
            <style>{style}</style>
            {children}
        </div>
    )
}

export function PDFPage({ children }: { children?: React.ReactNode }) {
    return (
        <div className="pdf-page" style={{ breakBefore: "page" }}>
            {children}
        </div>
    )
}
