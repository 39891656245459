import React from "react"
import { PDF } from "./components/PDF"
import { InspectionWorkRequestPDF } from "./InspectionWorkRequestPDF"
import { PDFAsset } from "./models/PDFAsset"
import { PDFWorkRequest, PDFWorkRequestType } from "./models/PDFWorkRequest"
import { PreventativeWorkRequestPDF } from "./PreventativeWorkRequestPDF"
import { ServiceWorkRequestPDF } from "./ServiceRequestPDF"

export type WorkRequestsPDFProps = {
    asset: PDFAsset
    workRequests: PDFWorkRequest[]
    companyName: string
    companyLogoUrl: string | null
}

export function WorkRequestsPDF(props: WorkRequestsPDFProps): JSX.Element {
    return (
        <PDF>
            {props.workRequests.map((it, i) => {
                switch (it.type) {
                    case PDFWorkRequestType.Preventative:
                        return <PreventativeWorkRequestPDF key={i} workRequest={it} {...props} />
                    case PDFWorkRequestType.Inspection:
                        return <InspectionWorkRequestPDF key={i} workRequest={it} {...props} />
                    case PDFWorkRequestType.Service:
                        return <ServiceWorkRequestPDF key={i} workRequest={it} {...props} />
                }
            })}
        </PDF>
    )
}
