import { Page } from "@ui/common/page/Page"
import React from "react"
import { ServiceRequestCreationModal } from "./ServiceRequestCreationModal"
import { WorkRequestsFilterButton, WorkRequestsFilterOverlay } from "./WorkRequestsFilterOverlay"
import { WorkRequestsQuickFilters } from "./WorkRequestsQuickFilters"
import { WorkRequestsTable } from "./WorkRequestsTable"

export function WorkRequestsScreen(): JSX.Element {
    return (
        <Page className="page--work-requests" filterButton={WorkRequestsFilterButton}>
            <WorkRequestsFilterOverlay />
            <WorkRequestsQuickFilters />
            <WorkRequestsTable />
            <ServiceRequestCreationModal />
        </Page>
    )
}
