import { useDerivedState, useIsInIframe, useIsMobile } from "@lib/Hooks"
import { classNames } from "@lib/HtmlUtil"
import { arrayOfNotNull, arrayToObject } from "@lib/TypeUtil"
import { HistoryItem } from "@model/HistoryItem"
import { TableColumnSize } from "@model/tables/Table"
import { FormTable } from "@ui/common/tables/FormTable"
import React from "react"
import { Collapsible } from "../Collapsible"

export type HistoryTableProps<T extends string> = {
    history: HistoryItem<T>[]
    statusLabelCreator: (value: T) => string
}

export function descriptionColumn(elements: string[], id: string): JSX.Element {
    if (typeof elements === "string")
        return (
            <div key={`HistoryTableDescriptionContainer_${id}`}>
                <div key={`HistoryTableDescription_${id}_0`}>{elements}</div>
            </div>
        )

    return (
        <div key={`HistoryTableDescriptionContainer_${id}`}>
            {elements.map((x, index) => (
                <div key={`HistoryTableDescription_${id}_${index}`}>{x}</div>
            ))}
        </div>
    )
}

export function HistoryTable<T extends string>({ history, statusLabelCreator }: HistoryTableProps<T>): JSX.Element {
    const isMobile = useIsMobile() || useIsInIframe()
    const [collapsed, setCollapsed] = useDerivedState<Record<number, boolean>>(
        arrayToObject(history, (item) => [item.id, true]),
        [history]
    )

    return (
        <FormTable
            className={classNames("history-table", isMobile ? "history-table--mobile table--collapsible" : "")}
            head={[
                {
                    content: "Date",
                    className: "history-table__date",
                    size: TableColumnSize.MinContent,
                },
                {
                    content: "User",
                    size: !isMobile ? TableColumnSize.FitContent : TableColumnSize.FitContentGrow,
                },
                {
                    content: "Status",
                    size: !isMobile ? TableColumnSize.FitContent : TableColumnSize.FitContentGrow,
                },
                {
                    content: !isMobile ? "Description" : "",
                    className: "history-table__description",
                    size: !isMobile ? TableColumnSize.FitContent : TableColumnSize.FitContent,
                },
            ]}
            data={
                history.map((item) => ({
                    entryId: item.id,
                    columns: arrayOfNotNull([
                        {
                            content: formatHistoryDate(item.dateString, isMobile),
                            className: "history-table__date",
                        },
                        { content: item.userName },
                        { content: statusLabelCreator(item.status) },
                        {
                            content:
                                isMobile && item.description.length > 0
                                    ? HistoryItemDescriptionMobileButton(
                                          () =>
                                              setCollapsed(
                                                  arrayToObject(history, (it) => [
                                                      it.id,
                                                      item.id === it.id ? !collapsed[it.id] : collapsed[it.id],
                                                  ])
                                              ),
                                          !collapsed[item.id]
                                      )
                                    : descriptionColumn(item.description, item.id.toString()),
                            className: "history-table__description",
                            size: TableColumnSize.Medium,
                        },
                        isMobile && item.description.length > 0
                            ? {
                                  content: HistoryItemDescriptionMobileContent(collapsed[item.id], item),
                                  className: "history-table__description-content--mobile-cont",
                                  columnSpanOverride: 4,
                              }
                            : null,
                    ]),
                })) ?? []
            }
        />
    )
}

function HistoryItemDescriptionMobileButton(onClick: () => void, isCollapsed: boolean) {
    return (
        <div
            className={classNames(
                "history-item-description-button",
                isCollapsed ? "history-item-description-button--open" : ""
            )}
            onClick={onClick}
        ></div>
    )
}

function HistoryItemDescriptionMobileContent<T extends string>(isCollapsed: boolean, item: HistoryItem<T>) {
    return (
        <Collapsible isCollapsed={isCollapsed}>
            <div className="history-table__description-content--mobile">
                <div>
                    <strong>Description:</strong>
                </div>
                <div>{descriptionColumn(item.description, item.id.toString())}</div>
            </div>
        </Collapsible>
    )
}

function formatHistoryDate(date: string, isMobile: boolean) {
    const matchGroups = /(\d{1,2}\/\d{1,2}\/\d{4,4})\s(.*)/gi.exec(date)

    return matchGroups !== null ? `${matchGroups[1]} ${isMobile ? "\n" : ""}${matchGroups[2]}` : date
}
