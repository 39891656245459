import { CompanyAlias, defaultCompanyAlias } from "@model/company/CompanyAlias"
import { FilterDef } from "@model/filters/Filter"
import { ThresholdUnit } from "@model/Threshold"
import { WorkRequestFilter } from "@model/workRequests/WorkRequest"

export const WorkRequestsFilterDefinitions: Record<WorkRequestFilter, FilterDef<WorkRequestFilter>> = {
    [WorkRequestFilter.Search]: {
        type: WorkRequestFilter.Search,
        categoryLabel: "Search",
        defaultValue: [{ value: "" }],
    },
    [WorkRequestFilter.SubCompany]: {
        type: WorkRequestFilter.SubCompany,
        categoryLabel: defaultCompanyAlias(CompanyAlias.SubCompany),
        defaultValue: [],
    },
    [WorkRequestFilter.District]: {
        type: WorkRequestFilter.District,
        categoryLabel: defaultCompanyAlias(CompanyAlias.District),
        defaultValue: [],
        dependencies: [WorkRequestFilter.SubCompany],
    },
    [WorkRequestFilter.SubDistrict]: {
        type: WorkRequestFilter.SubDistrict,
        categoryLabel: defaultCompanyAlias(CompanyAlias.SubDistrict),
        defaultValue: [],
        dependencies: [WorkRequestFilter.SubCompany, WorkRequestFilter.District],
    },
    [WorkRequestFilter.Unit]: {
        type: WorkRequestFilter.Unit,
        categoryLabel: defaultCompanyAlias(CompanyAlias.Unit),
        defaultValue: [],
        dependencies: [WorkRequestFilter.SubCompany, WorkRequestFilter.District, WorkRequestFilter.SubDistrict],
    },
    [WorkRequestFilter.Group]: {
        type: WorkRequestFilter.Group,
        categoryLabel: defaultCompanyAlias(CompanyAlias.Group),
        defaultValue: [],
        dependencies: [WorkRequestFilter.SubCompany],
    },
    [WorkRequestFilter.Site]: { type: WorkRequestFilter.Site, categoryLabel: "Site", defaultValue: [] },
    [WorkRequestFilter.AssetType]: { type: WorkRequestFilter.AssetType, categoryLabel: "Type", defaultValue: [] },
    [WorkRequestFilter.Category]: {
        type: WorkRequestFilter.Category,
        categoryLabel: "Category",
        defaultValue: [],
        dependencies: [WorkRequestFilter.AssetType],
    },
    [WorkRequestFilter.Class]: {
        type: WorkRequestFilter.Class,
        categoryLabel: "Class",
        defaultValue: [],
        dependencies: [WorkRequestFilter.Category, WorkRequestFilter.AssetType],
    },
    [WorkRequestFilter.Make]: {
        type: WorkRequestFilter.Make,
        categoryLabel: "Make",
        defaultValue: [],
    },
    [WorkRequestFilter.Model]: {
        type: WorkRequestFilter.Model,
        categoryLabel: "Model",
        defaultValue: [],
        dependencies: [WorkRequestFilter.Make],
    },
    [WorkRequestFilter.WorkType]: {
        type: WorkRequestFilter.WorkType,
        categoryLabel: "Service Type",
        defaultValue: [], // string[]
    },
    [WorkRequestFilter.Urgency]: {
        type: WorkRequestFilter.Urgency,
        categoryLabel: "Urgency",
        defaultValue: [], // string[]
    },
    [WorkRequestFilter.Schedule]: { type: WorkRequestFilter.Schedule, categoryLabel: "Schedule", defaultValue: [] },
    [WorkRequestFilter.HoursUntil]: {
        type: WorkRequestFilter.HoursUntil,
        categoryLabel: "Hours Until",
        defaultValue: [],
    },
    [WorkRequestFilter.HoursUntilUnit]: {
        type: WorkRequestFilter.HoursUntilUnit,
        categoryLabel: "Hours Until Unit",
        defaultValue: [{ value: ThresholdUnit.Unit }],
    },
    [WorkRequestFilter.MilesUntil]: {
        type: WorkRequestFilter.MilesUntil,
        categoryLabel: "Miles Until",
        defaultValue: [{ value: "" }], // string
    },
    [WorkRequestFilter.MilesUntilUnit]: {
        type: WorkRequestFilter.MilesUntilUnit,
        categoryLabel: "Miles Until Unit",
        defaultValue: [{ value: ThresholdUnit.Unit }],
    },
    [WorkRequestFilter.DaysUntil]: {
        type: WorkRequestFilter.DaysUntil,
        categoryLabel: "Days Until",
        defaultValue: [{ value: "" }], // string
    },
    [WorkRequestFilter.DaysUntilUnit]: {
        type: WorkRequestFilter.DaysUntilUnit,
        categoryLabel: "Days Until Unit",
        defaultValue: [{ value: ThresholdUnit.Unit }],
    },
    [WorkRequestFilter.ServiceCode]: {
        type: WorkRequestFilter.ServiceCode,
        categoryLabel: "Service Code",
        defaultValue: [],
    },
    [WorkRequestFilter.RedTag]: {
        type: WorkRequestFilter.RedTag,
        categoryLabel: "Red Tag",
        defaultValue: [{ value: false }],
    },
    [WorkRequestFilter.YellowTag]: {
        type: WorkRequestFilter.YellowTag,
        categoryLabel: "Yellow Tag",
        defaultValue: [{ value: false }],
    },
    [WorkRequestFilter.Overdue]: {
        type: WorkRequestFilter.Overdue,
        categoryLabel: "Overdue",
        defaultValue: [{ value: false }],
    },
    [WorkRequestFilter.Upcoming]: {
        type: WorkRequestFilter.Upcoming,
        categoryLabel: "Upcoming",
        defaultValue: [{ value: false }],
    },
    [WorkRequestFilter.Dismissed]: {
        type: WorkRequestFilter.Dismissed,
        categoryLabel: "Dismissed",
        defaultValue: [{ value: false }],
    },
    [WorkRequestFilter.RequestedBy]: {
        type: WorkRequestFilter.RequestedBy,
        categoryLabel: "Requested By",
        defaultValue: [],
    },
}
