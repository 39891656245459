import { classNames } from "@lib/HtmlUtil"
import DateFnsUtils from "@date-io/date-fns"
import { useBlocCoordinator } from "@lib/bloc/hooks"
import { GlobalKeyListener } from "@lib/GlobalKeyListener"
import { Redirect, Route, RouteSwitch } from "@lib/router/components"
import { useRoute } from "@lib/router/hooks"
import { isRouteMatch, PageNotFound } from "@lib/router/route"
import { createTheme, MuiThemeProvider } from "@material-ui/core"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { Color } from "@model/Color"
import { GlobalSettingsScreen } from "@ui/admin/GlobalSettingsScreen"
import { InlinedSvgs, PDFLogoLoad } from "@ui/common/images/InlinedSvgs"
import { DashboardScreen } from "@ui/dashboard/DashboardScreen"
import { DI } from "@ui/DI"
import { PageNotFoundScreen } from "@ui/pageNotFound/PageNotFoundScreen"
import { Routes } from "@lib/Routes"
import { WorkOrdersScreen } from "@ui/workOrders/WorkOrdersScreen"
import { AssetWorkRequestsScreen } from "@ui/workRequests/AssetWorkRequestsScreen"
import { WorkRequestsScreen } from "@ui/workRequests/WorkRequestsScreen"
import React, { useEffect, useLayoutEffect } from "react"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { StatusIndicators } from "./StatusIndicators"
import { WorkOrderScreen } from "@ui/workOrders/WorkOrderScreen"
import { ServiceRequestScreen } from "@ui/serviceRequest/ServiceRequestScreen"
import { PermissionObject, PermissionType } from "@model/user/User"
import { NoAccessScreen } from "@ui/NoAccess/NoAccessScreen"
import { UserWorkRequestsScreen } from "@ui/workRequests/UserWorkRequestsScreen"
import { PDFLogo } from "@lib/pdf/components/PDFLogo"
import { ServiceQuotesScreen } from "@ui/serviceQuotes/ServiceQuotesScreen"
import { ServiceQuoteScreen } from "@ui/serviceQuotes/ServiceQuoteScreen"

const theme = createTheme({
    typography: {
        fontSize: 22.4, // This sets the theme to be the default font size. Material UIs default font size is 14.
    },
    palette: {
        primary: {
            main: Color.Action,
        },
    },
})

export function App(): JSX.Element | null {
    const [state, viewModel] = useBlocCoordinator(() => DI.appViewModel())
    const route = useRoute()

    useEffect(() => {
        viewModel.onMounted()
        GlobalKeyListener.init()
    }, [])

    useLayoutEffect(() => {
        if (state.hasHydrated) document.getElementById("splash")?.classList.add("splash--loaded")
    }, [state.hasHydrated])

    if (!state.hasHydrated) return null
    if (isRouteMatch(route, Routes.Root)) return <Redirect to={Routes.Dashboard()} />

    return (
        <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TransitionGroup className={classNames("page-cont")}>
                    <CSSTransition
                        classNames="page-"
                        timeout={250}
                        appear={true}
                        key={route.item.meta.transitionKey || route.key}
                    >
                        <RouteSwitch route={route}>
                            <Route matches={Routes.Dashboard}>
                                { viewModel.getUser().hasAccess( PermissionObject.MaintenanceDashboard, PermissionType.View) ? <DashboardScreen /> : <NoAccessScreen />}
                            </Route>
                            <Route matches={Routes.DashboardBasicCompany}>
                                { viewModel.getUser().hasAccess( PermissionObject.MaintenanceDashboard, PermissionType.View) ? <DashboardScreen /> : <NoAccessScreen />}
                            </Route>
                            <Route matches={[Routes.WorkRequests, Routes.CreateServiceRequest, Routes.CreateServiceRecord]}>
                                { viewModel.getUser().hasAccess( PermissionObject.WorkRequest, PermissionType.View) ? <WorkRequestsScreen /> : <NoAccessScreen />}                                
                            </Route>
                            <Route matches={[Routes.AssetWorkRequests, Routes.WorkRequest]}>
                                { viewModel.getUser().hasAccess( PermissionObject.WorkRequest, PermissionType.View) ? <AssetWorkRequestsScreen /> : <NoAccessScreen />}
                            </Route>
                            <Route matches={[Routes.UserWorkRequests, Routes.UserWorkRequest]}>
                                { viewModel.getUser().hasAccess( PermissionObject.WorkRequest, PermissionType.View) ? <UserWorkRequestsScreen /> : <NoAccessScreen />}
                            </Route>
                            <Route matches={Routes.WorkOrders}>
                                { viewModel.getUser().hasAccess( PermissionObject.MaintenanceWorkOrder, PermissionType.View) ? <WorkOrdersScreen /> : <NoAccessScreen />}                                
                            </Route>
                            <Route matches={Routes.WorkOrdersBasciCompany}>
                                { viewModel.getUser().hasAccess( PermissionObject.MaintenanceWorkOrder, PermissionType.View) ? <WorkOrdersScreen /> : <NoAccessScreen />}                                
                            </Route>
                            <Route matches={Routes.WorkOrder}>
                                { viewModel.getUser().hasAccess( PermissionObject.MaintenanceWorkOrder, PermissionType.View) ? <WorkOrderScreen /> : <NoAccessScreen />}
                            </Route>
                            <Route matches={Routes.GlobalSettings}>
                                { viewModel.getUser().hasAccess( PermissionObject.MaintenanceAdminPage, PermissionType.View) ? <GlobalSettingsScreen /> : <NoAccessScreen />}
                            </Route>
                            <Route matches={Routes.ServiceRequest}>
                                { viewModel.getUser().hasAccess( PermissionObject.WorkRequest, PermissionType.View) ? <ServiceRequestScreen /> : <NoAccessScreen />}
                            </Route>
                            <Route matches={Routes.ServiceRecord}>
                                { viewModel.getUser().hasAccess( PermissionObject.WorkRequest, PermissionType.View) ? <ServiceRequestScreen /> : <NoAccessScreen />}
                            </Route><Route matches={Routes.ServiceQuotes}>
                                { viewModel.getUser().hasAccess( PermissionObject.ServiceQuote, PermissionType.View) ? <ServiceQuotesScreen/> : <NoAccessScreen />}
                            </Route>
                            <Route matches={Routes.ServiceQuotes}>
                                { viewModel.getUser().hasAccess( PermissionObject.ServiceQuote, PermissionType.View) ? <ServiceQuotesScreen/> : <NoAccessScreen />}
                            </Route>
                            <Route matches={Routes.ServiceQuote}>
                                { viewModel.getUser().hasAccess( PermissionObject.ServiceQuote, PermissionType.View) ? <ServiceQuoteScreen/> : <NoAccessScreen />}
                            </Route>
                            <Route matches={PageNotFound}>
                                <PageNotFoundScreen />
                            </Route>
                        </RouteSwitch>
                    </CSSTransition>
                </TransitionGroup>
                <StatusIndicators />
                <InlinedSvgs  />
                <PDFLogoLoad companyLogoUrl={viewModel.getCompanyLogo() ?? ""} />
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    )
}
