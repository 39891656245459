import { classNames } from "@lib/HtmlUtil"
import { TableColumn, TableRow } from "@model/tables/Table"
import { Collapsible } from "@ui/common/Collapsible"
import { Table } from "@ui/common/tables/Table"
import React from "react"

export type CollapsibleTableProps = {
    className?: string
    head: TableColumn[]
    data: CollapsibleTableRow[]
}

export type CollapsibleTableRow = TableRow & {
    isCollapsed: boolean
    collapsibleContent?: string | JSX.Element | null
}

export function CollapsibleTable({ head, data, className }: CollapsibleTableProps): JSX.Element {
    const dataWithCollapsible = data.map((it) => {
        return {
            ...it,
            columns: [
                ...it.columns,
                ...(it.collapsibleContent
                    ? [
                          {
                              columnSpanOverride: it.columns.length,
                              className: "table-cell--collapsible",
                              content: (
                                  <Collapsible isCollapsed={it.isCollapsed}>
                                      <div className="table-cell--collapsible__content">{it.collapsibleContent}</div>
                                  </Collapsible>
                              ),
                          },
                      ]
                    : []),
            ],
        }
    })

    return <Table className={classNames("table--collapsible", className)} head={head} data={dataWithCollapsible} />
}
