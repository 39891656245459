import { Outcome } from "@ethossoftworks/outcome"
import { arrayToObject, numArrayOrDefault, numOrDefault, stringOrDefault } from "@lib/TypeUtil"
import {
    hasAccess,
    isInParentCompany,
    navItemForValue,
    Permission,
    PermissionObject,
    PermissionType,
    User,
} from "@model/user/User"
import { ApiError, ApiService } from "@service/ApiService"
import { UserService } from "./UserService"

export class MainUserService implements UserService {
    constructor(private apiService: ApiService) {}

    async authenticateUser(): Promise<Outcome<User>> {
        const tokenOutcome = await this.apiService.generateAccessToken()
        if (tokenOutcome.isError()) return tokenOutcome

        try {
            return Outcome.ok(userFromObj(JSON.parse(atob(tokenOutcome.value.split(".")[1]))))
        } catch (e) {
            return Outcome.error(ApiError.Unauthenticated)
        }
    }
}

function userFromObj(obj: any): User {
    return {
        id: numOrDefault(obj.UserID, -1),
        name: stringOrDefault(obj.FullName, ""),
        companyId: numOrDefault(obj.CompanyID, -1),
        companyName: stringOrDefault(obj.CompanyName, ""),
        navItems: arrayToObject(numArrayOrDefault(obj.MenuItems).map(navItemForValue), (it) => [it, true]),
        permissionObjects: permissionsFromApi(obj),
        hasAccess: hasAccess,
        isInParentCompany: isInParentCompany,
        impersonateCompany: impersonateCompanyFromApi(obj.ImpersonateCompany),
        companyTypeID: obj.CompanyTypeID,
        parentCompanyID: obj.ParentCompanyID,
        personID: obj.PersonID,
        isMechanic: obj.IsMechanic,
    }
}

export function impersonateCompanyFromApi(value: any | null): boolean {
    if (value != null) return value

    return false
}

export function permissionsFromApi(obj: any): Permission[] {
    return Object.keys(obj)
        .filter((x: any) => PermissionObject[x] !== undefined)
        .map((x): Permission => {
            return {
                actions: permissionTypeArrayFromApi(obj[x]),
                permissionObject: permissionObjectFromApi(x),
            }
        })
}

export function permissionTypeArrayFromApi(obj: any): PermissionType[] {
    return obj.map((x: any) => permissionTypeFromApi(x))
}

export function permissionTypeFromApi(value: any): PermissionType {
    switch (value) {
        case PermissionType.View:
            return PermissionType.View
        case PermissionType.Add:
            return PermissionType.Add
        case PermissionType.Edit:
            return PermissionType.Edit
        case PermissionType.Delete:
            return PermissionType.Delete
        case PermissionType.Execute:
            return PermissionType.Execute

        default:
            return PermissionType.View
    }
}

export function permissionObjectFromApi(value: any): PermissionObject {
    switch (value) {
        case PermissionObject[PermissionObject.MaintenanceServiceCode]:
            return PermissionObject.MaintenanceServiceCode
        case PermissionObject[PermissionObject.MaintenanceServiceRecord]:
            return PermissionObject.MaintenanceServiceRecord
        case PermissionObject[PermissionObject.MaintenanceServiceSchedule]:
            return PermissionObject.MaintenanceServiceSchedule
        case PermissionObject[PermissionObject.MaintenanceServiceTask]:
            return PermissionObject.MaintenanceServiceTask
        case PermissionObject[PermissionObject.MaintenanceServiceSteps]:
            return PermissionObject.MaintenanceServiceSteps
        case PermissionObject[PermissionObject.MaintenanceWorkOrder]:
            return PermissionObject.MaintenanceWorkOrder
        case PermissionObject[PermissionObject.CloseWorkOrder]:
            return PermissionObject.CloseWorkOrder
        case PermissionObject[PermissionObject.RollbackWorkOrder]:
            return PermissionObject.RollbackWorkOrder
        case PermissionObject[PermissionObject.WorkRequest]:
            return PermissionObject.WorkRequest
        case PermissionObject[PermissionObject.MaintenanceAdminPage]:
            return PermissionObject.MaintenanceAdminPage
        case PermissionObject[PermissionObject.MaintenanceDashboard]:
            return PermissionObject.MaintenanceDashboard
        case PermissionObject[PermissionObject.ServiceNotification]:
            return PermissionObject.ServiceNotification
        case PermissionObject[PermissionObject.MaintenanceGlobalSettings]:
            return PermissionObject.MaintenanceGlobalSettings
        case PermissionObject[PermissionObject.RedYellowTag]:
            return PermissionObject.RedYellowTag
        case PermissionObject[PermissionObject.MaintenanceCBACode]:
            return PermissionObject.MaintenanceCBACode
        case PermissionObject[PermissionObject.ServiceQuote]:
            return PermissionObject.ServiceQuote
        case PermissionObject[PermissionObject.ServiceQuoteVendor]:
            return PermissionObject.ServiceQuoteVendor
        default:
            return PermissionObject.MaintenanceWorkOrder
    }
}
