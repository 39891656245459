import { WorkRequestStatus } from "@model/workRequests/WorkRequest"
import React from "react"

export function WorkRequestStatusIndicator({
    status,
    flagged,
}: {
    status: WorkRequestStatus
    flagged: boolean | undefined
}): JSX.Element {
    let dataStatus
    if (status == WorkRequestStatus.Done && flagged) dataStatus = "Done-Flagged"
    else dataStatus = status

    return <div className="work-request-status-indicator" data-status={dataStatus}></div>
}
