import { classNames } from "@lib/HtmlUtil"
import React from "react"

export type RadioProps = {
    className?: string
    isChecked: boolean
    label: string
    subLabel?: string | React.ReactNode
    isEnabled?: boolean
    onSelected?: () => void
    style?: React.CSSProperties
}

export function Radio({
    label,
    subLabel,
    isChecked,
    onSelected,
    className,
    isEnabled = true,
    style,
}: RadioProps): JSX.Element {
    return (
        <label
            className={classNames("radio", className ? className : null, !isEnabled ? "radio--disabled" : null)}
            style={style}
            title={label}
        >
            <input
                className="radio-input-field"
                type="radio"
                checked={isChecked}
                onChange={(ev) => (ev.currentTarget.checked ? onSelected?.() : () => {})}
                {...(!isEnabled ? { disabled: true } : {})}
            />
            <div className="radio-input-mask"></div>
            <div className="radio-label-cont">
                <div className="radio-label">{label}</div>
                {subLabel && <div className="radio-sub-label">{subLabel}</div>}
            </div>
        </label>
    )
}
