import { labelForWorkOrderPrintOption, WorkOrderPrintOption } from "@model/workOrders/WorkOrderPrintOption"
import { Button } from "@ui/common/buttons/Button"
import { Grid, GridGap } from "@ui/common/grids/Grid"
import { Modal, ModalBody, ModalContent, ModalHeader, ModalHeaderTitle, ModalSize } from "@ui/common/Modal"
import ReactToPrint from "react-to-print";
import React, { useRef } from "react"
import { WorkOrderSummaryPDF } from "@lib/pdf/WorkOrderSummaryPDF";
import { WorkOrderDetailsPDF } from "@lib/pdf/WorkOrderDetailsPDF";
import { PartsListPDF } from "@lib/pdf/PartsListPDF";
import { PDFAsset } from "@lib/pdf/models/PDFAsset";
import { PDFWorkOrder } from "@lib/pdf/models/PDFWorkOrder";
import { PDFAssetPart } from "@lib/pdf/models/PDFAssetPart";

export type PrintWorkOrderProps = {
    isVisible: boolean
    isLoading: boolean
    onOptionSelected?: (option: WorkOrderPrintOption) => void
    onClose: () => void
    asset?: PDFAsset
    workOrder?: PDFWorkOrder
    assetParts?: PDFAssetPart[]
    companyName?: string
    selectedPrintOption?: WorkOrderPrintOption
    companyLogoUrl: string | null
}

export function PrintWorkOrderModal(props: PrintWorkOrderProps): JSX.Element {
    let componentRef = useRef<HTMLDivElement>(null)

    return (
        <Modal isVisible={props.isVisible} useContentTag={true} size={ModalSize.Small} onClose={props.onClose}>
            <ModalContent isLoading={props.isLoading}>
                <ModalHeader>
                    <ModalHeaderTitle>Print Work Order</ModalHeaderTitle>
                </ModalHeader>
                <ModalBody>
                    <Grid columns={1} gap={GridGap.Small}>
                        {Object.values(WorkOrderPrintOption).map((it) => (
                            <ReactToPrint
                                key={it.toString()}
                                trigger={() => <Button>{labelForWorkOrderPrintOption(it)}</Button>}
                                content={() => componentRef.current}
                                onBeforeGetContent={() => props.onOptionSelected?.(it)
                                }
                            />
                        ))}
                        {(props.asset! && props.workOrder! && props.selectedPrintOption!) &&
                            <>
                                <div style={{ display: "none" }}>
                                    <div ref={componentRef}>
                                        {getPdfComponent(props)}
                                    </div>
                                </div>
                            </>
                        }
                    </Grid>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
function getPdfComponent(props: PrintWorkOrderProps): JSX.Element {
    if (props.selectedPrintOption == WorkOrderPrintOption.Summary) {
        return <WorkOrderSummaryPDF companyName={props.companyName!} asset={props.asset!} workOrder={props.workOrder!} companyLogoUrl={props.companyLogoUrl} />
    }
    else if (props.selectedPrintOption == WorkOrderPrintOption.WorkOrder) {
        return <WorkOrderDetailsPDF companyName={props.companyName!} asset={props.asset!} workOrder={props.workOrder!} companyLogoUrl={props.companyLogoUrl} />
    }
    else if (props.selectedPrintOption == WorkOrderPrintOption.Parts) {
        return <PartsListPDF companyName={props.companyName!} asset={props.asset!} parts={props.assetParts!} companyLogoUrl={props.companyLogoUrl} />
    }
    else if (props.selectedPrintOption == WorkOrderPrintOption.SummaryWithParts) {
        return <>
            <WorkOrderSummaryPDF companyName={props.companyName!} asset={props.asset!} workOrder={props.workOrder!} companyLogoUrl={props.companyLogoUrl} />
            <PartsListPDF companyName={props.companyName!} asset={props.asset!} parts={props.assetParts!} companyLogoUrl={props.companyLogoUrl} />
        </>
    }
    else if (props.selectedPrintOption == WorkOrderPrintOption.WorkOrderWithParts) {
        return <>
            <WorkOrderDetailsPDF companyName={props.companyName!} asset={props.asset!} workOrder={props.workOrder!} companyLogoUrl={props.companyLogoUrl} />
            <PartsListPDF companyName={props.companyName!} asset={props.asset!} parts={props.assetParts!} companyLogoUrl={props.companyLogoUrl} />
        </>
    }
    else {
        return <></>
    }
}