import { classNames } from "@lib/HtmlUtil"
import React, { useState } from "react"
import { Button } from "../buttons/Button"
import { Collapsible } from "../Collapsible"
import { LoadingIndicator } from "../LoadingIndicator"

export type FormFieldProps = {
    label?: React.ReactNode
    labelColor?: string
    children?: React.ReactNode
    className?: string
    direction?: FormFieldDirection
    style?: React.CSSProperties,
    hasRedStroke?: boolean
}

export type CollapsibleFormFieldProps = {
    label?: React.ReactNode
    labelColor?: string
    children?: React.ReactNode
    className?: string
    direction?: FormFieldDirection
    style?: React.CSSProperties
    onClick: () => void
    isCollapsed: boolean,
    isLoading: boolean
}

export enum FormFieldDirection {
    Column = "column",
    Row = "row",
}

export function FormField({ direction = FormFieldDirection.Row, ...props }: FormFieldProps): JSX.Element {
    return (
        <div data-direction={direction} style={props.style} className={classNames("form-field", props.className)}>
            {props.label && (
                <FormFieldLabel style={props.labelColor ? { color: props.labelColor } : {}}>
                    {props.label}
                </FormFieldLabel>
            )}
            <div className={props.hasRedStroke ? "form-field-content form-field-content-red-stroke" : "form-field-content"}>{props.children}</div>
        </div>
    )
}

export function CollapsibleFormField({ direction = FormFieldDirection.Row, ...props }: CollapsibleFormFieldProps): JSX.Element {
    const [isCollapsed, setIsCollapsed] = useState(props.isCollapsed)
    const [wasLoaded, setWasLoaded] = useState(false)
    return (
        <div data-direction={direction} style={props.style} className={classNames("form-field", props.className)}>
            {props.label && (
                <>
                    <div className="collapsible-form-field-header">
                        <FormFieldLabel style={props.labelColor ? { color: props.labelColor } : {}}>
                            {props.label}
                        </FormFieldLabel>
                        <div
                            className={classNames(
                                "collapsible-form-field-header-collapse-button",
                                !isCollapsed ? "collapsible-form-field-header-collapse-button--open" : ""
                            )}
                            onClick={() => {
                                if (isCollapsed && !wasLoaded) { props?.onClick(); setWasLoaded(true) };
                                setIsCollapsed(!isCollapsed);
                            }}
                        ></div>
                    </div>
                </>
            )}
            <Collapsible isCollapsed={isCollapsed}>
                <div className="form-field-content collapsible-form-field">
                    {props.children}
                    <LoadingIndicator className="modal-loader" isLoading={props.isLoading} />
                </div>
            </Collapsible>
        </div>
    )
}

export enum FormFieldNoteType {
    Info = "info",
    Error = "error",
}

export function FormFieldNote({
    children,
    type = FormFieldNoteType.Info,
    inset = false,
}: {
    children?: React.ReactNode
    type?: FormFieldNoteType
    inset?: boolean
}): JSX.Element {
    return (
        <div className="form-field-note" data-inset={inset} data-type={type}>
            {children}
        </div>
    )
}

export function FormFieldLabel({
    children,
    className,
    style,
}: {
    children?: React.ReactNode
    className?: string
    style?: React.CSSProperties
}): JSX.Element {
    return (
        <div className={classNames(className, "form-field-label")} style={style}>
            {children}
        </div>
    )
}
