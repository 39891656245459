import { Bloc } from "@lib/bloc/Bloc"
import { ApproveServiceQuoteForm, newApproveServiceQuoteForm } from "@model/serviceQuotes/ServiceQuote"
import { Urgency } from "@model/Urgency"
import { WorkOrderStatus } from "@model/workOrders/WorkOrder"

export type ApproveServiceQuoteState = ApproveServiceQuoteForm

export class ApproveServiceQuoteBloc extends Bloc<ApproveServiceQuoteState> {
    constructor() {
        super(newApproveServiceQuoteForm(), { persistStateOnDispose: false })
    }

    notifyContactsToChanged = (value: number[]) => this.update({ notifyContacts: value })
    vendorMessageToChanged = (value: string) => this.update({ vendorMessage: value })
    purchaseOrderNumberToChanged = (value: string) => this.update({ purchaseOrderNumber: value })
    createWorkOrderToChanged = (value: boolean) => this.update({ createWorkOrder: value })
    workOrderStatusChanged = (value: WorkOrderStatus) => this.update({ workOrderStatus: value })
    workOrderAssignedToChanged = (value: number) => this.update({ workOrderAssignedTo: value })
    workOrderUrgencyChanged = (value: Urgency) => this.update({ workOrderUrgency: value })
    workOrderDueDateChanged = (value: Date) => this.update({ workOrderDueDate: value })
    workOrderDueHourMeterChanged = (value: string) => this.update({ workOrderDueHourMeter: value })
    workOrderDueOdometerChanged = (value: string) => this.update({ workOrderDueOdometer: value })
    workOrderSpecialInstructionsChanged = (value: string) => this.update({ workOrderSpecialInstructions: value })
}
