import { Strings } from "@lib/Strings"
import { ServiceSchedule } from "@model/ServiceSchedule"
import { Step } from "@model/Step"
import { WorkRequest } from "@model/workRequests/WorkRequest"
import { newWorkOrder, WorkOrder } from "./WorkOrder"

export type AdjustServiceRequestsForm = {
    workOrder: WorkOrder
    isSecondStep: boolean
    requiresManualAdjustment: boolean
    scheduleServiceRequestForms: Record<number, AdjustServiceRequestForm>
    serviceRequests: WorkRequest[]
    scheduleServiceRequests: WorkRequest[]
}

export type AdjustServiceRequestForm = {
    step: Step | null
    schedule: ServiceSchedule | null

    dueDate: Date | null
    dueHourMeter: string
    dueOdometer: string
    workToBePerformed: string

    adjustmentAction: AdjustmentAction

    adjustedDueDate: Date | null
    adjustedDueHourMeter: string
    adjustedDueOdometer: string
    differenceDueHourMeter: string
    differenceDueOdometer: string
    applyManualAdjustment: boolean
}

export enum AdjustmentAction {
    AdvanceNextStep = 1,
    ManualAdjustment = 2,
    None = 3,
}

export function newAdjustServiceRequestsForm(): AdjustServiceRequestsForm {
    return {
        workOrder: newWorkOrder(),
        isSecondStep: false,
        requiresManualAdjustment: false,
        serviceRequests: [],
        scheduleServiceRequestForms: [],
        scheduleServiceRequests: [],
    }
}

export function newAdjustServiceRequestForm(workRequest: WorkRequest): AdjustServiceRequestForm {
    return {
        dueDate: workRequest.dueDate,
        dueHourMeter: workRequest?.dueHourMeter !== null ? Strings.formatDecimal(workRequest?.dueHourMeter ?? 0) : "",
        dueOdometer: workRequest?.dueOdometer !== null ? Strings.formatInteger(workRequest?.dueOdometer ?? 0) : "",
        workToBePerformed: workRequest.workToBePerformed,
        step: workRequest.step,
        schedule: workRequest.schedule,
        adjustedDueDate: null,
        adjustedDueHourMeter: "",
        adjustedDueOdometer: "",
        applyManualAdjustment: false,
        adjustmentAction: AdjustmentAction.None,
        differenceDueHourMeter: "",
        differenceDueOdometer: "",
    }
}
