import { useBlocCoordinator } from "@lib/bloc/hooks"
import { Strings } from "@lib/Strings"
import { safeParseInt } from "@lib/TypeUtil"
import { Asset, labelForAsset } from "@model/assets/Asset"
import { Button, ButtonTheme, ButtonType } from "@ui/common/buttons/Button"
import { DataField } from "@ui/common/DataField"
import { FormField, FormFieldDirection, FormFieldNote, FormFieldNoteType } from "@ui/common/form/FormField"
import { Grid, GridCont } from "@ui/common/grids/Grid"
import {
    Modal,
    ModalBody,
    ModalButtons,
    ModalContent,
    ModalHeader,
    ModalHeaderPreTitle,
    ModalHeaderSubTitle,
    ModalHeaderTitle,
    ModalSize,
} from "@ui/common/Modal"
import { DI } from "@ui/DI"
import React, { useEffect } from "react"
import { useRoute } from "@lib/router/hooks"
import { ServiceQuoteVerifyAssetViewModel } from "./ServiceQuoteVerifyAssetViewModel"
import { ExternalRoutes } from "@lib/Routes"
import { ServiceQuote } from "@model/serviceQuotes/ServiceQuote"
import { ImageUrls } from "../common/images/ImageUrls"
import { SearchableTextInput } from "@ui/common/form/SearchableTextInput"

export type ServiceQuoteVerifyAssetModalProps = {
    onClose?: () => void
    serviceQuoteId?: number | undefined
}

export function ServiceQuoteVerifyAssetModal(props: ServiceQuoteVerifyAssetModalProps): JSX.Element {
    const route = useRoute()
    const [state, viewModel] = useBlocCoordinator(() => DI.serviceQuoteVerifyAssetViewModel())

    let auxTitle = `Validate Service Quote`
    const title = auxTitle

    return (
        <div>
            {state.isModalVisible && (
                <>
                    <Modal
                        isVisible={state.isModalVisible}
                        useContentTag={true}
                        onClose={() => {
                            cancel(props, viewModel, state)
                        }}
                        size={ModalSize.Large}
                        isClosable={true}
                    >
                        <ModalContent>
                            <ModalHeader>
                                <ModalHeaderTitle>{title + labelForAsset(state.selectedAsset)}</ModalHeaderTitle>
                            </ModalHeader>
                            <ModalBody>
                                <GridCont>
                                    <Grid columns={1}>
                                        <div>
                                            <strong>
                                                Verify that this Service Quote is assigned to the right Asset.
                                            </strong>
                                        </div>
                                    </Grid>
                                    <Grid columns={2}>
                                        <FormField className="service-quote-verify-asset-label">
                                            <a className="external-link"
                                                href={ExternalRoutes.SmartHubAsset(state.selectedAsset?.id!)}
                                                target="_blank">
                                                {state.selectedAsset?.label}
                                            </a>
                                            <Button
                                                type={ButtonType.Icon}
                                                theme={ButtonTheme.Light}
                                                iconUrl={ImageUrls.Pencil}
                                                label="Edit"
                                                onClick={() => {
                                                    viewModel.showSearchAssetsInput(true)
                                                }}
                                            />
                                        </FormField>

                                        {state.showSearchAssetsInput && (
                                            <>
                                                <FormField label="Asset ID, VIN, or Serial*">
                                                    <SearchableTextInput
                                                        options={state.availableAssets.map((asset) => ({
                                                            label: labelForAsset(asset),
                                                            value: asset,
                                                            key: asset.id,
                                                        }))}
                                                        isLoading={state.isSearchingForAssets}
                                                        value={state.selectedAsset?.label ?? ""}
                                                        emptyMessage={"No assets found"}
                                                        debounceMillis={250}
                                                        minSearchCharacters={3}
                                                        onSearchChanged={(search) => viewModel.searchForAssets(search)}
                                                        onOptionSelected={(option) =>
                                                            viewModel.selectedAssetChanged(option.value)
                                                        }
                                                        isEnabled={true}
                                                    />
                                                </FormField>
                                            </>
                                        )}
                                    </Grid>
                                    <Grid columns={4} className="service-request-creation-modal-asset-info">
                                        <DataField label="ID" value={state.selectedAsset?.identifier ?? ""} />
                                        <DataField label="Make" value={state.selectedAsset?.make.name ?? ""} />
                                        <DataField label="Model" value={state.selectedAsset?.model.name ?? ""} />
                                        <DataField
                                            label="Year"
                                            value={
                                                state.selectedAsset ? state.selectedAsset?.modelYear?.toString() : ""
                                            }
                                        />
                                        <DataField
                                            label="Category"
                                            value={state.selectedAsset ? state.selectedAsset?.category?.name : ""}
                                        />
                                        <DataField
                                            label="Class"
                                            value={state.selectedAsset ? state.selectedAsset?.class?.name : ""}
                                        />
                                        <DataField label="VIN" value={state.selectedAsset?.serialNumber ?? ""} />
                                        {(state.selectedAsset?.lifetimeHourMeter ?? null) !== null && (
                                            <DataField
                                                label={`Lifetime Hour Meter`}
                                                value={Strings.formatDecimal(
                                                    state.selectedAsset?.lifetimeHourMeter ?? 0
                                                )}
                                            />
                                        )}
                                        {(state.selectedAsset?.lifetimeOdometer ?? null) !== null && (
                                            <DataField
                                                label={`Lifetime Odometer`}
                                                value={Strings.formatInteger(
                                                    state.selectedAsset?.lifetimeOdometer ?? 0
                                                )}
                                            />
                                        )}
                                    </Grid>
                                </GridCont>
                            </ModalBody>
                            <ModalButtons>
                                <Button
                                    type={ButtonType.Text}
                                    label={"Cancel"}
                                    onClick={() => {
                                        cancel(props, viewModel, state)
                                    }}
                                />
                                <Button
                                    type={ButtonType.Contained}
                                    label={"Verify"}
                                    isEnabled={state.isFormValid && !state.isSaving}
                                    onClick={() => {
                                        viewModel.showConfirmVerifyDialog()
                                    }}
                                />
                            </ModalButtons>
                        </ModalContent>
                    </Modal>
                    <Modal
                        isVisible={state.showConfirmVerifyDialog}
                        onClose={() => viewModel.hideConfirmVerifyDialog()}
                        useContentTag={true}
                    >
                        <ModalHeader>
                            <ModalHeaderTitle>Confirm dialog</ModalHeaderTitle>
                        </ModalHeader>
                        <ModalBody>
                            <strong>Confirm asset verify?</strong>
                        </ModalBody>
                        <ModalButtons>
                            <Button
                                type={ButtonType.Text}
                                label="Cancel"
                                onClick={() => viewModel.hideConfirmVerifyDialog()}
                            />
                            <Button
                                type={ButtonType.Contained}
                                label="Confirm"
                                onClick={() => {
                                    viewModel.verifyAssetClicked()
                                }}
                            />
                        </ModalButtons>
                    </Modal>
                </>
            )}
        </div>
    )
}
function cancel(
    props: ServiceQuoteVerifyAssetModalProps,
    viewModel: ServiceQuoteVerifyAssetViewModel,
    state: any
): void {
    props.onClose?.()
    viewModel.cancel()
}
