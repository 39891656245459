import { classNames } from "@lib/HtmlUtil"
import React from "react"

export enum ButtonType {
    Contained = "contained",
    Outlined = "outlined",
    Text = "text",
    Icon = "icon",
}

export enum ButtonTheme {
    Light = "light",
    Dark = "dark",
}

export type ButtonProps = {
    label?: string
    type?: ButtonType
    isEnabled?: boolean
    onClick?: () => void
    className?: string
    children?: React.ReactNode
    iconUrl?: string
    theme?: ButtonTheme
    tooltip?: string
}

export function Button({
    label,
    onClick,
    isEnabled = true,
    type = ButtonType.Contained,
    className,
    children,
    iconUrl,
    tooltip,
    theme = ButtonTheme.Dark,
}: ButtonProps): JSX.Element {
    return (
        <button
            className={classNames("button", isEnabled ? "button--enabled" : "button--disabled", className)}
            data-button-type={type}
            data-button-theme={theme}
            onClick={isEnabled ? onClick : undefined}
            {...(!isEnabled ? { disabled: true } : undefined)}
            {...(tooltip ? { title: tooltip } : undefined)}
            style={{ ...(iconUrl ? { backgroundImage: `url(${iconUrl})` } : {}) }}
        >
            {label}
            {children}
        </button>
    )
}

export function ButtonCont({ children, className }: { children?: React.ReactNode; className?: string }): JSX.Element {
    return <div className={classNames("button-cont", className)}>{children}</div>
}
