export enum WorkOrderPrintOption {
    Summary = "summary",
    WorkOrder = "workOrder",
    Parts = "parts",
    WorkOrderWithParts = "workOrderWithParts",
    SummaryWithParts = "summaryWithParts",
}

export function labelForWorkOrderPrintOption(option: WorkOrderPrintOption): string {
    switch (option) {
        case WorkOrderPrintOption.WorkOrder:
            return "Work Order Details"
        case WorkOrderPrintOption.WorkOrderWithParts:
            return "Work Order Details + Parts List"
        case WorkOrderPrintOption.Summary:
            return "Work Order Summary"
        case WorkOrderPrintOption.SummaryWithParts:
            return "Work Order Summary + Parts List"
        case WorkOrderPrintOption.Parts:
            return "Parts List"
    }
}
