import { Button, ButtonType } from "@ui/common/buttons/Button"
import { Grid } from "@ui/common/grids/Grid"
import { Modal, ModalBody, ModalButtons, ModalContent, ModalHeader, ModalHeaderTitle } from "@ui/common/Modal"
import React from "react"

export function RollbackWorkRequestModal({
    onCancel,
    onConfirm,
    ...props
}: {
    isVisible: boolean    
    onCancel: () => void
    onConfirm: () => void
}): JSX.Element {
    return (
        <Modal isVisible={props.isVisible} useContentTag={true} onClose={onCancel}>
            <ModalContent>
                <ModalHeader>
                    <ModalHeaderTitle>Rollback Preventive/Inspection Service Requests</ModalHeaderTitle>
                </ModalHeader>
                <ModalBody>
                    <Grid columns={1}>
                        <div>
                            <p><b>WARNING</b>: <i>Dismissing a Preventative or Inspection Service Request may result in an asset not generating scheduled Service Requests if the previous Work Order has not been rolled back.</i></p>
                            <br/>
                            <p><i>Only proceed with dismissing a Preventative/Inspection Service Request after verifying that the previous Work Order containing Service Requests related to that Service Schedule has been rolled back.</i></p>
                        </div>
                    </Grid>
                </ModalBody>
                <ModalButtons>
                    <Button label="Cancel" type={ButtonType.Text} onClick={onCancel} />
                    <Button
                        label="Rollback Service Request"
                        isEnabled={true}
                        onClick={onConfirm}
                    />
                </ModalButtons>
            </ModalContent>
        </Modal>
    )
}
