import { Strings } from "@lib/Strings";
import { safeParseFloat } from "@lib/TypeUtil";
import { ServiceQuoteItem, ServiceQuoteStatus } from "@model/serviceQuotes/ServiceQuote";
import { TableColumnSize } from "@model/tables/Table";
import { CollapsibleTable, CollapsibleTableRow } from "@ui/common/tables/CollapsibleTable";
import React from "react";

export function ServiceRequestsTable({
    serviceRequests,
}: {
    serviceRequests: Record<number, ServiceQuoteItem>;
}): JSX.Element {
    const head = [
        { size: TableColumnSize.FitContent, content: "" },
        { size: TableColumnSize.FitContent, content: "Labor Hours" },
        { size: TableColumnSize.FitContent, content: "Labor Cost" },
        { size: TableColumnSize.FitContent, content: "Parts Cost" },
        { size: TableColumnSize.FitContent, content: "Misc Cost" },
        { size: TableColumnSize.FitContent, content: "Total Cost" },
        { size: TableColumnSize.FitContent },
    ];

    const serviceQuoteTotals = {
        laborHours: 0,
        laborCost: 0,
        partsCost: 0,
        miscCost: 0,
        totalCost: 0,
    };

    const approveTotalstotals = {
        laborHours: 0,
        laborCost: 0,
        partsCost: 0,
        miscCost: 0,
        totalCost: 0,
    };


    Object.values(serviceRequests).map((it, i) => {

        serviceQuoteTotals.laborHours += safeParseFloat(it.laborHours);
        serviceQuoteTotals.laborCost += safeParseFloat(it.totalLaborCost);
        serviceQuoteTotals.partsCost += safeParseFloat(0 /*it.PartsCost*/);
        serviceQuoteTotals.miscCost += safeParseFloat(it.totalMiscCost);
        serviceQuoteTotals.totalCost += safeParseFloat(0 /*it.TotalCost*/);


        if (it.status == ServiceQuoteStatus.Approved) {
            approveTotalstotals.laborHours += safeParseFloat(it.laborHours);
            approveTotalstotals.laborCost += safeParseFloat(it.totalLaborCost);
            approveTotalstotals.partsCost += safeParseFloat(0 /*it.PartsCost*/);
            approveTotalstotals.miscCost += safeParseFloat(it.totalMiscCost);
            approveTotalstotals.totalCost += safeParseFloat(0 /*it.TotalCost*/);
        }
    });

    const data: CollapsibleTableRow[] = [
        {
            entryId: "totals-row-first",
            columns: [
                { content: "Service Quote Total" },
                { content: Strings.formatFloat(serviceQuoteTotals.laborHours) },
                { content: `$\u00A0\u00A0${Strings.formatMoney(serviceQuoteTotals.laborCost)}` },
                { content: `$\u00A0\u00A0${Strings.formatMoney(serviceQuoteTotals.partsCost)}` },
                { content: `$\u00A0\u00A0${Strings.formatMoney(serviceQuoteTotals.miscCost)}` },
                { content: `$ ${Strings.formatMoney(serviceQuoteTotals.totalCost)}`, className: "total-row-label" },
                {},
            ],
            isCollapsed: true,
        },
        {
            entryId: "totals-row-second",
            columns: [
                { content: "Approved Total" },
                { content: Strings.formatFloat(approveTotalstotals.laborHours) },
                { content: `$\u00A0\u00A0${Strings.formatMoney(approveTotalstotals.laborCost)}` },
                { content: `$\u00A0\u00A0${Strings.formatMoney(approveTotalstotals.partsCost)}` },
                { content: `$\u00A0\u00A0${Strings.formatMoney(approveTotalstotals.miscCost)}` },
                { content: `$ ${Strings.formatMoney(approveTotalstotals.totalCost)}`, className: "total-row-label" },
                {},
            ],
            isCollapsed: true,
        }
    ];

    return <CollapsibleTable className="work-order-requests-table" head={head} data={data} />;
}
