import { classNames } from "@lib/HtmlUtil"
import React from "react"

export type FlagChipProps = {
    className?: string
    flags: {
        label: string
        color?: string
    }[]
}

export function FlagChip(props: FlagChipProps): JSX.Element {
    return (
        <div className={classNames("flag-chip", props.className)} data-overlay={props.flags.length > 1}>
            <div className="flag-chip-flag-cont">
                {props.flags.map((flag, i) => (
                    <div
                        key={flag.label}
                        className="flag-chip__flag"
                        style={{
                            marginLeft: `calc(${i} * var(--flag-chip-margin))`,
                            marginRight: `calc(${Math.abs(i - props.flags.length + 1)} * var(--flag-chip-margin))`,
                            zIndex: Math.abs(i - props.flags.length),
                        }}
                    >
                        <div className="flag-chip__flag-label" style={{ backgroundColor: flag.color }}>
                            {flag.label}
                        </div>
                    </div>
                ))}
            </div>
            <div className="flag-chip-overlay">
                {props.flags.map((flag) => (
                    <div key={flag.label} className="flag-chip__flag">
                        <div className="flag-chip__flag-label" style={{ backgroundColor: flag.color }}>
                            {flag.label}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
