import { PDFAsset } from "@lib/pdf/models/PDFAsset"
import { Tag, TagType } from "@model/Tag"
import { AssetCategory, newAssetCategory } from "./AssetCategory"
import { AssetClass, newAssetClass } from "./AssetClass"
import { AssetGroup } from "./AssetGroup"
import { AssetMake, newAssetMake } from "./AssetMake"
import { AssetModel, newAssetModel } from "./AssetModel"
import { AssetSite } from "./AssetSite"

export type Asset = {
    id: number
    identifier: string
    label: string | null
    tag: Tag | null
    serialNumber: string
    make: AssetMake
    model: AssetModel
    modelYear: number | null
    class: AssetClass | null
    category: AssetCategory
    site: AssetSite | null
    location: string
    daysInactive: number
    hourMeter: number | null
    odometer: number | null
    displayHourMeter: boolean | null
    displayOdometer: boolean | null
    lifetimeHourMeter: number | null
    lifetimeOdometer: number | null
    group: AssetGroup | null
    company: number
    subCompany: number
    district: number
    subDistrict: number
    unit: number
    billingCodeId: number | null
    billingCodeName: string
    billingCodeCode: string
    MeterSyncRequired: boolean
    MeterSyncRequiredUseCase: MeterSyncRequiredUseCases | null
    companyName: string
    AssetDatasourceID: number | null
    AssetDataSource: AssetDataSource | null
}

export enum AssetDataSource {
    NoDevice = 1,
    GaugeDevice = 2,
    OEMDevice = 3,
    RFID = 4,
}

export enum MeterSyncRequiredUseCases {
    WorkOrderClosed = "closedWorkOrder",
    AutoCreated = "autoCreated",
}

export function newAsset(): Asset {
    return {
        id: 0,
        identifier: "",
        label: null,
        tag: null,
        serialNumber: "",
        make: newAssetMake(),
        model: newAssetModel(),
        modelYear: 0,
        class: newAssetClass(),
        category: newAssetCategory(),
        location: "",
        daysInactive: 0,
        hourMeter: 0,
        odometer: 0,
        displayOdometer: false,
        displayHourMeter: false,
        lifetimeHourMeter: null,
        lifetimeOdometer: null,
        group: null,
        company: 0,
        subCompany: 0,
        district: 0,
        subDistrict: 0,
        unit: 0,
        site: null,
        billingCodeId: null,
        billingCodeName: "",
        billingCodeCode: "",
        MeterSyncRequired: false,
        MeterSyncRequiredUseCase: null,
        companyName: "",
        AssetDatasourceID: null,
        AssetDataSource: null,
    }
}

export function labelForAsset(asset?: Asset | null): string {
    if (!asset) return ""
    if (asset.label) return asset.label
    return `${asset.identifier} ${asset.make} ${asset.model} ${asset.modelYear} ${asset.category.name}`
}

export function pdfAssetFromModel(asset: Asset): PDFAsset {
    return {
        label: labelForAsset(asset),
        lastKnownLocation: asset.location,
        serialNumber: asset.serialNumber,
        tag: (() => {
            if (asset.tag == null || asset.tag?.type == TagType.None) {
                return undefined
            }
            return asset.tag?.type == TagType.Red ? "Red Tag" : "Yellow Tag"
        })(),
        tagReason: asset.tag?.reason,
    }
}
