import { useBloc } from "@lib/bloc/hooks"
import { labelForMonthIndex, monthIndexForDelta } from "@lib/DateUtil"
import { DashboardDataType } from "@state/dashboard/DashboardScreenBloc"
import { LoadingIndicator, LoadingIndicatorContainer } from "@ui/common/LoadingIndicator"
import { DI } from "@ui/DI"
import React from "react"
import { Bar, CartesianGrid, ComposedChart, ResponsiveContainer, XAxis, YAxis } from "recharts"
import { Widget, WidgetContent, WidgetLabel } from "./DashboardWidget"

export function RedYellowTagsWidget(): JSX.Element {
    const [state, viewModel] = useBloc(() => DI.dashboardScreenBloc())
    const currentMonth = new Date().getMonth()

    const maxValue = state.redYellowTagsWidgetData.reduce((accum, it) => Math.max(accum, it.redTags, it.yellowTags), 0)

    const verticalTicks = []
    for (let i = 1; i <= maxValue + 1; i++) {
        verticalTicks.push(i)
    }

    const chartData = state.redYellowTagsWidgetData.map((it, i) => ({
        value: maxValue + 1,
        yTags: it.yellowTags,
        rTags: it.redTags,
        month: labelForMonthIndex(monthIndexForDelta(currentMonth, i - (state.redYellowTagsWidgetData.length - 1))),
    }))

    return (
        <Widget className="red-yellow-tags-widget">
            <WidgetLabel label="Red / Yellow Tags by Month" />
            <LoadingIndicatorContainer>
                <LoadingIndicator isLoading={!state.hasLoaded[DashboardDataType.RedYellowTagsWidget]} />
                <WidgetContent>
                    <div className="graph-cont" style={{ flex: "1 1 0px", maxWidth: "100%" }}>
                        <div className="y-axis-label">Tags</div>
                        <ResponsiveContainer width={"100%"} height={"100%"}>
                            <ComposedChart data={chartData} barGap={0} barCategoryGap={0}>
                                <CartesianGrid horizontal={true} vertical={false} strokeWidth="1" stroke="#F1F3F4" />
                                <Bar dataKey="value" background={<TransparentBar />} fill="transparent" />
                                <YAxis
                                    dataKey="value"
                                    fontSize="12px"
                                    width={22}
                                    axisLine={false}
                                    tickLine={false}
                                    padding={{ bottom: 0 }}
                                    stroke="#000000"
                                    interval={"preserveStartEnd"}
                                    ticks={verticalTicks}
                                />
                                <XAxis
                                    dataKey="month"
                                    fontSize="10px"
                                    axisLine={false}
                                    tickLine={false}
                                    interval={0}
                                    minTickGap={0}
                                    height={11}
                                    stroke="#000000"
                                />
                            </ComposedChart>
                        </ResponsiveContainer>
                        <ResponsiveContainer width={"100%"} height={"100%"} className="overlay-graph">
                            <ComposedChart data={chartData} barSize={4} barGap={6}>
                                <Bar
                                    dataKey="yTags"
                                    fill="#F4B824"
                                    shape={<RoundedBar identifier="yTags" />}
                                    label={{ position: "top", fontSize: "10" }}
                                    isAnimationActive={false}
                                />
                                <Bar
                                    dataKey="rTags"
                                    fill="#FF0B0B"
                                    shape={<RoundedBar identifier="rTags" />}
                                    label={{ position: "top", fontSize: "10" }}
                                    isAnimationActive={false}
                                />
                                <YAxis dataKey="value" fontSize="12px" width={22} stroke="transparent" />
                                <XAxis dataKey="month" fontSize="10px" stroke="transparent" height={11} />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                    <div className="red-yellow-tags-widget-legend">
                        <div className="red-yellow-tags-widget-legend-item">
                            <div
                                className="red-yellow-tags-widget-legend-item-indicator"
                                style={{ background: "#FF0B0B" }}
                            />
                            <div className="red-yellow-tags-widget-legend-item-text">Red Tag</div>
                        </div>
                        <div className="red-yellow-tags-widget-legend-item">
                            <div
                                className="red-yellow-tags-widget-legend-item-indicator"
                                style={{ background: "#F4B824" }}
                            />
                            <div className="red-yellow-tags-widget-legend-item-text">Yellow Tag</div>
                        </div>
                    </div>
                </WidgetContent>
            </LoadingIndicatorContainer>
        </Widget>
    )
}

function RoundedBar(props: any): JSX.Element {
    const { fill, x, y, width, height } = props

    return (
        <>
            <defs>
                <clipPath id={`round-corner-${props.identifier}-${props.index}`}>
                    <rect x={x} y={y} width={width} height={height + width} rx={width / 2} />
                </clipPath>
            </defs>

            <rect
                width={width}
                height={height}
                clipPath={`url(#round-corner-${props.identifier}-${props.index})`}
                x={x}
                y={y}
                fill={fill}
            />
        </>
    )
}

function TransparentBar(props: any): JSX.Element {
    const { x, y, width, height } = props
    return <rect width={width} height={height} x={x} y={y} fill={props.index % 2 === 0 ? "transparent" : "#F1F3F4"} />
}
