import { SortableTableColumnDef } from "@model/tables/Table"
import { ButtonType } from "@ui/common/buttons/Button"
import { CheckBox } from "@ui/common/form/CheckBox"
import { Modal } from "@ui/common/Modal"
import React from "react"

export type ManageColumnsModalProps<T extends string> = {
    isVisible: boolean
    isDefaultColumnsChecked: boolean
    columnDefinitions: Record<T, SortableTableColumnDef<T>>
    columnLabelOverrides?: Partial<Record<T, string>>
    selectedColumns: T[]
    availableColumns: T[]
    onClose?: () => void
    onCancel: () => void
    onApply: () => void
    onDefaultsToggled: () => void
    onColumnCheckChanged: (column: T, isChecked: boolean) => void
}

export function ManageColumnsModal<T extends string>({
    isVisible,
    onClose,
    onCancel,
    onApply,
    isDefaultColumnsChecked,
    onDefaultsToggled,
    columnDefinitions,
    availableColumns,
    selectedColumns,
    onColumnCheckChanged,
    columnLabelOverrides,
}: ManageColumnsModalProps<T>): JSX.Element {
    return (
        <Modal
            isVisible={isVisible}
            title="Columns"
            subTitle="Display the following Columns."
            onClose={onClose ?? onCancel}
            className="modal--manage-columns"
            buttons={[
                {
                    type: ButtonType.Text,
                    label: "Cancel",
                    onClick: onCancel,
                },
                {
                    type: ButtonType.Contained,
                    label: "Apply",
                    isEnabled: selectedColumns.length > 0,
                    onClick: onApply,
                },
            ]}
        >
            <div className="default-columns-cont">
                <CheckBox
                    label="Default Columns"
                    isChecked={isDefaultColumnsChecked}
                    onCheckChanged={onDefaultsToggled}
                />
            </div>
            <div className="modal--manage-columns__columns">
                {availableColumns.map((column) => (
                    <CheckBox
                        key={column}
                        label={columnLabelOverrides?.[column] ?? columnDefinitions[column].label}
                        isChecked={selectedColumns.includes(column)}
                        {...(columnDefinitions[column].isRequired ? { isEnabled: false } : null)}
                        onCheckChanged={(isChecked) => onColumnCheckChanged(column, isChecked)}
                    />
                ))}
            </div>
        </Modal>
    )
}
