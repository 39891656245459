import { useBlocCoordinator } from "@lib/bloc/hooks"
import { SortOrder } from "@lib/Comparable"
import { safeParseInt, stringArrayOrDefault } from "@lib/TypeUtil"
import { Link } from "@material-ui/core"
import { labelForContact } from "@model/contacts/Contact"
import { compareUrgency, labelForUrgency, Urgency, urgencyForValue } from "@model/Urgency"
import { User } from "@model/user/User"
import { CreateWorkOrderForm, newWorkOrderFromCreateWorkOrderForm } from "@model/workOrders/CreateWorkOrderForm"
import {
    labelForWorkOrderStatus,
    WorkOrder,
    WorkOrderStatus,
    workOrderStatusForValue,
} from "@model/workOrders/WorkOrder"
import { getMinDueDateAsDate, WorkRequest } from "@model/workRequests/WorkRequest"
import { Button, ButtonType } from "@ui/common/buttons/Button"
import { DatePicker, TextInputDatePicker } from "@ui/common/form/DatePicker"
import { FormDivider, FormDividerMargin } from "@ui/common/form/FormDivider"
import { FormField, FormFieldLabel } from "@ui/common/form/FormField"
import { MultiSelect } from "@ui/common/form/MultiSelect"
import { Select } from "@ui/common/form/Select"
import { TextArea } from "@ui/common/form/TextArea"
import { TextInput, TextInputType } from "@ui/common/form/TextInput"
import { Grid, GridCont, GridGap } from "@ui/common/grids/Grid"
import {
    Modal,
    ModalBody,
    ModalButtons,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalSize,
} from "@ui/common/Modal"
import { DI } from "@ui/DI"
import { xor } from "lodash"
import React, { useEffect } from "react"
import { mapToWorkRequestHeaderRows, WorkRequestHeaderRow, WorkRequestHeaderTable } from "./WorkRequestHeaderTable"

const CreationWorkOrderStatus = [WorkOrderStatus.Pending, WorkOrderStatus.Open, WorkOrderStatus.InProgress]

export type CreateWorkOrderModalProps = {
    onCreateClicked: (workOrder: CreateWorkOrderForm) => void
    onCancelClicked: () => void
    workRequests: WorkRequest[]
    isVisible: boolean
    user: User
}

export function CreateWorkOrderModal(props: CreateWorkOrderModalProps): JSX.Element {
    return (
        <Modal isVisible={props.isVisible} useContentTag={true} onClose={props.onCancelClicked} size={ModalSize.Large}>
            <CreateWorkOrderModalContent {...props} />
        </Modal>
    )
}

function CreateWorkOrderModalContent(props: CreateWorkOrderModalProps): JSX.Element {
    const [state, viewModel] = useBlocCoordinator(() => DI.createWorkOrderViewModel())

    useEffect(() => {
        viewModel.urgencyChanged(props.workRequests.sort((a,b) => compareUrgency(a.urgency,b.urgency, SortOrder.Desc))[0].urgency ?? Urgency.Medium)
        var odometerValues = props.workRequests.filter(y => y.dueOdometer != null).map((x) => x.dueOdometer ?? 0)
        if(odometerValues.length > 0)
            viewModel.dueOdometerChanged(Math.min(...odometerValues).toString())
        
        var HourMeterValues = props.workRequests.filter(y=> y.dueHourMeter != null).map((x) => x.dueHourMeter ?? 0)
        if( HourMeterValues.length > 0)
            viewModel.dueHourMeterChanged(Math.min(...HourMeterValues).toString())
            
        var minDueData = getMinDueDateAsDate(props.workRequests)
        if( minDueData != null) viewModel.dueDateChanged(minDueData)
        
    }, [])

    return (
        <ModalContent>
            <ModalHeader>
                <ModalHeaderTitle>Create Work Order</ModalHeaderTitle>
            </ModalHeader>
            <ModalBody>
                <Grid columns={1} gap={GridGap.Small}>
                    <WorkRequestHeaderTable
                        workRequests={mapToWorkRequestHeaderRows(props.workRequests)}
                    ></WorkRequestHeaderTable>
                    <FormDivider margin={FormDividerMargin.Small} />
                    <FormFieldLabel>Work Order:</FormFieldLabel>
                    <GridCont>
                        <Grid columns={2}>
                            <FormField label="Status">
                                <Select
                                    value={state.status}
                                    options={CreationWorkOrderStatus.map((it) => ({
                                        label: labelForWorkOrderStatus(it),
                                        value: it,
                                    }))}
                                    onChange={(value) => viewModel.statusChanged(workOrderStatusForValue(value))}
                                />
                            </FormField>
                            <FormField label={"Assigned To" + (state.status != WorkOrderStatus.Pending ? "*" : "")}>
                                <Select
                                    value={state.assignedTo}
                                    options={[
                                        {label: "Select Mechanic...", value: ""},
                                        ...state.mechanics.filter((contact) => !contact.deleted).map((it) => ({
                                            label: labelForContact(it, props.user),
                                            value: it.id.toString(),
                                        })),
                                    ]}
                                    onChange={(value) => viewModel.assignedToChanged(safeParseInt(value))}
                                />
                            </FormField>
                            <FormField label="Urgency">
                                <Select
                                    value={state.urgency}
                                    options={Object.values(Urgency).map((it) => ({
                                        label: labelForUrgency(it),
                                        value: it,
                                    }))}
                                    onChange={(value) => viewModel.urgencyChanged(urgencyForValue(value))}
                                />
                            </FormField>
                            <FormField label={"Due Date" + (state.status != WorkOrderStatus.Pending ? "*" : "")}>
                                <TextInputDatePicker
                                    value={state.dueDate}
                                    onChange={(value) => viewModel.dueDateChanged(value)}
                                />
                            </FormField>
                            <FormField label="Due Hour Meter">
                                <TextInput
                                    value={state.dueHourMeter}
                                    onChange={(value) => viewModel.dueHourMeterChanged(value)}
                                    min={0}
                                    type={TextInputType.Decimal}
                                />
                            </FormField>
                            <FormField label="Due Odometer">
                                <TextInput
                                    value={state.dueOdometer}
                                    onChange={(value) => viewModel.dueOdometerChanged(value)}
                                    min={0}
                                    type={TextInputType.Integer}
                                />
                            </FormField>
                        </Grid>
                        <Grid columns={2}>
                            <FormField label="Notify Contacts">
                                <MultiSelect
                                    options={state.contacts
                                        .filter((it) => !it.deleted || viewModel.state.notifyContacts.includes(it.id))
                                        .map((contact) => ({
                                        key: contact.id,
                                        value: `${contact.id}`,
                                        label: labelForContact(contact),
                                        isChecked: state.notifyContacts.includes(contact.id),
                                        checkAll: false,
                                    }))}
                                    onSelectionChanged={(selection) =>
                                        viewModel.notifyContactsChanged(selection.map((contactId) => safeParseInt(contactId)))
                                    }
                                />
                            </FormField>
                        </Grid>
                        <Grid columns={1}>
                            <FormField label="Special Instructions">
                                <TextArea
                                    maxLength={1000}
                                    value={state.specialInstructions}
                                    onChange={(value) => viewModel.specialInstructionsChanged(value)}
                                />
                            </FormField>
                        </Grid>
                    </GridCont>
                </Grid>
            </ModalBody>
            <ModalButtons>
                <Button label="Cancel" type={ButtonType.Text} onClick={props.onCancelClicked} />
                <Button
                    label="Create"
                    type={ButtonType.Contained}
                    onClick={() => props.onCreateClicked(state)}
                    isEnabled={state.isFormValid}
                />
            </ModalButtons>
        </ModalContent>
    )
}
