import { GlobalKeyListener } from "@lib/GlobalKeyListener"
import { classNames, isNodeAncestor, isNodeAncestorMatch } from "@lib/HtmlUtil"
import { isNumber, isString } from "@lib/TypeUtil"
import { Button, ButtonType } from "@ui/common/buttons/Button"
import { RecyclerList, RecyclerListRowProps } from "@ui/common/RecyclerList"
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import { ArrowSmallIcon } from "../images/InlinedSvgs"
import { CheckBox } from "./CheckBox"
import { TextInput, TextInputType } from "./TextInput"

export type MultiSelectProps<T> = {
    className?: string
    theme?: MultiSelectTheme
    options: MultiSelectOption<T>[]
    isLoading?: boolean
    onSelectionChanged: (option: T[]) => void
    disabled?: boolean;
    defaultLabel?: string;
}

export enum MultiSelectTheme {
    Light = "light",
    Normal = "normal",
    Dark = "dark",
}

export type MultiSelectOption<T> = {
    key?: string | number
    label: string
    value: T
    isChecked: boolean
    checkAll?: boolean
    uncheckAll?: boolean
    isEnabled?: boolean
    toolTip?: string
}

export function MultiSelect<T>({
    className,
    options,
    onSelectionChanged,
    disabled,
    isLoading = false,
    theme = MultiSelectTheme.Normal,
    defaultLabel,
}: MultiSelectProps<T>): JSX.Element {
    const containerRef = useRef<HTMLDivElement>(null)
    const valueRef = useRef<HTMLButtonElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const [overlayVisible, setOverlayVisible] = useState(false)
    const [search, setSearch] = useState("")

    const [label, count] = useMemo(() => {
        return createLabel(options, defaultLabel)
    }, [options])

    const filteredOptions = useMemo(() => {
        return options.filter((option) => search === "" || option.label.toLocaleLowerCase().includes(search))
    }, [options, search])

    useLayoutEffect(() => {
        if (!overlayVisible) return

        const handleExternalClick = (ev: MouseEvent) => {
            const el = ev.target as HTMLElement | null
            if (isNodeAncestorMatch(el, ".multi-select-options", 5)) return // ignore clicks in options that cause isLoading = true
            if (isNodeAncestor(el, containerRef.current, 5)) return
            setOverlayVisible(false)
        }

        const handleEscape = (ev: KeyboardEvent) => {
            if (ev.key !== "Escape") return false
            setOverlayVisible(false)
            valueRef.current?.focus()
            return true
        }

        document.addEventListener("click", handleExternalClick)
        GlobalKeyListener.enqueue(handleEscape)

        return () => {
            document.removeEventListener("click", handleExternalClick)
            GlobalKeyListener.dequeue(handleEscape)
        }
    }, [overlayVisible])

    useEffect(() => {
        setTimeout(() => {
            if (overlayVisible) inputRef.current?.focus()
        }, 100)
    }, [overlayVisible])

    return (
        <div
            ref={containerRef}
            data-theme={theme}
            className={classNames("multi-select", className, overlayVisible ? "multi-select--overlay-active" : null)}
        >
            <button ref={valueRef} className="multi-select-value" onClick={() => setOverlayVisible(!overlayVisible)} disabled={disabled ?? false}>
                {isLoading ? (
                    <div className="multi-select-loader">
                        <span>Loading...</span>
                    </div>
                ) : (
                    <>
                        <span className="multi-select-value-label">{label}</span>
                        {count > 1 && <span className="multi-select-value-count">({count})</span>}
                        { className == "work-order-status-widget" ? <ArrowSmallIcon /> : <></> } 
                    </>
                )}
            </button>
            <div className="multi-select-overlay">
                {options.length > 10 && (
                    <div className="multi-select-search-cont">
                        <TextInput
                            focusRef={inputRef}
                            type={TextInputType.Search}
                            onChange={(value) => setSearch(value.toLocaleLowerCase())}
                            value={search}
                        />
                    </div>
                )}
                <div className="multi-select-overlay-button-cont">
                {options.some(option => option.uncheckAll !== false) && (
                    <Button
                        type={ButtonType.Text}
                        label="Uncheck All"
                        onClick={() =>
                            onSelectionChanged(
                                options
                                    .filter(
                                        (option) =>
                                            option.isChecked &&
                                            filteredOptions.findIndex(
                                                (filteredOption) => filteredOption.value === option.value
                                            ) === -1
                                    )
                                    .map((option) => option.value)
                            )
                        }
                    />)}

                    {options.some(option => option.checkAll !== false) && (
                    <Button
                       type={ButtonType.Text}
                       label="Check All"
                       onClick={() =>
                            onSelectionChanged(
                            options
                            .filter(
                            (option) =>
                            option.isChecked ||
                            filteredOptions.findIndex(
                                (filteredOption) => filteredOption.value === option.value
                            ) !== -1
                        )
                        .map((option) => option.value)
                    )
                }
                />
                )}

                    
                </div>
                {isLoading ? (
                    <div className="multi-select-options">
                        <div className="multi-select-loader"></div>
                    </div>
                ) : filteredOptions.length === 0 ? (
                    <div className="multi-select-options">
                        <div className="multi-select-no-matches-found">No available options</div>
                    </div>
                ) : (
                    <RecyclerList
                        className="multi-select-options"
                        itemCount={filteredOptions.length}
                        itemHeight={30}
                        data={{ options, filteredOptions, onSelectionChanged }}
                    >
                        {Option}
                    </RecyclerList>
                )}
            </div>
        </div>
    )
}

function Option<T>(
    props: RecyclerListRowProps<{
        options: MultiSelectOption<T>[]
        filteredOptions: MultiSelectOption<T>[]
        onSelectionChanged: (option: T[]) => void
    }>
): JSX.Element {
    const option = props.filteredOptions[props.index]

    return (
        <CheckBox
            style={props.style}
            className="multi-select-option"
            key={option.key ?? (isString(option.value) || isNumber(option.value) ? option.value : option.label)}
            label={option.label}
            isChecked={option.isChecked}
            isEnabled={option.isEnabled}
            tooltip={option.toolTip}
            onCheckChanged={(isChecked) => {
                const filtered = props.options
                    .filter((item) => item.isChecked && item.value !== option.value)
                    .map((item) => item.value)
                if (isChecked) filtered.push(option.value)
                props.onSelectionChanged(filtered)
            }}
        />
    )
}

function createLabel<T>(options: MultiSelectOption<T>[],defaultLabel: string | undefined): [string, number] {
    const filtered =  options.filter((option) => option.isChecked).map((option) => option.label)

    if (filtered.length === 0) {
        return [ defaultLabel ?? "Select", 0]
    } else if (filtered.length === 1) {
        return [filtered[0], 1]
    } else {
        return [filtered.join(", "), filtered.length]
    }    
}
