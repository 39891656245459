import { classNames } from "@lib/HtmlUtil"
import { Color } from "@model/Color"
import React from "react"

export type CircleButtonProps = {
    bottomLabel: string
    circleLabel?: number
    children?: React.ReactNode
    onClick?: () => void
    color?: Color
    className?: string
    isActive?: boolean
    disabled?:boolean
    toolTip?:string
}

export function CircleButton({
    onClick,
    bottomLabel,
    children,
    circleLabel,
    className,
    color = Color.CircleButtonDefault,
    isActive = false,
    disabled = false,
    toolTip = "",
}: CircleButtonProps): JSX.Element {
    const style = {
        backgroundColor: color,
        color: (() => {
            if (color === Color.Transparent) return "#FFFFFF"
            return parseInt(color.substr(1), 16) < parseInt("777777", 16) ? "#FFFFFF" : "#000000"
        })(),
    }

    return (
        <button
            className={classNames(
                "circle-button",
                onClick && !disabled? "circle-button--is-interactive" : null,
                isActive ? "circle-button--active" : null,
                className
            )}
            onClick={!disabled ? onClick: () => {}}
            data-color={color}
            title={toolTip}
        >
            <div className="circle-button-circle" style={style}>
                <div className="circle-button-circle-label">
                    {circleLabel}
                    {children}
                </div>
            </div>
            <div className="circle-button-label">{bottomLabel}</div>
        </button>
    )
}
