import { Job } from "@ethossoftworks/job"
import { BlocCoordinator } from "@lib/bloc/BlocCoordinator"
import { Router, withRouter } from "@lib/router/router"
import { ExternalRoutes, Routes } from "@lib/Routes"
import { safeParseInt } from "@lib/TypeUtil"
import { Contact } from "@model/contacts/Contact"
import {
    ApproveServiceQuoteForm,
    RequestRevisionServiceQuoteForm,
    ServiceQuoteStatus,
} from "@model/serviceQuotes/ServiceQuote"
import { ServiceFormType } from "@model/serviceRequests/ServiceForm"
import { ServiceCode, ServiceRequestType, ServiceSchedule, ServiceSubType } from "@model/serviceRequests/ServiceRequest"
import { PermissionObject, PermissionType, User } from "@model/user/User"
import { CompanyBloc, CompanyState } from "@state/company/CompanyBloc"
import {
    ServiceQuoteEffect,
    ServiceQuoteScreenBloc,
    ServiceQuoteScreenState,
} from "@state/serviceQuotes/ServiceQuoteScreenBloc"
import { StatusBloc } from "@state/StatusBloc"
import { UserBloc, UserState } from "@state/user/UserBloc"
import { ServiceRequestCreationBloc } from "@state/workRequests/ServiceRequestCreationBloc"
import { YesNoDialog } from "@ui/common/YesNoModal"
import { DeclineServiceQuoteDialog } from "./DeclineServiceQuoteModal"

type Dependencies = [ServiceQuoteScreenState, CompanyState, UserState]

type StateSelection = ServiceQuoteScreenState & {
    mechanics: Contact[]
    contacts: Contact[]
    serviceCodes: ServiceCode[]
    isLoading: boolean
    hasDataFetchError: boolean
    assignedToContact: Contact | null
    serviceSubTypes: Record<ServiceRequestType, ServiceSubType>
    openedFormDate: Date
    companyLogoUrl: string | null
    user: User
}

export class ServiceQuoteScreenViewModel extends BlocCoordinator<Dependencies, StateSelection> {
    private router: Router
    constructor(
        public ServiceQuoteScreenBloc: ServiceQuoteScreenBloc,
        private companyBloc: CompanyBloc,
        private statusBloc: StatusBloc,
        public serviceRequestCreationBloc: ServiceRequestCreationBloc,
        private userBloc: UserBloc
    ) {
        super([ServiceQuoteScreenBloc, companyBloc, userBloc])
        this.router = withRouter((router) => router)
    }

    protected transform = ([ServiceQuoteState, companyState, userState]: Dependencies): StateSelection => ({
        ...ServiceQuoteState,
        serviceCodes: Object.values(companyState.serviceCodes),
        contacts: companyState.contacts,
        mechanics: companyState.settings.mechanics,
        isLoading: ServiceQuoteState.effectStatus[ServiceQuoteEffect.Fetch].isBusy(),
        hasDataFetchError: ServiceQuoteState.effectStatus[ServiceQuoteEffect.Fetch].isError(),
        assignedToContact: companyState.contacts.find((it) => ServiceQuoteState.form.assignedTo === it.id) ?? null,
        serviceSubTypes: companyState.settings.serviceSubTypes,
        openedFormDate: new Date(),
        companyLogoUrl: companyState.companyLogoUrl,
        user: userState.user,
        ApproveServiceQuoteModalVisible: ServiceQuoteState.ApproveServiceQuoteModalVisible,
    })

    loadServiceQuoteHistory = async (ServiceQuoteId: number) => {
        this.ServiceQuoteScreenBloc.fetchHistoryData(ServiceQuoteId)
    }

    redirectToAssetGeneralPage = (assetId: number) => window.open(ExternalRoutes.SmartHubAsset(assetId ?? 0), "_blank")

    approveAllServiceRequests = async () => {
        const outcome = await this.ServiceQuoteScreenBloc.updateAllServiceRequestsStatus(
            ServiceQuoteStatus.Approved,
            null
        )
        if (!Job.isCancelled(outcome) && outcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error updating Service Quote Items")
        }
        this.ServiceQuoteScreenBloc.saveForm()
    }

    declineAllServiceRequests = async (rejectionReason: string) => {
        const messageId = this.statusBloc.enqueueInfoMessage("Updating Service Quote Items...")
        this.ServiceQuoteScreenBloc.hideWorkRequestDetails()

        const outcome = await this.ServiceQuoteScreenBloc.updateAllServiceRequestsStatus(
            ServiceQuoteStatus.Declined,
            rejectionReason
        )
        if (!Job.isCancelled(outcome) && outcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error updating Service Quote Items")
        }

        this.statusBloc.hideInfoMessage(messageId)
        this.ServiceQuoteScreenBloc.saveForm()
    }

    viewServiceQuotesClicked = withRouter((router) => () => router.navigate(Routes.ServiceQuotes()))

    onMounted = (ServiceQuoteId: string) => this.ServiceQuoteScreenBloc.fetchData(safeParseInt(ServiceQuoteId))
    cancelClicked = () => this.ServiceQuoteScreenBloc.resetForm()

    saveClicked = async () => {
        const messageId = this.statusBloc.enqueueInfoMessage("Updating service Quote...")
        const outcome = await this.ServiceQuoteScreenBloc.saveForm()

        if (!Job.isCancelled(outcome) && outcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error updating service Quote")
        }
        this.statusBloc.hideInfoMessage(messageId)
    }

    serviceQuoteItemChanged = async () => {
        const messageId = this.statusBloc.enqueueInfoMessage("Updating service Quote...")
        const outcome = await this.ServiceQuoteScreenBloc.saveForm()

        if (!Job.isCancelled(outcome) && outcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error updating service Quote")
        }
        this.statusBloc.hideInfoMessage(messageId)
    }

    approveServiceQuoteClicked = async (approveServiceQuoteForm: ApproveServiceQuoteForm) => {
        const messageId = this.statusBloc.enqueueInfoMessage("Approving service Quote...")

        const outcome = await this.ServiceQuoteScreenBloc.saveForm(approveServiceQuoteForm)

        if (!Job.isCancelled(outcome) && outcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error Approving service Quote")
            return
        }

        this.hideApproveServiceQuoteModal()

        if (this.state.asset?.id) {
            const assetSchedulesOutcome = await this.companyBloc.fetchServiceScheduleInUse(this.state.asset.id)

            if (!Job.isCancelled(assetSchedulesOutcome) && assetSchedulesOutcome.isError()) {
                this.statusBloc.enqueueErrorMessage("Error verifiying asset on schedule")
            }
            if (assetSchedulesOutcome.isOk()) {
                var assetSchedules = assetSchedulesOutcome.value
                if (assetSchedules.length > 0) {
                    this.showAsociateServiceScheduleModal()
                    this.setAssetSchedules(assetSchedules)
                }
            }
        }

        this.statusBloc.hideInfoMessage(messageId)
    }

    AssociateScheduleToServiceQuoteClicked = async (assetSchedules: number[]) => {
        const messageId = this.statusBloc.enqueueInfoMessage("Associating Service Schedule to service Quote...")

        const outcome = await this.ServiceQuoteScreenBloc.associateScheduleToServiceQuote(assetSchedules)

        if (!Job.isCancelled(outcome) && outcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error Associating Service Schedule service Quote")
        }

        this.hideAsociateServiceScheduleModal()
        this.statusBloc.hideInfoMessage(messageId)
    }

    requestRevisionServiceQuoteClicked = async (requestRevisionServiceQuoteForm: RequestRevisionServiceQuoteForm) => {
        const messageId = this.statusBloc.enqueueInfoMessage("Requesting Revision service Quote...")

        requestRevisionServiceQuoteForm.userName = this.getUser().name

        const outcome = await this.ServiceQuoteScreenBloc.saveForm(undefined, requestRevisionServiceQuoteForm)

        if (!Job.isCancelled(outcome) && outcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error Requesting Revision service Quote")
        } else {
            this.hideRequestRevisionServiceQuoteModal()
        }

        this.statusBloc.hideInfoMessage(messageId)
    }

    handleSaveClicked = async () => {
        this.saveClicked()
    }

    showAsociateServiceScheduleModal = () => this.ServiceQuoteScreenBloc.setAsociateServiceScheduleModalVisibility(true)
    hideAsociateServiceScheduleModal = () =>
        this.ServiceQuoteScreenBloc.setAsociateServiceScheduleModalVisibility(false)

    setAssetSchedules = (schedules: ServiceSchedule[]) => this.ServiceQuoteScreenBloc.setAssetSchedules(schedules)

    showServiceRequestDetails = (workRequestId: number, formType: string) => {
        this.ServiceQuoteScreenBloc.showServiceRequestDetails(workRequestId, formType)
    }

    workRequestDetailsCancelClicked = (requestId: number) => {
        this.ServiceQuoteScreenBloc.hideWorkRequestDetails()
        this.ServiceQuoteScreenBloc.resetRequestForm(requestId)
    }
    workRequestDetailsSaveClicked = async (requestId: number) => {
        const messageId = this.statusBloc.enqueueInfoMessage("Updating Service Quote Item...")
        this.ServiceQuoteScreenBloc.hideWorkRequestDetails()

        const outcome = await this.ServiceQuoteScreenBloc.saveRequestForm(requestId, this.userBloc.state.user)
        if (!Job.isCancelled(outcome) && outcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error updating Service Quote Item")
        }

        this.statusBloc.hideInfoMessage(messageId)
    }

    getUser = () => this.userBloc.state.user

    statusChanged = (status: ServiceQuoteStatus) => {
        this.ServiceQuoteScreenBloc.statusChanged(status)
    }

    rejectionReasonChanged = (id: number, reason: string) => {
        this.ServiceQuoteScreenBloc.rejectionReasonChanged(id, reason)
    }

    assignedToChanged = (contactId: number) => this.ServiceQuoteScreenBloc.assignedToChanged(contactId)

    odometerLifetimeOdometerEditClicked = () => {
        window.open(ExternalRoutes.SmartHubAssetSync(this.state.asset?.id ?? 0), "_blank")
    }

    hourMeterLifetimeHourMeterEditClicked = () => {
        window.open(ExternalRoutes.SmartHubAssetSync(this.state.asset?.id ?? 0), "_blank")
    }

    isRequestFormValid = (requestId: number): boolean => {
        const form = this.state.serviceRequestForms[requestId]
        if (
            form.status == ServiceQuoteStatus.Declined &&
            (form.rejectionReason == null || form.rejectionReason == "")
        ) {
            return false
        }
        if (!form) return false

        return true
    }

    isFormValid = (): boolean => {
        const form = this.state.form
        if (!form) return false

        return true
    }

    requestServiceCodeChanged = (requestId: number, value: string, shouldUpdate: boolean = false) => {
        const serviceCode = this.state.serviceCodes.find((it) => it.id === safeParseInt(value))
        this.ServiceQuoteScreenBloc.requestServiceCodeChanged(requestId, serviceCode ?? null)
        if (shouldUpdate) {
            this.saveRequestForm(requestId)
            this.ServiceQuoteScreenBloc.saveForm()
        }
    }

    serviceQuoteStatusChanged(id: number, status: ServiceQuoteStatus, shouldUpdate: boolean = false): void {
        this.ServiceQuoteScreenBloc.serviceQuoteStatusChanged(id, status)
        if (shouldUpdate) this.serviceQuoteItemChanged()
    }

    showApproveServiceQuoteModal = () => this.ServiceQuoteScreenBloc.showApproveServiceQuoteModal()
    hideApproveServiceQuoteModal = () => this.ServiceQuoteScreenBloc.hideApproveServiceQuoteModal()

    showRequestRevisionServiceQuoteModal = () => this.ServiceQuoteScreenBloc.showRequestRevisionServiceQuoteModal()
    hideRequestRevisionServiceQuoteModal = () => this.ServiceQuoteScreenBloc.hideRequestRevisionServiceQuoteModal()

    showDeclineServiceQuoteModal = () => {
        if (this.state.serviceQuote != null) {
            let serviceQuoteId = this.state.serviceQuote.id
            if (this.state.formHasChanged) {
                YesNoDialog.open(
                    "There are unsaved changes. Are you sure you want to discard those changes?",
                    "Discard Changes?",
                    () => {
                        DeclineServiceQuoteDialog.open(serviceQuoteId, this.state.serviceRequests, this.state.user)
                    }
                )
            } else {
                DeclineServiceQuoteDialog.open(this.state.serviceQuote.id, this.state.serviceRequests, this.state.user)
            }
        }
    }

    requestNotesChanged(requestId: number, value: string): void {
        this.ServiceQuoteScreenBloc.requestNotesChanged(requestId, value)
    }
    requestWorkToBePerformedChanged = (requestId: number, value: string) =>
        this.ServiceQuoteScreenBloc.requestWorkToBePerformedChanged(requestId, value)

    attachmentError = (errors: string[]) => this.statusBloc.enqueueErrorMessage(errors.join("<br>"))

    saveRequestForm = async (requestId: number) => {
        if (!this.ServiceQuoteScreenBloc.isServiceRequestFormChanged(requestId)) return
        const messageId = this.statusBloc.enqueueInfoMessage("Updating service request...")
        const outcome = await this.ServiceQuoteScreenBloc.saveRequestForm(requestId, this.userBloc.state.user)

        if (!Job.isCancelled(outcome) && outcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error updating service request")
            this.ServiceQuoteScreenBloc.resetRequestForm(requestId)
        }

        this.statusBloc.hideInfoMessage(messageId)
    }

    createServiceButtonClicked(formType: ServiceFormType) {
        this.serviceRequestCreationBloc.showModal(formType)
    }

    hasServiceQuoteEditPermission = () =>
        this.userBloc.state.user.hasAccess(PermissionObject.ServiceQuote, PermissionType.Edit)

    hasServiceQuoteDeletePermission = () =>
        this.userBloc.state.user.hasAccess(PermissionObject.ServiceQuote, PermissionType.Delete)
}
