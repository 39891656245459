import { Bloc } from "@lib/bloc/Bloc"

export type AppState = {
    hasHydrated: boolean
}

export class AppBloc extends Bloc<AppState> {
    constructor() {
        super({ hasHydrated: false }, { persistStateOnDispose: true })
    }

    hasHydratedChanged = (hasHydrated: boolean) => this.update({ hasHydrated })
}
