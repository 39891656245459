import { Bloc } from "@lib/bloc/Bloc"
import { Urgency } from "@model/Urgency"
import { WorkOrder } from "@model/workOrders/WorkOrder"
import {
    AddWorkRequestToWorkOrderForm,
    newAddWorkRequestToWorkOrderForm,
} from "@model/workRequests/AddWorkRequestToWorkOrderForm"

export type AddWorkRequestToWorkOrderState = {
    selectedWorkOrder: number
    form: AddWorkRequestToWorkOrderForm
    isFormValid: boolean
    isSaving: boolean
}

export class AddWorkRequestToWorkOrderBloc extends Bloc<AddWorkRequestToWorkOrderState> {
    constructor() {
        super(
            {
                selectedWorkOrder: -1,
                form: newAddWorkRequestToWorkOrderForm(),
                isFormValid: false,
                isSaving: false,
            },
            { persistStateOnDispose: false }
        )
    }

    isFormValid = (): boolean => {
        return this.state.form.dueDate != null && this.state.form.urgency != null
    }

    selectedWorkOrderChanged = (workOrder: WorkOrder) =>
        this.update({
            selectedWorkOrder: workOrder.id,
            form: newAddWorkRequestToWorkOrderForm(workOrder),
        })

    urgencyChanged = (value: Urgency) => this.updateFormField({ urgency: value })

    dueDateChanged = (value: Date) => this.updateFormField({ dueDate: value })

    dueHourMeterChanged = (value: string) => this.updateFormField({ dueHourMeter: value })

    dueOdometerChanged = (value: string) => this.updateFormField({ dueOdometer: value })

    specialInstructionsChanged = (value: string) => this.updateFormField({ specialInstructions: value })

    private updateFormField = (value: Partial<AddWorkRequestToWorkOrderForm>) =>
        this.update({
            form: {
                ...this.state.form,
                ...value,
            },
        })
}
