import { classNames } from "@lib/HtmlUtil"
import { Color } from "@model/Color"
import React, { useRef, useState } from "react"
import { Button, ButtonType } from "./buttons/Button"
import { TextInput, TextInputProps } from "./form/TextInput"
import { PencilIcon } from "./images/InlinedSvgs"

export type DataFieldProps = {
    className?: string
    label: string
    value: string | React.ReactNode
    buttons?: React.ReactNode
    color?: Color
}

export function DataField({ label, value, color, className, buttons }: DataFieldProps): JSX.Element {
    return (
        <div
            className={classNames("data-field", className)}
            style={{ borderLeft: `2px solid ${color ?? "rgba(0,0,0,.20)"}` }}
            {...(buttons !== undefined ? { "data-has-buttons": true } : {})}
        >
            <div className="data-field-label">{label}</div>
            <div className="data-field-value">{value}</div>
            {buttons && <div className="data-field-button-cont">{buttons}</div>}
        </div>
    )
}

export type EditableDataFieldProps = {
    className?: string
    label: string
    value: string
    color?: Color
    textInput: Omit<TextInputProps, "value">
    disabled?: boolean
}

export function EditableDataField(props: EditableDataFieldProps): JSX.Element {
    const [isEditMode, setIsEditMode] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)

    const handleEditClick = () => {
        setIsEditMode(!isEditMode)
        if (!isEditMode) window.setTimeout(() => inputRef.current?.focus(), 10)
    }

    return (
        <DataField
            className={classNames("data-field--editable", props.className)}
            label={props.label}
            value={
                <>
                    {isEditMode ? (
                        <TextInput focusRef={inputRef} {...props.textInput} value={props.value} />
                    ) : (
                        props.value
                    )}
                </>
            }
            color={props.color}
            buttons={( props.disabled != null && !props.disabled) ?
                <Button type={ButtonType.Icon} label="Edit" onClick={handleEditClick}>
                    <PencilIcon />
                </Button> : ""
            }
        />
    )
}
