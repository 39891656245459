import { Bloc } from "@lib/bloc/Bloc"
import { Strings } from "@lib/Strings"
import { arrayToObject, safeParseInt } from "@lib/TypeUtil"
import { CompanySettings, newCompanySettings, WorkOrderAutomationSettings } from "@model/company/CompanySettings"
import { Contact, ContactType } from "@model/contacts/Contact"
import { ServiceRequestType, ServiceSubType } from "@model/serviceRequests/ServiceRequest"
import { Threshold, ThresholdGroup, ThresholdType, ThresholdUnit } from "@model/Threshold"
import { WorkRequestType } from "@model/workRequests/WorkRequest"
import isEqual from "lodash.isequal"

export type GlobalSettingsScreenState = {
    readonly hasPopulated: boolean
    readonly loadedSettings: CompanySettings
    readonly hasUnsavedChanges: boolean
    readonly form: GlobalSettingsForm
    readonly applyToSubCompanies: boolean
    readonly showMechanicAssignedModal: boolean
}

type GlobalSettingsForm = {
    readonly mechanics: Contact[]
    readonly serviceRequestTypeAliases: Record<ServiceRequestType, ServiceSubType>
    readonly workOrderAutomation: WorkOrderAutomationSettings
    readonly thresholds: ThresholdSettingsForm
}

type ThresholdSettingsForm = {
    [WorkRequestType.Preventative]: ThresholdGroupForm
    [WorkRequestType.Inspection]: ThresholdGroupForm
    [WorkRequestType.Service]: ThresholdGroupForm
}

type ThresholdGroupForm = {
    [ThresholdType.Hours]: ThresholdForm
    [ThresholdType.Miles]: ThresholdForm
    [ThresholdType.Days]: ThresholdForm
    applyToSubCompanies: boolean | null
}

type ThresholdForm = { unit: ThresholdUnit; value: string }

export enum GlobalSettingsEffect {
    Save = "save",
    Fetch = "fetch",
}

export class GlobalSettingsScreenBloc extends Bloc<GlobalSettingsScreenState> {
    constructor() {
        super(newGlobalSettingsScreenState(), { persistStateOnDispose: false })
    }

    override computed = (state: GlobalSettingsScreenState): Partial<GlobalSettingsScreenState> => ({
        hasUnsavedChanges: !isEqual(newGlobalSettingsForm(state.loadedSettings), state.form) && state.hasPopulated,
    })

    populateSettings = (settings: CompanySettings) => {
        this.update({
            loadedSettings: settings,
            hasPopulated: true,
            form: newGlobalSettingsForm(settings),
            applyToSubCompanies:
                settings.thresholds[WorkRequestType.Preventative].applyToSubCompanies ||
                settings.thresholds[WorkRequestType.Inspection].applyToSubCompanies ||
                settings.thresholds[WorkRequestType.Service].applyToSubCompanies ||
                false,
            showMechanicAssignedModal: false,
        })
    }

    updateMechanicSelection = (selection: Contact[]) =>
        this.updateFormField(
            (form): Partial<GlobalSettingsForm> => ({
                mechanics: selection,
            })
        )

    removeMechanic = (id: number) =>
        this.updateFormField(
            (form): Partial<GlobalSettingsForm> => ({
                mechanics: form.mechanics.filter((mechanic) => mechanic.id !== id),
            })
        )

    updateWorkOrderAutomation = (
        type: WorkRequestType.Inspection | WorkRequestType.Preventative | WorkRequestType.Service,
        value: boolean
    ) =>
        this.updateFormField(
            (form): Partial<GlobalSettingsForm> => ({
                workOrderAutomation: {
                    ...form.workOrderAutomation,
                    [type]: value,
                },
            })
        )

    updateThresholdValue = (
        type: WorkRequestType.Inspection | WorkRequestType.Preventative | WorkRequestType.Service,
        thresholdType: ThresholdType,
        value: string
    ) => {
        this.updateFormField((form): Partial<GlobalSettingsForm> => {
            const threshold: ThresholdForm = { ...form.thresholds[type][thresholdType], value: value }

            return {
                thresholds: {
                    ...form.thresholds,
                    [type]: {
                        ...form.thresholds[type],
                        [thresholdType]: threshold,
                    },
                },
            }
        })

        if (!Object.values(ThresholdType).some((tType) => this.state.form.thresholds[type][tType].value != "")) {
            this.updateWorkOrderAutomation(type, false)
        }
    }

    applyToSubCompaniesChanged = (value: boolean) => {
        this.update({
            ...this.state,
            applyToSubCompanies: value,
        })

        this.updateFormField(
            (form): Partial<GlobalSettingsForm> => ({
                workOrderAutomation: {
                    ...form.workOrderAutomation,
                    applyToSubCompanies: value,
                },
            })
        )

        this.updateFormField((form): Partial<GlobalSettingsForm> => {
            return {
                thresholds: {
                    ...form.thresholds,
                    [WorkRequestType.Inspection]: {
                        ...form.thresholds[WorkRequestType.Inspection],
                        applyToSubCompanies: value,
                    },
                    [WorkRequestType.Preventative]: {
                        ...form.thresholds[WorkRequestType.Preventative],
                        applyToSubCompanies: value,
                    },
                    [WorkRequestType.Service]: {
                        ...form.thresholds[WorkRequestType.Service],
                        applyToSubCompanies: value,
                    },
                },
            }
        })
    }

    updateThresholdUnit = (
        type: WorkRequestType.Inspection | WorkRequestType.Preventative | WorkRequestType.Service,
        thresholdType: ThresholdType,
        unit: ThresholdUnit
    ) =>
        this.updateFormField((form): Partial<GlobalSettingsForm> => {
            const threshold: ThresholdForm = { value: form.thresholds[type][thresholdType].value, unit: unit }

            return {
                thresholds: {
                    ...form.thresholds,
                    [type]: {
                        ...form.thresholds[type],
                        [thresholdType]: clampThresholdFormValue(threshold),
                    },
                },
            }
        })

    companySettingsFromForm = (): CompanySettings => {
        const loadedSettings = this.state.loadedSettings
        const form = this.state.form

        return {
            mechanics: form.mechanics,
            mechanicTypes: [
                ContactType.ThirdParty,
                ContactType.Contractor,
                ContactType.Customer,
                ContactType.Employee,
                ContactType.Vendor,
            ],
            serviceSubTypes: form.serviceRequestTypeAliases,
            workOrderAutomation: form.workOrderAutomation,
            thresholds: {
                [WorkRequestType.Preventative]: thresholdGroupFromThresholdGroupForm(
                    loadedSettings.thresholds[WorkRequestType.Preventative].id,
                    form.thresholds.preventative,
                    form.thresholds.preventative.applyToSubCompanies
                ),
                [WorkRequestType.Inspection]: thresholdGroupFromThresholdGroupForm(
                    loadedSettings.thresholds[WorkRequestType.Inspection].id,
                    form.thresholds.inspection,
                    form.thresholds.inspection.applyToSubCompanies
                ),
                [WorkRequestType.Service]: thresholdGroupFromThresholdGroupForm(
                    loadedSettings.thresholds[WorkRequestType.Service].id,
                    form.thresholds.service,
                    form.thresholds.service.applyToSubCompanies
                ),
            },
        }
    }

    private updateFormField = (update: (state: GlobalSettingsForm) => Partial<GlobalSettingsForm>) =>
        this.update({
            form: {
                ...this.state.form,
                ...update(this.state.form),
            },
        })

    hideMechanicAssignedModal = () => this.update({ showMechanicAssignedModal: false })
    showMechanicAssignedModal = () => this.update({ showMechanicAssignedModal: true })
}

function newGlobalSettingsScreenState(): GlobalSettingsScreenState {
    const defaultSettings = newCompanySettings()

    return {
        hasUnsavedChanges: false,
        loadedSettings: defaultSettings,
        hasPopulated: false,
        form: newGlobalSettingsForm(defaultSettings),
        applyToSubCompanies: false,
        showMechanicAssignedModal: false,
    }
}

function newGlobalSettingsForm(settings: CompanySettings): GlobalSettingsForm {
    return {
        mechanics: settings.mechanics,
        serviceRequestTypeAliases: settings.serviceSubTypes,
        workOrderAutomation: settings.workOrderAutomation,
        thresholds: {
            [WorkRequestType.Preventative]: thresholdGroupFormFromThresholdGroup(
                settings.thresholds[WorkRequestType.Preventative]
            ),
            [WorkRequestType.Inspection]: thresholdGroupFormFromThresholdGroup(
                settings.thresholds[WorkRequestType.Inspection]
            ),
            [WorkRequestType.Service]: thresholdGroupFormFromThresholdGroup(
                settings.thresholds[WorkRequestType.Service]
            ),
        },
    }
}

function thresholdGroupFormFromThresholdGroup(group: ThresholdGroup): ThresholdGroupForm {
    return {
        ...arrayToObject(Object.values(ThresholdType), (type) => [
            type,
            {
                unit: group[type].unit,
                value: stringValueForThreshold(group[type]),
            },
        ]),
        applyToSubCompanies: group.applyToSubCompanies,
    }
}

function thresholdGroupFromThresholdGroupForm(
    id: number | null,
    group: ThresholdGroupForm,
    applyToSubCompanies: boolean | null
): ThresholdGroup {
    return {
        id: id,
        ...arrayToObject(Object.values(ThresholdType), (type) => [
            type,
            {
                unit: group[type].unit,
                value: group[type].value.trim() !== "" ? safeParseInt(group[type].value) : null,
            },
        ]),
        applyToSubCompanies: applyToSubCompanies,
        isReadOnly: false
    }
}

function clampThresholdFormValue(thresholdForm: ThresholdForm): ThresholdForm {
    if (thresholdForm.value === "") return thresholdForm

    const clampedThreshold = Threshold.clampValue({
        unit: thresholdForm.unit,
        value: safeParseInt(thresholdForm.value),
    })

    return {
        ...thresholdForm,
        value: clampedThreshold.value === null ? "" : Strings.formatInteger(clampedThreshold.value),
    }
}

function stringValueForThreshold(threshold?: Threshold): string {
    if (threshold === undefined || threshold.value === null) return ""
    return Strings.formatInteger(threshold.value)
}
