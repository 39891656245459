import { Bloc } from "@lib/bloc/Bloc"
import { RequestRevisionServiceQuoteForm } from "@model/serviceQuotes/ServiceQuote"

export type RequestRevisionServiceQuoteState = RequestRevisionServiceQuoteForm & {}

export function newRequestRevisionServiceQuoteForm(): RequestRevisionServiceQuoteState {
    return {
        assetId: -1,
        notifyContacts: [],
        serviceQuoteId: -1,
        vendorMessage: "",
        userName: "",
    }
}

export enum RequestRevisionQuoteEffect {
    RequestRevision = "RequestRevision",
}
export class RequestRevisionServiceQuoteBloc extends Bloc<RequestRevisionServiceQuoteState> {
    constructor() {
        super(newRequestRevisionServiceQuoteForm(), { persistStateOnDispose: false })
    }

    notifyContactsToChanged = (value: number[]) => this.update({ notifyContacts: value })
    vendorMessageToChanged = (value: string) => this.update({ vendorMessage: value })
}
