import { useEffect, useState } from "react"
import { isRouteMatch, Route, RouteItem } from "./route"
import { createRouteForRouterState, withRouter } from "./router"

export const useRouter = withRouter((router) => () => router)

export const useRoute = withRouter((router) => (): Route => {
    const route = createRouteForRouterState(router.state)
    const [_, setRoute] = useState({ key: route.key, url: route.url })

    useEffect(() => {
        const dispose = router.listen((state) => {
            const updatedRoute = createRouteForRouterState(state)
            setRoute({ key: updatedRoute.key, url: updatedRoute.url })
        })
        return () => dispose()
    }, [])

    return route
})

export function useRouteMatch(matches: RouteItem | RouteItem[]): Route | null {
    const route = useRoute()
    return isRouteMatch(route, matches) ? route : null
}

export function useRouteParams(): Record<string, string> {
    return useRoute().data.params
}

export function useRouteQuery(): Record<string, string> {
    return useRoute().data.query
}

export function useRouteHash(): string {
    return useRoute().data.hash
}

export function usePreventNavigation({ when, message }: { when: boolean; message?: string }): void {
    message = message ?? "You have unsaved changes. Do you want to discard your changes?"

    const onBeforeNavigateListener = () => window.confirm(message)
    const unloadListener = (ev: BeforeUnloadEvent) => {
        ev.preventDefault()
        ev.returnValue = message
    }

    useEffect(() => {
        if (!when) return
        const router = useRouter()
        router.addOnBeforeNavigateListener(onBeforeNavigateListener)
        window.addEventListener("beforeunload", unloadListener, { capture: true })

        return () => {
            router.removeOnBeforeNavigateListener(onBeforeNavigateListener)
            window.removeEventListener("beforeunload", unloadListener, { capture: true })
        }
    }, [when])
}
