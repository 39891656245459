import React, { CSSProperties } from "react"
import { PDFHeaderBackground, PDFMinEntryHeight, PDFSectionBorder } from "./PDF"

export function PDFTable({ children, style }: { children: React.ReactNode; style: React.CSSProperties }): JSX.Element {
    return (
        <div style={{ display: "grid", borderBottom: PDFSectionBorder, borderRight: PDFSectionBorder, ...style }}>
            {children}
        </div>
    )
}

export function PDFtd({ children, style }: { children?: React.ReactNode; style?: React.CSSProperties }): JSX.Element {
    return (
        <div
            className="pdf-table-data"
            style={{
                padding: "2px 8px",
                fontSize: "1.4rem",
                borderTop: PDFSectionBorder,
                borderLeft: PDFSectionBorder,
                minHeight: PDFMinEntryHeight,
                display: "grid",
                alignItems: "center",
                ...style,
            }}
        >
            {children}
        </div>
    )
}

export function PDFTLabel({ children, style }: { children: React.ReactNode; style?: CSSProperties }): JSX.Element {
    return (
        <div
            className="pdf-table-label"
            style={{
                fontWeight: 700,
                background: PDFHeaderBackground,
                padding: "2px 8px",
                fontSize: "1.4rem",
                borderTop: PDFSectionBorder,
                borderLeft: PDFSectionBorder,
                minHeight: PDFMinEntryHeight,
                display: "grid",
                alignItems: "center",
                ...style,
            }}
        >
            {children}
        </div>
    )
}
