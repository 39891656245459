import { useBlocCoordinator } from "@lib/bloc/hooks"
import { CompanyAlias } from "@model/company/CompanyAlias"
import { DashboardFilter } from "@model/dashboard/DashboardFilter"
import { DashboardFilterDefinitions } from "@model/dashboard/DashboardFilterDefinitions"
import { filterContainsValue } from "@model/filters/Filter"
import { FilterButton } from "@ui/common/buttons/FilterButton"
import { EditFilterPresetModal } from "@ui/common/filters/EditFilterPresetModal"
import { FilterOverlay, FilterOverlayGroup, FilterOverlayGroupColumn } from "@ui/common/filters/FilterOverlay"
import { FilterOverlayPresetsGroup } from "@ui/common/filters/FilterOverlayPresetsGroup"
import { ManageFilterPresetsModal } from "@ui/common/filters/ManageFilterPresetsModal"
import { FormField } from "@ui/common/form/FormField"
import { MultiSelect } from "@ui/common/form/MultiSelect"
import { DI } from "@ui/DI"
import React from "react"

export function DashboardFilterOverlay(): JSX.Element {
    const [state, viewModel] = useBlocCoordinator(() => DI.dashboardFilterOverlayViewModel())
    const dashboardBloc = DI.dashboardScreenBloc()

    return (
        <>
            <FilterOverlay
                isOverlayVisible={state.isOverlayVisible}
                className="filter-overlay--work-dashboard"
                hasSearch={true}
                onCancel={() => {
                    viewModel.updateFilters(dashboardBloc.state.filters)
                    viewModel.toggleOverlayVisibility()
                }}
            >
                <FilterOverlayGroup label="Asset Assignment" columns={1}>
                    <FilterOverlayGroupColumn>
                        {(state.subCompanies.length > 0 || state.parentCompany) && (
                            <FormField label={state.aliases[CompanyAlias.SubCompany]}>
                                <MultiSelect
                                    options={[state.parentCompany, ...state.subCompanies].map((it) => ({
                                        label: it.name,
                                        value: it,
                                        key: it.id,
                                        isChecked: filterContainsValue(state.filters[DashboardFilter.SubCompany], [
                                            it.id,
                                        ]),
                                    }))}
                                    onSelectionChanged={(selected) =>
                                        viewModel.updateFilter(
                                            DashboardFilter.SubCompany,
                                            selected.map((it) => ({ value: it.id, label: it.name }))
                                        )
                                    }
                                />
                            </FormField>
                        )}
                        {state.districts.length > 0 && (
                            <FormField label={state.aliases[CompanyAlias.District]}>
                                <MultiSelect
                                    options={state.districts.map((it) => ({
                                        label: it.name,
                                        value: it,
                                        key: it.id,
                                        isChecked: filterContainsValue(state.filters[DashboardFilter.District], [
                                            it.id,
                                        ]),
                                    }))}
                                    onSelectionChanged={(selected) =>
                                        viewModel.updateFilter(
                                            DashboardFilter.District,
                                            selected.map((it) => ({ value: it.id, label: it.name }))
                                        )
                                    }
                                />
                            </FormField>
                        )}
                        {state.subDistricts.length > 0 && (
                            <FormField label={state.aliases[CompanyAlias.SubDistrict]}>
                                <MultiSelect
                                    options={state.subDistricts.map((it) => ({
                                        label: it.name,
                                        value: it,
                                        key: it.id,
                                        isChecked: filterContainsValue(state.filters[DashboardFilter.SubDistrict], [
                                            it.id,
                                        ]),
                                    }))}
                                    onSelectionChanged={(selected) =>
                                        viewModel.updateFilter(
                                            DashboardFilter.SubDistrict,
                                            selected.map((it) => ({ value: it.id, label: it.name }))
                                        )
                                    }
                                />
                            </FormField>
                        )}
                        {state.units.length > 0 && (
                            <FormField label={state.aliases[CompanyAlias.Unit]}>
                                <MultiSelect
                                    options={state.units.map((it) => ({
                                        label: it.name,
                                        value: it,
                                        key: it.id,
                                        isChecked: filterContainsValue(state.filters[DashboardFilter.Unit], [it.id]),
                                    }))}
                                    onSelectionChanged={(selected) =>
                                        viewModel.updateFilter(
                                            DashboardFilter.Unit,
                                            selected.map((it) => ({ value: it.id, label: it.name }))
                                        )
                                    }
                                />
                            </FormField>
                        )}
                        {state.groups.length > 0 && (
                            <FormField label={state.aliases[CompanyAlias.Group]}>
                                <MultiSelect
                                    options={state.groups.map((it) => ({
                                        label: it.name,
                                        value: it,
                                        key: it.id,
                                        isChecked: filterContainsValue(state.filters[DashboardFilter.Group], [it.id]),
                                    }))}
                                    onSelectionChanged={(selected) =>
                                        viewModel.updateFilter(
                                            DashboardFilter.Group,
                                            selected.map((it) => ({ value: it.id, label: it.name }))
                                        )
                                    }
                                />
                            </FormField>
                        )}
                        {state.sites.length > 0 && (
                            <FormField label={DashboardFilterDefinitions[DashboardFilter.Site].categoryLabel}>
                                <MultiSelect
                                    options={state.sites.map((it) => ({
                                        label: it.name,
                                        value: it,
                                        key: it.id,
                                        isChecked: filterContainsValue(state.filters[DashboardFilter.Site], [it.id]),
                                    }))}
                                    onSelectionChanged={(selected) =>
                                        viewModel.updateFilter(
                                            DashboardFilter.Site,
                                            selected.map((it) => ({ value: it.id, label: it.name }))
                                        )
                                    }
                                />
                            </FormField>
                        )}
                    </FilterOverlayGroupColumn>
                </FilterOverlayGroup>
                <FilterOverlayGroup label="Asset Properties" columns={1}>
                    <FilterOverlayGroupColumn>
                        <FormField label={DashboardFilterDefinitions[DashboardFilter.AssetType].categoryLabel}>
                            <MultiSelect
                                options={state.types.map((it) => ({
                                    label: it.name,
                                    value: it,
                                    key: it.id,
                                    isChecked: filterContainsValue(state.filters[DashboardFilter.AssetType], [it.id]),
                                }))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        DashboardFilter.AssetType,
                                        selected.map((it) => ({ value: it.id, label: it.name }))
                                    )
                                }
                            />
                        </FormField>
                        <FormField label={DashboardFilterDefinitions[DashboardFilter.Category].categoryLabel}>
                            <MultiSelect
                                options={state.categories.map((it) => ({
                                    label: it.name,
                                    value: it,
                                    key: it.id,
                                    isChecked: filterContainsValue(state.filters[DashboardFilter.Category], [it.id]),
                                }))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        DashboardFilter.Category,
                                        selected.map((it) => ({ value: it.id, label: it.name }))
                                    )
                                }
                            />
                        </FormField>
                        <FormField label={DashboardFilterDefinitions[DashboardFilter.Class].categoryLabel}>
                            <MultiSelect
                                options={state.classes.map((it) => ({
                                    label: it.name,
                                    value: it,
                                    key: it.id,
                                    isChecked: filterContainsValue(state.filters[DashboardFilter.Class], [it.id]),
                                }))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        DashboardFilter.Class,
                                        selected.map((it) => ({ value: it.id, label: it.name }))
                                    )
                                }
                            />
                        </FormField>
                        <FormField label={DashboardFilterDefinitions[DashboardFilter.Make].categoryLabel}>
                            <MultiSelect
                                options={state.makes.map((it) => ({
                                    label: it.name,
                                    value: it,
                                    key: it.id,
                                    isChecked: filterContainsValue(state.filters[DashboardFilter.Make], [it.id]),
                                }))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        DashboardFilter.Make,
                                        selected.map((it) => ({ value: it.id, label: it.name }))
                                    )
                                }
                            />
                        </FormField>
                        <FormField label={DashboardFilterDefinitions[DashboardFilter.Model].categoryLabel}>
                            <MultiSelect
                                options={state.models.map((it) => ({
                                    label: it.name,
                                    value: it,
                                    key: it.id,
                                    isChecked: filterContainsValue(state.filters[DashboardFilter.Model], [it.id]),
                                }))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        DashboardFilter.Model,
                                        selected.map((it) => ({ value: it.id, label: it.name }))
                                    )
                                }
                            />
                        </FormField>
                    </FilterOverlayGroupColumn>
                </FilterOverlayGroup>
                <FilterOverlayGroup label="" columns={2} />
                <FilterOverlayPresetsGroup
                    presets={state.filterPresets}
                    viewModel={viewModel}
                    overlayState={state}
                    searchFilter={DashboardFilter.Search}
                    searchLabel={"Asset ID, VIN, or Serial Number"}
                />
            </FilterOverlay>
            <EditFilterPresetModal
                isVisible={state.isCreatePresetVisible}
                unavailableNames={state.filterPresets.map((it) => it.name)}
                onCancel={() => viewModel.hideCreatePreset()}
                onSave={(form) => viewModel.createPreset(form)}
                isSaving={ state.isSaving}
            />
            <ManageFilterPresetsModal
                isVisible={state.isManagePresetsVisible}
                presets={state.filterPresets}
                onCancel={() => viewModel.hideManagePresets()}
                onPresetDelete={(preset) => viewModel.deletePreset(preset)}
                onPresetEdit={(preset, form) => viewModel.updatePresetForm(preset, form)}
                isSaving={state.isSaving}
            />
        </>
    )
}

export function DashboardFilterButton(): JSX.Element {
    const [state, viewModel] = useBlocCoordinator(() => DI.dashboardFilterOverlayViewModel())

    return (
        <FilterButton
            onClick={() => viewModel.toggleOverlayVisibility()}
            isFilterOverlayVisible={state.isOverlayVisible}
        />
    )
}
