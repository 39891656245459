import { useBlocCoordinator } from "@lib/bloc/hooks"
import { Color } from "@model/Color"
import { StatusWidgetCounts, StatusWidgetGroup } from "@model/widgets/StatusWidget"
import { WorkOrderQuickFilterCount, WorkOrderStatus } from "@model/workOrders/WorkOrder"
import { WorkRequestQuickFilterCount, WorkRequestType } from "@model/workRequests/WorkRequest"
import { CircleButton } from "@ui/common/buttons/CircleButton"
import { LoadingIndicator } from "@ui/common/LoadingIndicator"
import { DI } from "@ui/DI"
import React from "react"

export function StatusWidget({
    items,
    isLoading,
    onSettingsButtonClick,
}: {
    items: StatusWidgetCounts
    isLoading: boolean
    onSettingsButtonClick: () => void
}): JSX.Element {
    const [_, viewModel] = useBlocCoordinator(() => DI.statusWidgetViewModel())

    return (
        <div className={`widget status-widget`}>
            {/* <WidgetSettingsButton onClick={onSettingsButtonClick} theme={WidgetSettingsButtonTheme.Light} /> */}
            <div className="status-widget-group-cont">
                <div className="status-widget-group">
                    <div className="status-widget-group-name">Service Requests</div>
                    <div className="status-widget-group-items">
                        <CircleButton
                            circleLabel={items[StatusWidgetGroup.WorkRequests][WorkRequestQuickFilterCount.Assets]}
                            bottomLabel="Assets"
                            onClick={() => viewModel.workRequestAssetsCountClicked()}
                            color={undefined}
                            disabled={!viewModel.hasWorkRequestViewPermissions()}
                        />
                        <CircleButton
                            circleLabel={
                                items[StatusWidgetGroup.WorkRequests][WorkRequestQuickFilterCount.Preventative]
                            }
                            bottomLabel="Preventative"
                            onClick={() => viewModel.workRequestTypeCountClicked(WorkRequestType.Preventative)}
                            color={undefined}
                            disabled={!viewModel.hasWorkRequestViewPermissions()}
                        />
                        <CircleButton
                            circleLabel={items[StatusWidgetGroup.WorkRequests][WorkRequestQuickFilterCount.Inspection]}
                            bottomLabel="Inspection"
                            onClick={() => viewModel.workRequestTypeCountClicked(WorkRequestType.Inspection)}
                            color={undefined}
                            disabled={!viewModel.hasWorkRequestViewPermissions()}
                        />
                        <CircleButton
                            circleLabel={items[StatusWidgetGroup.WorkRequests][WorkRequestQuickFilterCount.Service]}
                            bottomLabel="Repair"
                            onClick={() => viewModel.workRequestTypeCountClicked(WorkRequestType.Service)}
                            color={undefined}
                            disabled={!viewModel.hasWorkRequestViewPermissions()}
                        />
                        <CircleButton
                            circleLabel={items[StatusWidgetGroup.WorkRequests][WorkRequestQuickFilterCount.Overdue]}
                            {...(items[StatusWidgetGroup.WorkRequests][WorkRequestQuickFilterCount.Overdue] > 0
                                ? { color: Color.Orange }
                                : {})}
                            bottomLabel="Overdue"
                            onClick={() => viewModel.workRequestOverdueClicked()}
                            disabled={!viewModel.hasWorkRequestViewPermissions()}
                        />
                        <CircleButton
                            circleLabel={items[StatusWidgetGroup.WorkRequests][WorkRequestQuickFilterCount.RedTag]}
                            {...(items[StatusWidgetGroup.WorkRequests][WorkRequestQuickFilterCount.RedTag] > 0
                                ? { color: Color.Red }
                                : {})}
                            bottomLabel="Red Tag"
                            onClick={() => viewModel.workRequestRedTagClicked()}
                            disabled={!viewModel.hasWorkRequestViewPermissions()}
                        />
                        <CircleButton
                            circleLabel={items[StatusWidgetGroup.WorkRequests][WorkRequestQuickFilterCount.YellowTag]}
                            {...(items[StatusWidgetGroup.WorkRequests][WorkRequestQuickFilterCount.YellowTag] > 0
                                ? { color: Color.Yellow }
                                : {})}
                            bottomLabel="Yellow Tag"
                            onClick={() => viewModel.workRequestYellowTagClicked()}
                            disabled={!viewModel.hasWorkRequestViewPermissions()}
                        />
                        <CircleButton
                            circleLabel={items[StatusWidgetGroup.WorkRequests][WorkRequestQuickFilterCount.Immediate]}
                            {...(items[StatusWidgetGroup.WorkRequests][WorkRequestQuickFilterCount.Immediate] > 0
                                ? { color: Color.Salmon }
                                : {})}
                            bottomLabel="Immediate"
                            onClick={() => viewModel.workRequestImmediateClicked()}
                            disabled={!viewModel.hasWorkRequestViewPermissions()}
                        />
                    </div>
                </div>
                <div className="status-widget-group">
                    <div className="status-widget-group-name">Work Orders</div>
                    <div className="status-widget-group-items">
                        <CircleButton
                            circleLabel={items[StatusWidgetGroup.WorkOrders][WorkOrderQuickFilterCount.WorkOrders]}
                            bottomLabel="Work Orders"
                            onClick={() => viewModel.workOrdersCountClicked()}
                            color={undefined}
                            disabled={!viewModel.hasWorkOrderViewPermissions()}
                        />
                        <CircleButton
                            circleLabel={items[StatusWidgetGroup.WorkOrders][WorkOrderQuickFilterCount.Pending]}
                            bottomLabel="Pending"
                            onClick={() => viewModel.workOrderStatusCountClicked(WorkOrderStatus.Pending)}
                            color={undefined}
                            disabled={!viewModel.hasWorkOrderViewPermissions()}
                        />
                        <CircleButton
                            circleLabel={items[StatusWidgetGroup.WorkOrders][WorkOrderQuickFilterCount.Open]}
                            bottomLabel="Open"
                            onClick={() => viewModel.workOrderStatusCountClicked(WorkOrderStatus.Open)}
                            color={undefined}
                            disabled={!viewModel.hasWorkOrderViewPermissions()}
                        />
                        <CircleButton
                            circleLabel={items[StatusWidgetGroup.WorkOrders][WorkOrderQuickFilterCount.InProgress]}
                            bottomLabel="In Progress"
                            onClick={() => viewModel.workOrderStatusCountClicked(WorkOrderStatus.InProgress)}
                            color={undefined}                            
                            disabled={!viewModel.hasWorkOrderViewPermissions()}
                        />
                        <CircleButton
                            circleLabel={items[StatusWidgetGroup.WorkOrders][WorkOrderQuickFilterCount.Completed]}
                            {...(items[StatusWidgetGroup.WorkOrders][WorkOrderQuickFilterCount.Completed] > 0
                                ? { color: Color.Green }
                                : {})}
                            bottomLabel="Completed"                            
                            onClick={() => viewModel.workOrderStatusCountClicked(WorkOrderStatus.WorkCompleted)}
                            disabled={!viewModel.hasWorkOrderViewPermissions()}
                        />
                        <CircleButton
                            circleLabel={items[StatusWidgetGroup.WorkOrders][WorkOrderQuickFilterCount.Overdue]}
                            {...(items[StatusWidgetGroup.WorkOrders][WorkOrderQuickFilterCount.Overdue] > 0
                                ? { color: Color.Orange }
                                : {})}
                            bottomLabel="Overdue"
                            onClick={() => viewModel.workOrderOverdueClicked()}
                            disabled={!viewModel.hasWorkOrderViewPermissions()}
                        />
                        <CircleButton
                            circleLabel={items[StatusWidgetGroup.WorkOrders][WorkOrderQuickFilterCount.RedTag]}
                            {...(items[StatusWidgetGroup.WorkOrders][WorkOrderQuickFilterCount.RedTag] > 0
                                ? { color: Color.Red }
                                : {})}
                            bottomLabel="Red Tag"
                            onClick={() => viewModel.workOrderRedTagClicked()}
                            disabled={!viewModel.hasWorkOrderViewPermissions()}
                        />
                        <CircleButton
                            circleLabel={items[StatusWidgetGroup.WorkOrders][WorkOrderQuickFilterCount.YellowTag]}
                            {...(items[StatusWidgetGroup.WorkOrders][WorkOrderQuickFilterCount.YellowTag] > 0
                                ? { color: Color.Yellow }
                                : {})}
                            bottomLabel="Yellow Tag"
                            onClick={() => viewModel.workOrderYellowTagClicked()}
                            disabled={!viewModel.hasWorkOrderViewPermissions()}
                        />
                        <CircleButton
                            circleLabel={items[StatusWidgetGroup.WorkOrders][WorkOrderQuickFilterCount.Immediate]}
                            {...(items[StatusWidgetGroup.WorkOrders][WorkOrderQuickFilterCount.Immediate] > 0
                                ? { color: Color.Salmon }
                                : {})}
                            bottomLabel="Immediate"
                            onClick={() => viewModel.workOrderImmediateClicked()}
                            disabled={!viewModel.hasWorkOrderViewPermissions()}
                        />
                    </div>
                </div>
            </div>
            <LoadingIndicator isLoading={isLoading} backgroundColor={"#191e27bf"} loaderColor="#FFFFFF" />
        </div>
    )
}
