import { Job } from "@ethossoftworks/job"
import { BlocCoordinator } from "@lib/bloc/BlocCoordinator"
import { compare, SortOrder } from "@lib/Comparable"
import { Contact, labelForContact } from "@model/contacts/Contact"
import { ThresholdType, ThresholdUnit } from "@model/Threshold"
import { WorkRequestType } from "@model/workRequests/WorkRequest"
import { GlobalSettingsScreenBloc, GlobalSettingsScreenState } from "@state/admin/GlobalSettingsScreenBloc"
import { CompanyBloc, CompanyEffect, CompanyState } from "@state/company/CompanyBloc"
import { StatusBloc } from "@state/StatusBloc"
import { UserBloc } from "@state/user/UserBloc"

type Dependencies = [GlobalSettingsScreenState, CompanyState]
type StateSelection = GlobalSettingsScreenState & {
    contacts: Contact[]
    hasUnsavedChanges: boolean
    isLoading: boolean
}

export class GlobalSettingsScreenViewModel extends BlocCoordinator<Dependencies, StateSelection> {
    constructor(
        private globalSettingsBloc: GlobalSettingsScreenBloc,
        private companyBloc: CompanyBloc,
        private statusBloc: StatusBloc,
        private userBloc: UserBloc
    ) {
        super([globalSettingsBloc, companyBloc])
    }

    protected transform = ([globalSettingsState, companyState]: Dependencies): StateSelection => ({
        ...globalSettingsState,
        form: {
            ...globalSettingsState.form,
            mechanics: globalSettingsState.form.mechanics.sort((a, b) =>
                compare(labelForContact(a), labelForContact(b), SortOrder.Asc)
            ),
        },
        contacts: companyState.contacts,
        isLoading: companyState.effectStatus[CompanyEffect.FetchGlobalSettings].isBusy(),
    })

    onMounted = async () => {
        const settingsOutcome = await this.companyBloc.fetchSettings()

        if (settingsOutcome.isError()) {
            if (Job.isCancelled(settingsOutcome)) return
            this.statusBloc.enqueueErrorMessage("Error loading settings")
            return
        }

        this.globalSettingsBloc.populateSettings(settingsOutcome.value)
    }

    getUser = () => this.userBloc.state.user

    onMechanicSelectionChanged = (selection: Contact[]) => this.globalSettingsBloc.updateMechanicSelection(selection)

    onMechanicRemoveButtonClicked = (mechanicId: number) => this.globalSettingsBloc.removeMechanic(mechanicId)

    onWorkOrderAutomationChanged = (
        type: WorkRequestType.Preventative | WorkRequestType.Inspection | WorkRequestType.Service,
        value: boolean
    ) => this.globalSettingsBloc.updateWorkOrderAutomation(type, value)

    onThresholdValueChanged = (
        workRequestType: WorkRequestType.Inspection | WorkRequestType.Preventative | WorkRequestType.Service,
        thresholdType: ThresholdType,
        value: string
    ) => this.globalSettingsBloc.updateThresholdValue(workRequestType, thresholdType, value)

    onThresholdUnitChanged = (
        workRequestType: WorkRequestType.Inspection | WorkRequestType.Preventative | WorkRequestType.Service,
        thresholdType: ThresholdType,
        unit: ThresholdUnit
    ) => this.globalSettingsBloc.updateThresholdUnit(workRequestType, thresholdType, unit)

    onResetClicked = () => this.globalSettingsBloc.populateSettings(this.companyBloc.state.settings)

    onSaveClicked = async () => {
        const loaderId = this.statusBloc.enqueueInfoMessage("Saving...")
        const settingsOutcome = await this.companyBloc.updateSettings(this.globalSettingsBloc.companySettingsFromForm())

        if (settingsOutcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error saving settings")
            this.statusBloc.hideInfoMessage(loaderId)
            return
        }

        this.globalSettingsBloc.populateSettings(settingsOutcome.value)
        this.statusBloc.hideInfoMessage(loaderId)
    }

    onApplyToSubCompaniesChanged = (value: boolean) => {
        this.globalSettingsBloc.applyToSubCompaniesChanged(value)
    }

    onCloseMechanicAssignedModal = () => this.globalSettingsBloc.hideMechanicAssignedModal()

    showMechanicAssignedModal = () => this.globalSettingsBloc.showMechanicAssignedModal()
}
