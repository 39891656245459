import { useBlocCoordinator } from "@lib/bloc/hooks"
import { Link } from "@lib/router/components"
import { useRouter } from "@lib/router/hooks"
import { labelForContact, labelForContactType, ContactType } from "@model/contacts/Contact"
import { Filter } from "@model/filters/Filter"
import { WorkOrderFilter, WorkOrderStatus } from "@model/workOrders/WorkOrder"
import { WidgetSelect } from "@ui/common/form/WidgetSelect"
import { LoadingIndicator, LoadingIndicatorContainer } from "@ui/common/LoadingIndicator"
import { DI } from "@ui/DI"
import { Routes } from "@lib/Routes"
import React, { useEffect } from "react"
import { Label, Pie, PieChart, ResponsiveContainer } from "recharts"
import { Widget, WidgetContent, WidgetLabel } from "./DashboardWidget"
import { MultiSelect, MultiSelectTheme } from "@ui/common/form/MultiSelect"
import { Grid, GridCont, GridGap, GridJustification } from "@ui/common/grids/Grid"
import { FormField } from "@ui/common/form/FormField"

export function WorkOrderStatusWidget(): JSX.Element {
    const [state, viewModel] = useBlocCoordinator(() => DI.workOrderStatusWidgetViewModel())
    const chartData = [
        {
            fill: "#B0B0B0",
            value: state.workOrderStatusCounts[WorkOrderStatus.Pending],
        },
        {
            fill: "#646464",
            value: state.workOrderStatusCounts[WorkOrderStatus.Open],
        },
        {
            fill: "#323232",
            value: state.workOrderStatusCounts[WorkOrderStatus.InProgress],
        },
        {
            fill: "#7BC131",
            value: state.workOrderStatusCounts[WorkOrderStatus.WorkCompleted],
        },
    ]

    const TotalCount = () : number =>{
        return state.workOrderStatusCounts[WorkOrderStatus.Pending] + state.workOrderStatusCounts[WorkOrderStatus.Open]
        + state.workOrderStatusCounts[WorkOrderStatus.InProgress] + state.workOrderStatusCounts[WorkOrderStatus.WorkCompleted]
    }

    useEffect(() => {
        viewModel.onMounted()
    }, [])

    return (
        <Widget className="work-order-status-widget">
            <WidgetLabel label="Existing Work Order Status" />
            <LoadingIndicatorContainer>
                <LoadingIndicator isLoading={state.isLoading} />
                <WidgetContent>
                    <div className="work-order-status-widget-legend-cont">
                        <LegendItem
                            onClick={() => viewModel.onWorkOrderStatusClicked(WorkOrderStatus.Pending)}
                            color="#B0B0B0"
                            label="Pending"
                            count={state.workOrderStatusCounts[WorkOrderStatus.Pending]}
                            isEnabled={viewModel.hasWorkOrderViewPermissions()}
                        />
                        <LegendItem
                            onClick={() => viewModel.onWorkOrderStatusClicked(WorkOrderStatus.Open)}
                            color="#646464"
                            label="Open"
                            count={state.workOrderStatusCounts[WorkOrderStatus.Open]}
                            isEnabled={viewModel.hasWorkOrderViewPermissions()}
                        />
                        <LegendItem
                            onClick={() => viewModel.onWorkOrderStatusClicked(WorkOrderStatus.InProgress)}
                            color="#323232"
                            label="In Progress"
                            count={state.workOrderStatusCounts[WorkOrderStatus.InProgress]}
                            isEnabled={viewModel.hasWorkOrderViewPermissions()}
                        />
                        <LegendItem
                            onClick={() => viewModel.onWorkOrderStatusClicked(WorkOrderStatus.WorkCompleted)}
                            color="#7BC131"
                            label="Complete"
                            count={state.workOrderStatusCounts[WorkOrderStatus.WorkCompleted]}
                            isEnabled={viewModel.hasWorkOrderViewPermissions()}
                        />
                        <LegendItem
                            onClick={() => viewModel.onWorkOrderStatusClicked()}
                            color="#323232"
                            label="Total"
                            count={TotalCount()}
                            isEnabled={viewModel.hasWorkOrderViewPermissions()}
                        />
                    </div>
                    <div className="work-order-status-widget-chart-cont">
                        <ResponsiveContainer width={"100%"} height={"100%"}>
                            <PieChart>
                                <Pie
                                    data={chartData}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={"65%"}
                                    outerRadius={"100%"}
                                />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </WidgetContent>
            </LoadingIndicatorContainer>
            
                    <Grid columns={2}>
                        <FormField>
                            <MultiSelect
                                className="work-order-status-widget"
                                options={
                                    ...Object.values(ContactType).map((it, index) => ({
                                    key: it,
                                    value: index + 1,
                                    label: labelForContactType(it),
                                    isChecked:  state.selectedMechanicType.includes(it),
                                }))}
                                defaultLabel={"All Mechanic Types"}
                                onSelectionChanged={(selection) =>
                                    viewModel.mechanicTypeFilterChanged(selection)
                                }
                            />
                        </FormField>
                        <FormField>
                            <WidgetSelect
                    onChange={(value) => viewModel.mechanicFilterChanged(value)}
                    options={[{ label: "All Mechanics", value: "-1" }].concat(
                        state.mechanics
                        .filter((it) => !it.deleted || it.hasActiveWorkOrders)
                        .map((it) => ({ label: labelForContact(it, viewModel.getUser()), value: it.id.toString() }))
                    )}
            />
            </FormField>

                    </Grid>
            
            
        </Widget>
    )
}

function LegendItem({
    color,
    label,
    count,
    onClick,
    isEnabled = true
}: {
    color: string
    onClick: () => Record<WorkOrderFilter, Filter<WorkOrderFilter>>
    label: string
    count: number
    isEnabled?: boolean
}) {
    const router = useRouter()

    return (
        (isEnabled)?
            <Link
            className="work-order-status-widget__legend-item"
            to={Routes.WorkOrders()}
            onClick={(ev: React.MouseEvent) => {
                ev.preventDefault()
                var result = onClick()

                router.navigate(Routes.WorkOrders(JSON.stringify(result)),undefined, true)
            }}
        >
            <div className="count" style={{ backgroundColor: color }}>
                {count}
            </div>
            <div className="label" style={{ color: color }}>
                {label}
            </div>
        </Link>
        : <span
            className="work-order-status-widget__legend-item">
        <div className="count" style={{ backgroundColor: color }}>
            {count}
        </div>
        <div className="unauthorizedLabel" style={{ color: color }}>
            {label}
        </div>
    </span>
    )
}
