import { useRoute } from "@lib/router/hooks"
import { ExternalRoutes} from "@lib/Routes"
import React from "react"
import { PermissionObject, PermissionType, User } from "@model/user/User"

export function AdminSidebar(props: { user: User }): JSX.Element {
    const route = useRoute()

    return (
        <div className="admin-sidebar">
            {props.user.hasAccess(PermissionObject.MaintenanceServiceSchedule, PermissionType.View) &&  
                <a 
                    className="admin-sidebar-link" target="_blank"
                    href={ExternalRoutes.SmartHubServiceSchedules()}
                >
                    Service Schedules
                </a>
            }
            {props.user.hasAccess(PermissionObject.MaintenanceServiceSteps, PermissionType.View) &&             
                <a  
                    className="admin-sidebar-link" target="_blank"
                    href={ExternalRoutes.SmartHubServiceSteps()}
                >
                    Service Steps
                </a>
            }
            {props.user.hasAccess(PermissionObject.MaintenanceServiceTask, PermissionType.View) &&
                <a 
                    className="admin-sidebar-link" target="_blank"
                    href={ExternalRoutes.SmartHubServiceTasks()}
                >
                    Service Tasks
                </a>
            }
            {props.user.hasAccess(PermissionObject.MaintenanceServiceCode, PermissionType.View) &&
                <a 
                    className="admin-sidebar-link" target="_blank"
                    href={ExternalRoutes.SmartHubServiceCodes()}
                >
                    Service Codes
                </a>
            }
            {props.user.hasAccess(PermissionObject.ServiceNotification, PermissionType.View) &&
                <a 
                    className="admin-sidebar-link" target="_blank"
                    href={ExternalRoutes.SmartHubServiceNotifications()}
                >
                    Service Notifications
                </a>
            }
            {props.user.hasAccess(PermissionObject.ServiceQuoteVendor, PermissionType.View) &&
                <a 
                    className="admin-sidebar-link" target="_blank"
                    href={ExternalRoutes.SmartHubServiceQuoteVendor()}
                >
                    Vendor Management
                </a>
            }
        </div>
    )
}
