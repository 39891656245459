import React, { CSSProperties } from "react"
import { PDF, PDFHeaderBackground, PDFMinEntryHeight, PDFPage, PDFSectionBorder } from "./components/PDF"
import { PDFHeader } from "./components/PDFHeader"
import { PDFPageHeader } from "./components/PDFPageHeader"
import { PDFSection } from "./components/PDFSection"
import { PDFAsset } from "./models/PDFAsset"
import { PDFAssetPart } from "./models/PDFAssetPart"

export type PartsListPDFProps = {
    asset: PDFAsset
    companyName: string
    parts: PDFAssetPart[]
    companyLogoUrl: string | null
}

export function PartsListPDF({ asset, companyName, parts, companyLogoUrl }: PartsListPDFProps): JSX.Element {
    return (
        <PDF>
            <PDFPage>
                <PDFPageHeader title={`Parts List - ${asset.label}`} companyName={companyName} />
                <PDFHeader
                    title={`Parts List`}
                    asset={asset}
                    showAssignedTo={false}
                    showLastKnownLocation={false}
                    showSerialNumber={true}
                    companyLogoUrl={companyLogoUrl}
                />
                <PDFSection style={{ breakInside: "initial" }}>
                    <table
                        style={{
                            borderCollapse: "collapse",
                            tableLayout: "auto",
                            width: "100%",
                            border: PDFSectionBorder,
                        }}
                    >
                        <thead>
                            <tr>
                                <PartHead style={{ textAlign: "center" }}>Qty.</PartHead>
                                <PartHead>Unit</PartHead>
                                <PartHead style={{ textAlign: "center" }}>Part Number</PartHead>
                                <PartHead style={{ maxWidth: "20vw" }}>Description</PartHead>
                                <PartHead>Vendor</PartHead>
                                <PartHead style={{ maxWidth: "30vw" }}>Note</PartHead>
                            </tr>
                        </thead>
                        <tbody>
                            {parts.map((it, i) => (
                                <Part key={i} part={it} />
                            ))}
                        </tbody>
                    </table>
                </PDFSection>
            </PDFPage>
        </PDF>
    )
}

function Part({ part }: { part: PDFAssetPart }): JSX.Element {
    return (
        <tr style={{ breakInside: "avoid", border: PDFSectionBorder }}>
            <PartCell style={{ textAlign: "center" }}>{part.quantity}</PartCell>
            <PartCell>{part.unit}</PartCell>
            <PartCell style={{ textAlign: "center" }}>{part.partNumber}</PartCell>
            <PartCell style={{ maxWidth: "20vw" }}>{part.description}</PartCell>
            <PartCell>{part.vendor}</PartCell>
            <PartCell style={{ maxWidth: "30vw" }}>{part.note}</PartCell>
        </tr>
    )
}

function PartHead({ children, style }: { children?: React.ReactNode; style?: CSSProperties }): JSX.Element {
    return (
        <th
            className="pdf-table-label"
            style={{
                fontWeight: 700,
                background: PDFHeaderBackground,
                border: PDFSectionBorder,
                padding: "2px 8px",
                fontSize: "1.4rem",
                minHeight: PDFMinEntryHeight,
                textAlign: "left",
                ...style,
            }}
        >
            {children}
        </th>
    )
}

function PartCell({ children, style }: { children?: React.ReactNode; style?: CSSProperties }): JSX.Element {
    return (
        <td
            className="pdf-table-data"
            style={{
                padding: "2px 8px",
                fontSize: "1.4rem",
                minHeight: PDFMinEntryHeight,
                border: PDFSectionBorder,
                ...style,
            }}
        >
            {children}
        </td>
    )
}
