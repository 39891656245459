import { Job } from "@ethossoftworks/job"
import { BlocCoordinator } from "@lib/bloc/BlocCoordinator"
import { CompanyAlias } from "@model/company/CompanyAlias"
import { Contact, labelForContact } from "@model/contacts/Contact"
import { Filter, FilterUpdateType } from "@model/filters/Filter"
import { createFilterGroupFromPreset, FilterPresetType } from "@model/filters/FilterPresets"
import { User } from "@model/user/User"
import { WorkOrderFilter, WorkOrderTableColumn, WorkOrderTableRow } from "@model/workOrders/WorkOrder"
import { WorkOrderFilterDefinitions } from "@model/workOrders/WorkOrderFilterDefinitions"
import { WorkOrderPrintOption } from "@model/workOrders/WorkOrderPrintOption"
import { CompanyBloc, CompanyState } from "@state/company/CompanyBloc"
import { FilterOverlayBlocCoordinatorMixin } from "@state/filters/FilterOverlayBlocCoordinatorMixin"
import { SortableTableBlocEffect } from "@state/SortableTableBloc"
import { StatusBloc } from "@state/StatusBloc"
import { UserBloc } from "@state/user/UserBloc"
import { UserPreferencesBloc, UserPreferencesState } from "@state/UserPreferencesBloc"
import { WorkOrdersFilterOverlayBloc } from "@state/workOrders/WorkOrdersFilterOverlayBloc"
import { WorkOrdersTableBloc, WorkOrdersTableState } from "@state/workOrders/WorkOrdersTableBloc"
/**
 * Work Orders Table ViewModel
 */
type Dependencies = [UserPreferencesState, WorkOrdersTableState, CompanyState]

type StateSelection = {
    aliases: Record<CompanyAlias, string>
    columns: WorkOrderTableColumn[]
    availableColumns: WorkOrderTableColumn[]
    isTableDataLoading: boolean
    user: User
    hasUserWorkRequest: boolean
    companyLogoUrl: string | null
} & WorkOrdersTableState

export class WorkOrdersTableViewModel extends BlocCoordinator<Dependencies, StateSelection> {
    private filterOverlayMixin: FilterOverlayBlocCoordinatorMixin<WorkOrderFilter>
    private isMobile = () => window.matchMedia && window.matchMedia("(max-width: 640px)").matches

    constructor(
        private userPreferencesBloc: UserPreferencesBloc,
        private workOrdersBloc: WorkOrdersTableBloc,
        private filterOverlayBloc: WorkOrdersFilterOverlayBloc,
        private companyBloc: CompanyBloc,
        private userBloc: UserBloc,
        private statusBloc: StatusBloc
    ) {
        super([userPreferencesBloc, workOrdersBloc, companyBloc])

        this.filterOverlayMixin = new FilterOverlayBlocCoordinatorMixin(
            FilterPresetType.WorkOrder,
            filterOverlayBloc,
            workOrdersBloc,
            userPreferencesBloc,
            statusBloc
        )
    }

    protected transform = ([userPreferences, tableState, companyState]: Dependencies): StateSelection => ({
        aliases: companyState.aliases,
        columns: userPreferences.tableSettings.workOrdersTableColumns.columns.filter(
            this.availableColumnFilter(companyState)
        ),
        isTableDataLoading: tableState.effectStatus[SortableTableBlocEffect.Fetch].isBusy(),
        availableColumns: Object.values(WorkOrderTableColumn).filter(this.availableColumnFilter(companyState)),
        user: this.userBloc.state.user,
        hasUserWorkRequest: tableState.userWorkRequests ? tableState.userWorkRequests?.length > 0 : false,
        companyLogoUrl: companyState.companyLogoUrl,
        ...tableState,
    })

    private availableColumnFilter = (state: CompanyState) => (it: WorkOrderTableColumn) => {
        switch (it) {
            case WorkOrderTableColumn.SubCompany:
                return state.subCompanies.length > 0
            case WorkOrderTableColumn.District:
                return state.districts.length > 0
            case WorkOrderTableColumn.SubDistrict:
                return state.subDistricts.length > 0
            case WorkOrderTableColumn.Unit:
                return state.units.length > 0
            case WorkOrderTableColumn.Group:
                return state.groups.length > 0
            default:
                return true
        }
    }

    onMounted = (filters: Record<WorkOrderFilter, Filter<WorkOrderFilter>> | null) => {
        if (filters) {
            this.filterOverlayMixin.updateFilters(filters)
            this.filterOverlayMixin.applyFilters()
        } else {
            let filters = this.workOrdersBloc.state.filters
            if (this.workOrdersBloc.state.ignoreUserPresetFilterOnLoad) {
                this.filterOverlayBloc.updateFilters(filters)
                this.workOrdersBloc.state.ignoreUserPresetFilterOnLoad = false
            } else {
                const defaultPreset = this.userPreferencesBloc.state.filterPresets.workOrders.find((it) => it.isDefault)
                filters = defaultPreset
                    ? createFilterGroupFromPreset(defaultPreset, WorkOrderFilterDefinitions)
                    : this.workOrdersBloc.state.filters

                if (defaultPreset) {
                    this.filterOverlayBloc.defaultFilterPresetLoaded(defaultPreset)
                } else {
                    this.filterOverlayBloc.updateFilters(filters)
                }
            }

            this.reloadFiltersData()
            this.workOrdersBloc.fetchData(filters, this.workOrdersBloc.state.sort, this.workOrdersBloc.state.pagination)
        }
        if (this.isMobile()) {
            let selectedMechanic: Contact | undefined = this.companyBloc.state.settings.mechanics.find(
                (i) => i.id == this.userBloc.state.user.personID
            )
            if (selectedMechanic) {
                const updatedFilter = this.workOrdersBloc.createUpdatedFilter(
                    WorkOrderFilter.AssignedTo,
                    [{ value: selectedMechanic.id, label: labelForContact(selectedMechanic) }],
                    FilterUpdateType.Set
                )
                this.workOrdersBloc.applyFiltersAndFetch({ ...this.workOrdersBloc.state.filters, ...updatedFilter })
            }

            this.workOrdersBloc.fetchUserWorkRequests(this.userBloc.state.user.id)
        }
    }

    private reloadFiltersData = () => {
        this.companyBloc.fetchModels()
        this.companyBloc.fetchTypes()
        this.companyBloc.fetchCategories()
        this.companyBloc.fetchMakes()
        this.companyBloc.fetchClasses()
        this.companyBloc.fetchClasses()
        this.companyBloc.fetchSubCompanies()
        this.companyBloc.fetchGroups()
        this.companyBloc.fetchDistricts()
        this.companyBloc.fetchSubDistricts()
        this.companyBloc.fetchUnits()
        this.companyBloc.fetchServiceCodes()
        this.companyBloc.fetchSitesInUse()
        this.companyBloc.fetchContacts()
        this.companyBloc.fetchCbaCodes()
    }

    onResetFiltersClicked = () => {
        this.filterOverlayBloc.resetFilters()
        this.workOrdersBloc.applyFiltersAndFetch(null)
    }
    onExportClicked = () => {
        this.workOrdersBloc.exportData()
    }
    onNextPageClicked = () => this.workOrdersBloc.fetchNextPage()
    onPreviousPageClicked = () => this.workOrdersBloc.fetchPreviousPage()
    onViewAllPagesClicked = () => this.workOrdersBloc.fetchAllPages()
    onResetViewAllClicked = () => this.workOrdersBloc.resetViewAll()
    onPageClicked = (page: number) => this.workOrdersBloc.fetchPage(page)
    onManageColumnsClicked = () => {
        this.workOrdersBloc.manageColumnsColumnsSelectionChanged(
            this.userPreferencesBloc.state.tableSettings.workOrdersTableColumns.columns
        )
        this.workOrdersBloc.manageColumnsModalVisibilityChanged(true)
    }

    onColumnSortClicked = (columnIndex: number) =>
        this.workOrdersBloc.toggleSort(
            this.userPreferencesBloc.state.tableSettings.workOrdersTableColumns.columns[columnIndex]
        )

    onFilterRemoveClicked = (filter: Filter<WorkOrderFilter>) =>
        this.workOrdersBloc.removeFilter(filter.definition.type)

    onViewAllColumnClicked = (row: WorkOrderTableRow) => this.workOrdersBloc.showViewAllColumnsModal(row)
    onViewAllColumnsModalClosed = () => this.workOrdersBloc.hideViewAllColumnsModal()

    onManageColumnsCancel = () => {
        this.workOrdersBloc.manageColumnsModalVisibilityChanged(false)
        this.workOrdersBloc.manageColumnsColumnsSelectionChanged(
            this.userPreferencesBloc.state.tableSettings.workOrdersTableColumns.columns
        )
    }

    onManageColumnsApply = async () => {
        this.workOrdersBloc.manageColumnsModalVisibilityChanged(false)
        const loaderId = this.statusBloc.enqueueInfoMessage("Saving preferences")
        const outcome = await this.userPreferencesBloc.updateWorkOrdersTableColumns(
            this.workOrdersBloc.state.manageColumnsModalSelectedColumns
        )
        if (!Job.isCancelled(outcome) && outcome.isError())
            this.statusBloc.enqueueErrorMessage("Error saving preferences")
        this.statusBloc.hideInfoMessage(loaderId)
    }

    onManageColumnsDefaultsToggled = () => this.workOrdersBloc.manageColumnsDefaultsToggled()

    onManageColumnsColumnCheckChanged = (column: WorkOrderTableColumn, isChecked: boolean) =>
        this.workOrdersBloc.manageColumnsColumnChanged(column, isChecked)

    workOrderToPrintChanged = (workOrderId: number | null) => this.workOrdersBloc.workOrderToPrintChanged(workOrderId)

    workOrderPrintOptionSelected = async (printOption: WorkOrderPrintOption) =>
        this.workOrdersBloc.printWorkOrder(
            printOption,
            this.companyBloc.state.contacts,
            Object.values(this.companyBloc.state.serviceCodes),
            this.companyBloc.state.sites
        )
}
