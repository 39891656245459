import { useBlocCoordinator } from "@lib/bloc/hooks"
import { Color } from "@model/Color"
import { ServiceFormType } from "@model/serviceRequests/ServiceForm"
import { PermissionObject, PermissionType } from "@model/user/User"
import { WorkRequestQuickFilterCount } from "@model/workRequests/WorkRequest"
import { CircleButton } from "@ui/common/buttons/CircleButton"
import { Popover, PopoverItem } from "@ui/common/buttons/Popover"
import { QuickFilters, QuickFiltersActions, QuickFiltersButtons } from "@ui/common/filters/QuickFilters"
import { MultiSelect, MultiSelectTheme } from "@ui/common/form/MultiSelect"
import { LoadingIndicator } from "@ui/common/LoadingIndicator"
import { DI } from "@ui/DI"
import React from "react"

export function WorkRequestsQuickFilters(): JSX.Element {
    const [state, viewModel] = useBlocCoordinator(() => DI.workRequestsQuickFiltersViewModel())
    return (
        <QuickFilters className="work-requests-quick-filters">
            <QuickFiltersButtons>
                <CircleButton bottomLabel="Assets" circleLabel={state.counts[WorkRequestQuickFilterCount.Assets]} />
                <CircleButton
                    bottomLabel="Preventative"
                    isActive={state.isPreventativeToggleActive}
                    circleLabel={state.counts[WorkRequestQuickFilterCount.Preventative]}
                    onClick={() =>
                        viewModel.toggleQuickFilter(
                            WorkRequestQuickFilterCount.Preventative,
                            state.isPreventativeToggleActive
                        )
                    }
                />
                <CircleButton
                    bottomLabel="Inspection"
                    isActive={state.isInspectionToggleActive}
                    circleLabel={state.counts[WorkRequestQuickFilterCount.Inspection]}
                    onClick={() =>
                        viewModel.toggleQuickFilter(
                            WorkRequestQuickFilterCount.Inspection,
                            state.isInspectionToggleActive
                        )
                    }
                />
                <CircleButton
                    bottomLabel="Repair"
                    isActive={state.isServiceToggleActive}
                    circleLabel={state.counts[WorkRequestQuickFilterCount.Service]}
                    onClick={() =>
                        viewModel.toggleQuickFilter(WorkRequestQuickFilterCount.Service, state.isServiceToggleActive)
                    }
                />
                <CircleButton
                    bottomLabel="Overdue"
                    isActive={state.isOverdueToggleActive}
                    circleLabel={state.counts[WorkRequestQuickFilterCount.Overdue]}
                    {...(state.counts[WorkRequestQuickFilterCount.Overdue] > 0 ? { color: Color.Orange } : {})}
                    onClick={() =>
                        viewModel.toggleQuickFilter(WorkRequestQuickFilterCount.Overdue, state.isOverdueToggleActive)
                    }
                />
                <CircleButton
                    bottomLabel="Red Tag"
                    isActive={state.isRedTagToggleActive}
                    circleLabel={state.counts[WorkRequestQuickFilterCount.RedTag]}
                    {...(state.counts[WorkRequestQuickFilterCount.RedTag] > 0 ? { color: Color.Red } : {})}
                    onClick={() =>
                        viewModel.toggleQuickFilter(WorkRequestQuickFilterCount.RedTag, state.isRedTagToggleActive)
                    }
                />
                <CircleButton
                    bottomLabel="Yellow Tag"
                    isActive={state.isYellowTagToggleActive}
                    circleLabel={state.counts[WorkRequestQuickFilterCount.YellowTag]}
                    {...(state.counts[WorkRequestQuickFilterCount.YellowTag] > 0 ? { color: Color.Yellow } : {})}
                    onClick={() =>
                        viewModel.toggleQuickFilter(
                            WorkRequestQuickFilterCount.YellowTag,
                            state.isYellowTagToggleActive
                        )
                    }
                />
                <CircleButton
                    bottomLabel="Immediate"
                    isActive={state.isImmediateToggleActive}
                    circleLabel={state.counts[WorkRequestQuickFilterCount.Immediate]}
                    {...(state.counts[WorkRequestQuickFilterCount.Immediate] > 0 ? { color: Color.Salmon } : {})}
                    onClick={() =>
                        viewModel.toggleQuickFilter(
                            WorkRequestQuickFilterCount.Immediate,
                            state.isImmediateToggleActive
                        )
                    }
                />
            </QuickFiltersButtons>
            <MultiSelect
                theme={MultiSelectTheme.Dark}
                options={[
                    {
                        isChecked: state.isPreventativeToggleActive,
                        label: "Preventative",
                        value: WorkRequestQuickFilterCount.Preventative,
                    },
                    {
                        isChecked: state.isInspectionToggleActive,
                        label: "Inspection",
                        value: WorkRequestQuickFilterCount.Inspection,
                    },
                    {
                        isChecked: state.isServiceToggleActive,
                        label: "Service",
                        value: WorkRequestQuickFilterCount.Service,
                    },
                    {
                        isChecked: state.isOverdueToggleActive,
                        label: "Overdue",
                        value: WorkRequestQuickFilterCount.Overdue,
                    },
                    {
                        isChecked: state.isRedTagToggleActive,
                        label: "Red Tag",
                        value: WorkRequestQuickFilterCount.RedTag,
                    },
                    {
                        isChecked: state.isYellowTagToggleActive,
                        label: "Yellow Tag",
                        value: WorkRequestQuickFilterCount.YellowTag,
                    },
                    {
                        isChecked: state.isImmediateToggleActive,
                        label: "Immediate",
                        value: WorkRequestQuickFilterCount.Immediate,
                    },
                ]}
                onSelectionChanged={(selection) =>
                    viewModel.updateQuickFilterSelection(selection.map((count) => count))
                }
            />
            { viewModel.hasWorkRequestAddPermission() &&
            <QuickFiltersActions>
                <Popover
                    button={({ onClick }) => (
                        <CircleButton
                            className="circle-button--add"
                            bottomLabel="Service"
                            color={Color.Transparent}
                            onClick={onClick}
                            toolTip="Create Repair Request or Service Record"
                        />
                    )}
                >
                    <PopoverItem
                        label="Create Repair Request"
                        onClick={() => viewModel.createServiceButtonClicked(ServiceFormType.Request)}
                    />
                    <PopoverItem
                        label="Create Service Record"
                        onClick={() => viewModel.createServiceButtonClicked(ServiceFormType.Record)}
                    />
                </Popover>
            </QuickFiltersActions>
            }
            <LoadingIndicator isLoading={state.isLoading} backgroundColor={"#191e27bf"} loaderColor="#FFFFFF" />
        </QuickFilters>
    )
}
