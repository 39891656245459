import React from "react"
import { EstimatedCostPDFSection } from "./components/EstimatedCostPDFSection"
import { NotesPDFSection } from "./components/NotesPDFSection"
import { PDFMinEntryHeight, PDFPage, PDFSectionBorder } from "./components/PDF"
import { PDFCheck } from "./components/PDFCheck"
import { PDFHeader } from "./components/PDFHeader"
import { PDFPageHeader } from "./components/PDFPageHeader"
import { PDFHeadedSection } from "./components/PDFSection"
import { WorkRequestStatusPDFSection } from "./components/WorkRequestStatusPDFSection"
import { PDFAsset } from "./models/PDFAsset"
import { isClosed, pdfHeaderTitleForWorkOrder, PDFWorkOrder, PDFWorkOrderStatus } from "./models/PDFWorkOrder"
import { PDFWorkRequest } from "./models/PDFWorkRequest"

export type ServiceWorkRequestPDFProps = {
    workRequest: PDFWorkRequest
    asset: PDFAsset
    workOrder?: PDFWorkOrder
    companyName: string
    companyLogoUrl: string | null
}

export function ServiceWorkRequestPDF({
    asset,
    workOrder,
    companyName,
    workRequest,
    companyLogoUrl,
}: ServiceWorkRequestPDFProps): JSX.Element {
    return (
        <PDFPage>
            <PDFPageHeader title={workOrder ? pdfHeaderTitleForWorkOrder(workOrder) : ""} companyName={companyName} />
            <PDFHeader
                title={`Repair Request`}
                asset={asset}
                workRequest={workRequest}
                workOrder={workOrder}
                showAssignedTo={false}
                showLastKnownLocation={false}
                showSerialNumber={false}
                companyLogoUrl={companyLogoUrl}
            />
            <PDFHeadedSection title={"Complaint"}>{workRequest.workToBePerformed}</PDFHeadedSection>
            <PDFHeadedSection title={"Tasks To Be Performed"}>
                <div style={{ display: "grid", gridTemplateColumns: "auto auto 1fr", gap: 40 }}>
                    <div>All tasks Performed?</div>
                    <div style={{ display: "grid", gap: 4 }}>
                        <PDFCheck label="Yes" />
                        <PDFCheck label="No" />
                    </div>
                    <div style={{ display: "grid", gap: 32, gridTemplateColumns: "auto 1fr", alignItems: "end" }}>
                        <div style={{ lineHeight: 1, fontWeight: 700 }}>Reason:</div>
                        <div style={{ borderBottom: PDFSectionBorder, height: PDFMinEntryHeight }} />
                    </div>
                </div>
            </PDFHeadedSection>
            <EstimatedCostPDFSection workRequest={workRequest} workOrder={workOrder} />
            <WorkRequestStatusPDFSection workRequest={workRequest} workOrder={workOrder} />
            <NotesPDFSection
                text={workOrder  ? workRequest?.originalWorkRequest.notes : undefined} 
            />
        </PDFPage>
    )
}
