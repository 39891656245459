import { Bloc } from "@lib/bloc/Bloc"
import { arrayToObject } from "@lib/TypeUtil"
import { AsyncStatus } from "@model/AsyncStatus"
import { ServiceQuoteQuickFilterCount } from "@model/serviceQuotes/ServiceQuote"
import { ServiceQuoteService } from "@service/serviceQuotes/ServiceQuoteService"

export type ServiceQuotesQuickFiltersState = {
    effectStatus: Record<ServiceQuotesQuickFiltersEffect, AsyncStatus>
    counts: Record<ServiceQuoteQuickFilterCount, number>
    isMobileFilterModalOpen: boolean
}

export enum ServiceQuotesQuickFiltersEffect {
    Fetch = "fetch",
}

export class ServiceQuotesQuickFiltersBloc extends Bloc<ServiceQuotesQuickFiltersState> {
    constructor(private serviceQuotesService: ServiceQuoteService) {
        super(newServiceQuotesQuickFiltersState(), { persistStateOnDispose: true })
    }

    private updateEffectStatus = (effect: ServiceQuotesQuickFiltersEffect, status: AsyncStatus) =>
        this.update({
            effectStatus: {
                ...this.state.effectStatus,
                [effect]: status,
            },
        })

    showMobileFilterModal(isOpen: boolean) {
        this.update({ isMobileFilterModalOpen: isOpen })
    }
}

function newServiceQuotesQuickFiltersState(): ServiceQuotesQuickFiltersState {
    return {
        effectStatus: arrayToObject(Object.values(ServiceQuotesQuickFiltersEffect), (value) => [
            value,
            AsyncStatus.idle(),
        ]),
        counts: arrayToObject(Object.values(ServiceQuoteQuickFilterCount), (it) => [it, 0]),
        isMobileFilterModalOpen: false,
    }
}
