import { classNames } from "@lib/HtmlUtil"
import React, { useCallback, useLayoutEffect, useRef, useState } from "react"
import { CSSTransition } from "react-transition-group"

type LoadingIndicatorProps = {
    isLoading: boolean
    loaderColor?: string
    backgroundColor?: string
    size?: number
    className?: string
    updateOnResize?: boolean
}

export function LoadingIndicatorContainer({ children }: { children?: React.ReactNode }): JSX.Element {
    return <div className={"loading-indicator-cont"}>{children}</div>
}

export function LoadingIndicator({
    isLoading,
    loaderColor = "#F4B824",
    backgroundColor = "rgba(255,255,255,.75)",
    size = 45,
    className,
    updateOnResize = true,
}: LoadingIndicatorProps): JSX.Element {
    const ref = useRef<HTMLDivElement>(null)
    const [top, setTop] = useState(0)

    const recalculateTop = useCallback(() => {
        if (ref.current === null) return

        const { top: boundingRectTop, bottom: boundingRectBottom } = ref.current.getBoundingClientRect()
        const minTop = 20
        const maxTop = ref.current.offsetHeight - size - 20

        const adjustedTop = (() => {
            if (boundingRectTop > 0 && boundingRectBottom < innerHeight) {
                // Full element is in view so just center within the element
                return ref.current.offsetHeight / 2 - size / 2
            } else {
                // // Try to center loader in center of screen
                // const adjustedTop = innerHeight / 2 - size / 2 - boundingRectTop

                // Try to center loader in remaining pixels in viewport
                const pixelsInView = Math.min(boundingRectBottom, innerHeight) - Math.max(boundingRectTop, 0)
                return Math.abs(Math.min(0, boundingRectTop)) + pixelsInView / 2 - size / 2
            }
        })()

        setTop(Math.min(maxTop, Math.max(minTop, adjustedTop)))
    }, [])

    useLayoutEffect(() => {
        if (ref.current === null) return

        recalculateTop()

        if (updateOnResize && isLoading) {
            const resizeObserver = new ResizeObserver(() => recalculateTop())
            resizeObserver.observe(ref.current)
            return () => resizeObserver.disconnect()
        }

        return () => {}
    }, [isLoading, size])

    return (
        <CSSTransition classNames="loading-indicator-" in={isLoading} timeout={250} appear={true} unmountOnExit={true}>
            <div
                ref={ref}
                className={classNames("loading-indicator", className)}
                style={{ backgroundColor: backgroundColor }}
            >
                <div
                    className="loader"
                    style={{
                        top: top,
                        color: loaderColor,
                        width: size,
                        height: size,
                        borderWidth: size / 9,
                    }}
                ></div>
            </div>
        </CSSTransition>
    )
}
