import React from "react"
import { NotesPDFSection } from "./components/NotesPDFSection"
import { PDF, PDFMinEntryHeight, PDFPage, PDFSectionBorder } from "./components/PDF"
import { PDFCheck, PDFCheckDirection } from "./components/PDFCheck"
import { PDFHeader } from "./components/PDFHeader"
import { PDFPageHeader } from "./components/PDFPageHeader"
import { PDFHeadedSection, PDFSection, PDFSectionSubTitle } from "./components/PDFSection"
import { PDFTable, PDFtd, PDFTLabel } from "./components/PDFTable"
import { PDFAsset } from "./models/PDFAsset"
import {
    isClosed,
    labelForWorkOrderStatus,
    pdfHeaderTitleForWorkOrder,
    PDFWorkOrder,
    PDFWorkOrderStatus,
} from "./models/PDFWorkOrder"
import { PDFWorkRequest } from "./models/PDFWorkRequest"
import { Strings } from "./util/Strings"

export type WorkOrderSummaryPDFProps = {
    asset: PDFAsset
    workOrder: PDFWorkOrder
    companyName: string
    companyLogoUrl: string | null
}

export function WorkOrderSummaryPDF({ asset, workOrder, companyName, companyLogoUrl }: WorkOrderSummaryPDFProps): JSX.Element {
    return (
        <PDF>
            <WorkOrderSummaryPage asset={asset} workOrder={workOrder} companyName={companyName} companyLogoUrl={companyLogoUrl} />
        </PDF>
    )
}

export function WorkOrderSummaryPage({ asset, workOrder, companyName, companyLogoUrl }: WorkOrderSummaryPDFProps): JSX.Element {
    //If both red and yellow tags exits, we take red.
    let tagChange = workOrder.workRequests.find((x) => x.originalWorkRequest.tag?.type.toString() === "redTag")
        ?.originalWorkRequest.tag

    if (!tagChange)
        tagChange = workOrder.workRequests.find((x) => x.originalWorkRequest.tag?.type.toString() === "yellowTag")
            ?.originalWorkRequest.tag

    if (!tagChange) workOrder.workRequests.find((x) => x.originalWorkRequest.tag)?.originalWorkRequest.tag

    return (
        <PDFPage>
            <PDFPageHeader title={pdfHeaderTitleForWorkOrder(workOrder)} companyName={companyName} />
            <PDFHeader
                title={`${labelForWorkOrderStatus(workOrder.status)} Work Order: ${workOrder.workOrderNumber}`}
                asset={asset}
                workOrder={workOrder}
                companyLogoUrl={companyLogoUrl}
            />
            <PDFSection
                style={{
                    display: "grid",
                    gridTemplateColumns: "3fr 2fr",
                    gap: "40px",
                    alignItems: "start",
                }}
            >
                <PDFTable style={{ gridTemplateColumns: "auto 1fr auto 1fr" }}>
                    <PDFTLabel>Service Date:</PDFTLabel>
                    <PDFtd style={{ gridColumn: "2 /span 1" }}>
                        {workOrder.originalWorkOrder.workCompletedDate?.toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                            })}
                    </PDFtd>
                    <PDFTLabel>Time:</PDFTLabel>
                    <PDFtd style={{ gridColumn: "4/span 1" }}>
                        {workOrder.originalWorkOrder.workCompletedDate
                                ?.toLocaleDateString("en-US", {
                                    hour: "numeric",
                                    minute: "numeric",
                                })
                                .split(", ")[1]}
                    </PDFtd>
                    <PDFTLabel>Service Location:</PDFTLabel>
                    <PDFtd style={{ gridColumn: "2 /span 3" }}>
                        {workOrder ? workOrder.siteName : undefined}
                    </PDFtd>
                    <PDFTLabel>Work Performed By:</PDFTLabel>
                    <PDFtd style={{ gridColumn: "2 /span 3" }}> {workOrder.assignedTo}</PDFtd>
                </PDFTable>
                <PDFTable style={{ gridTemplateColumns: "auto 1fr" }}>
                    <PDFTLabel>Service Hour Meter:</PDFTLabel>
                    <PDFtd>{(workOrder.originalWorkOrder.completedHourMeter ?? "")}</PDFtd>
                    <PDFTLabel>Service Odometer:</PDFTLabel>
                    <PDFtd>{(workOrder.originalWorkOrder.completedOdometer ?? "")}</PDFtd>
                </PDFTable>
            </PDFSection>
            <PDFHeadedSection title={"Special Instructions"}>{workOrder.specialInstructions}</PDFHeadedSection>
            <WorkRequestSection workRequests={workOrder.workRequests} workOrder={workOrder} />
            <PDFHeadedSection title={"Tag Status"}>
                <div style={{ display: "grid", gridTemplateColumns: "1fr", gap: "16px 0px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "auto 1fr", gap: "4px 40px" }}>
                        <div style={{ fontWeight: 700 }}>Current Tag:</div>
                        <div>{asset.tag}</div>
                        <div style={{ fontWeight: 700 }}>Reason:</div>
                        <div>{asset.tag !== undefined ? asset.tagReason : ""}</div>
                    </div>
                    <div style={{ padding: 8, border: PDFSectionBorder, display: "grid", gap: "8px 0px" }}>
                        <div style={{ fontWeight: 700 }}>Change Tag To:</div>
                        <div
                            style={{
                                display: "grid",
                                gridTemplateColumns: "auto auto auto 1fr",
                                gap: "0px 24px",
                                alignItems: "end",
                            }}
                        >
                            <PDFCheck
                                direction={PDFCheckDirection.Top}
                                checked={
                                    workOrder &&
                                    (tagChange?.type.toString() == "none" || tagChange == null)
                                }
                                label="None"
                            />
                            <PDFCheck
                                direction={PDFCheckDirection.Top}
                                checked={
                                    workOrder &&
                                    tagChange?.type.toString() == "redTag"
                                }
                                label="Red"
                            />
                            <PDFCheck
                                direction={PDFCheckDirection.Top}
                                checked={
                                    workOrder &&
                                    tagChange?.type.toString() == "yellowTag"
                                }
                                label="Yellow"
                            />
                            <div style={{ fontWeight: 700, lineHeight: 1 }}>
                                Reason: <span style={{ fontWeight: 100 }}>{tagChange?.reason}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </PDFHeadedSection>
            {!(workOrder) && <NotesPDFSection />}
        </PDFPage>
    )
}

function WorkRequestSection({
    workRequests,
    workOrder,
}: {
    workRequests: PDFWorkRequest[]
    workOrder: PDFWorkOrder
}): JSX.Element {
    const columns = "1fr minMax(160px, 1fr) 14% 7% 7% 7%"

    return (
        <PDFHeadedSection
            title={
                <div
                    style={{
                        display: "grid",
                        alignItems: "center",
                        gridTemplateColumns: columns,
                        gap: "0px 16px",
                        padding: "0px 8px",
                    }}
                >
                    <div style={{ marginLeft: -8 }}>Repair Request</div>
                    <PDFSectionSubTitle style={{ textAlign: "left", paddingLeft: "17px" }}>Status</PDFSectionSubTitle>
                    <PDFSectionSubTitle>
                        Service
                        <br />
                        Code
                    </PDFSectionSubTitle>
                    <PDFSectionSubTitle style={{ paddingLeft: "7px" }}>
                        Labor
                        <br />
                        Hours
                    </PDFSectionSubTitle>
                    <PDFSectionSubTitle>
                        Labor
                        <br />
                        Cost
                    </PDFSectionSubTitle>
                    <PDFSectionSubTitle style={{ textAlign: "left", paddingLeft: "5px" }}>
                        Parts
                        <br />
                        Cost
                    </PDFSectionSubTitle>
                </div>
            }
        >
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: columns,
                    alignItems: "center",
                    gap: "8px 16px",
                }}
            >
                {workRequests.map((it, i) => (
                    <WorkRequest workRequest={it} workOrder={workOrder} key={i} />
                ))}
            </div>
        </PDFHeadedSection>
    )
}

function WorkRequest({
    workRequest,
    workOrder,
}: {
    workRequest: PDFWorkRequest
    workOrder: PDFWorkOrder
}): JSX.Element {
    return (
        <>
            <div style={{ fontWeight: 600 }}>{workRequest.workToBePerformed}</div>
            <div style={{ display: "block", padding: "0px 10px 7px 10px" }}>
                <PDFCheck
                    checked={
                        workOrder && workRequest.originalWorkRequest.status.toString() === "done"
                    }
                    label="Done"
                />
                <PDFCheck
                    checked={
                        workOrder &&
                        workRequest.originalWorkRequest.status.toString() === "workNotPerformed"
                    }
                    label="Work Not Performed"
                    style={{ paddingTop: "7px" }}
                />
            </div>
            <div style={{ lineHeight: PDFMinEntryHeight, textAlign: "center", borderBottom: PDFSectionBorder }}>
                {workRequest.serviceCode}
            </div>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr 2fr",
                    gridColumn: "4 /span 3",
                    minHeight: PDFMinEntryHeight,
                    border: PDFSectionBorder,
                }}
            >
                <div style={{ borderRight: PDFSectionBorder, padding: "5px" }}>
                    {" " + workRequest.originalWorkRequest.actualLaborHours}
                </div>
                <div style={{ borderRight: PDFSectionBorder, padding: "5px", whiteSpace: "nowrap"  }}>
                    {
                        " $ " + Strings.formatMoney(workRequest.originalWorkRequest.actualLaborCost)}
                </div>
                <div style={{ padding: "5px", whiteSpace: "nowrap"  }}>
                    {
                        " $ " + Strings.formatMoney(workRequest.originalWorkRequest.actualPartsCost)}
                </div>
            </div>
        </>
    )
}
