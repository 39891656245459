import { SortableTableColumnDef, TableColumnAlignment, TableColumnSize } from "@model/tables/Table"
import { ServiceQuotesTableColumn } from "./ServiceQuote"

export const ServiceQuoteTableColumnDefinitions: Record<
    ServiceQuotesTableColumn,
    SortableTableColumnDef<ServiceQuotesTableColumn>
> = {
    [ServiceQuotesTableColumn.ServiceQuote]: {
        type: ServiceQuotesTableColumn.ServiceQuote,
        label: "Service Quote",
        size: TableColumnSize.MinContent,
        mobileSize: TableColumnSize.MinContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: true,
    },
    [ServiceQuotesTableColumn.Asset]: {
        type: ServiceQuotesTableColumn.Asset,
        label: "Asset",
        size: TableColumnSize.FitContent,
        mobileSize: TableColumnSize.MinContent,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: true,
    },
    [ServiceQuotesTableColumn.Vendor]: {
        type: ServiceQuotesTableColumn.Vendor,
        label: "Vendor",
        size: TableColumnSize.FitContent,
        mobileSize: TableColumnSize.MinContent,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: true,
    },
    [ServiceQuotesTableColumn.Contact]: {
        type: ServiceQuotesTableColumn.Contact,
        label: "Contact",
        size: TableColumnSize.FitContent,
        mobileSize: TableColumnSize.FitContentGrow,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: true,
    },
    [ServiceQuotesTableColumn.AssignedTo]: {
        type: ServiceQuotesTableColumn.AssignedTo,
        label: "Assigned To",
        size: TableColumnSize.FitContent,
        mobileSize: TableColumnSize.MinContent,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: false,
    },
    [ServiceQuotesTableColumn.ServiceQuoteStatus]: {
        type: ServiceQuotesTableColumn.ServiceQuoteStatus,
        label: "Status",
        size: TableColumnSize.MinContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: false,
    },
    [ServiceQuotesTableColumn.TotalCost]: {
        type: ServiceQuotesTableColumn.TotalCost,
        label: "Total Cost",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: false,
    },
    [ServiceQuotesTableColumn.DateReceived]: {
        type: ServiceQuotesTableColumn.DateReceived,
        label: "Date Received",
        size: TableColumnSize.MinContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: false,
    },
    [ServiceQuotesTableColumn.LastKnownLocation]: {
        type: ServiceQuotesTableColumn.LastKnownLocation,
        label: "Last Known Location",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: false,
    },
}
