import React from "react"

export enum FormDividerMargin {
    None = "none",
    Small = "small",
    Normal = "normal",
    Large = "large",
}

export function FormDivider({ margin = FormDividerMargin.Normal }: { margin?: FormDividerMargin }): JSX.Element {
    return <div className="form-divider" data-margin={margin}></div>
}
