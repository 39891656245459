import { Bloc } from "@lib/bloc/Bloc"
import { arrayToObject } from "@lib/TypeUtil"
import { AsyncStatus } from "@model/AsyncStatus"
import { WorkRequestQuickFilterCount } from "@model/workRequests/WorkRequest"
import { WorkRequestService } from "@service/workRequests/WorkRequestService"

export type WorkRequestsQuickFiltersState = {
    effectStatus: Record<WorkRequestsQuickFiltersEffect, AsyncStatus>
    counts: Record<WorkRequestQuickFilterCount, number>
}

export enum WorkRequestsQuickFiltersEffect {
    Fetch = "fetch",
}

export class WorkRequestsQuickFiltersBloc extends Bloc<WorkRequestsQuickFiltersState> {
    constructor(private workRequestsService: WorkRequestService) {
        super(newWorkRequestsQuickFiltersState(), { persistStateOnDispose: true })
    }
 
    private updateEffectStatus = (effect: WorkRequestsQuickFiltersEffect, status: AsyncStatus) =>
        this.update({
            effectStatus: {
                ...this.state.effectStatus,
                [effect]: status,
            },
        })

    setIsLoading = (isLoading: boolean) => {
        if (isLoading)
            this.updateEffectStatus(WorkRequestsQuickFiltersEffect.Fetch, AsyncStatus.busy())
        else
            this.updateEffectStatus(WorkRequestsQuickFiltersEffect.Fetch, AsyncStatus.idle())
    }
}

function newWorkRequestsQuickFiltersState(): WorkRequestsQuickFiltersState {
    return {
        effectStatus: arrayToObject(Object.values(WorkRequestsQuickFiltersEffect), (value) => [
            value,
            AsyncStatus.idle(),
        ]),
        counts: {
            [WorkRequestQuickFilterCount.Assets]: 0,
            [WorkRequestQuickFilterCount.Immediate]: 0,
            [WorkRequestQuickFilterCount.Inspection]: 0,
            [WorkRequestQuickFilterCount.Overdue]: 0,
            [WorkRequestQuickFilterCount.Preventative]: 0,
            [WorkRequestQuickFilterCount.RedTag]: 0,
            [WorkRequestQuickFilterCount.Service]: 0,
            [WorkRequestQuickFilterCount.YellowTag]: 0,
            [WorkRequestQuickFilterCount.Upcoming]: 0,
        },
    }
}
