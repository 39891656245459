/**
 * Provides a keydown listener LIFO (last in first out) stack for handling and consuming keyboard events.
 * This is mainly used to properly handle stacked escape button presses to close Filter Overlays, Modals,
 * and MultiSelects (i.e. when a Modal or Filter Overlay contains a MultiSelect).
 *
 * Returning `true` from a listener tells GlobalKeyListener that that event should be considered consumed and not
 * allow the remaining listeners to run.
 */
export class GlobalKeyListener {
    private static listeners: ((ev: KeyboardEvent) => boolean)[] = []

    static init() {
        window.addEventListener("keydown", (ev) => {
            for (let i = this.listeners.length - 1; i >= 0; i--) {
                const eventHandled = this.listeners[i](ev)
                if (eventHandled === true) return
            }
        })
    }

    static enqueue(listener: (ev: KeyboardEvent) => boolean) {
        this.listeners.push(listener)
    }

    static dequeue(listener: (ev: KeyboardEvent) => boolean) {
        const index = this.listeners.indexOf(listener)
        if (index === -1) return
        this.listeners.splice(index, 1)
    }
}
