
import { TableColumnSize } from "@model/tables/Table"
import { labelForWorkRequest, WorkRequest } from "@model/workRequests/WorkRequest"
import { Button, ButtonType } from "@ui/common/buttons/Button"
import { DatePicker, DatePickerTheme, TextInputDatePicker } from "@ui/common/form/DatePicker"
import { FormField, FormFieldLabel } from "@ui/common/form/FormField"
import { Select, SelectTheme } from "@ui/common/form/Select"
import { TextArea } from "@ui/common/form/TextArea"
import { TextInput, TextInputType } from "@ui/common/form/TextInput"
import { Grid, GridCont, GridGap } from "@ui/common/grids/Grid"
import {
    Modal,
    ModalBody,
    ModalButtons,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalSize,
} from "@ui/common/Modal"
import { CollapsibleTable, CollapsibleTableRow } from "@ui/common/tables/CollapsibleTable"
import { DI } from "@ui/DI"
import { xor } from "lodash"
import React, { useEffect, useState } from "react"
import { Color } from "@model/Color"
import { Radio } from "@ui/common/form/Radio"
import { useBlocCoordinator } from "@lib/bloc/hooks"
import { AdjustServiceRequestsViewModel } from "./AdjustServiceRequestsViewModel"
import { AdjustmentAction, AdjustServiceRequestForm } from "@model/workOrders/AdjustServiceRequestsForm"
import { withRouter } from "@lib/router/router"
import { Routes } from "@lib/Routes"
import { CheckBox } from "@ui/common/form/CheckBox"

export function AdjustServiceRequestsModal({
    onClose,
    onAdjustServiceRequestModal,
    workPerformed,    
    scheduleWorkRequests,
    isVisible,
}: {
    onClose: () => void,
    onAdjustServiceRequestModal: (serviceRequestsToManualAdjust: WorkRequest[],
        serviceRequestsToAdvanceStep: WorkRequest[]) => void
    workPerformed: string[],
    scheduleWorkRequests: WorkRequest[],
    isVisible: boolean
}): JSX.Element {
    return (
        <Modal isVisible={isVisible} useContentTag={true} isClosable={false} size={ModalSize.Larger}>
            <AdjustServiceRequestsModalContent  workPerformed={workPerformed} scheduleWorkRequests={scheduleWorkRequests} onClose={onClose} onAdjustServiceRequestModal={onAdjustServiceRequestModal}/>
        </Modal>
    )
}

function AdjustServiceRequestsModalContent({
    onClose,
    onAdjustServiceRequestModal,
    workPerformed,
    scheduleWorkRequests    
}: {
    onClose: () => void,
    onAdjustServiceRequestModal: (serviceRequestsToManualAdjust: WorkRequest[],
        serviceRequestsToAdvanceStep: WorkRequest[]) => void
        workPerformed: string[]    
    scheduleWorkRequests: WorkRequest[]
}): JSX.Element {
    
    const [state, viewModel] = useBlocCoordinator(() => DI.adjustServiceRequestsViewModel())

    useEffect(() => {
         viewModel.setscheduleServiceRequests(scheduleWorkRequests)
         viewModel.setScheduleServiceRequestForms(scheduleWorkRequests)
    }, [])

    if (!state.isSecondStep)
    {
        return (        
            <ModalContent>
                <ModalHeader>
                    <ModalHeaderTitle>{`Adjust Existing Service Requests`}</ModalHeaderTitle>
                </ModalHeader>
                <ModalBody>    
                <Grid columns={1} gap={GridGap.Small}>                    
                    <GridCont>
                        <Grid columns={2}>                                
                            <FormField label="Work performed on this Work Order has been associated to the following Service Request(s).  Would you like to make any adjustments to the Service Request(s)" className="adjust-service-request-form-field">
                                <AdjustServiceRequestsTable viewModel={viewModel} workRequestForms={state.scheduleServiceRequestForms} scheduleWorkRequests={scheduleWorkRequests} />
                            </FormField>
                            <FormField label="Services Performed in this Work Order" className="service-performed-form-field">
                                <TextArea
                                    disabled={true} 
                                    maxLength={2000}
                                    value={workPerformed.map((x) => x + "\n")}
                                    >
                                </TextArea>
                            </FormField>
                        </Grid>
                    </GridCont>
                </Grid>                    
                </ModalBody>
                <ModalButtons>
                    <Button
                        label={state.requiresManualAdjustment ? "Continue to Manual Adjustment Options" : "Apply Adjustments to Selected Service Requests" }
                        type={ButtonType.Contained}
                        onClick={() =>state.requiresManualAdjustment ? viewModel.isSecondStepChanged(true) : onAdjustServiceRequestModal([],viewModel.getServiceRequestToAdvanceStep())}                    
                    />
                </ModalButtons>
            </ModalContent>
        )
    }
    else 
    {
        return (        
            <ModalContent>
                <ModalHeader>
                    <ModalHeaderTitle>{`Adjust Open Service Requests`}</ModalHeaderTitle>
                </ModalHeader>
                <ModalBody>
                <Grid columns={1} gap={GridGap.Small}>
                    <GridCont>
                        <Grid columns={1}>  
                            <FormField label="">
                                {"The following list of existing service request are associated to service performed on this Work Order."}
                                {"Would you like to adjust any of these service requests based on the service performed?"}
                            </FormField>
                        </Grid>
                        <Grid columns={1}>  
                            <FormField label="">
                                <ManualAdjustOpenServiceRequestsTable viewModel={viewModel} workRequestForms={state.scheduleServiceRequestForms} scheduleWorkRequests={scheduleWorkRequests} />
                            </FormField>
                        </Grid>                            
                    </GridCont>
                </Grid>
                </ModalBody>
                <ModalButtons>
                    <Button label="Continue without Adjustment" type={ButtonType.Text} onClick={() => onAdjustServiceRequestModal([],viewModel.getServiceRequestToAdvanceStep())} />
                    <Button
                        label={"Apply Adjustments to Selected Service Requests"}
                        type={ButtonType.Contained}
                        isEnabled={viewModel.filterServiceRequestToManualAdjust().length > 0}
                        onClick={() => onAdjustServiceRequestModal(viewModel.getServiceRequestToManualAdjust(),viewModel.getServiceRequestToAdvanceStep())}
                    />
                </ModalButtons>
            </ModalContent>
        )
    }    
}

function AdjustServiceRequestsTable({
    scheduleWorkRequests,
    workRequestForms,
    viewModel,
}: {
    scheduleWorkRequests: Record<number, WorkRequest>
    workRequestForms: Record<number, AdjustServiceRequestForm>
    viewModel: AdjustServiceRequestsViewModel
}): JSX.Element {
    const head = [
        { size: TableColumnSize.Large, content: ( "Work To Be Performed") },
        { size: TableColumnSize.FitContent, content: <>Advance Schedule<br></br>to Next Step</> },
        { size: TableColumnSize.FitContent, content: <>Manually Adjust<br></br>the Schedule</> },
        { size: TableColumnSize.FitContent, content: <>Make No<br></br>Adjustments</> }
    ]

    const data: CollapsibleTableRow[] = [
        ...Object.values(scheduleWorkRequests).map((it, i) => {
            const form = workRequestForms[it.id]
            if (form === undefined) return { entryId: it.id, columns: [], isCollapsed: true, collapsibleContent: null }

            return {
                entryId: it.id,
                columns: [
                    {                        
                        content: <a href={`/work-requests/asset/${it.assetId.toString()}/${it.id.toString()}`} target="_blank">{labelForWorkRequest(it)}</a>,
                    },
                    {
                        content: (
                            <Radio key={it.id}
                                    isChecked={form.adjustmentAction === AdjustmentAction.AdvanceNextStep}
                                    label=""
                                    onSelected={() => viewModel.requestAdjustmentActionChanged(it.id, AdjustmentAction.AdvanceNextStep)}
                                />                            
                        ),
                    },
                    {
                        content: (
                            <Radio key={it.id}
                                    isChecked={form.adjustmentAction === AdjustmentAction.ManualAdjustment}
                                    label=""
                                    onSelected={() => viewModel.requestAdjustmentActionChanged(it.id, AdjustmentAction.ManualAdjustment)}
                                />                            
                        ),
                    },
                    {
                        content: (
                            <Radio key={it.id}
                                    isChecked={form.adjustmentAction === AdjustmentAction.None}
                                    label=""
                                    onSelected={() =>  viewModel.requestAdjustmentActionChanged(it.id, AdjustmentAction.None)}
                                />                            
                        ),
                    },
                    
                ],
                isCollapsed: true,
            }
        }),
    ]

    return <CollapsibleTable className="work-order-requests-table" head={head} data={data} />
}


function ManualAdjustOpenServiceRequestsTable({
    scheduleWorkRequests,
    workRequestForms,
    viewModel,
}: {
    scheduleWorkRequests: Record<number, WorkRequest>
    workRequestForms: Record<number, AdjustServiceRequestForm>
    viewModel: AdjustServiceRequestsViewModel
}): JSX.Element {
    const head = [
        { size: TableColumnSize.FitContent, content: "Work To Be Performed" },
        { size: TableColumnSize.FitContent, content: "Service Step" },
        { size: TableColumnSize.MinContent, content: <>Current<br></br>Due Hour Meter</> },
        { size: TableColumnSize.MinContent, content: <>Adjusted<br></br>Due Hour Meter</>},
        { size: TableColumnSize.MinContent, content: <>Difference<br></br>Due Hour Meter</>},
        { size: TableColumnSize.MinContent, content: <>Current<br></br>Due Date</>},
        { size: TableColumnSize.MinContent, content: <>Adjusted<br></br>Due Date</>},
        { size: TableColumnSize.MinContent, content: <>Current<br></br>Due Odometer</>},
        { size: TableColumnSize.MinContent, content: <>Adjusted<br></br>Due Odometer</>},
        { size: TableColumnSize.MinContent, content: <>Difference<br></br>Due Odometer</>},
        { size: TableColumnSize.MinContent, content: "" }        
    ]

    const data: CollapsibleTableRow[] = [
        ...Object.values(scheduleWorkRequests).map((it, i) => {
            const form = workRequestForms[it.id]

            if (form === undefined || form.adjustmentAction !== AdjustmentAction.ManualAdjustment) return { entryId: it.id, columns: [], isCollapsed: true, collapsibleContent: null }

            return {
                entryId: it.id,
                columns: [
                    {
                        content: <a href={`/work-requests/asset/${it.assetId.toString()}/${it.id.toString()}`} target="_blank">{labelForWorkRequest(it)}</a>,
                    },
                    {                        
                        content: ( 
                            <Select
                                theme={SelectTheme.Dark}
                                options={
                                    !form.schedule
                                        ? []
                                        : form.schedule.steps
                                              .sort((a, b) => (a.step > b.step ? 1 : b.step > a.step ? -1 : 0))
                                              .map((it) => ({
                                                  label: `${it.label}`,
                                                  value: it.id.toString(),
                                              }))
                                }
                                value={form.step?.id}
                                onChange={(value) => viewModel.requestStepChanged(it.id, value)}
                            />
                        ),
                    },
                    {
                        content: (//Current Due Hour Meter
                            <TextInput
                                type={TextInputType.Float}
                                value={form.dueHourMeter}                                
                                isEnabled={false}
                            />
                        ),
                    },
                    {
                        content: (//Adjusted Due Hour Meter
                            <TextInput
                                type={TextInputType.Float}
                                value={form.adjustedDueHourMeter}
                                min={0}                                
                                onChange={(value) => viewModel.requestAdjustedDueHourMeterChanged(it.id, value)}                                 
                            />
                        ),
                    },
                    {
                        content: (//Difference Due Hour Meter
                            <TextInput
                                type={TextInputType.Float}
                                value={form.differenceDueHourMeter}                                
                                isEnabled={false}
                            />
                        ),
                    },
                    {
                        content: (//Current Due Date
                        <TextInputDatePicker
                            theme={DatePickerTheme.Light}
                            value={form.dueDate}
                            disabled={true}
                        />                            
                        ),
                    },
                    {
                        content: (//Adjusted Due Date
                        <TextInputDatePicker
                            theme={DatePickerTheme.Light}
                            value={form.adjustedDueDate}
                            onChange={(date) => viewModel.requestAdjustedDueDateChanged(it.id,date)}
                        />
                        ),
                    },
                    {
                        content: (//Current Due Odometer
                            <TextInput
                                type={TextInputType.Integer}
                                value={form.dueOdometer}
                                isEnabled={false}
                            />
                        ),
                    },
                    {
                        content: (//Adjusted Due Odometer
                            <TextInput
                                type={TextInputType.Integer}
                                value={form.adjustedDueOdometer}
                                min={0}
                                onChange={(value) => viewModel.requestAdjustedDueOdometerChanged(it.id, value)}                                
                            />
                        ),
                    },
                    {
                        content: (//Difference Due Odometer
                            <TextInput
                                type={TextInputType.Integer}
                                value={form.differenceDueOdometer}
                                isEnabled={false}
                            />
                        ),
                    },
                    {
                        content: (//Adjusted Due Odometer
                            <CheckBox
                                isChecked={form.applyManualAdjustment}
                                onCheckChanged={(value) => viewModel.requestApplyChangeChanged(it.id, value)}
                            />
                        ),
                    },
                ],
                isCollapsed: true,
            }
        }),
    ]

    return <CollapsibleTable className="work-order-requests-table" head={head} data={data} />
}


