import { BlocCoordinator } from "@lib/bloc/BlocCoordinator"
import { Asset } from "@model/assets/Asset"
import { StatusBloc } from "@state/StatusBloc"
import {
    ServiceQuoteVerifyAssetBloc,
    ServiceQuoteVerifyAssetState,
} from "@state/serviceQuotes/ServiceQuoteVerifyAssetBloc"

type Dependencies = [ServiceQuoteVerifyAssetState]
type StateSelection = ServiceQuoteVerifyAssetState & {
    afterSaveDelay: number
}

export class ServiceQuoteVerifyAssetViewModel extends BlocCoordinator<Dependencies, StateSelection> {
    constructor(private serviceQuoteVerifyAssetBloc: ServiceQuoteVerifyAssetBloc, private statusBloc: StatusBloc) {
        super([serviceQuoteVerifyAssetBloc])
    }

    protected transform = ([serviceQuoteVerifyAssetState]: Dependencies): StateSelection => ({
        ...serviceQuoteVerifyAssetState,
        afterSaveDelay: 5000,
    })

    cancel = () => {
        this.serviceQuoteVerifyAssetBloc.hideModalAndResetForm()
    }

    searchForAssets = (search: string) => this.serviceQuoteVerifyAssetBloc.searchAssets(search)
    selectedAssetChanged = (asset: Asset | null) => this.serviceQuoteVerifyAssetBloc.selectedAssetChanged(asset)
    verifyAssetClicked = () => {
        this.serviceQuoteVerifyAssetBloc.showConfirmVerifyDialogChanged(false)
        const message = this.statusBloc.enqueueInfoMessage("Saving...")
        this.serviceQuoteVerifyAssetBloc.verifyAssetClicked()
        this.statusBloc.hideInfoMessage(message)
        this.serviceQuoteVerifyAssetBloc.hideModalAndResetForm()
    }
    showSearchAssetsInput = (showSearchAssetsInput: boolean) =>
        this.serviceQuoteVerifyAssetBloc.showSearchAssetsInputChanged(showSearchAssetsInput)
    showConfirmVerifyDialog = () => this.serviceQuoteVerifyAssetBloc.showConfirmVerifyDialogChanged(true)
    hideConfirmVerifyDialog = () => this.serviceQuoteVerifyAssetBloc.showConfirmVerifyDialogChanged(false)
}
