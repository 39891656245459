import { classNames } from "@lib/HtmlUtil"
import React from "react"

export enum GridGap {
    Small = "small",
    Normal = "normal",
    Medium = "medium",
    Large = "large",
}

export enum GridJustification {
    Start = "start",
    Center = "center",
    End = "end",
    Stretch = "stretch",
}

export function GridCont({
    children,
    className,
    gap = GridGap.Normal,
}: {
    children?: React.ReactNode
    className?: string
    gap?: GridGap
}): JSX.Element {
    return (
        <div className={classNames("grid-cont", className)} data-gap={gap}>
            {children}
        </div>
    )
}

export type GridProps = {
    children?: React.ReactNode
    className?: string
    columns?: number
    columnsMd?: number
    columnsSm?: number
    gap?: GridGap
    justifyItems?: GridJustification
    style?: React.CSSProperties
}

export function Grid({
    children,
    className,
    columns = 1,
    columnsMd,
    columnsSm,
    gap = GridGap.Normal,
    justifyItems,
    style,
}: GridProps): JSX.Element {
    return (
        <div
            className={classNames("grid", className)}
            data-columns={columns}
            {...(columnsMd ? { "data-columns-md": columnsMd } : {})}
            {...(columnsSm ? { "data-columns-sm": columnsSm } : {})}
            {...(justifyItems ? { "data-justify-items": justifyItems } : undefined)}
            data-gap={gap}
            style={style}
        >
            {children}
        </div>
    )
}
