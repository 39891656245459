import { useLastNonNull } from "@lib/Hooks"
import { WorkOrder } from "@model/workOrders/WorkOrder"
import { Button, ButtonType } from "@ui/common/buttons/Button"
import { Grid } from "@ui/common/grids/Grid"
import { Modal, ModalBody, ModalButtons, ModalContent, ModalHeader, ModalHeaderTitle, ModalSize } from "@ui/common/Modal"
import React from "react"
import { labelForWorkRequest, WorkRequest } from "@model/workRequests/WorkRequest"
import { Color } from "@model/Color"
import { Table } from "../common/tables/Table"
import { TableColumnAlignment, TableColumnSize } from "@model/tables/Table"
import { FormDivider, FormDividerMargin } from "../common/form/FormDivider"
import { FormFieldLabel } from "../common/form/FormField"
import { MeterSyncRecord } from "@model/assets/MeterSyncRecord"
import { Link } from "@lib/router/components"
import { Routes } from "@lib/Routes"
import { safeParseFloat, safeParseInt } from "@lib/TypeUtil"


export function RollbackClosedWorkOrderModal({
    onCancel,
    onConfirm,
    ...props
}: {
    data: { workOrder: WorkOrder; workRequests: WorkRequest[], meterSyncRecord: MeterSyncRecord[] } | null
    hasRollbackWorkOrderPermission: boolean
    canPerformRollBack: boolean
    onCancel: () => void
    onConfirm: () => void
    isVisible: boolean
    isLoading: boolean
}): JSX.Element {
    const data = useLastNonNull(props.data)
    return (
        <Modal isVisible={props.isVisible} useContentTag={true} onClose={onCancel} size={ModalSize.Medium}>
            <ModalContent isLoading={props.isLoading}>
                <ModalHeader>
                    <ModalHeaderTitle>Rollback Work Order</ModalHeaderTitle>
                </ModalHeader>
                <ModalBody>
                    <Grid columns={1}>
                        <div style={{ minHeight: 173 }}>
                            {data?.workRequests && data?.workRequests?.length > 0 && (
                                <>
                                    The following Service Requests will be deleted as part of the rollback.
                                    <FormFieldLabel style={{ marginTop: "10px" }}>Work to be     Performed</FormFieldLabel>
                                    <ul className="ul-list-for-modal" style={{ margin: "10px 30px" }}>
                                        {data?.workRequests?.map((workRequest, i) => (
                                            <li key={'wrmk_' + i.toString()}>
                                                {!workRequest.workOrderId && (
                                                    <Link style={{ minHeight: 0 }}
                                                        target="_blank"
                                                        className="table-cell--work-requests-name-link"
                                                        to={Routes.WorkRequest(workRequest.assetId.toString(), workRequest.id.toString())}
                                                    >
                                                        {labelForWorkRequest(workRequest)}
                                                    </Link>
                                                )}
                                                {workRequest.workOrderId && (
                                                    <a style={{ minHeight: 0 }}
                                                        className="table-cell--work-requests-name-link"
                                                        target="_blank"
                                                        href={window.location.origin + "/work-orders/" + workRequest.workOrderId.toString()}
                                                    >
                                                        {labelForWorkRequest(workRequest)}
                                                    </a>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                    <FormDivider margin={FormDividerMargin.Small} />
                                </>
                            )}
                            {!props.isLoading && props.canPerformRollBack && (
                                <>
                                    The meter sync performed as part of the targeted Work Order will be undone.
                                    <Table
                                        className={"page--asset-work-requests--multi-table"}
                                        head={[
                                            {
                                                content: <>Hour Meter<br></br>Before Rollback</>, size: TableColumnSize.FitContentGrow, alignment: TableColumnAlignment.Center
                                            },
                                            { content: <>Hour Meter<br></br>After Rollback</>, size: TableColumnSize.FitContentGrow, alignment: TableColumnAlignment.Center },
                                            { content: "Change", size: TableColumnSize.FitContentGrow, alignment: TableColumnAlignment.Center },
                                            { content: <>Odometer<br></br>Before Rollback</>, size: TableColumnSize.FitContentGrow, alignment: TableColumnAlignment.Center },
                                            { content: <>Odometer<br></br>After Rollback</>, size: TableColumnSize.FitContentGrow, alignment: TableColumnAlignment.Center },
                                            { content: "Change", size: TableColumnSize.FitContentGrow, alignment: TableColumnAlignment.Center },
                                        ]}
                                        data={data!.meterSyncRecord.map((meterSyncRecord) => ({
                                            entryId: meterSyncRecord.meterSyncRecordId,
                                            columns: [
                                                {
                                                    content: safeParseFloat(meterSyncRecord.newHourmeter).toFixed(1).toString(),
                                                    alignment: TableColumnAlignment.Center,
                                                },
                                                {
                                                    className: "manage-filters-preset-name",
                                                    content: safeParseFloat(meterSyncRecord.oldHourMeter).toFixed(1).toString(),                                                    
                                                    alignment: TableColumnAlignment.Center,
                                                },
                                                {
                                                    content: ((meterSyncRecord.oldHourMeter ?? 0) - (meterSyncRecord.newHourmeter ?? 0)).toFixed(1).toString(),
                                                    alignment: TableColumnAlignment.Center,
                                                },
                                                {
                                                    className: "manage-filters-preset-name",
                                                    content: safeParseInt(meterSyncRecord.oldOdometer).toString(),
                                                    alignment: TableColumnAlignment.Center,
                                                },
                                                {
                                                    content: safeParseInt(meterSyncRecord.newOdometer).toString(),
                                                    alignment: TableColumnAlignment.Center,
                                                },
                                                {
                                                    className: "manage-filters-preset-name",
                                                    content: ((meterSyncRecord.oldOdometer ?? 0) - (meterSyncRecord.newOdometer ?? 0)).toFixed().toString(),
                                                    alignment: TableColumnAlignment.Center,
                                                },
                                            ],
                                        }))}
                                    />
                                </>
                            )}
                            {!props.isLoading && !props.canPerformRollBack && (
                                <>
                                    This Work Order cannot be rolled back due to one of the following:
                                    <ul className="ul-list-for-modal" style={{ marginTop: 25 }}>
                                        <li>
                                            This asset has a more recently closed Work Order
                                        </li>
                                        <li>
                                            This asset has had a meter sync performed since this Work Order was closed
                                        </li>
                                        <li>
                                            This asset currently has a non-Closed Work Order
                                        </li>
                                    </ul>
                                </>
                            )}
                        </div>
                    </Grid>
                </ModalBody>
                <ModalButtons>
                    <Button label="Cancel" type={ButtonType.Text} onClick={onCancel} />
                    <Button
                        label="Rollback Work Order"
                        isEnabled={!props.isLoading && props.canPerformRollBack}
                        onClick={() => onConfirm()}
                    />
                </ModalButtons>
            </ModalContent>
        </Modal>
    )
}
