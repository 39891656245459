import { arrayToObject, isString, numOrDefault } from "@lib/TypeUtil"
import { DashboardFilter } from "@model/dashboard/DashboardFilter"
import { Filter, isEmptyFilterValue, numberArrayFilterValue, textFilterValue } from "@model/filters/Filter"
import { WorkOrderStatus } from "@model/workOrders/WorkOrder"
import { workRequestTypeForValue } from "@model/workRequests/WorkRequest"
import { ApiService, HttpMethod } from "@service/ApiService"
import { CompanyFieldIdMapper } from "@service/company/CompanyService"
import { RedYellowTagsWidgetDataItem, WidgetService } from "./WidgetService"

export class MainWidgetService implements WidgetService {
    constructor(private apiService: ApiService, private idMapper: CompanyFieldIdMapper) {}

    fetchStatusWidgets(): void {
        throw new Error("Method not implemented.")
    }

    async fetchExistingWorkOrderStatuses(filters: Filter<DashboardFilter>[]): Promise<Record<WorkOrderStatus, number>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/Maintenance/ExistingWorkOrderStatus",
            body: this.createDashboardFilterBody(filters),
        })
        if (response.isError() || !ApiService.isValidObjectResponse(response)) {
            return arrayToObject(Object.values(WorkOrderStatus), (it) => [it, 0])
        }

        return arrayToObject(Object.values(WorkOrderStatus), (it) => [
            it,
            numOrDefault((response.value as { [key: string]: any })?.[workOrderStatusToApi(it)], 0),
        ])
    }

    async fetchWorkRequestsCompletedByMonth(filters: Filter<DashboardFilter>[]): Promise<number[]> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/Maintenance/WorkRequestCompletedByMonth",
            body: this.createDashboardFilterBody(filters),
        })

        if (response.isError() || !ApiService.isValidObjectResponse(response)) {
            return []
        }
        return workRequestsCompletedByMonthFromApi(response.value)
    }

    async fetchRedYellowTagsByMonth(filters: Filter<DashboardFilter>[]): Promise<RedYellowTagsWidgetDataItem[]> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/Maintenance/RedYellowTagHistory",
            body: this.createDashboardFilterBody(filters),
        })
        if (response.isError() || !ApiService.isValidObjectResponse(response)) {
            return []
        }
        return redYellowTagsByMonthFromApi(response.value)
    }

    private createDashboardFilterBody(filters: Filter<DashboardFilter>[]): any {
        const filterBody: Record<string, any> = {}

        filters?.forEach((filter) => {
            if (isEmptyFilterValue(filter)) return

            switch (filter.definition.type) {
                // Asset Assignment
                case DashboardFilter.SubCompany:
                    filterBody.companyId = numberArrayFilterValue(filter)
                    break
                case DashboardFilter.District:
                    filterBody.districtId = Array.isArray(filterBody.districtId)
                        ? [...filterBody.districtId, ...numberArrayFilterValue(filter)]
                        : numberArrayFilterValue(filter)
                    break
                case DashboardFilter.SubDistrict:
                    filterBody.districtId = Array.isArray(filterBody.districtId)
                        ? [...filterBody.districtId, ...numberArrayFilterValue(filter)]
                        : numberArrayFilterValue(filter)
                    break
                case DashboardFilter.Unit:
                    filterBody.districtId = Array.isArray(filterBody.districtId)
                        ? [...filterBody.districtId, ...numberArrayFilterValue(filter)]
                        : numberArrayFilterValue(filter)
                    break
                case DashboardFilter.Group:
                    filterBody.groupId = numberArrayFilterValue(filter)
                    break
                case DashboardFilter.Site:
                    filterBody.siteId = numberArrayFilterValue(filter)
                    break

                // Asset Properties
                case DashboardFilter.AssetType:
                    filterBody.typeId = numberArrayFilterValue(filter)
                    break
                case DashboardFilter.Category:
                    filterBody.categoryId = numberArrayFilterValue(filter)
                    break
                case DashboardFilter.Class:
                    filterBody.classId = numberArrayFilterValue(filter)
                    break
                case DashboardFilter.Make:
                    filterBody.assetMakeId = numberArrayFilterValue(filter)
                    break
                case DashboardFilter.Model:
                    filterBody.assetModelId = numberArrayFilterValue(filter)
                    break

                // Work Request
                case DashboardFilter.WorkType:
                    filterBody.serviceTypeId = filter.values
                        .map((it) => it.value)
                        .flatMap((it) => {
                            if (!isString(it)) return []
                            return this.idMapper.fromWorkRequestType(workRequestTypeForValue(it))
                        })
                    break

                // Work Order
                case DashboardFilter.AssignedTo:
                    filterBody.mechanics = numberArrayFilterValue(filter)
                    break
                // Work Order Status Widget
                case DashboardFilter.MechanicTypes:
                    filterBody.mechanicTypes = numberArrayFilterValue(filter)
                    break
                // Search
                case DashboardFilter.Search:
                    filterBody.globalSearchQuery = textFilterValue(filter)
                    break
            }
        })

        return filterBody
    }
}

function workRequestsCompletedByMonthFromApi(obj: any): number[] {
    if (!Array.isArray(obj.workRequestCompletedByMonth)) return []
    return obj.workRequestCompletedByMonth.map((it: any) => numOrDefault(it, 0))
}

function redYellowTagsByMonthFromApi(obj: any): RedYellowTagsWidgetDataItem[] {
    if (!Array.isArray(obj.redTags) || !Array.isArray(obj.yellowTags) || obj.redTags.length !== obj.yellowTags.length)
        return []
    return obj.redTags.map(
        (it: any, i: number) =>
            ({
                redTags: numOrDefault(it, 0),
                yellowTags: numOrDefault(obj.yellowTags[i], 0),
            } as RedYellowTagsWidgetDataItem)
    )
}

function workOrderStatusToApi(status: WorkOrderStatus): string {
    switch (status) {
        case WorkOrderStatus.Pending:
            return "pending"
        case WorkOrderStatus.Open:
            return "open"
        case WorkOrderStatus.InProgress:
            return "inProgress"
        case WorkOrderStatus.WorkCompleted:
            return "workCompleted"
        default:
            return ""
    }
}
