import ReactDOM from "react-dom"
import React from "react"
import { App } from "@ui/app/App"
import { withRouter } from "@lib/router/router"
import { Routes } from "@lib/Routes"

import "@static/styles/main.scss"

withRouter((router) => router.init(Routes))

ReactDOM.render(<App />, document.getElementById("root"))
