import { BlocCoordinator } from "@lib/bloc/BlocCoordinator"
import { compare, SortOrder } from "@lib/Comparable"
import { AssetCategory } from "@model/assets/AssetCategory"
import { AssetClass } from "@model/assets/AssetClass"
import { AssetMake } from "@model/assets/AssetMake"
import { AssetModel } from "@model/assets/AssetModel"
import { AssetType } from "@model/assets/AssetType"
import { Company } from "@model/company/Company"
import { CompanyAlias } from "@model/company/CompanyAlias"
import { Contact } from "@model/contacts/Contact"
import { District } from "@model/district/District"
import { Filter, FilterValue, isFilterEmptyOrContainsValue } from "@model/filters/Filter"
import { FilterPreset, FilterPresetForm, FilterPresetType } from "@model/filters/FilterPresets"
import { Group } from "@model/group/Group"
import { ServiceQuoteFilter, ServiceQuoteVendor } from "@model/serviceQuotes/ServiceQuote"
import { labelForServiceCode, ServiceCode, ServiceSchedule } from "@model/serviceRequests/ServiceRequest"
import { Site } from "@model/site/Site"
import { CompanyBloc, CompanyState } from "@state/company/CompanyBloc"
import {
    FilterOverlayBlocCoordinator,
    FilterOverlayBlocCoordinatorMixin,
} from "@state/filters/FilterOverlayBlocCoordinatorMixin"
import {
    ServiceQuotesFilterOverlayBloc,
    ServiceQuotesFilterOverlayState,
} from "@state/serviceQuotes/ServiceQuotesFilterOverlayBloc"
import { ServiceQuotesTableBloc } from "@state/serviceQuotes/ServiceQuotesTableBloc"
import { StatusBloc } from "@state/StatusBloc"
import { UserPreferencesBloc, UserPreferencesState } from "@state/UserPreferencesBloc"

type Dependencies = [ServiceQuotesFilterOverlayState, UserPreferencesState, CompanyState]

type StateSelection = ServiceQuotesFilterOverlayState & {
    aliases: Record<CompanyAlias, string>
    parentCompany: Company
    subCompanies: Company[]
    makes: AssetMake[]
    models: AssetModel[]
    types: AssetType[]
    categories: AssetCategory[]
    classes: AssetClass[]
    mechanics: Contact[] //used for assigned to
    districts: District[]
    subDistricts: District[]
    units: District[]
    groups: Group[]
    sites: Site[]
    filterPresets: FilterPreset<ServiceQuoteFilter>[]
    serviceCodes: ServiceCode[]
    serviceSchedules: ServiceSchedule[]
    vendor: ServiceQuoteVendor[]
}

export class ServiceQuotesFilterOverlayViewModel
    extends BlocCoordinator<Dependencies, StateSelection>
    implements FilterOverlayBlocCoordinator<ServiceQuoteFilter>
{
    private filterOverlayMixin: FilterOverlayBlocCoordinatorMixin<ServiceQuoteFilter>

    constructor(
        filterOverlayBloc: ServiceQuotesFilterOverlayBloc,
        userPreferencesBloc: UserPreferencesBloc,
        companyBloc: CompanyBloc,
        serviceQuotesTableBloc: ServiceQuotesTableBloc,
        statusBloc: StatusBloc
    ) {
        super([filterOverlayBloc, userPreferencesBloc, companyBloc])

        this.filterOverlayMixin = new FilterOverlayBlocCoordinatorMixin(
            FilterPresetType.ServiceQuote,
            filterOverlayBloc,
            serviceQuotesTableBloc,
            userPreferencesBloc,
            statusBloc
        )
    }

    transform = ([filterOverlayState, userPreferencesState, companyState]: Dependencies): StateSelection => {
        const filteredDistricts = companyState.districts.filter((it) =>
            isFilterEmptyOrContainsValue(filterOverlayState.filters[ServiceQuoteFilter.SubCompany], [it.companyId])
        )

        const filteredSubDistricts = companyState.subDistricts.filter(
            (it) =>
                isFilterEmptyOrContainsValue(filterOverlayState.filters[ServiceQuoteFilter.SubCompany], [
                    it.companyId,
                ]) &&
                isFilterEmptyOrContainsValue(filterOverlayState.filters[ServiceQuoteFilter.District], [
                    it.parentId ?? 0,
                ])
        )

        const filteredUnits = companyState.units.filter(
            (it) =>
                filteredSubDistricts.find((subDistrict) => subDistrict.id === it.parentId) !== undefined &&
                isFilterEmptyOrContainsValue(filterOverlayState.filters[ServiceQuoteFilter.SubDistrict], [
                    it.parentId ?? 0,
                ])
        )

        return {
            ...filterOverlayState,
            aliases: companyState.aliases,
            parentCompany: {
                id: companyState.companyId,
                name: companyState.companyName,
                parentId: -1,
                logoUrl: companyState.companyLogoUrl,
            },
            subCompanies: companyState.subCompanies,
            sites: companyState.sites,
            districts: filteredDistricts,
            subDistricts: filteredSubDistricts,
            units: filteredUnits,
            groups: companyState.groups.filter((it) =>
                isFilterEmptyOrContainsValue(filterOverlayState.filters[ServiceQuoteFilter.SubCompany], [it.companyId])
            ),
            makes: companyState.assetMakes,
            models: companyState.assetModels.filter((it) =>
                isFilterEmptyOrContainsValue(filterOverlayState.filters[ServiceQuoteFilter.Make], [it.makeId])
            ),
            types: companyState.assetTypes,
            categories: companyState.assetCategories.filter((it) =>
                isFilterEmptyOrContainsValue(filterOverlayState.filters[ServiceQuoteFilter.AssetType], [it.typeId])
            ),
            classes: companyState.assetClasses.filter((it) =>
                isFilterEmptyOrContainsValue(filterOverlayState.filters[ServiceQuoteFilter.Category], [it.categoryId])
            ),
            mechanics: companyState.settings.mechanics,
            serviceCodes: Object.values(companyState.serviceCodes)
                .filter((it) => it.associatedWithWorkOrder)
                .sort((a, b) => compare(labelForServiceCode(a), labelForServiceCode(b), SortOrder.Asc)),
            serviceSchedules: Object.values(companyState.serviceSchedules)
                .filter((it) => it.associatedWithWorkRequest)
                .sort((a, b) => compare(a.name, b.name, SortOrder.Asc)),
            preset: filterOverlayState.preset,
            filterPresets: userPreferencesState.filterPresets.serviceQuotes.sort((a, b) =>
                compare(a.name, b.name, SortOrder.Asc)
            ),
            vendor: companyState.vendors.filter((it) =>
                isFilterEmptyOrContainsValue(filterOverlayState.filters[ServiceQuoteFilter.VendorID], [it.id])
            ),
        }
    }

    toggleOverlayVisibility = () => this.filterOverlayMixin.toggleOverlayVisibility()

    updateFilters = (filters: Record<ServiceQuoteFilter, Filter<ServiceQuoteFilter>>) =>
        this.filterOverlayMixin.updateFilters(filters)

    updateFilter = (type: ServiceQuoteFilter, value: FilterValue[]) => this.filterOverlayMixin.updateFilter(type, value)

    resetFilters = () => this.filterOverlayMixin.resetFilters()

    applyFilters = async () => this.filterOverlayMixin.applyFilters()

    filterPresetSelected = (id: number) => this.filterOverlayMixin.filterPresetSelected(id)

    showCreatePreset = () => this.filterOverlayMixin.showCreatePreset()

    hideCreatePreset = () => this.filterOverlayMixin.hideCreatePreset()

    createPreset = (form: FilterPresetForm) => this.filterOverlayMixin.createPreset(form)

    updatePresetFilters = () => this.filterOverlayMixin.updatePresetFilters()

    updatePresetForm = (preset: FilterPreset<ServiceQuoteFilter>, form: FilterPresetForm) =>
        this.filterOverlayMixin.updatePresetForm(preset, form)

    deletePreset = (preset: FilterPreset<ServiceQuoteFilter>) => this.filterOverlayMixin.deletePreset(preset)

    showManagePresets = () => this.filterOverlayMixin.showManagePresets()

    hideManagePresets = () => this.filterOverlayMixin.hideManagePresets()

    isSearchFilterValid = () => this.filterOverlayMixin.isSearchFilterValid()

    isSecondaryFilterValid = () => this.filterOverlayMixin.isSecondaryFilterValid()
}
