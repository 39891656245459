import { useBlocCoordinator } from "@lib/bloc/hooks"
import { useIsInIframe, useIsMobile } from "@lib/Hooks"
import { classNames } from "@lib/HtmlUtil"
import { Strings } from "@lib/Strings"
import { safeParseInt } from "@lib/TypeUtil"
import { Asset, labelForAsset } from "@model/assets/Asset"
import { Contact, labelForContact } from "@model/contacts/Contact"
import { ServiceFormType, ServiceRecordForm, ServiceRequestForm } from "@model/serviceRequests/ServiceForm"
import {
    labelForServiceCode,
    labelForServiceRequestType,
    ServiceCode,
    ServiceRequestType,
    serviceRequestTypeForValue,
    ServiceSubType,
} from "@model/serviceRequests/ServiceRequest"
import { Site } from "@model/site/Site"
import { labelForUrgency, Urgency, urgencyForValue } from "@model/Urgency"
import { labelForWorkOrderStatus, WorkOrderStatus, workOrderStatusForValue } from "@model/workOrders/WorkOrder"
import { Button, ButtonType } from "@ui/common/buttons/Button"
import { DataField } from "@ui/common/DataField"
import { AttachmentList } from "@ui/common/form/AttachmentList"
import { CheckBox } from "@ui/common/form/CheckBox"
import { DatePicker, DateTimePicker, TextInputDatePicker, TextInputDateTimePicker } from "@ui/common/form/DatePicker"
import { FormField, FormFieldDirection, FormFieldNote, FormFieldNoteType } from "@ui/common/form/FormField"
import { MultiSelect } from "@ui/common/form/MultiSelect"
import { SearchableTextInput } from "@ui/common/form/SearchableTextInput"
import { Select } from "@ui/common/form/Select"
import { TagFormField } from "@ui/common/form/TagFormField"
import { TextArea } from "@ui/common/form/TextArea"
import { TextInput, TextInputType } from "@ui/common/form/TextInput"
import { Grid, GridCont } from "@ui/common/grids/Grid"
import {
    Modal,
    ModalBody,
    ModalButtons,
    ModalContent,
    ModalHeader,
    ModalHeaderPreTitle,
    ModalHeaderSubTitle,
    ModalHeaderTitle,
    ModalSize,
} from "@ui/common/Modal"
import { DI } from "@ui/DI"
import React, { useEffect } from "react"
import { useRoute } from "@lib/router/hooks"
import { ServiceRequestCreationViewModel } from "./ServiceRequestCreationViewModel"
import { isRouteMatch } from "@lib/router/route"
import { Routes } from "@lib/Routes"
import { TagType } from "@model/Tag"
import { PermissionObject, PermissionType, User } from "@model/user/User"

export type ServiceRequestCreationModalProps = {
    onClose?: () => void
    parentWorkOrderId?: number
    asset?: Asset
    alwaysOpen?: boolean
}

export function ServiceRequestCreationModal(props: ServiceRequestCreationModalProps): JSX.Element {
    const route = useRoute()
    const isCreateServiceRequestRoute = isRouteMatch(route, Routes.CreateServiceRequest)
    const isCreateServiceRecordRoute = isRouteMatch(route, Routes.CreateServiceRecord)
    const isServiceRecordRoute = isRouteMatch(route, Routes.ServiceRecord)
    const [state, viewModel] = useBlocCoordinator(() => DI.serviceRequestCreationViewModel())
    const { assetId } = route.data.params
    const isMobile = useIsMobile() || useIsInIframe()
    const isFollowUpServiceRequest = state.followUpWorkRequest != undefined

    let auxTitle = `Create ${state.formType === ServiceFormType.Request ? "Repair Request" : "Service Record"}`
    if (isFollowUpServiceRequest) {
        auxTitle = `Follow Up ${state.formType === ServiceFormType.Request ? "Repair Request" : "Service Record"}`
    }
    //if (props.parentWorkOrderId) auxTitle += ` for Work Order ${props.parentWorkOrderId}`

    const title = auxTitle

    var OnMount= () => {
        if (props.parentWorkOrderId) state.requestForm.workOrderId = props.parentWorkOrderId
        if (props.asset) {
            state.selectedAsset = props.asset
            viewModel.selectedAssetChanged(props.asset)
        }

        if ((props.alwaysOpen && !isServiceRecordRoute) || isCreateServiceRequestRoute) {
            viewModel.showModal(ServiceFormType.Request)
        } else if (isCreateServiceRecordRoute || isServiceRecordRoute) {
            viewModel.showModal(ServiceFormType.Record)
        }
        if (assetId && safeParseInt(assetId) != 0) {
            viewModel.loadDefaultSelectedAsset(safeParseInt(assetId))
        }

        if (route.data.query?.caller) {
            viewModel.callerFromMobileAppChanged(route.data.query.caller)
        }

    }

    useEffect(() => {
        OnMount()
    }, [])


    useEffect(() => {
        OnMount()
    }, [props.asset])

    return (
        <div>
            {state.isModalVisible && (
                <Modal
                    className={classNames(props.alwaysOpen ? "service-request-creation-modal--alwaysOpen" : null)}
                    isVisible={state.isModalVisible}
                    useContentTag={true}
                    onClose={() => {
                        cancel(props, viewModel, state, isCreateServiceRequestRoute || isCreateServiceRecordRoute)
                    }}
                    size={props.alwaysOpen ? ModalSize.Full : ModalSize.Large}
                    isClosable={!props.alwaysOpen != undefined ? true : undefined}
                >
                    <ModalContent>
                        <ModalHeader>
                            {isFollowUpServiceRequest && <ModalHeaderPreTitle>Service</ModalHeaderPreTitle>}
                            <ModalHeaderTitle>{title}</ModalHeaderTitle>
                            {isFollowUpServiceRequest && (
                                <ModalHeaderSubTitle>{labelForAsset(state.selectedAsset)}</ModalHeaderSubTitle>
                            )}
                        </ModalHeader>
                        <ModalBody>
                            <GridCont>
                                {!isFollowUpServiceRequest && (
                                    <>
                                        <Grid columns={2}>
                                            <FormField label="Asset ID, VIN, or Serial*">
                                                <SearchableTextInput
                                                    options={state.availableAssets.map((asset) => ({
                                                        label: labelForAsset(asset),
                                                        value: asset,
                                                        key: asset.id,
                                                    }))}
                                                    isLoading={state.isSearchingForAssets}
                                                    value={state.selectedAsset?.label ?? ""}
                                                    emptyMessage={"No assets found"}
                                                    debounceMillis={250}
                                                    minSearchCharacters={3}
                                                    onSearchChanged={(search) => viewModel.searchForAssets(search)}
                                                    onOptionSelected={(option) =>
                                                        viewModel.selectedAssetChanged(option.value)
                                                    }
                                                    isEnabled={props.asset === undefined}
                                                />
                                            </FormField>
                                            <FormField className="modal-save-buttons-top">
                                                <Button
                                                    type={ButtonType.Text}
                                                    label={props.alwaysOpen ? "Clear" : "Cancel"}
                                                    onClick={() => {
                                                        cancel(
                                                            props,
                                                            viewModel,
                                                            state,
                                                            isCreateServiceRequestRoute || isCreateServiceRecordRoute
                                                        )
                                                    }}
                                                />
                                                <Button
                                                    type={ButtonType.Contained}
                                                    label={
                                                        isFollowUpServiceRequest
                                                            ? "Create " + title
                                                            : state.requestForm.createWorkOrder
                                                                ? "Create Work Order"
                                                                : title
                                                    }
                                                    isEnabled={state.isFormValid && !state.isSaving}
                                                    onClick={
                                                        state.formType === ServiceFormType.Request
                                                            ? () => {
                                                                if (props.alwaysOpen) {
                                                                    viewModel.createServiceRequestButtonClicked(
                                                                        props.alwaysOpen
                                                                    )
                                                                } else {
                                                                    viewModel.createServiceRequestButtonClicked(
                                                                        isCreateServiceRequestRoute ||
                                                                        isCreateServiceRecordRoute
                                                                    )
                                                                }
                                                            }
                                                            : () =>
                                                                viewModel.createServiceRecordButtonClicked(
                                                                    isCreateServiceRequestRoute ||
                                                                    isCreateServiceRecordRoute
                                                                )
                                                    }
                                                />
                                            </FormField>
                                        </Grid>
                                        {isMobile && state.selectedAsset != null && (
                                            <div className="asset-label--mobile">
                                                {labelForAsset(state.selectedAsset)}
                                            </div>
                                        )}
                                        {!isMobile && (
                                            <Grid columns={4} className="service-request-creation-modal-asset-info">
                                                <DataField label="ID" value={state.selectedAsset?.identifier ?? ""} />
                                                <DataField label="Make" value={state.selectedAsset?.make.name ?? ""} />
                                                <DataField
                                                    label="Model"
                                                    value={state.selectedAsset?.model.name ?? ""}
                                                />
                                                <DataField
                                                    label="Year"
                                                    value={
                                                        state.selectedAsset
                                                            ? state.selectedAsset?.modelYear?.toString()
                                                            : ""
                                                    }
                                                />
                                                <DataField
                                                    label="Category"
                                                    value={
                                                        state.selectedAsset ? state.selectedAsset?.category?.name : ""
                                                    }
                                                />
                                                <DataField
                                                    label="Class"
                                                    value={state.selectedAsset ? state.selectedAsset?.class?.name : ""}
                                                />
                                                <DataField
                                                    label="VIN"
                                                    value={state.selectedAsset?.serialNumber ?? ""}
                                                />
                                                {(state.selectedAsset?.lifetimeHourMeter ?? null) !== null && (
                                                    <DataField
                                                        label={`Lifetime Hour Meter`}
                                                        value={Strings.formatDecimal(
                                                            state.selectedAsset?.lifetimeHourMeter ?? 0
                                                        )}
                                                    />
                                                )}
                                                {(state.selectedAsset?.lifetimeOdometer ?? null) !== null && (
                                                    <DataField
                                                        label={`Lifetime Odometer`}
                                                        value={Strings.formatInteger(
                                                            state.selectedAsset?.lifetimeOdometer ?? 0
                                                        )}
                                                    />
                                                )}
                                            </Grid>
                                        )}
                                    </>
                                )}
                                {state.formType === ServiceFormType.Request ? (
                                    <ServiceRequestForm
                                        asset={state.selectedAsset}
                                        form={state.requestForm}
                                        serviceCodes={state.serviceCodes}
                                        serviceSubTypes={state.serviceSubTypes}
                                        contacts={state.contacts}
                                        mechanics={state.mechanics}
                                        viewModel={viewModel}
                                        parentWorkOrderId={props.parentWorkOrderId}
                                    />
                                ) : (
                                    <ServiceRecordForm
                                        asset={state.selectedAsset}
                                        form={state.recordForm}
                                        mechanics={state.mechanics}
                                        serviceCodes={state.serviceCodes}
                                        sites={state.allSites}
                                        viewModel={viewModel}
                                    />
                                )}
                            </GridCont>
                        </ModalBody>
                        <ModalButtons>
                            <Button
                                type={ButtonType.Text}
                                label={props.alwaysOpen ? "Clear" : "Cancel"}
                                onClick={() => {
                                    cancel(
                                        props,
                                        viewModel,
                                        state,
                                        isCreateServiceRequestRoute || isCreateServiceRecordRoute
                                    )
                                }}
                            />
                            <Button
                                type={ButtonType.Contained}
                                label={
                                    isFollowUpServiceRequest
                                        ? "Create " + title
                                        : state.requestForm.createWorkOrder
                                            ? "Create Work Order"
                                            : title
                                }
                                isEnabled={state.isFormValid && !state.isSaving}
                                onClick={
                                    state.formType === ServiceFormType.Request
                                        ? () => {
                                            if (props.alwaysOpen) {
                                                viewModel.createServiceRequestButtonClicked(props.alwaysOpen)
                                            } else {
                                                viewModel.createServiceRequestButtonClicked(
                                                    isCreateServiceRequestRoute || isCreateServiceRecordRoute
                                                )
                                            }
                                        }
                                        : () =>
                                            viewModel.createServiceRecordButtonClicked(
                                                isCreateServiceRequestRoute || isCreateServiceRecordRoute
                                            )
                                }
                            />
                        </ModalButtons>
                    </ModalContent>
                </Modal>
            )}
        </div>
    )
}

function ServiceRequestForm({
    asset,
    form,
    serviceSubTypes,
    serviceCodes,
    contacts,
    mechanics,
    viewModel,
    parentWorkOrderId,
}: {
    asset: Asset | null
    form: ServiceRequestForm
    serviceSubTypes: Record<ServiceRequestType, ServiceSubType>
    serviceCodes: ServiceCode[]
    contacts: Contact[]
    mechanics: Contact[]
    viewModel: ServiceRequestCreationViewModel
    parentWorkOrderId: number | undefined
}): JSX.Element {
    return (
        <>
            <Grid columns={4}>
                <FormField label="Service Code">
                    <Select
                        options={[
                            ...[{ id: -1, value: "-1", label: "Select Service Code" }].map((s) => ({
                                key: s.id,
                                value: `${s.value}`,
                                label: s.label,
                            })),
                            ...serviceCodes.map((it) => ({
                                key: it.id,
                                value: `${it.id}`,
                                label: labelForServiceCode(it),
                            })),
                        ]}
                        onChange={(value) => {
                            viewModel.requestServiceCodeChanged(
                                viewModel.state.serviceCodes.find((it) => it.id === safeParseInt(value)) ?? null
                            )
                        }}
                        value={form.serviceCode?.id}
                    />
                </FormField>
                { viewModel.getUser().hasAccess( PermissionObject.ServiceQuote, PermissionType.View) ?
                <FormField>
                    <CheckBox
                        label="Unplanned Repair"
                        tooltip={"Planned repairs are typically significant repairs that are planned for in the asset's life and budgeted for in the annual budget."}
                        onCheckChanged={(isChecked) => viewModel.requestIsUnplannedChanged(isChecked)}
                        isChecked={form.isUnplanned}
                        style={{fontWeight: "bold", marginTop: "25px"}}
                    />
                </FormField>
                : null }
            </Grid>
            <Grid columns={4}>
                <FormField label="Urgency">
                    <Select
                        value={form.urgency}
                        options={Object.values(Urgency).map((urgency) => ({
                            value: urgency,
                            label: labelForUrgency(urgency),
                        }))}
                        onChange={(value) => viewModel.requestUrgencyChanged(urgencyForValue(value))}
                    />
                </FormField>
                <FormField label="Due Date">
                    <TextInputDatePicker
                        value={form.dueDate}
                        onChange={(date) => viewModel.requestDueDateChanged(date)}
                    />
                </FormField>
                {(asset?.hourMeter ?? null) !== null && (
                    <FormField label="Due Hour Meter" direction={FormFieldDirection.Column}>
                        <TextInput
                            type={TextInputType.Decimal}
                            value={form.dueHourMeter ?? ""}
                            onChange={(value) => viewModel.requestDueHourChanged(value)}
                        />
                        {asset?.hourMeter != null && asset?.hourMeter != 0 ? (
                            <FormFieldNote>Current: {Strings.formatDecimal(asset?.hourMeter ?? 0)}</FormFieldNote>
                        ) : (
                            ""
                        )}
                    </FormField>
                )}
                {(asset?.odometer ?? null) !== null && (
                    <FormField label="Due Odometer" direction={FormFieldDirection.Column}>
                        <TextInput
                            type={TextInputType.Integer}
                            min={0}
                            value={form.dueOdometer ?? ""}
                            onChange={(value) => viewModel.requestDueOdometerChanged(value)}
                        />
                        {asset?.odometer != null && asset?.odometer != 0 ? (
                            <FormFieldNote>Current: {Strings.formatInteger(asset?.odometer ?? 0)}</FormFieldNote>
                        ) : (
                            ""
                        )}
                    </FormField>
                )}
                
                <FormField label="Estimated Labor Hours">
                    <TextInput
                        type={TextInputType.Float}
                        min={0}
                        value={form.estimatedLaborHours ?? ""}
                        onChange={(value) => viewModel.requestEstimatedLaborHoursChanged(value)}
                        onFocus={(event) => event.target.select()}
                    />
                </FormField>
                <FormField label="Estimated Parts Cost">
                    <TextInput
                        type={TextInputType.Money}
                        value={form.estimatedPartsCost ?? ""}
                        onChange={(value) => viewModel.requestEstimatedPartsCostChanged(value)}
                        onFocus={(event) => event.target.select()}
                    />
                </FormField>
            </Grid>
            <Grid columns={1}>
                <FormField label="Complaint*">
                    <TextArea
                        value={form.workToBePerformed ?? ""}
                        onChange={(value) => viewModel.requestWorkToBePerformedChanged(value)}
                        maxLength={250}
                    />
                </FormField>
            </Grid>
            <Grid columns={1}>
                <TagFormField
                    tag={form.tag?.type == asset?.tag?.type && asset?.tag ? asset?.tag : form.tag}
                    onTagTypeChanged={(type) => viewModel.requestTagTypeChanged(type)}
                    onReasonChanged={(reason) => viewModel.requestTagReasonChanged(reason)}
                    disabled={form.tag?.type === asset?.tag?.type || !viewModel.hasRedYellowTagAddPermission()}
                    disableSelection={!viewModel.hasRedYellowTagAddPermission()}
                    disableNoneOption={
                        (asset?.tag! && asset?.tag?.type !== TagType.None) || !viewModel.hasRedYellowTagAddPermission()
                    }
                />
            </Grid>
            <Grid columns={3} columnsMd={2}>
                <FormField label="Notify Contacts">
                    <MultiSelect
                        options={contacts
                            .filter((it) => !it.deleted)
                            .map((contact) => ({
                            key: contact.id,
                            value: `${contact.id}`,
                            label: labelForContact(contact),
                            isChecked: form.notifyContacts.includes(contact.id),
                            checkAll: false,
                        }))}
                        onSelectionChanged={(selection) =>
                            viewModel.requestNotifyContactChanged(selection.map((contactId) => safeParseInt(contactId)))
                        }
                    />
                    {/* <Button
                type={ButtonType.Icon}
                theme={ButtonTheme.Light}
                iconUrl={ImageUrls.AddDark}
                label="Add Contact"
            /> */}
                </FormField>
            </Grid>
            <Grid columns={1}>
                <FormField label="Mechanic's Notes">
                    <TextArea
                        value={form.mechanicsNotes ?? ""}
                        onChange={(value) => viewModel.requestMechanicsNotesChanged(value)}
                        maxLength={2000}
                    />
                </FormField>
            </Grid>
            <Grid columns={1}>
                <AttachmentList
                    attachments={form.attachments}
                    onAttachmentsChanged={(files, formData) => viewModel.attachmentsChanged(files, formData)}
                    onAttachmentDeleteConfirm={(attachment) => viewModel.attachmentDeleted(attachment)}
                    onImageRotate90={(attachment) => viewModel.rotateImage(attachment, 90)}
                    onError={(errors) => viewModel.attachmentError(errors)}
                />
            </Grid>
            {parentWorkOrderId === undefined && viewModel.hasWorkOrderAddPermission() && (
                <Grid columns={2}>
                    <FormField label="Create Work Order?">
                        <CheckBox
                            label="Yes"
                            onCheckChanged={(isChecked) => viewModel.requestCreateWorkOrderChanged(isChecked)}
                            isChecked={form.createWorkOrder}
                        />
                    </FormField>
                </Grid>
            )}
            {form.createWorkOrder && (
                <>
                    <div />
                    <Grid columns={1}>
                        <FormField label="Work Order:" />
                        <Grid columns={2}>
                            <FormField label="Status*">
                                <Select
                                    value={form.workOrderStatus ?? WorkOrderStatus.Pending}
                                    options={Object.values(WorkOrderStatus)
                                        .filter((it) => it !== WorkOrderStatus.Closed)
                                        .map((it) => ({
                                            label: labelForWorkOrderStatus(it),
                                            value: it,
                                        }))}
                                    onChange={(value) =>
                                        viewModel.requestWorkOrderStatusChanged(workOrderStatusForValue(value))
                                    }
                                />
                            </FormField>
                            <FormField label="Assigned To">
                                <Select
                                    value={form.workOrderAssignedTo ?? "-1"}
                                    options={[
                                        { label: "Unassigned", value: "-1" },
                                        ...mechanics.filter((contact) => !contact.deleted).map((it) => ({
                                            label: labelForContact(it),
                                            value: it.id.toString(),
                                        })),
                                    ]}
                                    onChange={(value) => {
                                        const parsedValue = safeParseInt(value)
                                        viewModel.requestWorkOrderAssignedToChanged(
                                            parsedValue === -1 ? null : parsedValue
                                        )
                                    }}
                                />
                            </FormField>
                        </Grid>
                        <FormField label="Special Instructions">
                            <TextArea
                                value={form.workOrderSpecialInstructions ?? ""}
                                onChange={(value) => viewModel.requestWorkOrderSpecialInstructionsChanged(value)}
                                maxLength={1000}
                            />
                        </FormField>
                    </Grid>
                </>
            )}
        </>
    )
}

function ServiceRecordForm({
    asset,
    form,
    mechanics,
    serviceCodes,
    sites,
    viewModel,
}: {
    asset: Asset | null
    form: ServiceRecordForm
    mechanics: Contact[]
    serviceCodes: ServiceCode[]
    sites: Site[]
    viewModel: ServiceRequestCreationViewModel
}): JSX.Element {
    return (
        <>
            <Grid columns={4}>
                <FormField label="Service Code">
                    <Select
                        options={[
                            ...[{ id: -1, value: "-1", label: "Select Service Code" }].map((s) => ({
                                key: s.id,
                                value: `${s.value}`,
                                label: s.label,
                            })),
                            ...serviceCodes.map((it) => ({
                                key: it.id,
                                value: `${it.id}`,
                                label: labelForServiceCode(it),
                            })),
                        ]}
                        onChange={(value) => {
                            viewModel.recordServiceCodeChanged(
                                viewModel.state.serviceCodes.find((it) => it.id === safeParseInt(value)) ?? null
                            )
                        }}
                        value={form.serviceCode?.id}
                    />
                </FormField>
                <FormField label="Service Date*">
                    <TextInputDateTimePicker
                        value={form.serviceDate}
                        onChange={(date) => viewModel.recordServiceDateChanged(date)}
                        disablePast={false}
                        disableFuture={viewModel.state.recordForm ? true : false}
                    />
                    {viewModel.state.recordForm && form.serviceDate && new Date(form.serviceDate) > new Date() ? (
                    <div style={{ position: "absolute", top: "44px" }}>
                    <FormFieldNote type={FormFieldNoteType.Error}>
                    Service Date cannot be in the future
                    </FormFieldNote>
                    </div>
                    ) : (
                    ""
                    )}
                </FormField>
                <FormField label="Site">
                    <Select
                        options={[
                            ...[{ id: -1, value: "-1", label: "Select Site" }].map((s) => ({
                                key: s.id,
                                value: `${s.value}`,
                                label: s.label,
                            })),
                            ...sites.map((it) => ({
                                key: it.id,
                                value: `${it.id}`,
                                label: it.name,
                            })),
                        ]}
                        value={form.site?.id}
                        onChange={(value) =>
                            viewModel.recordSiteChanged(sites.find((it) => it.id === safeParseInt(value)) ?? null)
                        }
                    />
                </FormField>
                <FormField label="Work Performed By"  direction={FormFieldDirection.Column}>
                    <MultiSelect
                        options={mechanics
                            .filter((it) => !it.deleted)
                            .map((contact) => ({
                            key: contact.id,
                            value: contact.id.toString(),
                            label: labelForContact(contact, viewModel.getUser()),
                            isChecked: form.workPerformedBy?.find(x=> x == contact.id) != null                                       
                        }))}
                        onSelectionChanged={(value) =>
                            viewModel.recordWorkPerformedByChanged(value.map((i) => safeParseInt(i)))
                            }
                    />
                    {mechanics.filter( x=> form.workPerformedBy?.includes(x.id) ) 
                        ?.filter((item, i) => item.cellPhone != null || item.email != null)
                        .splice(0, 3) // we only show the first 3 items...
                        .map((mechanics, i) => (
                            <FormFieldNote inset={true} key={i}>
                                {mechanics?.cellPhone != null ? (
                                    <span>
                                        {mechanics.cellPhone}&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                ) : (
                                    ""
                                )}
                                {mechanics?.email}
                            </FormFieldNote>
                        ))}
                </FormField>
            </Grid>
            <Grid columns={4}>
                    <FormField label="Hour Meter" direction={FormFieldDirection.Column}>
                        <TextInput
                            type={TextInputType.Decimal}
                            min={0}
                            value={form.hourMeter ?? ""}
                            onChange={(value) => viewModel.recordHourMeterChanged(value)}
                        />
                        <FormFieldNote>Current: {Strings.formatDecimal(asset?.hourMeter ?? 0)}</FormFieldNote>
                    </FormField>

                    <FormField label="Odometer" direction={FormFieldDirection.Column}>
                        <TextInput
                            type={TextInputType.Integer}
                            min={0}
                            value={form.odometer ?? ""}
                            onChange={(value) => viewModel.recordOdometerChanged(value)}
                        />
                        <FormFieldNote>Current: {Strings.formatInteger(asset?.odometer ?? 0)}</FormFieldNote>
                    </FormField>
                    <FormField>
                    { viewModel.getUser().hasAccess( PermissionObject.ServiceQuote, PermissionType.View) ?
                    <CheckBox
                        label="Unplanned Repair"
                        tooltip={"Planned repairs are typically significant repairs that are planned for in the asset's life and budgeted for in the annual budget."}
                        onCheckChanged={(isChecked) => viewModel.recordIsUnplannedChanged(isChecked)}
                        isChecked={form.isUnplanned}
                        style={{fontWeight: "bold", marginTop: "25px"}}
                    />
                    : null }
                </FormField>
            </Grid>
            <Grid columns={4}>
                <FormField label="Labor Hours">
                    <TextInput
                        type={TextInputType.Float}
                        min={0}
                        value={form.laborHours ?? ""}
                        onChange={(value) => viewModel.recordLaborHoursChanged(value)}
                        onFocus={(event) => event.target.select()}
                    />
                </FormField>
                <FormField label="Labor Cost">
                    <TextInput
                        type={TextInputType.Money}
                        value={form.laborCost ?? ""}
                        onChange={(value) => viewModel.recordLaborCostChanged(value)}
                        onFocus={(event) => event.target.select()}
                    />
                </FormField>
                <FormField label="Parts Cost">
                    <TextInput
                        type={TextInputType.Money}
                        value={form.partsCost ?? ""}
                        onChange={(value) => viewModel.recordPartsCostChanged(value)}
                        onFocus={(event) => event.target.select()}
                    />
                </FormField>
                <FormField label="Total Cost">
                    <TextInput type={TextInputType.Money} value={form.totalCost ?? ""} isEnabled={false} />
                </FormField>
            </Grid>
            <Grid columns={1}>
                <FormField label="Work Performed*">
                    <TextArea
                        value={form.workPerformed ?? ""}
                        onChange={(value) => viewModel.recordWorkPerformedChanged(value)}
                        maxLength={250}
                    />
                </FormField>
                <FormField label="Mechanic's Notes">
                    <TextArea
                        value={form.mechanicsNotes ?? ""}
                        onChange={(value) => viewModel.recordMechanicsNotesChanged(value)}
                        maxLength={2000}
                    />
                </FormField>
            </Grid>
            <Grid columns={4}>
                <FormField label="External Work Order">
                    <TextInput
                        type={TextInputType.Text}
                        maxLength={250}
                        value={form.externalWorkOrder ?? ""}
                        onChange={(value) => viewModel.recordExternalWorkOrderChanged(value)}
                    />
                </FormField>
                <FormField label="External Vendor">
                    <TextInput
                        type={TextInputType.Text}
                        maxLength={250}
                        value={form.externalVendor ?? ""}
                        onChange={(value) => viewModel.recordExternalVendorChanged(value)}
                    />
                </FormField>
                <FormField label="External Invoice Number">
                    <TextInput
                        type={TextInputType.Text}
                        maxLength={250}
                        value={form.externalInvoice ?? ""}
                        onChange={(value) => viewModel.recordExternalInvoiceNumberChanged(value)}
                    />
                </FormField>
                <FormField label="External Note">
                    <TextInput
                        type={TextInputType.Text}
                        maxLength={250}
                        value={form.externalNote ?? ""}
                        onChange={(value) => viewModel.recordExternalNoteChanged(value)}
                    />
                </FormField>
            </Grid>
            <Grid columns={1}>
                <AttachmentList
                    attachments={form.attachments}
                    onAttachmentsChanged={(files, formData) => viewModel.attachmentsChanged(files, formData)}
                    onAttachmentDeleteConfirm={(attachment) => viewModel.attachmentDeleted(attachment)}
                    onImageRotate90={(attachment) => viewModel.rotateImage(attachment, 90)}
                    onError={(errors) => viewModel.attachmentError(errors)}
                />
            </Grid>
        </>
    )
}
function cancel(
    props: ServiceRequestCreationModalProps,
    viewModel: ServiceRequestCreationViewModel,
    state: any,
    navigateToAssetWorkRequests: boolean
): void {
    props.onClose?.()
    viewModel.cancel(navigateToAssetWorkRequests)
    if (props.alwaysOpen) {
        viewModel.showModal(ServiceFormType.Request)
    }
}
