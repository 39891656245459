import { useBlocCoordinator } from "@lib/bloc/hooks"
import { ServiceQuote, ServiceQuoteItem } from "@model/serviceQuotes/ServiceQuote"
import { User } from "@model/user/User"
import { DI } from "@ui/DI"
import { Modal, ModalBody, ModalButtons, ModalContent, ModalHeader, ModalHeaderTitle, ModalSize } from "@ui/common/Modal"
import { Grid, GridCont, GridGap } from "@ui/common/grids/Grid"
import React, { useEffect } from "react"
import { ServiceRequestsTable } from "./ServiceRequestsTable"
import { FormDivider, FormDividerMargin } from "@ui/common/form/FormDivider"
import { FormField } from "@ui/common/form/FormField"
import { TextArea } from "@ui/common/form/TextArea"
import { MultiSelect } from "@ui/common/form/MultiSelect"
import { labelForContact } from "@model/contacts/Contact"
import { safeParseInt } from "@lib/TypeUtil"
import { Button, ButtonType } from "@ui/common/buttons/Button"
import ReactDOM from "react-dom"

export const DeclineServiceQuoteDialog = {
    open: (serviceQuoteId: number, serviceRequests: ServiceQuoteItem[], user: User): void => {
        let finalCancelClicked = () => {
            let modal = document.getElementById("modalHolder")
            if(modal) {
                ReactDOM.unmountComponentAtNode(modal)
            }
        }
        let finalDeclineClicked = () => {
            let modal = document.getElementById("modalHolder")
            if(modal) {
                ReactDOM.unmountComponentAtNode(modal)
            }
        }
        ReactDOM.render(
            <DeclineServiceQuoteModal
                user={user}
                serviceQuoteId={serviceQuoteId}
                serviceRequests = {serviceRequests}
                onCancelClicked={finalCancelClicked}
                onDeclineClicked={finalDeclineClicked}/>,
                document.getElementById("modalHolder")
        )
    }
}

type DeclineServiceQuoteModalProps = {
    onCancelClicked: () => void
    onDeclineClicked: () => void
    serviceQuoteId: number
    serviceRequests: ServiceQuoteItem[]
    user: User
}

function DeclineServiceQuoteModal(props: DeclineServiceQuoteModalProps): JSX.Element {
    return (
        <Modal isVisible={true} useContentTag={true} onClose={props.onCancelClicked} size={ModalSize.Large}>
            <DeclineServiceQuoteModalContent {...props} />
        </Modal>
    )
}

function DeclineServiceQuoteModalContent(props: DeclineServiceQuoteModalProps): JSX.Element {
    const [state, viewModel] = useBlocCoordinator(() => DI.declineServiceQuoteViewModel())
    useEffect(() => {}, [])

    return (
        <ModalContent>
            <ModalHeader>
                <ModalHeaderTitle>Decline Service Quote</ModalHeaderTitle>
            </ModalHeader>
            <ModalBody>
                <Grid columns={1} gap={GridGap.Small}>
                    <ServiceRequestsTable serviceRequests={props.serviceRequests} />
                    <FormDivider margin={FormDividerMargin.Small} />
                    <GridCont>
                        <Grid columns={1}>
                            <FormField label="Message to Vendor *">
                                <TextArea
                                    maxLength={1000}
                                    value={state.vendorMessage}
                                    onChange={(value) => viewModel.vendorMessageChanged(value)}
                                    />
                            </FormField>
                        </Grid>
                        <Grid columns={2}>
                            <FormField label="Notify Contacts">
                                <MultiSelect
                                    options={state.contacts.map((c) => ({
                                        key: c.id,
                                        value: `${c.id}`,
                                        label: labelForContact(c),
                                        isChecked: state.notifyContacts.includes(c.id),
                                        checkAll: false,
                                    }))}
                                    onSelectionChanged={(selection) => viewModel.notifyContactsChanged(selection.map((contactId) => safeParseInt(contactId)))}
                                    />
                            </FormField>
                        </Grid>
                    </GridCont>
                </Grid>
            </ModalBody>
            <ModalButtons>
                <Button
                    label="Cancel"
                    type={ButtonType.Text}
                    onClick={props.onCancelClicked} />
                <Button
                    label="Decline Quote"
                    type={ButtonType.Contained}
                    onClick={async () => {
                        await viewModel.declineServiceQuote(props.serviceQuoteId)
                        props.onDeclineClicked()
                    }}
                    isEnabled={state.isFormValid} />
            </ModalButtons>
        </ModalContent>
    )
}