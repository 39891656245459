import { classNames } from "@lib/HtmlUtil"
import { Attachment, AttachmentType } from "@model/Attachment"
import { Button, ButtonTheme, ButtonType } from "@ui/common/buttons/Button"
import { ImageUrls } from "@ui/common/images/ImageUrls"
import React, { useRef, useState } from "react"
import { Modal, ModalBody, ModalButtons, ModalHeader, ModalHeaderTitle } from "../Modal"
import { FormField } from "./FormField"

export type AttachmentListProps = {
    className?: string
    formDataName?: string
    attachments: Attachment[]
    disabled?: boolean
    onCloseRef?: React.RefObject<any>
    onAttachmentsChanged?: (files: File[], formData: FormData) => void
    onAttachmentDeleteConfirm?: (attachments: Attachment) => void
    onImageRotate90?: (attachments: Attachment) => void
    onImageRotate180?: (attachments: Attachment) => void
    onError?: (errorMessages: string[]) => void
}

export function AttachmentList({
    attachments = [],
    formDataName = "attachments",
    ...props
}: AttachmentListProps): JSX.Element {
    const formRef = useRef<HTMLFormElement>(null)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [attachmentToDelete, setAttachmentToDelete] = useState<Attachment | null>(null)
    const [modalScrollPosition, setModalScrollPosition] = useState<number | null>(null)
    const fileExtensionsAllowed: string[] = [
        ".pdf",
        ".jpeg",
        ".jpg",
        ".png",
        ".heic",
        ".xls",
        ".xlsx",
        ".csv",
        ".doc",
        ".docx",
    ]

    // General scroll to element function

    const showDeleteDialogHandler = (attachment: Attachment, scrollY: number) => {
        setAttachmentToDelete(attachment)
        setShowDeleteDialog(true)
        setModalScrollPosition(scrollY)
    }

    const onClose = () => {
        if (props.onCloseRef != null && props.onCloseRef.current != null)
            props.onCloseRef.current.scrollTo(0, modalScrollPosition)

        setShowDeleteDialog(false)
    }

    const label = (
        <>
            <span>Attachments</span>
            {props.disabled == null || (props.disabled != null && !props.disabled) ? (
                <Button
                    type={ButtonType.Icon}
                    theme={ButtonTheme.Light}
                    className="attachment-list-add-button"
                    label="Add Attachments"
                    iconUrl={ImageUrls.AddBlue}
                >
                    <form ref={formRef}>
                        <input
                            className="attachment-list-add-button-input"
                            type="file"
                            multiple={true}
                            accept={fileExtensionsAllowed.join(",")}
                            name="attachments"
                            onClick={(ev) => {
                                //NOTE: this is needed to clear the input element and allow uploading the same file twice. Eg: The user tries to upload a file but it exceeds the max size.
                                const element = ev.target as HTMLInputElement
                                element.value = ""
                            }}
                            onChange={(ev) => {
                                if (ev.currentTarget.files === null || formRef.current === null) return
                                let errors: string[] = []
                                let alreadySeen: { [Name: string]: boolean } = {};
                                let checkDuplicates = function (filename: string): string {
                                    if(alreadySeen[filename] || filename == "image") {
                                        let timestamp = Date.now();
                                        filename = filename.concat(`_${timestamp}`);
                                        return checkDuplicates(filename);
                                    }
                                    else {
                                        alreadySeen[filename] = true;
                                        return filename;
                                    }
                                }
                                for(let attachment of attachments) {
                                    alreadySeen[attachment.name] = true;
                                }
                                let filesToSend: File[] = [];
                                for (let file of ev.currentTarget.files) {
                                    let filename = file.name.substring(0, file.name.lastIndexOf("."));
                                    let fileExtension = file.name.substring(file.name.lastIndexOf("."));
                                    filename = checkDuplicates(filename);
                                    if(file.name != `${filename}${fileExtension}`) {
                                        filesToSend.push(new File([file], `${filename}${fileExtension}`, { type: file.type }));
                                    }
                                    else {
                                        filesToSend.push(file);
                                    }
                                    if (!fileExtensionsAllowed.some((x) => x.toLowerCase() == fileExtension.toLowerCase())) {
                                        errors.push("File type not allowed: " + file.name)
                                    }
                                    if (file.size > 50e6) {
                                        errors.push("File " + file.name + " is larger than 50MB<br>")
                                    }
                                }
                                if (errors.length > 0) {
                                    props.onError?.(errors)
                                    return
                                }
                                props.onAttachmentsChanged?.(filesToSend, new FormData(formRef.current))
                            }}
                        />
                    </form>
                </Button>
            ) : (
                ""
            )}
        </>
    )

    return (
        <>
            <FormField label={label}>
                <div className={classNames("attachment-list", props.className)}>
                    {attachments.length === 0 && <div className="attachment-list-empty-item">None</div>}
                    {attachments.map((attachment) => (
                        <AttachmentListItem
                            attachment={attachment}
                            key={attachment.name}
                            onAttachmentDeleteClick={showDeleteDialogHandler}
                            onAttachmentListCloseRef={props.onCloseRef}
                            disabled={props.disabled}
                            onImageRotate90={props.onImageRotate90}
                            onImageRotate180={props.onImageRotate180}
                        />
                    ))}
                </div>
            </FormField>
            <Modal isVisible={showDeleteDialog} onClose={onClose} useContentTag={true}>
                <ModalHeader>
                    <ModalHeaderTitle>Delete Attachment</ModalHeaderTitle>
                </ModalHeader>
                <ModalBody>
                    Would you like to delete this attachment? <strong>This process cannot be undone.</strong>
                </ModalBody>
                <ModalButtons>
                    <Button type={ButtonType.Text} label="Cancel" onClick={onClose} />
                    <Button
                        type={ButtonType.Contained}
                        label="Delete"
                        onClick={() => {
                            if (!props || !attachmentToDelete || !props.onAttachmentDeleteConfirm) return
                            props.onAttachmentDeleteConfirm?.(attachmentToDelete)
                            setAttachmentToDelete(null)
                            onClose()
                        }}
                    />
                </ModalButtons>
            </Modal>
        </>
    )
}
export function AttachmentListItem({
    attachment,
    onAttachmentDeleteClick,
    onAttachmentListCloseRef,
    onImageRotate90,
    onImageRotate180,
    disabled,
}: {
    attachment: Attachment
    onAttachmentDeleteClick?: (attachment: Attachment, scrollY: number) => void
    onAttachmentListCloseRef?: React.RefObject<any>
    onImageRotate90?: (attachment: Attachment) => void
    onImageRotate180?: (attachment: Attachment) => void
    disabled?: boolean
}): JSX.Element {
    const attachmentRef = useRef(null)

    return (
        <div className="attachment-list-item" id={attachment.url} data-type={attachment.type} ref={attachmentRef}>
            <a
                className="attachment-list-item-preview"
                id={attachment.url}
                href={attachment.url}
                target="_blank"
                {...(attachment.type === AttachmentType.Image
                    ? { style: { backgroundImage: `url('${attachment.url}')`, backgroundSize: "100%" } }
                    : attachment.name.toLowerCase().endsWith("xls") || attachment.name.toLowerCase().endsWith("xlsx")
                    ? { style: { backgroundImage: `url(${ImageUrls.Excel})`, backgroundSize: "100%" } }
                    : attachment.name.toLowerCase().endsWith("pdf")
                    ? { style: { backgroundImage: `url(${ImageUrls.Pdf})`, backgroundSize: "50%" } }
                    : attachment.name.toLowerCase().endsWith("doc") || attachment.name.toLowerCase().endsWith("docx")
                    ? { style: { backgroundImage: `url(${ImageUrls.Word})`, backgroundSize: "50%" } }
                    : {})}
            ></a>
            {disabled == null || (disabled != null && !disabled) ? (
                <Button
                    type={ButtonType.Icon}
                    theme={ButtonTheme.Light}
                    iconUrl={ImageUrls.Trash}
                    label="Remove file"
                    onClick={() =>
                        onAttachmentDeleteClick?.(
                            attachment,
                            onAttachmentListCloseRef != null && onAttachmentListCloseRef.current != null
                                ? onAttachmentListCloseRef.current.scrollTop
                                : 0
                        )
                    }
                />
            ) : (
                ""
            )}
            {attachment.type === AttachmentType.Image ? (
                <div
                    {...{
                        style: {
                            top: "-41px",
                            float: "right",
                            padding: "2px",
                            position: "relative",
                            height: "40px",
                            width: "40px",
                        },
                    }}
                >
                    <Button
                        type={ButtonType.Icon}
                        theme={ButtonTheme.Light}
                        iconUrl={ImageUrls.Rotate}
                        label="Rotate"
                        onClick={() => onImageRotate90?.(attachment)}
                    />
                </div>
            ) : (
                ""
            )}
            <div className="attachment-list-item-label">{attachment.name}</div>
        </div>
    )
}
