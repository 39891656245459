import { compare, SortOrder } from "@lib/Comparable"
import { HistoryItem } from "@model/HistoryItem"
import { Urgency } from "@model/Urgency"
import { ApiCreateWorkOrderForm } from "@model/workOrders/CreateWorkOrderForm"
import { WorkOrderStatus } from "@model/workOrders/WorkOrder"
export type ServiceQuote = {
    id: number
    companyId: number
    workOrderId: number | null
    serviceQuoteStatusId: number
    Status: ServiceQuoteStatus
    purchaseOrderNumber: string | null
    vendorId: number
    vendor: ServiceQuoteVendor | null
    name: string
    creationDateTime: Date
    processedDateTime: Date | null
    quoteAssetVerified: boolean
    quoteHistory: HistoryItem<ServiceQuoteStatus>[] | null
    assetId: number | null
    serviceQuoteDate: Date | null
    serviceQuoteExpirationDate: Date | null
    taxRate: number | null
    subtotal: number | null
    taxes: number | null
    otherCharges: number | null
    fileId: string | null
    totalLaborHours: number | null
    totalCost: number | null
    totalLaborCost: number | null
    assetEquipmentNumber: string | null
    assetSerialNumber: string | null
    responseEmailAddress: string | null
    rejectionReason: string | null
    number: string | null
    serviceQuoteItems: ServiceQuoteItem[]
    assignedTo: number | null
    notifyContacts: number[]
    vendorMessage: string | null
    assetSchedules: number[] | undefined
}

export enum ServiceQuoteStatus {
    Pending = "pending",
    Declined = "declined",
    RevisionRequested = "revisionRequested",
    Approved = "approved",
}

export type ServiceQuoteItem = {
    id: number
    status: ServiceQuoteStatus
    serviceQuoteId: number
    serviceCodeId: number | null
    lineItemNumber: number
    descriptionWorkToBePerformed: string
    mechanicsNotes: string | null
    laborHours: number | null
    laborRate: number
    totalLaborCost: number
    totalPartsCost: number | null
    lineItemSubtotal: number
    taxExemptAmount: number
    environmentalCharge: number
    totalMiscCost: number | null
    workRequestId: number | null
    rejectionReason: string | null
    rejectionDate: Date | null
    fileId: string | null
}

export type ApproveServiceQuoteForm = {
    serviceQuoteId: number
    assetId: number
    vendorMessage: string
    purchaseOrderNumber: string
    notifyContacts: number[]
    createWorkOrder: boolean
    serviceQuoteItems: ServiceQuoteItem[]

    workOrderStatus: WorkOrderStatus
    workOrderAssignedTo: number
    workOrderUrgency: Urgency
    workOrderDueDate: Date | null
    workOrderDueHourMeter: string
    workOrderDueOdometer: string
    workOrderSpecialInstructions: string
}

export type ApiApproveServiceQuote = {
    serviceQuoteId: number
    vendorMessage: string
    purchaseOrderNumber: string
    notifiedContacts: string
    serviceQuoteItems: ApiServiceQuoteItem[]
    createWorkOrder: ApiCreateWorkOrderForm
}

export function newApproveServiceQuoteForm(): ApproveServiceQuoteForm {
    return {
        serviceQuoteId: -1,
        assetId: -1,
        vendorMessage: "",
        purchaseOrderNumber: "",
        notifyContacts: [],
        serviceQuoteItems: [],
        createWorkOrder: false,
        workOrderStatus: WorkOrderStatus.Pending,
        workOrderAssignedTo: -1,
        workOrderUrgency: Urgency.Medium,
        workOrderDueDate: null,
        workOrderDueHourMeter: "",
        workOrderDueOdometer: "",
        workOrderSpecialInstructions: "",
    }
}

export type RequestRevisionServiceQuoteForm = {
    serviceQuoteId: number
    assetId: number
    vendorMessage: string
    notifyContacts: number[]
    userName: string
}

export function labelForServiceRequestHeader(request: ServiceQuoteItem): string {
    const label = `${request.lineItemNumber}.  ${request.descriptionWorkToBePerformed}`

    if (!label) return ""
    return label.length > 0 ? label : `${request.id}`
}

export function labelForServiceRequest(request: ServiceQuoteItem): string {
    const label = request.descriptionWorkToBePerformed

    if (!label) return ""
    return label.length > 0 ? label : `${request.id}`
}

export type ServiceQuoteVendor = {
    id: number
    VendorName: string
    Description: string
    PrimaryContactName: string
    PrimaryContactPhoneNumber: string
    PrimaryContactEmail: string
    PrimaryResponseEmailAddress: string
}

export type MiscItem = {
    id: number
    miscellaneousQty: number | null
    miscellaneousName: string | null
    miscellaneousUnitCost: number | null
    miscellaneousUnit: string | null
    serviceQuoteId: number | null
}

export type ApiServiceQuote = {
    serviceQuoteID: number
    workOrderID: number | null
    vendorID: number | null
    purchaseOrderNumber: string | null
    assetId: number | null
    serviceQuoteNumber: string | null
    name: string
    totalLaborHours: number | null
    totalCost: number | null
    totalLaborCost: number | null
    fileID: string | null
    creationDateTime: Date
    serviceQuoteDate: Date | null
    processedDateTime: Date | null
    serviceQuoteExpirationDate: Date | null
    taxRate: number | null
    subtotal: number | null
    taxes: number | null
    otherCharges: number | null
    quoteAssetVerified: boolean
    serviceQuoteItems: ApiServiceQuoteItem[]
    serviceQuoteStatusID: number
    serviceQuoteStatus: ApiServiceQuoteStatus
    assetEquipmentNumber: string | null
    assetSerialNumber: string | null
    companyID: number
    assignedTo: number | null
    responseEmailAddress: string | null
    rejectionReason: string | null
    vendor: ApiServiceQuoteVendor | null
    notifiedContacts: string
    vendorMessage: string | null
    associatedSchedules: string | null
}

export type ApiServiceQuoteItem = {
    serviceQuoteItemID: number
    serviceQuoteID: number
    lineItemNumber: number
    descriptionWorkToBePerformed: string
    mechanicsNotes: string | null
    laborHours: number
    laborRate: number
    totalLaborCost: number
    totalMiscCost: number | null
    totalPartsCost: number | null
    lineItemSubtotal: number
    taxExemptAmount: number
    environmentalCharge: number
    rejectionReason: string | null
    serviceQuoteStatusID: number
    serviceCodeID: number | null
    workRequestID: number | null
    rejectionDate: Date | null
    fileID: string | null
}

export type ApiServiceQuoteVendor = {
    vendorId: number
    vendorName: string
    description: string
    companyId: number
    address: string
    city: string
    stateID: number | null
    postalCode: string
    primaryContactName: string
    primaryContactPhoneNumber: string
    primaryContactEmail: string
    primaryResponseEmailAddress: string
    deleted: boolean
}

export function serviceQuoteStatusItemForValue(value: string): ServiceQuoteStatus {
    return value as ServiceQuoteStatus
}

export function serviceQuoteStatusForValue(value: string): ServiceQuoteStatus {
    switch (value) {
        case ServiceQuoteStatus.Approved:
            return ServiceQuoteStatus.Approved
        case ServiceQuoteStatus.Declined:
            return ServiceQuoteStatus.Declined
        case ServiceQuoteStatus.Pending:
            return ServiceQuoteStatus.Pending
        case ServiceQuoteStatus.RevisionRequested:
            return ServiceQuoteStatus.RevisionRequested
        default:
            return ServiceQuoteStatus.Pending
    }
}

export function labelForServiceQuoteStatus(status: ServiceQuoteStatus): string {
    switch (status) {
        case ServiceQuoteStatus.Pending:
            return "Pending"
        case ServiceQuoteStatus.Declined:
            return "Declined"
        case ServiceQuoteStatus.RevisionRequested:
            return "Revision Requested"
        case ServiceQuoteStatus.Approved:
            return "Approved"
    }
}

export function numberForServiceQuoteStatus(status: ServiceQuoteStatus): number {
    switch (status) {
        case ServiceQuoteStatus.Pending:
            return 1
        case ServiceQuoteStatus.Declined:
            return 2
        case ServiceQuoteStatus.RevisionRequested:
            return 3
        case ServiceQuoteStatus.Approved:
            return 4
    }
}
export enum ServiceQuoteFilter {
    ServiceQuoteId = "serviceQuoteId",
    Asset = "asset",
    VendorID = "vendorId",
    Contact = "contact",
    AssignedTo = "assignedTo",
    TotalCostFilterOption = "totalCostFilterOption",
    CreationDateTimeFilterOption = "creationDateTimeFilterOption",
    Status = "status",
    LastKnownLocation = "lastKnownLocation",
    Search = "search",
    WOSearch = "woSearch",
    SubCompany = "subCompany",
    District = "district",
    SubDistrict = "subDistrict",
    Unit = "unit",
    Group = "group",
    Site = "site",
    AssetType = "assetType",
    Category = "category",
    Class = "class",
    Make = "make",
    Model = "model",
    WorkType = "workType",
    MechanicTypes = "mechanicTypes",
}

export enum ServiceQuoteQuickFilterCount {
    ServiceQuotes = "serviceQuotes",
    DaysOpenSubSeven = "daysOpenSubSeven",
    DaysOpenSevenToThirty = "daysOpenSevenToThirty",
    DaysOpenThirtyToNinety = "daysOpenThirtyToNinety",
    DaysOpenOverNinety = "daysOpenOverNinety",
    TotalCostSubOneThousand = "totalCostsSubOneThousand",
    TotalCostOneToTenThousand = "totalCostOneToTenThousand",
    TotalCostTenToTwentyFiveThousand = "totalCostTenToTwentyFiveThousand",
    TotalCostOverTentyFiveThousand = "totalCostOverTwentyFiveThousand",
}

export type ServiceQuoteTableRow = {
    id: number
    asset: string | null
    assetId: number | null
    workOrderId: number | null
    serviceQuoteStatusId: number | null
    vendorId: number | null
    name: string | null
    processedDateTime: Date | null
    purchaseOrderNumber: string | null
    quoteAssetVerified: boolean | null
    quoteHistory: string | null
    creationDateTime: Date | null
    serviceQuoteExpirationDate: Date | null
    taxRate: number | null
    subtotal: number | null
    taxes: number | null
    otherCharges: number | null
    fileId: string | null
    totalLaborHours: number | null
    totalLaborCost: number | null
    assetEquipmentNumber: string | null
    assetSerialNumber: string | null
    responseEmailAddress: string | null
    rejectionReason: string | null
    number: number | null
    companyId: number | null
    assignedToId: number | null
    vendor: string | null
    contact: string | null
    assignedTo: string | null
    serviceQuoteStatus: ServiceQuoteStatus
    totalCost: number | null
    dateReceived: Date | null
    lastKnownLocation: string | null
    assignedToName: string | null
}

// Note: These values are used to store column preferences
export enum ServiceQuotesTableColumn {
    ServiceQuote = "serviceQuote",
    Asset = "asset",
    Vendor = "vendor",
    Contact = "contact",
    AssignedTo = "assignedTo",
    ServiceQuoteStatus = "serviceQuoteStatus",
    TotalCost = "totalCost",
    DateReceived = "dateReceived",
    LastKnownLocation = "lastKnownLocation",
}

export enum ApiServiceQuoteStatus {
    None = 0,
    Pending = 1,
    Declined = 2,
    RevisionRequested = 3,
    ConvertedToWO = 4,
}

export const defaultServiceQuotesTableColumns = [
    ServiceQuotesTableColumn.ServiceQuote,
    ServiceQuotesTableColumn.Asset,
    ServiceQuotesTableColumn.Vendor,
    ServiceQuotesTableColumn.Contact,
    ServiceQuotesTableColumn.AssignedTo,
    ServiceQuotesTableColumn.ServiceQuoteStatus,
    ServiceQuotesTableColumn.TotalCost,
    ServiceQuotesTableColumn.DateReceived,
    ServiceQuotesTableColumn.LastKnownLocation,
]

export function sortServiceQuoteColumns(columns: ServiceQuotesTableColumn[]): ServiceQuotesTableColumn[] {
    return columns.sort((a, b) => {
        const order = Object.values(ServiceQuotesTableColumn)
        return compare(order.indexOf(a), order.indexOf(b), SortOrder.Asc)
    })
}

export enum QuickFilterStatus {
    SubSevenDays = "LessThanSevenDays",
    SevenToThirtyDays = "SevenToThirtyDays",
    ThirtyToNinetyDays = "ThirtyOneToNinetyDays",
    OverNinteyDays = "OverNinetyDays",
    SubOneThousand = "UnderOneThousand",
    OneToTenThousand = "OneToTenThousand",
    TenToTwentyFiveThousand = "TenToTwentyFiveThousand",
    OverTwentyFiveThousand = "OverTwentyFiveThousand",
}

export enum ServiceQuoteItemFormType {
    Standard = "Standard",
    Declined = "Declined",
    DeclineAll = "DeclineAll",
}
