import React from "react"
import { Modal } from "@ui/common/Modal"

export function StatusWidgetSettingsModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }): JSX.Element {
    return (
        <Modal
            isVisible={isOpen}
            title="Dashboard Quick Access Widgets"
            subTitle="Display the following Quick Access Widgets."
            onClose={onClose}
        >
            <div className="status-widget-settings-modal-content">
                <div className="status-widget-settings-modal-group">
                    <div className="status-widget-settings-modal-group-label">Service Requests</div>
                </div>
                <div className="status-widget-settings-modal-group">
                    <div className="status-widget-settings-modal-group-label">Work Orders</div>
                </div>
            </div>
        </Modal>
    )
}
