export enum Color {
    Primary = "#191e27",
    Action = "#247cf4",
    Red = "#FF0B0B",
    Yellow = "#F4B824",
    Salmon = "#FF644B",
    Orange = "#FF8D2B",
    Green = "#7BC131",
    Grey = "#BAC3C6",
    GreyLight = "#F5F7F7",
    CircleButtonDefault = "#3E484F",
    Transparent = "transparent",
}

function darkenColor(hex: string, percent: number): string {
    const colorString = hex.substr(1)
    return `#${Array(3)
        .fill(0)
        .map((_, index) => parseInt(colorString.substring(index * 2, index * 2 + 2), 16))
        .map((val) => Math.max(val - Math.ceil(255 * percent), 0))
        .map((value) => value.toString(16))
        .join("")}`
}

function lightenColor(hex: string, percent: number): string {
    const colorString = hex.substr(1)
    return `#${Array(3)
        .fill(0)
        .map((_, index) => parseInt(colorString.substring(index * 2, index * 2 + 2), 16))
        .map((val) => Math.min(val + Math.ceil(255 * percent), 255))
        .map((value) => value.toString(16))
        .join("")}`
}
