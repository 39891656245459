import { Outcome } from "@ethossoftworks/outcome"
import { Bloc } from "@lib/bloc/Bloc"
import { arrayToObject, safeParseInt } from "@lib/TypeUtil"
import {
    Contact,
    ContactType,
    labelForContact,
    labelForContactType,
    valueForContactType,
} from "@model/contacts/Contact"
import { DashboardFilter } from "@model/dashboard/DashboardFilter"
import { DashboardFilterDefinitions } from "@model/dashboard/DashboardFilterDefinitions"
import {
    createDefaultFilter,
    createEmptyFilter,
    Filter,
    FilterUpdateType,
    FilterValue,
    isEmptyFilterValue,
    newDefaultFiltersObj,
    updateFilter,
} from "@model/filters/Filter"
import { newStatusWidgetCounts, StatusWidgetCounts, StatusWidgetGroup } from "@model/widgets/StatusWidget"
import { WorkOrderFilter, WorkOrderQuickFilterCount, WorkOrderStatus } from "@model/workOrders/WorkOrder"
import { WorkOrderFilterDefinitions } from "@model/workOrders/WorkOrderFilterDefinitions"
import {
    labelForWorkRequestType,
    WorkRequestFilter,
    WorkRequestQuickFilterCount,
    workRequestTypeForValue,
} from "@model/workRequests/WorkRequest"
import { WorkRequestsFilterDefinitions } from "@model/workRequests/WorkRequestsFilterDefinitions"
import { CompanyFieldIdMapper } from "@service/company/CompanyService"
import { RedYellowTagsWidgetDataItem, WidgetService } from "@service/widgets/WidgetService"
import { WorkOrderService } from "@service/workOrders/WorkOrderService"
import { WorkRequestService } from "@service/workRequests/WorkRequestService"
import { IFilterOverlayMixinBloc } from "@state/filters/IFilterOverlayMixinBloc"

export type DashboardScreenState = {
    readonly filters: Record<DashboardFilter, Filter<DashboardFilter>>
    readonly hasLoaded: Record<DashboardDataType, boolean>
    readonly statusWidgetCounts: StatusWidgetCounts
    readonly isStatusWidgetSettingsModalOpen: boolean
    readonly workOrderStatusWidgetData: Record<WorkOrderStatus, number>
    readonly workRequestCompletedWidgetData: number[]
    readonly redYellowTagsWidgetData: RedYellowTagsWidgetDataItem[]
    readonly selectedMechanicType: ContactType[]
    readonly mechanics: Contact[]
    readonly selectedMechanic: Contact[]
}

export enum DashboardDataType {
    StatusWidget,
    WorkRequestsCompletedWidget,
    RedYellowTagsWidget,
    WorkOrderStatusWidget,
}

export class DashboardScreenBloc
    extends Bloc<DashboardScreenState>
    implements IFilterOverlayMixinBloc<DashboardFilter>
{
    constructor(
        private workRequestService: WorkRequestService,
        private workOrderService: WorkOrderService,
        private widgetService: WidgetService,
        private idMapper: CompanyFieldIdMapper
    ) {
        super(newDashboardScreenState(), { persistStateOnDispose: true })
    }

    async applyFiltersAndFetch(filters: Record<DashboardFilter, Filter<DashboardFilter>> | null): Promise<any> {
        return this.effect({
            id: this.applyFiltersAndFetch,
            block: async (job) => {
                const filtersArray = Object.values(filters ?? []).filter((filter) => !isEmptyFilterValue(filter))
                this.fetchStatusCounts(filtersArray)

                this.update({
                    filters:
                        filters ?? newDefaultFiltersObj(Object.values(DashboardFilter), DashboardFilterDefinitions),
                })

                await Promise.all([
                    this.fetchWorkOrderStatusWidgetData(filters),
                    this.fetchRedYellowTagsWidgetData(),
                    this.fetchWorkRequestsCompletedWidgetData(filters),
                ])

                return Outcome.ok(undefined)
            },
        })
    }

    async selectedWorkTypeFilterChanged(workType: string) {
        return this.effect({
            id: this.applyFiltersAndFetch,
            block: async (job) => {
                const filterValue: FilterValue[] = (() => {
                    if (workType === "all") {
                        return createDefaultFilter(DashboardFilterDefinitions[DashboardFilter.WorkType]).values
                    } else {
                        const workRequestType = workRequestTypeForValue(workType)
                        return [{ value: workRequestType, label: labelForWorkRequestType(workRequestType) }]
                    }
                })()

                var filters = {
                    ...this.state.filters,
                    ...{
                        [DashboardFilter.WorkType]: updateFilter(
                            this.state.filters[DashboardFilter.WorkType],
                            filterValue,
                            FilterUpdateType.Set
                        ),
                    },
                }

                this.fetchWorkRequestsCompletedWidgetData(filters)

                return Outcome.ok(undefined)
            },
        })
    }

    removeFilter = (type: DashboardFilter) =>
        this.applyFiltersAndFetch({
            ...this.state.filters,
            [type]: updateFilter(this.state.filters[type], this.state.filters[type].values, FilterUpdateType.Remove),
        })

    async selectedMechanicFilterChanged(mechanic: string | null, mechanicType: ContactType[] | null) {
        return this.effect({
            id: this.applyFiltersAndFetch,
            block: async (job) => {
                const filterValue: FilterValue[] = (() => {
                    if (mechanic === "all") {
                        this.update({ selectedMechanic: [] })
                        return createDefaultFilter(DashboardFilterDefinitions[DashboardFilter.AssignedTo]).values
                    } else {
                        const mechanicId = safeParseInt(mechanic)
                        const contact = this.idMapper.toContact(mechanicId)
                        if (!contact) {
                            this.update({ selectedMechanic: [] })
                            return []
                        }
                        this.update({ selectedMechanic: [contact] })
                        return [{ value: mechanicId, label: labelForContact(contact) }]
                    }
                })()

                const mechanicTypeFilterValue: FilterValue[] = (() => {
                    if (mechanicType === null) {
                        return createDefaultFilter(DashboardFilterDefinitions[DashboardFilter.MechanicTypes]).values
                    } else {
                        let arrayFilterValues: FilterValue[] = []

                        mechanicType.forEach((element) => {
                            arrayFilterValues.push({
                                value: valueForContactType(element),
                                label: labelForContactType(element),
                            })
                        })

                        return arrayFilterValues
                    }
                })()

                var filters = {
                    ...this.state.filters,
                    ...{
                        [DashboardFilter.AssignedTo]: updateFilter(
                            this.state.filters[DashboardFilter.AssignedTo],
                            filterValue,
                            FilterUpdateType.Set
                        ),
                        [DashboardFilter.MechanicTypes]: updateFilter(
                            this.state.filters[DashboardFilter.MechanicTypes],
                            mechanicTypeFilterValue,
                            FilterUpdateType.Set
                        ),
                    },
                }

                this.fetchWorkOrderStatusWidgetData(filters)

                return Outcome.ok(undefined)
            },
        })
    }

    hasLoadedChanged = (type: DashboardDataType, value: boolean) =>
        this.update({
            hasLoaded: {
                ...this.state.hasLoaded,
                [type]: value,
            },
        })

    fetchStatusCounts = (filters: Filter<DashboardFilter>[]) => {
        let workRequestFilters: Filter<WorkRequestFilter>[]
        let workOrderFilters: Filter<WorkOrderFilter>[]

        workRequestFilters = this.workRequestFilterFromDashboardFilters(filters)
        workOrderFilters = this.workOrderFilterFromDashboardFilters(filters)
        this.hasLoadedChanged(DashboardDataType.StatusWidget, false)
        this.effect({
            id: this.fetchStatusCounts,
            block: async (job) => {
                const counts = newStatusWidgetCounts()
                const [workRequestCountOutcome, workOrderCountOutcome] = await job.pause(
                    Promise.all([
                        this.workRequestService.fetchWorkRequestCounts(workRequestFilters),
                        this.workOrderService.fetchWorkOrderCounts(workOrderFilters),
                    ])
                )

                if (workRequestCountOutcome.isOk()) {
                    Object.values(WorkRequestQuickFilterCount).forEach((type) => {
                        counts[StatusWidgetGroup.WorkRequests][type] = workRequestCountOutcome.value[type]
                    })
                }

                if (workOrderCountOutcome.isOk()) {
                    Object.values(WorkOrderQuickFilterCount).forEach((type) => {
                        counts[StatusWidgetGroup.WorkOrders][type] = workOrderCountOutcome.value[type]
                    })
                }

                this.statusWidgetsChanged(counts)
                this.hasLoadedChanged(DashboardDataType.StatusWidget, true)
                return Outcome.ok(undefined)
            },
        })
    }

    private workRequestFilterFromDashboardFilters = (
        filters: Filter<DashboardFilter>[]
    ): Filter<WorkRequestFilter>[] => {
        let workRequestFilters: Filter<WorkRequestFilter>[]
        workRequestFilters = []
        const filterDefinitions = DashboardFilterDefinitions
        for (const filter of filters) {
            if (isEmptyFilterValue(filter)) continue
            if (filter.definition === filterDefinitions[DashboardFilter.District]) {
                this.addWorkRequestFilterItem(workRequestFilters, WorkRequestFilter.District, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.SubCompany]) {
                this.addWorkRequestFilterItem(workRequestFilters, WorkRequestFilter.SubCompany, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.SubDistrict]) {
                this.addWorkRequestFilterItem(workRequestFilters, WorkRequestFilter.SubDistrict, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Unit]) {
                this.addWorkRequestFilterItem(workRequestFilters, WorkRequestFilter.Unit, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Group]) {
                this.addWorkRequestFilterItem(workRequestFilters, WorkRequestFilter.Group, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Site]) {
                this.addWorkRequestFilterItem(workRequestFilters, WorkRequestFilter.Site, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.AssetType]) {
                this.addWorkRequestFilterItem(workRequestFilters, WorkRequestFilter.AssetType, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Category]) {
                this.addWorkRequestFilterItem(workRequestFilters, WorkRequestFilter.Category, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Class]) {
                this.addWorkRequestFilterItem(workRequestFilters, WorkRequestFilter.Class, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Make]) {
                this.addWorkRequestFilterItem(workRequestFilters, WorkRequestFilter.Make, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Model]) {
                this.addWorkRequestFilterItem(workRequestFilters, WorkRequestFilter.Model, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Search]) {
                this.addWorkRequestFilterItem(workRequestFilters, WorkRequestFilter.Search, filter.values)
            }
        }
        return workRequestFilters
    }

    private addWorkRequestFilterItem = (
        filters: Filter<WorkRequestFilter>[],
        definition: WorkRequestFilter,
        value: any
    ): void => {
        const filterDefinitions = WorkRequestsFilterDefinitions
        let workRequestFilter: Filter<WorkRequestFilter>
        workRequestFilter = createEmptyFilter(filterDefinitions[definition])
        workRequestFilter.values = value
        filters.push(workRequestFilter)
    }

    private workOrderFilterFromDashboardFilters = (filters: Filter<DashboardFilter>[]): Filter<WorkOrderFilter>[] => {
        let workOrderFilters: Filter<WorkOrderFilter>[]
        workOrderFilters = []
        const filterDefinitions = DashboardFilterDefinitions
        for (const filter of filters) {
            if (isEmptyFilterValue(filter)) continue
            if (filter.definition === filterDefinitions[DashboardFilter.District]) {
                this.addWorkOrderFilterItem(workOrderFilters, WorkOrderFilter.District, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.SubCompany]) {
                this.addWorkOrderFilterItem(workOrderFilters, WorkOrderFilter.SubCompany, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.SubDistrict]) {
                this.addWorkOrderFilterItem(workOrderFilters, WorkOrderFilter.SubDistrict, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Unit]) {
                this.addWorkOrderFilterItem(workOrderFilters, WorkOrderFilter.Unit, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Group]) {
                this.addWorkOrderFilterItem(workOrderFilters, WorkOrderFilter.Group, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Site]) {
                this.addWorkOrderFilterItem(workOrderFilters, WorkOrderFilter.Site, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.AssetType]) {
                this.addWorkOrderFilterItem(workOrderFilters, WorkOrderFilter.AssetType, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Category]) {
                this.addWorkOrderFilterItem(workOrderFilters, WorkOrderFilter.Category, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Class]) {
                this.addWorkOrderFilterItem(workOrderFilters, WorkOrderFilter.Class, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Make]) {
                this.addWorkOrderFilterItem(workOrderFilters, WorkOrderFilter.Make, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Model]) {
                this.addWorkOrderFilterItem(workOrderFilters, WorkOrderFilter.Model, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Search]) {
                this.addWorkOrderFilterItem(workOrderFilters, WorkOrderFilter.Search, filter.values)
            }
        }
        return workOrderFilters
    }

    private addWorkOrderFilterItem = (
        filters: Filter<WorkOrderFilter>[],
        definition: WorkOrderFilter,
        value: any
    ): void => {
        const filterDefinitions = WorkOrderFilterDefinitions
        let workOrderFilter: Filter<WorkOrderFilter>
        workOrderFilter = createEmptyFilter(filterDefinitions[definition])
        workOrderFilter.values = value
        filters.push(workOrderFilter)
    }

    statusWidgetsChanged = (statusWidgets: StatusWidgetCounts) => this.update({ statusWidgetCounts: statusWidgets })

    shouldShowStatusWidgetModal = (isOpen: boolean) => this.update({ isStatusWidgetSettingsModalOpen: isOpen })

    async fetchWorkOrderStatusWidgetData(filters: Record<DashboardFilter, Filter<DashboardFilter>> | null) {
        this.hasLoadedChanged(DashboardDataType.WorkOrderStatusWidget, false)

        const filtersArray = Object.values(filters ?? []).filter((filter) => !isEmptyFilterValue(filter))
        const data = await this.widgetService.fetchExistingWorkOrderStatuses(filtersArray)

        this.update({
            workOrderStatusWidgetData: data,
            hasLoaded: {
                ...this.state.hasLoaded,
                [DashboardDataType.WorkOrderStatusWidget]: true,
            },
        })
    }

    async fetchWorkRequestsCompletedWidgetData(filters: Record<DashboardFilter, Filter<DashboardFilter>> | null) {
        this.hasLoadedChanged(DashboardDataType.WorkRequestsCompletedWidget, false)

        const filtersArray = Object.values(filters ?? []).filter((filter) => !isEmptyFilterValue(filter))
        const data = await this.widgetService.fetchWorkRequestsCompletedByMonth(filtersArray)

        this.update({
            workRequestCompletedWidgetData: data,
            hasLoaded: {
                ...this.state.hasLoaded,
                [DashboardDataType.WorkRequestsCompletedWidget]: true,
            },
        })
    }
    selectedMechanicTypeChanged = (value: ContactType[]) => this.update({ selectedMechanicType: value })

    mechanicsChanged = (value: Contact[]) => this.update({ mechanics: value, selectedMechanic: value })

    async fetchRedYellowTagsWidgetData() {
        this.hasLoadedChanged(DashboardDataType.RedYellowTagsWidget, false)

        const filtersArray = Object.values(this.state.filters ?? []).filter((filter) => !isEmptyFilterValue(filter))
        const data = await this.widgetService.fetchRedYellowTagsByMonth(filtersArray)

        this.update({
            redYellowTagsWidgetData: data,
            hasLoaded: {
                ...this.state.hasLoaded,
                [DashboardDataType.RedYellowTagsWidget]: true,
            },
        })
    }
}

const newDashboardScreenState = (): DashboardScreenState => ({
    filters: arrayToObject(
        Object.values(DashboardFilter).map((filter) => createDefaultFilter(DashboardFilterDefinitions[filter])),
        (item) => [item.definition.type, item]
    ),
    mechanics: [],
    hasLoaded: {
        [DashboardDataType.StatusWidget]: false,
        [DashboardDataType.RedYellowTagsWidget]: false,
        [DashboardDataType.WorkOrderStatusWidget]: false,
        [DashboardDataType.WorkRequestsCompletedWidget]: false,
    },
    selectedMechanicType: [],
    statusWidgetCounts: newStatusWidgetCounts(),
    isStatusWidgetSettingsModalOpen: false,
    workOrderStatusWidgetData: arrayToObject(Object.values(WorkOrderStatus), (it) => [it, 0]),
    workRequestCompletedWidgetData: Array(12).fill(0),
    redYellowTagsWidgetData: Array(12).fill({ redTags: 0, yellowTags: 0 }),
    selectedMechanic: [],
})
