export type AssetCategory = {
    id: number
    typeId: number
    name: string
    description: string
}

export function newAssetCategory(): AssetCategory {
    return {
        id: 0,
        typeId: 0,
        name: "",
        description: "",
    }
}
