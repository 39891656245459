import { Strings } from "@lib/Strings"
import { TableColumn, TableColumnAlignment, TableColumnDef, TableColumnSize, TableRow } from "@model/tables/Table"
import { colorForUrgency, labelForUrgency, Urgency } from "@model/Urgency"
import {
    getMinDueDateAsNumber,
    labelForWorkRequest,
    labelForWorkRequestType,
    WorkRequest,
    WorkRequestStatus,
    WorkRequestType,
} from "@model/workRequests/WorkRequest"
import { FlagChip } from "@ui/common/FlagChip"
import React from "react"
import { Step } from "@model/Step"
import { Tag } from "@model/Tag"
import { HistoryItem } from "@model/HistoryItem"
import { Attachment } from "@model/Attachment"
import { Task } from "@model/Task"
import { ServiceRequestType } from "@model/serviceRequests/ServiceRequest"
import { Table } from "@ui/common/tables/Table"
import { WorkRequestStatusFlag } from "@model/workRequests/WorkRequestStatusFlag"
import { ServiceSchedule } from "@model/ServiceSchedule"

export enum WorkOrderHeaderTableColumn {
    WorkRequest = "workRequest",
    Type = "type",
    Urgency = "urgency",
    DueDate = "dueDate",
    DueOdometer = "dueOdometer",
    DueHourMeter = "dueHourMeter",
}

enum Color {
    grey = "#E3E7E8",
}

export type WorkRequestHeaderRow<T extends WorkRequest> = {
    id: number
    assetId: number
    step: Step | null
    billingCodeId: number | null
    schedule: ServiceSchedule | null
    workRequestType: WorkRequestType
    estimatedLaborHours: number
    estimatedLaborCost: number
    estimatedPartsCost: number
    actualLaborHours: number
    actualLaborCost: number
    actualPartsCost: number
    dateCreated: Date
    urgency: Urgency
    dueDate: Date | null
    dueOdometer: number | null
    dueHourMeter: number | null
    hoursUntil: number | null
    milesUntil: number | null
    daysUntil: number | null
    isUpcoming: boolean
    isOverdue: boolean
    tag: Tag | null
    attachments: Attachment[]
    history: HistoryItem<WorkRequestStatus>[]
    specialInstructions: string
    notes: string
    tasks: Task[] | null
    status: WorkRequestStatus
    workOrderId: number | null
    workToBePerformed: string
    workRequestStatusFlags: WorkRequestStatusFlag[]
    serviceCodeId: number | null
    serviceType: ServiceRequestType | null
    notifyContacts: number[]
    createdBy: number | null
    createdByName: string
    IsMaxDueDate: boolean
    IsMinDueOdomerter: boolean
    IsMinDueHourMeter: boolean
    statusFlagReason: string
    filesId: string
    followUpWorkRequestId: number | null
    serviceSubTypeId: number | null
    dismissed: boolean | null
    isUnplanned: boolean
}

export const WorkRequestHeaderTableColumnDefinitions: Record<
    WorkOrderHeaderTableColumn,
    TableColumnDef<WorkOrderHeaderTableColumn>
> = {
    [WorkOrderHeaderTableColumn.WorkRequest]: {
        type: WorkOrderHeaderTableColumn.WorkRequest,
        label: "Service Request",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Start,
        isRequired: false,
    },
    [WorkOrderHeaderTableColumn.Type]: {
        type: WorkOrderHeaderTableColumn.Type,
        label: "Type",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isRequired: false,
    },
    [WorkOrderHeaderTableColumn.Urgency]: {
        type: WorkOrderHeaderTableColumn.Urgency,
        label: "Urgency",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isRequired: false,
    },
    [WorkOrderHeaderTableColumn.DueDate]: {
        type: WorkOrderHeaderTableColumn.DueDate,
        label: "Due Date",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isRequired: false,
    },
    [WorkOrderHeaderTableColumn.DueHourMeter]: {
        type: WorkOrderHeaderTableColumn.DueHourMeter,
        label: "Due Hour Meter",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isRequired: false,
    },
    [WorkOrderHeaderTableColumn.DueOdometer]: {
        type: WorkOrderHeaderTableColumn.DueOdometer,
        label: "Due Odometer",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isRequired: false,
    },
}

function createColumnContent(
    column: WorkOrderHeaderTableColumn,
    row: WorkRequestHeaderRow<WorkRequest>
): JSX.Element | null {
    switch (column) {
        case WorkOrderHeaderTableColumn.WorkRequest:
            return <div>{labelForWorkRequest(row)}</div>
        case WorkOrderHeaderTableColumn.Type:
            return <div>{labelForWorkRequestType(row.workRequestType)}</div>
        case WorkOrderHeaderTableColumn.Urgency:
            return <FlagChip flags={[{ label: labelForUrgency(row.urgency), color: colorForUrgency(row.urgency) }]} />
        case WorkOrderHeaderTableColumn.DueDate:
            return <DueDateColumn row={row} />
        case WorkOrderHeaderTableColumn.DueHourMeter:
            return <DueHourMeterColumn row={row} />
        case WorkOrderHeaderTableColumn.DueOdometer:
            return <DueOdometerColumn row={row} />
        default:
            return null
    }
}

const DueDateLabel = (row: WorkRequestHeaderRow<WorkRequest>) => (row.dueDate ? Strings.formatDate(row.dueDate) : "")
const DueDateColumn = ({ row }: { row: WorkRequestHeaderRow<WorkRequest> }) => (
    <FlagChip flags={[{ label: DueDateLabel(row), color: row.IsMaxDueDate ? Color.grey : "" }]} />
)
const DueHourMeterColumn = ({ row }: { row: WorkRequestHeaderRow<WorkRequest> }) => (
    <FlagChip
        flags={[
            {
                label: row.dueHourMeter ? Strings.formatDecimal(row.dueHourMeter) : "",
                color: row.IsMinDueHourMeter ? Color.grey : "",
            },
        ]}
    />
)
const DueOdometerColumn = ({ row }: { row: WorkRequestHeaderRow<WorkRequest> }) => (
    <FlagChip
        flags={[
            {
                label: row.dueOdometer ? Strings.formatInteger(row.dueOdometer) : "",
                color: row.IsMinDueOdomerter ? Color.grey : "",
            },
        ]}
    />
)

function createTableHead(columns: WorkOrderHeaderTableColumn[]): TableColumn[] {
    return columns.map(
        (column): TableColumn => ({
            size: WorkRequestHeaderTableColumnDefinitions[column].size,
            alignment: WorkRequestHeaderTableColumnDefinitions[column].alignment,
            content: <div>{WorkRequestHeaderTableColumnDefinitions[column].label}</div>,
        })
    )
}

function createTableData(
    columns: WorkOrderHeaderTableColumn[],
    workRequests: WorkRequestHeaderRow<WorkRequest>[]
): TableRow[] {
    return workRequests.map((workRequest) => ({
        entryId: workRequest.id,
        columns: columns.map(
            (column): TableColumn => ({
                size: WorkRequestHeaderTableColumnDefinitions[column].size,
                alignment: WorkRequestHeaderTableColumnDefinitions[column].alignment,
                content: createColumnContent(column, workRequest),
                className: WorkRequestHeaderTableColumnDefinitions[column].className,
            })
        ),
    }))
}

export type WorkRequestHeaderTableProps = {
    workRequests: WorkRequestHeaderRow<WorkRequest>[]
}

export function WorkRequestHeaderTable(props: WorkRequestHeaderTableProps): JSX.Element {
    return (
        <Table
            className="table--header--work-requests"
            head={createTableHead(Object.values(WorkOrderHeaderTableColumn))}
            data={createTableData(Object.values(WorkOrderHeaderTableColumn), props.workRequests)}
        />
    )
}

export function mapToWorkRequestHeaderRows(workRequests: WorkRequest[]): WorkRequestHeaderRow<WorkRequest>[] {
    var MinDueDate = getMinDueDateAsNumber(workRequests)
    var MinDueOdometer = Math.min(...workRequests.map((x) => x.dueOdometer ?? 0))
    var MinHourMeter = Math.min(...workRequests.map((x) => x.dueHourMeter ?? 0))

    return workRequests.map((x) =>
        mapToWorkRequestHeaderRow(
            x,
            datesAreEqual(x.dueDate, MinDueDate),
            x.dueOdometer === MinDueOdometer,
            x.dueHourMeter === MinHourMeter
        )
    )
}

function mapToWorkRequestHeaderRow(
    obj: WorkRequest,
    isMaxDueDate: boolean,
    isMinDueOdomerter: boolean,
    isMinDueHourMeter: boolean
): WorkRequestHeaderRow<WorkRequest> {
    return {
        IsMaxDueDate: isMaxDueDate,
        IsMinDueOdomerter: isMinDueOdomerter,
        IsMinDueHourMeter: isMinDueHourMeter,
        ...obj,
    }
}
const datesAreEqual = (dueDate: Date | null, MinDueDate: number | null) =>
    MinDueDate != null && dueDate != null ? dueDate.getTime() === MinDueDate : false
