import { arrayToObject } from "@lib/TypeUtil"
import { Contact, ContactType } from "@model/contacts/Contact"
import { newServiceSubType, ServiceRequestType, ServiceSubType } from "@model/serviceRequests/ServiceRequest"
import { newThresholdGroup, ThresholdGroup } from "@model/Threshold"
import { WorkRequestType } from "@model/workRequests/WorkRequest"

export type CompanySettings = {
    readonly mechanics: Contact[]
    readonly mechanicTypes: ContactType[]
    readonly serviceSubTypes: Record<ServiceRequestType, ServiceSubType>
    readonly workOrderAutomation: WorkOrderAutomationSettings
    readonly thresholds: ThresholdSettings
}

export type WorkOrderAutomationSettings = {
    id: number | null
    companyId: number
    [WorkRequestType.Inspection]: boolean
    [WorkRequestType.Preventative]: boolean
    [WorkRequestType.Service]: boolean
    applyToSubCompanies: boolean | null
    isReadOnly: boolean
}

export type ThresholdSettings = {
    [WorkRequestType.Inspection]: ThresholdGroup
    [WorkRequestType.Preventative]: ThresholdGroup
    [WorkRequestType.Service]: ThresholdGroup
}

export function thresholdSettingsHasValue(settings: ThresholdSettings): boolean {
    return Object.values(settings).some(
        (value: ThresholdGroup) => value.hours.value !== null || value.miles.value !== null || value.days.value !== null
    )
}

export function newCompanySettings(): CompanySettings {
    return {
        mechanics: [],
        mechanicTypes: [
            ContactType.ThirdParty,
            ContactType.Contractor,
            ContactType.Customer,
            ContactType.Employee,
            ContactType.Vendor,
        ],
        serviceSubTypes: arrayToObject(Object.values(ServiceRequestType), (type) => [type, newServiceSubType(type)]),
        workOrderAutomation: newWorkOrderAutomationSettings(),
        thresholds: newThresholdSettings(),
    }
}

export function newThresholdSettings(): ThresholdSettings {
    return {
        [WorkRequestType.Inspection]: newThresholdGroup(),
        [WorkRequestType.Preventative]: newThresholdGroup(),
        [WorkRequestType.Service]: newThresholdGroup(),
    }
}

export function newWorkOrderAutomationSettings(): WorkOrderAutomationSettings {
    return {
        id: null,
        companyId: 0,
        [WorkRequestType.Inspection]: false,
        [WorkRequestType.Preventative]: false,
        [WorkRequestType.Service]: false,
        applyToSubCompanies: null,
        isReadOnly: false
    }
}
