import { labelForServiceQuoteStatus, ServiceQuoteStatus, serviceQuoteStatusItemForValue } from "@model/serviceQuotes/ServiceQuote"
import { ServiceQuoteStatusIndicator } from "@ui/serviceQuotes/ServiceQuoteStatusIndicator"
import React from "react"
import { RoundedSelect } from "./RoundedSelect"

export type ServiceQuoteStatusSelectProps = {
    status: ServiceQuoteStatus
    onChange: (status: ServiceQuoteStatus) => void
    disabled?: boolean,
    filterPredicate?: (x: ServiceQuoteStatus) => boolean
}

export function ServiceQuoteStatusSelect({
    status,
    onChange,
    disabled,
    filterPredicate
}: ServiceQuoteStatusSelectProps): JSX.Element {

    return (
        <RoundedSelect
            className="select--service-quote-status"
            preValueContent={<ServiceQuoteStatusIndicator status={status} />}
            options={filterPredicate != undefined ? Object.values(ServiceQuoteStatus).filter(filterPredicate).map((it) => ({   
                value: it,
                label: labelForServiceQuoteStatus(it),
            }))
            : Object.values(ServiceQuoteStatus).map((it) => ({   
                value: it,
                label: labelForServiceQuoteStatus(it),
            }))}
            onChange={(value) => onChange(serviceQuoteStatusItemForValue(value))}
            value={status}
            isEnabled={!disabled ?? true}
        />
    )
}
