import { classNames } from "@lib/HtmlUtil"
import React from "react"

export type SelectProps = {
    options: SelectOption[]
    onChange?: (value: string) => void
    theme?: SelectTheme
    preValueContent?: React.ReactNode
    isEnabled?: boolean
} & Omit<
    React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>,
    "children" | "onChange"
>

export enum SelectTheme {
    Light = "light",
    Normal = "normal",
    Dark = "dark",
}

export type SelectOption = {
    value: string
    label: string
}

export function Select({
    options = [],
    theme = SelectTheme.Normal,
    preValueContent,
    className,
    isEnabled = true ,
    ...props
}: SelectProps) {
    return (
        <div
            className={classNames("select", className)}
            data-theme={theme}
            data-has-pre-content={preValueContent !== undefined}
        >
            {preValueContent}
            <select {...props} onChange={(ev) => props.onChange?.(ev.currentTarget.value)} disabled={!isEnabled ? true : undefined}>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            <div className="select-border"></div>
        </div>
    )
}
