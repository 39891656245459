import { arrayToObject } from "@lib/TypeUtil"
import { Filter, FilterValue, isEmptyFilter, isEmptyFilterValue, isFilterGroupEqual } from "@model/filters/Filter"
import { clampThresholdFilter } from "@model/Threshold"
import { WorkRequestFilter } from "@model/workRequests/WorkRequest"
import { WorkRequestsFilterDefinitions } from "@model/workRequests/WorkRequestsFilterDefinitions"
import { FilterOverlayBloc, FilterOverlayState } from "@state/filters/FilterOverlayBloc"

export type WorkRequestsFilterOverlayState = FilterOverlayState<WorkRequestFilter>

export class WorkRequestsFilterOverlayBloc extends FilterOverlayBloc<WorkRequestFilter> {
    constructor() {
        super(Object.values(WorkRequestFilter), WorkRequestsFilterDefinitions)
    }

    override computed(state: FilterOverlayState<WorkRequestFilter>) {
        const updated: FilterOverlayState<WorkRequestFilter> = {
            ...state,
            filters: {
                ...state.filters,
                [WorkRequestFilter.DaysUntil]: ((): Filter<WorkRequestFilter> => {
                    const filter = state.filters[WorkRequestFilter.DaysUntil]
                    const unitFilter = state.filters[WorkRequestFilter.DaysUntilUnit]
                    return clampThresholdFilter(filter, unitFilter)
                })(),
                [WorkRequestFilter.HoursUntil]: ((): Filter<WorkRequestFilter> => {
                    const filter = state.filters[WorkRequestFilter.HoursUntil]
                    const unitFilter = state.filters[WorkRequestFilter.HoursUntilUnit]
                    return clampThresholdFilter(filter, unitFilter)
                })(),
                [WorkRequestFilter.MilesUntil]: ((): Filter<WorkRequestFilter> => {
                    const filter = state.filters[WorkRequestFilter.MilesUntil]
                    const unitFilter = state.filters[WorkRequestFilter.MilesUntilUnit]
                    return clampThresholdFilter(filter, unitFilter)
                })(),
            },
        }

        return { ...super.computed(updated) }
    }

    override getSearchFilter() {
        return WorkRequestFilter.Search
    }

    override getSecondaryFilter() {
        return WorkRequestFilter.Search
    }

    override updateFilter(type: WorkRequestFilter, value: FilterValue[]) {
        if ([WorkRequestFilter.DaysUntil, WorkRequestFilter.MilesUntil, WorkRequestFilter.HoursUntil].includes(type)) {
            super.updateFilter(WorkRequestFilter.Upcoming, [{ value: false }])
        } else if (type === WorkRequestFilter.Upcoming) {
            super.updateFilter(WorkRequestFilter.MilesUntil, [{ value: "" }])
            super.updateFilter(WorkRequestFilter.HoursUntil, [{ value: "" }])
            super.updateFilter(WorkRequestFilter.DaysUntil, [{ value: "" }])
        }
        return super.updateFilter(type, value)
    }

    override isPresetModified(state: FilterOverlayState<WorkRequestFilter>): boolean {
        if (state.preset === null) return false

        const daysUntilFilter = state.filters[WorkRequestFilter.DaysUntil]
        const milesUntilFilter = state.filters[WorkRequestFilter.MilesUntil]
        const hoursUntilFilter = state.filters[WorkRequestFilter.HoursUntil]
         
        return !isFilterGroupEqual(
            arrayToObject(state.preset.filters, (it) => [it.definition.type, it]),
            arrayToObject(
                Object.values<Filter<WorkRequestFilter>>(state.filters).filter((it) => {
                    if (it.definition.type === WorkRequestFilter.DaysUntilUnit && isEmptyFilterValue(daysUntilFilter)) {
                        return false
                    }
                    if (
                        it.definition.type === WorkRequestFilter.MilesUntilUnit &&
                        isEmptyFilterValue(milesUntilFilter)
                    ) {
                        return false
                    }
                    if (
                        it.definition.type === WorkRequestFilter.HoursUntilUnit &&
                        isEmptyFilterValue(hoursUntilFilter)
                    ) {
                        return false
                    }
                    if (
                        it.definition.type === WorkRequestFilter.RedTag &&
                        isEmptyFilterValue(it)
                    ) {
                        return false
                    }
                    if (
                        it.definition.type === WorkRequestFilter.YellowTag &&
                        isEmptyFilterValue(it)
                    ) {
                        return false
                    }
                    if (
                        it.definition.type === WorkRequestFilter.Dismissed &&
                        isEmptyFilterValue(it)
                    ) {
                        return false
                    }

                    return !isEmptyFilter(it)
                }),
                (it) => [it.definition.type, it]
            )
        )
    }
}
