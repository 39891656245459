import { Outcome } from "@ethossoftworks/outcome"
import { SortOrder } from "@lib/Comparable"
import { Filter } from "@model/filters/Filter"
import { PagedResponse, Pagination } from "@model/Pagination"
import {
    UserWorkRequestsTableColumn,
    UserWorkRequestsTableRow,
    WorkRequestFilter,
} from "@model/workRequests/WorkRequest"
import { WorkRequestsFilterDefinitions } from "@model/workRequests/WorkRequestsFilterDefinitions"
import { AssetService } from "@service/assets/AssetService"
import { WorkOrderService } from "@service/workOrders/WorkOrderService"
import { WorkRequestService } from "@service/workRequests/WorkRequestService"
import { SortableTableBloc, SortableTableState } from "@state/SortableTableBloc"

export type UserWorkRequestTableState = SortableTableState<
    UserWorkRequestsTableColumn,
    UserWorkRequestsTableRow,
    WorkRequestFilter,
    ExtraState
>
type ExtraState = {}

export enum UserWorkRequestsEffect {
    FetchData = "fetchAssetWorkRequests",
}

export class UserWorkRequestsBloc extends SortableTableBloc<
    UserWorkRequestsTableColumn,
    UserWorkRequestsTableRow,
    WorkRequestFilter,
    ExtraState
> {
    constructor(
        private assetService: AssetService,
        private workRequestService: WorkRequestService,
        private workOrderService: WorkOrderService
    ) {
        super({
            filters: Object.values(WorkRequestFilter),

            filterDefinitions: WorkRequestsFilterDefinitions,

            defaultManagedColumns: [],

            readOnlyManagedColumns: [],

            dataFetcher: async (
                filters?: Filter<WorkRequestFilter>[],
                sort?: { column: UserWorkRequestsTableColumn; order: SortOrder },
                pagination?: Pagination
            ): Promise<Outcome<PagedResponse<UserWorkRequestsTableRow[]>>> => {
                if (!sort) sort = { column: UserWorkRequestsTableColumn.Asset, order: SortOrder.Asc }
                const results = await workRequestService.fetchWorkRequestsByUser(filters, sort, pagination)
                return results
            },
            initialExtraState: {},
            persistStateOnDispose: true,
            defaultFiltersOverride: (filters) => {
                return {}
            },
        })
    }
}
