import { Bloc } from "@lib/bloc/Bloc"
import { FilterPresetForm } from "@model/filters/FilterPresets"

export type CreateFilterPresetState = {
    readonly form: FilterPresetForm
    readonly isFormValid: boolean
    readonly isNameUsed: boolean
    readonly unavailableNames: string[]
}

export class CreateFilterPresetBloc extends Bloc<CreateFilterPresetState> {
    constructor() {
        super(
            {
                form: { isDefault: false, name: "" },
                isFormValid: false,
                unavailableNames: [],
                isNameUsed: false,
            },
            { persistStateOnDispose: false }
        )
    }

    override computed = (state: CreateFilterPresetState): Partial<CreateFilterPresetState> => ({
        isFormValid:
            state.form.name.trim() !== "" &&
            state.form.name.length <= 100 &&
            !state.unavailableNames.includes(state.form.name.toLocaleLowerCase()),
        isNameUsed: state.unavailableNames.includes(state.form.name.toLocaleLowerCase()),
    })

    formChanged = (form: Partial<FilterPresetForm>) => this.update({ form: { ...this.state.form, ...form } })
    unavailableNamesChanged = (names: string[]) =>
        this.update({ unavailableNames: names.map((it) => it.toLocaleLowerCase()) })
}
