export type Attachment = {
    url: string
    name: string
    type: AttachmentType
}

export enum AttachmentType {
    Image = "image",
    File = "file",
}
