export enum ServiceRequestType {
    Repair = "repair",
    FollowUp = "followUp",
    Inspection = "inspection",
    Warranty = "warranty",
}

export type ServiceSubType = {
    type: ServiceRequestType
    id: number
    companyId: number
    name: string
    alias: string | null
}

export function newServiceSubType(type: ServiceRequestType): ServiceSubType {
    return {
        type: type,
        id: -1,
        companyId: -1,
        name: "",
        alias: null,
    }
}

export function labelForServiceRequestType(
    type: ServiceRequestType,
    settings?: Record<ServiceRequestType, ServiceSubType>
) {
    switch (type) {
        case ServiceRequestType.Repair:
            return settings?.[type].alias ?? "Repair"
        case ServiceRequestType.FollowUp:
            return settings?.[type].alias ?? "Follow-Up"
        case ServiceRequestType.Inspection:
            return settings?.[type].alias ?? "Inspection"
        case ServiceRequestType.Warranty:
            return settings?.[type].alias ?? "Warranty"
    }
}

export function serviceRequestTypeForValue(value: string): ServiceRequestType {
    switch (value) {
        case ServiceRequestType.Repair:
            return ServiceRequestType.Repair
        case ServiceRequestType.FollowUp:
            return ServiceRequestType.FollowUp
        case ServiceRequestType.Inspection:
            return ServiceRequestType.Inspection
        case ServiceRequestType.Warranty:
            return ServiceRequestType.Warranty
        default:
            return ServiceRequestType.Repair
    }
}

export type ServiceCode = {
    id: number
    name: string
    code: string
    companyId: number
    associatedWithWorkRequest: boolean
    associatedWithWorkOrder: boolean
}

export function labelForServiceCode(code: ServiceCode): string {
    if (code) {
        return `${code.code} ${code.name}`
    }
    else {
        return '';
    }    
}

export type ServiceSchedule = {
    id: number
    name: string
    serviceScheduleType: ServiceScheduleType | null
    companyId: number
    odometer: number | null
    hourMeter: number | null
    days: number | null
    targetDate: Date | null
    associatedWithWorkRequest: boolean
}

export enum ServiceScheduleType {
    Preventative = "preventative",
    Inspection = "inspection",
}

export function labelForServiceSchedule(code: ServiceSchedule): string {
    return code.name
}
