import { useIsInIframe } from "@lib/Hooks"
import { classNames } from "@lib/HtmlUtil"
import { ButtonCont } from "@ui/common/buttons/Button"
import React from "react"

export function PageToolbar({ children }: { children?: React.ReactNode }): JSX.Element {
    const isInIframe = useIsInIframe()
    let hasActionButtons = false
    let hasTitle = false
    let hasButtons = false

    React.Children.toArray(children).forEach((it) => {
        if (!React.isValidElement(it)) return
        if (it.type === PageToolbarTitle) hasTitle = true
        if (it.type === PageToolbarActionButtons) hasActionButtons = true
        if (it.type === PageToolbarButtons) hasButtons = true
    })

    return (
        <div
            className={classNames("page-toolbar", isInIframe ? "page-toolbar--no-header" : null)}
            {...(hasTitle && { "data-has-title": true })}
            {...(hasActionButtons && { "data-has-action-buttons": true })}
            {...(hasButtons && { "data-has-buttons": true })}
        >
            {children}
        </div>
    )
}

export function PageToolbarTitle({ children }: { children?: React.ReactNode }): JSX.Element {
    return <div className="page-toolbar-title">{children}</div>
}

export function PageToolbarActionButtons({ children }: { children?: React.ReactNode }): JSX.Element {
    return <ButtonCont className="page-toolbar-action-button-cont">{children}</ButtonCont>
}

export function PageToolbarButtons({ children }: { children?: React.ReactNode }): JSX.Element {
    return <ButtonCont className="page-toolbar-button-cont">{children}</ButtonCont>
}
