import { arrayToObject } from "@lib/TypeUtil"
import { WorkOrderQuickFilterCount } from "@model/workOrders/WorkOrder"
import { WorkRequestQuickFilterCount } from "@model/workRequests/WorkRequest"

export enum StatusWidgetGroup {
    WorkRequests = "Work Requests",
    WorkOrders = "Work Orders",
}

export type StatusWidgetCounts = {
    readonly [StatusWidgetGroup.WorkRequests]: Record<WorkRequestQuickFilterCount, number>
    readonly [StatusWidgetGroup.WorkOrders]: Record<WorkOrderQuickFilterCount, number>
}

export function newStatusWidgetCounts(): StatusWidgetCounts {
    return {
        [StatusWidgetGroup.WorkRequests]: arrayToObject(Object.values(WorkRequestQuickFilterCount), (it) => [it, 0]),
        [StatusWidgetGroup.WorkOrders]: arrayToObject(Object.values(WorkOrderQuickFilterCount), (it) => [it, 0]),
    }
}
