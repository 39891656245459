import { BlocCoordinator } from "@lib/bloc/BlocCoordinator"
import { isRouteMatch, Route } from "@lib/router/route"
import { withRouter } from "@lib/router/router"
import { Routes } from "@lib/Routes"
import { Strings } from "@lib/Strings"
import { safeParseFloat, safeParseInt } from "@lib/TypeUtil"
import { BillingCode } from "@model/company/BillingCode"
import { Contact } from "@model/contacts/Contact"
import {
    ServiceCode,
    ServiceRequestType,
    serviceRequestTypeForValue,
    ServiceSubType,
} from "@model/serviceRequests/ServiceRequest"
import { TagType } from "@model/Tag"
import { TaskValue } from "@model/Task"
import { urgencyForValue } from "@model/Urgency"
import { PermissionObject, PermissionType } from "@model/user/User"
import { CompanyBloc, CompanyState } from "@state/company/CompanyBloc"
import { StatusBloc } from "@state/StatusBloc"
import { UserBloc, UserState } from "@state/user/UserBloc"
import { AssetWorkRequestsBloc } from "@state/workRequests/AssetWorkRequestsBloc"
import {
    WorkRequestDetailsEffect,
    WorkRequestDetailsScreenBloc,
    WorkRequestDetailsScreenState,
} from "@state/workRequests/WorkRequestDetailsScreenBloc"
import { Attachment } from "../../model/Attachment"

type Dependencies = [WorkRequestDetailsScreenState, CompanyState, UserState]
type StateSelection = WorkRequestDetailsScreenState & {
    isLoading: boolean
    estimatedTotalCost: string
    isFormValid: boolean
    hasDataFetchError: boolean
    serviceSubTypes: Record<ServiceRequestType, ServiceSubType>
    serviceCodes: ServiceCode[]
    billingCodes: BillingCode[]
    contacts: Contact[]
}

export class WorkRequestDetailsModalViewModel extends BlocCoordinator<Dependencies, StateSelection> {
    constructor(
        private workRequestDetailsBloc: WorkRequestDetailsScreenBloc,
        private assetWorkRequestsBloc: AssetWorkRequestsBloc,
        private companyBloc: CompanyBloc,
        private statusBloc: StatusBloc,
        private userBloc: UserBloc
    ) {
        super([workRequestDetailsBloc, companyBloc, userBloc])
    }

    transform = ([workRequestDetailsState, companyState, userState]: Dependencies): StateSelection => ({
        ...workRequestDetailsState,
        isLoading: workRequestDetailsState.effectStatus[WorkRequestDetailsEffect.FetchData].isBusy(),
        estimatedTotalCost: Strings.formatMoney(
            safeParseFloat(workRequestDetailsState.form?.estimatedLaborCost) +
                safeParseFloat(workRequestDetailsState.form?.estimatedPartsCost)
        ),
        hasDataFetchError: workRequestDetailsState.effectStatus[WorkRequestDetailsEffect.FetchData].isError(),
        isFormValid:
            (workRequestDetailsState.form.tag === null ||
                (workRequestDetailsState.form.tag !== null && workRequestDetailsState.form.tag.reason.trim() !== "")) &&
            // workRequestDetailsState.workRequest?.workRequestType === WorkRequestType.Service &&
            workRequestDetailsState.form.workToBePerformed !== "",
        serviceSubTypes: companyState.settings.serviceSubTypes,
        serviceCodes: Object.values(companyState.serviceCodes),
        billingCodes: Object.values(companyState.billingCodes),
        contacts: Object.values(companyState.contacts),
    })

    onMounted = async (workRequestId: number, assetId: number) => {
        this.workRequestDetailsBloc.fetchData(workRequestId, assetId)
    }

    loadWorkRequestHistory = async (workRequestId: number) => {
        this.workRequestDetailsBloc.fetchHistoryData(workRequestId)
    }

    fetchDataErrorCancelClicked = withRouter(
        (router) => (assetId: string) => router.navigate(Routes.AssetWorkRequests(assetId))
    )

    getUser = () => this.userBloc.state.user

    serviceTypeChanged = (value: string) =>
        this.workRequestDetailsBloc.serviceTypeChanged(serviceRequestTypeForValue(value) ?? ServiceRequestType.FollowUp)
    serviceCodeChanged = (value: ServiceCode | null) => this.workRequestDetailsBloc.serviceCodeChanged(value)
    workToBePerformedChanged = (value: string) => this.workRequestDetailsBloc.workToBePerformedChanged(value)

    stepChanged = (value: string) => this.workRequestDetailsBloc.stepChanged(safeParseInt(value))
    billingCodeChanged = (value: BillingCode | null) => this.workRequestDetailsBloc.billingCodeChanged(value)
    urgencyChanged = (value: string) => this.workRequestDetailsBloc.urgencyChanged(urgencyForValue(value))
    dueDateChanged = (date: Date) => this.workRequestDetailsBloc.dueDateFormFieldChanged(date)
    dueHourMeterChanged = (value: string) => this.workRequestDetailsBloc.dueHourMeterChanged(value)
    dueOdometerChanged = (value: string) => this.workRequestDetailsBloc.dueOdometerChanged(value)
    estimatedLaborHoursChanged = (value: string) => this.workRequestDetailsBloc.estimatedLaborHoursChanged(value)
    estimatedLaborCostChanged = (value: string) => this.workRequestDetailsBloc.estimatedLaborCostChanged(value)
    estimatedPartsCostChanged = (value: string) => this.workRequestDetailsBloc.estimatedPartsCostChanged(value)
    specialInstructionsChanged = (value: string) => this.workRequestDetailsBloc.specialInstructionsChanged(value)
    notesChanged = (value: string) => this.workRequestDetailsBloc.notesChanged(value)
    tagTypeChanged = (tag: TagType | null) => this.workRequestDetailsBloc.tagTypeFormFieldChanged(tag)
    tagReasonChanged = (reason: string) => this.workRequestDetailsBloc.tagReasonFormFieldChanged(reason)
    toggleShowTaskDescriptions = () => this.workRequestDetailsBloc.toggleShowTaskDescriptions()
    requestSelectAllTasksToggled = () => this.workRequestDetailsBloc.requestSelectAllTasksToggled()

    requestNotifyContactChanged = (contactIds: number[]) =>
        this.workRequestDetailsBloc.requestNotifyContactChanged(contactIds)

    requestTasksChanged = (taskId: number, value: TaskValue, measurement?: string) =>
        this.workRequestDetailsBloc.requestTaskChanged(taskId, value, measurement)

    onWorkRequestModalClosed = (assetId: number, route: Route) => {
        this.workRequestDetailsBloc.clearNewAttachmentsOnCancel()

        withRouter((router) => {
            if (isRouteMatch(route, Routes.UserWorkRequest))
                router.navigate(Routes.UserWorkRequests(this.userBloc.state.user.id.toString()))
            else router.navigate(Routes.AssetWorkRequests(assetId.toString()))
        })
    }

    onSaveClicked = async (route: Route) => {
        const request = this.workRequestDetailsBloc.state.workRequest
        if (!request) return

        const message = this.statusBloc.enqueueInfoMessage("Saving service request...")
        const response = await this.workRequestDetailsBloc.updateWorkRequest()

        this.statusBloc.hideInfoMessage(message)
        if (response.isError()) {
            this.statusBloc.enqueueErrorMessage("Error saving service request")
            return
        }

        this.assetWorkRequestsBloc.workRequestUpdated(response.value.updatedWorkRequest)
        if (response.value.updatedAsset) this.assetWorkRequestsBloc.assetChanged(response.value.updatedAsset)
        withRouter((router) => {
            if (isRouteMatch(route, Routes.UserWorkRequest))
                router.navigate(Routes.UserWorkRequests(this.userBloc.state.user.id.toString()))
            else router.navigate(Routes.AssetWorkRequests(request.assetId.toString()))
        })
    }

    attachmentsChanged = async (files: File[], formData: FormData) => {
        const message = this.statusBloc.enqueueInfoMessage("Attaching File...")
        const outcome = await this.workRequestDetailsBloc.attachmentsChanged(
            files,
            formData,
            this.userBloc.state.user.id,
            this.userBloc.state.user.companyId
        )
        this.statusBloc.hideInfoMessage(message)
    }
    attachmentDeleted = (attachment: Attachment) =>
        this.workRequestDetailsBloc.attachmentsDelete(
            attachment.name,
            this.userBloc.state.user.id,
            this.userBloc.state.user.companyId
        )
    rotateImage = (attachment: Attachment, angle: number) =>
        this.workRequestDetailsBloc.rotateImage(
            attachment.name,
            angle,
            this.userBloc.state.user.id,
            this.userBloc.state.user.companyId
        )

    attachmentError = (errors: string[]) => this.statusBloc.enqueueErrorMessage(errors.join("<br>"))

    hasWorkRequestEditPermission = () =>
        this.userBloc.state.user.hasAccess(PermissionObject.WorkRequest, PermissionType.Edit)

    hasRedYellowTagAddPermission = () =>
        this.userBloc.state.user.hasAccess(PermissionObject.RedYellowTag, PermissionType.Add)

    hasRedYellowTagAddDeletePermission = () =>
        this.userBloc.state.user.hasAccess(PermissionObject.RedYellowTag, [PermissionType.Add, PermissionType.Delete])
}
