import { arrayToObject } from "@lib/TypeUtil"
import { DashboardFilter } from "@model/dashboard/DashboardFilter"
import { DashboardFilterDefinitions } from "@model/dashboard/DashboardFilterDefinitions"
import { Filter, isEmptyFilterValue, isFilterGroupEqual } from "@model/filters/Filter"
import { FilterOverlayBloc, FilterOverlayState } from "@state/filters/FilterOverlayBloc"

export type DashboardFilterOverlayState = FilterOverlayState<DashboardFilter>

export class DashboardFilterOverlayBloc extends FilterOverlayBloc<DashboardFilter> {
    constructor() {
        super(Object.values(DashboardFilter), DashboardFilterDefinitions)
    }

    override computed(state: FilterOverlayState<DashboardFilter>) {
        const updated: FilterOverlayState<DashboardFilter> = {
            ...state,
            filters: {
                ...state.filters,
            },
        }

        return { ...super.computed(updated) }
    }

    override getSearchFilter() {
        return DashboardFilter.Search
    }

    override getSecondaryFilter?() {
       return DashboardFilter.Search
    }

    override isPresetModified(state: FilterOverlayState<DashboardFilter>): boolean {
        if (state.preset === null) return false

        return !isFilterGroupEqual(
            arrayToObject(state.preset.filters, (it) => [it.definition.type, it]),
            arrayToObject(
                Object.values<Filter<DashboardFilter>>(state.filters).filter((it) => {
                    return !isEmptyFilterValue(it)
                }),
                (it) => [it.definition.type, it]
            )
        )
    }
}
