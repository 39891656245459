import { PDFAssetPart } from "@lib/pdf/models/PDFAssetPart"

export type AssetPart = {
    id: number
    quantity: number,
    unit: string,
    partNumber: string,
    description: string,
    vendor: string,
    note: string,
    vendorId: number,
    companyId: number,
}

export function newAssetPart(): AssetPart {
    return {
        id: 0,
        quantity: 0,
        unit: "",
        partNumber: "",
        description: "",
        vendor: "",
        note: "",
        companyId: 0,
        vendorId: 0
    }
}

export function labelForAssetPartUnit(unitId: number): string {
    switch (unitId) {
        case 1:
            return "Milliliter (ml)"
        case 2:
            return "Liter (l)"
        case 3:
            return "Fluid Ounce (fl.oz.)"
        case 4:
            return "Cup (c)"
        case 5:
            return "Pint (pt)"
        case 6:
            return "Quart (qt)"
        case 7:
            return "Gallon (gal)"
        case 8:
            return "Millimeter (mm)"
        case 9:
            return "Centimeter (cm)"
        case 10:
            return "Meter (m)"
        case 11:
            return "Inch (in)"
        case 12:
            return "Foot (ft)"
        case 13:
            return "Yard (yd)"
        case 14:
            return "Milligram (mg)"
        case 15:
            return "Gram (g)"
        case 16:
            return "Kilogram (kg)"
        case 17:
            return "Ounce (oz)"
        case 18:
            return "Pound (lb)"
        case 19:
            return "Unit"
        default:
            return ""
    }
}

export function pdfAssetPartFromModel(asset: AssetPart): PDFAssetPart {
    return {
        quantity: asset.quantity,
        unit: asset.unit,
        partNumber: asset.partNumber,
        description: asset.description,
        vendor: asset.vendor,
        note: asset.note,
    }
}