import { useLastNonNull } from "@lib/Hooks"
import { labelForWorkRequest, WorkRequest } from "@model/workRequests/WorkRequest"
import { Button, ButtonType } from "@ui/common/buttons/Button"
import { Grid } from "@ui/common/grids/Grid"
import { Modal, ModalBody, ModalButtons, ModalContent, ModalHeader, ModalHeaderTitle } from "@ui/common/Modal"
import React from "react"

export function RemoveWorkRequestModal({
    workRequest,
    onCancel,
    onConfirm,
}: {
    workRequest: WorkRequest | null
    onCancel: () => void
    onConfirm: (WorkRequest: WorkRequest) => void
}): JSX.Element {
    const data = useLastNonNull(workRequest)
    if (data === null) return <Modal isVisible={workRequest !== null} />

    return (
        <Modal isVisible={workRequest !== null} useContentTag={true} onClose={onCancel}>
            <ModalContent>
                <ModalHeader>
                    <ModalHeaderTitle>Remove Service Request</ModalHeaderTitle>
                </ModalHeader>
                <ModalBody>
                    <Grid columns={1}>
                        <div>
                            Are you sure you wish to remove the following Service Request from this Work Order? Removing a
                            Service Request from a Work order will return it to the queue of Service Requests.
                        </div>
                        <i>{labelForWorkRequest(data)}</i>
                    </Grid>
                </ModalBody>
                <ModalButtons>
                    <Button label="Cancel" type={ButtonType.Text} onClick={onCancel} />
                    <Button label="Remove Service Request" onClick={() => onConfirm(data)} />
                </ModalButtons>
            </ModalContent>
        </Modal>
    )
}
