export enum CompanyAlias {
    District = "districtAlias",
    SubDistrict = "subDistrictAlias",
    Unit = "unitAlias",
    SubCompany = "subCompanyAlias",
    Group = "groupAlias",
}

export function defaultCompanyAlias(alias: CompanyAlias): string {
    switch (alias) {
        case CompanyAlias.District:
            return "District"
        case CompanyAlias.Group:
            return "Group"
        case CompanyAlias.SubCompany:
            return "Sub Company"
        case CompanyAlias.SubDistrict:
            return "Sub District"
        case CompanyAlias.Unit:
            return "Unit"
    }
}
