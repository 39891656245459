import { arrayToObject } from "@lib/TypeUtil"
import { DashboardFilter } from "@model/dashboard/DashboardFilter"
import { createDefaultFilter, Filter, FilterDef } from "@model/filters/Filter"
import { ServiceQuoteFilter } from "@model/serviceQuotes/ServiceQuote"
import { WorkOrderFilter } from "@model/workOrders/WorkOrder"
import { WorkRequestFilter } from "@model/workRequests/WorkRequest"

export type FilterPresets = {
    readonly workRequests: FilterPreset<WorkRequestFilter>[]
    readonly workOrders: FilterPreset<WorkOrderFilter>[]
    readonly dashboard: FilterPreset<DashboardFilter>[]
    readonly serviceQuotes: FilterPreset<ServiceQuoteFilter>[]
}

export type FilterPreset<T extends string> = {
    readonly type: FilterPresetType
    readonly id: number
    readonly name: string
    readonly isDefault: boolean
    readonly filters: Filter<T>[]
}

export enum FilterPresetType {
    Dashboard = "dashboard",
    WorkRequest = "workRequest",
    WorkOrder = "workOrder",
    ServiceQuote = "serviceQuote",
}

export type FilterPresetForm = {
    isDefault: boolean
    name: string
}

export function isWorkRequestFilterPreset(filter: FilterPreset<any>): filter is FilterPreset<WorkRequestFilter> {
    return filter.type === FilterPresetType.WorkRequest
}

export function isWorkOrderFilterPreset(filter: FilterPreset<any>): filter is FilterPreset<WorkOrderFilter> {
    return filter.type === FilterPresetType.WorkOrder
}
export function isDashboardFilterPreset(filter: FilterPreset<any>): filter is FilterPreset<DashboardFilter> {
    return filter.type === FilterPresetType.Dashboard
}

export function isServiceQuoteFilterPreset(filter: FilterPreset<any>): filter is FilterPreset<ServiceQuoteFilter> {
    return filter.type === FilterPresetType.ServiceQuote
}

export function createFilterGroupFromPreset<T extends string>(
    preset: FilterPreset<T> | null,
    definitions: Record<T, FilterDef<T>>
): Record<T, Filter<T>> {
    return {
        ...arrayToObject(Object.values(definitions) as FilterDef<T>[], (def) => [def.type, createDefaultFilter(def)]),
        ...(preset !== null ? arrayToObject(preset.filters, (it) => [it.definition.type, it]) : {}),
    }
}
