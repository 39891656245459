import { Outcome } from "@ethossoftworks/outcome"
import { Bloc } from "@lib/bloc/Bloc"
import { Strings } from "@lib/Strings"
import { arrayToObject, safeParseFloat, safeParseInt } from "@lib/TypeUtil"
import { Asset } from "@model/assets/Asset"
import { AssetPart } from "@model/assets/AssetPart"
import { AsyncStatus } from "@model/AsyncStatus"
import { Attachment } from "@model/Attachment"
import { HistoryItem } from "@model/HistoryItem"
import {
    ApproveServiceQuoteForm,
    RequestRevisionServiceQuoteForm,
    ServiceQuote,
    ServiceQuoteItem,
    ServiceQuoteItemFormType,
    ServiceQuoteStatus,
    ServiceQuoteVendor,
} from "@model/serviceQuotes/ServiceQuote"
import { ServiceCode, ServiceSchedule } from "@model/serviceRequests/ServiceRequest"
import { User } from "@model/user/User"
import { AssetService } from "@service/assets/AssetService"
import { ServiceQuoteService } from "@service/serviceQuotes/ServiceQuoteService"
import isEqual from "lodash.isequal"

export type ServiceQuoteScreenState = {
    effectStatus: Record<ServiceQuoteEffect, AsyncStatus>
    serviceQuote: null | ServiceQuote
    asset: null | Asset
    form: ServiceQuoteForm
    serviceRequestForms: Record<number, ServiceQuoteWorkRequestForm>
    serviceRequests: ServiceQuoteItem[]
    formHasChanged: boolean
    workRequestDetailsModal: number | null
    AssetParts?: AssetPart[]
    ApproveServiceQuoteModalVisible: boolean
    RequestRevisionServiceQuoteModalVisible: boolean
    AsociateServiceScheduleModalVisibile: boolean
    AssetSchedules: ServiceSchedule[]
    FormType: string
}

export type ServiceQuoteForm = {
    status: ServiceQuoteStatus
    assignedTo: number | null
    vendor: ServiceQuoteVendor | null
    attachments: Attachment[] | null
    history: HistoryItem<ServiceQuoteStatus>[]
}

export type ServiceQuoteWorkRequestForm = {
    LaborHours: string
    LaborCost: string
    PartsCost: string
    MiscCost: string
    TotalCost: string
    notes: string
    workToBePerformed: string
    attachments: Attachment[] | null
    status: ServiceQuoteStatus
    serviceCodeId: number | null
    filesId: string | null
    hadFilesId: boolean
    newAttachmentsNames: string[]
    rejectionReason: string | null
    formType: string
}

export enum ServiceQuoteEffect {
    Fetch = "fetch",
    Save = "save",
    CreateWorkOrder = "createWorkOrder",
    SaveRepairRequest = "saveRepairRequest",
    CreateServiceQuote = "createServiceQuote",
    DeclineServiceQuote = "declineServiceQuote",
    RequestRevisionServiceQuote = "requestRevisionServiceQuote",
    FetchServiceQuoteHistory = "fetchWorkRequestHistory",
}

export class ServiceQuoteScreenBloc extends Bloc<ServiceQuoteScreenState> {
    constructor(private serviceQuoteService: ServiceQuoteService, private assetService: AssetService) {
        super(newServiceQuoteViewState(), { persistStateOnDispose: false })
    }

    override computed = (state: ServiceQuoteScreenState): Partial<ServiceQuoteScreenState> => {
        return {
            formHasChanged:
                state.serviceQuote !== null &&
                !isEqual(state.form, newServiceQuoteForm(state.serviceQuote, state.asset, state.form.attachments)),
            serviceRequestForms: arrayToObject(Object.entries(state.serviceRequestForms), ([key, it]) => [
                safeParseInt(key),
                {
                    ...it,
                    actualTotalCost: Strings.formatMoney(
                        safeParseFloat(it.LaborCost) + safeParseFloat(it.MiscCost) + safeParseFloat(it.PartsCost)
                    ),
                },
            ]),
        }
    }

    fetchData = (serviceQuoteId: number) =>
        this.effect({
            id: ServiceQuoteEffect.Fetch,
            block: async (job) => {
                this.effectStatusChanged(ServiceQuoteEffect.Fetch, AsyncStatus.busy())

                const [serviceQuoteOutcome] = await job.pause(
                    Promise.all([this.serviceQuoteService.fetchServiceQuote(serviceQuoteId)])
                )

                if (serviceQuoteOutcome.isError()) {
                    this.effectStatusChanged(ServiceQuoteEffect.Fetch, AsyncStatus.error(undefined))
                    return Outcome.error(undefined)
                }

                let attachments: Attachment[] = []
                const serviceQuote = serviceQuoteOutcome.value
                if (serviceQuote?.fileId) {
                    const atachmentsResponse = await this.serviceQuoteService.fetchServiceQuoteAttachments(
                        serviceQuote?.fileId
                    )
                    if (atachmentsResponse.isError()) return Outcome.error(atachmentsResponse)
                    attachments = atachmentsResponse.value
                }

                const [assetOutcome] = await job.pause(
                    Promise.all([this.assetService.fetchAsset(serviceQuoteOutcome.value.assetId ?? 0)])
                )

                if (assetOutcome.isError()) {
                    this.effectStatusChanged(ServiceQuoteEffect.Fetch, AsyncStatus.error(undefined))
                    return Outcome.error(undefined)
                }

                this.effectStatusChanged(ServiceQuoteEffect.Fetch, AsyncStatus.idle())

                this.update({
                    serviceQuote: serviceQuoteOutcome.value,
                    asset: assetOutcome.value,
                    serviceRequests: serviceQuoteOutcome.value.serviceQuoteItems ?? [],
                    form: newServiceQuoteForm(serviceQuoteOutcome.value, assetOutcome.value, attachments),
                    serviceRequestForms: arrayToObject(serviceQuoteOutcome.value.serviceQuoteItems ?? [], (it) => [
                        it.id,
                        newServiceRequestForm(it, assetOutcome.value),
                    ]),
                })

                return Outcome.ok(undefined)
            },
        })

    fetchHistoryData = (serviceQuoteId: number) =>
        this.effect({
            id: ServiceQuoteEffect.FetchServiceQuoteHistory,
            block: async (job) => {
                this.effectStatusChanged(ServiceQuoteEffect.FetchServiceQuoteHistory, AsyncStatus.busy())
                const [serviceQuoteHistory] = await job.pause(
                    Promise.all([this.serviceQuoteService.fetchServiceQuoteHistory(serviceQuoteId)])
                )

                if (serviceQuoteHistory.isError()) {
                    this.effectStatusChanged(ServiceQuoteEffect.FetchServiceQuoteHistory, AsyncStatus.error(undefined))
                    return Outcome.error(undefined)
                }

                this.serviceQuoteHistoryLoaded(serviceQuoteHistory.value, serviceQuoteId)
                this.effectStatusChanged(ServiceQuoteEffect.FetchServiceQuoteHistory, AsyncStatus.idle())
                return Outcome.ok(undefined)
            },
        })

    private serviceQuoteHistoryLoaded = (serviceQuoteHistory: HistoryItem<ServiceQuoteStatus>[], id: number) => {
        let tempServiceQuote = this.state.serviceQuote

        if (tempServiceQuote) tempServiceQuote.quoteHistory = serviceQuoteHistory

        this.update({ serviceQuote: tempServiceQuote })
    }

    showServiceRequestDetails = (workRequestId: number, formType: string) =>
        this.effect({
            id: ServiceQuoteEffect.Fetch,
            block: async (job) => {
                this.effectStatusChanged(ServiceQuoteEffect.Fetch, AsyncStatus.busy())

                const serviceRequestForm = this.state.serviceRequestForms[workRequestId]

                let attachments: Attachment[] = []

                if (serviceRequestForm?.filesId) {
                    const atachmentsResponse = await this.serviceQuoteService.fetchServiceQuoteItemAttachments(
                        serviceRequestForm?.filesId
                    )
                    if (atachmentsResponse.isError()) return Outcome.error(atachmentsResponse)
                    attachments = atachmentsResponse.value
                }

                this.update({
                    workRequestDetailsModal: workRequestId,
                    serviceRequests: this.state.serviceRequests,
                    FormType: formType,
                })

                this.requestFormFieldChanged(workRequestId, (state) => ({ attachments: attachments }))

                this.effectStatusChanged(ServiceQuoteEffect.Fetch, AsyncStatus.idle())

                return Outcome.ok(undefined)
            },
        })

    hideWorkRequestDetails = () =>
        this.update({ workRequestDetailsModal: null, FormType: ServiceQuoteItemFormType.Standard })

    assetChanged = (asset: Asset) => this.update({ asset: asset })

    mapServiceRequestFormToServiceQuoteItem = (x: ServiceQuoteItem): ServiceQuoteItem => {
        let serviceQuoteItemForm = this.state.serviceRequestForms[x.id]
        x.serviceCodeId = serviceQuoteItemForm.serviceCodeId
        x.status = serviceQuoteItemForm.status
        x.rejectionReason = serviceQuoteItemForm.rejectionReason
        return x
    }

    saveForm = (
        approveServiceQuoteForm?: ApproveServiceQuoteForm,
        requestRevisionServiceQuote?: RequestRevisionServiceQuoteForm
    ) =>
        this.effect({
            id: ServiceQuoteEffect.Save,
            block: async (job) => {
                if (!this.state.serviceQuote) return Outcome.error(undefined)

                var updateServiceQuote = {
                    ...this.state.serviceQuote,
                    Status: this.state.form.status,
                    assignedTo: this.state.form.assignedTo,
                    serviceQuoteItems: this.state.serviceRequests.map(this.mapServiceRequestFormToServiceQuoteItem),
                }

                if (requestRevisionServiceQuote) {
                    for (let serviceQuoteItem of updateServiceQuote.serviceQuoteItems) {
                        serviceQuoteItem.status = ServiceQuoteStatus.Declined
                        serviceQuoteItem.rejectionReason = `Service Quote Revision Requested by ${requestRevisionServiceQuote.userName} - ${requestRevisionServiceQuote.vendorMessage}`
                    }
                    updateServiceQuote.Status = ServiceQuoteStatus.RevisionRequested
                    updateServiceQuote.notifyContacts = requestRevisionServiceQuote.notifyContacts
                }

                var response = await job.pause(this.serviceQuoteService.updateServiceQuote(updateServiceQuote))

                if (response.isError()) {
                    return Outcome.error(response)
                }

                let serviceQuote = response.value

                if (!serviceQuote) return Outcome.error(undefined)

                if (approveServiceQuoteForm) {
                    approveServiceQuoteForm.serviceQuoteItems = serviceQuote.serviceQuoteItems.filter(
                        (x) => x.status == ServiceQuoteStatus.Approved
                    )
                    approveServiceQuoteForm.serviceQuoteId = serviceQuote.id
                    approveServiceQuoteForm.assetId = this.state.asset?.id ?? 0

                    const responseApproveSQ = await this.serviceQuoteService.ApproveServiceQuote(
                        approveServiceQuoteForm
                    )
                    if (responseApproveSQ.isError()) return Outcome.error(undefined)

                    serviceQuote = responseApproveSQ.value
                }

                const responseSQH = await this.serviceQuoteService.fetchServiceQuoteHistory(serviceQuote?.id)
                if (responseSQH.isError()) return Outcome.error(undefined)

                if (serviceQuote) serviceQuote.quoteHistory = responseSQH.value

                this.update({
                    serviceQuote: serviceQuote,
                    serviceRequests: serviceQuote.serviceQuoteItems,
                })

                this.statusChanged(serviceQuote.Status);

                return Outcome.ok(response)
            },
        })

    associateScheduleToServiceQuote = (serviceSchedules?: number[]) =>
        this.effect({
            id: ServiceQuoteEffect.Save,
            block: async (job) => {
                if (!this.state.serviceQuote) return Outcome.error(undefined)

                var updateServiceQuote = {
                    ...this.state.serviceQuote,
                    assetSchedules: serviceSchedules,
                }

                var response = await job.pause(this.serviceQuoteService.updateServiceQuote(updateServiceQuote))

                if (response.isError()) {
                    return Outcome.error(response)
                }

                let serviceQuote = response.value

                if (!serviceQuote) return Outcome.error(undefined)

                const responseSQH = await this.serviceQuoteService.fetchServiceQuoteHistory(serviceQuote?.id)
                if (responseSQH.isError()) return Outcome.error(undefined)

                if (serviceQuote) serviceQuote.quoteHistory = responseSQH.value

                this.update({
                    serviceQuote: serviceQuote,
                })

                return Outcome.ok(response)
            },
        })

    resetForm = () =>
        this.update({
            form: newServiceQuoteForm(this.state.serviceQuote),
        })

    saveRequestForm = (requestId: number, user: User | null) =>
        this.effect({
            id: ServiceQuoteEffect.SaveRepairRequest,
            block: async (job) => {
                const workRequest = this.state.serviceRequests.find((it) => it.id === requestId)
                const workRequestFormData = this.state.serviceRequestForms[requestId]
                if (!workRequest) return Outcome.error(undefined)

                const response = {
                    ...workRequest,
                    status: workRequestFormData.status,
                    serviceCodeId: workRequestFormData.serviceCodeId,
                    rejectionReason: workRequestFormData.rejectionReason ?? null,
                    mechanicsNotes: workRequestFormData.notes,
                    filesId: workRequestFormData.filesId as string,
                }

                this.update({
                    serviceRequests: this.state.serviceRequests.map((i) => {
                        if (i.id == response.id) {
                            i = response
                        }
                        return i
                    }),
                    serviceRequestForms: {
                        ...this.state.serviceRequestForms,
                        [requestId]: newServiceRequestForm(response, this.state.asset),
                    },
                })

                return Outcome.ok(response)
            },
        })

    resetRequestForm = (requestId: number) => {
        const serviceRequest = this.state.serviceRequests.find((it) => it.id === requestId)
        if (!serviceRequest) return this.state
        return this.update({
            serviceRequestForms: {
                ...this.state.serviceRequestForms,
                [requestId]: newServiceRequestForm(serviceRequest, this.state.asset),
            },
        })
    }

    setAsociateServiceScheduleModalVisibility = (value: boolean) =>
        this.update({ AsociateServiceScheduleModalVisibile: value })

    setAssetSchedules = (value: ServiceSchedule[]) => this.update({ AssetSchedules: value })

    showApproveServiceQuoteModal = () => this.update({ ApproveServiceQuoteModalVisible: true })
    hideApproveServiceQuoteModal = () => this.update({ ApproveServiceQuoteModalVisible: false })

    showRequestRevisionServiceQuoteModal = () => this.update({ RequestRevisionServiceQuoteModalVisible: true })
    hideRequestRevisionServiceQuoteModal = () => this.update({ RequestRevisionServiceQuoteModalVisible: false })

    statusChanged = (status: ServiceQuoteStatus) => this.formFieldChanged({ status: status })

    assignedToChanged = (contactId: number) => this.formFieldChanged({ assignedTo: contactId })

    serviceQuoteStatusChanged = (requestId: number, status: ServiceQuoteStatus) =>
        this.requestFormFieldChanged(requestId, (state) => ({ status: status }))

    requestServiceCodeChanged = (requestId: number, value: ServiceCode | null) =>
        this.requestFormFieldChanged(requestId, (state) => ({ serviceCodeId: value?.id ?? null }))

    requestNotesChanged = (requestId: number, value: string) =>
        this.requestFormFieldChanged(requestId, (state) => ({ notes: value }))

    requestWorkToBePerformedChanged = (requestId: number, value: string) =>
        this.requestFormFieldChanged(requestId, (state) => ({ workToBePerformed: value }))

    rejectionReasonChanged = (requestId: number, value: string) =>
        this.requestFormFieldChanged(requestId, (state) => ({ rejectionReason: value }))

    isServiceRequestFormChanged(requestId: number): boolean {
        const serviceRequest = this.state.serviceRequests.find((it) => it.id === requestId)
        if (!serviceRequest) return false
        return !isEqual(
            this.state.serviceRequestForms[requestId],
            newServiceRequestForm(serviceRequest, this.state.asset)
        )
    }

    private formFieldChanged = (form: Partial<ServiceQuoteForm>) =>
        this.update({
            form: {
                ...this.state.form,
                ...form,
            },
        })

    public updateAllServiceRequestsStatus = async (
        newStatus: ServiceQuoteStatus,
        rejectionReason: string | null
    ): Promise<Outcome<void>> => {
        try {
            const updatedServiceRequests = this.state.serviceRequests.map((i) => {
                i.status = newStatus
                if (rejectionReason !== null) {
                    i.rejectionReason = rejectionReason
                    i.rejectionDate = new Date()
                }

                const newFormState = this.state.serviceRequestForms[i.id]
                newFormState.status = newStatus
                newFormState.rejectionReason = i.rejectionReason
                this.requestFormFieldChanged(i.id, (state) => newFormState)

                return i
            })

            await this.update({ serviceRequests: updatedServiceRequests, FormType: ServiceQuoteItemFormType.Standard })

            return Outcome.ok(undefined)
        } catch (error) {
            console.error("Error updating Service Quote Items:", error)
            return Outcome.error(error)
        }
    }

    private serviceRequestsFieldChanged = (
        requestId: number,
        block: (state: ServiceQuoteItem) => Partial<ServiceQuoteItem>
    ) => {
        const form = this.state.serviceRequests[requestId]
        if (!form) return this.state

        return this.update({
            serviceRequests: {
                ...this.state.serviceRequests,
                [requestId]: {
                    ...form,
                    ...block(form),
                },
            },
        })
    }

    private requestFormFieldChanged = (
        requestId: number,
        block: (state: ServiceQuoteWorkRequestForm) => Partial<ServiceQuoteWorkRequestForm>
    ) => {
        const form = this.state.serviceRequestForms[requestId]
        if (!form) return this.state

        return this.update({
            serviceRequestForms: {
                ...this.state.serviceRequestForms,
                [requestId]: {
                    ...form,
                    ...block(form),
                },
            },
        })
    }

    private effectStatusChanged = (effect: ServiceQuoteEffect, status: AsyncStatus) =>
        this.update({
            effectStatus: {
                ...this.state.effectStatus,
                [effect]: status,
            },
        })
}

function newServiceQuoteViewState(): ServiceQuoteScreenState {
    return {
        effectStatus: arrayToObject(Object.values(ServiceQuoteEffect), (it) => [it, AsyncStatus.idle()]),
        serviceQuote: null,
        asset: null,
        form: newServiceQuoteForm(),
        serviceRequestForms: {},
        formHasChanged: false,
        serviceRequests: [],
        workRequestDetailsModal: null,
        ApproveServiceQuoteModalVisible: false,
        RequestRevisionServiceQuoteModalVisible: false,
        AsociateServiceScheduleModalVisibile: false,
        AssetSchedules: [],
        FormType: ServiceQuoteItemFormType.Standard,
    }
}

function newServiceQuoteForm(
    serviceQuote?: ServiceQuote | null,
    asset?: Asset | null,
    attachments?: Attachment[] | null
): ServiceQuoteForm {
    return {
        status: serviceQuote?.Status ?? ServiceQuoteStatus.Pending,
        assignedTo: serviceQuote?.assignedTo ?? -1,
        history: [],
        vendor: serviceQuote?.vendor ?? null,
        attachments: attachments ?? null,
    }
}

function newServiceRequestForm(serviceRequest: ServiceQuoteItem, asset: Asset | null): ServiceQuoteWorkRequestForm {
    return {
        status: serviceRequest.status,
        MiscCost: Strings.formatFloat(serviceRequest.totalMiscCost ?? 0),
        LaborCost: Strings.formatMoney(serviceRequest.totalLaborCost),
        LaborHours: Strings.formatFloat(serviceRequest.laborHours ?? 0),
        PartsCost: Strings.formatMoney(0), //Strings.formatMoney(serviceRequest.PartsCost),
        TotalCost: Strings.formatMoney(serviceRequest.totalLaborCost + (serviceRequest.totalMiscCost ?? 0)),
        attachments: null, //serviceRequest.attachments,
        notes: serviceRequest.mechanicsNotes ?? "",
        workToBePerformed: serviceRequest.descriptionWorkToBePerformed,
        serviceCodeId: serviceRequest.serviceCodeId,
        filesId: serviceRequest.fileId,
        hadFilesId: serviceRequest.fileId != null && serviceRequest.fileId.length > 0 ? true : false,
        newAttachmentsNames: [],
        rejectionReason: null,
        formType: ServiceQuoteItemFormType.Standard,
    }
}
