import React from "react"
import { Page } from "@ui/common/page/Page"
import { WorkOrdersFilterButton, WorkOrdersFilterOverlay } from "./WorkOrdersFilterOverlay"
import { WorkOrdersQuickFilters } from "./WorkOrdersQuickFilters"
import { WorkOrdersTable } from "./WorkOrdersTable"

export function WorkOrdersScreen(): JSX.Element {
    return (
        <Page className="page--work-orders" filterButton={WorkOrdersFilterButton}>
            <WorkOrdersFilterOverlay />
            <WorkOrdersQuickFilters />
            <WorkOrdersTable />
        </Page>
    )
}
