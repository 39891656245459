import { BlocCoordinator } from "@lib/bloc/BlocCoordinator"
import { DashboardFilter } from "@model/dashboard/DashboardFilter"
import { DashboardFilterDefinitions } from "@model/dashboard/DashboardFilterDefinitions"
import { Filter, newDefaultFiltersObj } from "@model/filters/Filter"
import { createFilterGroupFromPreset } from "@model/filters/FilterPresets"
import { DashboardFilterOverlayBloc } from "@state/dashboard/DashboardFilterOverlayBloc"
import { DashboardScreenBloc, DashboardScreenState } from "@state/dashboard/DashboardScreenBloc"
import { UserPreferencesBloc } from "@state/UserPreferencesBloc"

type Dependencies = [DashboardScreenState]

type StateSelection = DashboardScreenState

export class DashboardScreenViewModel extends BlocCoordinator<Dependencies, StateSelection> {
    constructor(
        private dashboardBloc: DashboardScreenBloc,
        private filterOverlayBloc: DashboardFilterOverlayBloc,
        private userPreferencesBloc: UserPreferencesBloc
    ) {
        super([dashboardBloc])
    }

    protected transform = ([dashboardState]: Dependencies): StateSelection => dashboardState

    onMounted = async () => {
        const defaultPreset = this.userPreferencesBloc.state.filterPresets.dashboard.find((it) => it.isDefault)
        const filters = defaultPreset
            ? createFilterGroupFromPreset(defaultPreset, DashboardFilterDefinitions)
            : this.dashboardBloc.state.filters

        if (defaultPreset) {
            this.filterOverlayBloc.defaultFilterPresetLoaded(defaultPreset)
        } else {
            this.filterOverlayBloc.updateFilters(filters)
        }

        this.dashboardBloc.applyFiltersAndFetch(filters)
    }

    onFilterRemoveClicked = (filter: Filter<DashboardFilter>) => this.dashboardBloc.removeFilter(filter.definition.type)
}
