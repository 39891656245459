import { BlocCoordinator } from "@lib/bloc/BlocCoordinator"
import { arrayOfNotNull } from "@lib/TypeUtil"
import { Contact } from "@model/contacts/Contact"
import { Filter, filterContainsValue, FilterUpdateType, FilterValue, filterValueForUrgency, filterValueForWorkOrderStatus } from "@model/filters/Filter"
import { FilterPresetType } from "@model/filters/FilterPresets"
import { labelForUrgency, Urgency } from "@model/Urgency"
import {
    labelForWorkOrderStatus,
    numberForWorkOrderStatus,
    WorkOrderFilter,
    WorkOrderQuickFilterCount,
    WorkOrderStatus,
} from "@model/workOrders/WorkOrder"
import { CompanyBloc, CompanyState } from "@state/company/CompanyBloc"
import { FilterOverlayBlocCoordinatorMixin } from "@state/filters/FilterOverlayBlocCoordinatorMixin"
import { StatusBloc } from "@state/StatusBloc"
import { UserPreferencesBloc } from "@state/UserPreferencesBloc"
import {
    WorkOrdersFilterOverlayBloc,
    WorkOrdersFilterOverlayState,
} from "@state/workOrders/WorkOrdersFilterOverlayBloc"
import {
    WorkOrdersQuickFiltersBloc,
    WorkOrdersQuickFiltersState,
} from "@state/workOrders/WorkOrdersQuickFiltersBloc"
import { WorkOrdersTableBloc, WorkOrdersTableState } from "@state/workOrders/WorkOrdersTableBloc"
import { SortableTableBlocEffect } from "@state/SortableTableBloc"

type Dependencies = [WorkOrdersQuickFiltersState, WorkOrdersTableState, CompanyState, WorkOrdersFilterOverlayState]

export type WorkOrdersQuickFiltersViewModelState = {
    counts: Record<WorkOrderQuickFilterCount, number>
    isPendingToggleActive: boolean
    isOpenToggleActive: boolean
    isInProgressToggleActive: boolean
    isCompletedToggleActive: boolean
    isRedTagToggleActive: boolean
    isYellowTagToggleActive: boolean
    isImmediateToggleActive: boolean
    isOverdueToggleActive: boolean
    isHighToggleActive: boolean
    isMediumToggleActive: boolean
    isLowToggleActive: boolean
    isLoading: boolean
    assignedToFilter: Filter<WorkOrderFilter>
    searchFilter: Filter<WorkOrderFilter>
    mechanics: Contact[]
    isMobileFilterModalOpen: boolean
}

export class WorkOrdersQuickFiltersViewModel extends BlocCoordinator<
    Dependencies,
    WorkOrdersQuickFiltersViewModelState
> {
    private filterOverlayMixin: FilterOverlayBlocCoordinatorMixin<WorkOrderFilter>

    constructor(
        private quickFiltersBloc: WorkOrdersQuickFiltersBloc,
        private tableBloc: WorkOrdersTableBloc,
        private companyBloc: CompanyBloc,
        private filterOverlayBloc: WorkOrdersFilterOverlayBloc,
        userPreferencesBloc: UserPreferencesBloc,
        statusBloc: StatusBloc
    ) {
        super([quickFiltersBloc, tableBloc, companyBloc, filterOverlayBloc])

        this.filterOverlayMixin = new FilterOverlayBlocCoordinatorMixin(
            FilterPresetType.WorkOrder,
            filterOverlayBloc,
            tableBloc,
            userPreferencesBloc,
            statusBloc
        )
    }

    protected transform = ([
        quickFiltersState,
        tableState,
        companyState,
        filterOverlayState,
    ]: Dependencies): WorkOrdersQuickFiltersViewModelState => ({
        counts: tableState.counts,
        isPendingToggleActive: filterContainsValue(tableState.filters[WorkOrderFilter.Status], [
            WorkOrderStatus.Pending,
        ]),
        isOpenToggleActive: filterContainsValue(tableState.filters[WorkOrderFilter.Status], [WorkOrderStatus.Open]),
        isInProgressToggleActive: filterContainsValue(tableState.filters[WorkOrderFilter.Status], [
            WorkOrderStatus.InProgress,
        ]),
        isCompletedToggleActive: filterContainsValue(tableState.filters[WorkOrderFilter.Status], [
            WorkOrderStatus.WorkCompleted,
        ]),
        isYellowTagToggleActive: filterContainsValue(tableState.filters[WorkOrderFilter.YellowTag], [true]),
        isRedTagToggleActive: filterContainsValue(tableState.filters[WorkOrderFilter.RedTag], [true]),
        isImmediateToggleActive: filterContainsValue(tableState.filters[WorkOrderFilter.Urgency], [Urgency.Immediate]),
        isOverdueToggleActive: filterContainsValue(tableState.filters[WorkOrderFilter.Overdue], [true]),
        isHighToggleActive: filterContainsValue(tableState.filters[WorkOrderFilter.Urgency], [Urgency.High]),
        isMediumToggleActive: filterContainsValue(tableState.filters[WorkOrderFilter.Urgency], [Urgency.Medium]),
        isLowToggleActive: filterContainsValue(tableState.filters[WorkOrderFilter.Urgency], [Urgency.Low]),
        isLoading: tableState.effectStatus[SortableTableBlocEffect.Fetch].isBusy(),
        assignedToFilter: tableState.filters[WorkOrderFilter.AssignedTo],
        searchFilter: filterOverlayState.filters[WorkOrderFilter.Search],
        mechanics: companyState.settings.mechanics,
        isMobileFilterModalOpen: quickFiltersState.isMobileFilterModalOpen,
    })

    updateQuickFilterSelection(selection: WorkOrderQuickFilterCount[]) {
        const updatedFilters: Record<WorkOrderFilter, Filter<WorkOrderFilter>> = {
            ...this.tableBloc.state.filters,
            ...this.tableBloc.createUpdatedFilter(
                WorkOrderFilter.Status,
                arrayOfNotNull([
                    selection.includes(WorkOrderQuickFilterCount.Pending)
                        ? filterValueForWorkOrderStatus(WorkOrderStatus.Pending)
                        : null,
                    selection.includes(WorkOrderQuickFilterCount.Open)
                        ? filterValueForWorkOrderStatus(WorkOrderStatus.Open)
                        : null,
                    selection.includes(WorkOrderQuickFilterCount.InProgress)
                        ? filterValueForWorkOrderStatus(WorkOrderStatus.InProgress)
                        : null,
                    selection.includes(WorkOrderQuickFilterCount.Completed)
                        ? filterValueForWorkOrderStatus(WorkOrderStatus.WorkCompleted)
                        : null,
                ]),
                FilterUpdateType.Set
            ),
            ...this.tableBloc.createUpdatedFilter(
                WorkOrderFilter.YellowTag,
                [{ value: selection.includes(WorkOrderQuickFilterCount.YellowTag) }],
                FilterUpdateType.Set
            ),
            ...this.tableBloc.createUpdatedFilter(
                WorkOrderFilter.RedTag,
                [{ value: selection.includes(WorkOrderQuickFilterCount.RedTag) }],
                FilterUpdateType.Set
            ),
            ...this.tableBloc.createUpdatedFilter(
                WorkOrderFilter.Urgency,
                arrayOfNotNull([
                    selection.includes(WorkOrderQuickFilterCount.Immediate)
                        ? filterValueForUrgency(Urgency.Immediate)
                        : null,
                    selection.includes(WorkOrderQuickFilterCount.High)
                        ? filterValueForUrgency(Urgency.High)
                        : null,
                    selection.includes(WorkOrderQuickFilterCount.Medium)
                        ? filterValueForUrgency(Urgency.Medium)
                        : null,
                    selection.includes(WorkOrderQuickFilterCount.Low) ? filterValueForUrgency(Urgency.Low) : null,
                ]),
                FilterUpdateType.Set
            ),
            ...this.tableBloc.createUpdatedFilter(
                WorkOrderFilter.Overdue,
                [{ value: selection.includes(WorkOrderQuickFilterCount.Overdue) }],
                FilterUpdateType.Set
            ),
        }

        this.tableBloc.applyFiltersAndFetch(updatedFilters)
    }

    toggleQuickFilter(countType: WorkOrderQuickFilterCount, isActive: boolean) {
        const updatedFilter = (() => {
            switch (countType) {
                case WorkOrderQuickFilterCount.Pending:
                    return this.tableBloc.createUpdatedFilter(
                        WorkOrderFilter.Status,
                        [filterValueForWorkOrderStatus(WorkOrderStatus.Pending)],
                        isActive ? FilterUpdateType.Remove : FilterUpdateType.Add
                    )
                case WorkOrderQuickFilterCount.Open:
                    return this.tableBloc.createUpdatedFilter(
                        WorkOrderFilter.Status,
                        [filterValueForWorkOrderStatus(WorkOrderStatus.Open)],
                        isActive ? FilterUpdateType.Remove : FilterUpdateType.Add
                    )
                case WorkOrderQuickFilterCount.InProgress:
                    return this.tableBloc.createUpdatedFilter(
                        WorkOrderFilter.Status,
                        [filterValueForWorkOrderStatus(WorkOrderStatus.InProgress)],
                        isActive ? FilterUpdateType.Remove : FilterUpdateType.Add
                    )
                case WorkOrderQuickFilterCount.Completed:
                    return this.tableBloc.createUpdatedFilter(
                        WorkOrderFilter.Status,
                        [filterValueForWorkOrderStatus(WorkOrderStatus.WorkCompleted)],
                        isActive ? FilterUpdateType.Remove : FilterUpdateType.Add
                    )
                case WorkOrderQuickFilterCount.RedTag:
                    return this.tableBloc.createUpdatedFilter(
                        WorkOrderFilter.RedTag,
                        [{ value: !isActive }],
                        FilterUpdateType.Set
                    )
                case WorkOrderQuickFilterCount.YellowTag:
                    return this.tableBloc.createUpdatedFilter(
                        WorkOrderFilter.YellowTag,
                        [{ value: !isActive }],
                        FilterUpdateType.Set
                    )
                case WorkOrderQuickFilterCount.Overdue:
                    return this.tableBloc.createUpdatedFilter(
                        WorkOrderFilter.Overdue,
                        [{ value: !isActive }],
                        FilterUpdateType.Set
                    )
                case WorkOrderQuickFilterCount.Immediate:
                    return this.tableBloc.createUpdatedFilter(
                        WorkOrderFilter.Urgency,
                        [filterValueForUrgency(Urgency.Immediate)],
                        isActive ? FilterUpdateType.Remove : FilterUpdateType.Add
                    )
                case WorkOrderQuickFilterCount.High:
                    return this.tableBloc.createUpdatedFilter(
                        WorkOrderFilter.Urgency,
                        [filterValueForUrgency(Urgency.High)],
                        isActive ? FilterUpdateType.Remove : FilterUpdateType.Add
                    )
                case WorkOrderQuickFilterCount.Medium:
                    return this.tableBloc.createUpdatedFilter(
                        WorkOrderFilter.Urgency,
                        [filterValueForUrgency(Urgency.Medium)],
                        isActive ? FilterUpdateType.Remove : FilterUpdateType.Add
                    )
                case WorkOrderQuickFilterCount.Low:
                    return this.tableBloc.createUpdatedFilter(
                        WorkOrderFilter.Urgency,
                        [filterValueForUrgency(Urgency.Low)],
                        isActive ? FilterUpdateType.Remove : FilterUpdateType.Add
                    )
                default:
                    return undefined
            }
        })()

        this.tableBloc.applyFiltersAndFetch({ ...this.tableBloc.state.filters, ...updatedFilter })
    }

    updateAssignedToFilter = (value: FilterValue[]) => {
        const updatedFilter = this.tableBloc.createUpdatedFilter(
            WorkOrderFilter.AssignedTo,
            value,
            FilterUpdateType.Set
        )
        this.tableBloc.applyFiltersAndFetch({ ...this.tableBloc.state.filters, ...updatedFilter })
    }

    updateSearchFilter = (value: FilterValue[]) => {
        this.filterOverlayMixin.updateFilter(WorkOrderFilter.Search, value)
    }

    applySearchFilter = () => {
        const updatedFilter = this.tableBloc.createUpdatedFilter(
            WorkOrderFilter.Search,
            this.filterOverlayBloc.state.filters[WorkOrderFilter.Search].values,
            FilterUpdateType.Set
        )
        this.tableBloc.applyFiltersAndFetch({ ...this.tableBloc.state.filters, ...updatedFilter })
    }

    isSearchFilterValid = () => this.filterOverlayMixin.isSearchFilterValid()

    showMobileFilterModal = (isOpen: boolean) => this.quickFiltersBloc.showMobileFilterModal(isOpen)
}
