import { ModalSize, ModalContent, ModalHeader, ModalHeaderTitle, ModalBody, ModalButtons, Modal } from "@ui/common/Modal";
import { Button, ButtonType } from "@ui/common/buttons/Button";
import { FormFieldLabel } from "@ui/common/form/FormField";
import { Grid } from "@ui/common/grids/Grid";
import React from "react";
import ReactDOM from "react-dom";

export const YesNoDialog = {
    open: (text: string, title: string, onYesClicked: () => void, onNoClicked?: () => void): void => {
        let finalYesClicked = () => {
            let modal = document.getElementById("modalHolder")
            if(modal) {
                ReactDOM.unmountComponentAtNode(modal)
            }
            if(onYesClicked !== null) {
                onYesClicked()
            }
        }
        let finalNoClicked = () => {
            let modal = document.getElementById("modalHolder")
            if(modal) {
                ReactDOM.unmountComponentAtNode(modal)
            }
            if(onNoClicked && onNoClicked != null) {
                onNoClicked();
            }
        }
        ReactDOM.render(
            <YesNoModal
                onYesClicked={finalYesClicked}
                onNoClicked={finalNoClicked}
                text={text}
                title={title} />,
                document.getElementById("modalHolder")
        );
    }
};

type YesNoModalProps = {
    onNoClicked: () => void
    onYesClicked: () => void
    text: string
    title: string
}

function YesNoModal(props: YesNoModalProps) : JSX.Element {
    return (
        <Modal isVisible={true} useContentTag={true} onClose={props.onNoClicked} size={ModalSize.Small}>
            <YesNoModalContent {...props} />
        </Modal>
    )
}

function YesNoModalContent(props: YesNoModalProps) : JSX.Element {
    return (
        <ModalContent>
            <ModalHeader>
                <ModalHeaderTitle>{props.title}</ModalHeaderTitle>
            </ModalHeader>
            <ModalBody>
                <Grid columns={1}>
                    <FormFieldLabel>{props.text}</FormFieldLabel>
                </Grid>
            </ModalBody>
            <ModalButtons>
                <Button
                    label="No"
                    type={ButtonType.Text}
                    onClick={props.onNoClicked} />
                <Button
                    label="Yes"
                    type={ButtonType.Text}
                    onClick={props.onYesClicked} />
            </ModalButtons>
        </ModalContent>
    )
}