import { SortOrder, compare } from "@lib/Comparable"
import { arrayToObject, isBoolean, isNumber, isString, safeParseInt } from "@lib/TypeUtil"
import { Urgency, labelForUrgency } from "@model/Urgency"
import { ServiceQuoteStatus, labelForServiceQuoteStatus, numberForServiceQuoteStatus } from "@model/serviceQuotes/ServiceQuote"
import { WorkOrderStatus, labelForWorkOrderStatus, numberForWorkOrderStatus } from "@model/workOrders/WorkOrder"
import { WorkRequestType, labelForWorkRequestType } from "@model/workRequests/WorkRequest"
import isEqual from "lodash.isequal"

export type Filter<T extends string = string> = {
    definition: FilterDef<T>
    values: FilterValue[]
}

export type FilterValue = {
    label?: string
    value: boolean | string | number
}

export type FilterDef<T extends string = string> = {
    type: T
    categoryLabel: string
    defaultValue: FilterValue[]
    dependencies?: T[]
}

export function createDefaultFilter<T extends string>(def: FilterDef<T>): Filter<T> {
    return {
        definition: def,
        values: def.defaultValue,
    }
}

export function createEmptyFilter<T extends string>(def: FilterDef<T>): Filter<T> {
    return {
        definition: def,
        values: [],
    }
}

export function textFilterValue(filter: Filter | null): string {
    const value = filter?.values[0]?.value
    return isString(value) ? value : ""
}

export function numberFilterValue(filter: Filter | null): number {
    const value = filter?.values[0]?.value
    return isNumber(value) ? value : 0
}

export function booleanFilterValue(filter: Filter | null): boolean {
    const value = filter?.values[0]?.value
    return isBoolean(value) ? value : false
}

export function textArrayFilterValue(filter: Filter | null): string[] {
    return filter?.values.map((it) => (isString(it.value) ? it.value : "")) ?? []
}

export function numberArrayFilterValue(filter: Filter | null): number[] {
    return filter?.values.map((it) => (isNumber(it.value) ? it.value : 0)) ?? []
}

export function numberArrayFromStringFilterValue(filter: Filter | null): number[] {
    return filter?.values.map((it) => safeParseInt(it.value)) ?? []
}

export function isFilterEmptyOrContainsValue(filter: Filter | null, values: (boolean | string | number)[]): boolean {
    return isEmptyFilterValue(filter) || filterContainsValue(filter, values)
}

export function isEmptyFilter(filter: Filter | null): boolean {
    if (filter === null) return true
    if (filter.values.length === 0) return true
    return filter.values.every((it) => {
        if (isString(it.value) && it.value.trim() === "") return true
        return false
    })
}

export function isEmptyFilterValue(filter: Filter | null): boolean {
    if (filter === null) return true
    if (filter.values.length === 0) return true

    return filter.values.every((it) => {
        if (isBoolean(it.value) && it.value === false) return true
        if (isString(it.value) && it.value.trim() === "") return true
        return false
    })
}

export function filterContainsValue(filter: Filter | null, values: (boolean | string | number)[]): boolean {
    if (!filter) return false
    return values.every((item) => filter.values.findIndex((it) => it.value === item) !== -1)
}

export function isFilterGroupEqual<T extends string>(a: Record<T, Filter<T>>, b: Record<T, Filter<T>>): boolean {
    const aKeys = Object.keys(a).sort((a, b) => compare(a, b, SortOrder.Asc))
    const bKeys = Object.keys(b).sort((a, b) => compare(a, b, SortOrder.Asc))
    if (!isEqual(aKeys, bKeys)) return false

    const values: [T, Filter<T>][] = Object.entries(a) as [T, Filter<T>][]

    for (const [key, value] of values) {
        if (!filterValueIsEqual(value, b[key])) return false
    }

    return true
}

export function filterValueIsEqual(a: Filter, b: Filter): boolean {
    if (a.values.length !== b.values.length) return false

    for (let i = 0, l = a.values.length; i < l; i++) {
        if (!b.values.map((it) => it.value).includes(a.values[i].value)) return false
    }

    return true
}

export enum FilterUpdateType {
    Add,
    Remove,
    Set,
}

export function updateFilter<T extends string>(
    filter: Filter<T>,
    value: FilterValue[],
    updateType: FilterUpdateType
): Filter<T> {
    const newValue: FilterValue[] = (() => {
        if (updateType === FilterUpdateType.Add) {
            return [
                ...filter.values.filter((item) => value.findIndex((it) => it.value === item.value) === -1),
                ...value,
            ]
        } else if (updateType === FilterUpdateType.Remove) {
            return filter.values.filter((item) => value.findIndex((it) => it.value === item.value) === -1)
        } else if (updateType === FilterUpdateType.Set) {
            return value
        }
        return filter.values
    })()

    return {
        ...filter,
        values: newValue,
    }
}

export function filterValueForUrgency(urgency: Urgency): FilterValue {
    return {
        value: urgency,
        label: labelForUrgency(urgency),
    }
}

export function filterValueForWorkRequestType(type: WorkRequestType): FilterValue {
    return {
        value: type,
        label: labelForWorkRequestType(type),
    }
}

export function filterValueForWorkOrderStatus(status: WorkOrderStatus): FilterValue {
    return {
        value: numberForWorkOrderStatus(status),
        label: labelForWorkOrderStatus(status),
    }
}

export function filterValueForServiceQuoteStatus(status: ServiceQuoteStatus): FilterValue {
    return {
        value: numberForServiceQuoteStatus(status),
        label: labelForServiceQuoteStatus(status),
    }
}
export function newDefaultFiltersObj<F extends string>(
    filters: F[],
    filterDefinitions: Record<F, FilterDef<F>>
): Record<F, Filter<F>> {
    return arrayToObject(
        filters.map((filter) => createDefaultFilter(filterDefinitions[filter])),
        (item) => [item.definition.type, item]
    )
}

export function newEmptyFiltersObj<F extends string>(
    filters: F[],
    filterDefinitions: Record<F, FilterDef<F>>
): Record<F, Filter<F>> {
    return arrayToObject(
        filters.map((filter) => createEmptyFilter(filterDefinitions[filter])),
        (item) => [item.definition.type, item]
    )
}
