import { FilterDef } from "@model/filters/Filter"
import { ServiceQuoteFilter } from "./ServiceQuote"

export const ServiceQuoteFilterDefinitions: Record<ServiceQuoteFilter, FilterDef<ServiceQuoteFilter>> = {
    [ServiceQuoteFilter.Search]: {
        type: ServiceQuoteFilter.Search,
        categoryLabel: "Search",
        defaultValue: [{ value: "" }], // string
    },
    [ServiceQuoteFilter.Asset]: {
        type: ServiceQuoteFilter.Asset,
        categoryLabel: "Asset",
        defaultValue: [],
    },
    [ServiceQuoteFilter.AssignedTo]: {
        type: ServiceQuoteFilter.AssignedTo,
        categoryLabel: "Assigned To",
        defaultValue: [],
    },
    [ServiceQuoteFilter.CreationDateTimeFilterOption]: {
        type: ServiceQuoteFilter.CreationDateTimeFilterOption,
        categoryLabel: "Date Received:",
        defaultValue: [],
    },
    [ServiceQuoteFilter.LastKnownLocation]: {
        type: ServiceQuoteFilter.LastKnownLocation,
        categoryLabel: "Last Known Location",
        defaultValue: [],
    },
    [ServiceQuoteFilter.ServiceQuoteId]: {
        type: ServiceQuoteFilter.ServiceQuoteId,
        categoryLabel: "Service Quote #",
        defaultValue: [],
    },
    [ServiceQuoteFilter.Status]: {
        type: ServiceQuoteFilter.Status,
        categoryLabel: "Status",
        defaultValue: [],
    },
    [ServiceQuoteFilter.TotalCostFilterOption]: {
        type: ServiceQuoteFilter.TotalCostFilterOption,
        categoryLabel: "Total Cost:",
        defaultValue: [],
    },
    [ServiceQuoteFilter.VendorID]: {
        type: ServiceQuoteFilter.VendorID,
        categoryLabel: "Vendor",
        defaultValue: [],
    },
    [ServiceQuoteFilter.WOSearch]: {
        type: ServiceQuoteFilter.WOSearch,
        categoryLabel: "Work Order",
        defaultValue: [{ value: "" }], // string
    },
    [ServiceQuoteFilter.Contact]: {
        type: ServiceQuoteFilter.Contact,
        categoryLabel: "Contact",
        defaultValue: [],
    },
    [ServiceQuoteFilter.SubCompany]: {
        type: ServiceQuoteFilter.SubCompany,
        categoryLabel: "SubCompany",
        defaultValue: [],
    },
    [ServiceQuoteFilter.District]: {
        type: ServiceQuoteFilter.District,
        categoryLabel: "District",
        defaultValue: [],
    },
    [ServiceQuoteFilter.SubDistrict]: {
        type: ServiceQuoteFilter.SubDistrict,
        categoryLabel: "SubDistrict",
        defaultValue: [],
    },
    [ServiceQuoteFilter.Unit]: {
        type: ServiceQuoteFilter.Unit,
        categoryLabel: "Unit",
        defaultValue: [],
    },
    [ServiceQuoteFilter.Group]: {
        type: ServiceQuoteFilter.Group,
        categoryLabel: "Group",
        defaultValue: [],
    },
    [ServiceQuoteFilter.Site]: {
        type: ServiceQuoteFilter.Site,
        categoryLabel: "Site",
        defaultValue: [],
    },
    [ServiceQuoteFilter.AssetType]: {
        type: ServiceQuoteFilter.AssetType,
        categoryLabel: "Type",
        defaultValue: [],
    },
    [ServiceQuoteFilter.Category]: {
        type: ServiceQuoteFilter.Category,
        categoryLabel: "Category",
        defaultValue: [],
    },
    [ServiceQuoteFilter.Class]: {
        type: ServiceQuoteFilter.Class,
        categoryLabel: "Class",
        defaultValue: [],
    },
    [ServiceQuoteFilter.Make]: {
        type: ServiceQuoteFilter.Make,
        categoryLabel: "Make",
        defaultValue: [],
    },
    [ServiceQuoteFilter.Model]: {
        type: ServiceQuoteFilter.Model,
        categoryLabel: "Model",
        defaultValue: [],
    },
    [ServiceQuoteFilter.WorkType]: {
        type: ServiceQuoteFilter.WorkType,
        categoryLabel: "WorkType",
        defaultValue: [],
    },
    [ServiceQuoteFilter.MechanicTypes]: {
        type: ServiceQuoteFilter.MechanicTypes,
        categoryLabel: "MechanicTypes",
        defaultValue: [],
    },
}
