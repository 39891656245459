import { EffectStatus } from "@lib/bloc/Bloc"
import { useBloc } from "@lib/bloc/hooks"
import { useIsInIframe } from "@lib/Hooks"
import { classNames } from "@lib/HtmlUtil"
import { Link, useRoute } from "@lib/router/components"
import { isRouteMatch, RouteItemData } from "@lib/router/route"
import { labelForAsset, newAsset } from "@model/assets/Asset"
import { NavItem, PermissionObject, PermissionType, User } from "@model/user/User"
import { HeaderState } from "@state/app/HeaderBloc"
import { UserBloc } from "@state/user/UserBloc"
import { DI } from "@ui/DI"
import { ExternalRoutes, Routes } from "@lib/Routes"
import React, { useState } from "react"
import { Popover, PopoverItem } from "./buttons/Popover"
import { SearchableTextInput } from "./form/SearchableTextInput"
import { ImageUrls } from "./images/ImageUrls"
import { CompanyType} from "@model/company/CompanyType"

export function Header({ filterButton }: { filterButton?: () => JSX.Element }): JSX.Element {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [state, _] = useBloc(() => DI.userBloc())
    const isInIframe = useIsInIframe()

    return (
        <header
            className={classNames(
                isMobileMenuOpen ? "header--mobile-menu-open" : null,
                isInIframe ? "header--hide" : null
            )}
        >
            <Toolbar {...{ isMobileMenuOpen, setMobileMenuOpen }} user={state.user} />
            <NavigationMenu user={state.user} />
            <SubNav filterButton={filterButton} user={state.user} />
        </header>
    )
}

function Toolbar(props: {
    isMobileMenuOpen: boolean
    setMobileMenuOpen: (isOpen: boolean) => void
    user: User
}): JSX.Element {
    return (
        <div className="header-toolbar">
            <div className="header-toolbar-section">
                <img alt="Logo" className="logo--text" src={ImageUrls.LogoText} width="194" height="24" />
                <img alt="Logo" className="logo" src={ImageUrls.Logo} width="28" height="23" />
                <div className="header-toolbar-user-message">
                    Welcome back, {props.user.name} - {props.user.companyName}
                </div>
            </div>
            <div className="header-toolbar-section header-toolbar-section--actions">
                <Popover
                    button={(props: { onClick: () => void }) => (
                        <ToolbarLink label="Gauge" onClick={props.onClick} />
                    )}
                >
                    <PopoverItem label="Reach Out to Us" href={ExternalRoutes.SmartHubContact()} />
                    <PopoverItem label="Submit a Support Ticket" href={ExternalRoutes.SmartHubSupport()} />
                    <PopoverItem label="View Release Notes" href={ExternalRoutes.SmartHubReleaseNotes()} />
                </Popover>
                {props.user.impersonateCompany && props.user.companyTypeID != CompanyType.Admin && props.user.parentCompanyID != null ? (
                    <ToolbarLink label="Return to Parent Company" href={ExternalRoutes.SmartHubUnImpersonate()} />
                ) : (
                    ""
                )}
                <ToolbarLink label="My Info" href={ExternalRoutes.SmartHubMyInfo()} />
                <ToolbarLink label="Logout" href={ExternalRoutes.SmartHubLogout()} />
            </div>
            <button className="mobile-menu-button" onClick={() => props.setMobileMenuOpen(!props.isMobileMenuOpen)}>
                <div className="mobile-menu-button-stripe"></div>
                <div className="mobile-menu-button-stripe"></div>
                <div className="mobile-menu-button-stripe"></div>
            </button>
        </div>
    )
}

type ToolbarLinkProps = {
    label: string
    href?: string
    onClick?: () => void
}

const ToolbarLink = ({ label: title, href, onClick }: ToolbarLinkProps): JSX.Element =>
    href !== undefined ? (
        <a className="header-toolbar-link" href={href}>
            {title}
        </a>
    ) : (
        <div className="header-toolbar-link" onClick={onClick}>
            {title}
        </div>
    )

const NavigationMenu = ({ user }: { user: User }): JSX.Element => {
    const [headerState, headerBloc] = useBloc(() => DI.headerBloc())

    return (
        <div className="header-navigation-menu">
            {user.navItems[NavItem.Dashboard] && (
                <NavigationTab label="Dashboard" href={ExternalRoutes.SmartHubDashboard()} isActive={false} />
            )}
            {user.navItems[NavItem.MapSites] && (
                <NavigationTab label="Map" href={ExternalRoutes.SmartHubMap()} isActive={false} />
            )}
            {user.navItems[NavItem.Assets] && (
                <NavigationTab label="Assets" href={ExternalRoutes.SmartHubAssets()} isActive={false} />
            )}
            {user.navItems[NavItem.Users] && (
                <NavigationTab label="Users" href={ExternalRoutes.SmartHubUsers()} isActive={false} />
            )}
            {user.hasAccess(PermissionObject.MaintenanceDashboard, PermissionType.View) && (
                <NavigationTab label="Maintenance" isActive={true} />
            )}
            {user.navItems[NavItem.ServiceSchedule] && (
                <NavigationTab label="Service" href={ExternalRoutes.SmartHubServiceSchedule()} isActive={false} />
            )}
            {user.navItems[NavItem.Sites] && (
                <NavigationTab label="Sites" href={ExternalRoutes.SmartHubSites()} isActive={false} />
            )}
            {user.navItems[NavItem.Tasks] && (
                <NavigationTab label="Admin" href={ExternalRoutes.SmartHubTasks()} isActive={false} />
            )}
            {user.navItems[NavItem.Clients] && (
                <NavigationTab label="Clients" href={ExternalRoutes.SmartHubClients()} isActive={false} />
            )}
            {user.navItems[NavItem.Reports] && (
                <NavigationTab label="Reports" href={ExternalRoutes.SmartHubReports()} isActive={false} />
            )}
            {user.navItems[NavItem.Company] && (
                <NavigationTab label="Company" href={ExternalRoutes.SmartHubCompany()} isActive={false} />
            )}
            {user.navItems[NavItem.Notifications] && (
                <NavigationTab label="Alerts" href={ExternalRoutes.SmartHubNotifications()} isActive={false} />
            )}
            {user.navItems[NavItem.WinterOps] && (
                <NavigationTab label="Winter Ops" href={ExternalRoutes.SmartHubWinterOps()} isActive={false} />
            )}
            {user.navItems[NavItem.Elog] && (
                <NavigationTab label="eLog" href={ExternalRoutes.SmartHubElog()} isActive={false} />
            )}
            <div className="header-asset-search-cont">
                <SearchableTextInput
                    className="header-asset-search"
                    options={headerState.foundAssets.map((asset) => ({
                        label: labelForAsset(asset),
                        value: asset,
                        key: asset.id,
                    }))}
                    popupPreContent={({ close }) => (
                        <div className="header-asset-search-result-count">
                            <div>{headerState.foundAssets.length} Results</div>
                            <button onClick={close}>Close</button>
                        </div>
                    )}
                    optionContent={({ option, onOptionSelected }) => (
                        <div className="header-asset-search-result" onClick={() => onOptionSelected(option)}>
                            <a
                                className="header-asset-search-result-text-cont"
                                href={ExternalRoutes.SmartHubAsset(option.value.id)}
                                target="_blank"
                            >
                                <div className="header-asset-search-result-text">{labelForAsset(option.value)}</div>
                            </a>
                        </div>
                    )}
                    optionContentHeight={40}
                    placeholder={"Search Asset (Identifier, VIN, Plate #)"}
                    isLoading={headerState.isSearching}
                    emptyMessage={"No assets found"}
                    debounceMillis={250}
                    minSearchCharacters={3}
                    value={""}
                    onSearchChanged={headerBloc.searchForAssets}
                />
            </div>
        </div>
    )
}

const NavigationTab = ({ label, href, isActive }: { label: string; href?: string; isActive: boolean }): JSX.Element =>
    href !== undefined ? (
        <a className={`navigation-tab${isActive ? " navigation-tab--active" : ""}`} href={href} data-label={label}>
            <span className="navigation-tab-label">{label}</span>
        </a>
    ) : (
        <div className={`navigation-tab${isActive ? " navigation-tab--active" : ""}`} data-label={label}>
            <span className="navigation-tab-label">{label}</span>
        </div>
    )

const SubNav = ({ filterButton, user }: { filterButton?: () => JSX.Element; user: User }): JSX.Element => {
    const route = useRoute()

    return (
        <div className="header-sub-nav-cont">
            <div className="header-sub-nav">
                <SubNavLink
                    to={Routes.Dashboard()}
                    label="Dashboard"
                    isActive={isRouteMatch(route, Routes.Dashboard)}
                />
                {user.hasAccess(PermissionObject.WorkRequest, PermissionType.View) && (
                    <SubNavLink
                        to={Routes.WorkRequests()}
                        label="Service Requests"
                        isActive={isRouteMatch(route, [
                            Routes.WorkRequests,
                            Routes.AssetWorkRequests,
                            Routes.WorkRequest,
                        ])}
                    />
                )}
                {user.hasAccess(PermissionObject.ServiceQuote, PermissionType.View) && (
                    <SubNavLink
                        to={Routes.ServiceQuotes()}
                        label="Service Quotes"
                        isActive={isRouteMatch(route, [
                            Routes.ServiceQuotes,
                        ])}
                    />
                )}
                {user.hasAccess(PermissionObject.MaintenanceWorkOrder, PermissionType.View) && (
                    <SubNavLink
                        to={Routes.WorkOrders()}
                        label="Work Orders"
                        isActive={isRouteMatch(route, [Routes.WorkOrders, Routes.WorkOrder])}
                    />
                )}
                {user.hasAccess(PermissionObject.MaintenanceAdminPage, PermissionType.View) && (
                    <SubNavLink
                        to={Routes.GlobalSettings()}
                        label="Admin"
                        isActive={isRouteMatch(route, Routes.GlobalSettings)}
                    />
                )}
            </div>
            {filterButton?.()}
        </div>
    )
}

const SubNavLink = ({ to, label, isActive }: { to: RouteItemData; label: string; isActive: boolean }): JSX.Element => (
    <Link className={`header-sub-nav-link${isActive ? " header-sub-nav-link--active" : ""}`} to={to}>
        <span className="header-sub-nav-link-label">{label}</span>
    </Link>
)
