import { Outcome } from "@ethossoftworks/outcome"
import { SortOrder } from "@lib/Comparable"
import { PDFAsset } from "@lib/pdf/models/PDFAsset"
import { PDFAssetPart } from "@lib/pdf/models/PDFAssetPart"
import { PDFWorkOrder } from "@lib/pdf/models/PDFWorkOrder"
import { pdfAssetFromModel } from "@model/assets/Asset"
import { pdfAssetPartFromModel } from "@model/assets/AssetPart"
import { AsyncStatus } from "@model/AsyncStatus"
import { Contact } from "@model/contacts/Contact"
import { Filter, isEmptyFilterValue } from "@model/filters/Filter"
import { PagedResponse, Pagination } from "@model/Pagination"
import { ServiceCode } from "@model/serviceRequests/ServiceRequest"
import { Site } from "@model/site/Site"
import {
    defaultWorkOrdersTableColumns,
    pdfWorkOrderFromModels,
    WorkOrderFilter,
    WorkOrderQuickFilterCount,
    WorkOrderTableColumn,
    WorkOrderTableRow,
} from "@model/workOrders/WorkOrder"
import { WorkOrderFilterDefinitions } from "@model/workOrders/WorkOrderFilterDefinitions"
import { WorkOrderPrintOption } from "@model/workOrders/WorkOrderPrintOption"
import { UserWorkRequestsTableRow, WorkRequestFilter } from "@model/workRequests/WorkRequest"
import { WorkRequestsFilterDefinitions } from "@model/workRequests/WorkRequestsFilterDefinitions"
import { downloadBlob } from "@service/ApiService"
import { AssetService } from "@service/assets/AssetService"
import { WorkOrderService } from "@service/workOrders/WorkOrderService"
import { WorkRequestService } from "@service/workRequests/WorkRequestService"
import { SortableTableBloc, SortableTableBlocEffect, SortableTableState } from "@state/SortableTableBloc"

export type WorkOrdersTableState = SortableTableState<
    WorkOrderTableColumn,
    WorkOrderTableRow,
    WorkOrderFilter,
    ExtraState
>

type ExtraState = {
    workOrderToPrint: number | null
    workOrderToPrintIsLoading: boolean
    counts: Record<WorkOrderQuickFilterCount, number>
    ignoreUserPresetFilterOnLoad: boolean
    pdfAsset?: PDFAsset
    pdfWorkOrder?: PDFWorkOrder
    pdfAssetParts?: PDFAssetPart[]
    pdfCompanyName?: string
    selectedPrintOption?: WorkOrderPrintOption
    userWorkRequests?: UserWorkRequestsTableRow[]
}

export enum WorkOrdersTableEffect {
    Print = "print",
}

export class WorkOrdersTableBloc extends SortableTableBloc<
    WorkOrderTableColumn,
    WorkOrderTableRow,
    WorkOrderFilter,
    ExtraState
> {
    constructor(
        private workOrderService: WorkOrderService,
        private assetService: AssetService,
        private workRequestService: WorkRequestService
    ) {
        super({
            filters: Object.values(WorkOrderFilter),

            filterDefinitions: WorkOrderFilterDefinitions,

            defaultManagedColumns: defaultWorkOrdersTableColumns,

            readOnlyManagedColumns: [WorkOrderTableColumn.WorkOrder],

            dataFetcher: async (
                filters?: Filter<WorkOrderFilter>[],
                sort?: { column: WorkOrderTableColumn; order: SortOrder },
                pagination?: Pagination
            ): Promise<Outcome<PagedResponse<WorkOrderTableRow[]>>> => {
                if (!sort) sort = { column: WorkOrderTableColumn.Asset, order: SortOrder.Asc }
                const results = await workOrderService.fetchWorkOrders(filters, sort, pagination)
                if (results.isOk()) this.update({ counts: results.value.counts })
                return results
            },
            initialExtraState: {
                workOrderToPrint: null,
                workOrderToPrintIsLoading: false,
                counts: {
                    [WorkOrderQuickFilterCount.Completed]: 0,
                    [WorkOrderQuickFilterCount.High]: 0,
                    [WorkOrderQuickFilterCount.Immediate]: 0,
                    [WorkOrderQuickFilterCount.InProgress]: 0,
                    [WorkOrderQuickFilterCount.Low]: 0,
                    [WorkOrderQuickFilterCount.Medium]: 0,
                    [WorkOrderQuickFilterCount.Open]: 0,
                    [WorkOrderQuickFilterCount.Overdue]: 0,
                    [WorkOrderQuickFilterCount.Pending]: 0,
                    [WorkOrderQuickFilterCount.RedTag]: 0,
                    [WorkOrderQuickFilterCount.WorkOrders]: 0,
                    [WorkOrderQuickFilterCount.YellowTag]: 0,
                },
                ignoreUserPresetFilterOnLoad: false,
            },

            persistStateOnDispose: true,
        })
    }

    fetchUserWorkRequests = (userId: number) => {
        console.log(this.state.filters)
        this.effect({
            id: SortableTableBlocEffect.FetchUserWorkRequests,
            block: async (job) => {
                this.effectStatusChanged(SortableTableBlocEffect.FetchUserWorkRequests, AsyncStatus.busy())
                let filters: Filter<WorkRequestFilter>[] = [
                    {
                        definition: WorkRequestsFilterDefinitions.requestedBy,
                        values: [{ value: userId, label: "" }],
                    },
                ]

                const dataOutcome = await job.pause(this.workRequestService.fetchWorkRequestsByUser(filters))
                if (!dataOutcome.isOk()) {
                    this.effectStatusChanged(
                        SortableTableBlocEffect.FetchUserWorkRequests,
                        AsyncStatus.error(dataOutcome)
                    )
                    return dataOutcome
                }

                this.effectStatusChanged(SortableTableBlocEffect.FetchUserWorkRequests, AsyncStatus.idle())

                this.update({ userWorkRequests: dataOutcome.value.data })
                return dataOutcome
            },
        })
    }

    exportData = () => {
        console.log(this.state.filters)
        this.effect({
            id: SortableTableBlocEffect.Fetch,
            block: async (job) => {
                this.effectStatusChanged(SortableTableBlocEffect.Fetch, AsyncStatus.busy())
                const filtersArray = Object.values(this.state.filters ?? []).filter(
                    (filter) => !isEmptyFilterValue(filter)
                )
                const dataOutcome = await job.pause(this.workOrderService.fetchWorkOrderXls(filtersArray))
                if (!dataOutcome.isOk()) {
                    this.effectStatusChanged(SortableTableBlocEffect.Fetch, AsyncStatus.error(dataOutcome))
                    return dataOutcome
                }
                const blob = dataOutcome.value
                if (blob) {
                    downloadBlob(blob, "WorkOrdersList.xlsx")
                }
                this.effectStatusChanged(SortableTableBlocEffect.Fetch, AsyncStatus.idle())
                return dataOutcome
            },
        })
    }

    workOrderToPrintChanged = (workOrderId: number | null) =>
        this.update({
            workOrderToPrint: workOrderId,
        })

    printWorkOrder = async (
        workOrderPrintOption: WorkOrderPrintOption,
        contacts: Contact[],
        serviceCodes: ServiceCode[],
        sites: Site[]
    ) => {
        let pdfAsset: PDFAsset | null
        let pdfWorkOrder: PDFWorkOrder
        let pdfAssetParts: PDFAssetPart[] | undefined = undefined
        let pdfCompanyName: string = ""
        this.update({
            workOrderToPrintIsLoading: true,
        })

        const workOrderOutcome = await this.workOrderService.fetchWorkOrder(this.state.workOrderToPrint!)
        if (workOrderOutcome.isError()) {
            return workOrderOutcome
        }

        const assetOutcome = await this.assetService.fetchAsset(workOrderOutcome.value.assetId)
        if (assetOutcome.isError()) {
            return assetOutcome
        }
        pdfAsset = pdfAssetFromModel(assetOutcome.value)
        pdfCompanyName = assetOutcome.value.companyName
        if (
            workOrderPrintOption == WorkOrderPrintOption.Summary ||
            workOrderPrintOption == WorkOrderPrintOption.WorkOrder ||
            workOrderPrintOption == WorkOrderPrintOption.SummaryWithParts ||
            workOrderPrintOption == WorkOrderPrintOption.WorkOrderWithParts
        ) {
            const workRequestsOutcome = await this.workRequestService.fetchWorkRequestsForWorkOrder(
                workOrderOutcome.value.id
            )
            if (workRequestsOutcome.isError()) {
                return workRequestsOutcome
            }
            pdfWorkOrder = pdfWorkOrderFromModels(
                workOrderOutcome.value,
                workRequestsOutcome.value,
                contacts,
                serviceCodes,
                sites
            )
        } else {
            pdfWorkOrder = pdfWorkOrderFromModels(workOrderOutcome.value, [], contacts, serviceCodes, sites)
        }

        if (
            workOrderPrintOption == WorkOrderPrintOption.Parts ||
            workOrderPrintOption == WorkOrderPrintOption.SummaryWithParts ||
            workOrderPrintOption == WorkOrderPrintOption.WorkOrderWithParts
        ) {
            const assetPartsOutcome = await this.assetService.fetchParts(assetOutcome.value.id)
            if (assetPartsOutcome.isError()) {
                return assetPartsOutcome
            }

            pdfAssetParts = assetPartsOutcome.value.map(pdfAssetPartFromModel)
        }

        this.update({
            pdfAsset: pdfAsset,
            pdfWorkOrder: pdfWorkOrder,
            pdfAssetParts: pdfAssetParts,
            pdfCompanyName: pdfCompanyName,
            selectedPrintOption: workOrderPrintOption,
            workOrderToPrintIsLoading: false,
            workOrderToPrint: null, // This hides the printing options dialog
        })

        return Outcome.ok(undefined)
    }
}
