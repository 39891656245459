import { Bloc } from "@lib/bloc/Bloc"
import { arrayToObject, safeParseAndRoundFloat, safeParseInt } from "@lib/TypeUtil"
import { Step } from "@model/Step"
import {
    AdjustmentAction,
    AdjustServiceRequestForm,
    AdjustServiceRequestsForm,
    newAdjustServiceRequestForm,
    newAdjustServiceRequestsForm,
} from "@model/workOrders/AdjustServiceRequestsForm"
import { WorkRequest } from "@model/workRequests/WorkRequest"

export type AdjustServiceRequestsState = AdjustServiceRequestsForm

export class AdjustServiceRequestsBloc extends Bloc<AdjustServiceRequestsState> {
    constructor() {
        super(newAdjustServiceRequestsForm(), { persistStateOnDispose: false })
    }

    private requestFormFieldChanged = (
        requestId: number,
        block: (state: AdjustServiceRequestForm) => Partial<AdjustServiceRequestForm>
    ) => {
        const form = this.state.scheduleServiceRequestForms[requestId]
        if (!form) return this.state

        return this.update({
            scheduleServiceRequestForms: {
                ...this.state.scheduleServiceRequestForms,
                [requestId]: {
                    ...form,
                    ...block(form),
                },
            },
        })
    }

    requestStepChanged = async (requestId: number, value: Step) =>
        this.requestFormFieldChanged(requestId, (state) => ({ step: value, workToBePerformed: value?.label }))

    requestAdjustmentActionChanged = (requestId: number, value: AdjustmentAction) =>
        this.requestFormFieldChanged(requestId, (state) => ({ adjustmentAction: value }))

    requestAdjustedDueDateChanged = (requestId: number, value: Date) =>
        this.requestFormFieldChanged(requestId, (state) => ({ adjustedDueDate: value }))

    requestAdjustedDueHourMeterChanged = (requestId: number, value: string) => {
        this.requestFormFieldChanged(requestId, (state) => ({
            adjustedDueHourMeter: value,
            differenceDueHourMeter: calculateDifferenceFloat(value, state.dueHourMeter),
        }))
    }

    requestAdjustedDueOdometerChanged = (requestId: number, value: string) => {
        this.requestFormFieldChanged(requestId, (state) => ({
            adjustedDueOdometer: value,
            differenceDueOdometer: calculateDifference(value, state.dueOdometer),
        }))
    }

    requestApplyChangeChanged = (requestId: number, value: boolean) =>
        this.requestFormFieldChanged(requestId, (state) => ({ applyManualAdjustment: value }))

    isSecondStepChanged = (value: boolean) => this.update({ isSecondStep: value })

    setScheduleWorkRequests = (value: WorkRequest[]) => this.update({ scheduleServiceRequests: value })
    setScheduleWorkRequestForms = (value: WorkRequest[]) =>
        this.update({
            scheduleServiceRequestForms: arrayToObject(value, (it) => [it.id, newAdjustServiceRequestForm(it)]),
        })
}

function calculateDifference(val1: string, val2: string): string {
    let result = 0
    let val1Numb = safeParseInt(val1)
    let val2Numb = safeParseInt(val2)
    result = val1Numb - val2Numb

    return result.toString()
}

function calculateDifferenceFloat(val1: string, val2: string): string {
    let result = 0
    let val1Numb = safeParseAndRoundFloat(val1)
    let val2Numb = safeParseAndRoundFloat(val2)
    result = val1Numb - val2Numb

    return result.toString()
}
