import { useBlocCoordinator } from "@lib/bloc/hooks"
import {  safeParseInt  } from "@lib/TypeUtil"
import { labelForContact } from "@model/contacts/Contact"
import { ServiceQuote } from "@model/serviceQuotes/ServiceQuote"
import { labelForServiceSchedule, ServiceSchedule } from "@model/serviceRequests/ServiceRequest"
import { User } from "@model/user/User"
import { Button, ButtonType } from "@ui/common/buttons/Button"
import { FormDivider, FormDividerMargin } from "@ui/common/form/FormDivider"
import { FormField } from "@ui/common/form/FormField"
import { MultiSelect } from "@ui/common/form/MultiSelect"
import { Radio } from "@ui/common/form/Radio"
import { TextArea } from "@ui/common/form/TextArea"
import { Grid, GridCont, GridGap } from "@ui/common/grids/Grid"
import {
    Modal,
    ModalBody,
    ModalButtons,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalSize,
} from "@ui/common/Modal"
import { DI } from "@ui/DI"
import { xor } from "lodash"
import React, { useEffect, useState } from "react"



export type AssociateServiceScheduleModalProps = {
    onSaveClicked: (selectedAssetSchedules: any[]) => void
    serviceQuote: ServiceQuote | null
    assetSchedules: ServiceSchedule[]
    isVisible: boolean
}

export function AssociateServiceScheduleModal(props: AssociateServiceScheduleModalProps): JSX.Element {
    return (
        <Modal isVisible={props.isVisible} useContentTag={true} size={ModalSize.Large}>
            <AssociateServiceScheduleModalContent {...props} />
        </Modal>
    )
}

function AssociateServiceScheduleModalContent(props: AssociateServiceScheduleModalProps): JSX.Element {
    const [selectRadioOption, setSelectRadioOption] = useState(false)
    const [selectedAssetSchedules, setSelectedAssetSchedules] = useState<number[]>([])
    
    useEffect(() => {
        
    }, [])

    return (
        <ModalContent>
            <ModalHeader>
                <ModalHeaderTitle>{`Associate Service Schedule - Service Quote ${props.serviceQuote?.number}`}  </ModalHeaderTitle>
            </ModalHeader>
            <ModalBody>
            <Grid columns={1} gap={GridGap.Small}>
                    <GridCont>
                        <Grid columns={1}>  
                            <FormField label="Are any of the approved services on this Service Quote related to the Asset's Service Schedule(s)?">
                                <Radio
                                    isChecked={!selectRadioOption}
                                    label="No"
                                    onSelected={() => setSelectRadioOption(false)}
                                />
                                <Radio
                                    isChecked={selectRadioOption}
                                    label="Yes"
                                    onSelected={() => setSelectRadioOption(true)}
                                />                                
                            </FormField>
                            
                        </Grid>
                        { selectRadioOption ? <Grid columns={2}>
                            <FormField label="Asset's Service Schedules">
                                <MultiSelect
                                    options={props.assetSchedules.map((schedule) => ({
                                        key: schedule.id,
                                        value: `${schedule.id}`,
                                        label: labelForServiceSchedule(schedule),
                                        isChecked: selectedAssetSchedules.includes(schedule.id),
                                        checkAll: false,
                                    }))}
                                    onSelectionChanged={(selection) =>
                                        setSelectedAssetSchedules(selection.map((id) => safeParseInt(id)))
                                    }
                                />
                            </FormField>
                            <FormField label="Service Schedule Asociated to Service Quote">
                                <TextArea 
                                    value={props.assetSchedules.filter(x=> selectedAssetSchedules.includes(x.id) ).map((schedule) => labelForServiceSchedule(schedule) + "\n")} >
                                </TextArea>                                
                            </FormField>
                        </Grid>: <></> }                        
                    </GridCont>
                </Grid>
            </ModalBody>
            <ModalButtons>
                <Button
                    label={"Save"}
                    type={ButtonType.Contained}
                    onClick={() => props.onSaveClicked(selectedAssetSchedules)}                    
                    isEnabled={selectRadioOption ? selectedAssetSchedules.length > 0 : true}
                />
            </ModalButtons>
        </ModalContent>
    )
}