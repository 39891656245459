import { CompanyAlias, defaultCompanyAlias } from "@model/company/CompanyAlias"
import { SortableTableColumnDef, TableColumnAlignment, TableColumnSize } from "@model/tables/Table"
import { WorkRequestTableColumn } from "@model/workRequests/WorkRequest"

export const WorkRequestsTableColumnDefinitions: Record<
    WorkRequestTableColumn,
    SortableTableColumnDef<WorkRequestTableColumn>
> = {
    [WorkRequestTableColumn.Tag]: {
        type: WorkRequestTableColumn.Tag,
        label: "Tag",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        className: "table-cell--tag",
        isRequired: false,
    },
    [WorkRequestTableColumn.Asset]: {
        type: WorkRequestTableColumn.Asset,
        label: "Asset",
        size: TableColumnSize.Medium,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: true,
    },
    [WorkRequestTableColumn.Service]: {
        type: WorkRequestTableColumn.Service,
        label: "Repair",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: false,
    },
    [WorkRequestTableColumn.PreventativeMaintenance]: {
        type: WorkRequestTableColumn.PreventativeMaintenance,
        label: "Preventative",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: false,
    },
    [WorkRequestTableColumn.Inspection]: {
        type: WorkRequestTableColumn.Inspection,
        label: "Inspection",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: false,
    },
    [WorkRequestTableColumn.Total]: {
        type: WorkRequestTableColumn.Total,
        label: "Total",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: false,
    },
    [WorkRequestTableColumn.Location]: {
        type: WorkRequestTableColumn.Location,
        label: "Last Known Location",
        size: TableColumnSize.Medium,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: false,
    },
    [WorkRequestTableColumn.WorkOrder]: {
        type: WorkRequestTableColumn.WorkOrder,
        label: "Existing Work Orders",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: false,
    },
    [WorkRequestTableColumn.HourMeter]: {
        type: WorkRequestTableColumn.HourMeter,
        label: "Hour Meter",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: false,
    },
    [WorkRequestTableColumn.Odometer]: {
        type: WorkRequestTableColumn.Odometer,
        label: "Odometer",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: false,
    },
    [WorkRequestTableColumn.DaysInactive]: {
        type: WorkRequestTableColumn.DaysInactive,
        label: "Days Inactive",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: false,
    },
    [WorkRequestTableColumn.Class]: {
        type: WorkRequestTableColumn.Class,
        label: "Class",
        size: TableColumnSize.Normal,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: false,
    },
    [WorkRequestTableColumn.EstimatedLaborHours]: {
        type: WorkRequestTableColumn.EstimatedLaborHours,
        label: "Estimated Labor Hours",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: false,
    },
    [WorkRequestTableColumn.EstimatedLaborCost]: {
        type: WorkRequestTableColumn.EstimatedLaborCost,
        label: "Estimated Labor Cost",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: false,
    },
    [WorkRequestTableColumn.EstimatedPartsCost]: {
        type: WorkRequestTableColumn.EstimatedPartsCost,
        label: "Estimated Parts Cost",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: false,
    },
    [WorkRequestTableColumn.EstimatedTotalCost]: {
        type: WorkRequestTableColumn.EstimatedTotalCost,
        label: "Total Estimated Cost",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: false,
    },
    [WorkRequestTableColumn.SubCompany]: {
        type: WorkRequestTableColumn.SubCompany,
        label: defaultCompanyAlias(CompanyAlias.SubCompany),
        size: TableColumnSize.Normal,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: false,
    },
    [WorkRequestTableColumn.District]: {
        type: WorkRequestTableColumn.District,
        label: defaultCompanyAlias(CompanyAlias.District),
        size: TableColumnSize.Normal,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: false,
    },
    [WorkRequestTableColumn.SubDistrict]: {
        type: WorkRequestTableColumn.SubDistrict,
        label: defaultCompanyAlias(CompanyAlias.SubDistrict),
        size: TableColumnSize.Normal,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: false,
    },
    [WorkRequestTableColumn.Unit]: {
        type: WorkRequestTableColumn.Unit,
        label: defaultCompanyAlias(CompanyAlias.Unit),
        size: TableColumnSize.Normal,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: false,
    },
    [WorkRequestTableColumn.Group]: {
        type: WorkRequestTableColumn.Group,
        label: defaultCompanyAlias(CompanyAlias.Group),
        size: TableColumnSize.Normal,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: false,
    },
    [WorkRequestTableColumn.City]: {
        type: WorkRequestTableColumn.City,
        label: "City",
        size: TableColumnSize.Normal,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: false,
    },
    [WorkRequestTableColumn.State]: {
        type: WorkRequestTableColumn.State,
        label: "State",
        size: TableColumnSize.Normal,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: false,
    },
}
