import { PDFWorkRequest } from "./PDFWorkRequest"

export type PDFWorkOrder = {
    originalWorkOrder: any
    originalContacts: any[]
    workOrderNumber: string
    dueDate: string
    dueHourMeter?: number | null
    dueOdometer?: number | null
    urgency: string
    assignedTo?: string
    specialInstructions: string
    workRequests: PDFWorkRequest[]
    status: PDFWorkOrderStatus
    siteName?: string
    closedDate?: string
}

export enum PDFWorkOrderStatus {
    Deleted = "deleted",
    Pending = "pending",
    Open = "open",
    InProgress = "inProgress",
    WorkCompleted = "workCompleted",
    Closed = "closed",
}
export function isClosed(workOrder: PDFWorkOrder) {
    return [PDFWorkOrderStatus.Closed, PDFWorkOrderStatus.WorkCompleted].includes(workOrder.status)
}
export function labelForWorkOrderStatus(status: PDFWorkOrderStatus): string {
    switch (status) {
        case PDFWorkOrderStatus.Deleted:
            return "Deleted"
        case PDFWorkOrderStatus.Pending:
            return "Pending"
        case PDFWorkOrderStatus.Open:
            return "Open"
        case PDFWorkOrderStatus.InProgress:
            return "In Progress"
        case PDFWorkOrderStatus.WorkCompleted:
            return "Work Completed"
        case PDFWorkOrderStatus.Closed:
            return "Closed"
    }
}

export function pdfHeaderTitleForWorkOrder(workOrder: PDFWorkOrder): string {
    return `WO# ${workOrder.workOrderNumber} - ${labelForWorkOrderStatus(workOrder.status)}`
}
