export function safeParseFloat(value?: unknown): number {
    if (isNumber(value)) {
        return value
    } else if (isString(value)) {
        const newValue = parseFloat(value.replace(/[^0-9\.\-]/g, ""))
        return isNaN(newValue) ? 0.0 : newValue
    }
    return 0.0
}

export const isNumber = (value?: unknown): value is number => typeof value === "number" && !isNaN(value)

export const isString = (value?: unknown): value is string => typeof value === "string" || value instanceof String
