import { useBlocCoordinator } from "@lib/bloc/hooks"
import { SortOrder } from "@lib/Comparable"
import { Strings } from "@lib/Strings"
import { safeParseFloat, safeParseInt, stringArrayOrDefault } from "@lib/TypeUtil"
import { Link } from "@material-ui/core"
import { labelForContact } from "@model/contacts/Contact"
import { ApproveServiceQuoteForm, ServiceQuote, ServiceQuoteItem, ServiceQuoteStatus } from "@model/serviceQuotes/ServiceQuote"
import { TableColumnSize } from "@model/tables/Table"
import { compareUrgency, labelForUrgency, Urgency, urgencyForValue } from "@model/Urgency"
import { User } from "@model/user/User"
import {
    labelForWorkOrderStatus,
    WorkOrder,
    WorkOrderStatus,
    workOrderStatusForValue,
} from "@model/workOrders/WorkOrder"
import { getMinDueDateAsDate, WorkRequest } from "@model/workRequests/WorkRequest"
import { Button, ButtonType } from "@ui/common/buttons/Button"
import { CheckBox } from "@ui/common/form/CheckBox"
import { DatePicker, TextInputDatePicker } from "@ui/common/form/DatePicker"
import { FormDivider, FormDividerMargin } from "@ui/common/form/FormDivider"
import { FormField, FormFieldLabel } from "@ui/common/form/FormField"
import { MultiSelect } from "@ui/common/form/MultiSelect"
import { Select } from "@ui/common/form/Select"
import { TextArea } from "@ui/common/form/TextArea"
import { TextInput, TextInputType } from "@ui/common/form/TextInput"
import { Grid, GridCont, GridGap } from "@ui/common/grids/Grid"
import {
    Modal,
    ModalBody,
    ModalButtons,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalSize,
} from "@ui/common/Modal"
import { CollapsibleTable, CollapsibleTableRow } from "@ui/common/tables/CollapsibleTable"
import { DI } from "@ui/DI"
import { xor } from "lodash"
import React, { useEffect } from "react"

const CreationWorkOrderStatus = [WorkOrderStatus.Pending, WorkOrderStatus.Open, WorkOrderStatus.InProgress]

export type ApproveServiceQuoteModalProps = {
    onApproveClicked: (approveServiceQuoteForm: ApproveServiceQuoteForm) => void
    onCancelClicked: () => void
    serviceQuote: ServiceQuote | null
    serviceRequests: ServiceQuoteItem[]
    isVisible: boolean
    user: User
}

export function ApproveServiceQuoteModal(props: ApproveServiceQuoteModalProps): JSX.Element {
    return (
        <Modal isVisible={props.isVisible} useContentTag={true} onClose={props.onCancelClicked} size={ModalSize.Large}>
            <ApproveServiceQuoteModalContent {...props} />
        </Modal>
    )
}

function ApproveServiceQuoteModalContent(props: ApproveServiceQuoteModalProps): JSX.Element {
    const [state, viewModel] = useBlocCoordinator(() => DI.approveServiceQuoteViewModel())

    useEffect(() => {
         
    }, [])

    return (
        <ModalContent>
            <ModalHeader>
                <ModalHeaderTitle>{`Service Quote - ${props.serviceQuote?.number} (${props.serviceQuote?.vendor?.VendorName})`}  </ModalHeaderTitle>
            </ModalHeader>
            <ModalBody>
            <Grid columns={1} gap={GridGap.Small}>
                    <ServiceRequestsTable serviceRequests={props.serviceRequests}  />
                    <FormDivider margin={FormDividerMargin.Small} />
                    <GridCont>
                        <Grid columns={1}>  
                            <FormField label="Message to Vendor">
                                <TextArea
                                    maxLength={1000}
                                    value={state.vendorMessage}
                                    onChange={(value) => viewModel.vendorMessageChanged(value)}
                                />
                            </FormField>
                        </Grid>
                        <Grid columns={2}>
                            <FormField label="Notify Contacts">
                                <MultiSelect
                                    options={state.contacts.map((contact) => ({
                                        key: contact.id,
                                        value: `${contact.id}`,
                                        label: labelForContact(contact),
                                        isChecked: state.notifyContacts.includes(contact.id),
                                        checkAll: false,
                                    }))}
                                    onSelectionChanged={(selection) =>
                                        viewModel.notifyContactsChanged(selection.map((contactId) => safeParseInt(contactId)))
                                    }
                                />
                            </FormField>
                        </Grid>
                        <Grid columns={2}>
                            <FormField label="Purchase Order Number">
                                <TextInput
                                    value={state.purchaseOrderNumber}
                                    onChange={(value) => viewModel.purchaseOrderNumberChanged(value)}
                                    min={0}
                                    type={TextInputType.Text}
                                />
                            </FormField>
                            <FormField label="Create Work Order?">
                            <CheckBox
                                label="Yes"
                                onCheckChanged={(isChecked) => viewModel.createWorkOrderChanged(isChecked)}
                                isChecked={state.createWorkOrder}
                                style={{
                                    marginTop: "10px"
                                }}
                            />
                            </FormField>
                        </Grid>
                    </GridCont>
                </Grid>
                { state.createWorkOrder ? 
                    <Grid columns={1} gap={GridGap.Small}>
                    <FormDivider margin={FormDividerMargin.Small} />
                    <FormFieldLabel>Work Order:</FormFieldLabel>
                    <GridCont>
                        <Grid columns={2}>
                            <FormField label="Status">
                                <Select
                                    value={state.workOrderStatus}
                                    options={CreationWorkOrderStatus.map((it) => ({
                                        label: labelForWorkOrderStatus(it),
                                        value: it,
                                    }))}
                                    onChange={(value) => viewModel.workOrderStatusChanged(workOrderStatusForValue(value))}
                                />
                            </FormField>
                            <FormField label={"Assigned To" + (state.workOrderStatus != WorkOrderStatus.Pending ? "*" : "")}>
                                <Select
                                    value={state.workOrderAssignedTo}
                                    options={[
                                        {label: "Select Mechanic...", value: ""},
                                        ...state.mechanics.filter((contact) => !contact.deleted).map((it) => ({
                                            label: labelForContact(it, props.user),
                                            value: it.id.toString(),
                                        })),
                                    ]}
                                    onChange={(value) => viewModel.workOrderAssignedToChanged(safeParseInt(value))}
                                />
                            </FormField>
                            <FormField label="Urgency">
                                <Select
                                    value={state.workOrderUrgency}
                                    options={Object.values(Urgency).map((it) => ({
                                        label: labelForUrgency(it),
                                        value: it,
                                    }))}
                                    onChange={(value) => viewModel.workOrderUrgencyChanged(urgencyForValue(value))}
                                />
                            </FormField>
                            <FormField label={"Due Date"}>
                                <TextInputDatePicker
                                    value={state.workOrderDueDate}
                                    onChange={(value) => viewModel.workOrderDueDateChanged(value)}
                                />
                            </FormField>
                            <FormField label="Due Hour Meter">
                                <TextInput
                                    value={state.workOrderDueHourMeter}
                                    onChange={(value) => viewModel.workOrderDueHourMeterChanged(value)}
                                    min={0}
                                    type={TextInputType.Decimal}
                                />
                            </FormField>
                            <FormField label="Due Odometer">
                                <TextInput
                                    value={state.workOrderDueOdometer}
                                    onChange={(value) => viewModel.workOrderDueOdometerChanged(value)}
                                    min={0}
                                    type={TextInputType.Integer}
                                />
                            </FormField>
                        </Grid>
                        <Grid columns={1}>
                            <FormField label="Special Instructions">
                                <TextArea
                                    maxLength={1000}
                                    value={state.workOrderSpecialInstructions}
                                    onChange={(value) => viewModel.workOrderSpecialInstructionsChanged(value)}
                                />
                            </FormField>
                        </Grid>
                    </GridCont>
                </Grid> : <></>
                }
                
            </ModalBody>
            <ModalButtons>
                <Button label="Cancel" type={ButtonType.Text} onClick={props.onCancelClicked} />
                <Button
                    label={state.createWorkOrder ? "Create Work Order" : "Approve"}
                    type={ButtonType.Contained}
                    onClick={() => props.onApproveClicked(state)}
                    isEnabled={state.isFormValid}
                />
            </ModalButtons>
        </ModalContent>
    )
}

function ServiceRequestsTable({
    serviceRequests,
}: {
    serviceRequests: Record<number, ServiceQuoteItem>
}): JSX.Element {
    const head = [
        { size: TableColumnSize.FitContent, content: "" },
        { size: TableColumnSize.FitContent, content: "Labor Hours" },
        { size: TableColumnSize.FitContent, content: "Labor Cost" },
        { size: TableColumnSize.FitContent, content: "Parts Cost" },
        { size: TableColumnSize.FitContent, content: "Misc Cost" },
        { size: TableColumnSize.FitContent, content: "Total Cost" },
        { size: TableColumnSize.FitContent },
    ]

    const declinedServiceQuoteTotals = {
        laborHours: 0,
        laborCost: 0,
        partsCost: 0,
        miscCost: 0,
        totalCost: 0,
        count: 0,
    }

    const approveTotalstotals = {
        laborHours: 0,
        laborCost: 0,
        partsCost: 0,
        miscCost: 0,
        totalCost: 0,
        count: 0,
    }


    Object.values(serviceRequests).map((it, i) => {

        if (it.status == ServiceQuoteStatus.Declined) {
            declinedServiceQuoteTotals.laborHours += safeParseFloat(it.laborHours)
            declinedServiceQuoteTotals.laborCost += safeParseFloat(it.totalLaborCost)
            declinedServiceQuoteTotals.partsCost += safeParseFloat(it.totalPartsCost)
            declinedServiceQuoteTotals.miscCost += safeParseFloat(it.totalMiscCost)
            declinedServiceQuoteTotals.totalCost += declinedServiceQuoteTotals.laborCost + declinedServiceQuoteTotals.partsCost + declinedServiceQuoteTotals.miscCost
            declinedServiceQuoteTotals.count++;
        }
        
        

        if (it.status == ServiceQuoteStatus.Approved) {
            approveTotalstotals.laborHours += safeParseFloat(it.laborHours)
            approveTotalstotals.laborCost += safeParseFloat(it.totalLaborCost)
            approveTotalstotals.partsCost += safeParseFloat(it.totalPartsCost)
            approveTotalstotals.miscCost += safeParseFloat(it.totalMiscCost)
            approveTotalstotals.totalCost += approveTotalstotals.laborCost + approveTotalstotals.partsCost + approveTotalstotals.miscCost
            approveTotalstotals.count++;
        }
    })

    const data: CollapsibleTableRow[] = [
        {
            entryId: "totals-row-declined",
            columns: [
                { content: `Declined Service Requests (${declinedServiceQuoteTotals.count})`},
                { content: Strings.formatFloat(declinedServiceQuoteTotals.laborHours) },
                { content: `$\u00A0\u00A0${Strings.formatMoney(declinedServiceQuoteTotals.laborCost)}` },
                { content: `$\u00A0\u00A0${Strings.formatMoney(declinedServiceQuoteTotals.partsCost)}` },
                { content: `$\u00A0\u00A0${Strings.formatMoney(declinedServiceQuoteTotals.miscCost)}` },
                { content: `$ ${Strings.formatMoney(declinedServiceQuoteTotals.totalCost)}`, className: "total-row-label" },
                {},
            ],
            isCollapsed: true,
        },
        {
            entryId: "totals-row-approved",
            columns: [
                { content: `Approved Service Requests (${approveTotalstotals.count})`},
                { content: Strings.formatFloat(approveTotalstotals.laborHours) },
                { content: `$\u00A0\u00A0${Strings.formatMoney(approveTotalstotals.laborCost)}` },
                { content: `$\u00A0\u00A0${Strings.formatMoney(approveTotalstotals.partsCost)}` },
                { content: `$\u00A0\u00A0${Strings.formatMoney(approveTotalstotals.miscCost)}` },
                { content: `$ ${Strings.formatMoney(approveTotalstotals.totalCost)}`, className: "total-row-label" },
                {},
            ],
            isCollapsed: true,
        },
                  
    ]

    return <CollapsibleTable className="work-order-requests-table" head={head} data={data} />
}
