export type AssetModel = {
    id: number
    makeId: number
    name: string
}

export function newAssetModel(): AssetModel {
    return {
        id: 0,
        makeId: 0,
        name: "",
    }
}
