import { classNames } from "@lib/HtmlUtil"
import { TableColumn, TableColumnAlignment, TableColumnSize, TableRow } from "@model/tables/Table"
import React from "react"

export type TableProps = {
    className?: string
    head: TableColumn[]
    data: TableRow[]
}

export function Table(props: TableProps): JSX.Element {
    const columnSizes = props.head.reduce((accum, value) => {
        switch (value.size ?? TableColumnSize.Normal) {
            case TableColumnSize.MinContent:
                return `${accum} min-content`
            case TableColumnSize.FitContent:
                return `${accum} auto`
            case TableColumnSize.FitContentGrow:
                return `${accum} minmax(auto, 1fr)`
            case TableColumnSize.Normal:
                return `${accum} minmax(150px, 1fr)`
            case TableColumnSize.Medium:
                return `${accum} minmax(225px, 1.5fr)`
            case TableColumnSize.Large:
                return `${accum} minmax(300px, 2fr)`
            default:
                return accum
        }
    }, "")

    return (
        <div className={classNames("table", props.className)}>
            <div className="table-data-cont">
                <table className="table-data" style={{ gridTemplateColumns: `${columnSizes}` }}>
                    <thead>
                        <tr>
                            {props.head.map((column, i) => (
                                <Column
                                    type={TableColumnType.Head}
                                    column={column}
                                    columnCount={props.head.length}
                                    currentColumnIndex={i}
                                    key={`head_${i}`}
                                />
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.flatMap((row) => (
                            <tr className="table-row" data-id={row.entryId} key={row.entryId}>
                                {row.columns.map((column, i) => (
                                    <Column
                                        type={TableColumnType.Body}
                                        key={`${row.entryId}_${i}`}
                                        column={column}
                                        currentColumnIndex={i % props.head.length}
                                        columnCount={props.head.length}
                                    />
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

type TableColumnProps = {
    type: TableColumnType
    column: TableColumn
    columnCount: number
    currentColumnIndex: number
}

enum TableColumnType {
    Head = "head",
    Body = "body",
}

function Column(props: TableColumnProps): JSX.Element {
    const attributes = {
        ...(props.column.columnSpanOverride
            ? { style: { gridColumn: `span ${props.column.columnSpanOverride}` } }
            : {}),
        className: classNames(
            "table-cell",
            props.type === TableColumnType.Head ? "table-cell--head" : "table-cell--body",
            props.column.noHoverBackground ? "table-cell--no-hover-background" : null,
            props.column.className
        ),
        ["data-alignment"]: props.column.alignment ?? TableColumnAlignment.Start,
        ["data-size"]: props.column.size ?? TableColumnSize.Normal,
        ...(props.currentColumnIndex === props.columnCount - 1 ? { ["data-row-end"]: true } : {}),
        children: (
            <div
                className={classNames(
                    "table-cell__content",
                    props.type === TableColumnType.Head ? "table-cell--head__content" : "table-cell--body__content"
                )}
                onClick={props.column.onClick}
            >
                {props.column.content}
            </div>
        ),
    }

    return props.type === TableColumnType.Head ? <th {...attributes} /> : <td {...attributes} />
}
