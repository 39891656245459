import { classNames } from "@lib/HtmlUtil"
import { DI } from "@ui/DI"
import React, { useLayoutEffect } from "react"
import { Header } from "@ui/common/Header"

type PageProps = {
    children: React.ReactNode
    className?: string
    filterButton?: () => JSX.Element
}

export function Page({ children, className, filterButton }: PageProps): JSX.Element {
    useLayoutEffect(() => {
        DI.statusBloc().hideInfoMessage() // Clear preexisting loading messages from other pages
    }, [])

    return (
        <div className={classNames("page", className)}>
            <Header filterButton={filterButton} />
            <div className="content-wrapper">{children}</div>
            <div id="modalHolder" />
        </div>
    )
}

export function PageContent({ children }: { children?: React.ReactNode }): JSX.Element {
    return <div className="page-content">{children}</div>
}
