import { BlocCoordinator } from "@lib/bloc/BlocCoordinator"
import { Contact, ContactType, labelForContact, mapNumberToContactType } from "@model/contacts/Contact"
import { Filter, FilterValue, filterValueForWorkOrderStatus } from "@model/filters/Filter"
import { PermissionObject, PermissionType } from "@model/user/User"
import { WorkOrderFilter, WorkOrderStatus } from "@model/workOrders/WorkOrder"
import { WorkOrderFilterDefinitions } from "@model/workOrders/WorkOrderFilterDefinitions"
import { CompanyBloc, CompanyState } from "@state/company/CompanyBloc"
import { DashboardDataType, DashboardScreenBloc, DashboardScreenState } from "@state/dashboard/DashboardScreenBloc"
import { UserBloc, UserState } from "@state/user/UserBloc"
import { WorkOrdersTableBloc } from "@state/workOrders/WorkOrdersTableBloc"
type StateSelection = {
    isLoading: boolean
    mechanics: Contact[]
    workOrderStatusCounts: Record<WorkOrderStatus, number>
    selectedMechanicType: ContactType[]
    selectedMechanic: Contact[]
}

type Dependencies = [DashboardScreenState, CompanyState, UserState]

export class WorkOrderStatusWidgetViewModel extends BlocCoordinator<Dependencies, StateSelection> {
    constructor(
        private widgetBloc: DashboardScreenBloc,
        private companyBloc: CompanyBloc,
        private workOrderTableBloc: WorkOrdersTableBloc,
        private userBloc: UserBloc
    ) {
        super([widgetBloc, companyBloc, userBloc])
    }

    protected transform([widgetState, companyState]: Dependencies): StateSelection {
        return {
            selectedMechanicType: widgetState.selectedMechanicType,
            workOrderStatusCounts: widgetState.workOrderStatusWidgetData,
            isLoading: !widgetState.hasLoaded[DashboardDataType.WorkOrderStatusWidget],
            mechanics: widgetState.mechanics,
            selectedMechanic: widgetState.selectedMechanic,
        }
    }

    onMounted = () => {
        this.widgetBloc.mechanicsChanged(this.companyBloc.state.settings.mechanics)
    }

    mechanicFilterChanged(value: string) {
        this.widgetBloc.selectedMechanicFilterChanged(value, null)
    }

    mechanicTypeFilterChanged(value: number[]) {
        const updatedMechanics = this.companyBloc.state.settings.mechanics.filter((contact) => {
            return typeof contact.personTypeID === "number" && value.includes(contact.personTypeID)
        })

        const updatedMechanicTypes = mapNumberToContactType(value)

        this.widgetBloc.selectedMechanicTypeChanged(updatedMechanicTypes)
        this.widgetBloc.mechanicsChanged(updatedMechanics)
        this.widgetBloc.selectedMechanicFilterChanged(null, updatedMechanicTypes)
    }

    hasWorkOrderViewPermissions(): boolean {
        return this.userBloc.state.user.hasAccess(PermissionObject.MaintenanceWorkOrder, PermissionType.View)
    }

    private addWorkOrderFilterItemObject = (
        filters: Record<WorkOrderFilter, Filter<WorkOrderFilter>>,
        definition: WorkOrderFilter,
        values: FilterValue[]
    ): void => {
        filters[definition] = { ...filters[definition], values: values }
    }

    onWorkOrderStatusClicked(status?: WorkOrderStatus): Record<WorkOrderFilter, Filter<WorkOrderFilter>> {
        var workOrderFilters: Record<WorkOrderFilter, Filter<WorkOrderFilter>>

        if (status != null) {
            workOrderFilters = {
                ...this.workOrderTableBloc.newDefaultFilters(),
                [WorkOrderFilter.Status]: {
                    definition: WorkOrderFilterDefinitions[WorkOrderFilter.Status],
                    values: [filterValueForWorkOrderStatus(status)],
                },
            }
        } else {
            workOrderFilters = this.workOrderTableBloc.newDefaultFilters()
        }

        let filteredMechanics: FilterValue[]
        let aux =
            this.state.selectedMechanic?.length > 0
                ? this.state.selectedMechanic
                : this.state.selectedMechanicType.length > 0
                ? this.state.mechanics
                : []

        filteredMechanics = aux?.map((x) => {
            return { label: labelForContact(x), value: x.id ?? undefined }
        })

        if (filteredMechanics?.length > 0)
            this.addWorkOrderFilterItemObject(workOrderFilters, WorkOrderFilter.AssignedTo, filteredMechanics)

        return workOrderFilters
    }

    getUser = () => this.userBloc.state.user
}
