import React, { CSSProperties, useCallback, useEffect, useRef, useState } from "react"

export type RecyclerListRow<T extends {} = {}> = React.FunctionComponent<RecyclerListRowProps<T>>
export type RecyclerListRowProps<T extends {} = {}> = { style: React.CSSProperties; index: number } & T

export type RecyclerListProps<T = {}> = {
    itemHeight: number
    itemCount: number
    children: RecyclerListRow<T>
    className?: string
    overScan?: number
    data: T
}

export function RecyclerList<T extends {} = {}>({
    itemHeight,
    itemCount,
    className,
    overScan = 2,
    children,
    data,
}: RecyclerListProps<T>): JSX.Element {
    const [scroll, setScroll] = useState(0)
    const [windowHeight, setWindowHeight] = useState(0)
    const windowRef = useRef<HTMLDivElement | null>(null)

    const onScroll = useCallback((ev: React.UIEvent<HTMLDivElement>) => {
        setScroll((ev.target as HTMLElement).scrollTop)
    }, [])

    useEffect(() => {
        if (!windowRef.current) return

        const resizeObserver = new ResizeObserver((entries) => {
            if (!windowRef.current) return

            if (windowHeight !== windowRef.current.offsetHeight) {
                setWindowHeight(windowRef.current.offsetHeight)
            }
        })
        resizeObserver.observe(windowRef.current)
        setWindowHeight(windowRef.current.offsetHeight)

        return () => resizeObserver.disconnect()
    }, [])

    const listHeight = itemCount * itemHeight
    const itemsPerWindow = Math.ceil(windowHeight / itemHeight) + overScan
    const startIndex = Math.max(0, Math.floor(scroll / itemHeight) - overScan / 2)
    if (scroll > listHeight) setScroll(listHeight - windowHeight)

    const items: React.ReactNode[] = Array(itemsPerWindow)

    for (let i = 0; i < itemsPerWindow; i++) {
        const style: CSSProperties = {
            position: "absolute",
            width: "100%",
            left: "0px",
            top: "0px",
            transform: `translateY(${itemHeight * (startIndex + i)}px)`,
        }
        if (i + startIndex >= itemCount) continue
        items[i] = children({ index: startIndex + i, style, ...data })
    }

    return (
        <div
            ref={windowRef}
            className={className}
            style={{ willChange: "transform", overflow: "auto" }}
            onScroll={onScroll}
        >
            <div style={{ height: `${listHeight}px`, position: "relative" }}>{items}</div>
        </div>
    )
}
