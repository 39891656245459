import {
    labelForWorkRequestStatus,
    WorkRequest,
    WorkRequestStatus,
    workRequestStatusForValue,
} from "@model/workRequests/WorkRequest"
import { WorkRequestStatusFlag } from "@model/workRequests/WorkRequestStatusFlag"
import { WorkRequestStatusIndicator } from "@ui/workRequests/WorkRequestStatusIndicator"
import React from "react"
import { RoundedSelect } from "./RoundedSelect"

export type WorkRequestStatusSelectProps = {
    status: WorkRequestStatus
    onChange: (status: WorkRequestStatus) => void
    disabled?: boolean
    flags?: WorkRequestStatusFlag[]
}

export function WorkRequestStatusSelect({
    status,
    onChange,
    disabled,
    flags,
}: WorkRequestStatusSelectProps): JSX.Element {
    return (
        <RoundedSelect
            className="select--work-request-status"
            preValueContent={<WorkRequestStatusIndicator status={status} flagged={flags && flags.length > 0} />}
            options={Object.values(WorkRequestStatus).map((it) => ({
                value: it,
                label: labelForWorkRequestStatus(it),
            }))}
            onChange={(value) => onChange(workRequestStatusForValue(value))}
            value={status}
            isEnabled={!disabled ?? true}
        />
    )
}
