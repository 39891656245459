import { SortOrder } from "@lib/Comparable"
import { classNames, isNodeAncestor } from "@lib/HtmlUtil"
import { Filter, isEmptyFilterValue } from "@model/filters/Filter"
import { Pagination } from "@model/Pagination"
import {
    SortableTableHeadColumn,
    TableColumn,
    TableColumnAlignment,
    TableColumnSize,
    TableRow,
} from "@model/tables/Table"
import React, { useEffect, useRef, useState } from "react"
import { Button, ButtonTheme, ButtonType } from "@ui/common/buttons/Button"
import { ChipCont } from "@ui/common/Chip"
import { ArrowIcon, ManageColumnsIcon, BanIcon, SaveIcon } from "@ui/common/images/InlinedSvgs"
import { LoadingIndicator } from "@ui/common/LoadingIndicator"
import { FilterChip } from "./FilterChip"
import { useIsInIframe, useIsMobile } from "@lib/Hooks"

export type SortableTableProps<F extends string> = {
    className?: string
    isLoading?: boolean
    head: SortableTableHeadColumn[]
    data: TableRow[]
    actionBar?: SortableTableActionsBarProps<F>
    emptyMessage?: string
    onColumnSortClicked?: (columnIndex: number) => void
}

export function SortableTable<F extends string>({
    emptyMessage = "No Data",
    isLoading = false,
    ...props
}: SortableTableProps<F>): JSX.Element {
    const columnSizes = props.head.reduce((accum, value) => {
        switch (value.size ?? TableColumnSize.Normal) {
            case TableColumnSize.MinContent:
                return `${accum} min-content`
            case TableColumnSize.FitContent:
                return `${accum} auto`
            case TableColumnSize.FitContentGrow:
                return `${accum} minmax(auto, 1fr)`
            case TableColumnSize.Normal:
                return `${accum} minmax(150px, 1fr)`
            case TableColumnSize.Medium:
                return `${accum} minmax(225px, 1.5fr)`
            case TableColumnSize.Large:
                return `${accum} minmax(300px, 2fr)`
            default:
                return accum
        }
    }, "")

    return (
        <div className={classNames("table", "table--sortable", props.className)}>
            {props.actionBar && <SortableTableActionsBar {...props.actionBar} />}
            <div className="table-data-cont">
                <LoadingIndicator isLoading={isLoading} />
                {!isLoading && props.data.length === 0 && (
                    <div className={"table-empty-view"}>
                        <div className="table-empty-view-message">{emptyMessage}</div>
                    </div>
                )}
                <table
                    className="table-data"
                    style={{
                        gridTemplateColumns: `${columnSizes}`,
                    }}
                >
                    <thead>
                        <tr>
                            {props.head.map((column, i) => (
                                <HeadColumn
                                    column={column}
                                    columnCount={props.head.length}
                                    currentColumnIndex={i}
                                    onClick={() => props?.onColumnSortClicked?.(i)}
                                    key={`head_${i}`}
                                />
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.flatMap((row) => (
                            <tr data-id={row.entryId} key={row.entryId} onClick={row.onClick}>
                                {row.columns.map((column, i) => (
                                    <BodyColumn
                                        key={`${row.entryId}_${i}`}
                                        column={column}
                                        currentColumnIndex={i % props.head.length}
                                        columnCount={props.head.length}
                                    />
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

type SortableTableActionsBarProps<F extends string> = {
    filters?: Filter<F>[]
    filterLabelOverrides?: Partial<Record<F, string>>
    filterValueOverrides?: Partial<Record<F, string>>
    onFilterRemoveClicked?: (filter: Filter<F>) => void
    onExportClicked?: () => void
    onResetFiltersClicked?: () => void
    onManageColumnsClicked?: () => void
} & SortableTablePaginationProps

function SortableTableActionsBar<F extends string>(props: SortableTableActionsBarProps<F>): JSX.Element {
    const isMobile = useIsMobile() || useIsInIframe()
    return (
        <div className="sortable-table-filters-actions-cont">
            <ChipCont className="sortable-table-filters-cont">
                {(props.filters ?? [])
                    .filter((filter) => !isEmptyFilterValue(filter))
                    .map((filter) => (
                        <FilterChip
                            {...((props.filterLabelOverrides?.[filter.definition.type] ?? undefined) !== undefined
                                ? { filterLabelOverride: props.filterLabelOverrides?.[filter.definition.type] }
                                : null)}
                            {...((props.filterValueOverrides?.[filter.definition.type] ?? undefined) !== undefined
                                ? { filterValueOverride: props.filterValueOverrides?.[filter.definition.type] }
                                : null)}
                            filter={filter}
                            key={filter.definition.type}
                            onRemoveClicked={() => props.onFilterRemoveClicked?.(filter)}
                        />
                    ))}
            </ChipCont>
            <div className="sortable-table-actions-cont">
                {props.onResetFiltersClicked && (
                    <Button
                        type={ButtonType.Icon}
                        theme={ButtonTheme.Light}
                        onClick={props.onResetFiltersClicked}
                        tooltip="Reset Filters"
                    >
                        <BanIcon />
                        Reset Filters
                    </Button>
                )}
                {props.onExportClicked && !isMobile && (
                    <Button type={ButtonType.Icon} theme={ButtonTheme.Light} onClick={props.onExportClicked} tooltip="Export list to Excel">
                        <SaveIcon />
                        Export
                    </Button>
                )}
                {props.onManageColumnsClicked && !isMobile && (
                    <Button type={ButtonType.Icon} theme={ButtonTheme.Light} onClick={props.onManageColumnsClicked} tooltip="Select Displayed Columns">
                        <ManageColumnsIcon />
                        Manage Columns
                    </Button>
                )}
                {props.pagination && (
                    <SortableTablePagination
                        pagination={props.pagination}
                        onNextPageClicked={props.onNextPageClicked}
                        onPreviousPageClicked={props.onPreviousPageClicked}
                        onPageClicked={props.onPageClicked}
                        onViewAllClicked={props.onViewAllClicked}
                        onResetViewAllClicked={props.onResetViewAllClicked}
                    />
                )}
            </div>
        </div>
    )
}

type SortableTablePaginationProps = {
    pagination: Pagination
    onViewAllClicked?: () => void
    onPageClicked?: (page: number) => void
    onPreviousPageClicked?: () => void
    onNextPageClicked?: () => void
    onResetViewAllClicked?: () => void
}

function SortableTablePagination(props: SortableTablePaginationProps): JSX.Element {
    const [showPageSelector, setShowPageSelector] = useState(false)
    const paginationCountRef = useRef<HTMLDivElement>(null)
    const rowsPerPage = props.pagination.rowsPerPage === -1 ? props.pagination.totalRows : props.pagination.rowsPerPage

    const previousDisabled = props.pagination.currentPage === 0
    const nextDisabled = props.pagination.currentPage === props.pagination.pageCount - 1

    useEffect(() => {
        const handleClickListener = (ev: MouseEvent) => {
            if (isNodeAncestor(ev.target as HTMLElement | null, paginationCountRef.current)) return
            setShowPageSelector(false)
        }

        if (showPageSelector) window.addEventListener("click", handleClickListener)
        return () => window.removeEventListener("click", handleClickListener)
    }, [showPageSelector])

    return (
        <div className="sortable-table-pagination-cont">
            <Button
                type={ButtonType.Icon}
                theme={ButtonTheme.Light}
                isEnabled={!previousDisabled}
                onClick={previousDisabled ? undefined : props.onPreviousPageClicked}
            >
                <ArrowIcon />
                Previous
            </Button>
            <div
                ref={paginationCountRef}
                className={classNames(
                    "sortable-table-pagination-count-cont",
                    showPageSelector ? "pagination--show-page-selector" : ""
                )}
            >
                <button
                    className="sortable-table-button sortable-table-pagination-count-button"
                    onClick={() => setShowPageSelector(!showPageSelector)}
                    title="Select Page"
                >
                    <div className="sortable-table-pagination-count--current">
                        {props.pagination.totalRows === 0 ? 0 : props.pagination.currentPage * rowsPerPage + 1}-
                        {Math.min(props.pagination.currentPage * rowsPerPage + rowsPerPage, props.pagination.totalRows)}
                    </div>
                    <div className="sortable-table-pagination-count--total">of {props.pagination.totalRows}</div>
                </button>
                <div className="pagination-page-selector">
                    {props.pagination.rowsPerPage !== -1 &&
                        Array(props.pagination.pageCount)
                            .fill(null)
                            .map((_, page) => (
                                <button
                                    key={`page-${page}`}
                                    className={classNames(
                                        "sortable-table-button sortable-table-button--page-selector",
                                        props.pagination.currentPage === page ? "sortable-table-button--disabled" : null
                                    )}
                                    onClick={() => {
                                        setShowPageSelector(false)
                                        props.onPageClicked?.(page)
                                    }}
                                    {...(props.pagination.currentPage === page ? { disabled: true } : {})}
                                >
                                    {page + 1}
                                </button>
                            ))}
                    {props.pagination.rowsPerPage !== 10 && (
                        <button
                            className="sortable-table-button sortable-table-button--page-selector sortable-table-button--view-all"
                            onClick={() => {
                                setShowPageSelector(false)
                                props.onResetViewAllClicked?.()
                            }}
                        >
                            Reset
                        </button>
                    )}
                    {props.pagination.rowsPerPage < props.pagination.totalRows && (
                        <button
                            className="sortable-table-button sortable-table-button--page-selector sortable-table-button--view-all"
                            onClick={() => {
                                setShowPageSelector(false)
                                props.onViewAllClicked?.()
                            }}
                        >
                            View All
                        </button>
                    )}
                </div>
            </div>
            <Button
                type={ButtonType.Icon}
                theme={ButtonTheme.Light}
                className="sortable-table-pagination-next-button"
                isEnabled={!nextDisabled}
                onClick={nextDisabled ? undefined : props.onNextPageClicked}
            >
                <ArrowIcon />
                Next
            </Button>
        </div>
    )
}

type SortableTableHeadColumnProps = {
    column: SortableTableHeadColumn
    columnCount: number
    currentColumnIndex: number
    onClick?: (column: SortableTableHeadColumn) => void
}

function HeadColumn(props: SortableTableHeadColumnProps): JSX.Element {
    return (
        <th
            className={classNames("table-cell", "table-cell--head", props.column.className)}
            data-alignment={props.column.alignment ?? TableColumnAlignment.Start}
            data-size={props.column.size ?? TableColumnSize.Normal}
            {...(props.currentColumnIndex === props.columnCount - 1 ? { "data-row-end": true } : {})}
            {...(!props.column.isSortable ? undefined : { ["data-sortable"]: true })}
        >
            {props.column.isSortable ? (
                <button
                    className="table-cell__content table-cell--head__content"
                    onClick={() => props.onClick?.(props.column)}
                >
                    <div className="sortable-table-sort-cont">
                        {props.column.content}
                        {props.column.isSortable && (
                            <div className="table-sort-icon" data-order={props.column.sortOrder}>
                                Sort
                                {props.column.sortOrder == SortOrder.Asc
                                    ? "Ascending"
                                    : props.column.sortOrder == SortOrder.Desc
                                    ? "Descending"
                                    : ""}
                            </div>
                        )}
                    </div>
                </button>
            ) : (
                <div className="table-cell__content table-cell--head__content">
                    <div className="sortable-table-sort-cont">{props.column.content}</div>
                </div>
            )}
        </th>
    )
}

type SortableTableColumnProps = {
    column: TableColumn
    columnCount: number
    currentColumnIndex: number
}

function BodyColumn(props: SortableTableColumnProps): JSX.Element {
    return (
        <td
            className={classNames("table-cell", "table-cell--body", props.column.className)}
            data-alignment={props.column.alignment}
            data-size={props.column.size}
            {...(props.currentColumnIndex === props.columnCount - 1 ? { "data-row-end": true } : {})}
        >
            <div className="table-cell__content table-cell--body__content">{props.column.content}</div>
        </td>
    )
}
