import { Ok, Outcome } from "@ethossoftworks/outcome"
import { BuildInfo } from "@lib/BuildInfo"
import { apiHost, tokenEndpoint } from "@lib/Routes"
import { nullStringOrDefault, numOrDefault, safeParseDate, stringOrDefault } from "@lib/TypeUtil"
import { Pagination } from "@model/Pagination"
import { Tag, TagType } from "@model/Tag"
import { ThresholdType, ThresholdUnit } from "@model/Threshold"
import { Urgency } from "@model/Urgency"
export enum HttpMethod {
    Get = "GET",
    Post = "POST",
    Put = "PUT",
    Patch = "PATCH",
    Delete = "DELETE",
}

export enum ApiError {
    Unauthenticated = "unauthenticated",
    Unknown = "unknown",
}

export type ApiPagedResponse = {
    totalPages: 1
    pageSize: 100
    pageNumber: 0
    totalResults: 1
    results: any[]
    summary: any
}

export class ApiService {
    private token: string | null = null

    async generateAccessToken(): Promise<Outcome<string, ApiError>> {
        if (location.hostname === "localhost") {
            this.token = localStorage.getItem("accessToken") ?? ""
            return Outcome.ok(this.token)
        }

        try {
            const response = await fetch(tokenEndpoint, {
                method: HttpMethod.Get,
                credentials: "include",
            })

            if (response.status === 401) return Outcome.error(ApiError.Unauthenticated)
            if (!response.ok) return Outcome.error(ApiError.Unknown)

            const responseBody = JSON.parse(await response.text())
            this.token = responseBody.token ?? null
            if (!this.token) return Outcome.error(ApiError.Unknown)

            return Outcome.ok(this.token)
        } catch (e) {
            return Outcome.error(ApiError.Unknown)
        }
    }

    async makeRequest<T = object>({
        method,
        path,
        query,
        body,
    }: {
        method: HttpMethod
        path: string
        query?: Record<string, string | number | (string | number)[]>
        body?: object
    }): Promise<Outcome<T | undefined>> {
        // TODO: Implement refreshing of token
        // const response = await this.refreshTokenIfInvalid()
        // if (response.isError()) return response

        try {
            const response = await this.fetchData({
                method,
                path,
                query,
                body,
            })

            if (!response.ok) return Outcome.error(response)
            if (method === HttpMethod.Delete) {
                return Outcome.ok(undefined)
            } else {
                const string = await response.text()
                const json = string === "" ? {} : JSON.parse(string)
                return Outcome.ok<T>(json)
            }
        } catch (e) {
            return Outcome.error(e)
        }
    }

    async makeDownloadRequest({
        method,
        path,
        query,
        body,
    }: {
        method: HttpMethod
        path: string
        query?: Record<string, string | number | (string | number)[]>
        body?: object
    }): Promise<Outcome<undefined | Blob>> {
        // TODO: Implement refreshing of token
        // const response = await this.refreshTokenIfInvalid()
        // if (response.isError()) return response

        try {
            if (method !== HttpMethod.Post && method !== HttpMethod.Get) {
                return Outcome.ok(undefined)
            }

            const response = await this.fetchData({
                method,
                path,
                query,
                body,
            })

            if (!response.ok) return Outcome.error(response)
            const blob = await response.blob()
            return Outcome.ok(blob)
        } catch (e) {
            return Outcome.error(e)
        }
    }

    private async fetchData({
        method,
        path,
        query,
        body,
    }: {
        method: HttpMethod
        path: string
        query?: Record<string, string | number | (string | number)[]>
        body?: object
    }): Promise<Response> {
        const url = new URL(`${apiHost}${path}`)
        Object.entries(query ?? {}).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach((it) => url.searchParams.append(key, it.toString()))
            } else {
                url.searchParams.append(key, value.toString())
            }
        })

        let requestInitData = {}
        let requestInitDataDefaultHeaders = {
            "Ocp-Apim-Subscription-Key": BuildInfo.subscriptionKey,
            "Ocp-Apim-Trace": "true",
            Authorization: `Bearer ${this.token ?? ""}`,
        }

        if (body instanceof FormData && method === HttpMethod.Post) {
            //When uploading a file the Content-Type must not be defined in order to work. Setting it to multipart/form-data doesn't work.
            requestInitData = {
                headers: requestInitDataDefaultHeaders,
                body: body,
            }
        } else {
            requestInitData = {
                headers: {
                    "Content-Type": method === HttpMethod.Patch ? "application/merge-patch+json" : "application/json",
                    ...requestInitDataDefaultHeaders,
                },
                ...(body ? { body: JSON.stringify(body) } : {}),
            }
        }

        const response = await fetch(url.toString(), {
            method: method,
            ...requestInitData,
        })

        return response
    }

    private async refreshAccessTokenIfInvalid(): Promise<Outcome<undefined, ApiError>> {
        if (this.token === null) {
            const outcome = await this.generateAccessToken()
            if (outcome.isError()) return outcome
        }
        return Outcome.ok(undefined)
    }

    static isValidArrayResponse(response: Outcome<undefined | object>): response is Ok<any[]> {
        if (response.isError()) return false
        if (response.value === undefined || response.value === null) return false
        if (!Array.isArray(response.value)) return false
        return true
    }

    static isValidObjectResponse<T>(response: Outcome<undefined | T>): response is Ok<T> {
        if (response.isError()) return false
        if (response.value === undefined || response.value === null) return false
        if (typeof response.value !== "object") return false
        return true
    }

    static isValidPagedResponse(response: Outcome<undefined | object>): response is Ok<ApiPagedResponse> {
        if (response.isError()) return false
        if (response.value === undefined || response.value === null) return false
        if (typeof response.value !== "object") return false
        if (!(response.value as any).totalPages === undefined) return false
        if (!(response.value as any).pageSize === undefined) return false
        if ((response.value as any).pageNumber === undefined) return false
        if (!(response.value as any).totalResults === undefined) return false
        if (!(response.value as any).results === undefined) return false
        if (!Array.isArray((response.value as any).results)) return false
        return true
    }
}

export function downloadBlob(blob: Blob, fileName: string): void {
    const file = window.URL.createObjectURL(blob)
    /*window.location.assign(file);*/ //This generates a guid as the filename.
    var link = document.createElement("a")
    link.setAttribute("href", file)
    link.setAttribute("download", fileName)
    link.click()
}

export function paginationFromApi(obj: any): Pagination {
    return {
        currentPage: numOrDefault(obj.pageNumber, 0),
        pageCount: numOrDefault(obj.totalPages, 0),
        rowsPerPage: numOrDefault(obj.pageSize, 0),
        totalRows: numOrDefault(obj.totalResults, 0),
    }
}

export function sortToApi(sort?: any): Record<string, any> {
    var columns = sort?.column.split(",")
    let sortVar = ""

    columns.map((it: any, i: any) => {
        sortVar += `${it}:${sort?.order}`
        sortVar += i < columns.length - 1 ? "," : ""
    })

    return { sort: sortVar }
}

export function paginationToApi(obj?: Pagination): { [key: string]: number } {
    return {
        pageSize: obj?.rowsPerPage ?? 50,
        page: obj?.currentPage ?? 0,
    }
}

export function nullableDateFromApi(obj: string | null | undefined): Date | null {
    if (obj === null || obj === undefined || obj === "") return null
    return dateFromApi(obj)
}

export function dateFromApi(obj: string | null): Date {
    if (obj && (obj.indexOf("Z") > 0 || obj.indexOf("+") > 0 || obj.indexOf("-") > 10)) return safeParseDate(`${obj}`)

    return safeParseDate(`${obj}Z`)
}

export function tagFromApi(obj: any): Tag | null {
    if (obj === null || obj === undefined) return null
    const status = nullStringOrDefault(obj.type, null)
    if (status === null || status === "None" || !["YellowTag", "RedTag"].includes(status)) return null

    const date = dateFromApi(obj.dateTagged)
    date.setHours(0, 0, 0, 0)
    return {
        type: status === "RedTag" ? TagType.Red : TagType.Yellow,
        dateTagged: date,
        daysTagged: Math.floor((new Date().getTime() - date.getTime()) / (1000 * 60 * 60 * 24)),
        reason: stringOrDefault(obj.reasonTagged, ""),
    }
}

export function notifiedContactsToApi(notifyContacts: number[]): string {
    return JSON.stringify({ UserId: notifyContacts })
}

export function notifiedContactsFromApi(obj: any): number[] {
    if (obj) {
        var notifiedContacts = JSON.parse(obj)
        if (notifiedContacts && notifiedContacts.hasOwnProperty("UserId")) {
            return notifiedContacts.UserId
        }
    }

    return []
}

export function tagTypeToApi(type: TagType | null): string | null {
    if (type === TagType.None) return "None"

    if (type === null) return null
    return type === TagType.Red ? "RedTag" : "YellowTag"
}

export function tagTypeFromApi(value: string | null): TagType | null {
    if (value === null) return null
    return value === "RedTag" ? TagType.Red : TagType.Yellow
}

export function thresholdUnitToApi(type: ThresholdType, unit: ThresholdUnit): string {
    if (unit === ThresholdUnit.Percent) return "%"

    switch (type) {
        case ThresholdType.Days:
            return "days"
        case ThresholdType.Hours:
            return "hr"
        case ThresholdType.Miles:
            return "mi"
    }
}

function thresholdUnitFromApi(value: string): ThresholdUnit {
    return value === "%" ? ThresholdUnit.Percent : ThresholdUnit.Unit
}

export function UrgencyFromApi(value: any): Urgency {
    switch (stringOrDefault(value, "")) {
        case "Immediate":
            return Urgency.Immediate
        case "High":
            return Urgency.High
        case "Medium":
            return Urgency.Medium
        case "Low":
            return Urgency.Low
        default:
            return Urgency.Medium
    }
}
