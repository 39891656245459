import { Bloc } from "@lib/bloc/Bloc"
import { arrayToObject } from "@lib/TypeUtil"
import { AsyncStatus } from "@model/AsyncStatus"
import { WorkOrderQuickFilterCount } from "@model/workOrders/WorkOrder"
import { WorkOrderService } from "@service/workOrders/WorkOrderService"

export type WorkOrdersQuickFiltersState = {
    effectStatus: Record<WorkOrdersQuickFiltersEffect, AsyncStatus>
    counts: Record<WorkOrderQuickFilterCount, number>
    isMobileFilterModalOpen: boolean
}

export enum WorkOrdersQuickFiltersEffect {
    Fetch = "fetch",
}

export class WorkOrdersQuickFiltersBloc extends Bloc<WorkOrdersQuickFiltersState> {
    constructor(private workOrdersService: WorkOrderService) {
        super(newWorkOrdersQuickFiltersState(), { persistStateOnDispose: true })
    }

    private updateEffectStatus = (effect: WorkOrdersQuickFiltersEffect, status: AsyncStatus) =>
        this.update({
            effectStatus: {
                ...this.state.effectStatus,
                [effect]: status,
            },
        })

    showMobileFilterModal(isOpen: boolean) {
        this.update({ isMobileFilterModalOpen: isOpen })
    }
}

function newWorkOrdersQuickFiltersState(): WorkOrdersQuickFiltersState {
    return {
        effectStatus: arrayToObject(Object.values(WorkOrdersQuickFiltersEffect), (value) => [
            value,
            AsyncStatus.idle(),
        ]),
        counts: arrayToObject(Object.values(WorkOrderQuickFilterCount), (it) => [it, 0]),
        isMobileFilterModalOpen: false,
    }
}
