export enum ImageUrls {
    Add = "/images/add.svg",
    AddBlue = "/images/add-blue.svg",
    AddDark = "/images/add-dark.svg",
    ArrowRight = "/images/arrow-right.svg",
    CheckMark = "/images/checkmark.svg",
    Close = "/images/close.svg",
    Gear = "/images/gear.svg",
    Glasses = "/images/glasses.svg",
    GlassesDark = "/images/glasses-dark.svg",
    Logo = "/images/logo.svg",
    LogoText = "/images/logo-text.svg",
    More = "/images/more.svg",
    Pencil = "/images/pencil.svg",
    Print = "/images/print.svg",
    Trash = "/images/trash-light.svg",
    Wrench = "/images/wrench.svg",
    WrenchSolid = "/images/wrench-solid.svg",
    Note = "/images/note.svg",
    Excel = "/images/excel.png",
    Word = "/images/word.png",
    Pdf = "/images/pdf.png",
    Rotate = "/images/rotate-solid.svg",
    Info = "/images/circle-info-solid.svg"
}
