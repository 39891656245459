import { classNames } from "@lib/HtmlUtil"
import { Tag, TagType } from "@model/Tag"
import React from "react"

export type TagProps = {
    tag: Tag
    showDayCount?: boolean
    theme?: TagTheme
}

export enum TagTheme {
    Light = "light",
    Dark = "dark",
}

export function Tag({ tag, showDayCount = true, theme = TagTheme.Dark }: TagProps): JSX.Element {
    return (
        <div className={classNames("tag", tag.type === TagType.Red ? "tag--red" : "tag--yellow")} data-theme={theme}>
            {showDayCount ? `${tag.daysTagged} d` : null}
        </div>
    )
}
