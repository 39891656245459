import { classNames } from "@lib/HtmlUtil"
import { Strings } from "@lib/Strings"
import { safeParseFloat, safeParseInt } from "@lib/TypeUtil"
import { SearchIcon } from "@ui/common/images/InlinedSvgs"
import React, { InputHTMLAttributes } from "react"

export enum TextInputTheme {
    Light = "light",
    Normal = "normal",
}

export type TextInputProps = {
    focusRef?: React.RefObject<HTMLInputElement>
    isEnabled?: boolean
    type?: TextInputType
    value?: string
    onChange?: (value: string) => void
    onBlur?: (ev: FocusEvent) => void
    invalidCharacterPattern?: RegExp
    classNames?: string
    theme?: TextInputTheme
    outlineInvalid?: boolean
} & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange" | "type" | "value" | "disabled">

export enum TextInputType {
    Text = "text",
    Password = "password",
    Search = "search",
    Integer = "integer",
    Float = "float",
    Money = "money",
    File = "file",
    Decimal = "decimal",
}

export function TextInput({
    focusRef,
    onChange,
    isEnabled = true,
    type = TextInputType.Text,
    theme = TextInputTheme.Normal,
    value,
    invalidCharacterPattern,
    className,
    onBlur,
    outlineInvalid = false,
    ...rest
}: TextInputProps): JSX.Element {
    const replaceInvalidCharacters = (el: HTMLInputElement) => {
        if (type === TextInputType.Money) {
            el.value = el.value.replace(/[^0-9\.,]/g, "")
        } else if (type === TextInputType.Integer) {
            el.value = el.value.replace(invalidCharacterPattern ?? /[^0-9\,\-]/g, "")
        } else if (type === TextInputType.Float) {
            el.value = el.value.replace(invalidCharacterPattern ?? /[^0-9\,\.\-]/g, "")
        } else {
            if (!invalidCharacterPattern) return
            el.value = el.value.replace(invalidCharacterPattern, "")
        }
    }

    const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        replaceInvalidCharacters(ev.currentTarget)
        onChange?.(ev.currentTarget.value)
    }

    const handleInput = (ev: React.ChangeEvent<HTMLInputElement>) => replaceInvalidCharacters(ev.currentTarget)

    const handleBlur = (ev: React.FocusEvent<HTMLInputElement>) => {
        if (type === TextInputType.Money) {
            if (ev.currentTarget.value === "") return
            onChange?.(Strings.formatMoney(ev.currentTarget.value))
        } else if (type === TextInputType.Integer) {
            if (ev.currentTarget.value === "") return

            if (rest.min !== undefined && safeParseInt(ev.currentTarget.value) < safeParseInt(rest.min + ""))
                ev.currentTarget.value = rest.min + ""
            if (rest.max && safeParseInt(ev.currentTarget.value) > safeParseInt(rest.max + ""))
                ev.currentTarget.value = rest.max + ""

            onChange?.(Strings.formatInteger(ev.currentTarget.value))
        } else if (type === TextInputType.Float) {
            if (ev.currentTarget.value === "") return

            if (rest.min !== undefined && safeParseFloat(ev.currentTarget.value) < safeParseFloat(rest.min + ""))
                ev.currentTarget.value = rest.min + ""
            if (rest.max && safeParseFloat(ev.currentTarget.value) > safeParseFloat(rest.max + ""))
                ev.currentTarget.value = rest.max + ""

            onChange?.(Strings.formatFloat(ev.currentTarget.value))
        } else if (type === TextInputType.Decimal) {
            if (ev.currentTarget.value === "") return

            if (rest.min !== undefined && safeParseFloat(ev.currentTarget.value) < safeParseFloat(rest.min + ""))
                ev.currentTarget.value = rest.min + ""
            if (rest.max && safeParseFloat(ev.currentTarget.value) > safeParseFloat(rest.max + ""))
                ev.currentTarget.value = rest.max + ""

            onChange?.(Strings.formatDecimal(ev.currentTarget.value))
        } else {
            replaceInvalidCharacters(ev.currentTarget)
        }

        onBlur?.(ev)
    }

    const attrs = {
        ...rest,
        disabled: !isEnabled ? true : undefined,
        onChange: isEnabled ? handleChange : undefined,
        onInput: isEnabled ? handleInput : undefined,
        ...(value !== undefined ? { value } : {}),
    }

    const inputMode = (() => {
        if (type === TextInputType.Integer) {
            return { inputMode: "numeric" } as { inputMode: "numeric" }
        } else if (type === TextInputType.Search || type === TextInputType.Text) {
            return { inputMode: "text" } as { inputMode: "text" }
        } else if (type === TextInputType.Float || type === TextInputType.Money || TextInputType.Decimal) {
            return { inputMode: "decimal" } as { inputMode: "decimal" }
        } else {
            return {}
        }
    })()

    return (
        <div
            className={classNames("text-input", !isEnabled ? "text-input--disabled" : null, className)}
            data-type={type}
            data-theme={theme}
        >
            <input
                ref={focusRef}
                className={"text-input-field" + (outlineInvalid ? " outlineInvalid" : "")}
                type={
                    type === TextInputType.Money || type === TextInputType.Integer || TextInputType.Search
                        ? TextInputType.Text
                        : type || TextInputType.Decimal
                        ? TextInputType.Text
                        : type
                }
                onBlur={handleBlur}
                {...inputMode}
                {...attrs}
            />
            {type === TextInputType.Search && <SearchIcon />}
        </div>
    )
}
