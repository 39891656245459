import { GlobalKeyListener } from "@lib/GlobalKeyListener"
import { classNames } from "@lib/HtmlUtil"
import React, { MouseEvent, useEffect } from "react"
import { CSSTransition } from "react-transition-group"

export type FilterOverlayProps = {
    isOverlayVisible: boolean
    className: string
    hasSearch: boolean
    children: React.ReactNode
    onCancel?: () => void
}

export function FilterOverlay({ isOverlayVisible, className, hasSearch, ...props }: FilterOverlayProps): JSX.Element {
    const handleOverlayClick = (ev: MouseEvent) => {
        if (!(ev.target as HTMLElement).classList.contains("filter-overlay")) return
        props.onCancel?.()
    }

    useEffect(() => {
        if (!isOverlayVisible) return

        const handleEscape = (ev: KeyboardEvent) => {
            if (ev.key !== "Escape") return false
            props.onCancel?.()
            return true
        }

        GlobalKeyListener.enqueue(handleEscape)
        return () => GlobalKeyListener.dequeue(handleEscape)
    }, [isOverlayVisible])

    return (
        <CSSTransition
            classNames="filter-overlay-"
            timeout={300}
            unmountOnExit={true}
            appear={true}
            in={isOverlayVisible}
            onClick={handleOverlayClick}
        >
            <div
                className={classNames("filter-overlay", isOverlayVisible ? "filter-overlay--active" : null, className)}
                onClick={handleOverlayClick}
            >
                <div className="filter-overlay__content">{props.children}</div>
            </div>
        </CSSTransition>
    )
}

export type FilterOverlayGroupProps = {
    label: string
    children?: React.ReactNode
    columns: number // Total number of columns must be 5
    className?: string
}

export function FilterOverlayGroup({ children, label, columns, className }: FilterOverlayGroupProps): JSX.Element {
    return (
        <div className={classNames("filter-overlay-group", className)} data-columns={columns}>
            <div className="filter-overlay-group-label">{label}</div>
            <div className="filter-overlay-group-column-cont" data-columns={columns}>
                {children}
            </div>
        </div>
    )
}

export type FilterOverlayGroupColumnProps = {
    children: React.ReactNode
    noTopLabelOnFirstChild?: boolean
}

export function FilterOverlayGroupColumn({
    children,
    noTopLabelOnFirstChild,
}: FilterOverlayGroupColumnProps): JSX.Element {
    return (
        <div
            className={classNames(
                "filter-overlay-group-column",
                noTopLabelOnFirstChild ? "filter-overlay-group-column--no-top-label" : null
            )}
        >
            {children}
        </div>
    )
}
