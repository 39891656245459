import { useBloc } from "@lib/bloc/hooks"
import { Button, ButtonType } from "@ui/common/buttons/Button"
import { DI } from "@ui/DI"
import React from "react"
import { CSSTransition, TransitionGroup } from "react-transition-group"

export function StatusIndicators(): JSX.Element {
    const [state, bloc] = useBloc(() => DI.statusBloc())

    return (
        <TransitionGroup className="snackbar-cont">
            {state.errorMessages.length > 0 && (
                <CSSTransition classNames="snackbar" timeout={300} appear={true} key={state.errorMessages[0]}>
                    <div className="snackbar">
                        <span dangerouslySetInnerHTML={{ __html: state.errorMessages[0] }} />
                        <div className="snackbar-button-cont">
                            <Button type={ButtonType.Text} onClick={() => bloc.closeErrorMessage()} label="Close" />
                        </div>
                    </div>
                </CSSTransition>
            )}
            {state.infoMessages.length > 0 && (
                <CSSTransition classNames="snackbar" timeout={300} appear={true} key={"info-snackbar"}>
                    <div className="snackbar snackbar--loader" dangerouslySetInnerHTML={{ __html: state.infoMessages[0].message }} />
                </CSSTransition>
            )}
        </TransitionGroup>
    )
}
