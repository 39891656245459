import { Outcome } from "@ethossoftworks/outcome"
import { Bloc } from "@lib/bloc/Bloc"
import { diffList } from "@lib/ListUtil"
import { arrayToObject } from "@lib/TypeUtil"
import { AssetCategory } from "@model/assets/AssetCategory"
import { AssetClass } from "@model/assets/AssetClass"
import { AssetMake } from "@model/assets/AssetMake"
import { AssetModel } from "@model/assets/AssetModel"
import { AssetType } from "@model/assets/AssetType"
import { AsyncStatus } from "@model/AsyncStatus"
import { BillingCode } from "@model/company/BillingCode"
import { Company } from "@model/company/Company"
import { CompanyAlias, defaultCompanyAlias } from "@model/company/CompanyAlias"
import { CompanySettings, newCompanySettings } from "@model/company/CompanySettings"
import { Contact } from "@model/contacts/Contact"
import { District } from "@model/district/District"
import { Group } from "@model/group/Group"
import { ServiceQuoteVendor } from "@model/serviceQuotes/ServiceQuote"
import { ServiceCode, ServiceRequestType, ServiceSchedule } from "@model/serviceRequests/ServiceRequest"
import { Site } from "@model/site/Site"
import { CompanyUrgency, Urgency } from "@model/Urgency"
import { CBACode } from "@model/workOrders/CbaCode"
import { WorkOrderStatus } from "@model/workOrders/WorkOrder"
import { WorkRequestType } from "@model/workRequests/WorkRequest"
import { CompanyFieldIdMapper, CompanyService } from "@service/company/CompanyService"

export type CompanyState = {
    readonly companyId: number
    readonly companyName: string
    readonly companyLogoUrl: string | null
    readonly effectStatus: Record<CompanyEffect, AsyncStatus>
    readonly subCompanies: Company[]
    readonly assetMakes: AssetMake[]
    readonly assetModels: AssetModel[]
    readonly assetTypes: AssetType[]
    readonly assetCategories: AssetCategory[]
    readonly assetClasses: AssetClass[]
    readonly districts: District[]
    readonly subDistricts: District[]
    readonly units: District[]
    readonly groups: Group[]
    readonly contacts: Contact[]
    readonly cbaCodes: CBACode[]
    readonly sites: Site[]
    readonly allSites: Site[]
    readonly aliases: Record<CompanyAlias, string>
    readonly settings: CompanySettings
    readonly serviceCodes: Record<number, ServiceCode>
    readonly serviceSchedules: Record<number, ServiceSchedule>
    readonly billingCodes: Record<number, BillingCode>
    readonly urgencies: CompanyUrgency[]
    readonly vendors: ServiceQuoteVendor[]
}

export enum CompanyEffect {
    FetchGlobalSettings = "fetchGlobalSettings",
}

export class CompanyBloc extends Bloc<CompanyState> {
    constructor(private companyService: CompanyService) {
        super(newCompanyState(), { persistStateOnDispose: true })
    }

    override computed = (state: CompanyState): Partial<CompanyState> => ({
        settings: {
            ...state.settings,
            mechanics: state.contacts.filter((it) => it.isMechanic),
        },
    })

    createIdMapper(): CompanyFieldIdMapper {
        return {
            fromParentUrgency: (urgency: Urgency) => {
                return (
                    this.state.urgencies.find((it) => it.companyId === this.state.companyId && it.type === urgency)
                        ?.id ?? -1
                )
            },
            fromUrgency: (urgency: Urgency) => {
                return this.state.urgencies.filter((it) => it.type === urgency).map((it) => it.id)
            },
            toUrgency: (id: number) => {
                return this.state.urgencies.find((it) => it.id === id)?.type ?? Urgency.Medium
            },
            fromServiceRequestType: (type: ServiceRequestType | null) => {
                if (type === null) return null
                return this.state.settings.serviceSubTypes[type].id
            },
            toServiceRequestType: (id: number | null) => {
                if (id === null) return ServiceRequestType.Repair
                return (
                    Object.values(this.state.settings.serviceSubTypes).find((it) => it.id === id)?.type ??
                    ServiceRequestType.Repair
                )
            },
            fromWorkRequestType: (type: WorkRequestType | null): number => {
                switch (type) {
                    case WorkRequestType.Preventative:
                        return 1
                    case WorkRequestType.Inspection:
                        return 2
                    case WorkRequestType.Service:
                        return 3
                    default:
                        return 1
                }
            },
            toWorkRequestType: (id: number | null): WorkRequestType => {
                switch (id) {
                    case 1:
                        return WorkRequestType.Preventative
                    case 2:
                        return WorkRequestType.Inspection
                    case 3:
                        return WorkRequestType.Service
                    default:
                        return WorkRequestType.Service
                }
            },
            fromWorkOrderStatus: (status: WorkOrderStatus): number => {
                switch (status) {
                    case WorkOrderStatus.Deleted:
                        return 0
                    case WorkOrderStatus.Pending:
                        return 1
                    case WorkOrderStatus.Open:
                        return 2
                    case WorkOrderStatus.InProgress:
                        return 3
                    case WorkOrderStatus.Closed:
                        return 4
                    case WorkOrderStatus.WorkCompleted:
                        return 5
                }
            },
            toWorkOrderStatus: (id: number): WorkOrderStatus => {
                switch (id) {
                    case 0:
                        return WorkOrderStatus.Deleted
                    case 1:
                        return WorkOrderStatus.Pending
                    case 2:
                        return WorkOrderStatus.Open
                    case 3:
                        return WorkOrderStatus.InProgress
                    case 4:
                        return WorkOrderStatus.Closed
                    case 5:
                        return WorkOrderStatus.WorkCompleted
                    default:
                        return WorkOrderStatus.Pending
                }
            },
            toSubCompany: (id: number | null): Company | null =>
                this.state.subCompanies.find((it) => it.id === id) ?? null,
            toDistrict: (id: number | null): District | null => this.state.districts.find((it) => it.id === id) ?? null,
            toSubDistrict: (id: number | null): District | null =>
                this.state.subDistricts.find((it) => it.id === id) ?? null,
            toUnit: (id: number | null): District | null => this.state.units.find((it) => it.id === id) ?? null,
            toGroup: (id: number | null): Group | null => this.state.groups.find((it) => it.id === id) ?? null,
            toSite: (id: number | null): Site | null => this.state.sites.find((it) => it.id === id) ?? null,
            toAssetType: (id: number | null): AssetType | null =>
                this.state.assetTypes.find((it) => it.id === id) ?? null,
            toAssetCategory: (id: number | null): AssetCategory | null =>
                this.state.assetCategories.find((it) => it.id === id) ?? null,
            toAssetClass: (id: number | null): AssetClass | null =>
                this.state.assetClasses.find((it) => it.id === id) ?? null,
            toAssetMake: (id: number | null): AssetMake | null =>
                this.state.assetMakes.find((it) => it.id === id) ?? null,
            toAssetModel: (id: number | null): AssetModel | null =>
                this.state.assetModels.find((it) => it.id === id) ?? null,
            toServiceCode: (id: number | null): ServiceCode | null => this.state.serviceCodes[id ?? -1] ?? null,
            toServiceSchedule: (id: number | null): ServiceSchedule | null =>
                this.state.serviceSchedules[id ?? -1] ?? null,
            toContact: (id: number | null): Contact | null => this.state.contacts.find((it) => it.id === id) ?? null,
            toVendor: (id: number | null): ServiceQuoteVendor | null =>
                this.state.vendors.find((it) => it.id === id) ?? null,
        }
    }

    fetchUrgencies = () =>
        this.effect({
            id: this.fetchUrgencies,
            block: async (job) => {
                const response = await job.pause(this.companyService.fetchUrgencies())
                if (response.isError()) return response
                this.urgenciesChanged(response.value)
                return Outcome.ok(response.value)
            },
        })

    fetchVendors = (companyId: number) =>
        this.effect({
            id: this.fetchVendors,
            block: async (job) => {
                const response = await job.pause(this.companyService.fetchVendors(companyId))
                if (response.isError()) return response
                this.vendorsChanged(response.value)
                return Outcome.ok(response.value)
            },
        })

    fetchContacts = () =>
        this.effect({
            id: this.fetchContacts,
            block: async (job) => {
                const response = await job.pause(this.companyService.fetchContacts())
                if (response.isError()) return response
                this.contactsChanged(response.value)
                return response
            },
        })

    fetchSubCompanies = () =>
        this.effect({
            id: this.fetchSubCompanies,
            block: async (job) => {
                const response = await job.pause(this.companyService.fetchSubCompanies())
                if (response.isError()) return response
                this.subCompaniesChanged(response.value)
                return response
            },
        })

    fetchMakes = () =>
        this.effect({
            id: this.fetchMakes,
            block: async (job) => {
                const response = await job.pause(this.companyService.fetchAssetMakes())
                if (response.isError()) return Outcome.error(undefined)
                this.makesChanged(response.value)
                return response
            },
        })

    fetchModels = () =>
        this.effect({
            id: this.fetchModels,
            block: async (job) => {
                const response = await job.pause(this.companyService.fetchAssetModels())
                if (response.isError()) return Outcome.error(undefined)
                this.modelsChanged(response.value)
                return response
            },
        })

    fetchTypes = () =>
        this.effect({
            id: this.fetchTypes,
            block: async (job) => {
                const response = await job.pause(this.companyService.fetchAssetTypes())
                if (response.isError()) return Outcome.error(undefined)
                this.typesChanged(response.value)
                return response
            },
        })

    fetchCategories = () =>
        this.effect({
            id: this.fetchCategories,
            block: async (job) => {
                const response = await job.pause(this.companyService.fetchAssetCategories())
                if (response.isError()) return Outcome.error(undefined)
                this.categoriesChanged(response.value)
                return response
            },
        })

    fetchClasses = () =>
        this.effect({
            id: this.fetchClasses,
            block: async (job) => {
                const response = await job.pause(this.companyService.fetchAssetClasses())
                if (response.isError()) return Outcome.error(undefined)
                this.classesChanged(response.value)
                return response
            },
        })

    fetchGroups = () =>
        this.effect({
            id: this.fetchGroups,
            block: async (job) => {
                const response = await job.pause(this.companyService.fetchGroups())
                if (response.isError()) return response
                this.groupsChanged(response.value)
                return response
            },
        })

    fetchDistricts = () =>
        this.effect({
            id: this.fetchDistricts,
            block: async (job) => {
                const response = await job.pause(this.companyService.fetchDistricts())
                if (response.isError()) return response
                this.districtsChanged(response.value)
                return response
            },
        })

    fetchSubDistricts = () =>
        this.effect({
            id: this.fetchSubDistricts,
            block: async (job) => {
                const response = await job.pause(this.companyService.fetchSubDistricts())
                if (response.isError()) return response
                this.subDistrictsChanged(response.value)
                return response
            },
        })

    fetchUnits = () =>
        this.effect({
            id: this.fetchUnits,
            block: async (job) => {
                const response = await job.pause(this.companyService.fetchUnits())
                if (response.isError()) return response
                this.unitsChanged(response.value)
                return response
            },
        })

    fetchSitesInUse = () =>
        this.effect({
            id: this.fetchSitesInUse,
            block: async (job) => {
                const response = await job.pause(this.companyService.fetchSitesInUse())
                if (response.isError()) return response
                this.sitesChanged(response.value)
                return response
            },
        })

    fetchAllSites = () =>
        this.effect({
            id: this.fetchAllSites,
            block: async (job) => {
                const response = await job.pause(this.companyService.fetchAllSites())
                if (response.isError()) return response
                this.allSitesChanged(response.value)
                return response
            },
        })

    fetchAliases = () =>
        this.effect({
            id: this.fetchAliases,
            block: async (job) => {
                const response = await job.pause(this.companyService.fetchAliases(this.state.companyId))
                if (response.isError()) return response
                this.aliasesChanged(response.value)
                return response
            },
        })
    fetchCompanyLogo = () =>
        this.effect({
            id: this.fetchCompanyLogo,
            block: async (job) => {
                const response = await job.pause(this.companyService.fetchCompanyLogo(this.state.companyId))
                if (response.isError()) return response

                this.companyLogoUrlChanged(response.value)
                return Outcome.ok(response.value)
            },
        })

    fetchSettings = () =>
        this.effect({
            id: this.fetchSettings,
            block: async (job) => {
                this.effectStatusChanged(CompanyEffect.FetchGlobalSettings, AsyncStatus.busy())

                const [thresholdOutcome, serviceSubTypeOutcome, workOrderAutomationOutcome, mechanics] =
                    await job.pause(
                        Promise.all([
                            this.companyService.fetchThresholdSettings(this.state.companyId),
                            this.companyService.fetchServiceSubTypes(),
                            this.companyService.fetchWorkOrderAutomationSettings(),
                            this.companyService.fetchMechanics(),
                        ])
                    )

                if (
                    thresholdOutcome.isError() ||
                    serviceSubTypeOutcome.isError() ||
                    workOrderAutomationOutcome.isError() ||
                    mechanics.isError()
                ) {
                    return Outcome.error(undefined)
                }

                let mechanicsWithActiveWorkOrders = mechanics.value.filter((x) => x.hasActiveWorkOrders)
                let currentMechanics = this.state.settings.mechanics
                let newMechanics = currentMechanics.map((x) => {
                    x.hasActiveWorkOrders =
                        mechanicsWithActiveWorkOrders.find((value) => value.id === x.id) !== undefined
                    return x
                })

                const defaultSettings = newCompanySettings()
                const newSettings: CompanySettings = {
                    ...defaultSettings,
                    thresholds: thresholdOutcome.isOk() ? thresholdOutcome.value : defaultSettings.thresholds,
                    serviceSubTypes: serviceSubTypeOutcome.isOk()
                        ? serviceSubTypeOutcome.value
                        : defaultSettings.serviceSubTypes,
                    workOrderAutomation: workOrderAutomationOutcome.value,
                    mechanics: newMechanics,
                }

                this.update({ settings: newSettings })
                this.effectStatusChanged(CompanyEffect.FetchGlobalSettings, AsyncStatus.idle())

                return Outcome.ok(this.state.settings)
            },
        })

    fetchServiceCodes = () =>
        this.effect({
            id: this.fetchServiceCodes,
            block: async (job) => {
                const response = await job.pause(this.companyService.fetchServiceCodes())
                if (response.isError()) return response
                this.serviceCodesChanged(response.value)
                return response
            },
        })

    fetchCbaCodes = () =>
        this.effect({
            id: this.fetchCbaCodes,
            block: async (job) => {
                const response = await job.pause(this.companyService.fetchCbaCodes())
                if (response.isError()) return response
                this.cbaCodesChanged(response.value)
                return response
            },
        })

    fetchServiceScheduleFilterOptions = () =>
        this.effect({
            id: this.fetchServiceScheduleFilterOptions,
            block: async (job) => {
                const response = await job.pause(this.companyService.fetchServiceScheduleFilterOptions())
                if (response.isError()) return response
                this.serviceSchedulesChanged(response.value)
                return response
            },
        })

    fetchServiceScheduleInUse = (assetId: number) =>
        this.effect({
            id: this.fetchServiceScheduleFilterOptions,
            block: async (job) => {
                const response = await job.pause(this.companyService.fetchServiceSchedulesInUse(assetId))
                if (response.isError()) return response
                return response
            },
        })

    updateSettings = (settings: CompanySettings) =>
        this.effect({
            id: this.updateSettings,
            block: async (job) => {
                const [contactsOutcome, thresholdsOutcome, workOrderAutomationOutcome] = await job.pause(
                    Promise.all([
                        this.updateMechanics(settings.mechanics),
                        !this.state.settings.thresholds.inspection.isReadOnly &&
                        !this.state.settings.thresholds.preventative.isReadOnly &&
                        !this.state.settings.thresholds.service.isReadOnly
                            ? this.companyService.updateThresholdSettings(settings.thresholds)
                            : Outcome.ok(this.state.settings.thresholds),
                        !this.state.settings.workOrderAutomation.isReadOnly
                            ? this.companyService.updateWorkOrderAutomationSettings(settings.workOrderAutomation)
                            : Outcome.ok(this.state.settings.workOrderAutomation),
                    ])
                )

                if (contactsOutcome.isError() || thresholdsOutcome.isError() || workOrderAutomationOutcome.isError()) {
                    return Outcome.error(undefined)
                }

                const newSettings: CompanySettings = {
                    ...this.state.settings,
                    thresholds: thresholdsOutcome.value,
                    workOrderAutomation: workOrderAutomationOutcome.value,
                }

                this.update({ settings: newSettings })
                return Outcome.ok(newSettings)
            },
        })

    private async updateMechanics(contacts: Contact[]): Promise<Outcome<Contact[]>> {
        const [added, removed] = diffList(this.state.settings.mechanics, contacts, (it) => it.id)
        const mechanicsToAdd = added.map((it) => ({ ...it, isMechanic: true }))
        const mechanicsToRemove = removed.map((it) => ({ ...it, isMechanic: false }))

        const contactOutcomes = await Promise.all(
            [...mechanicsToRemove, ...mechanicsToAdd].map((it) => this.companyService.updateContact(it))
        )

        const updatedMechanics: Contact[] = []

        for (const outcome of contactOutcomes) {
            if (outcome.isError()) return outcome
            updatedMechanics.push(outcome.value)
        }

        this.contactsChanged(
            this.state.contacts.map((contact) => {
                const updated = updatedMechanics.find((it) => it.id === contact.id)
                return updated !== undefined ? updated : contact
            })
        )

        return Outcome.ok(updatedMechanics)
    }

    private effectStatusChanged = (effect: CompanyEffect, status: AsyncStatus) =>
        this.update({
            effectStatus: {
                ...this.state.effectStatus,
                [effect]: status,
            },
        })

    companyIdChanged = (id: number) => this.update({ companyId: id })
    companyNameChanged = (name: string) => this.update({ companyName: name })
    companyLogoUrlChanged = (url: string | null) => this.update({ companyLogoUrl: url })
    private contactsChanged = (contacts: Contact[]) => this.update({ contacts: contacts })
    private subCompaniesChanged = (companies: Company[]) => this.update({ subCompanies: companies })
    private makesChanged = (makes: AssetMake[]) => this.update({ assetMakes: makes })
    private modelsChanged = (models: AssetModel[]) => this.update({ assetModels: models })
    private typesChanged = (types: AssetType[]) => this.update({ assetTypes: types })
    private categoriesChanged = (categories: AssetCategory[]) => this.update({ assetCategories: categories })
    private classesChanged = (classes: AssetClass[]) => this.update({ assetClasses: classes })
    private groupsChanged = (groups: Group[]) => this.update({ groups })
    private districtsChanged = (districts: District[]) => this.update({ districts })
    private subDistrictsChanged = (subDistricts: District[]) => this.update({ subDistricts })
    private unitsChanged = (units: District[]) => this.update({ units })
    private aliasesChanged = (aliases: Record<CompanyAlias, string>) => this.update({ aliases })
    private sitesChanged = (sites: Site[]) => this.update({ sites: sites })
    private allSitesChanged = (sites: Site[]) => this.update({ allSites: sites })
    private serviceCodesChanged = (value: Record<number, ServiceCode>) => this.update({ serviceCodes: value })
    private cbaCodesChanged = (cbaCodes: CBACode[]) => this.update({ cbaCodes: cbaCodes })
    private serviceSchedulesChanged = (value: Record<number, ServiceSchedule>) =>
        this.update({ serviceSchedules: value })
    private urgenciesChanged = (value: CompanyUrgency[]) => this.update({ urgencies: value })
    private vendorsChanged = (value: ServiceQuoteVendor[]) => this.update({ vendors: value })
}

function newCompanyState(): CompanyState {
    return {
        companyId: -1,
        companyName: "",
        effectStatus: arrayToObject(Object.values(CompanyEffect), (it) => [it, AsyncStatus.idle()]),
        subCompanies: [],
        assetCategories: [],
        assetClasses: [],
        assetMakes: [],
        assetModels: [],
        assetTypes: [],
        districts: [],
        subDistricts: [],
        units: [],
        groups: [],
        contacts: [],
        sites: [],
        allSites: [],
        aliases: arrayToObject(Object.values(CompanyAlias), (it) => [it, defaultCompanyAlias(it)]),
        settings: newCompanySettings(),
        serviceCodes: {},
        serviceSchedules: {},
        billingCodes: {},
        urgencies: [],
        cbaCodes: [],
        companyLogoUrl: null,
        vendors: [],
    }
}
