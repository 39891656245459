import { Bloc } from "@lib/bloc/Bloc"
import { FilterPreset } from "@model/filters/FilterPresets"

export type ManageFilterPresetsState = {
    readonly presetToDelete: FilterPreset<any> | null
    readonly presetToEdit: FilterPreset<any> | null
    readonly isSaving: boolean
}

export class ManageFilterPresetsBloc extends Bloc<ManageFilterPresetsState> {
    constructor() {
        super(newManageFilterPresetsState(), { persistStateOnDispose: false })
    }

    presetToEditChanged = (preset: FilterPreset<any> | null) => this.update({ presetToEdit: preset })

    presetToDeleteChanged = (preset: FilterPreset<any> | null) => this.update({ presetToDelete: preset })
}

function newManageFilterPresetsState(): ManageFilterPresetsState {
    return {
        presetToDelete: null,
        presetToEdit: null,
        isSaving: false,
    }
}
