import { CompanyAlias, defaultCompanyAlias } from "@model/company/CompanyAlias"
import { SortableTableColumnDef, TableColumnAlignment, TableColumnSize } from "@model/tables/Table"
import { WorkOrderTableColumn } from "./WorkOrder"

export const WorkOrdersTableColumnDefinitions: Record<
    WorkOrderTableColumn,
    SortableTableColumnDef<WorkOrderTableColumn>
> = {
    [WorkOrderTableColumn.Tag]: {
        type: WorkOrderTableColumn.Tag,
        label: "Tag",
        size: TableColumnSize.FitContent,
        mobileSize: TableColumnSize.MinContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: false,
    },
    [WorkOrderTableColumn.WorkOrder]: {
        type: WorkOrderTableColumn.WorkOrder,
        label: "Work Order",
        size: TableColumnSize.FitContent,
        mobileSize: TableColumnSize.MinContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: true,
    },
    [WorkOrderTableColumn.Asset]: {
        type: WorkOrderTableColumn.Asset,
        label: "Asset",
        size: TableColumnSize.Medium,
        mobileSize: TableColumnSize.FitContentGrow,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: true,
    },
    [WorkOrderTableColumn.Status]: {
        type: WorkOrderTableColumn.Status,
        label: "Status",
        size: TableColumnSize.FitContent,
        mobileSize: TableColumnSize.MinContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: false,
    },
    [WorkOrderTableColumn.Urgency]: {
        type: WorkOrderTableColumn.Urgency,
        label: "Urgency",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: false,
    },
    [WorkOrderTableColumn.DaysOpen]: {
        type: WorkOrderTableColumn.DaysOpen,
        label: "Days Open",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: false,
    },
    [WorkOrderTableColumn.Location]: {
        type: WorkOrderTableColumn.Location,
        label: "Last Known Location",
        size: TableColumnSize.Normal,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: false,
    },
    [WorkOrderTableColumn.WorkToBePerformed]: {
        type: WorkOrderTableColumn.WorkToBePerformed,
        label: "Work To Be Performed",
        size: TableColumnSize.Medium,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: false,
    },
    [WorkOrderTableColumn.DueDate]: {
        type: WorkOrderTableColumn.DueDate,
        label: "Due Date",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: false,
    },
    [WorkOrderTableColumn.HoursUntil]: {
        type: WorkOrderTableColumn.HoursUntil,
        label: "Hours Until",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: false,
    },
    [WorkOrderTableColumn.MilesUntil]: {
        type: WorkOrderTableColumn.MilesUntil,
        label: "Miles Until",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: false,
    },
    [WorkOrderTableColumn.DaysUntil]: {
        type: WorkOrderTableColumn.DaysUntil,
        label: "Days Until",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: false,
    },
    [WorkOrderTableColumn.AssignedTo]: {
        type: WorkOrderTableColumn.AssignedTo,
        label: "Assigned To",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: false,
    },
    [WorkOrderTableColumn.HourMeter]: {
        type: WorkOrderTableColumn.HourMeter,
        label: "Hour Meter",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: false,
    },
    [WorkOrderTableColumn.Odometer]: {
        type: WorkOrderTableColumn.Odometer,
        label: "Odometer",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: false,
    },
    [WorkOrderTableColumn.DaysInactive]: {
        type: WorkOrderTableColumn.DaysInactive,
        label: "Days Inactive",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: false,
    },
    [WorkOrderTableColumn.ServiceCode]: {
        type: WorkOrderTableColumn.ServiceCode,
        label: "Service Code",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: false,
    },
    [WorkOrderTableColumn.Class]: {
        type: WorkOrderTableColumn.Class,
        label: "Class",
        size: TableColumnSize.Normal,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: false,
    },
    [WorkOrderTableColumn.SubCompany]: {
        type: WorkOrderTableColumn.SubCompany,
        label: defaultCompanyAlias(CompanyAlias.SubCompany),
        size: TableColumnSize.Normal,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: false,
    },
    [WorkOrderTableColumn.District]: {
        type: WorkOrderTableColumn.District,
        label: defaultCompanyAlias(CompanyAlias.District),
        size: TableColumnSize.Normal,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: false,
    },
    [WorkOrderTableColumn.SubDistrict]: {
        type: WorkOrderTableColumn.SubDistrict,
        label: defaultCompanyAlias(CompanyAlias.SubDistrict),
        size: TableColumnSize.Normal,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: false,
    },
    [WorkOrderTableColumn.Unit]: {
        type: WorkOrderTableColumn.Unit,
        label: defaultCompanyAlias(CompanyAlias.Unit),
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: false,
    },
    [WorkOrderTableColumn.Group]: {
        type: WorkOrderTableColumn.Group,
        label: defaultCompanyAlias(CompanyAlias.Group),
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: false,
    },
    [WorkOrderTableColumn.City]: {
        type: WorkOrderTableColumn.City,
        label: "City",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: false,
    },
    [WorkOrderTableColumn.State]: {
        type: WorkOrderTableColumn.State,
        label: "State",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Start,
        isSortable: true,
        isRequired: false,
    },
    [WorkOrderTableColumn.ClosedDate]: {
        type: WorkOrderTableColumn.ClosedDate,
        label: "Closed Date",
        size: TableColumnSize.FitContent,
        alignment: TableColumnAlignment.Center,
        isSortable: true,
        isRequired: false,
    },
}
