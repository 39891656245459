import { Page, PageContent } from "@ui/common/page/Page"
import { ServiceRequestCreationModal } from "@ui/workRequests/ServiceRequestCreationModal"
import React from "react"

export function ServiceRequestScreen(): JSX.Element {
    return (
        <Page className={"page--service-request"}>
            <PageContent>
                <ServiceRequestCreationModal alwaysOpen={true} />
            </PageContent>
        </Page>
    )
}
