import { ApiService } from "@service/ApiService"
import { AssetService } from "@service/assets/AssetService"
import { MainAssetService } from "@service/assets/MainAssetService"
import { CompanyFieldIdMapper, CompanyService } from "@service/company/CompanyService"
import { MainCompanyService } from "@service/company/MainCompanyService"
import { MainServiceQuoteService } from "@service/serviceQuotes/MainServiceQuoteService"
import { ServiceQuoteService } from "@service/serviceQuotes/ServiceQuoteService"
import { MainUserService } from "@service/user/MainUserService"
import { UserService } from "@service/user/UserService"
import { MainUserPreferencesService } from "@service/userPreferences/MainUserPreferencesService"
import { UserPreferencesService } from "@service/userPreferences/UserPreferencesService"
import { MainWidgetService } from "@service/widgets/MainWidgetService"
import { WidgetService } from "@service/widgets/WidgetService"
import { MainWorkOrderService } from "@service/workOrders/MainWorkOrderService"
import { WorkOrderService } from "@service/workOrders/WorkOrderService"
import { MainWorkRequestService } from "@service/workRequests/MainWorkRequestService"
import { WorkRequestService } from "@service/workRequests/WorkRequestService"
import { GlobalSettingsScreenBloc } from "@state/admin/GlobalSettingsScreenBloc"
import { AppBloc } from "@state/app/AppBloc"
import { HeaderBloc } from "@state/app/HeaderBloc"
import { CompanyBloc } from "@state/company/CompanyBloc"
import { DashboardFilterOverlayBloc } from "@state/dashboard/DashboardFilterOverlayBloc"
import { DashboardScreenBloc } from "@state/dashboard/DashboardScreenBloc"
import { CreateFilterPresetBloc } from "@state/filters/CreateFilterPresetBloc"
import { ManageFilterPresetsBloc } from "@state/filters/ManageFilterPresetsBloc"
import { ApproveServiceQuoteBloc } from "@state/serviceQuotes/ApproveServiceQuoteBloc"
import { RequestRevisionServiceQuoteBloc } from "@state/serviceQuotes/RequestRevisionServiceQuoteBloc"
import { ServiceQuoteScreenBloc } from "@state/serviceQuotes/ServiceQuoteScreenBloc"
import { ServiceQuotesFilterOverlayBloc } from "@state/serviceQuotes/ServiceQuotesFilterOverlayBloc"
import { ServiceQuotesQuickFiltersBloc } from "@state/serviceQuotes/ServiceQuotesQuickFiltersBloc"
import { ServiceQuotesTableBloc } from "@state/serviceQuotes/ServiceQuotesTableBloc"
import { ServiceQuoteVerifyAssetBloc } from "@state/serviceQuotes/ServiceQuoteVerifyAssetBloc"
import { StatusBloc } from "@state/StatusBloc"
import { UserBloc } from "@state/user/UserBloc"
import { UserPreferencesBloc } from "@state/UserPreferencesBloc"
import { AdjustServiceRequestsBloc } from "@state/workOrders/AdjustServiceRequestsBloc"
import { CreateWorkOrderBloc } from "@state/workOrders/CreateWorkOrderBloc"
import { WorkOrderScreenBloc } from "@state/workOrders/WorkOrderScreenBloc"
import { WorkOrdersFilterOverlayBloc } from "@state/workOrders/WorkOrdersFilterOverlayBloc"
import { WorkOrdersQuickFiltersBloc } from "@state/workOrders/WorkOrdersQuickFiltersBloc"
import { WorkOrdersTableBloc } from "@state/workOrders/WorkOrdersTableBloc"
import { AddWorkRequestToWorkOrderBloc } from "@state/workRequests/AddWorkRequestToWorkOrderBloc"
import { AssetWorkRequestsBloc } from "@state/workRequests/AssetWorkRequestsBloc"
import { ServiceRequestCreationBloc } from "@state/workRequests/ServiceRequestCreationBloc"
import { UserWorkRequestsBloc } from "@state/workRequests/UserWorkRequestsBloc"
import { WorkRequestDetailsScreenBloc } from "@state/workRequests/WorkRequestDetailsScreenBloc"
import { WorkRequestsFilterOverlayBloc } from "@state/workRequests/WorkRequestsFilterOverlayBloc"
import { WorkRequestsQuickFiltersBloc } from "@state/workRequests/WorkRequestsQuickFiltersBloc"
import { WorkRequestsTableBloc } from "@state/workRequests/WorkRequestsTableBloc"

import { GlobalSettingsScreenViewModel } from "@ui/admin/GlobalSettingsScreenViewModel"
import { AppViewModel } from "@ui/app/AppViewModel"
import { DashboardFilterOverlayViewModel } from "@ui/dashboard/DashboardFilterOverlayViewModel"
import { DashboardScreenViewModel } from "@ui/dashboard/DashboardScreenViewModel"
import { WorkOrderStatusWidgetViewModel } from "@ui/dashboard/WorkOrderStatusWidgetViewModel"
import { ServiceQuoteVerifyAssetViewModel } from "@ui/serviceQuotes/ServiceQuoteVerifyAssetViewModel"
import { StatusWidgetViewModel } from "@ui/widgets/StatusWidgetViewModel"
import { CreateWorkOrderViewModel } from "@ui/workOrders/CreateWorkOrderViewModel"
import { WorkOrderScreenViewModel } from "@ui/workOrders/WorkOrderScreenViewModel"
import { WorkOrdersFilterOverlayViewModel } from "@ui/workOrders/WorkOrdersFilterOverlayViewModel"
import { WorkOrdersQuickFiltersViewModel } from "@ui/workOrders/WorkOrdersQuickFiltersViewModel"
import { WorkOrdersTableViewModel } from "@ui/workOrders/WorkOrdersTableViewModel"
import { AssetWorkRequestsViewModel } from "@ui/workRequests/AssetWorkRequestsViewModel"
import { ServiceRequestCreationViewModel } from "@ui/workRequests/ServiceRequestCreationViewModel"
import { WorkRequestDetailsModalViewModel } from "@ui/workRequests/WorkRequestDetailsModalViewModel"
import { WorkRequestsFilterOverlayViewModel } from "@ui/workRequests/WorkRequestsFilterOverlayViewModel"
import { WorkRequestsQuickFiltersViewModel } from "@ui/workRequests/WorkRequestsQuickFiltersViewModel"
import { WorkRequestsTableViewModel } from "@ui/workRequests/WorkRequestsTableViewModel"
import { DeclineServiceQuoteBloc } from "../state/serviceQuotes/DeclineServiceQuoteBloc"
import { ApproveServiceQuoteViewModel } from "./serviceQuotes/ApproveServiceQuoteViewModel"
import { DeclineServiceQuoteViewModel } from "./serviceQuotes/DeclineServiceQuoteViewModel"
import { RequestRevisionServiceQuoteViewModel } from "./serviceQuotes/RequestRevisionServiceQuoteViewModel"
import { ServiceQuoteScreenViewModel } from "./serviceQuotes/ServiceQuoteScreenViewModel"
import { ServiceQuotesFilterOverlayViewModel } from "./serviceQuotes/ServiceQuotesFilterOverlayViewModel"
import { ServiceQuotesQuickFiltersViewModel } from "./serviceQuotes/ServiceQuotesQuickFiltersViewModel"
import { ServiceQuotesTableViewModel } from "./serviceQuotes/ServiceQuotesTableViewModel"
import { AdjustServiceRequestsViewModel } from "./workOrders/AdjustServiceRequestsViewModel"
import { UserWorkRequestsViewModel } from "./workRequests/UserWorkRequestsViewModel"

export class DI {
    /**
     * Blocs
     */
    static appBloc = single(() => new AppBloc())
    static headerBloc = single(() => new HeaderBloc(DI.assetService()))

    static workRequestsTableBloc = single(
        () => new WorkRequestsTableBloc(DI.workRequestService(), DI.assetService(), () => DI.companyBloc().state)
    )

    static serviceQuotesTableBloc = single(() => new ServiceQuotesTableBloc(DI.serviceQuotesService()))

    static workRequestsFilterOverlayBloc = single(() => new WorkRequestsFilterOverlayBloc())

    static serviceQuotesFilterOverlayBloc = single(() => new ServiceQuotesFilterOverlayBloc())

    static workRequestsQuickFiltersBloc = single(() => new WorkRequestsQuickFiltersBloc(DI.workRequestService()))

    static serviceQuotesQuickFiltersBloc = single(() => new ServiceQuotesQuickFiltersBloc(DI.serviceQuotesService()))

    static serviceRequestCreationBloc = single(
        () => new ServiceRequestCreationBloc(DI.assetService(), DI.workRequestService())
    )

    static serviceQuoteCreationBloc = single(
        () => new ServiceRequestCreationBloc(DI.assetService(), DI.workRequestService())
    )

    static serviceQuoteVerifyAssetBloc = single(
        () => new ServiceQuoteVerifyAssetBloc(DI.serviceQuotesService(), DI.assetService())
    )

    static dashboardScreenBloc = single(
        () =>
            new DashboardScreenBloc(
                DI.workRequestService(),
                DI.workOrderService(),
                DI.widgetService(),
                DI.companyFieldIdMapper()
            )
    )

    static dashboardFilterOverlayBloc = single(() => new DashboardFilterOverlayBloc())

    static userPreferencesBloc = single(() => new UserPreferencesBloc(DI.userPreferencesService()))

    static globalSettingsScreenBloc = single(() => new GlobalSettingsScreenBloc())

    static statusBloc = single(() => new StatusBloc())

    static assetWorkRequestsBloc = single(
        () =>
            new AssetWorkRequestsBloc(
                DI.assetService(),
                DI.workRequestService(),
                DI.workOrderService(),
                DI.companyService()
            )
    )

    static userWorkRequestsBloc = single(
        () => new UserWorkRequestsBloc(DI.assetService(), DI.workRequestService(), DI.workOrderService())
    )

    static workRequestDetailsScreenBloc = single(
        () => new WorkRequestDetailsScreenBloc(DI.workRequestService(), DI.assetService())
    )

    static userBloc = single(() => new UserBloc(DI.userService()))

    static workOrdersTableBloc = single(
        () => new WorkOrdersTableBloc(DI.workOrderService(), DI.assetService(), DI.workRequestService())
    )

    static workOrdersFilterOverlayBloc = single(() => new WorkOrdersFilterOverlayBloc())

    static workOrdersQuickFilterBloc = single(() => new WorkOrdersQuickFiltersBloc(DI.workOrderService()))

    static companyBloc = single(() => new CompanyBloc(DI.companyService()))

    static workOrderScreenBloc = single(
        () =>
            new WorkOrderScreenBloc(
                DI.workOrderService(),
                DI.assetService(),
                DI.workRequestService(),
                DI.serviceQuotesService()
            )
    )

    static serviceQuoteScreenBloc = single(
        () => new ServiceQuoteScreenBloc(DI.serviceQuotesService(), DI.assetService())
    )

    static addWorkRequestToWorkOrderBloc = single(() => new AddWorkRequestToWorkOrderBloc())

    static createWorkOrderBloc = single(() => new CreateWorkOrderBloc())

    static createFilterPresetBloc = single(() => new CreateFilterPresetBloc())

    static manageFilterPresetsBloc = single(() => new ManageFilterPresetsBloc())

    static approveServiceQuoteBloc = single(() => new ApproveServiceQuoteBloc())

    static adjustServiceRequestsBloc = single(() => new AdjustServiceRequestsBloc())

    static requestRevisionServiceQuoteBloc = single(() => new RequestRevisionServiceQuoteBloc())

    static declineServiceQuoteBloc = single(
        () => new DeclineServiceQuoteBloc(DI.serviceQuotesService(), DI.serviceQuoteScreenBloc())
    )

    /**
     * ViewModels
     */
    static appViewModel = () =>
        new AppViewModel(DI.appBloc(), DI.userPreferencesBloc(), DI.userBloc(), DI.companyBloc())

    static dashboardScreenViewModel = () =>
        new DashboardScreenViewModel(
            DI.dashboardScreenBloc(),
            DI.dashboardFilterOverlayBloc(),
            DI.userPreferencesBloc()
        )
    static statusWidgetViewModel = () =>
        new StatusWidgetViewModel(
            DI.workRequestsTableBloc(),
            DI.workOrdersTableBloc(),
            DI.userPreferencesBloc(),
            DI.dashboardScreenBloc(),
            DI.userBloc()
        )

    static dashboardFilterOverlayViewModel = () =>
        new DashboardFilterOverlayViewModel(
            DI.dashboardFilterOverlayBloc(),
            DI.userPreferencesBloc(),
            DI.dashboardScreenBloc(),
            DI.companyBloc(),
            DI.statusBloc()
        )

    static workRequestsTableViewModel = () =>
        new WorkRequestsTableViewModel(
            DI.userPreferencesBloc(),
            DI.workRequestsTableBloc(),
            DI.workRequestsFilterOverlayBloc(),
            DI.companyBloc(),
            DI.statusBloc(),
            DI.userBloc()
        )

    static serviceRequestCreationViewModel = () =>
        new ServiceRequestCreationViewModel(
            DI.serviceRequestCreationBloc(),
            DI.workRequestsTableBloc(),
            DI.workOrderScreenBloc(),
            DI.assetWorkRequestsBloc(),
            DI.companyBloc(),
            DI.userBloc(),
            DI.statusBloc()
        )

    static serviceQuoteVerifyAssetViewModel = () =>
        new ServiceQuoteVerifyAssetViewModel(DI.serviceQuoteVerifyAssetBloc(), DI.statusBloc())

    static workRequestsQuickFiltersViewModel = () =>
        new WorkRequestsQuickFiltersViewModel(
            DI.workRequestsQuickFiltersBloc(),
            DI.workRequestsTableBloc(),
            DI.serviceRequestCreationBloc(),
            DI.userBloc()
        )

    static serviceQuotesQuickFiltersViewModel = () =>
        new ServiceQuotesQuickFiltersViewModel(
            DI.serviceQuotesQuickFiltersBloc(),
            DI.serviceQuotesTableBloc(),
            DI.companyBloc(),
            DI.serviceQuotesFilterOverlayBloc(),
            DI.userPreferencesBloc(),
            DI.statusBloc()
        )

    static globalSettingsScreenViewModel = () =>
        new GlobalSettingsScreenViewModel(
            DI.globalSettingsScreenBloc(),
            DI.companyBloc(),
            DI.statusBloc(),
            DI.userBloc()
        )

    static assetWorkRequestsViewModel = () =>
        new AssetWorkRequestsViewModel(
            DI.assetWorkRequestsBloc(),
            DI.statusBloc(),
            DI.userBloc(),
            DI.serviceRequestCreationBloc()
        )

    static userWorkRequestsViewModel = () =>
        new UserWorkRequestsViewModel(
            DI.userWorkRequestsBloc(),
            DI.userPreferencesBloc(),
            DI.companyBloc(),
            DI.userBloc()
        )

    static workRequestDetailsModalViewModel = () =>
        new WorkRequestDetailsModalViewModel(
            DI.workRequestDetailsScreenBloc(),
            DI.assetWorkRequestsBloc(),
            DI.companyBloc(),
            DI.statusBloc(),
            DI.userBloc()
        )

    static workRequestsFilterOverlayViewModel = () =>
        new WorkRequestsFilterOverlayViewModel(
            DI.workRequestsFilterOverlayBloc(),
            DI.userPreferencesBloc(),
            DI.workRequestsTableBloc(),
            DI.companyBloc(),
            DI.statusBloc()
        )

    static workOrdersTableViewModel = () =>
        new WorkOrdersTableViewModel(
            DI.userPreferencesBloc(),
            DI.workOrdersTableBloc(),
            DI.workOrdersFilterOverlayBloc(),
            DI.companyBloc(),
            DI.userBloc(),
            DI.statusBloc()
        )

    static workOrdersFilterOverlayViewModel = () =>
        new WorkOrdersFilterOverlayViewModel(
            DI.workOrdersFilterOverlayBloc(),
            DI.userPreferencesBloc(),
            DI.companyBloc(),
            DI.workOrdersTableBloc(),
            DI.statusBloc()
        )

    static serviceQuotesFilterOverlayViewModel = () =>
        new ServiceQuotesFilterOverlayViewModel(
            DI.serviceQuotesFilterOverlayBloc(),
            DI.userPreferencesBloc(),
            DI.companyBloc(),
            DI.serviceQuotesTableBloc(),
            DI.statusBloc()
        )

    static workOrdersQuickFiltersViewModel = () =>
        new WorkOrdersQuickFiltersViewModel(
            DI.workOrdersQuickFilterBloc(),
            DI.workOrdersTableBloc(),
            DI.companyBloc(),
            DI.workOrdersFilterOverlayBloc(),
            DI.userPreferencesBloc(),
            DI.statusBloc()
        )

    static workOrderScreenViewModel = () =>
        new WorkOrderScreenViewModel(
            DI.workOrderScreenBloc(),
            DI.companyBloc(),
            DI.statusBloc(),
            DI.serviceRequestCreationBloc(),
            DI.userBloc()
        )
    static serviceQuoteScreenViewModel = () =>
        new ServiceQuoteScreenViewModel(
            DI.serviceQuoteScreenBloc(),
            DI.companyBloc(),
            DI.statusBloc(),
            DI.serviceRequestCreationBloc(),
            DI.userBloc()
        )

    static createWorkOrderViewModel = () => new CreateWorkOrderViewModel(DI.createWorkOrderBloc(), DI.companyBloc())

    static approveServiceQuoteViewModel = () =>
        new ApproveServiceQuoteViewModel(DI.approveServiceQuoteBloc(), DI.companyBloc())

    static adjustServiceRequestsViewModel = () => new AdjustServiceRequestsViewModel(DI.adjustServiceRequestsBloc())

    static requestRevisionServiceQuoteViewModel = () =>
        new RequestRevisionServiceQuoteViewModel(DI.requestRevisionServiceQuoteBloc(), DI.companyBloc())

    static declineServiceQuoteViewModel = () =>
        new DeclineServiceQuoteViewModel(DI.declineServiceQuoteBloc(), DI.companyBloc())

    static workOrderStatusWidgetViewModel = () =>
        new WorkOrderStatusWidgetViewModel(
            DI.dashboardScreenBloc(),
            DI.companyBloc(),
            DI.workOrdersTableBloc(),
            DI.userBloc()
        )

    /**
     * Services
     */
    static workOrderService = single<WorkOrderService>(
        () => new MainWorkOrderService(DI.apiService(), DI.companyFieldIdMapper())
    )

    static workRequestService = single<WorkRequestService>(
        () => new MainWorkRequestService(DI.apiService(), DI.companyFieldIdMapper())
    )

    static serviceQuotesService = single<ServiceQuoteService>(
        () => new MainServiceQuoteService(DI.apiService(), DI.companyFieldIdMapper())
    )

    static userPreferencesService = single<UserPreferencesService>(
        () => new MainUserPreferencesService(DI.apiService(), DI.companyFieldIdMapper())
    )

    static assetService = single<AssetService>(() => new MainAssetService(DI.apiService()))
    static apiService = single<ApiService>(() => new ApiService())
    static userService = single<UserService>(() => new MainUserService(DI.apiService()))
    static companyService = single<CompanyService>(() => new MainCompanyService(DI.apiService()))
    static widgetService = single<WidgetService>(
        () => new MainWidgetService(DI.apiService(), DI.companyFieldIdMapper())
    )

    static serviceQuotesTableViewModel = () =>
        new ServiceQuotesTableViewModel(
            DI.userPreferencesBloc(),
            DI.serviceQuotesTableBloc(),
            DI.serviceQuotesFilterOverlayBloc(),
            DI.companyBloc(),
            DI.userBloc(),
            DI.statusBloc(),
            DI.serviceQuoteVerifyAssetBloc()
        )

    /**
     * Utils
     */
    static companyFieldIdMapper = single<CompanyFieldIdMapper>(() => DI.companyBloc().createIdMapper())
}

function single<T>(factory: () => T): () => T {
    let instance: T | undefined = undefined
    return () => {
        if (instance === undefined) instance = factory()
        return instance
    }
}
