import { arrayToObject } from "@lib/TypeUtil"
import { Filter, FilterValue, isEmptyFilter, isEmptyFilterValue, isFilterGroupEqual } from "@model/filters/Filter"
import { ServiceQuoteFilter } from "@model/serviceQuotes/ServiceQuote"
import { ServiceQuoteFilterDefinitions } from "@model/serviceQuotes/ServiceQuoteFilterDefinitions"
import { FilterOverlayBloc, FilterOverlayState } from "@state/filters/FilterOverlayBloc"

export type ServiceQuotesFilterOverlayState = FilterOverlayState<ServiceQuoteFilter>

export class ServiceQuotesFilterOverlayBloc extends FilterOverlayBloc<ServiceQuoteFilter> {
    constructor() {
        super(Object.values(ServiceQuoteFilter), ServiceQuoteFilterDefinitions)
    }

    override getSearchFilter() {
        return ServiceQuoteFilter.Search
    }

    override getSecondaryFilter() {
        return ServiceQuoteFilter.WOSearch
    }

    override updateFilter(type: ServiceQuoteFilter, value: FilterValue[]) {
        if (
            [
                ServiceQuoteFilter.ServiceQuoteId,
                ServiceQuoteFilter.ServiceQuoteId,
                ServiceQuoteFilter.ServiceQuoteId,
            ].includes(type)
        ) {
            super.updateFilter(ServiceQuoteFilter.ServiceQuoteId, [{ value: false }])
        } else if (type === ServiceQuoteFilter.AssignedTo) {
            super.updateFilter(ServiceQuoteFilter.AssignedTo, [{ value: "" }])
        }
        return super.updateFilter(type, value)
    }

    override isPresetModified(state: FilterOverlayState<ServiceQuoteFilter>): boolean {
        if (state.preset === null) return false

        const serviceQuoteFilter = state.filters[ServiceQuoteFilter.ServiceQuoteId]
        const assetFilter = state.filters[ServiceQuoteFilter.Asset]
        const vendorFilter = state.filters[ServiceQuoteFilter.VendorID]
        const contactFilter = state.filters[ServiceQuoteFilter.Contact]
        const assignedToFilter = state.filters[ServiceQuoteFilter.AssignedTo]
        const statusFilter = state.filters[ServiceQuoteFilter.Status]
        const totalCostFilter = state.filters[ServiceQuoteFilter.TotalCostFilterOption]
        const dateReceivedFilter = state.filters[ServiceQuoteFilter.CreationDateTimeFilterOption]
        const lastKnownFilter = state.filters[ServiceQuoteFilter.LastKnownLocation]

        return !isFilterGroupEqual(
            arrayToObject(state.preset.filters, (it) => [it.definition.type, it]),
            arrayToObject(
                Object.values<Filter<ServiceQuoteFilter>>(state.filters).filter((it) => {
                    if (
                        it.definition.type === ServiceQuoteFilter.ServiceQuoteId &&
                        isEmptyFilterValue(serviceQuoteFilter)
                    ) {
                        return false
                    }
                    if (it.definition.type === ServiceQuoteFilter.Asset && isEmptyFilterValue(assetFilter)) {
                        return false
                    }
                    if (it.definition.type === ServiceQuoteFilter.VendorID && isEmptyFilterValue(vendorFilter)) {
                        return false
                    }
                    if (it.definition.type === ServiceQuoteFilter.Contact && isEmptyFilterValue(contactFilter)) {
                        return false
                    }
                    if (it.definition.type === ServiceQuoteFilter.AssignedTo && isEmptyFilterValue(assignedToFilter)) {
                        return false
                    }
                    if (it.definition.type === ServiceQuoteFilter.Status && isEmptyFilterValue(statusFilter)) {
                        return false
                    }
                    if (
                        it.definition.type === ServiceQuoteFilter.TotalCostFilterOption &&
                        isEmptyFilterValue(totalCostFilter)
                    ) {
                        return false
                    }
                    if (
                        it.definition.type === ServiceQuoteFilter.CreationDateTimeFilterOption &&
                        isEmptyFilterValue(dateReceivedFilter)
                    ) {
                        return false
                    }
                    if (
                        it.definition.type === ServiceQuoteFilter.LastKnownLocation &&
                        isEmptyFilterValue(lastKnownFilter)
                    ) {
                        return false
                    }

                    return !isEmptyFilter(it)
                }),
                (it) => [it.definition.type, it]
            )
        )
    }
}
