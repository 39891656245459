import { useBloc } from "@lib/bloc/hooks"
import { FilterPreset, FilterPresetForm } from "@model/filters/FilterPresets"
import { TableColumnAlignment, TableColumnSize } from "@model/tables/Table"
import { DI } from "@ui/DI"
import React from "react"
import { Button, ButtonTheme, ButtonType } from "../buttons/Button"
import { ImageUrls } from "../images/ImageUrls"
import { Modal, ModalBody, ModalButtons, ModalContent, ModalHeader, ModalHeaderTitle } from "../Modal"
import { Table } from "../tables/Table"
import { EditFilterPresetModal } from "./EditFilterPresetModal"

export type ManageFilterPresetsModalProps<T extends string> = {
    isVisible: boolean
    presets: FilterPreset<T>[]
    isSaving: boolean
    onCancel: () => void
    onPresetDelete: (preset: FilterPreset<T>) => void
    onPresetEdit: (preset: FilterPreset<T>, form: FilterPresetForm) => void
}

export function ManageFilterPresetsModal<T extends string>(props: ManageFilterPresetsModalProps<T>): JSX.Element {
    const [state, bloc] = useBloc(() => DI.manageFilterPresetsBloc())

    return (
        <>
            <Modal isVisible={props.isVisible} onClose={props.onCancel} useContentTag={true}>
                <ManageFilterPresetModalContent
                    {...props}
                    onPresetDelete={(preset) => bloc.presetToDeleteChanged(preset)}
                    onPresetEdit={(preset) => bloc.presetToEditChanged(preset)}
                />
            </Modal>

            <EditFilterPresetModal
                isVisible={state.presetToEdit !== null}
                unavailableNames={props.presets
                    .filter((it) => it.name !== state.presetToEdit?.name ?? "")
                    .map((it) => it.name)}
                onCancel={() => bloc.presetToEditChanged(null)}
                onSave={(form) => {
                    if (!state.presetToEdit) return
                    props.onPresetEdit(state.presetToEdit, form)
                    bloc.presetToEditChanged(null)
                }}
                form={{ isDefault: state.presetToEdit?.isDefault ?? false, name: state.presetToEdit?.name ?? "" }}
                isSaving={state.isSaving}
            />

            <Modal
                isVisible={state.presetToDelete !== null}
                onClose={() => bloc.presetToDeleteChanged(null)}
                useContentTag={true}
            >
                <ModalHeader>
                    <ModalHeaderTitle>Delete Filter Preset</ModalHeaderTitle>
                </ModalHeader>
                <ModalBody>Are you sure you want to delete this filter preset?</ModalBody>
                <ModalButtons>
                    <Button type={ButtonType.Text} label="Cancel" onClick={() => bloc.presetToDeleteChanged(null)} />
                    <Button
                        type={ButtonType.Contained}
                        label="Delete"
                        onClick={() => {
                            if (!state.presetToDelete) return
                            props.onPresetDelete(state.presetToDelete)
                            bloc.presetToDeleteChanged(null)
                        }}
                    />
                </ModalButtons>
            </Modal>
        </>
    )
}

function ManageFilterPresetModalContent<T extends string>({
    onCancel,
    presets,
    onPresetDelete,
    onPresetEdit,
}: {
    presets: FilterPreset<T>[]
    onCancel: () => void
    onPresetDelete: (preset: FilterPreset<T>) => void
    onPresetEdit: (preset: FilterPreset<T>) => void
}): JSX.Element {
    return (
        <ModalContent>
            <ModalHeader>
                <ModalHeaderTitle>Manage Filter Presets</ModalHeaderTitle>
            </ModalHeader>
            <ModalBody>
                <Table
                    className="manage-filter-presets"
                    head={[
                        { content: "Default", size: TableColumnSize.FitContent },
                        { content: "Name", size: TableColumnSize.FitContentGrow },
                        { size: TableColumnSize.FitContent },
                    ]}
                    data={presets.map((preset) => ({
                        entryId: preset.id,
                        columns: [
                            {
                                content: preset.isDefault ? (
                                    <div className="manage-filter-presets-is-default-check" />
                                ) : (
                                    ""
                                ),
                                alignment: TableColumnAlignment.Center,
                            },
                            {
                                className: "manage-filters-preset-name",
                                content: preset.name,
                            },
                            {
                                content: (
                                    <>
                                        <Button
                                            type={ButtonType.Icon}
                                            theme={ButtonTheme.Light}
                                            iconUrl={ImageUrls.Pencil}
                                            label="Edit"
                                            onClick={() => onPresetEdit(preset)}
                                        />
                                        <Button
                                            type={ButtonType.Icon}
                                            theme={ButtonTheme.Light}
                                            iconUrl={ImageUrls.Trash}
                                            label="Delete"
                                            onClick={() => onPresetDelete(preset)}
                                        />
                                    </>
                                ),
                            },
                        ],
                    }))}
                />
            </ModalBody>
            <ModalButtons>
                <Button type={ButtonType.Contained} label="Done" onClick={onCancel} />
            </ModalButtons>
        </ModalContent>
    )
}
