export type PDFWorkRequest = {
    originalWorkRequest: any
    serviceCode?: string
    type: PDFWorkRequestType
    workToBePerformed?: string
    specialInstructions?: string
    tasks?: PDFWorkRequestTask[]
    estimatedLaborHours?: number
    estimatedLaborCost?: number
    estimatedPartsCost?: number
    estimatedTotalCost?: number
}

export type PDFWorkRequestTask = {
    measurement?: string
    name: string
    description?: string
    status?: string
}

export enum PDFWorkRequestType {
    Preventative = "preventative",
    Inspection = "inspection",
    Service = "service",
}
