import { Filter, FilterValue } from "@model/filters/Filter"
import { FilterPreset, FilterPresetForm, FilterPresetType } from "@model/filters/FilterPresets"
import { StatusBloc } from "@state/StatusBloc"
import { UserPreferencesBloc } from "@state/UserPreferencesBloc"
import { FilterOverlayBloc } from "./FilterOverlayBloc"
import { IFilterOverlayMixinBloc } from "./IFilterOverlayMixinBloc"

export interface FilterOverlayBlocCoordinator<F extends string> {
    resetFilters(): void
    toggleOverlayVisibility(): void
    updateFilters(filters: Record<F, Filter<F>>): void
    updateFilter(type: F, value: FilterValue[]): void
    applyFilters(): void
    showCreatePreset(): void
    hideCreatePreset(): void
    filterPresetSelected(id: number): void
    createPreset(form: FilterPresetForm): void
    updatePresetFilters(): void
    updatePresetForm(preset: FilterPreset<F>, form: FilterPresetForm): void
    deletePreset(preset: FilterPreset<F>): void
    showManagePresets(): void
    hideManagePresets(): void
    isSearchFilterValid(): boolean
    isSecondaryFilterValid(): boolean
}

export class FilterOverlayBlocCoordinatorMixin<F extends string> implements FilterOverlayBlocCoordinator<F> {
    constructor(
        private filterPresetType: FilterPresetType,
        private filterOverlayBloc: FilterOverlayBloc<F>,
        private filterBloc: IFilterOverlayMixinBloc<F>,
        private userPreferencesBloc: UserPreferencesBloc,
        private statusBloc: StatusBloc
    ) {}

    resetFilters = async () => {
        this.filterOverlayBloc.resetFilters()
    }

    toggleOverlayVisibility = () => {
        this.filterOverlayBloc.updateFilters(this.filterBloc.state.filters)
        this.filterOverlayBloc.toggleOverlayVisibility()
    }

    updateFilters = (filters: Record<F, Filter<F>>) => this.filterOverlayBloc.updateFilters(filters)

    updateFilter = (type: F, value: FilterValue[]) => this.filterOverlayBloc.updateFilter(type, value)

    applyFilters = async () => {
        this.filterOverlayBloc.updateIsSearching(true)
        this.filterOverlayBloc.applyFilters()
        await this.filterBloc.applyFiltersAndFetch(this.filterOverlayBloc.state.filters)
        this.filterOverlayBloc.updateIsSearching(false)
    }

    showCreatePreset = () => this.filterOverlayBloc.showCreatePreset()

    hideCreatePreset = () => this.filterOverlayBloc.hideCreatePreset()

    filterPresetSelected = (id: number) => {
        const preset = this.presets.find((it) => it.id === id)
        this.filterOverlayBloc.filterPresetChanged(preset ?? null)
    }

    createPreset = async (form: FilterPresetForm) => {
        this.filterOverlayBloc.updateIsSaving(true)
        this.filterOverlayBloc.hideCreatePreset()

        const infoMessage = this.statusBloc.enqueueInfoMessage("Creating preset...")
        const outcome = await this.userPreferencesBloc.createFilterPreset({
            id: 0,
            type: this.filterPresetType,
            name: form.name,
            isDefault: form.isDefault,
            filters: Object.values(this.filterOverlayBloc.state.filters),
        })

        if (outcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error creating preset")
            this.statusBloc.hideInfoMessage(infoMessage)
            return
        }

        this.statusBloc.hideInfoMessage(infoMessage)
        this.filterOverlayBloc.filterPresetCreated(outcome.value)
        this.filterOverlayBloc.updateIsSaving(false)
    }

    updatePresetFilters = async () => {
        if (this.filterOverlayBloc.state.preset === null) return

        const infoMessage = this.statusBloc.enqueueInfoMessage("Updating preset...")
        const outcome = await this.userPreferencesBloc.updateFilterPresetFilters(
            this.filterOverlayBloc.state.preset,
            Object.values(this.filterOverlayBloc.state.filters)
        )

        if (outcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error updating preset")
            this.statusBloc.hideInfoMessage(infoMessage)
            return
        }

        this.statusBloc.hideInfoMessage(infoMessage)
        this.filterOverlayBloc.filterPresetFiltersUpdated(outcome.value)
    }

    updatePresetForm = async (preset: FilterPreset<F>, form: FilterPresetForm) => {
        const infoMessage = this.statusBloc.enqueueInfoMessage("Updating preset...")
        const outcome = await this.userPreferencesBloc.updateFilterPresetForm(preset, form)

        if (outcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error updating preset")
            this.statusBloc.hideInfoMessage(infoMessage)
            return
        }

        this.statusBloc.hideInfoMessage(infoMessage)
        this.filterOverlayBloc.filterPresetFormUpdated(outcome.value)
    }

    deletePreset = async (preset: FilterPreset<F>) => {
        const infoMessage = this.statusBloc.enqueueInfoMessage("Deleting preset...")
        const outcome = await this.userPreferencesBloc.deleteFilterPreset(preset)

        if (outcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error deleting preset")
            this.statusBloc.hideInfoMessage(infoMessage)
            return
        }

        this.statusBloc.hideInfoMessage(infoMessage)
        this.filterOverlayBloc.filterPresetDeleted(preset)
        if (this.presets.length === 0) this.filterOverlayBloc.hideManagePresets()
    }

    showManagePresets = () => this.filterOverlayBloc.showManagePresets()

    hideManagePresets = () => this.filterOverlayBloc.hideManagePresets()

    isSearchFilterValid = () => this.filterOverlayBloc.isSearchFilterValid()

    isSecondaryFilterValid = () => this.filterOverlayBloc.isSecondaryFilterValid()

    isSaving = () => this.filterOverlayBloc.isSaving()

    private get presets(): FilterPreset<F>[] {
        switch (this.filterPresetType) {
            case FilterPresetType.WorkRequest:
                return this.userPreferencesBloc.state.filterPresets.workRequests as FilterPreset<F>[]
            case FilterPresetType.WorkOrder:
                return this.userPreferencesBloc.state.filterPresets.workOrders as FilterPreset<F>[]
            case FilterPresetType.Dashboard:
                return this.userPreferencesBloc.state.filterPresets.dashboard as FilterPreset<F>[]
            default:
                return []
        }
    }
}
