import { BlocCoordinator } from "@lib/bloc/BlocCoordinator"
import { Contact } from "@model/contacts/Contact"
import { CompanyBloc, CompanyState } from "@state/company/CompanyBloc"
import {
    RequestRevisionServiceQuoteBloc,
    RequestRevisionServiceQuoteState,
} from "@state/serviceQuotes/RequestRevisionServiceQuoteBloc"

type Dependencies = [RequestRevisionServiceQuoteState, CompanyState]
type StateSelection = RequestRevisionServiceQuoteState & {
    contacts: Contact[]
    isFormValid: boolean
    userName: string
}

export class RequestRevisionServiceQuoteViewModel extends BlocCoordinator<Dependencies, StateSelection> {
    constructor(
        private requestRevisionServiceQuoteBloc: RequestRevisionServiceQuoteBloc,
        private companyBloc: CompanyBloc
    ) {
        super([requestRevisionServiceQuoteBloc, companyBloc])
    }

    transform = ([requestRevisionServiceQuoteState, companyState]: Dependencies): StateSelection => ({
        ...requestRevisionServiceQuoteState,
        contacts: companyState.contacts,
        isFormValid: requestRevisionServiceQuoteState.vendorMessage != "",
        userName: requestRevisionServiceQuoteState.userName,
    })

    vendorMessageChanged = (value: string) => this.requestRevisionServiceQuoteBloc.vendorMessageToChanged(value)
    notifyContactsChanged = (value: number[]) => this.requestRevisionServiceQuoteBloc.notifyContactsToChanged(value)
}
