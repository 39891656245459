import { Job } from "@ethossoftworks/job"
import { BlocCoordinator } from "@lib/bloc/BlocCoordinator"
import { CompanyAlias } from "@model/company/CompanyAlias"
import { Filter } from "@model/filters/Filter"
import { createFilterGroupFromPreset } from "@model/filters/FilterPresets"
import { PermissionObject, PermissionType } from "@model/user/User"
import { WorkRequestFilter, WorkRequestsTableRow, WorkRequestTableColumn } from "@model/workRequests/WorkRequest"
import { WorkRequestPrintOption } from "@model/workRequests/WorkRequestPrintOption"
import { WorkRequestsFilterDefinitions } from "@model/workRequests/WorkRequestsFilterDefinitions"
import { CompanyBloc, CompanyState } from "@state/company/CompanyBloc"
import { SortableTableBlocEffect } from "@state/SortableTableBloc"
import { StatusBloc } from "@state/StatusBloc"
import { UserBloc, UserState } from "@state/user/UserBloc"
import { UserPreferencesBloc, UserPreferencesState } from "@state/UserPreferencesBloc"
import { WorkRequestsFilterOverlayBloc } from "@state/workRequests/WorkRequestsFilterOverlayBloc"
import { WorkRequestsTableBloc, WorkRequestsTableState } from "@state/workRequests/WorkRequestsTableBloc"

/**
 * Work Requests Table ViewModel
 */
type Dependencies = [UserPreferencesState, WorkRequestsTableState, CompanyState, UserState]

type StateSelection = {
    aliases: Record<CompanyAlias, string>
    columns: WorkRequestTableColumn[]
    availableColumns: WorkRequestTableColumn[]
    isTableDataLoading: boolean
    companyLogoUrl: string | null
} & WorkRequestsTableState

export class WorkRequestsTableViewModel extends BlocCoordinator<Dependencies, StateSelection> {
    constructor(
        private userPreferencesBloc: UserPreferencesBloc,
        private workRequestsBloc: WorkRequestsTableBloc,
        private filterOverlayBloc: WorkRequestsFilterOverlayBloc,
        private companyBloc: CompanyBloc,
        private statusBloc: StatusBloc,
        private userBlock: UserBloc
    ) {
        super([userPreferencesBloc, workRequestsBloc, companyBloc, userBlock])
    }

    protected transform = ([userPreferences, tableState, companyState, userState]: Dependencies): StateSelection => ({
        aliases: companyState.aliases,
        columns: userPreferences.tableSettings.workRequestsTableColumns.columns.filter(
            this.availableColumnFilter(companyState)
        ),
        availableColumns: Object.values(WorkRequestTableColumn).filter(this.availableColumnFilter(companyState)),
        isTableDataLoading: tableState.effectStatus[SortableTableBlocEffect.Fetch].isBusy(),
        companyLogoUrl: companyState.companyLogoUrl,
        ...tableState,
    })

    hasWorkOrderViewPermission = () =>
        this.userBlock.state.user.hasAccess(PermissionObject.MaintenanceWorkOrder, PermissionType.View)

    private availableColumnFilter = (state: CompanyState) => (it: WorkRequestTableColumn) => {
        switch (it) {
            case WorkRequestTableColumn.SubCompany:
                return state.subCompanies.length > 0
            case WorkRequestTableColumn.District:
                return state.districts.length > 0
            case WorkRequestTableColumn.SubDistrict:
                return state.subDistricts.length > 0
            case WorkRequestTableColumn.Unit:
                return state.units.length > 0
            case WorkRequestTableColumn.Group:
                return state.groups.length > 0
            default:
                return true
        }
    }

    onMounted = (queryfilter: Record<WorkRequestFilter, Filter<WorkRequestFilter>> | null) => {
        let filters = this.workRequestsBloc.state.filters
        if (queryfilter) {
            filters = queryfilter
            this.filterOverlayBloc.updateFilters(filters)
        } else {
            const defaultPreset = this.userPreferencesBloc.state.filterPresets.workRequests.find((it) => it.isDefault)
            filters = defaultPreset
                ? createFilterGroupFromPreset(defaultPreset, WorkRequestsFilterDefinitions)
                : this.workRequestsBloc.state.filters

            if (defaultPreset) {
                this.filterOverlayBloc.defaultFilterPresetLoaded(defaultPreset)
            } else {
                this.filterOverlayBloc.updateFilters(filters)
            }
        }

        this.companyBloc.fetchServiceCodes()
        this.workRequestsBloc.fetchData(
            filters,
            this.workRequestsBloc.state.sort,
            this.workRequestsBloc.state.pagination
        )
    }

    onResetFiltersClicked = () => {
        this.filterOverlayBloc.resetFilters()
        this.workRequestsBloc.applyFiltersAndFetch(null)
    }
    onExportClicked = () => {
        this.workRequestsBloc.exportData()
    }
    onNextPageClicked = () => this.workRequestsBloc.fetchNextPage()
    onPreviousPageClicked = () => this.workRequestsBloc.fetchPreviousPage()
    onViewAllPagesClicked = () => this.workRequestsBloc.fetchAllPages()
    onResetViewAllClicked = () => this.workRequestsBloc.resetViewAll()
    onPageClicked = (page: number) => this.workRequestsBloc.fetchPage(page)
    onManageColumnsClicked = () => {
        this.workRequestsBloc.manageColumnsColumnsSelectionChanged(
            this.userPreferencesBloc.state.tableSettings.workRequestsTableColumns.columns
        )
        this.workRequestsBloc.manageColumnsModalVisibilityChanged(true)
    }

    onColumnSortClicked = (columnIndex: number) =>
        this.workRequestsBloc.toggleSort(
            this.userPreferencesBloc.state.tableSettings.workRequestsTableColumns.columns[columnIndex]
        )

    onFilterRemoveClicked = (filter: Filter<WorkRequestFilter>) =>
        this.workRequestsBloc.removeFilter(filter.definition.type)

    onViewAllColumnClicked = (row: WorkRequestsTableRow) => this.workRequestsBloc.showViewAllColumnsModal(row)
    onViewAllColumnsModalClosed = () => this.workRequestsBloc.hideViewAllColumnsModal()

    onManageColumnsCancel = () => {
        this.workRequestsBloc.manageColumnsModalVisibilityChanged(false)
        this.workRequestsBloc.manageColumnsColumnsSelectionChanged(
            this.userPreferencesBloc.state.tableSettings.workRequestsTableColumns.columns
        )
    }

    onManageColumnsApply = async () => {
        this.workRequestsBloc.manageColumnsModalVisibilityChanged(false)
        const loaderId = this.statusBloc.enqueueInfoMessage("Saving preferences")
        const outcome = await this.userPreferencesBloc.updateWorkRequestsTableColumns(
            this.workRequestsBloc.state.manageColumnsModalSelectedColumns
        )
        if (!Job.isCancelled(outcome) && outcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error saving preferences")
        }
        this.statusBloc.hideInfoMessage(loaderId)
    }

    onManageColumnsDefaultsToggled = () => this.workRequestsBloc.manageColumnsDefaultsToggled()

    onManageColumnsColumnCheckChanged = (column: WorkRequestTableColumn, isChecked: boolean) =>
        this.workRequestsBloc.manageColumnsColumnChanged(column, isChecked)

    assetToPrintChanged = (assetId: number | null) => this.workRequestsBloc.assetToPrintChanged(assetId)

    assetPrintOptionSelected = async (printOption: WorkRequestPrintOption) =>
        this.workRequestsBloc.printWorkRequestOption(printOption, Object.values(this.companyBloc.state.serviceCodes))
}
