import { useLastNonNull } from "@lib/Hooks"
import { Tag, TagChange, tagChangeFromTag } from "@model/Tag"
import { labelForWorkRequest, WorkRequest } from "@model/workRequests/WorkRequest"
import { Button, ButtonType } from "@ui/common/buttons/Button"
import { FormField } from "@ui/common/form/FormField"
import { FormFieldGroup } from "@ui/common/form/FormFieldGroup"
import { Radio } from "@ui/common/form/Radio"
import { TextArea } from "@ui/common/form/TextArea"
import { Grid, GridGap } from "@ui/common/grids/Grid"
import { Modal, ModalBody, ModalButtons, ModalContent, ModalHeader, ModalHeaderTitle } from "@ui/common/Modal"
import React from "react"

export function DismissWorkRequestModal({
    onCancel,
    onConfirm,
    onReasonChanged,
    onTagChanged,
    onTagReasonChanged,
    assetTag,
    ...props
}: {
    data: {
        request: WorkRequest
        reason: string
        tagChange: TagChange
        tagReason: string
    } | null
    assetTag: Tag | null
    onReasonChanged: (workRequest: WorkRequest, value: string) => void
    onCancel: () => void
    onConfirm: () => void
    onTagChanged: (workRequest: WorkRequest, change: TagChange) => void
    onTagReasonChanged: (workRequest: WorkRequest, tagReason: string) => void
}): JSX.Element {
    const data = useLastNonNull(props.data)
    const isVisible = props.data !== null
    if (data === null) return <Modal isVisible={isVisible} />

    return (
        <Modal isVisible={isVisible} useContentTag={true} onClose={onCancel}>
            <ModalContent>
                <ModalHeader>
                    <ModalHeaderTitle>Dismiss Service Request</ModalHeaderTitle>
                </ModalHeader>
                <ModalBody>
                    <Grid columns={1}>
                        <div>
                            Are you sure you wish to dismiss this Service Request? Dismissing a Service Request will eliminate
                            it from the queue of Service Requests. <strong>This action cannot be undone</strong>.
                        </div>
                        <i>{labelForWorkRequest(data.request)}</i>
                        <FormField label={"Reason*"}>
                            <TextArea
                                onChange={(value) => onReasonChanged(data.request, value)}
                                value={data.reason}
                                maxLength={250}
                            />
                        </FormField>

                        {assetTag && (
                            <FormFieldGroup label="Change Tag">
                                <FormField>
                                    <Grid columns={3} gap={GridGap.Normal}>
                                        <Radio
                                            label={"Remove Tag"}
                                            isChecked={data.tagChange === TagChange.RemoveTag}
                                            onSelected={() => onTagChanged(data.request, TagChange.RemoveTag)}
                                        />
                                        <Radio
                                            label={"Red Tag"}
                                            isChecked={data.tagChange === TagChange.RedTag}
                                            onSelected={() => onTagChanged(data.request, TagChange.RedTag)}
                                        />
                                        <Radio
                                            label={"Yellow Tag"}
                                            isChecked={data.tagChange === TagChange.YellowTag}
                                            onSelected={() => onTagChanged(data.request, TagChange.YellowTag)}
                                        />
                                    </Grid>
                                </FormField>
                                <i>{labelForWorkRequest(data.request)}</i>
                                <FormField label={"Tag Reason"}>
                                    <TextArea
                                        onChange={(value) => onTagReasonChanged(data.request, value)}
                                        value={data.tagReason}
                                        maxLength={250}
                                        disabled={data.tagChange === tagChangeFromTag(assetTag)}
                                    />
                                </FormField>
                            </FormFieldGroup>
                        )}
                    </Grid>
                </ModalBody>
                <ModalButtons>
                    <Button label="Cancel" type={ButtonType.Text} onClick={onCancel} />
                    <Button
                        label="Dismiss Service Request"
                        isEnabled={data.reason.trim() !== ""}
                        onClick={() => onConfirm()}
                    />
                </ModalButtons>
            </ModalContent>
        </Modal>
    )
}
