import { Strings } from "@lib/Strings"
import { Urgency } from "@model/Urgency"
import { WorkOrder } from "@model/workOrders/WorkOrder"

export type AddWorkRequestToWorkOrderForm = {
    urgency: Urgency
    dueDate: Date
    dueHourMeter: string
    dueOdometer: string
    specialInstructions: string
}

export function newAddWorkRequestToWorkOrderForm(workOrder?: WorkOrder): AddWorkRequestToWorkOrderForm {
    return {
        urgency: workOrder?.urgency ?? Urgency.Medium,
        dueDate: workOrder?.dueDate ?? new Date(),
        dueHourMeter: Strings.formatFloat(workOrder?.dueHourMeter ?? ""),
        dueOdometer: Strings.formatInteger(workOrder?.dueOdometer ?? ""),
        specialInstructions: workOrder?.specialInstructions ?? "",
    }
}
