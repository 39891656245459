import { BlocCoordinator } from "@lib/bloc/BlocCoordinator"
import { arrayOfNotNull } from "@lib/TypeUtil"
import {
    Filter,
    filterContainsValue,
    FilterUpdateType,
    filterValueForUrgency,
    filterValueForWorkRequestType,
} from "@model/filters/Filter"
import { ServiceFormType } from "@model/serviceRequests/ServiceForm"
import { Urgency } from "@model/Urgency"
import { PermissionObject, PermissionType } from "@model/user/User"
import { WorkRequestFilter, WorkRequestQuickFilterCount, WorkRequestType } from "@model/workRequests/WorkRequest"
import { SortableTableBlocEffect } from "@state/SortableTableBloc"
import { UserBloc, UserState } from "@state/user/UserBloc"
import { ServiceRequestCreationBloc } from "@state/workRequests/ServiceRequestCreationBloc"
import {
    WorkRequestsQuickFiltersBloc,
    WorkRequestsQuickFiltersState,
} from "@state/workRequests/WorkRequestsQuickFiltersBloc"
import { WorkRequestsTableBloc, WorkRequestsTableState } from "@state/workRequests/WorkRequestsTableBloc"

type Dependencies = [WorkRequestsQuickFiltersState, WorkRequestsTableState, UserState]

type StateSelection = {
    counts: Record<WorkRequestQuickFilterCount, number>
    isPreventativeToggleActive: boolean
    isServiceToggleActive: boolean
    isInspectionToggleActive: boolean
    isYellowTagToggleActive: boolean
    isRedTagToggleActive: boolean
    isImmediateToggleActive: boolean
    isOverdueToggleActive: boolean
    isLoading: boolean
}

export class WorkRequestsQuickFiltersViewModel extends BlocCoordinator<Dependencies, StateSelection> {
    constructor(
        private quickFiltersBloc: WorkRequestsQuickFiltersBloc,
        private tableBloc: WorkRequestsTableBloc,
        private serviceRequestCreationBloc: ServiceRequestCreationBloc,
        private userBlock: UserBloc
    ) {
        super([quickFiltersBloc, tableBloc, userBlock])
    }

    protected transform = ([quickFiltersState, tableState, userState]: Dependencies): StateSelection => ({
        counts: tableState.counts,
        isPreventativeToggleActive: filterContainsValue(tableState.filters[WorkRequestFilter.WorkType], [
            WorkRequestType.Preventative,
        ]),
        isServiceToggleActive: filterContainsValue(tableState.filters[WorkRequestFilter.WorkType], [
            WorkRequestType.Service,
        ]),
        isInspectionToggleActive: filterContainsValue(tableState.filters[WorkRequestFilter.WorkType], [
            WorkRequestType.Inspection,
        ]),
        isYellowTagToggleActive: filterContainsValue(tableState.filters[WorkRequestFilter.YellowTag], [true]),
        isRedTagToggleActive: filterContainsValue(tableState.filters[WorkRequestFilter.RedTag], [true]),
        isImmediateToggleActive: filterContainsValue(tableState.filters[WorkRequestFilter.Urgency], [
            Urgency.Immediate,
        ]),
        isOverdueToggleActive: filterContainsValue(tableState.filters[WorkRequestFilter.Overdue], [true]),
        isLoading: tableState.effectStatus[SortableTableBlocEffect.Fetch].isBusy(),
    })

    updateQuickFilterSelection(selection: WorkRequestQuickFilterCount[]) {
        const updatedFilters: Record<WorkRequestFilter, Filter<WorkRequestFilter>> = {
            ...this.tableBloc.state.filters,
            ...this.tableBloc.createUpdatedFilter(
                WorkRequestFilter.WorkType,
                arrayOfNotNull([
                    selection.includes(WorkRequestQuickFilterCount.Inspection)
                        ? filterValueForWorkRequestType(WorkRequestType.Inspection)
                        : null,
                    selection.includes(WorkRequestQuickFilterCount.Preventative)
                        ? filterValueForWorkRequestType(WorkRequestType.Preventative)
                        : null,
                    selection.includes(WorkRequestQuickFilterCount.Service)
                        ? filterValueForWorkRequestType(WorkRequestType.Service)
                        : null,
                ]),
                FilterUpdateType.Set
            ),
            ...this.tableBloc.createUpdatedFilter(
                WorkRequestFilter.YellowTag,
                [{ value: selection.includes(WorkRequestQuickFilterCount.YellowTag) }],
                FilterUpdateType.Set
            ),
            ...this.tableBloc.createUpdatedFilter(
                WorkRequestFilter.RedTag,
                [{ value: selection.includes(WorkRequestQuickFilterCount.RedTag) }],
                FilterUpdateType.Set
            ),
            ...this.tableBloc.createUpdatedFilter(
                WorkRequestFilter.Urgency,
                [filterValueForUrgency(Urgency.Immediate)],
                selection.includes(WorkRequestQuickFilterCount.Immediate)
                    ? FilterUpdateType.Add
                    : FilterUpdateType.Remove
            ),
            ...this.tableBloc.createUpdatedFilter(
                WorkRequestFilter.Overdue,
                [{ value: selection.includes(WorkRequestQuickFilterCount.Overdue) }],
                FilterUpdateType.Set
            ),
            ...this.tableBloc.createUpdatedFilter(
                WorkRequestFilter.Upcoming,
                [{ value: selection.includes(WorkRequestQuickFilterCount.Upcoming) }],
                FilterUpdateType.Set
            ),
        }

        this.tableBloc.applyFiltersAndFetch(updatedFilters)
    }

    toggleQuickFilter(countType: WorkRequestQuickFilterCount, isActive: boolean) {
        const updatedFilter = (() => {
            switch (countType) {
                case WorkRequestQuickFilterCount.Preventative:
                    return this.tableBloc.createUpdatedFilter(
                        WorkRequestFilter.WorkType,
                        [filterValueForWorkRequestType(WorkRequestType.Preventative)],
                        isActive ? FilterUpdateType.Remove : FilterUpdateType.Add
                    )
                case WorkRequestQuickFilterCount.Service:
                    return this.tableBloc.createUpdatedFilter(
                        WorkRequestFilter.WorkType,
                        [filterValueForWorkRequestType(WorkRequestType.Service)],
                        isActive ? FilterUpdateType.Remove : FilterUpdateType.Add
                    )
                case WorkRequestQuickFilterCount.Inspection:
                    return this.tableBloc.createUpdatedFilter(
                        WorkRequestFilter.WorkType,
                        [filterValueForWorkRequestType(WorkRequestType.Inspection)],
                        isActive ? FilterUpdateType.Remove : FilterUpdateType.Add
                    )
                case WorkRequestQuickFilterCount.YellowTag:
                    return this.tableBloc.createUpdatedFilter(
                        WorkRequestFilter.YellowTag,
                        [{ value: !isActive }],
                        FilterUpdateType.Set
                    )
                case WorkRequestQuickFilterCount.RedTag:
                    return this.tableBloc.createUpdatedFilter(
                        WorkRequestFilter.RedTag,
                        [{ value: !isActive }],
                        FilterUpdateType.Set
                    )
                case WorkRequestQuickFilterCount.Immediate:
                    return this.tableBloc.createUpdatedFilter(
                        WorkRequestFilter.Urgency,
                        [filterValueForUrgency(Urgency.Immediate)],
                        isActive ? FilterUpdateType.Remove : FilterUpdateType.Add
                    )
                case WorkRequestQuickFilterCount.Overdue:
                    return this.tableBloc.createUpdatedFilter(
                        WorkRequestFilter.Overdue,
                        [{ value: !isActive }],
                        FilterUpdateType.Set
                    )
                case WorkRequestQuickFilterCount.Upcoming:
                    return this.tableBloc.createUpdatedFilter(
                        WorkRequestFilter.Upcoming,
                        [{ value: !isActive }],
                        FilterUpdateType.Set
                    )
                default:
                    return undefined
            }
        })()

        this.tableBloc.applyFiltersAndFetch({ ...this.tableBloc.state.filters, ...updatedFilter })
    }

    createServiceButtonClicked(formType: ServiceFormType) {
        this.serviceRequestCreationBloc.showModal(formType)
    }

    hasWorkRequestAddPermission = () =>
        this.userBlock.state.user.hasAccess(PermissionObject.WorkRequest, PermissionType.Add)
}
