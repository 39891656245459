import React from "react"

export type RemoveButtonProps = {
    onClick: () => void
    tooltip?: string
}

export function RemoveButton({ onClick, tooltip }: RemoveButtonProps) {
    return (
        <button className="button button--remove" onClick={onClick} title={tooltip}>
            Remove
        </button>
    )
}
