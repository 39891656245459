import { classNames } from "@lib/HtmlUtil"
import React from "react"
import { ArrowSmallIcon } from "../images/InlinedSvgs"

export type WidgetSelectProps = {
    options: SelectOption[]
    onChange?: (value: string) => void
} & Omit<
    React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>,
    "children" | "onChange"
>

export type SelectOption = {
    value: string
    label: string
}

export function WidgetSelect({ options = [], className, ...props }: WidgetSelectProps) {
    return (
        <div className={classNames("widget-select", className)}>
            <select {...props} onChange={(ev) => props.onChange?.(ev.currentTarget.value)}>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            <ArrowSmallIcon />
        </div>
    )
}
