export type AssetClass = {
    id: number
    categoryId: number
    name: string
    description: string
}

export function newAssetClass(): AssetClass {
    return {
        id: 0,
        categoryId: 0,
        name: "",
        description: "",
    }
}
