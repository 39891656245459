import React from "react"
import { PDF } from "./components/PDF"
import { InspectionWorkRequestPDF } from "./InspectionWorkRequestPDF"
import { PDFAsset } from "./models/PDFAsset"
import { PDFWorkOrder } from "./models/PDFWorkOrder"
import { PDFWorkRequestType } from "./models/PDFWorkRequest"
import { PreventativeWorkRequestPDF } from "./PreventativeWorkRequestPDF"
import { ServiceWorkRequestPDF } from "./ServiceRequestPDF"
import { WorkOrderSummaryPage } from "./WorkOrderSummaryPDF"

export type WorkOrderDetailsPDFProps = {
    asset: PDFAsset
    workOrder: PDFWorkOrder
    companyName: string
    companyLogoUrl: string | null
}

export function WorkOrderDetailsPDF(props: WorkOrderDetailsPDFProps): JSX.Element {
    return (
        <PDF>
            <WorkOrderSummaryPage workOrder={props.workOrder} companyName={props.companyName} asset={props.asset} companyLogoUrl={props.companyLogoUrl} />
            {props.workOrder.workRequests.map((it, i) => {
                switch (it.type) {
                    case PDFWorkRequestType.Preventative:
                        return <PreventativeWorkRequestPDF key={i} workRequest={it} {...props} />
                    case PDFWorkRequestType.Inspection:
                        return <InspectionWorkRequestPDF key={i} workRequest={it} {...props} />
                    case PDFWorkRequestType.Service:
                        return <ServiceWorkRequestPDF key={i} workRequest={it} {...props} />
                }
            })}
        </PDF>
    )
}
