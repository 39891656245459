import { useBlocCoordinator } from "@lib/bloc/hooks"
import { useIsInIframe, useIsMobile } from "@lib/Hooks"
import { Color } from "@model/Color"
import { labelForContact } from "@model/contacts/Contact"
import { filterContainsValue, textFilterValue } from "@model/filters/Filter"
import { WorkOrderFilter, WorkOrderQuickFilterCount } from "@model/workOrders/WorkOrder"
import { WorkOrderFilterDefinitions } from "@model/workOrders/WorkOrderFilterDefinitions"
import { Button } from "@ui/common/buttons/Button"
import { CircleButton } from "@ui/common/buttons/CircleButton"
import { QuickFilters, QuickFiltersButtons } from "@ui/common/filters/QuickFilters"
import { FormField, FormFieldDirection } from "@ui/common/form/FormField"
import { MultiSelect, MultiSelectTheme } from "@ui/common/form/MultiSelect"
import { TextInput, TextInputType } from "@ui/common/form/TextInput"
import { Grid } from "@ui/common/grids/Grid"
import { FilterIcon } from "@ui/common/images/InlinedSvgs"
import { LoadingIndicator } from "@ui/common/LoadingIndicator"
import { Modal, ModalContent } from "@ui/common/Modal"
import { DI } from "@ui/DI"
import React, { useEffect } from "react"

export function WorkOrdersQuickFilters(): JSX.Element {
    const isMobile = useIsMobile() || useIsInIframe()
    const [state, viewModel] = useBlocCoordinator(() => DI.workOrdersQuickFiltersViewModel())

    return (
        <QuickFilters>
            <QuickFiltersButtons>
                <CircleButton
                    bottomLabel="Work Orders"
                    circleLabel={state.counts[WorkOrderQuickFilterCount.WorkOrders]}
                />
                <CircleButton
                    bottomLabel="Pending"
                    isActive={state.isPendingToggleActive}
                    circleLabel={state.counts[WorkOrderQuickFilterCount.Pending]}
                    onClick={() =>
                        viewModel.toggleQuickFilter(WorkOrderQuickFilterCount.Pending, state.isPendingToggleActive)
                    }
                />
                <CircleButton
                    bottomLabel="Open"
                    isActive={state.isOpenToggleActive}
                    circleLabel={state.counts[WorkOrderQuickFilterCount.Open]}
                    onClick={() =>
                        viewModel.toggleQuickFilter(WorkOrderQuickFilterCount.Open, state.isOpenToggleActive)
                    }
                />
                <CircleButton
                    bottomLabel="In Progress"
                    isActive={state.isInProgressToggleActive}
                    circleLabel={state.counts[WorkOrderQuickFilterCount.InProgress]}
                    onClick={() =>
                        viewModel.toggleQuickFilter(
                            WorkOrderQuickFilterCount.InProgress,
                            state.isInProgressToggleActive
                        )
                    }
                />
                <CircleButton
                    bottomLabel="Completed"
                    isActive={state.isCompletedToggleActive}
                    circleLabel={state.counts[WorkOrderQuickFilterCount.Completed]}
                    {...(state.counts[WorkOrderQuickFilterCount.Completed] > 0 ? { color: Color.Green } : {})}
                    onClick={() =>
                        viewModel.toggleQuickFilter(WorkOrderQuickFilterCount.Completed, state.isCompletedToggleActive)
                    }
                />
                <CircleButton
                    bottomLabel="Red Tag"
                    isActive={state.isRedTagToggleActive}
                    circleLabel={state.counts[WorkOrderQuickFilterCount.RedTag]}
                    {...(state.counts[WorkOrderQuickFilterCount.RedTag] > 0 ? { color: Color.Red } : {})}
                    onClick={() =>
                        viewModel.toggleQuickFilter(WorkOrderQuickFilterCount.RedTag, state.isRedTagToggleActive)
                    }
                />
                <CircleButton
                    bottomLabel="Yellow Tag"
                    isActive={state.isYellowTagToggleActive}
                    circleLabel={state.counts[WorkOrderQuickFilterCount.YellowTag]}
                    {...(state.counts[WorkOrderQuickFilterCount.YellowTag] > 0 ? { color: Color.Yellow } : {})}
                    onClick={() =>
                        viewModel.toggleQuickFilter(WorkOrderQuickFilterCount.YellowTag, state.isYellowTagToggleActive)
                    }
                />
                <CircleButton
                    bottomLabel="Immediate"
                    isActive={state.isImmediateToggleActive}
                    circleLabel={state.counts[WorkOrderQuickFilterCount.Immediate]}
                    {...(state.counts[WorkOrderQuickFilterCount.Immediate] > 0 ? { color: Color.Salmon } : {})}
                    onClick={() =>
                        viewModel.toggleQuickFilter(WorkOrderQuickFilterCount.Immediate, state.isImmediateToggleActive)
                    }
                />
                <CircleButton
                    bottomLabel="Overdue"
                    isActive={state.isOverdueToggleActive}
                    circleLabel={state.counts[WorkOrderQuickFilterCount.Overdue]}
                    {...(state.counts[WorkOrderQuickFilterCount.Overdue] > 0 ? { color: Color.Orange } : {})}
                    onClick={() =>
                        viewModel.toggleQuickFilter(WorkOrderQuickFilterCount.Overdue, state.isOverdueToggleActive)
                    }
                />
                <CircleButton
                    bottomLabel="High"
                    isActive={state.isHighToggleActive}
                    circleLabel={state.counts[WorkOrderQuickFilterCount.High]}
                    onClick={() =>
                        viewModel.toggleQuickFilter(WorkOrderQuickFilterCount.High, state.isHighToggleActive)
                    }
                />
                <CircleButton
                    bottomLabel="Medium"
                    isActive={state.isMediumToggleActive}
                    circleLabel={state.counts[WorkOrderQuickFilterCount.Medium]}
                    onClick={() =>
                        viewModel.toggleQuickFilter(WorkOrderQuickFilterCount.Medium, state.isMediumToggleActive)
                    }
                />
                <CircleButton
                    bottomLabel="Low"
                    isActive={state.isLowToggleActive}
                    circleLabel={state.counts[WorkOrderQuickFilterCount.Low]}
                    onClick={() => viewModel.toggleQuickFilter(WorkOrderQuickFilterCount.Low, state.isLowToggleActive)}
                />
            </QuickFiltersButtons>
            {!isMobile ? (
                <WorkOrderStatusFilter isMobile={false} />
            ) : (
                <>
                    <div
                        className="work-orders-quick-filters-mobile-filter-button"
                        onClick={() => viewModel.showMobileFilterModal(true)}
                    >
                        Filters
                    </div>
                    <Modal
                        isVisible={state.isMobileFilterModalOpen}
                        title={"Filters"}
                        closeOnExternalClick={true}
                        onClose={() => viewModel.showMobileFilterModal(false)}
                        buttons={[
                            {
                                isEnabled: viewModel.isSearchFilterValid(),
                                onClick: () => {
                                    viewModel.applySearchFilter()
                                    viewModel.showMobileFilterModal(false)
                                },
                                label: "Done",
                            },
                        ]}
                    >
                        <ModalContent>
                            <Grid columns={1}>
                                <WorkOrderStatusFilter isMobile={true} />
                                <FormField label={WorkOrderFilterDefinitions[WorkOrderFilter.AssignedTo].categoryLabel}>
                                    <MultiSelect
                                        className="assigned-to-filter"
                                        options={state.mechanics.map((value) => ({
                                            key: value.id,
                                            value: value,
                                            label: labelForContact(value),
                                            isChecked: filterContainsValue(state.assignedToFilter, [value.id]),
                                        }))}
                                        onSelectionChanged={(selected) =>
                                            viewModel.updateAssignedToFilter(
                                                selected.map((it) => ({ value: it.id, label: labelForContact(it) }))
                                            )
                                        }
                                    />
                                </FormField>
                                <FormField label="Asset ID, VIN, or Serial*" direction={FormFieldDirection.Column}>
                                    <div style={{ display: "flex", width: "100%", gap: "8px" }}>
                                        <TextInput
                                            onChange={(value) => viewModel.updateSearchFilter([{ value }])}
                                            type={TextInputType.Search}
                                            value={textFilterValue(state.searchFilter)}
                                            style={{ flex: "1 1 auto" }}
                                            pattern="[a-zA-Z0-9 ]*"
                                            inputMode="text"
                                        />
                                        <Button label="Search" onClick={() => viewModel.applySearchFilter()} />
                                    </div>
                                    {!viewModel.isSearchFilterValid() && (
                                        <div className="filter-overlay-search-error">
                                            Search must contain at least 4 characters
                                        </div>
                                    )}
                                </FormField>
                            </Grid>
                        </ModalContent>
                    </Modal>
                </>
            )}
            <LoadingIndicator isLoading={state.isLoading} backgroundColor={"#191e27bf"} loaderColor="#FFFFFF" />
        </QuickFilters>
    )
}

function WorkOrderStatusFilter({ isMobile }: { isMobile: boolean }): JSX.Element {
    const [state, viewModel] = useBlocCoordinator(() => DI.workOrdersQuickFiltersViewModel())
    const isInIframe = window !== window.parent;
    return (
        <FormField direction={FormFieldDirection.Column} label={isMobile ? "Quick Filters" : ""}>
            <MultiSelect
                className={`${isMobile || isInIframe ? 'quick-filter-dropdown' : ''}`}
                theme={isMobile ? MultiSelectTheme.Normal : MultiSelectTheme.Dark}
                options={[
                    {
                        isChecked: state.isPendingToggleActive,
                        label: "Pending",
                        value: WorkOrderQuickFilterCount.Pending,
                    },
                    {
                        isChecked: state.isOpenToggleActive,
                        label: "Open",
                        value: WorkOrderQuickFilterCount.Open,
                    },
                    {
                        isChecked: state.isInProgressToggleActive,
                        label: "In Progress",
                        value: WorkOrderQuickFilterCount.InProgress,
                    },
                    {
                        isChecked: state.isCompletedToggleActive,
                        label: "Completed",
                        value: WorkOrderQuickFilterCount.Completed,
                    },
                    {
                        isChecked: state.isRedTagToggleActive,
                        label: "Red Tag",
                        value: WorkOrderQuickFilterCount.RedTag,
                    },
                    {
                        isChecked: state.isYellowTagToggleActive,
                        label: "Yellow Tag",
                        value: WorkOrderQuickFilterCount.YellowTag,
                    },
                    {
                        isChecked: state.isImmediateToggleActive,
                        label: "Immediate",
                        value: WorkOrderQuickFilterCount.Immediate,
                    },
                    {
                        isChecked: state.isOverdueToggleActive,
                        label: "Overdue",
                        value: WorkOrderQuickFilterCount.Overdue,
                    },
                    {
                        isChecked: state.isHighToggleActive,
                        label: "High",
                        value: WorkOrderQuickFilterCount.High,
                    },
                    {
                        isChecked: state.isMediumToggleActive,
                        label: "Medium",
                        value: WorkOrderQuickFilterCount.Medium,
                    },
                    {
                        isChecked: state.isLowToggleActive,
                        label: "Low",
                        value: WorkOrderQuickFilterCount.Low,
                    },
                ]}
                onSelectionChanged={(selection) =>
                    viewModel.updateQuickFilterSelection(selection.map((count) => count))
                }
            />
        </FormField>
    )
}
