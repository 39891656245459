import React, { useEffect, useRef, useState } from "react"
import { Button, ButtonType } from "@ui/common/buttons/Button"
import { Grid, GridCont, GridGap } from "@ui/common/grids/Grid"
import {
    Modal,
    ModalBody,
    ModalButtons,
    ModalContent,
    ModalHeader,
    ModalHeaderSubTitle,
    ModalHeaderTitle,
    ModalSize,
} from "@ui/common/Modal"
import { ServiceQuote, ServiceQuoteItem, ServiceQuoteStatus } from "@model/serviceQuotes/ServiceQuote"
import { DI } from "@ui/DI"
import { PageInfoSectionDataFields } from "@ui/common/page/PageInfoSection"
import { DataField } from "@ui/common/DataField"
import { Routes } from "@lib/Routes"
import { Label } from "recharts"
import { ServiceQuoteStatusIndicator } from "./ServiceQuoteStatusIndicator"
import { FormField } from "@ui/common/form/FormField"
import { FormSection } from "@ui/common/form/FormSection"
import { ServiceQuoteStatusSelect } from "@ui/common/form/ServiceQuoteStatusSelect"
import { Icon } from "@material-ui/core"

type ServiceQuoteListProps = {
    ServiecQuoteId: number | undefined,
    isVisible: boolean
    onClose: () => void
}

type ServiceQuoteItemState = 
{
    id: number
    serviceQuoteId: number
    lineItemNumber: number
    name: string
    status: ServiceQuoteStatus    
    descriptionWorkToBePerformed: string
    laborHours: number | null
    laborCost: number
    partsCost: number
    miscCost: number
    totalCost: number
}

type ServiceQuoteState = {
    id: number,    
    number: string
    ServiceQuoteItems: ServiceQuoteItemState[]
}

function newServiceQuoteState() : ServiceQuoteState {
    return {
        id: -1,
        number: "",
        ServiceQuoteItems: []
    }
}

function mockUpServiceQuoteState() : ServiceQuoteState{
    return {
        id: 1,
        number: "12345",
        ServiceQuoteItems: [
            {
                descriptionWorkToBePerformed: "some regular description",
                serviceQuoteId:1,
                id: 1,
                laborCost: 1,
                laborHours:1,
                lineItemNumber: 12,
                miscCost: 1,
                name: "some name",
                partsCost: 1,
                status: ServiceQuoteStatus.Approved,
                totalCost: 120
            }
        ]

}

}

export function mapServiceQuoteToServiceQuoteState(serviceQuote: ServiceQuote): ServiceQuoteState {
    return {
        id: serviceQuote.id,
        number: serviceQuote.purchaseOrderNumber ?? "",
        ServiceQuoteItems: serviceQuote.serviceQuoteItems.map(mapServiceQuoteItemToServiceQuoteItemState)
    }
}

export function mapServiceQuoteItemToServiceQuoteItemState(serviceQuoteItem: ServiceQuoteItem): ServiceQuoteItemState {
    return {
            descriptionWorkToBePerformed: serviceQuoteItem.descriptionWorkToBePerformed,
            id: serviceQuoteItem.id,
            serviceQuoteId: serviceQuoteItem.serviceQuoteId,
            laborCost: serviceQuoteItem.totalLaborCost,
            laborHours:serviceQuoteItem.laborHours,
            lineItemNumber: serviceQuoteItem.lineItemNumber,
            miscCost: serviceQuoteItem.totalMiscCost ?? 0,
            name: "some name",//??needs definition
            partsCost: serviceQuoteItem.totalPartsCost ?? 0,
            status: serviceQuoteItem.status,
            totalCost: serviceQuoteItem.totalLaborCost + (serviceQuoteItem.totalMiscCost ?? 0 )+ (serviceQuoteItem.totalPartsCost ?? 0)
    }
}

export function ServiceQuoteListModal(props: ServiceQuoteListProps ) : JSX.Element
{
    const fetchData = async (servicQuoteId: number) => 
    {
        const result = await service.fetchServiceQuote(servicQuoteId)
        
        if(result.isOk())
        {
            setState(mapServiceQuoteToServiceQuoteState(result.value))
        }
    }

    const service = DI.serviceQuotesService()
    const [state, setState] = useState(newServiceQuoteState())
    const [isLoading, SetIsLoading] = useState(true)    

    useEffect(() => {
        SetIsLoading(true)
        
        if(props.ServiecQuoteId)
        {
            fetchData(props.ServiecQuoteId)
        }
            
        SetIsLoading(false)
    }, [props.ServiecQuoteId])

    return (
    <Modal useContentTag={true} isVisible={props.isVisible} onClose={props.onClose} size={ModalSize.Normal}>
        <ModalContent isLoading={isLoading}>
            <ModalHeader>
                <ModalHeaderTitle>Service Quote {state.number}</ModalHeaderTitle>
            </ModalHeader>
            <ModalBody>
                <Grid columns={1} gap={GridGap.Small}>
                    {state.ServiceQuoteItems.map(ServiceQuoteItemView)}
                </Grid>
            </ModalBody>
            <ModalButtons>
                <Button
                    label={"Close"}
                    type={ButtonType.Text}
                    onClick={props.onClose}
                />
            </ModalButtons>
        </ModalContent>
    </Modal>
    )
}

export function ServiceQuoteItemView(item: ServiceQuoteItemState) : JSX.Element
{
    return (
        <GridCont key={item.id} gap={GridGap.Small} className="service-quote-item-grid-element">
            <Grid columns={1} >
                <FormField>
                    Service Request {item.lineItemNumber} | <a href={"/service-quotes/" + item.serviceQuoteId.toString()} target="_blank">{item.name}</a>
                </FormField>
            </Grid>
            <Grid columns={1}>
                <FormField label="Status">
                <ServiceQuoteStatusSelect
                            status={item.status}    
                            onChange={() => {}}
                            disabled={true}
                        />
                </FormField>
            </Grid>
            <Grid columns={4} >
                <FormField label="Labor Cost">{formatMoneyField(item.laborCost)}</FormField>
                <FormField label="Parts Cost">{formatMoneyField(item.partsCost)} </FormField>
                <FormField label="Misc. Cost">{formatMoneyField(item.miscCost)} </FormField>
                <FormField label="Total Cost">{formatMoneyField(item.totalCost)}</FormField>
            </Grid>
            <Grid columns={1}>
                <FormField label="Description" >{item.descriptionWorkToBePerformed} </FormField>
            </Grid>
        </GridCont>
    )
}

export const formatMoneyField = (value: number | undefined) => <div>{'$'+ value?.toString()}</div>