export enum WorkRequestPrintOption {
    PendingWorkRequest = "pendingWorkRequest",
    Parts = "parts"
}

export function labelForWorkRequestPrintOption(option: WorkRequestPrintOption): string {
    switch (option) {
        case WorkRequestPrintOption.PendingWorkRequest:
            return "Pending Service Requests"
        case WorkRequestPrintOption.Parts:
            return "Parts List"
    }
}
