import { Debouncer } from "@lib/EventUtil"
import { GlobalKeyListener } from "@lib/GlobalKeyListener"
import { classNames, isNodeAncestor } from "@lib/HtmlUtil"
import { RecyclerList, RecyclerListRowProps } from "@ui/common/RecyclerList"
import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react"
import { TextInput, TextInputType } from "./TextInput"

export type SearchableTextInputProps<T> = {
    className?: string
    options?: SearchableTextInputOption<T>[]
    popupPreContent?: (callbacks: { close: () => void }) => React.ReactNode
    optionContent?: (props: {
        option: SearchableTextInputOption<T>
        index: number
        onOptionSelected: (option: SearchableTextInputOption<T>) => void
    }) => React.ReactNode
    optionContentHeight?: number
    value?: string
    placeholder?: string
    isLoading?: boolean
    debounceMillis?: number
    minSearchCharacters?: number
    emptyMessage?: string
    onSearchChanged?: (value: string) => void
    onOptionSelected?: (option: SearchableTextInputOption<T>) => void
    isEnabled?: boolean
}

export type SearchableTextInputOption<T> = {
    value: T
    key: string | number
    label: string
    pattern?: string
}

export function SearchableTextInput<T>({
    options = [],
    optionContent,
    popupPreContent,
    isLoading = false,
    debounceMillis = 0,
    minSearchCharacters = 0,
    optionContentHeight = 30,
    isEnabled = true,
    ...props
}: SearchableTextInputProps<T>): JSX.Element {
    const [isFocused, setIsFocused] = useState(false)
    const [isOverlayVisible, setIsOverlayVisible] = useState(false)
    const [search, setSearch] = useState(props.value)
    const containerRef = useRef<HTMLDivElement>(null)
    const valueRef = useRef<HTMLInputElement>(null)
    const overlayDebouncer = useMemo(() => new Debouncer(debounceMillis), [debounceMillis])
    const searchDebouncer = useMemo(() => new Debouncer(debounceMillis), [debounceMillis])

    const handleSearchChanged = useCallback(
        (search: string) => {
            if (search.trim() === "") return
            if (search.length < minSearchCharacters) return
            props.onSearchChanged?.(search)
        },
        [props.onSearchChanged, minSearchCharacters]
    )

    useLayoutEffect(() => {
        if (!isOverlayVisible) return

        const handleEscape = (ev: KeyboardEvent) => {
            if (ev.key !== "Escape") return false
            setSearch("")
            setIsOverlayVisible(false)
            valueRef.current?.focus()
            return true
        }

        GlobalKeyListener.enqueue(handleEscape)

        return () => {
            GlobalKeyListener.dequeue(handleEscape)
        }
    }, [isOverlayVisible])

    return (
        <div
            ref={containerRef}
            className={classNames(
                props.className,
                "searchable-text-input",
                isOverlayVisible ? "searchable-text-input--active" : null
            )}
            onBlur={(ev) => {
                if (isNodeAncestor(ev.relatedTarget as HTMLElement | null, containerRef.current, 10)) return
                setSearch("")
                setIsOverlayVisible(false)
                setIsFocused(false)
                overlayDebouncer.cancel()
                searchDebouncer.cancel()
            }}
            tabIndex={-1}
        >
            <TextInput
                focusRef={valueRef}
                type={TextInputType.Search}
                onFocus={() => setIsFocused(true)}
                placeholder={props.placeholder}
                isEnabled={isEnabled}
                onChange={(value) => {
                    setSearch(value)

                    if ((value?.trim() ?? "" !== "") && (value ?? "").length >= minSearchCharacters) {
                        overlayDebouncer.emit(() => setIsOverlayVisible(true))
                    }

                    if (debounceMillis > 0) {
                        searchDebouncer.emit(() => handleSearchChanged(value))
                    } else {
                        handleSearchChanged(value)
                    }
                }}
                value={isFocused ? search : props.value}
                pattern= "[a-zA-Z0-9 ]*"
                inputMode= "text"
            />
            {isOverlayVisible &&
                (isLoading ? (
                    <div className="searchable-text-input-overlay-cont">
                        <div className="searchable-text-input-overlay">
                            <div className="searchable-text-input-loader"></div>
                        </div>
                    </div>
                ) : options.length === 0 ? (
                    <div className="searchable-text-input-overlay-cont">
                        <div className="searchable-text-input-overlay">
                            <div
                                className="searchable-text-input-no-matches-found"
                                onClick={() => setIsOverlayVisible(false)}
                            >
                                {props.emptyMessage ?? "No available options"}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="searchable-text-input-overlay-cont">
                        {popupPreContent?.({
                            close: () => {
                                setSearch("")
                                setIsOverlayVisible(false)
                                setIsFocused(false)
                            },
                        })}
                        <RecyclerList
                            className="searchable-text-input-overlay"
                            itemCount={options.length}
                            itemHeight={optionContentHeight}
                            data={{
                                options,
                                optionContent,
                                onOptionSelected: (option: SearchableTextInputOption<T>) => {
                                    props.onOptionSelected?.(option)
                                    setSearch("")
                                    setIsOverlayVisible(false)
                                    setIsFocused(false)
                                },
                            }}
                        >
                            {optionContent ? CustomOption : Option}
                        </RecyclerList>
                    </div>
                ))}
        </div>
    )
}

function CustomOption<T>(
    props: RecyclerListRowProps<{
        options: SearchableTextInputOption<T>[]
        optionContent?: (props: {
            option: SearchableTextInputOption<T>
            index: number
            onOptionSelected: (option: SearchableTextInputOption<T>) => void
        }) => React.ReactNode
        onOptionSelected: (option: SearchableTextInputOption<T>) => void
    }>
): JSX.Element {
    const option = props.options[props.index]
    return (
        <div style={props.style} key={option.key} className="searchable-text-input-option" title={option.label}>
            {props.optionContent?.({
                option,
                index: props.index,
                onOptionSelected: props.onOptionSelected,
            })}
        </div>
    )
}

function Option<T>(
    props: RecyclerListRowProps<{
        options: SearchableTextInputOption<T>[]
        optionContent?: (props: {
            option: SearchableTextInputOption<T>
            index: number
            onOptionSelected: (option: SearchableTextInputOption<T>) => void
        }) => React.ReactNode
        onOptionSelected: (option: SearchableTextInputOption<T>) => void
    }>
): JSX.Element {
    const option = props.options[props.index]
    return (
        <button
            style={props.style}
            key={option.key}
            className="searchable-text-input-option"
            onClick={(ev) => props.onOptionSelected?.(option)}
            title={option.label}
        >
            {option.label}
        </button>
    )
}
