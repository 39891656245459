import { classNames } from "@lib/HtmlUtil"
import React from "react"

export type FormFieldGroupProps = {
    label?: string
    children?: React.ReactNode
    theme?: FormFieldGroupTheme
    className?: string
}

export enum FormFieldGroupTheme {
    Light = "light",
    Normal = "normal",
}

export function FormFieldGroup({
    className,
    children,
    label,
    theme = FormFieldGroupTheme.Normal,
}: FormFieldGroupProps): JSX.Element {
    return (
        <div className={classNames("form-field-group", className)} data-theme={theme}>
            {label && <div className="form-field-group-label">{label}</div>}
            <div className="form-field-group-content">{children}</div>
        </div>
    )
}
