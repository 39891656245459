import { arrayOfNotNull } from "./TypeUtil"

export const classNames = (...names: (string | undefined | null)[]) =>
    arrayOfNotNull(names)
        .filter((name) => name !== "")
        .join(" ")

export function offsetTop(src: HTMLElement): number {
    let el = src.offsetParent
    let offset = src.offsetTop

    while (el !== null) {
        offset += (el as HTMLElement).offsetTop
        el = (el as HTMLElement).offsetParent
    }

    return offset
}

export function isNodeAncestor(src: HTMLElement | null, container: HTMLElement | null, maxLevel: number = 5): boolean {
    for (let i = maxLevel; i >= 0; i--) {
        if (src === null || container === null) return false
        if (src === container) return true
        src = src?.parentElement ?? null
    }
    return false
}

export function isNodeAncestorMatch(src: HTMLElement | null, matcher: string, maxLevel: number = 5): boolean {
    for (let i = maxLevel; i >= 0; i--) {
        if (src === null) return false
        if (src.matches(matcher)) return true
        src = src?.parentElement ?? null
    }
    return false
}
