import { Outcome } from "@ethossoftworks/outcome"
import { Bloc } from "@lib/bloc/Bloc"
import { Strings } from "@lib/Strings"
import { arrayToObject, safeParseAndRoundFloat, safeParseFloat, safeParseInt } from "@lib/TypeUtil"
import { Asset } from "@model/assets/Asset"
import { AsyncStatus } from "@model/AsyncStatus"
import { Attachment } from "@model/Attachment"
import { BillingCode } from "@model/company/BillingCode"
import { HistoryItem } from "@model/HistoryItem"
import { ServiceCode, ServiceRequestType } from "@model/serviceRequests/ServiceRequest"
import { Step } from "@model/Step"
import { Tag, tagChangeFromTag, TagType } from "@model/Tag"
import { CompletableTaskValue, InspectionTaskValue, Task, TaskValue } from "@model/Task"
import { Urgency } from "@model/Urgency"
import { WorkRequest, WorkRequestStatus, WorkRequestType } from "@model/workRequests/WorkRequest"
import { AssetService } from "@service/assets/AssetService"
import { WorkRequestService } from "@service/workRequests/WorkRequestService"
import { v4 as uuidv4 } from "uuid"

export type WorkRequestDetailsScreenState = {
    readonly effectStatus: Record<WorkRequestDetailsEffect, AsyncStatus>
    readonly form: WorkRequestDetailsForm
    readonly steps: Step[]
    readonly tasks: Task[] | null
    readonly workRequest: WorkRequest | null
    readonly asset: Asset | null
    showTaskDescriptions: boolean
}

export type WorkRequestDetailsForm = {
    readonly billingCodeId: number | null
    readonly urgency: Urgency
    readonly dueDate: Date | null
    readonly dueHourMeter: string
    readonly dueOdometer: string
    readonly estimatedLaborHours: string
    readonly estimatedLaborCost: string
    readonly estimatedPartsCost: string
    readonly workToBePerformed: string
    readonly specialInstructions: string
    readonly notes: string
    readonly tag: Tag | null
    readonly tasks: Task[] | null
    readonly attachments: Attachment[]
    readonly step: Step | null

    readonly serviceType: ServiceRequestType | null
    readonly serviceCodeId: number | null
    filesId: string | null
    hadFilesId: boolean
    newAttachmentsNames: string[]
    notifyContacts: number[]
    readonly isUnplanned: boolean
}

export enum WorkRequestDetailsEffect {
    FetchData,
    UpdateWorkRequest,
    FetchWorkRequestHistory,
}

type FieldType<T extends keyof WorkRequestDetailsForm> = WorkRequestDetailsForm[T]

export class WorkRequestDetailsScreenBloc extends Bloc<WorkRequestDetailsScreenState> {
    constructor(private workRequestService: WorkRequestService, private assetService: AssetService) {
        super(newWorkRequestDetailsState(), { persistStateOnDispose: false })
    }

    tagTypeFormFieldChanged = (value: TagType | null) => {
        if (value === null) {
            this.formFieldChanged("tag", null)
            return
        }

        let tagReason: string = this.state.form.tag?.reason ?? ""
        if (value == this.state.asset!.tag?.type) {
            //Discard tag reason changes in case the user updates the reason and come back to the original state.
            tagReason = this.state.asset!.tag?.reason ?? ""
        }

        var tagObj = {
            type: value,
            dateTagged: new Date(),
            daysTagged: 0,
            reason: tagReason,
        }
        this.formFieldChanged("tag", tagObj)
    }

    tagReasonFormFieldChanged = (value: string) => {
        if (this.state.form.tag === null) return

        this.formFieldChanged("tag", {
            ...this.state.form.tag,
            reason: value,
        })
    }

    requestNotifyContactChanged = (contactIds: number[]) => this.formFieldChanged("notifyContacts", contactIds)

    toggleShowTaskDescriptions = () => this.update({ showTaskDescriptions: !this.state.showTaskDescriptions })

    requestSelectAllTasksToggled = () => {
        const request = this.state.workRequest

        if (!request) return {}

        const allCompleted = this.state.form.tasks?.every(
            (it) => it.value === CompletableTaskValue.Performed || it.value === InspectionTaskValue.Pass
        )

        if (this.state.form.tasks)
            return this.formFieldChanged(
                "tasks",
                this.state.form.tasks.map((it) => ({
                    ...it,
                    value: (() => {
                        if (request.workRequestType === WorkRequestType.Inspection) {
                            return !allCompleted ? InspectionTaskValue.Pass : InspectionTaskValue.NA
                        } else {
                            return !allCompleted ? CompletableTaskValue.Performed : CompletableTaskValue.NA
                        }
                    })(),
                }))
            )

        return null
    }

    requestTaskChanged = (taskId: number, value: TaskValue, measurement?: string) => {
        if (this.state.form.tasks)
            this.formFieldChanged(
                "tasks",
                this.state.form.tasks.map((it) => {
                    if (it.id !== taskId) return it
                    return { ...it, value, measurement }
                })
            )
    }
    stepChanged = async (value: number) => {
        const step = this.state.workRequest?.schedule?.steps.find((item) => item.id === value)
        if (!step) return
        this.formFieldChanged("step", step)
        this.formFieldChanged("workToBePerformed", step?.label)

        // When WR is not added to a WO, it should get the tasks from the service schedule step
        if (this.state.workRequest && !this.state.workRequest.workOrderId && step) {
            let tasks = await this.workRequestService.fetchServiceScheduleStepTasks(
                step.id,
                this.state.workRequest.workRequestType
            )
            this.formFieldChanged("tasks", tasks)
        }
    }

    billingCodeChanged = (value: BillingCode | null) => this.formFieldChanged("billingCodeId", value?.id ?? null)
    serviceCodeChanged = (value: ServiceCode | null) => this.formFieldChanged("serviceCodeId", value?.id ?? null)
    urgencyChanged = (value: Urgency) => this.formFieldChanged("urgency", value)
    dueHourMeterChanged = (value: string) => this.formFieldChanged("dueHourMeter", value)
    dueOdometerChanged = (value: string) => this.formFieldChanged("dueOdometer", value)
    estimatedLaborHoursChanged = (value: string) => this.formFieldChanged("estimatedLaborHours", value)
    estimatedLaborCostChanged = (value: string) => this.formFieldChanged("estimatedLaborCost", value)
    estimatedPartsCostChanged = (value: string) => this.formFieldChanged("estimatedPartsCost", value)
    notesChanged = (value: string) => this.formFieldChanged("notes", value)
    specialInstructionsChanged = (value: string) => this.formFieldChanged("specialInstructions", value)

    serviceTypeChanged = (value: ServiceRequestType) => this.formFieldChanged("serviceType", value)
    workToBePerformedChanged = (value: string) => this.formFieldChanged("workToBePerformed", value)

    dueDateFormFieldChanged = (date: Date) => this.formFieldChanged("dueDate", date)

    private formFieldChanged = <T extends keyof WorkRequestDetailsForm>(field: T, value: FieldType<T>) =>
        this.update({
            form: {
                ...this.state.form,
                [field]: value,
            },
        })

    fetchData = (workRequestId: number, assetId: number) =>
        this.effect({
            id: WorkRequestDetailsEffect.FetchData,
            block: async (job) => {
                this.effectStatusUpdated(WorkRequestDetailsEffect.FetchData, AsyncStatus.busy())
                const [workRequest, asset] = await job.pause(
                    Promise.all([
                        this.workRequestService.fetchWorkRequest(workRequestId),
                        this.assetService.fetchAsset(assetId),
                    ])
                )

                if (workRequest.isError() || asset.isError()) {
                    this.effectStatusUpdated(WorkRequestDetailsEffect.FetchData, AsyncStatus.error(undefined))
                    return Outcome.error(undefined)
                }

                this.dataLoaded(workRequest.value, asset.value)
                this.effectStatusUpdated(WorkRequestDetailsEffect.FetchData, AsyncStatus.idle())
                return Outcome.ok(undefined)
            },
        })

    fetchHistoryData = (workRequestId: number) =>
        this.effect({
            id: WorkRequestDetailsEffect.FetchWorkRequestHistory,
            block: async (job) => {
                this.effectStatusUpdated(WorkRequestDetailsEffect.FetchWorkRequestHistory, AsyncStatus.busy())
                const [workRequestHistory] = await job.pause(
                    Promise.all([this.workRequestService.fetchWorkRequestHistory(workRequestId)])
                )

                if (workRequestHistory.isError()) {
                    this.effectStatusUpdated(
                        WorkRequestDetailsEffect.FetchWorkRequestHistory,
                        AsyncStatus.error(undefined)
                    )
                    return Outcome.error(undefined)
                }

                this.historyLoaded(workRequestHistory.value)
                this.effectStatusUpdated(WorkRequestDetailsEffect.FetchWorkRequestHistory, AsyncStatus.idle())
                return Outcome.ok(undefined)
            },
        })

    updateWorkRequest = () =>
        this.effect({
            id: WorkRequestDetailsEffect.UpdateWorkRequest,
            block: async (job): Promise<Outcome<{ updatedWorkRequest: WorkRequest; updatedAsset: Asset | null }>> => {
                if (this.state.workRequest === null) return Outcome.error("Service Request is null")

                this.effectStatusUpdated(WorkRequestDetailsEffect.UpdateWorkRequest, AsyncStatus.busy())

                // Update tag if necessary
                const formTagChange = tagChangeFromTag(this.state.form.tag)
                const originalTagChange = tagChangeFromTag(this.state.asset?.tag ?? null)
                var updatedAsset = null
                if (!(originalTagChange === formTagChange || this.state.asset === null)) {
                    const tagReason = this.state.form.tag?.reason ?? ""
                    updatedAsset = await job.pause(
                        this.assetService.updateTag(this.state.asset.id, formTagChange, tagReason)
                    )

                    if (updatedAsset.isError()) {
                        this.effectStatusUpdated(WorkRequestDetailsEffect.UpdateWorkRequest, AsyncStatus.idle())
                        return updatedAsset
                    }
                }

                const form = this.state.form
                const workRequest = await job.pause(
                    this.workRequestService.updateWorkRequest({
                        ...this.state.workRequest,
                        ...(this.state.workRequest.workRequestType === WorkRequestType.Service
                            ? {
                                  serviceType: form.serviceType,
                              }
                            : {
                                  step: form.step,
                                  stepId: form.step,
                                  billingCode: form.billingCodeId,
                                  specialInstructions: form.specialInstructions,
                              }),
                        serviceCodeId: form.serviceCodeId,
                        attachments: form.attachments,
                        workToBePerformed: form.workToBePerformed,
                        dueDate: form.dueDate,
                        dueHourMeter: form.dueHourMeter != null ? safeParseFloat(form.dueHourMeter) : null,
                        dueOdometer: form.dueOdometer != null ? safeParseInt(form.dueOdometer) : null,
                        estimatedLaborCost: safeParseFloat(form.estimatedLaborCost),
                        estimatedLaborHours: safeParseAndRoundFloat(form.estimatedLaborHours),
                        estimatedPartsCost: safeParseFloat(form.estimatedPartsCost),
                        specialInstructions: form.specialInstructions,
                        notes: form.notes,
                        tasks: this.state.workRequest.workOrderId ? form.tasks : null, // We only update tasks to the WR if it is assigned to a WO
                        tag: updatedAsset ? updatedAsset.value.tag : null,
                        urgency: form.urgency,
                        filesId: form.filesId as string,
                        notifyContacts: form.notifyContacts,
                        isUnplanned: form.isUnplanned,
                    })
                )

                if (workRequest.isError()) {
                    this.effectStatusUpdated(WorkRequestDetailsEffect.UpdateWorkRequest, AsyncStatus.idle())
                    return workRequest
                }

                this.effectStatusUpdated(WorkRequestDetailsEffect.UpdateWorkRequest, AsyncStatus.idle())
                return Outcome.ok({
                    updatedWorkRequest: workRequest.value,
                    updatedAsset: updatedAsset ? updatedAsset.value : null,
                })
            },
        })

    private dataLoaded = (workRequest: WorkRequest, asset: Asset) =>
        this.update({
            workRequest,
            asset,
            form: {
                ...this.state.form,
                attachments: workRequest.attachments,
                billingCodeId: workRequest.billingCodeId,
                workToBePerformed: workRequest.workToBePerformed,
                dueDate: workRequest.dueDate,
                dueHourMeter: workRequest.dueHourMeter != null ? Strings.formatDecimal(workRequest.dueHourMeter) : "",
                dueOdometer: workRequest.dueOdometer != null ? Strings.formatInteger(workRequest.dueOdometer) : "",
                estimatedLaborCost: Strings.formatMoney(workRequest.estimatedLaborCost),
                estimatedLaborHours: Strings.formatFloat(workRequest.estimatedLaborHours),
                estimatedPartsCost: Strings.formatMoney(workRequest.estimatedPartsCost),
                tasks: workRequest.tasks ?? [],
                specialInstructions: workRequest.specialInstructions,
                notes: workRequest.notes,
                step: workRequest.step,
                tag: asset.tag,
                urgency: workRequest.urgency,
                serviceCodeId: workRequest.serviceCodeId,
                serviceType: workRequest.serviceType,
                filesId: workRequest.filesId,
                hadFilesId: workRequest.filesId != null && workRequest.filesId.length > 0 ? true : false,
                newAttachmentsNames: [],
                notifyContacts: workRequest.notifyContacts,
            },
        })

    private historyLoaded = (workRequestHistory: HistoryItem<WorkRequestStatus>[]) => {
        let tempworkRequest = this.state.workRequest
        if (tempworkRequest) tempworkRequest.history = workRequestHistory

        this.update({ workRequest: tempworkRequest })
    }

    private effectStatusUpdated = (effect: WorkRequestDetailsEffect, status: AsyncStatus) =>
        this.update({
            effectStatus: {
                ...this.state.effectStatus,
                [effect]: status,
            },
        })

    attachmentsChanged = async (files: File[], formData: FormData, userId: number, companyId: number) => {
        try {
            this.state.form.newAttachmentsNames = Array.from(files)
                .filter((fileToUpload) => {
                    return this.state.form.attachments.findIndex((file) => file.name == fileToUpload.name) == -1
                })
                .map((i) => i.name)

            const filesId: string = this.getFilesId()

            const response = await this.workRequestService.uploadAttachments(filesId, files, userId, companyId)

            if (response.isOk()) {
                this.formFieldChanged("attachments", this.state.form.attachments.concat(response.value))
                this.formFieldChanged("filesId", filesId)
            }

            return response
        } catch (error) {
            console.error("Error in attachmentsChanged:", error)
            return error
        }
    }

    attachmentsDelete = (attachmentName: string, userId: number, companyId: number) => {
        const fileId: string = this.getFilesId()
        this.effect({
            id: this.attachmentsDelete,
            block: async (job) => {
                if (!attachmentName || !fileId) return Outcome.error(undefined)
                const response = await job.pause(this.workRequestService.deleteAttachments(fileId, [attachmentName]))
                if (response.isOk()) {
                    this.formFieldChanged(
                        "attachments",
                        this.state.form.attachments.filter((i) => i.name != attachmentName)
                    )
                }
                return response
            },
        })
    }

    rotateImage = (attachmentName: string, angle: number, userId: number, companyId: number) => {
        const fileId: string = this.getFilesId()
        this.effect({
            id: this.rotateImage,
            block: async (job) => {
                if (!attachmentName || !fileId) return Outcome.error(undefined)
                const response = await job.pause(this.workRequestService.rotateImage(fileId, attachmentName, angle))
                let attachments = JSON.parse(JSON.stringify(this.state.form.attachments))
                let modified = attachments.find(
                    (x: any) => x.name.toLowerCase().trim() === attachmentName.toLowerCase().trim()
                )
                modified.url = modified.url.split("&rotated=")[0] + "&rotated=" + new Date().getTime()

                this.formFieldChanged("attachments", [...attachments, { ...modified }])
                this.formFieldChanged("attachments", [...attachments])
                return response
            },
        })
    }

    private getFilesId = (): string => {
        if (!this.state.form.filesId) {
            this.state.form.filesId = uuidv4()
        }
        return this.state.form.filesId
    }

    clearNewAttachmentsOnCancel = () => {
        if (this.state.form.filesId && this.state.form.newAttachmentsNames.length > 0) {
            let filesId: string = this.state.form.filesId
            this.effect({
                id: this.clearNewAttachmentsOnCancel,
                block: async (job) => {
                    return await job.pause(
                        this.workRequestService.deleteAttachments(filesId, this.state.form.newAttachmentsNames)
                    )
                },
            })
        }
    }
}

function newWorkRequestDetailsState(): WorkRequestDetailsScreenState {
    return {
        form: {
            step: { id: 0, step: 0, label: "" },
            billingCodeId: null,
            urgency: Urgency.Medium,
            dueDate: new Date(),
            dueHourMeter: "",
            dueOdometer: "",
            estimatedLaborHours: "",
            estimatedLaborCost: "",
            estimatedPartsCost: "",
            workToBePerformed: "",
            specialInstructions: "",
            notes: "",
            tag: null,
            tasks: [],
            attachments: [],
            serviceType: ServiceRequestType.Repair,
            serviceCodeId: null,
            filesId: null,
            hadFilesId: false,
            newAttachmentsNames: [],
            notifyContacts: [],
            isUnplanned: false,
        },
        steps: [],
        tasks: [],
        workRequest: null,
        asset: null,
        effectStatus: arrayToObject(Object.values(WorkRequestDetailsEffect), (item) => [item, AsyncStatus.idle()]),
        showTaskDescriptions: false,
    }
}
