import { Job, JobCancellationException, JobFunc, SupervisorJob } from "@ethossoftworks/job"
import { Outcome } from "@ethossoftworks/outcome"
import isEqual from "lodash.isequal"
import { BehaviorSubject, Observable } from "rxjs"
import { distinctUntilChanged } from "rxjs/operators"

/**
 * Bloc (Business Logic Component)
 * An isolated slice of safely mutable, observable state that encapsulates business logic pertaining to state
 * manipulation.
 *
 * Bloc Lifecycle
 * A Bloc's lifecycle is dependent on its observers. When the first observer subscribes to the Bloc [onStart] is called.
 * When the last observer unsubscribes [onDispose] is called. A Bloc may choose to reset its state when [onDispose]
 * is called by setting [persistStateOnDispose] to false. A Bloc will call [onStart] again if it gains a new
 * observer after it has been disposed. Likewise, a Bloc will call [onDispose] again if it loses those observers.
 *
 * Observing State
 * When an observer subscribes to state it will immediately receive the latest state as the first emit. Afterwards,
 * only changes to the state will be emitted to observers.
 *
 * Updating Bloc State
 * The only way to update a Bloc's state is by calling the [update] method. Calling [update] will synchronously update
 * the internal state with a new copy of state and notify all observers of the change as long as the new state is
 * different than the previous state.
 *
 * Bloc Effects
 * Effects are asynchronous functions that update the state over time. An effect can be created with
 * an asynchronous function that calls [update] multiple times or by using the [effect] method. The [effect] method
 * provides a built-in cancellation mechanism. Calling an effect multiple times will cancel the previously started
 * effect and replace it with the new effect. The [effect] method also allows configuring whether or not the
 * effect should be cancelled when the Bloc is disposed or not.
 *
 * [initialState] The initial state of a Bloc.
 *
 * [persistStateOnDispose] If false, the internal state will be reset to [initialState] when the bloc is
 * disposed. If true, the Bloc's state will persist until the Bloc is garbage collected.
 */
export abstract class Bloc<T> {
    private readonly _effects: Map<EffectId, CancellableEffect<any>> = new Map()
    private readonly _state: BehaviorSubject<T>
    private readonly persistStateOnDispose: boolean
    private _observers: number = 0

    /**
     * Checks if an effect result is a cancelled effect result
     */
    static isCancelledEffect = <T>(result: Outcome<T>): boolean =>
        result.isError() && result.error instanceof JobCancellationException

    /**
     * Provides a mechanism to allow launching [Job]s externally that follow the Bloc's lifecycle. All [Job]s launched
     * in [blocScope] will be cancelled when the Bloc is disposed.
     */
    public readonly blocScope = new SupervisorJob()

    private readonly _proxy: Observable<T> = new Observable<T>((subscriber) => {
        this.handleSubscribe()
        this._observers++
        Logger.log("Adding Bloc dependency", this.constructor.name)

        const subscription = this._state.subscribe({
            next: (value) => subscriber.next(value),
            complete: () => subscriber.complete(),
            error: (error) => subscriber.error(error),
        })

        return () => {
            this._observers--
            Logger.log("Removing Bloc dependency", this.constructor.name)
            subscription.unsubscribe()
            this.handleUnsubscribe()
        }
    }).pipe(distinctUntilChanged(isEqual))

    constructor(private initialState: T, { persistStateOnDispose = false }: { persistStateOnDispose?: boolean } = {}) {
        this.persistStateOnDispose = persistStateOnDispose
        this._state = new BehaviorSubject(this.nextStateWithComputed(this.initialState))
        sendDevToolsUpdate(this, "New Bloc", this.state)
    }

    /**
     * Returns the current status of the Bloc
     */
    get status(): BlocStatus {
        return this._observers > 0 ? BlocStatus.Started : BlocStatus.Idle
    }

    /**
     * Returns the current state of the Bloc.
     */
    get state(): T {
        return this._state.value
    }

    /**
     * Returns the state as a stream/observable for observing updates. The latest state will be immediately emitted
     * to a new subscriber.
     */
    get stream() {
        return this._proxy
    }

    /**
     * Computes properties based on latest state for every update
     */
    protected computed?(state: T): Partial<T> {
        return state
    }

    /**
     * Called when the bloc receives its first subscription. [onStart] will be called again if it gains an
     * observer after it has been disposed.
     *
     * This is a good time to new-up any services, subscribe to dependent blocs, or open any resource handlers.
     */
    protected onStart?(): void

    /**
     * Called when the last subscription is closed. [onDispose] will be called every time all observers have stopped
     * observing.
     *
     * This is a good place to close any resource handlers or services.
     */
    protected onDispose?(): void

    /**
     * Runs a block of asynchronous code and provides a simple cancellation mechanism. If the effect is cancelled an
     * [Outcome.Error] will be returned with a [JobCancellationException] as its error value. When reusing effect IDs,
     * an ongoing effect will be cancelled and the passed block will run in its place. This can prevent the issue where
     * two of the same effect are called and the first call hangs for a few seconds while the second completes more
     * quickly.
     *
     * [id] The Identifier for the effect. This id is used for effect cancellation and querying effect status.
     * It is recommended to pass the calling effect function as the id.
     *
     * [cancelOnDispose] if true, the effect will be cancelled when the Bloc is disposed if the effect is still running.
     *
     * [onDone] a block of synchronous code to be run when the effect finishes regardless of success or failure. This
     * can be used to update state regardless of if an effect is cancelled or not. NOTE: It is not guaranteed that
     * [onDone] will run before disposal and resetting of state if [persistStateOnDispose === false] so be careful
     * when updating state.
     */
    protected async effect<R>({
        id,
        block,
        cancelOnDispose = true,
        onDone,
    }: {
        id: EffectId
        block: JobFunc<R>
        cancelOnDispose?: boolean
        onDone?: (result: Outcome<R>) => unknown
    }): Promise<Outcome<R>> {
        this.cancelEffect(id)

        const effect = new CancellableEffect(new Job(block), cancelOnDispose, onDone)
        this._effects.set(id, effect)
        const result = await effect.run()
        if (this._effects.get(id) === effect) this._effects.delete(id)
        return result
    }

    /**
     * [effectStatus] Returns the current status of an effect
     */
    effectStatus(id: EffectId): EffectStatus {
        return this._effects.has(id) ? EffectStatus.Running : EffectStatus.Idle
    }

    /**
     * Cancels an effect with the given [id].
     */
    protected cancelEffect(id: EffectId) {
        this._effects.get(id)?.cancel()
        this._effects.delete(id)
    }

    /**
     * Immutably update the state and notify all subscribers of the change.
     */
    protected update(state: Partial<T>): T {
        const newState = { ...this.state, ...state }
        this._state.next(this.nextStateWithComputed(newState))
        sendDevToolsUpdate(this, "Update", this.state)
        return newState
    }

    private handleSubscribe() {
        if (this._observers > 0) return
        Logger.log("Starting Bloc", this.constructor.name)
        this.onStart?.()
    }

    private handleUnsubscribe() {
        if (this._observers > 0) return
        Logger.log("Disposing Bloc", this.constructor.name)
        this._effects.forEach((effect, _) => (effect.cancelOnDispose ? effect.cancel() : null))
        this._effects.clear()
        this.blocScope.cancelChildren()
        if (!this.persistStateOnDispose) {
            this._state.next(this.nextStateWithComputed(this.initialState))
            sendDevToolsUpdate(this, "Dispose", this.state)
        }
        this.onDispose?.()
    }

    private nextStateWithComputed(state: T): T {
        return this.computed ? { ...state, ...this.computed(state) } : state
    }
}

/**
 * ID for effects
 */
export type EffectId = string | number | ((...args: any) => any)

/**
 * BlocStatus
 * The current status of a Bloc. Idle represents a Bloc that is in a disposed/unused state (there are no
 * active subscriptions). Once there is an active subscription, the Bloc is in a Started state.
 */
export enum BlocStatus {
    Started = "started",
    Idle = "idle",
}

/**
 * EffectStatus
 * The current status of an effect. Idle represents an effect that is not running.
 */
export enum EffectStatus {
    Idle = "idle",
    Running = "running",
}

/**
 * Returns the type of the state the Bloc contains
 */
export type BlocStateType<B> = B extends Bloc<infer S> ? S : any

class CancellableEffect<T> {
    constructor(
        private job: Job<T>,
        public cancelOnDispose: boolean,
        private onDone?: (result: Outcome<T>) => unknown
    ) {}

    run = async (): Promise<Outcome<T>> => {
        const result = await this.job.run()
        this.onDone?.(result)
        return result
    }

    cancel() {
        Logger.log("Bloc effect cancelled")
        this.job.cancel()
    }
}

class Logger {
    readonly LEVEL_DEBUG = 0
    readonly LEVEL_PROD = 1

    static level = 1

    static log(...values: any[]) {
        if (this.level === 1) return
        console.log(...values)
    }
}

// Connect with Redux Dev Tools
let devTools: null | any = null
let devToolsState = {}

async function connectDevTools() {
    const devToolsExt = (global.window as any)?.__REDUX_DEVTOOLS_EXTENSION__
    if (devToolsExt === undefined) return
    devTools = devToolsExt.connect({ name: "Bloc" })
    devTools.init(devToolsState)
}

async function sendDevToolsUpdate(bloc: Bloc<any>, action: string, state: any) {
    if (!devTools || devTools.send === undefined) return
    devToolsState = { ...devToolsState, [bloc.constructor.name]: state }
    devTools.send({ type: `${bloc.constructor.name} - ${action}`, state: state }, devToolsState)
}

if (process.env.NODE_ENV === "development") {
    connectDevTools()
}
