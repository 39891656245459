import { safeParseInt } from "@lib/TypeUtil"
import { textFilterValue } from "@model/filters/Filter"
import { FilterPreset } from "@model/filters/FilterPresets"
import { FilterOverlayState } from "@state/filters/FilterOverlayBloc"
import { FilterOverlayBlocCoordinator } from "@state/filters/FilterOverlayBlocCoordinatorMixin"
import React from "react"
import { Button, ButtonType } from "../buttons/Button"
import { FormField, FormFieldDirection } from "../form/FormField"
import { Select } from "../form/Select"
import { TextInput, TextInputType } from "../form/TextInput"
import { FilterOverlayGroup, FilterOverlayGroupColumn } from "./FilterOverlay"

export function FilterOverlayPresetsGroup<T extends string>({
    presets,
    overlayState,
    viewModel,
    searchFilter,
    searchLabel,
    secondarySearchLabel,
    secondaryFilter,
    inputType
}: {
    searchFilter?: T
    searchLabel?: string
    presets: FilterPreset<T>[]
    overlayState: FilterOverlayState<T>
    viewModel: FilterOverlayBlocCoordinator<T>
    secondarySearchLabel?: string
    secondaryFilter?: T
    inputType?: TextInputType
}): JSX.Element {
    return (
        <FilterOverlayGroup
            className="filter-overlay-group--presets"
            label={searchFilter ? "Search" : "Filters"}
            columns={1}
        >
            <FilterOverlayGroupColumn>
                <div className="filter-overlay-group-presets-cont">
                    {searchFilter && (
                        <FormField label={searchLabel ?? ""} direction={FormFieldDirection.Column}>
                            <TextInput
                                onChange={(value) => viewModel.updateFilter(searchFilter, [{ value }])}
                                type={TextInputType.Search}
                                value={textFilterValue(overlayState.filters[searchFilter])}
                                onKeyPress={(event) => {
                                    if(event.key == "Enter"){
                                        viewModel.applyFilters()}
                                    else if (secondaryFilter){
                                        viewModel.updateFilter(secondaryFilter as T,[{ value: '' }])

                                    }
                                }}
                            />
                            {!viewModel.isSearchFilterValid() && (
                                <div className="filter-overlay-search-error">
                                    Search must contain at least 3 characters
                                </div>
                            )}
                        </FormField>
                        
                    )}
                    {secondaryFilter &&(
                        <FormField label={secondarySearchLabel ?? ""} direction={FormFieldDirection.Column}>
                            <TextInput
                                onChange={(value) => viewModel.updateFilter(secondaryFilter, [{ value }])}
                                type={inputType != null ? inputType : TextInputType.Search}
                                value={textFilterValue(overlayState.filters[secondaryFilter])}
                                onKeyPress={(event) => {
                                    if(event.key == "Enter"){
                                        viewModel.applyFilters()}
                                    else{
                                        viewModel.updateFilter(searchFilter as T,[{ value: '' }])

                                    }
                                }}
                            />
                            {!viewModel.isSecondaryFilterValid() && (
                                <div className="filter-overlay-search-error">
                                    Search must contain at least 3 characters
                                </div>
                            )}
                        </FormField>
                        
                    )}
                    <FormField label={searchFilter ? `Saved Search \u0026 Filters` : "Saved Filters"}>
                        <Select
                            options={[
                                { value: "-1", label: "Select" },
                                ...presets.map((it) => ({
                                    value: it.id.toString(),
                                    label: `${it.name}${it.isDefault ? " (Default)" : ""}${
                                        it.id === overlayState.preset?.id && overlayState.isPresetModified
                                            ? " (Modified)"
                                            : ""
                                    }`,
                                })),
                            ]}
                            value={overlayState.preset === null ? "" : overlayState.preset.id.toString()}
                            onChange={(value) => viewModel.filterPresetSelected(safeParseInt(value))}
                        />
                    </FormField>
                    <div className="filter-overlay-presets-button-cont">
                        {overlayState.isPresetModified && (
                            <Button
                                type={ButtonType.Outlined}
                                label="Update"
                                onClick={() => viewModel.updatePresetFilters()}
                            />
                        )}
                        {
                            <Button
                                type={ButtonType.Outlined}
                                label="Create"
                                onClick={() => viewModel.showCreatePreset()}
                            />
                        }
                        {presets.length > 0 && (
                            <Button
                                type={ButtonType.Outlined}
                                label="Manage"
                                onClick={() => viewModel.showManagePresets()}
                            />
                        )}
                    </div>
                </div>
                <Button
                    isEnabled={viewModel.isSearchFilterValid()}
                    className="filter-overlay-apply-button"
                    type={ButtonType.Contained}
                    label="Apply Filters"
                    onClick={() => viewModel.applyFilters()}
                />
                <Button
                    isEnabled={true}
                    className="filter-overlay-reset-button"
                    type={ButtonType.Text}
                    label="Reset Filters"
                    onClick={() => viewModel.resetFilters()}
                />
            </FilterOverlayGroupColumn>
        </FilterOverlayGroup>
    )
}
