import React from "react"
import { isClosed, PDFWorkOrder } from "../models/PDFWorkOrder"
import { PDFWorkRequest } from "../models/PDFWorkRequest"
import { PDFSectionBorder, PDFMinEntryHeight } from "./PDF"
import { PDFCheck } from "./PDFCheck"
import { PDFHeadedSection } from "./PDFSection"

export function WorkRequestStatusPDFSection({
    workRequest,
    workOrder,
}: {
    workRequest?: PDFWorkRequest
    workOrder?: PDFWorkOrder
}): JSX.Element {
    return (
        <PDFHeadedSection title={"Service Request Status"}>
            <div style={{ display: "grid", gap: 32, gridTemplateColumns: "auto 1fr" }}>
                <div style={{ display: "grid", gap: "4px 0px" }}>
                    <PDFCheck
                        checked={
                            workOrder &&
                            workRequest?.originalWorkRequest.status.toString() === "done"
                        }
                        label="Done"
                    />
                    <PDFCheck
                        checked={
                            workOrder &&
                            workRequest?.originalWorkRequest.status.toString() === "workNotPerformed"
                        }
                        label="Work Not Performed"
                    />
                </div>
                <div
                    style={{
                        display: "grid",
                        gap: 32,
                        gridTemplateColumns: "auto 1fr",
                        alignItems: "end",
                    }}
                >
                    <div style={{ fontSize: "1.4rem", lineHeight: 1, fontWeight: 700 }}>Reason:</div>
                    <div style={{ borderBottom: PDFSectionBorder, height: PDFMinEntryHeight }}>
                        {workOrder 
                            ? workRequest?.originalWorkRequest.statusFlagReason
                            : undefined}
                    </div>
                </div>
            </div>
        </PDFHeadedSection>
    )
}
