import { Strings } from "@lib/Strings"
import { MAX_INT_32, safeParseInt } from "@lib/TypeUtil"
import { Filter, isEmptyFilterValue, textFilterValue } from "./filters/Filter"

export type Threshold = {
    value: number | null
    unit: ThresholdUnit
}

export type ThresholdGroup = {
    id: number | null
    [ThresholdType.Days]: Threshold
    [ThresholdType.Hours]: Threshold
    [ThresholdType.Miles]: Threshold
    applyToSubCompanies: boolean | null
    isReadOnly: boolean
}

export function newThresholdGroup(): ThresholdGroup {
    return {
        id: null,
        [ThresholdType.Hours]: { value: null, unit: ThresholdUnit.Unit },
        [ThresholdType.Miles]: { value: null, unit: ThresholdUnit.Unit },
        [ThresholdType.Days]: { value: null, unit: ThresholdUnit.Unit },
        applyToSubCompanies: null,
        isReadOnly: false,
    }
}

export const Threshold = {
    clampValue(threshold: Threshold): Threshold {
        return {
            value: (() => {
                if (threshold.value === null) return null
                if (threshold.unit === ThresholdUnit.Percent) return Math.max(0, Math.min(threshold.value ?? 0, 100))
                return Math.min(threshold.value, MAX_INT_32)
            })(),
            unit: threshold.unit,
        }
    },

    labelForType(type: ThresholdType): string {
        switch (type) {
            case ThresholdType.Days:
                return "Days"
            case ThresholdType.Hours:
                return "Hours"
            case ThresholdType.Miles:
                return "Miles"
        }
    },

    labelForUnit(type: ThresholdType, unit: ThresholdUnit): string {
        if (unit === ThresholdUnit.Percent) return "%"

        switch (type) {
            case ThresholdType.Days:
                return "days"
            case ThresholdType.Hours:
                return "hr"
            case ThresholdType.Miles:
                return "mi"
        }
    },
}

export enum ThresholdType {
    Hours = "hours",
    Miles = "miles",
    Days = "days",
}

export enum ThresholdUnit {
    Unit = "unit",
    Percent = "percent",
}

export function thresholdUnitForValue(value: string): ThresholdUnit {
    switch (value) {
        case ThresholdUnit.Percent:
        case "%":
            return ThresholdUnit.Percent
        case ThresholdUnit.Unit:
            return ThresholdUnit.Unit
    }
    return ThresholdUnit.Unit
}

export function clampThresholdFilter<T extends string>(filter: Filter<T>, unitFilter: Filter<T>): Filter<T> {
    if (isEmptyFilterValue(filter)) return filter

    const threshold = Threshold.clampValue({
        value: safeParseInt(textFilterValue(filter)),
        unit: thresholdUnitForValue(textFilterValue(unitFilter)),
    })

    const clampedValue = Strings.formatInteger(Threshold.clampValue(threshold).value?.toString() ?? 0)

    return { ...filter, values: [{ value: clampedValue }] }
}
