import { classNames } from "@lib/HtmlUtil"
import React from "react"

export function FormSectionCont({
    className,
    children,
}: {
    className?: string
    children: React.ReactNode
}): JSX.Element {
    return <div className={classNames("form-section-cont", className)}>{children}</div>
}
