import { arrayToObject } from "@lib/TypeUtil"
import { Filter, FilterValue, isEmptyFilter, isEmptyFilterValue, isFilterGroupEqual } from "@model/filters/Filter"
import { clampThresholdFilter } from "@model/Threshold"
import { WorkOrderFilter } from "@model/workOrders/WorkOrder"
import { WorkOrderFilterDefinitions } from "@model/workOrders/WorkOrderFilterDefinitions"
import { FilterOverlayBloc, FilterOverlayState } from "@state/filters/FilterOverlayBloc"

export type WorkOrdersFilterOverlayState = FilterOverlayState<WorkOrderFilter>

export class WorkOrdersFilterOverlayBloc extends FilterOverlayBloc<WorkOrderFilter> {
    constructor() {
        super(Object.values(WorkOrderFilter), WorkOrderFilterDefinitions)
    }

    override computed(state: FilterOverlayState<WorkOrderFilter>) {
        const updated: FilterOverlayState<WorkOrderFilter> = {
            ...state,
            filters: {
                ...state.filters,
                [WorkOrderFilter.DaysUntil]: ((): Filter<WorkOrderFilter> => {
                    const filter = state.filters[WorkOrderFilter.DaysUntil]
                    const unitFilter = state.filters[WorkOrderFilter.DaysUntilUnit]
                    return clampThresholdFilter(filter, unitFilter)
                })(),
                [WorkOrderFilter.HoursUntil]: ((): Filter<WorkOrderFilter> => {
                    const filter = state.filters[WorkOrderFilter.HoursUntil]
                    const unitFilter = state.filters[WorkOrderFilter.HoursUntilUnit]
                    return clampThresholdFilter(filter, unitFilter)
                })(),
                [WorkOrderFilter.MilesUntil]: ((): Filter<WorkOrderFilter> => {
                    const filter = state.filters[WorkOrderFilter.MilesUntil]
                    const unitFilter = state.filters[WorkOrderFilter.MilesUntilUnit]
                    return clampThresholdFilter(filter, unitFilter)
                })(),
            },
        }
        return { ...super.computed(updated) }
    }

    override getSearchFilter() {
        return WorkOrderFilter.Search
    }

    override getSecondaryFilter(){
        return WorkOrderFilter.WOSearch
    }

    override updateFilter(type: WorkOrderFilter, value: FilterValue[]) {
        if ([WorkOrderFilter.DaysUntil, WorkOrderFilter.MilesUntil, WorkOrderFilter.HoursUntil].includes(type)) {
            super.updateFilter(WorkOrderFilter.Upcoming, [{ value: false }])
        } else if (type === WorkOrderFilter.Upcoming) {
            super.updateFilter(WorkOrderFilter.MilesUntil, [{ value: "" }])
            super.updateFilter(WorkOrderFilter.HoursUntil, [{ value: "" }])
            super.updateFilter(WorkOrderFilter.DaysUntil, [{ value: "" }])
        }
        return super.updateFilter(type, value)
    }

    override isPresetModified(state: FilterOverlayState<WorkOrderFilter>): boolean {
        if (state.preset === null) return false

        const daysUntilFilter = state.filters[WorkOrderFilter.DaysUntil]
        const milesUntilFilter = state.filters[WorkOrderFilter.MilesUntil]
        const hoursUntilFilter = state.filters[WorkOrderFilter.HoursUntil]

        return !isFilterGroupEqual(
            arrayToObject(state.preset.filters, (it) => [it.definition.type, it]),
            arrayToObject(
                Object.values<Filter<WorkOrderFilter>>(state.filters).filter((it) => {
                    if (it.definition.type === WorkOrderFilter.DaysUntilUnit && isEmptyFilterValue(daysUntilFilter)) {
                        return false
                    }
                    if (it.definition.type === WorkOrderFilter.MilesUntilUnit && isEmptyFilterValue(milesUntilFilter)) {
                        return false
                    }
                    if (it.definition.type === WorkOrderFilter.HoursUntilUnit && isEmptyFilterValue(hoursUntilFilter)) {
                        return false
                    }
                    if (
                        it.definition.type === WorkOrderFilter.RedTag &&
                        isEmptyFilterValue(it)
                    ) {
                        return false
                    }
                    if (
                        it.definition.type === WorkOrderFilter.YellowTag &&
                        isEmptyFilterValue(it)
                    ) {
                        return false
                    }

                    return !isEmptyFilter(it)
                }),
                (it) => [it.definition.type, it]
            )
        )
    }
}
