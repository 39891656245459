import { Bloc } from "@lib/bloc/Bloc"
import { Urgency } from "@model/Urgency"
import { CreateWorkOrderForm, newCreateWorkOrderForm } from "@model/workOrders/CreateWorkOrderForm"
import { WorkOrderStatus } from "@model/workOrders/WorkOrder"

export type CreateWorkOrderState = CreateWorkOrderForm

export class CreateWorkOrderBloc extends Bloc<CreateWorkOrderState> {
    constructor() {
        super(newCreateWorkOrderForm(), { persistStateOnDispose: false })
    }

    statusChanged = (value: WorkOrderStatus) => this.update({ status: value })
    assignedToChanged = (value: number) => this.update({ assignedTo: value })
    urgencyChanged = (value: Urgency) => this.update({ urgency: value })
    dueDateChanged = (value: Date) => this.update({ dueDate: value })
    dueHourMeterChanged = (value: string) => this.update({ dueHourMeter: value })
    dueOdometerChanged = (value: string) => this.update({ dueOdometer: value })
    specialInstructionsChanged = (value: string) => this.update({ specialInstructions: value })
    notifyContactsToChanged = (value: number[]) => this.update({ notifyContacts: value })
}
