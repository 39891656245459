import { compare, SortOrder } from "@lib/Comparable"
import { Attachment } from "@model/Attachment"
import { HistoryItem } from "@model/HistoryItem"
import { ServiceRequestType } from "@model/serviceRequests/ServiceRequest"
import { ServiceSchedule } from "@model/ServiceSchedule"
import { Step } from "@model/Step"
import { Tag } from "@model/Tag"
import { Task } from "@model/Task"
import { Urgency } from "@model/Urgency"
import { WorkOrderStatus } from "@model/workOrders/WorkOrder"
import { WorkRequestStatusFlag } from "./WorkRequestStatusFlag"

export type WorkRequest = {
    id: number
    assetId: number
    step: Step | null
    schedule: ServiceSchedule | null
    billingCodeId: number | null
    workRequestType: WorkRequestType
    estimatedLaborHours: number
    estimatedLaborCost: number
    estimatedPartsCost: number
    actualLaborHours: number
    actualLaborCost: number
    actualPartsCost: number
    dateCreated: Date
    urgency: Urgency
    dueDate: Date | null
    dueOdometer: number | null
    dueHourMeter: number | null
    hoursUntil: number | null
    milesUntil: number | null
    daysUntil: number | null
    isUpcoming: boolean
    isOverdue: boolean
    tag: Tag | null
    attachments: Attachment[]
    history: HistoryItem<WorkRequestStatus>[]
    specialInstructions: string
    notes: string
    tasks: Task[] | null
    status: WorkRequestStatus
    workOrderId: number | null
    workToBePerformed: string
    workRequestStatusFlags: WorkRequestStatusFlag[]
    serviceCodeId: number | null
    serviceType: ServiceRequestType | null
    serviceSubTypeId: number | null
    notifyContacts: number[]
    createdBy: number | null
    createdByName: string
    statusFlagReason: string
    filesId: string
    followUpWorkRequestId: number | null
    dismissed: boolean | null
    isUnplanned: boolean
}

export function getMinDueDateAsNumber(workRequests: WorkRequest[]): number | null {
    if (workRequests.length == 0) return null

    if (workRequests.filter((x) => x.dueDate != null).length == 0) return null

    return Math.min.apply(
        null,
        workRequests.filter((x) => x.dueDate).map((x) => (x.dueDate ? x.dueDate.getTime() : new Date(0).getTime()))
    )
}

export function getMinDueDateAsDate(workRequests: WorkRequest[]): Date | null {
    var numberDate = getMinDueDateAsNumber(workRequests)

    return numberDate == null ? null : new Date(numberDate)
}

export function labelForWorkRequest(request: WorkRequest): string {
    const label = request.workToBePerformed

    if (!label) return ""
    return label.length > 0 ? label : `${request.id}`
}

export enum WorkRequestStatus {
    ToDo = "toDo",
    WorkNotPerformed = "workNotPerformed",
    Done = "done",
}

export function workRequestStatusForValue(status: string): WorkRequestStatus {
    switch (status) {
        case WorkRequestStatus.ToDo:
            return WorkRequestStatus.ToDo
        case WorkRequestStatus.WorkNotPerformed:
            return WorkRequestStatus.WorkNotPerformed
        case WorkRequestStatus.Done:
            return WorkRequestStatus.Done
        default:
            return WorkRequestStatus.ToDo
    }
}

export function labelForWorkRequestStatus(status: WorkRequestStatus): string {
    switch (status) {
        case WorkRequestStatus.ToDo:
            return "Not Started"
        case WorkRequestStatus.WorkNotPerformed:
            return "Declined"
        case WorkRequestStatus.Done:
            return "Done"
    }
}

export type WorkRequestsTableRow = {
    assetId: number
    assetName: string
    location: string
    site: {
        id: number
        name: string
    } | null
    hourMeter: number
    odometer: number
    daysInactive: number
    class: {
        id: number
        name: string
    } | null
    subCompany: {
        id: number
        name: string
    } | null
    company: {
        id: number
        name: string
    } | null
    district: {
        id: number
        name: string
    } | null
    subDistrict: {
        id: number
        name: string
    } | null
    unit: {
        id: number
        name: string
    } | null
    group: {
        id: number
        name: string
    } | null
    tag: Tag | null
    workRequestTypeInfo: Record<WorkRequestType, WorkRequestTableRowWorkRequestTypeInfo>
    workOrders: {
        id: string
        label: string
        status: WorkOrderStatus
        creationDate: Date
    }[]
    estimatedLaborHours: number
    estimatedLaborCost: number
    estimatedPartsCost: number
    estimatedTotalCost: number
    overdue: boolean
    upcoming: boolean
    city: string
    state: string
}

export type UserWorkRequestsTableRow = {
    assetId: number
    assetName: string
    id: number
    name: string
}
export type WorkRequestTableRowWorkRequestTypeInfo = {
    count: number
    highestPriority: WorkRequestTableRowPriority
}

export enum WorkRequestTableRowPriority {
    Immediate = "immediate",
    Overdue = "overdue",
    Upcoming = "upcoming",
    High = "high",
    Medium = "medium",
    Low = "low",
}

export function ordinalForWorkRequestTableRowPriority(priority: WorkRequestTableRowPriority): number {
    switch (priority) {
        case WorkRequestTableRowPriority.Immediate:
            return 5
        case WorkRequestTableRowPriority.Overdue:
            return 4
        case WorkRequestTableRowPriority.Upcoming:
            return 3
        case WorkRequestTableRowPriority.High:
            return 2
        case WorkRequestTableRowPriority.Medium:
            return 1
        case WorkRequestTableRowPriority.Low:
            return 0
        default:
            return 0
    }
}

export enum WorkRequestType {
    Service = "service",
    Preventative = "preventative",
    Inspection = "inspection",
}

export function workRequestTypeForValue(value: string): WorkRequestType {
    switch (value) {
        case WorkRequestType.Inspection:
            return WorkRequestType.Inspection
        case WorkRequestType.Preventative:
            return WorkRequestType.Preventative
        case WorkRequestType.Service:
            return WorkRequestType.Service
        default:
            return WorkRequestType.Preventative
    }
}

export function labelForWorkRequestType(type: WorkRequestType): string {
    switch (type) {
        case WorkRequestType.Inspection:
            return "Inspection"
        case WorkRequestType.Preventative:
            return "Preventative"
        case WorkRequestType.Service:
            return "Repair"
    }
}

// Note: These values are used to store column preferences
export enum WorkRequestTableColumn {
    Tag = "tag",
    Asset = "asset",
    Service = "service",
    PreventativeMaintenance = "preventativeMaintenance",
    Inspection = "inspection",
    Total = "total",
    Location = "location",
    WorkOrder = "workOrder",

    HourMeter = "hourMeter",
    Odometer = "odometer",
    DaysInactive = "daysInactive",
    Class = "class",
    EstimatedLaborHours = "estimatedLaborHours",
    EstimatedLaborCost = "estimatedLaborCost",
    EstimatedPartsCost = "estimatedPartsCost",
    EstimatedTotalCost = "totalEstimatedCost",
    SubCompany = "subCompany",
    District = "district",
    SubDistrict = "subDistrict",
    Unit = "unit",
    Group = "group",
    City = "city",
    State = "state",
}

// Note: These values are used to store column preferences
export enum AssetWorkRequestsTableColumn {
    Tag = "tag",
    Name = "name",
    Status = "status",
    Type = "type",
    Urgency = "urgency",
    HoursUntil = "hoursUntil",
    MilesUntil = "milesUntil",
    DaysUntil = "daysUntil",
}

export enum UserWorkRequestsTableColumn {
    Asset = "asset",
    Name = "name",
}

export function sortWorkRequestColumns(columns: WorkRequestTableColumn[]): WorkRequestTableColumn[] {
    return columns.sort((a, b) => {
        const order = Object.values(WorkRequestTableColumn)
        return compare(order.indexOf(a), order.indexOf(b), SortOrder.Asc)
    })
}

export function sortUserWorkRequestColumns(columns: UserWorkRequestsTableColumn[]): UserWorkRequestsTableColumn[] {
    return columns.sort((a, b) => {
        const order = Object.values(UserWorkRequestsTableColumn)
        return compare(order.indexOf(a), order.indexOf(b), SortOrder.Asc)
    })
}

export const defaultWorkRequestsTableColumns = [
    WorkRequestTableColumn.Tag,
    WorkRequestTableColumn.Asset,
    WorkRequestTableColumn.Service,
    WorkRequestTableColumn.PreventativeMaintenance,
    WorkRequestTableColumn.Inspection,
    WorkRequestTableColumn.Total,
    WorkRequestTableColumn.Location,
    WorkRequestTableColumn.WorkOrder,
]

export const defaultUserWorkRequestsTableColumns = [UserWorkRequestsTableColumn.Asset, UserWorkRequestsTableColumn.Name]

export enum WorkRequestFilter {
    Search = "search",
    SubCompany = "subCompany",
    District = "district",
    SubDistrict = "subDistrict",
    Unit = "unit",
    Group = "group",
    Site = "site",
    AssetType = "assetType",
    Category = "category",
    Class = "class",
    Make = "make",
    Model = "model",
    WorkType = "workType",
    Urgency = "urgency",
    Schedule = "schedule",
    HoursUntil = "hoursUntil",
    HoursUntilUnit = "hoursUntilUnit",
    MilesUntil = "milesUntil",
    MilesUntilUnit = "milesUntilUnit",
    DaysUntil = "daysUntil",
    DaysUntilUnit = "daysUntilUnit",
    ServiceCode = "serviceCode",
    RedTag = "redTag",
    YellowTag = "yellowTag",
    Overdue = "overdue",
    Upcoming = "upcoming",
    Dismissed = "dismissed",
    RequestedBy = "requestedBy",
}
export enum WorkRequestQuickFilterCount {
    Assets = "assets",
    Service = "service",
    Preventative = "preventative",
    Inspection = "inspection",
    RedTag = "redTag",
    YellowTag = "yellowTag",
    Immediate = "immediate",
    Overdue = "overdue",
    Upcoming = "upcoming",
}
