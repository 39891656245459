import { stringOrDefault } from "@lib/TypeUtil"
import { User } from "@model/user/User"

export type Contact = {
    id: number
    firstName: string | null
    lastName: string | null
    displayName: string | null
    title: string | null
    companyName: string | null
    company: ContactCompany
    address: string | null
    city: string | null
    state: number | null
    postalCode: string | null
    officePhone: string | null
    homePhone: string | null
    cellPhone: string | null
    email: string | null
    notes: string | null
    isMechanic: boolean
    personTypeID?: number | null
    hasActiveWorkOrders: boolean
    deleted: boolean
}

export type ContactCompany = {
    id: number
    name: string | null
}

export function newContact(): Contact {
    return {
        address: "",
        cellPhone: "",
        city: "",
        companyName: "", //this a value stored in db in the person table
        company: {
            id: 0,
            name: "",
        },
        displayName: "",
        email: "",
        firstName: "Test",
        homePhone: "",
        id: 0,
        isMechanic: false,
        lastName: "",
        notes: "",
        officePhone: "",
        postalCode: "",
        state: 0,
        title: "",
        hasActiveWorkOrders: false,
        deleted: false
        // type: ContactType.Customer,
    }
}

export enum ContactType {
    Employee = "employee",
    Vendor = "vendor",
    Customer = "customer",
    ThirdParty = "thirdParty",
    Contractor = "contractor",
}

export function labelForContactType(type: ContactType): string {
    switch (type) {
        case ContactType.Employee:
            return "Employee"
        case ContactType.Vendor:
            return "Vendor"
        case ContactType.Customer:
            return "Customer"
        case ContactType.ThirdParty:
            return "3rd Party"
        case ContactType.Contractor:
            return "Contractor"
    }
}

export function valueForContactType(type: ContactType): number {
    switch (type) {
        case ContactType.Employee:
            return 1
        case ContactType.Vendor:
            return 2
        case ContactType.Customer:
            return 3
        case ContactType.ThirdParty:
            return 4
        case ContactType.Contractor:
            return 5
    }
}

export function mapNumberToContactType(numbers: number[]): ContactType[] {
    return numbers.map((number) => {
        switch (number) {
            case 1:
                return ContactType.Employee
            case 2:
                return ContactType.Vendor
            case 3:
                return ContactType.Customer
            case 4:
                return ContactType.ThirdParty
            case 5:
                return ContactType.Contractor
            default:
                // Handle other cases explicitly or remove it entirely if not necessary.
                throw new Error(`Invalid number: ${number}`)
        }
    })
}

export function labelForContact(contact?: Contact, user?: User): string {
    if (!contact) return ""
    if (user?.isInParentCompany()) return labelForParentCompanyContact(contact, user)
    else return labelForChildCompanyContact(contact, user)
}

function labelForParentCompanyContact(contact: Contact, user?: User): string {
    return `${labelForContactName(contact)}${labelForContactCompanyName(contact)}${labelForContactSmartHubCompany(
        contact
    )}`
}

function labelForChildCompanyContact(contact: Contact, user?: User): string {
    return `${labelForContactName(contact)}${labelForContactCompanyName(contact)}`
}

function labelForContactName(contact: Contact) {
    return `${stringOrDefault(contact.firstName, "")} ${stringOrDefault(contact.lastName, "")}`
}

function labelForContactSmartHubCompany(contact: Contact) {
    return `${contact.company != null && contact.company.name != "" ? " (" + contact.company.name + ")" : ""}`
}

function labelForContactCompanyName(contact: Contact) {
    if (labelForContactName(contact).trim() !== "")
        return `${contact.companyName != null && contact.companyName != "" ? " - " + contact.companyName : ""}`
    else return `${contact.companyName != null && contact.companyName != "" ? contact.companyName : ""}`
}
