export enum DashboardFilter {
    Search = "search",
    SubCompany = "subCompany",
    District = "district",
    SubDistrict = "subDistrict",
    Unit = "unit",
    Group = "group",
    Site = "site",
    AssetType = "assetType",
    Category = "category",
    Class = "class",
    Make = "make",
    Model = "model",

    WorkType = "workType",
    AssignedTo = "assignedTo",
    MechanicTypes = "mechanicTypes",
}
