import { useBlocCoordinator } from "@lib/bloc/hooks"
import { SortOrder } from "@lib/Comparable"
import { useIsInIframe, useIsMobile, useLastNonNull } from "@lib/Hooks"
import { Link } from "@lib/router/components"
import { Strings } from "@lib/Strings"
import { safeParseFloat, safeParseInt } from "@lib/TypeUtil"
import { CompanyAlias } from "@model/company/CompanyAlias"
import { Filter, textFilterValue } from "@model/filters/Filter"
import {
    SortableTableHeadColumn,
    TableColumn,
    TableColumnAlignment,
    TableColumnSize,
    TableRow,
} from "@model/tables/Table"
import { Threshold, ThresholdType, thresholdUnitForValue } from "@model/Threshold"
import { colorForUrgency, labelForUrgency } from "@model/Urgency"
import {
    labelForWorkOrderStatus,
    WorkOrderFilter,
    WorkOrderTableColumn,
    WorkOrderTableRow,
} from "@model/workOrders/WorkOrder"
import { WorkOrdersTableColumnDefinitions } from "@model/workOrders/WorkOrdersTableColumnDefinitions"
import { Button, ButtonCont, ButtonTheme, ButtonType } from "@ui/common/buttons/Button"
import { Popover, PopoverItem } from "@ui/common/buttons/Popover"
import { DataField } from "@ui/common/DataField"
import { FlagChip } from "@ui/common/FlagChip"
import { Grid } from "@ui/common/grids/Grid"
import { ImageUrls } from "@ui/common/images/ImageUrls"
import { List } from "@ui/common/List"
import { Modal, ModalBody, ModalContent, ModalHeader, ModalHeaderTitle, ModalSize } from "@ui/common/Modal"
import { ManageColumnsModal } from "@ui/common/tables/ManageColumnsModal"
import { SortableTable } from "@ui/common/tables/SortableTable"
import { Tag } from "@ui/common/Tag"
import { DI } from "@ui/DI"
import { ExternalRoutes, Routes } from "@lib/Routes"
import { AssetLocation } from "@ui/workRequests/AssetLocation"
import React, { useEffect } from "react"
import { PrintWorkOrderModal } from "./PrintWorkOrderModal"
import { WorkOrdersTableViewModel } from "./WorkOrdersTableViewModel"
import { useRouteQuery } from "@lib/router/hooks"
import { withRouter } from "@lib/router/router"

export function WorkOrdersTable(): JSX.Element {
    const isMobile = useIsMobile() || useIsInIframe()
    const [state, viewModel] = useBlocCoordinator(() => DI.workOrdersTableViewModel())

    const { filter } = useRouteQuery()
    let filters: Record<WorkOrderFilter, Filter<WorkOrderFilter>>

    if (filter) {
        filters = JSON.parse(filter)
    }

    useEffect(() => {
        viewModel.onMounted( filters)
    }, [])

    const aliasedColumnLabels = createLabelsForAliasedColumns(state.aliases)
    const aliasedFilterLabels = createLabelsForAliasedFilters(state.aliases)
    const thresholdFilterValueOverrides = createThresholdFilterValueOverrides(state.filters)
    const filteredColumns = state.columns.filter(
        (it) =>
            !isMobile ||
            it === WorkOrderTableColumn.Tag ||
            it === WorkOrderTableColumn.Asset ||
            it === WorkOrderTableColumn.WorkOrder ||
            it === WorkOrderTableColumn.Status
    )

    return (
        <>
            <SortableTable
                className="table--work-orders"
                head={createTableHead(filteredColumns, isMobile, state.sort, aliasedColumnLabels)}
                data={createTableData(isMobile, filteredColumns, state.tableData, viewModel)}
                emptyMessage="No Assets Found"
                actionBar={{
                    pagination: state.pagination,
                    filters: Object.values(state.filters).filter(
                        (it) =>
                            it.definition.type !== WorkOrderFilter.DaysUntilUnit &&
                            it.definition.type !== WorkOrderFilter.MilesUntilUnit &&
                            it.definition.type !== WorkOrderFilter.HoursUntilUnit
                    ),
                    filterLabelOverrides: aliasedFilterLabels,
                    filterValueOverrides: thresholdFilterValueOverrides,
                    onFilterRemoveClicked: (filter) => viewModel.onFilterRemoveClicked(filter),
                    onExportClicked: () => viewModel.onExportClicked(),
                    onResetFiltersClicked: () => viewModel.onResetFiltersClicked(),
                    onManageColumnsClicked: () => viewModel.onManageColumnsClicked(),
                    onNextPageClicked: () => viewModel.onNextPageClicked(),
                    onPreviousPageClicked: () => viewModel.onPreviousPageClicked(),
                    onViewAllClicked: () => viewModel.onViewAllPagesClicked(),
                    onResetViewAllClicked: () => viewModel.onResetViewAllClicked(),
                    onPageClicked: (page) => viewModel.onPageClicked(page),
                }}
                isLoading={state.isTableDataLoading}
                onColumnSortClicked={(column) => viewModel.onColumnSortClicked(column)}
            />
            <ManageColumnsModal
                columnDefinitions={WorkOrdersTableColumnDefinitions}
                availableColumns={state.availableColumns}
                selectedColumns={state.manageColumnsModalSelectedColumns}
                isDefaultColumnsChecked={state.isDefaultColumnsChecked}
                isVisible={state.isManageColumnsModalVisible}
                onApply={viewModel.onManageColumnsApply}
                onCancel={viewModel.onManageColumnsCancel}
                onColumnCheckChanged={viewModel.onManageColumnsColumnCheckChanged}
                onDefaultsToggled={viewModel.onManageColumnsDefaultsToggled}
                columnLabelOverrides={aliasedColumnLabels}
            />
            <ViewAllColumnsModal
                row={state.viewAllColumnsSelectedRow}
                viewModel={viewModel}
                availableColumns={state.availableColumns}
                columnLabelOverrides={aliasedColumnLabels}
            />
            <PrintWorkOrderModal
                isVisible={state.workOrderToPrint !== null}
                isLoading={state.workOrderToPrintIsLoading}
                onClose={() => viewModel.workOrderToPrintChanged(null)}
                onOptionSelected={(option) => viewModel.workOrderPrintOptionSelected(option)}
                companyName={state.pdfCompanyName}
                workOrder={state.pdfWorkOrder}
                assetParts={state.pdfAssetParts}
                asset={state.pdfAsset}
                selectedPrintOption={state.selectedPrintOption}
                companyLogoUrl={state.companyLogoUrl}
            />
            {(isMobile ?
            <ButtonCont className="user-work-orders-button-cont" >
                <Button 
                        type={ButtonType.Contained}
                        label="Edit Service Requests"
                        onClick={() => withRouter((router) => router.navigate(Routes.UserWorkRequests( viewModel.state.user.id.toString())))  }
                        isEnabled={state.hasUserWorkRequest}
                    />
            </ButtonCont> : <></> ) }
        </>
    )
}

function ViewAllColumnsModal({
    row,
    viewModel,
    availableColumns,
    columnLabelOverrides,
}: {
    row: WorkOrderTableRow | null
    viewModel: WorkOrdersTableViewModel
    availableColumns: WorkOrderTableColumn[]
    columnLabelOverrides?: Partial<Record<WorkOrderTableColumn, string>>
}): JSX.Element {
    const data = useLastNonNull(row)
    if (data === null) return <Modal isVisible={row !== null} size={ModalSize.Large} />

    return (
        <Modal
            isVisible={row !== null}
            useContentTag={true}
            onClose={() => viewModel.onViewAllColumnsModalClosed()}
            size={ModalSize.Large}
        >
            <ModalContent>
                <ModalHeader>
                    <ModalHeaderTitle>
                        {data.id} {data.tag && <Tag tag={data.tag} />}
                    </ModalHeaderTitle>
                </ModalHeader>
                <ModalBody>
                    <Grid columns={4}>
                        {availableColumns
                            .filter((it) => ![WorkOrderTableColumn.Tag, WorkOrderTableColumn.WorkOrder].includes(it))
                            .map((it) => (
                                <DataField
                                    key={it}
                                    label={columnLabelOverrides?.[it] ?? WorkOrdersTableColumnDefinitions[it].label}
                                    value={createColumnContent(false, it, data, true)}
                                />
                            ))}
                    </Grid>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

function createLabelsForAliasedColumns(
    aliases: Record<CompanyAlias, string>
): Partial<Record<WorkOrderTableColumn, string>> {
    return {
        [WorkOrderTableColumn.District]: aliases[CompanyAlias.District],
        [WorkOrderTableColumn.Group]: aliases[CompanyAlias.Group],
        [WorkOrderTableColumn.SubCompany]: aliases[CompanyAlias.SubCompany],
        [WorkOrderTableColumn.SubDistrict]: aliases[CompanyAlias.SubDistrict],
        [WorkOrderTableColumn.Unit]: aliases[CompanyAlias.Unit],
    }
}

function createLabelsForAliasedFilters(
    aliases: Record<CompanyAlias, string>
): Partial<Record<WorkOrderFilter, string>> {
    return {
        [WorkOrderFilter.District]: aliases[CompanyAlias.District],
        [WorkOrderFilter.Group]: aliases[CompanyAlias.Group],
        [WorkOrderFilter.SubCompany]: aliases[CompanyAlias.SubCompany],
        [WorkOrderFilter.SubDistrict]: aliases[CompanyAlias.SubDistrict],
        [WorkOrderFilter.Unit]: aliases[CompanyAlias.Unit],
    }
}

function createThresholdFilterValueOverrides(
    filters: Record<WorkOrderFilter, Filter<WorkOrderFilter>>
): Partial<Record<WorkOrderFilter, string>> {
    const unitForFilter = (type: ThresholdType, filter: Filter<any>): string =>
        Threshold.labelForUnit(type, thresholdUnitForValue(textFilterValue(filter)))

    return {
        [WorkOrderFilter.DaysUntil]: `${textFilterValue(filters[WorkOrderFilter.DaysUntil])} ${unitForFilter(
            ThresholdType.Days,
            filters[WorkOrderFilter.DaysUntilUnit]
        )}`,
        [WorkOrderFilter.HoursUntil]: `${textFilterValue(filters[WorkOrderFilter.HoursUntil])} ${unitForFilter(
            ThresholdType.Hours,
            filters[WorkOrderFilter.HoursUntilUnit]
        )}`,
        [WorkOrderFilter.MilesUntil]: `${textFilterValue(filters[WorkOrderFilter.MilesUntil])} ${unitForFilter(
            ThresholdType.Miles,
            filters[WorkOrderFilter.MilesUntilUnit]
        )}`,
    }
}

function createTableHead(
    columns: WorkOrderTableColumn[],
    isMobile: boolean,
    sort?: { column: WorkOrderTableColumn; order: SortOrder },
    columnLabelOverrides?: Partial<Record<WorkOrderTableColumn, string>>
): SortableTableHeadColumn[] {
    return columns
        .map(
            (column): SortableTableHeadColumn => ({
                size: isMobile
                    ? WorkOrdersTableColumnDefinitions[column].mobileSize ??
                    WorkOrdersTableColumnDefinitions[column].size
                    : WorkOrdersTableColumnDefinitions[column].size,
                alignment: WorkOrdersTableColumnDefinitions[column].alignment,
                isSortable: WorkOrdersTableColumnDefinitions[column].isSortable,
                sortOrder: column === sort?.column ? sort.order : SortOrder.None,
                content: <div>{columnLabelOverrides?.[column] ?? WorkOrdersTableColumnDefinitions[column].label}</div>,
            })
        )
        .concat({
            alignment: TableColumnAlignment.Center,
            size: TableColumnSize.MinContent,
            isSortable: false,
            sortOrder: SortOrder.None,
        })
}

function createTableData(
    isMobile: boolean,
    columns: WorkOrderTableColumn[],
    tableData: WorkOrderTableRow[],
    viewModel: WorkOrdersTableViewModel
): TableRow[] {
    return tableData.map((row) => ({
        entryId: row.id,
        columns: columns
            .map(
                (column): TableColumn => ({
                    size: WorkOrdersTableColumnDefinitions[column].size,
                    alignment: WorkOrdersTableColumnDefinitions[column].alignment,
                    content: createColumnContent(isMobile, column, row),
                    className: WorkOrdersTableColumnDefinitions[column].className,
                })
            )
            .concat([
                {
                    alignment: TableColumnAlignment.Center,
                    size: TableColumnSize.MinContent,
                    content: isMobile ? (
                        <Button
                            onClick={() => viewModel.onViewAllColumnClicked(row)}
                            tooltip={"Show All Columns"}
                            type={ButtonType.Icon}
                            iconUrl={ImageUrls.GlassesDark}
                        />
                    ) : (
                        <MoreColumn row={row} viewModel={viewModel} />
                    ),
                    className: "table-cell--more",
                },
            ]),
    }))
}

function createColumnContent(
    isMobile: boolean,
    column: WorkOrderTableColumn,
    row: WorkOrderTableRow,
    isViewAllColumns: boolean = false
): JSX.Element | null {
    switch (column) {
        case WorkOrderTableColumn.Tag:
            if (!row.tag) return null
            return <Tag tag={row.tag} showDayCount={true} />
        case WorkOrderTableColumn.WorkOrder:
            return <a href={"/work-orders/" + row.id.toString()} target={isMobile ? "_self" :"_blank"}>{row.number}</a>
        case WorkOrderTableColumn.Asset:
            return !isMobile ? (
                <a href={"/work-orders/" + row.id.toString()} target="_blank">{row.assetName}</a>
            ) : (
                <div>{row.assetName}</div>
            )
        case WorkOrderTableColumn.Status:
            return <div>{labelForWorkOrderStatus(row.status)}</div>
        case WorkOrderTableColumn.Urgency:
            return <FlagChip flags={[{ label: labelForUrgency(row.urgency), color: colorForUrgency(row.urgency) }]} />
        case WorkOrderTableColumn.DaysOpen:
            return <div>{row.daysOpen}</div>
        case WorkOrderTableColumn.Location:
            return <AssetLocation location={row.location} site={row.site} hasLinks={!isMobile} />
        case WorkOrderTableColumn.WorkToBePerformed:
            return (
                <List>
                    {row.workToBePerformed.map((it, i) => (
                        <li key={i} title={it}>
                            {!isViewAllColumns && it.length > 24 ? `${it.substr(0, 24)}...` : it}
                        </li>
                    ))}
                </List>
            )
        case WorkOrderTableColumn.DueDate:
            return (
                <div style={row.dueDate && row.dueDate < new Date() ? { color: "var(--color-invalid)" } : {}}>
                    {row.dueDate != null ? Strings.formatDate(row.dueDate) : "-"}
                </div>
            )
        case WorkOrderTableColumn.HoursUntil:
            return (
                <div style={row.hoursUntil && row.hoursUntil < 0 ? { color: "var(--color-invalid)" } : {}}>
                    {row.dueHourMeter != null && row.hoursUntil != null ? Strings.formatInteger(row.hoursUntil) : "-"}
                </div>
            )
        case WorkOrderTableColumn.MilesUntil:
            return (
                <div style={row.milesUntil && row.milesUntil < 0 ? { color: "var(--color-invalid)" } : {}}>
                    {row.dueOdometer != null && row.milesUntil != null ? Strings.formatInteger(row.milesUntil) : "-"}
                </div>
            )
        case WorkOrderTableColumn.DaysUntil:
            return (
                <div style={row.daysUntil && row.daysUntil < 0 ? { color: "var(--color-invalid)" } : {}}>
                    {row.dueDate != null && row.daysUntil != null ? Strings.formatInteger(row.daysUntil) : "-"}
                </div>
            )
        case WorkOrderTableColumn.AssignedTo:
            return (
                <List>
                    {row.assignedTo.map((it, i) => (
                        <li key={i + it.id}>{it.name}</li>
                    ))}
                </List>
            )
        case WorkOrderTableColumn.HourMeter:
            return <div>{formatHourMeter(row.hourMeter)}</div>
        case WorkOrderTableColumn.Odometer:
            return <div>{formatOdometer(row.odometer)}</div>
        case WorkOrderTableColumn.DaysInactive:
            return <div>{row.daysInactive != -1 ? row.daysInactive : "N/A"}</div>
        case WorkOrderTableColumn.ServiceCode:
            return (
                <List>
                    {row.serviceCode.map((it, i) => (
                        <li key={i}>{it}</li>
                    ))}
                </List>
            )
        case WorkOrderTableColumn.Class:
            return <div>{row.class}</div>
        case WorkOrderTableColumn.SubCompany:
            return <div>{row.subCompany}</div>
        case WorkOrderTableColumn.District:
            return <div>{row.district}</div>
        case WorkOrderTableColumn.SubDistrict:
            return <div>{row.subDistrict}</div>
        case WorkOrderTableColumn.Unit:
            return <div>{row.unit}</div>
        case WorkOrderTableColumn.Group:
            return <div>{row.group}</div>
        case WorkOrderTableColumn.City:
            return <div>{row.city}</div>
        case WorkOrderTableColumn.State:
            return <div>{row.state}</div>
        case WorkOrderTableColumn.ClosedDate:
            return (
                <div>
                    {row.closedDate != null ? Strings.formatDate(row.closedDate) : "-"}
                </div>
            )
    }
}

const formatHourMeter = (value: number) => (value === 0 ? "-" : safeParseFloat(value).toFixed(1))

const formatOdometer = (value: number) => (value === 0 ? "-" : safeParseInt(value))

const MoreColumn = ({ row, viewModel }: { row: WorkOrderTableRow; viewModel: WorkOrdersTableViewModel }) => {
    return (
        <Popover button={{ iconUrl: ImageUrls.More, theme: ButtonTheme.Light }}>
            <PopoverItem
                icon={ImageUrls.Print}
                label="Print"
                onClick={() => viewModel.workOrderToPrintChanged(row.id)}
            />
            <PopoverItem
                icon={ImageUrls.Glasses}
                label="View All Columns"
                onClick={() => viewModel.onViewAllColumnClicked(row)}
            />
        </Popover>
    )
}
