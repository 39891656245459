import { classNames } from "@lib/HtmlUtil"
import React from "react"

export function Widget({ className, children }: { className?: string; children?: React.ReactNode }): JSX.Element {
    return <div className={classNames("widget", className)}>{children}</div>
}

export function WidgetLabel({ label }: { label: string }): JSX.Element {
    return <div className="widget-label">{label}</div>
}

export function WidgetContent({ children }: { children?: React.ReactNode }): JSX.Element {
    return <div className="widget-content">{children}</div>
}
