import { throttle } from "@lib/EventUtil"
import { classNames, isNodeAncestor } from "@lib/HtmlUtil"
import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import { Button, ButtonTheme, ButtonType } from "./Button"

export type PopoverProps = {
    children?: React.ReactNode
    tooltip?: string
    button:
        | React.FunctionComponent<{ onClick: () => void }>
        | {
              theme?: ButtonTheme
              iconUrl?: string
          }
}

export function Popover(props: PopoverProps): JSX.Element {
    const [showPopover, setShowPopover] = useState(false)
    const containerRef = useRef<HTMLDivElement>(null)
    const popoverRef = useRef<HTMLDivElement>(null)
    const [positionTop, setPositionTop] = useState(false)
    const [positionStart, setPositionStart] = useState(false)

    const adjustPosition = () => {
        if (!popoverRef.current) return

        let el: Element | null = popoverRef.current.previousElementSibling
        let offsetY = (el as HTMLElement).offsetHeight
        let offsetX = (el as HTMLElement).offsetLeft

        while (el !== null && el !== document.body) {
            offsetY += (el as HTMLElement).offsetTop
            offsetX += (el as HTMLElement).offsetLeft
            el = (el as HTMLElement).offsetParent
        }

        offsetY += popoverRef.current.offsetHeight
        offsetX -= popoverRef.current.offsetWidth

        if (offsetY >= document.body.scrollHeight - 20) {
            if (!positionTop) setPositionTop(true)
        } else {
            if (positionTop) setPositionTop(false)
        }

        if (offsetX <= 20) {
            if (!positionStart) setPositionStart(true)
        } else {
            if (positionStart) setPositionStart(false)
        }
    }

    useLayoutEffect(() => adjustPosition(), [])

    useEffect(() => {
        const throttledAdjustPosition = throttle(adjustPosition, 32, true)
        window.addEventListener("resize", throttledAdjustPosition)
        return () => window.removeEventListener("resize", throttledAdjustPosition)
    }, [])

    useEffect(() => {
        if (!showPopover) return

        const handleExternalClick = (ev: MouseEvent) => {
            if (isNodeAncestor(ev.target as HTMLElement | null, containerRef.current, 5)) return
            setShowPopover(false)
        }

        document.addEventListener("click", handleExternalClick)
        return () => document.removeEventListener("click", handleExternalClick)
    }, [showPopover])

    return (
        <div className={classNames("popover-cont", showPopover ? "popover--active" : null)} ref={containerRef}>
            {typeof props.button === "function" ? (
                <props.button onClick={() => setShowPopover(!showPopover)} />
            ) : (
                <Button
                    className="popover-button"
                    onClick={() => setShowPopover(!showPopover)}
                    tooltip={props.tooltip}
                    type={ButtonType.Icon}
                    theme={props.button.theme}
                    iconUrl={props.button.iconUrl}
                >
                    {props.tooltip}
                </Button>
            )}
            <div
                className={classNames(
                    "popover",
                    positionTop ? "popover--position-top" : null,
                    positionStart ? "popover--position-end" : null
                )}
                ref={popoverRef}
            >
                {React.Children.map(props.children, (child) => {
                    if (!React.isValidElement<PopoverItemProps>(child)) return
                    if (child.type !== PopoverItem) return child

                    return React.cloneElement(child, {
                        ...child.props,
                        onClick: () => {
                            child.props.onClick?.()
                            setShowPopover(false)
                        },
                    })
                })}
            </div>
        </div>
    )
}

export type PopoverItemProps = {
    children?: React.ReactNode
    icon?: string
    label: string
    onClick?: () => void
    href?: string
    isEnabled?: boolean
}

export function PopoverItem(props: PopoverItemProps): JSX.Element {
    return props.href ? (
        <a
            target="_blank"
            className="popover-item"
            href={props.href}
            {...(props.isEnabled === false ? { disabled: true } : undefined)}
        >
            {props.icon && <div className="popover-item-icon" style={{ backgroundImage: `url(${props.icon})` }}></div>}
            <div className="popover-item-label"> {props.label}</div>
        </a>
    ) : (
        <button
            className="popover-item"
            onClick={props.onClick}
            {...(props.isEnabled === false ? { disabled: true } : undefined)}
        >
            {props.icon && <div className="popover-item-icon" style={{ backgroundImage: `url(${props.icon})` }}></div>}
            {props.children}
            <div className="popover-item-label"> {props.label}</div>
        </button>
    )
}
