import { BlocCoordinator } from "@lib/bloc/BlocCoordinator"
import { Contact } from "@model/contacts/Contact"
import { Urgency } from "@model/Urgency"
import { WorkOrderStatus } from "@model/workOrders/WorkOrder"
import { CompanyBloc, CompanyState } from "@state/company/CompanyBloc"
import { ApproveServiceQuoteBloc, ApproveServiceQuoteState } from "@state/serviceQuotes/ApproveServiceQuoteBloc"

type Dependencies = [ApproveServiceQuoteState, CompanyState]
type StateSelection = ApproveServiceQuoteState & {
    mechanics: Contact[]
    contacts: Contact[]
    isFormValid: boolean
}

export class ApproveServiceQuoteViewModel extends BlocCoordinator<Dependencies, StateSelection> {
    constructor(private approveServiceQuoteBloc: ApproveServiceQuoteBloc, private companyBloc: CompanyBloc) {
        super([approveServiceQuoteBloc, companyBloc])
    }

    transform = ([approveServiceQuoteState, companyState]: Dependencies): StateSelection => ({
        ...approveServiceQuoteState,
        mechanics: companyState.settings.mechanics,
        contacts: companyState.contacts,
        isFormValid: true,
    })

    vendorMessageChanged = (value: string) => this.approveServiceQuoteBloc.vendorMessageToChanged(value)
    purchaseOrderNumberChanged = (value: string) => this.approveServiceQuoteBloc.purchaseOrderNumberToChanged(value)
    notifyContactsChanged = (value: number[]) => this.approveServiceQuoteBloc.notifyContactsToChanged(value)
    createWorkOrderChanged = (value: boolean) => this.approveServiceQuoteBloc.createWorkOrderToChanged(value)

    workOrderStatusChanged = (value: WorkOrderStatus) => this.approveServiceQuoteBloc.workOrderStatusChanged(value)
    workOrderAssignedToChanged = (value: number) => this.approveServiceQuoteBloc.workOrderAssignedToChanged(value)
    workOrderUrgencyChanged = (value: Urgency) => this.approveServiceQuoteBloc.workOrderUrgencyChanged(value)
    workOrderDueDateChanged = (value: Date) => this.approveServiceQuoteBloc.workOrderDueDateChanged(value)
    workOrderDueHourMeterChanged = (value: string) => this.approveServiceQuoteBloc.workOrderDueHourMeterChanged(value)
    workOrderDueOdometerChanged = (value: string) => this.approveServiceQuoteBloc.workOrderDueOdometerChanged(value)
    workOrderSpecialInstructionsChanged = (value: string) =>
        this.approveServiceQuoteBloc.workOrderSpecialInstructionsChanged(value)
}
