export type User = {
    id: number
    name: string
    companyId: number
    companyName: string
    navItems: { [K in NavItem]?: boolean }
    permissionObjects: Permission[]
    hasAccess(permissionObject: PermissionObject, permisionType: PermissionType | PermissionType[]): boolean
    isInParentCompany(this: User): boolean
    impersonateCompany: boolean
    companyTypeID: number
    parentCompanyID: number | null
    personID: number | null
    isMechanic: boolean | null
}

export function hasAccess(
    this: User,
    permissionObject: PermissionObject,
    permisionType: PermissionType | PermissionType[]
) {
    var permission = this.permissionObjects.filter((x) => x.permissionObject === permissionObject)

    if (permission.length > 0) {
        var action = Array.isArray(permisionType)
            ? permission[0].actions.find((x) => permisionType.find((y) => y == x))
            : permission[0].actions.find((x) => x === permisionType)

        if (action) return true
    }

    return false
}

export function isInParentCompany(this: User) {
    return this.parentCompanyID == null
}

export type Permission = {
    permissionObject: PermissionObject
    actions: PermissionType[]
}

export enum PermissionObject {
    WorkRequest = 66,
    MaintenanceAdminPage = 67,
    MaintenanceGlobalSettings = 68,
    ServiceNotification = 69,
    RedYellowTag = 71,
    MaintenanceServiceCode = 72,
    MaintenanceServiceRecord = 73,
    MaintenanceServiceSchedule = 74,
    MaintenanceServiceTask = 75,
    MaintenanceServiceSteps = 76,
    MaintenanceWorkOrder = 77,
    CloseWorkOrder = 78,
    RollbackWorkOrder = 79,
    MaintenanceDashboard = 80,
    MaintenanceCBACode = 85,
    ServiceQuote = 91,
    ServiceQuoteVendor = 92,
}

export enum PermissionType {
    View = 1,
    Add = 2,
    Edit = 3,
    Delete = 4,
    Execute = 5,
}

export enum NavItem {
    Clients = 1,
    Assets = 3,
    Users = 4,
    ServiceSchedule = 5,
    MapSites = 6,
    Reports = 7,
    Elog = 8,
    Dashboard = 9,
    Maintenance = 10,
    Sites = 11,
    Tasks = 12,
    Company = 13,
    Notifications = 14,
    WinterOps = 16,
}

export function navItemForValue(value: number): NavItem {
    switch (value) {
        case NavItem.Clients:
            return NavItem.Clients
        case NavItem.Assets:
            return NavItem.Assets
        case NavItem.Users:
            return NavItem.Users
        case NavItem.ServiceSchedule:
            return NavItem.ServiceSchedule
        case NavItem.MapSites:
            return NavItem.MapSites
        case NavItem.Reports:
            return NavItem.Reports
        case NavItem.Elog:
            return NavItem.Elog
        case NavItem.Dashboard:
            return NavItem.Dashboard
        case NavItem.Maintenance:
            return NavItem.Maintenance
        case NavItem.Sites:
            return NavItem.Sites
        case NavItem.Tasks:
            return NavItem.Tasks
        case NavItem.Company:
            return NavItem.Company
        case NavItem.Notifications:
            return NavItem.Notifications
        case NavItem.WinterOps:
            return NavItem.WinterOps
        default:
            return NavItem.Maintenance
    }
}
