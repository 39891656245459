import React from "react"

const logoWToHMult = 0.8588961618565903

export function createLogoSize({ width, height }: { width?: number; height?: number }): {
    width: number
    height: number
} {
    return {
        width: width ?? (height ?? 0) / logoWToHMult,
        height: height ?? (width ?? 0) * logoWToHMult,
    }
}

export function PDFLogo({ width = 26.888, height = 23.094, companyLogoUrl = null }: { width?: number; height?: number, companyLogoUrl: string | null }) {
    return ( 
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26.888 23.094">
            { companyLogoUrl != null ?
                <image  xlinkHref={companyLogoUrl} width="100%" height="100%"/> : 
                <path
                    d="M726.249,1154.715v5.523h-.415q-8.349,0-16.7,0a1.934,1.934,0,0,1-2.067-2.084q0-9.457,0-18.914a1.941,1.941,0,0,1,2.1-2.089q11.354,0,22.707-.007a2.007,2.007,0,0,1,2.079,2.071c-.032,6.342-.016,12.683-.016,19.025a1.833,1.833,0,0,1-1.94,1.94c-.492,0-.983-.01-1.474,0-.257.007-.351-.092-.345-.344.011-.48,0-.96,0-1.441q0-4.618,0-9.235c0-.521,0-.521-.528-.521h-3.378c-.015.2-.037.352-.037.509,0,.96-.015,1.921,0,2.881.008.361-.09.495-.473.493q-5.271-.019-10.543,0c-.334,0-.442-.1-.44-.44q.021-3.362,0-6.723c0-.373.1-.476.473-.475q7.17.019,14.339.008c.585,0,.587,0,.583-.582a4.856,4.856,0,0,0-.03-.515.982.982,0,0,0-1.009-.919,19.986,19.986,0,0,1-2.639-.169,6.63,6.63,0,0,1-2.017-.752,7.725,7.725,0,0,0-3.908-1c-2.826.013-5.652,0-8.478,0a1.064,1.064,0,0,0-1.221,1.219q0,6.52,0,13.041a1.062,1.062,0,0,0,1.219,1.22q4.368,0,8.736-.005a6.848,6.848,0,0,0,3.484-.915,15.593,15.593,0,0,1,1.5-.7A2.625,2.625,0,0,1,726.249,1154.715Z"
                    transform="translate(-707.068 -1137.144)"
                    fill="#f4b824"
                />
            }
        </svg>
    )
}
