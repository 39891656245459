import { useBlocCoordinator } from "@lib/bloc/hooks"
import { usePreventNavigation } from "@lib/router/hooks"
import { MAX_INT_32 } from "@lib/TypeUtil"
import { labelForContact } from "@model/contacts/Contact"
import { labelForServiceRequestType, ServiceRequestType } from "@model/serviceRequests/ServiceRequest"
import { Threshold, ThresholdType, ThresholdUnit, thresholdUnitForValue } from "@model/Threshold"
import { WorkRequestType } from "@model/workRequests/WorkRequest"
import { UserBloc } from "@state/user/UserBloc"
import { Button, ButtonTheme, ButtonType } from "@ui/common/buttons/Button"
import { Chip, ChipCont } from "@ui/common/Chip"
import { CheckBox } from "@ui/common/form/CheckBox"
import { FormField, FormFieldDirection, FormFieldLabel } from "@ui/common/form/FormField"
import { FormSection, FormSectionHeaderAlignment } from "@ui/common/form/FormSection"
import { FormSectionCont } from "@ui/common/form/FormSectionCont"
import { MultiSelect } from "@ui/common/form/MultiSelect"
import { Select } from "@ui/common/form/Select"
import { TextInput, TextInputType } from "@ui/common/form/TextInput"
import { Grid, GridCont } from "@ui/common/grids/Grid"
import { LoadingIndicator, LoadingIndicatorContainer } from "@ui/common/LoadingIndicator"
import { Page } from "@ui/common/page/Page"
import { PageToolbar, PageToolbarButtons, PageToolbarTitle } from "@ui/common/page/PageToolbar"
import { DI } from "@ui/DI"
import React, { useEffect } from "react"
import { AdminSidebar } from "./AdminSidebar"
import {
    Modal,
    ModalBody,
    ModalButtons,
    ModalContent,
    ModalHeader,
    ModalHeaderSubTitle,
    ModalHeaderTitle,
    ModalSize,
} from "@ui/common/Modal"
import { GlobalSettingsScreenViewModel } from "./GlobalSettingsScreenViewModel"

export function GlobalSettingsScreen(): JSX.Element {
    const [state, viewModel] = useBlocCoordinator(() => DI.globalSettingsScreenViewModel())

    const aliasOptions = {
        invalidCharacterPattern: /[^a-zA-Z0-9-\s_]/g,
        placeholder: "Default",
        minLength: 1,
        maxLength: 15,
    }

    useEffect(() => {
        viewModel.onMounted()
    }, [])

    usePreventNavigation({ when: state.hasUnsavedChanges })

    return (
        <Page className="admin-page admin-page--global-settings">
            <PageToolbar>
                <PageToolbarTitle>Global Settings</PageToolbarTitle>
                <PageToolbarButtons>
                    {state.hasUnsavedChanges ? (
                        <Button
                            type={ButtonType.Text}
                            theme={ButtonTheme.Light}
                            label="Reset"
                            onClick={() => viewModel.onResetClicked()}
                        />
                    ) : null}
                    <Button type={ButtonType.Contained} label="Save" onClick={() => viewModel.onSaveClicked()} />
                </PageToolbarButtons>
            </PageToolbar>
            <LoadingIndicatorContainer>
                <AdminSidebar user={viewModel.getUser()}></AdminSidebar>
                <LoadingIndicator isLoading={state.isLoading} />
                <FormSectionCont className="page-content">

                    <FormSection header="Mechanics List" headerAlignment={FormSectionHeaderAlignment.Side}>
                        <GridCont>
                            <Grid columns={2} className="global-settings-grid mechanic-list">
                                <FormField>
                                    <MultiSelect
                                        options={state.contacts.filter((contact) => !contact.deleted).map((contact) => ({
                                            key: contact.id,
                                            isChecked:
                                                state.form.mechanics.find((mechanic) => mechanic.id === contact.id) !==
                                                undefined,
                                            label: labelForContact(contact, viewModel.getUser()),
                                            value: contact,
                                            isEnabled: !state.form.mechanics.find((mechanic) => mechanic.id === contact.id)?.hasActiveWorkOrders ,
                                            toolTip: (contact.hasActiveWorkOrders ? "Mechanic currently has assigned Work Orders" : undefined)                                         
                                        }))}
                                        onSelectionChanged={(selection) =>
                                            viewModel.onMechanicSelectionChanged(selection)
                                        }
                                    />
                                </FormField>
                            </Grid>
                            <ChipCont className="mechanics-chip-cont">
                                {state.form.mechanics
                                .filter((it) => !it.deleted)
                                .map((mechanic) => (
                                    <Chip
                                        key={mechanic.id}
                                        values={[labelForContact(mechanic, viewModel.getUser())]}
                                        onRemoveClicked={() => mechanic.hasActiveWorkOrders ? viewModel.showMechanicAssignedModal() : viewModel.onMechanicRemoveButtonClicked(mechanic.id)}
                                        isEnabled={!mechanic.hasActiveWorkOrders}
                                    />
                                ))}
                            </ChipCont>
                        </GridCont>
                    </FormSection>
                    <FormSection
                        className="form-section--upcoming-thresholds"
                        header="Upcoming Thresholds"
                        subHeader={'Define the threshold for labeling Service Requests as "Upcoming"'}
                        headerAlignment={FormSectionHeaderAlignment.Side}
                    >
                        <Grid columns={3} className="global-settings-grid">
                            <Grid columns={1}>
                                <FormFieldLabel>Preventative Maintenance</FormFieldLabel>
                                {Object.values(ThresholdType).map((type) => (
                                    <FormField label={Threshold.labelForType(type)} key={type}>
                                        <TextInput
                                            type={TextInputType.Integer}
                                            isEnabled={!state.loadedSettings.thresholds[WorkRequestType.Preventative].isReadOnly}
                                            min={0}
                                            max={
                                                state.form.thresholds[WorkRequestType.Preventative][type].unit ===
                                                    ThresholdUnit.Unit
                                                    ? MAX_INT_32
                                                    : 100
                                            }
                                            value={state.form.thresholds[WorkRequestType.Preventative][type].value}
                                            onChange={(value) =>
                                                viewModel.onThresholdValueChanged(
                                                    WorkRequestType.Preventative,
                                                    type,
                                                    value
                                                )
                                            }
                                        />
                                        <Select
                                            className="threshold-value-type"
                                            value={state.form.thresholds[WorkRequestType.Preventative][type].unit}
                                            isEnabled={!state.loadedSettings.thresholds[WorkRequestType.Preventative].isReadOnly}
                                            onChange={(value) =>
                                                viewModel.onThresholdUnitChanged(
                                                    WorkRequestType.Preventative,
                                                    type,
                                                    thresholdUnitForValue(value)
                                                )
                                            }
                                            options={Object.values(ThresholdUnit).map((unit) => ({
                                                value: unit,
                                                label: Threshold.labelForUnit(type, unit),
                                            }))}
                                        />
                                    </FormField>
                                ))}
                            </Grid>
                            <Grid columns={1}>
                                <FormFieldLabel>Inspection</FormFieldLabel>
                                {Object.values(ThresholdType).map((type) => (
                                    <FormField label={Threshold.labelForType(type)} key={type}>
                                        <TextInput
                                            type={TextInputType.Integer}
                                            isEnabled={!state.loadedSettings.thresholds[WorkRequestType.Inspection].isReadOnly}
                                            min={0}
                                            max={
                                                state.form.thresholds[WorkRequestType.Inspection][type].unit ===
                                                    ThresholdUnit.Unit
                                                    ? MAX_INT_32
                                                    : 100
                                            }
                                            value={state.form.thresholds[WorkRequestType.Inspection][type].value}
                                            onChange={(value) =>
                                                viewModel.onThresholdValueChanged(
                                                    WorkRequestType.Inspection,
                                                    type,
                                                    value
                                                )
                                            }
                                        />
                                        <Select
                                            className="threshold-value-type"
                                            value={state.form.thresholds[WorkRequestType.Inspection][type].unit}
                                            isEnabled={!state.loadedSettings.thresholds[WorkRequestType.Inspection].isReadOnly}
                                            onChange={(value) =>
                                                viewModel.onThresholdUnitChanged(
                                                    WorkRequestType.Inspection,
                                                    type,
                                                    thresholdUnitForValue(value)
                                                )
                                            }
                                            options={Object.values(ThresholdUnit).map((unit) => ({
                                                value: unit,
                                                label: Threshold.labelForUnit(type, unit),
                                            }))}
                                        />
                                    </FormField>
                                ))}
                            </Grid>
                            <Grid columns={1}>
                                <FormFieldLabel>Repair</FormFieldLabel>
                                {Object.values(ThresholdType).map((type) => (
                                    <FormField label={Threshold.labelForType(type)} key={type}>
                                        <TextInput
                                            type={TextInputType.Integer}
                                            isEnabled={!state.loadedSettings.thresholds[WorkRequestType.Service].isReadOnly}
                                            min={0}
                                            max={
                                                state.form.thresholds[WorkRequestType.Service][type].unit ===
                                                    ThresholdUnit.Unit
                                                    ? MAX_INT_32
                                                    : 100
                                            }
                                            value={state.form.thresholds[WorkRequestType.Service][type].value}
                                            onChange={(value) =>
                                                viewModel.onThresholdValueChanged(
                                                    WorkRequestType.Service,
                                                    type,
                                                    value
                                                )
                                            }
                                        />
                                        <Select
                                            className="thrshold-value-etype"
                                            value={state.form.thresholds[WorkRequestType.Service][type].unit}
                                            isEnabled={!state.loadedSettings.thresholds[WorkRequestType.Service].isReadOnly}
                                            onChange={(value) =>
                                                viewModel.onThresholdUnitChanged(
                                                    WorkRequestType.Service,
                                                    type,
                                                    thresholdUnitForValue(value)
                                                )
                                            }
                                            options={Object.values(ThresholdUnit).map((unit) => ({
                                                value: unit,
                                                label: Threshold.labelForUnit(type, unit),
                                            }))}
                                        />
                                    </FormField>
                                ))}
                            </Grid>
                            {viewModel.getUser().parentCompanyID == null ? (
                                <Grid columns={1}>
                                    <CheckBox
                                        label="Apply to Sub Companies"
                                        tooltip={"Applies Upcoming Thresholds and Work Order Automation settings to all Sub Companies."}
                                        isChecked={state.applyToSubCompanies}
                                        onCheckChanged={(isChecked) =>
                                            viewModel.onApplyToSubCompaniesChanged(isChecked)
                                        }
                                    />
                                </Grid>
                            ) : null}
                        </Grid>
                    </FormSection>
                    <FormSection
                        header="Work Order Automation"
                        subHeader={
                            'Automatically create a Work Order when a scheduled Service Request meets the established "Upcoming" threshold.'
                        }
                        headerAlignment={FormSectionHeaderAlignment.Side}
                    >
                        <GridCont>
                            <Grid columns={1}>
                                <CheckBox
                                    label="Preventative Maintenance"
                                    tooltip={Object.values(ThresholdType).some((type) => state.form.thresholds[WorkRequestType.Preventative][type].value != '') ? "" : "Please enter an Upcoming Threshold value to enable Work Order Automation"}
                                    isChecked={state.form.workOrderAutomation[WorkRequestType.Preventative]}
                                    isEnabled={Object.values(ThresholdType).some((type) => state.form.thresholds[WorkRequestType.Preventative][type].value != '') && !state.loadedSettings.workOrderAutomation.isReadOnly}
                                    onCheckChanged={(isChecked) =>
                                        viewModel.onWorkOrderAutomationChanged(WorkRequestType.Preventative, isChecked)
                                    }
                                />
                            </Grid>
                            <Grid columns={1}>
                                <CheckBox
                                    label="Inspection"
                                    tooltip={Object.values(ThresholdType).some((type) => state.form.thresholds[WorkRequestType.Inspection][type].value != '') ? "" : "Please enter an Upcoming Threshold value to enable Work Order Automation"}
                                    isChecked={state.form.workOrderAutomation[WorkRequestType.Inspection]}
                                    isEnabled={Object.values(ThresholdType).some((type) => state.form.thresholds[WorkRequestType.Inspection][type].value != '') && !state.loadedSettings.workOrderAutomation.isReadOnly}
                                    onCheckChanged={(isChecked) =>
                                        viewModel.onWorkOrderAutomationChanged(WorkRequestType.Inspection, isChecked)
                                    }
                                />
                            </Grid>
                            <Grid columns={1}>
                                <CheckBox
                                    label="Repair"
                                    tooltip={Object.values(ThresholdType).some((type) => state.form.thresholds[WorkRequestType.Service][type].value != '') ? "" : "Please enter an Upcoming Threshold value to enable Work Order Automation"}
                                    isChecked={state.form.workOrderAutomation[WorkRequestType.Service]}
                                    isEnabled={Object.values(ThresholdType).some((type) => state.form.thresholds[WorkRequestType.Service][type].value != '') && !state.loadedSettings.workOrderAutomation.isReadOnly}
                                    onCheckChanged={(isChecked) =>
                                        viewModel.onWorkOrderAutomationChanged(WorkRequestType.Service, isChecked)
                                    }
                                />
                            </Grid>
                        </GridCont>
                    </FormSection>
                    {/* <ButtonCont>
                    <Button type={ButtonType.Text} label={"Cancel"} onClick={() => viewModel.onCancelClicked()} />
                    <Button type={ButtonType.Contained} label={"Save"} onClick={() => viewModel.onSaveClicked()} />
                </ButtonCont> */}
                </FormSectionCont>                
            </LoadingIndicatorContainer>
            <MechanicAssignedModal isVisible={state.showMechanicAssignedModal} viewModel={viewModel}></MechanicAssignedModal>
        </Page>
    )
}

function MechanicAssignedModal({
    isVisible,
    viewModel
}: {
    isVisible: boolean,
    viewModel: GlobalSettingsScreenViewModel    
}): JSX.Element {
    return (
        <Modal useContentTag={true} isVisible={isVisible} isClosable={false}>
            <ModalContent>
                <ModalHeader>
                    <ModalHeaderTitle>Mechanic assigned to Work Orders</ModalHeaderTitle>
                </ModalHeader>
                <ModalBody>
                    Mechanic currently has assigned Work Orders. Please unassign the Work Orders before removing this mechanic.
                </ModalBody>
                <ModalButtons>
                    <Button label={"Close"} onClick={() => viewModel.onCloseMechanicAssignedModal()} />
                </ModalButtons>
            </ModalContent>
        </Modal>
    )
}
