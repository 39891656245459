import { BlocCoordinator } from "@lib/bloc/BlocCoordinator";
import { Contact } from "@model/contacts/Contact";
import { CompanyBloc, CompanyState } from "@state/company/CompanyBloc";
import { DeclineServiceQuoteBloc, DeclineServiceQuoteState } from "@state/serviceQuotes/DeclineServiceQuoteBloc";

type Dependencies = [DeclineServiceQuoteState, CompanyState]
type StateSelection = DeclineServiceQuoteState & {
    contacts: Contact[]
    isFormValid: boolean
}

export class DeclineServiceQuoteViewModel extends BlocCoordinator<Dependencies, StateSelection> {
    constructor(private declineServiceQuoteBloc: DeclineServiceQuoteBloc, private companyBloc: CompanyBloc) {
        super([declineServiceQuoteBloc, companyBloc])
    }

    transform = ([declineServiceQuoteState, companyState]: Dependencies): StateSelection => ({
        ...declineServiceQuoteState,
        contacts: companyState.contacts,
        isFormValid: declineServiceQuoteState.vendorMessage ? true : false
    })

    vendorMessageChanged = (value: string) => this.declineServiceQuoteBloc.vendorMessageToChanged(value)
    notifyContactsChanged = (value: number[]) => this.declineServiceQuoteBloc.notifyContactsToChanged(value)
    purchaseOrderNumberChanged = (value: string) => this.declineServiceQuoteBloc.purchaseOrderNumberToChanged(value)

    declineServiceQuote = async (serviceQuoteId: number) => {
        await this.declineServiceQuoteBloc.declineServiceQuote(serviceQuoteId)
    }
}