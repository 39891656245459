import React from "react"
import { createLogoSize, PDFLogo } from "./PDFLogo"
import { PDFAsset } from "../models/PDFAsset"
import { PDFSectionBorder } from "./PDF"
import { isClosed, PDFWorkOrder } from "../models/PDFWorkOrder"
import { PDFSection } from "./PDFSection"
import { PDFWorkRequest } from "../models/PDFWorkRequest"
import { Strings } from "@lib/Strings"


export type PDFHeaderProps = {
    title: string
    asset: PDFAsset
    workRequest?: PDFWorkRequest
    workOrder?: PDFWorkOrder
    showLastKnownLocation?: boolean
    showSerialNumber?: boolean
    showAssignedTo?: boolean
    companyLogoUrl: string | null
}

export function PDFHeader({
    title,
    asset,
    workOrder,
    workRequest,
    showSerialNumber = true,
    showLastKnownLocation = true,
    showAssignedTo = true,
    companyLogoUrl,
}: PDFHeaderProps): JSX.Element {
    return (
        <>
            <PDFSection style={{ display: "grid", gap: "20px", gridTemplateColumns: "2fr auto", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                    <PDFLogo {...createLogoSize({ height: 65 })} companyLogoUrl={companyLogoUrl} />
                    <div style={{ display: "grid", gridTemplateColumns: "1fr", gap: 4 }}>
                        <div style={{ fontWeight: 700, fontSize: "2.0rem" }}>{title}</div>
                        <div>{asset.label}</div>
                        {showSerialNumber && (
                            <div style={{ fontSize: ".9em" }}>
                                <strong>Serial Number/VIN:</strong> {asset.serialNumber}
                            </div>
                        )}
                    </div>
                </div>
                {workOrder && (
                    <div
                        style={{
                            border: PDFSectionBorder,
                            padding: 4,
                            display: "grid",
                            gap: "2px 32px",
                            gridTemplateColumns: "auto auto",
                            justifyContent: "space-between",
                            fontSize: "1.2rem",
                        }}
                    >
                        {workOrder.assignedTo && showAssignedTo && (
                            <>
                                <WorkOrderInfoLabel>Assigned To</WorkOrderInfoLabel>
                                <div>{workOrder.assignedTo}</div>
                            </>
                        )}

                        <WorkOrderInfoLabel>Closed Date</WorkOrderInfoLabel>
                        <div>{workOrder?.closedDate ?? ""}</div>

                        <WorkOrderInfoLabel>Due Date</WorkOrderInfoLabel>
                        <div>
                            {workRequest
                                ? workRequest.originalWorkRequest.dueDate?.toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                  })
                                : workOrder.dueDate}
                        </div>
                        <WorkOrderInfoLabel>Due Hour Meter</WorkOrderInfoLabel>
                        <div>{workRequest ? workRequest.originalWorkRequest.dueHourMeter : workOrder.dueHourMeter}</div>
                        <WorkOrderInfoLabel>Due Odometer</WorkOrderInfoLabel>
                        <div>{workRequest ? workRequest.originalWorkRequest.dueOdometer : workOrder.dueOdometer}</div>
                        <WorkOrderInfoLabel>Urgency</WorkOrderInfoLabel>
                        <div>
                            {workRequest ? Strings.forceFirstLetterUpperCase(workRequest.originalWorkRequest.urgency.toString()) : workOrder.urgency}
                        </div>
                        
                    </div>
                )}
            </PDFSection>
            {asset.lastKnownLocation && showLastKnownLocation && (
                <PDFSection>
                    <div style={{ fontStyle: "italic", textAlign: "center", fontSize: ".9em", padding: "8px 24px" }}>
                        <strong>Last Known Location:</strong> {asset.lastKnownLocation}
                    </div>
                </PDFSection>
            )}
        </>
    )
}

function WorkOrderInfoLabel({ children }: { children: React.ReactNode }) {
    return <div style={{ fontWeight: 700 }}>{children}</div>
}
