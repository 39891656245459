import React, { CSSProperties } from "react"

export enum PDFCheckDirection {
    Top = "top",
    Right = "right",
}

export function PDFCheck({
    label,
    direction = PDFCheckDirection.Right,
    style,
    checked = false,
}: {
    label?: string
    direction?: PDFCheckDirection
    style?: CSSProperties
    checked?: boolean
}): JSX.Element {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                gap: 4,
                whiteSpace: "nowrap",
                flexDirection: direction == PDFCheckDirection.Top ? "column" : "row",
                ...style,
            }}
        >
            <div
                style={{
                    width: 12,
                    height: 12,
                    border: "1px solid #000000",
                    padding: "1px",
                    backgroundColor: checked ? "#000000" : undefined,
                }}
            />
            {label && (
                <span style={{ fontSize: "1.4rem", lineHeight: 1, order: direction == PDFCheckDirection.Top ? -1 : 0 }}>
                    {label}
                </span>
            )}
        </div>
    )
}
