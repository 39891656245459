import { Outcome } from "@ethossoftworks/outcome"
import { Bloc } from "@lib/bloc/Bloc"
import { hasAccess, isInParentCompany, User } from "@model/user/User"
import { UserService } from "@service/user/UserService"

export type UserState = {
    user: User
}

enum UserEffect {
    FetchUser = "fetchUser",
    AuthenticateUser = "authenticateUser",
}

export class UserBloc extends Bloc<UserState> {
    constructor(private userService: UserService) {
        super(newUserState())
    }

    authenticateUser = () =>
        this.effect({
            id: this.authenticateUser,
            block: async (job): Promise<Outcome<User>> => {
                const outcome = await job.pause(this.userService.authenticateUser())
                if (outcome.isError()) return outcome

                this.update({ user: outcome.value })
                return outcome
            },
        })
}

function newUserState(): UserState {
    return {
        user: {
            id: -1,
            name: "",
            companyId: -1,
            companyName: "",
            navItems: {},
            permissionObjects: [],
            hasAccess: hasAccess,
            isInParentCompany: isInParentCompany,
            impersonateCompany: false,
            companyTypeID: 1,
            parentCompanyID: null,
            personID: null,
            isMechanic: null,
        },
    }
}
