import { Ok, Outcome } from "@ethossoftworks/outcome"
import { Bloc } from "@lib/bloc/Bloc"
import { arrayToObject } from "@lib/TypeUtil"
import { AsyncStatus } from "@model/AsyncStatus"
import { ServiceQuote, ServiceQuoteStatus } from "@model/serviceQuotes/ServiceQuote"
import { ServiceQuoteService } from "@service/serviceQuotes/ServiceQuoteService"
import { ServiceQuoteScreenBloc } from "./ServiceQuoteScreenBloc"

export type DeclineServiceQuoteState = {
    effectStatus: Record<DeclineServiceQuoteEffect, AsyncStatus>
    vendorMessage: string
    purchaseOrderNumber: string
    notifyContacts: number[]
}

export function newDeclineServiceQuoteForm(): DeclineServiceQuoteState {
    return {
        effectStatus: arrayToObject(Object.values(DeclineServiceQuoteEffect), (it) => [it, AsyncStatus.idle()]),
        vendorMessage: "",
        purchaseOrderNumber: "",
        notifyContacts: [],
    }
}

export enum DeclineServiceQuoteEffect {
    DeclineQuote = "declineQuote",
}

export class DeclineServiceQuoteBloc extends Bloc<DeclineServiceQuoteState> {
    constructor(private serviceQuoteService: ServiceQuoteService, private serviceQuoteScreenBloc: ServiceQuoteScreenBloc) {
        super(newDeclineServiceQuoteForm(), { persistStateOnDispose: false })
    }

    notifyContactsToChanged = (value: number[]) => this.update({ notifyContacts: value })
    vendorMessageToChanged = (value: string) => this.update({ vendorMessage: value })
    purchaseOrderNumberToChanged = (value: string) => this.update({ purchaseOrderNumber: value })

    private effectStatusChanged = (effect: DeclineServiceQuoteEffect, status: AsyncStatus) =>
        this.update({
            effectStatus: {
                ...this.state.effectStatus,
                [effect]: status,
            },
        })

    declineServiceQuote = (serviceQuoteId: number): Promise<Outcome<Ok<ServiceQuote>>> => {
        return this.effect({
            id: DeclineServiceQuoteEffect.DeclineQuote,
            block: async (job) => {
                this.effectStatusChanged(DeclineServiceQuoteEffect.DeclineQuote, AsyncStatus.busy())
                let serviceQuoteResponse = await this.serviceQuoteService.fetchServiceQuote(serviceQuoteId)
                if (serviceQuoteResponse.isError()) {
                    return Outcome.error(serviceQuoteResponse)
                }
                let serviceQuote = serviceQuoteResponse.value
                for (let serviceQuoteItem of serviceQuote.serviceQuoteItems) {
                    serviceQuoteItem.status = ServiceQuoteStatus.Declined
                    serviceQuoteItem.rejectionReason = this.state.vendorMessage
                }
                serviceQuote.Status = ServiceQuoteStatus.Declined
                serviceQuote.rejectionReason = this.state.vendorMessage
                serviceQuote.notifyContacts = this.state.notifyContacts
                var response = await job.pause(this.serviceQuoteService.updateServiceQuote(serviceQuote))
                if (response.isError()) {
                    return Outcome.error(response)
                }

                let updatedServiceQuote = response.value

                if (!updatedServiceQuote) return Outcome.error(undefined)
                
                this.serviceQuoteScreenBloc.statusChanged(ServiceQuoteStatus.Declined)

                return Outcome.ok(response)
            },
        })
    }
}
