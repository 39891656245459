import { classNames } from "@lib/HtmlUtil"
import { isString } from "@lib/TypeUtil"
import React from "react"

export type CheckBoxProps = {
    className?: string
    isChecked: boolean
    tooltip?: string
    label?: string | React.ReactNode
    isEnabled?: boolean
    onCheckChanged: (isChecked: boolean) => void
    style?: React.CSSProperties
}

export function CheckBox({
    label,
    tooltip,
    isChecked,
    onCheckChanged,
    className,
    isEnabled = true,
    style,
}: CheckBoxProps): JSX.Element {
    return (
        <label
            className={classNames("checkbox", className ? className : null, !isEnabled ? "checkbox--disabled" : null)}
            style={style}
            title={tooltip ?? (isString(label) ? label : undefined)}
        >
            <input
                className="checkbox-input-field"
                type="checkbox"
                checked={isChecked}
                onChange={(ev) => onCheckChanged(ev.target.checked)}
                {...(!isEnabled ? { disabled: true } : {})}
            />
            <div className="checkbox-input-mask"></div>
            {label && <div className="checkbox-label">{label}</div>}
        </label>
    )
}
