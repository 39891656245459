import { useLastNonNull } from "@lib/Hooks"
import { labelForWorkRequest, WorkRequest, WorkRequestType } from "@model/workRequests/WorkRequest"
import { Button, ButtonType } from "@ui/common/buttons/Button"
import { FormField, FormFieldDirection, FormFieldLabel, FormFieldNote } from "@ui/common/form/FormField"
import { TextArea } from "@ui/common/form/TextArea"
import { Grid, GridGap, GridJustification } from "@ui/common/grids/Grid"
import { Modal, ModalBody, ModalButtons, ModalContent, ModalHeader, ModalHeaderTitle } from "@ui/common/Modal"
import React from "react"
import { FormDivider, FormDividerMargin } from "../common/form/FormDivider"
import { Radio } from "../common/form/Radio"

export function DismissWorkOrderModal({
    onReasonChanged,
    onCancel,
    onConfirm,
    onWorkRequestsToDismissChanged,    
    ...props    
}: {
    data: { reason: string; workRequests: WorkRequest[]; workRequestsToDelete: number[] } | null
    hasDismissWorkRequestPermission: boolean
    onReasonChanged: (value: string) => void
    onWorkRequestsToDismissChanged: (workRequestId: number, shouldDismiss: boolean) => void
    onCancel: () => void
    onConfirm: (reason: string, workRequestsToDelete: number[]) => void
}): JSX.Element {
    const data = useLastNonNull(props.data)
    const isVisible = props.data !== null
    if (data === null) return <Modal isVisible={isVisible} />

    return (
        <Modal isVisible={isVisible} useContentTag={true} onClose={onCancel}>
            <ModalContent>
                <ModalHeader>
                    <ModalHeaderTitle>Delete Work Order</ModalHeaderTitle>
                </ModalHeader>
                <ModalBody>
                    <Grid columns={1}>
                        <div>
                            Are you sure you wish to delete this Work Order?{" "}
                            <strong>This action cannot be undone.</strong>
                        </div>
                        {data.workRequests.filter(x => x.workRequestType == WorkRequestType.Inspection || x.workRequestType == WorkRequestType.Preventative).length > 0 && (
                            <>
                                <FormDivider margin={FormDividerMargin.None} />
                                <FormField label={"Preventative Maintenance and Inspections"} direction={FormFieldDirection.Column} className={"radio-list"}>
                                    <FormFieldNote>
                                        The following scheduled Service Requests will be returned to the Service Request queue.
                                    </FormFieldNote>
                                    <Grid columns={2} justifyItems={GridJustification.End} style={{ minHeight: "10px" }}>
                                        <FormFieldLabel></FormFieldLabel>
                                        <FormFieldLabel className={"radio-column-title"}>Return</FormFieldLabel>
                                    </Grid>
                                    {data.workRequests.filter(x => x.workRequestType == WorkRequestType.Inspection || x.workRequestType == WorkRequestType.Preventative).map((it) => (
                                        <>
                                            <Grid columns={2} justifyItems={GridJustification.Stretch}>
                                                <FormFieldLabel style={{ fontWeight: "normal", fontStyle: "italic" }}>{labelForWorkRequest(it)}</FormFieldLabel>
                                                <Grid columns={1} justifyItems={GridJustification.End}>
                                                    <Radio key={it.id}
                                                        label={""}
                                                        isChecked={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                    ))}
                                </FormField>
                            </>
                        )}
                        {data.workRequests.filter(x => x.workRequestType == WorkRequestType.Service).length > 0 && (
                            <>
                                <FormDivider margin={FormDividerMargin.None} />
                                <FormField label={"Service Requests"} direction={FormFieldDirection.Column} className={"radio-list"}>
                                    <FormFieldNote>
                                        The following Service Requests can be dismissed or returned to the Service Request queue.
                                    </FormFieldNote>
                                    <Grid columns={2} justifyItems={GridJustification.Stretch} style={{ minHeight: "10px" }}>
                                        <FormFieldLabel></FormFieldLabel>
                                        <FormField>
                                            <Grid columns={4} gap={GridGap.Small} justifyItems={GridJustification.End}>
                                                <label></label>
                                                <label></label>
                                                { props.hasDismissWorkRequestPermission ? <FormFieldLabel className={"radio-column-title"}>Dismiss</FormFieldLabel> : <label></label>}
                                                <FormFieldLabel className={"radio-column-title"}>Return</FormFieldLabel>
                                            </Grid>
                                        </FormField>
                                    </Grid>
                                    {data.workRequests.filter(x => x.workRequestType == WorkRequestType.Service).map((it) => (
                                        <>
                                            <Grid columns={2} justifyItems={GridJustification.Stretch}>
                                                <FormFieldLabel style={{ fontWeight: "normal", fontStyle: "italic" }}>{labelForWorkRequest(it)}</FormFieldLabel>
                                                <FormField>
                                                    <Grid columns={4} gap={GridGap.Small} justifyItems={GridJustification.End}>
                                                        <label></label>
                                                        <label></label>
                                                        { props.hasDismissWorkRequestPermission ? 
                                                        <Radio key={it.id + "A"}
                                                            label={""}
                                                            isChecked={data.workRequestsToDelete.includes(it.id)}
                                                            onSelected={() => onWorkRequestsToDismissChanged(it.id, true)}
                                                        /> : <label></label>}
                                                        <Radio key={it.id + "B"}
                                                            label={""}
                                                            isChecked={!data.workRequestsToDelete.includes(it.id)}
                                                            onSelected={() => onWorkRequestsToDismissChanged(it.id, false)}
                                                        />
                                                    </Grid>
                                                </FormField>
                                            </Grid>
                                        </>
                                    ))}
                                </FormField>
                            </>
                        )}
                        <FormDivider margin={FormDividerMargin.None} />
                        <FormField label={"Reason*"}>
                            <TextArea onChange={onReasonChanged} value={data.reason} maxLength={250} />
                        </FormField>
                    </Grid>
                </ModalBody>
                <ModalButtons>
                    <Button label="Cancel" type={ButtonType.Text} onClick={onCancel} />
                    <Button
                        label="Delete Work Order"
                        isEnabled={data.reason.trim() !== ""}
                        onClick={() => onConfirm(data.reason, data.workRequestsToDelete)}
                    />
                </ModalButtons>
            </ModalContent>
        </Modal>
    )
}
