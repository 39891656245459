export function monthIndexForDelta(current: number, delta: number): number {
    if (current + delta > 0) return current + delta
    return current + delta + 12
}

export function labelForMonthIndex(index: number) {
    switch (index) {
        case 0:
            return "Jan"
        case 1:
            return "Feb"
        case 2:
            return "Mar"
        case 3:
            return "Apr"
        case 4:
            return "May"
        case 5:
            return "Jun"
        case 6:
            return "Jul"
        case 7:
            return "Aug"
        case 8:
            return "Sep"
        case 9:
            return "Oct"
        case 10:
            return "Nov"
        case 11:
            return "Dec"
        default:
            return "Jan"
    }
}

export function shortDateFormat(date: Date) {
    return `${String(date.getMonth() + 1).padStart(2, "0")}/${String(date.getDate()).padStart(
        2,
        "0"
    )}/${date.getFullYear()}`
}
