import { isString } from "@lib/TypeUtil"
import React, { useLayoutEffect, useRef, useState } from "react"

export type TextAreaProps = {
    type?: TextAreaType
    onChange?: (value: string) => void
    outlineInvalid?: boolean
} & Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, "onChange">

export enum TextAreaType {
    Padded = "padded",
    Empty = "empty",
}

export function TextArea({
    type = TextAreaType.Padded,
    onChange,
    outlineInvalid = false,
    ...rest
}: TextAreaProps): JSX.Element {
    const ref = useRef<HTMLTextAreaElement>(null)
    const [height, setHeight] = useState(0)

    useLayoutEffect(() => {
        if (!isString(rest.value)) return
        resize(ref.current, height, setHeight, rest.value)
    }, [rest.value])

    let outlineInvalidClass = outlineInvalid ? "outlineInvalid" : ""
    return (
        <>
            <textarea
                ref={ref}
                className="text-area text-area--hidden"
                data-type={type}
                style={
                    ref.current !== null
                        ? { width: `calc(100% + ${ref.current.offsetWidth - ref.current.clientWidth}px)` }
                        : {}
                }
                {...rest}
                onChange={() => {}}
            />
            <textarea
                className={"text-area " + outlineInvalidClass}
                data-type={type}
                {...rest}
                onChange={(ev) => onChange?.(ev.currentTarget.value)}
                style={{ ...rest.style, ...(height !== 0 ? { height: `${height}px` } : {}) }}
            />
        </>
    )
}

function resize(
    element: HTMLTextAreaElement | null,
    currentHeight: number,
    setHeight: (height: number) => void,
    value: string = ""
) {
    if (!element) return
    element.value = value
    const height = element.scrollHeight + (element.offsetHeight - element.clientHeight)
    if (height === currentHeight) return
    setHeight(height)
}
