import { useBloc } from "@lib/bloc/hooks"
import { labelForUrgency, Urgency, urgencyForValue } from "@model/Urgency"
import { WorkOrder } from "@model/workOrders/WorkOrder"
import { labelForWorkRequest,  WorkRequest } from "@model/workRequests/WorkRequest"
import { AddWorkRequestToWorkOrderForm } from "@model/workRequests/AddWorkRequestToWorkOrderForm"
import { Button, ButtonType } from "@ui/common/buttons/Button"
import { DatePicker, TextInputDatePicker } from "@ui/common/form/DatePicker"
import { FormDivider, FormDividerMargin } from "@ui/common/form/FormDivider"
import { FormField, FormFieldLabel } from "@ui/common/form/FormField"
import { Radio } from "@ui/common/form/Radio"
import { Select } from "@ui/common/form/Select"
import { TextArea } from "@ui/common/form/TextArea"
import { TextInput, TextInputType } from "@ui/common/form/TextInput"
import { Grid, GridCont, GridGap } from "@ui/common/grids/Grid"
import {
    Modal,
    ModalBody,
    ModalButtons,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalSize,
} from "@ui/common/Modal"
import { DI } from "@ui/DI"
import React, { useEffect } from "react"
import { mapToWorkRequestHeaderRows, WorkRequestHeaderTable } from "@ui/workOrders/WorkRequestHeaderTable"

export type AddWorkRequestToWorkOrderModalProps = {
    onAddClicked: (workOrder: WorkOrder, form: AddWorkRequestToWorkOrderForm) => void
    onCancelClicked: () => void
    workRequests: WorkRequest[]
    workOrders: WorkOrder[]
    workOrderRequests: WorkRequest[]
    isVisible: boolean
    isSaving: boolean
}

export type onAddClickedCallback = (args: {}) => void

export function AddWorkRequestToWorkOrderModal(props: AddWorkRequestToWorkOrderModalProps): JSX.Element {
    return (
        <Modal isVisible={props.isVisible} useContentTag={true} onClose={props.onCancelClicked} size={ModalSize.Large}>
            <AddWorkRequestToWorkOrderModalContent {...props} />
        </Modal>
    )
}

function AddWorkRequestToWorkOrderModalContent(props: AddWorkRequestToWorkOrderModalProps): JSX.Element {
    const [state, bloc] = useBloc(() => DI.addWorkRequestToWorkOrderBloc())

    useEffect(() => {
        if (props.workOrders.length > 0 && state.selectedWorkOrder === -1)
            bloc.selectedWorkOrderChanged(props.workOrders[0])
    }, [props.workOrders])

    return (
        <ModalContent>
            <ModalHeader>
                <ModalHeaderTitle>Add Service Requests to Existing Work Order</ModalHeaderTitle>
            </ModalHeader>
            <ModalBody>
                <Grid columns={1} gap={GridGap.Small}>
                    <FormFieldLabel>Service Requests:</FormFieldLabel>
                    <WorkRequestHeaderTable workRequests={mapToWorkRequestHeaderRows(props.workRequests)}></WorkRequestHeaderTable>
                    
                    <FormDivider margin={FormDividerMargin.Small} />
                    <FormFieldLabel>Add to Work Order:</FormFieldLabel>
                    <Grid columns={3}>
                        {props.workOrders.map((it) => (
                            <Radio
                                key={it.id}
                                isChecked={it.id === state.selectedWorkOrder}
                                onSelected={() => bloc.selectedWorkOrderChanged(it)}
                                label={ it.number ? it.number?.toString() : ""}
                                subLabel={props.workOrderRequests
                                    .filter((request) => request.workOrderId === it.id)
                                    .map((request) => (
                                        <div key={request.id}>{labelForWorkRequest(request)}</div>
                                    ))}
                            />
                        ))}
                    </Grid>
                    <FormDivider margin={FormDividerMargin.Small} />
                    <FormFieldLabel>Update Work Order:</FormFieldLabel>
                    <GridCont>
                        <Grid columns={2}>
                            <FormField label="Urgency*">
                                <Select
                                    value={state.form.urgency}
                                    options={Object.values(Urgency).map((it) => ({
                                        label: labelForUrgency(it),
                                        value: it,
                                    }))}
                                    onChange={(value) => bloc.urgencyChanged(urgencyForValue(value))}
                                />
                            </FormField>
                            <FormField label="Due Date*">
                                <TextInputDatePicker
                                    value={state.form.dueDate}
                                    onChange={(value) => bloc.dueDateChanged(value)}
                                />
                            </FormField>
                            <FormField label="Due Hour Meter">
                                <TextInput
                                    value={state.form.dueHourMeter}
                                    onChange={(value) => bloc.dueHourMeterChanged(value)}
                                    min={0}
                                    type={TextInputType.Decimal}
                                />
                            </FormField>
                            <FormField label="Due Odometer">
                                <TextInput
                                    value={state.form.dueOdometer}
                                    onChange={(value) => bloc.dueOdometerChanged(value)}
                                    min={0}
                                    type={TextInputType.Integer}
                                />
                            </FormField>
                        </Grid>
                        <Grid columns={1}>
                            <FormField label="Special Instructions">
                                <TextArea
                                    maxLength={1000}
                                    value={state.form.specialInstructions}
                                    onChange={(value) => bloc.specialInstructionsChanged(value)}
                                />
                            </FormField>
                        </Grid>
                    </GridCont>
                </Grid>
            </ModalBody>
            <ModalButtons>
                <Button label="Cancel" type={ButtonType.Text} onClick={props.onCancelClicked} />
                <Button
                    label="Add"
                    type={ButtonType.Contained}
                    onClick={() => {
                        const workOrder = props.workOrders.find((it) => it.id === state.selectedWorkOrder)
                        if (!workOrder) return
                        props.onAddClicked(workOrder, state.form)
                    }}
                    isEnabled={bloc.isFormValid() && !state.isSaving}
                />
            </ModalButtons>
        </ModalContent>
    )
}
