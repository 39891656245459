import { BlocCoordinator } from "@lib/bloc/BlocCoordinator"
import { Contact } from "@model/contacts/Contact"
import { Urgency } from "@model/Urgency"
import { WorkOrderStatus } from "@model/workOrders/WorkOrder"
import { CompanyBloc, CompanyState } from "@state/company/CompanyBloc"
import { CreateWorkOrderBloc, CreateWorkOrderState } from "@state/workOrders/CreateWorkOrderBloc"

type Dependencies = [CreateWorkOrderState, CompanyState]
type StateSelection = CreateWorkOrderState & {
    mechanics: Contact[]
    contacts: Contact[]
    isFormValid: boolean
}

export class CreateWorkOrderViewModel extends BlocCoordinator<Dependencies, StateSelection> {
    constructor(private createWorkOrderBloc: CreateWorkOrderBloc, private companyBloc: CompanyBloc) {
        super([createWorkOrderBloc, companyBloc])
        // this.state.dueOdometer = Math.min(...this.state.workRequests.map((x) => x.dueOdometer)).toString()
        // this.state.dueHourMeter = Math.min(...this.state.workRequests.map((x) => x.dueHourMeter)).toString()
        // this.state.dueDate = getMinDueDateAsDate(this.state.workRequests)
    }

    transform = ([createWorkOrderState, companyState]: Dependencies): StateSelection => ({
        ...createWorkOrderState,
        mechanics: companyState.settings.mechanics,
        contacts: companyState.contacts,
        isFormValid:
            createWorkOrderState.status === WorkOrderStatus.Pending ||
            ((createWorkOrderState.status === WorkOrderStatus.Open ||
                createWorkOrderState.status === WorkOrderStatus.InProgress) &&
                createWorkOrderState.dueDate != null &&
                createWorkOrderState.assignedTo !== -1),
    })

    statusChanged = (value: WorkOrderStatus) => this.createWorkOrderBloc.statusChanged(value)
    assignedToChanged = (value: number) => this.createWorkOrderBloc.assignedToChanged(value)
    urgencyChanged = (value: Urgency) => this.createWorkOrderBloc.urgencyChanged(value)
    dueDateChanged = (value: Date) => this.createWorkOrderBloc.dueDateChanged(value)
    dueHourMeterChanged = (value: string) => this.createWorkOrderBloc.dueHourMeterChanged(value)
    dueOdometerChanged = (value: string) => this.createWorkOrderBloc.dueOdometerChanged(value)
    specialInstructionsChanged = (value: string) => this.createWorkOrderBloc.specialInstructionsChanged(value)
    notifyContactsChanged = (value: number[]) => this.createWorkOrderBloc.notifyContactsToChanged(value)
}
