/**
 * Determines items that have been added, removed, or modified in a list
 *
 * @param orig The original list
 * @param updated The updated list
 * @param uniqueId function to retrieve the unique id for each id
 * @param isEqual function to compare equality for each item to see if they've been modified. Default is Object.is
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Equality_comparisons_and_sameness for equality
 * comparison
 * @returns A tuple of [addedItems, removedItems, modifiedItems]
 */
export function diffList<T>(
    orig: T[],
    updated: T[],
    uniqueId: (it: T) => unknown,
    isEqual: (a: T, b: T) => boolean = Object.is
): [T[], T[], T[]] {
    const origMap = new Map()
    const updatedMap = new Map()

    for (const it of orig) {
        origMap.set(uniqueId(it), it)
    }

    for (const it of updated) {
        updatedMap.set(uniqueId(it), it)
    }

    const modifiedItems: T[] = []
    const removedItems: T[] = []
    for (const it of orig) {
        if (!updatedMap.has(uniqueId(it))) removedItems.push(it)
    }

    const addedItems: T[] = []
    for (const it of updated) {
        if (!origMap.has(uniqueId(it))) {
            addedItems.push(it)
        } else if (!isEqual(origMap.get(uniqueId(it)), it)) {
            modifiedItems.push(it)
        }
    }

    return [addedItems, removedItems, modifiedItems]
}
