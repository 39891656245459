import React from "react"

export const InlinedSvgs = React.memo(function InlinedSvgs(): JSX.Element {
    return (
        <div className="inlined-svgs">
            <svg
                id="inlined-svg--arrow-small"
                xmlns="http://www.w3.org/2000/svg"
                width="12.149"
                height="7.489"
                viewBox="0 0 12.149 7.489"
            >
                <path
                    d="M0,9.32,4.66,4.66,0,0"
                    transform="translate(10.735 1.414) rotate(90)"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeWidth="2"
                />
            </svg>
            <svg
                id="inlined-svg--arrow-button"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
            >
                <g transform="translate(-1345.766 8.049) rotate(-45)">
                    <rect width="3" height="10" transform="translate(953 970)" fill="currentColor" />
                    <rect width="3" height="10" transform="translate(953 973) rotate(-90)" fill="currentColor" />
                </g>
            </svg>
            <svg id="inlined-svg--filter" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                <rect width="40" height="40" rx="4" fill="currentColor" opacity="0" />
                <path
                    d="M370.338,870.245a7.54,7.54,0,1,1,7.54-7.54A7.508,7.508,0,0,1,370.338,870.245Zm12.877,2.991-4.079-4.081a10.623,10.623,0,0,0,2.094-6.452,10.848,10.848,0,1,0-4.441,8.8l4.081,4.081a1.621,1.621,0,0,0,2.347,0,1.621,1.621,0,0,0,0-2.347Z"
                    transform="translate(-351.72 -844.087)"
                    fill="currentColor"
                />
                <path
                    d="M779.043,458.982l-3.049,3.686v3.964a.352.352,0,0,1-.5.333l-2.023-.97a.375.375,0,0,1-.222-.332v-2.993l-3.049-3.686a.356.356,0,0,1,.277-.582h8.287a.353.353,0,0,1,.277.582Z"
                    transform="translate(-756.118 -443.4)"
                    fill="currentColor"
                />
            </svg>
            <svg
                id="inlined-svg--manage-columns"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
            >
                <g transform="translate(-11)">
                    <rect width="2" height="12" transform="translate(25 14)" fill="currentColor" />
                    <rect width="2" height="12" transform="translate(30 14)" fill="currentColor" />
                    <rect width="2" height="12" transform="translate(35 14)" fill="currentColor" />
                </g>
            </svg>

            <svg
                version="1.1"
                id="inlined-svg--ban"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="20"
                height="20"
                viewBox="0 0 120 120"
                xmlSpace="preserve"
                fill="currentColor"
            >
                <g>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M91.124,15.645c12.928,0,23.406,10.479,23.406,23.406 c0,12.927-10.479,23.406-23.406,23.406c-12.927,0-23.406-10.479-23.406-23.406C67.718,26.125,78.197,15.645,91.124,15.645 L91.124,15.645z M2.756,0h117.322c1.548,0,2.802,1.254,2.802,2.802c0,0.848-0.368,1.622-0.996,2.139l-10.667,13.556 c-1.405-1.375-2.95-2.607-4.614-3.672l6.628-9.22H9.43l37.975,46.171c0.59,0.516,0.958,1.254,0.958,2.102v49.148l21.056-9.623 V57.896c1.651,1.9,3.548,3.582,5.642,4.996v32.133c0,1.105-0.627,2.064-1.586,2.506l-26.476,12.758 c-1.327,0.773-3.023,0.332-3.798-1.033c-0.258-0.441-0.368-0.92-0.368-1.4V55.02L0.803,4.756c-1.07-1.106-1.07-2.839,0-3.945 C1.355,0.258,2.056,0,2.756,0L2.756,0z M96.93,28.282c1.328-1.349,3.489-1.355,4.825-0.013c1.335,1.342,1.341,3.524,0.013,4.872 l-5.829,5.914l5.836,5.919c1.317,1.338,1.299,3.506-0.04,4.843c-1.34,1.336-3.493,1.333-4.81-0.006l-5.797-5.878l-5.807,5.889 c-1.329,1.349-3.489,1.355-4.826,0.013c-1.335-1.342-1.341-3.523-0.013-4.872l5.83-5.913l-5.836-5.919 c-1.317-1.338-1.3-3.507,0.04-4.843c1.339-1.336,3.492-1.333,4.81,0.006l5.796,5.878L96.93,28.282L96.93,28.282z"
                    />
                </g>
            </svg>

            <svg id="inlined-svg--save" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                <rect width="11" height="2" transform="translate(15 12)" fill="currentColor" />
                <path
                    d="M4.614,4.527v7.853L1.688,9.453.18,10.961l3.993,3.992L5.68,16.461l5.5-5.5L9.673,9.453,6.745,12.379V4.527Z"
                    transform="translate(14.82 12)"
                    fill="currentColor"
                />
            </svg>
            <svg
                id="inlined-svg--widget-settings"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
            >
                <rect width="40" height="40" rx="4" fill="currentColor" opacity="0" />
                <g transform="translate(-972 -135)">
                    <rect width="8" height="2" transform="translate(980 154)" fill="currentColor" />
                    <rect width="10" height="2" transform="translate(994 154)" fill="currentColor" />
                    <rect width="10" height="2" transform="translate(998 160) rotate(-90)" fill="currentColor" />
                </g>
            </svg>
            <svg
                id="inlined-svg--search"
                xmlns="http://www.w3.org/2000/svg"
                width="16.999"
                height="17"
                viewBox="0 0 16.999 17"
            >
                <path
                    d="M367.074,864.721a5.281,5.281,0,1,1,5.281-5.281A5.258,5.258,0,0,1,367.074,864.721Zm9.018,2.095-2.857-2.858a7.44,7.44,0,0,0,1.467-4.518,7.6,7.6,0,1,0-3.11,6.162l2.858,2.858a1.136,1.136,0,0,0,1.644,0,1.135,1.135,0,0,0,0-1.643Z"
                    transform="translate(-359.445 -851.812)"
                    fill="currentColor"
                />
            </svg>
            <svg
                id="inlined-svg--pencil"
                xmlns="http://www.w3.org/2000/svg"
                width="19.025"
                height="19.025"
                viewBox="0 0 19.025 19.025"
            >
                <path
                    d="M2.249,13.8,12.87,3.182l2.974,2.973L5.23,16.768l-2.98.007ZM12.87,0,0,12.921v6.1H6.105l12.92-12.87Z"
                    fill="currentColor"
                />
            </svg>
        </div>
    )
})

export function PDFLogoLoad({companyLogoUrl }: {companyLogoUrl: string }) {
    return ( 
        <svg xmlns="http://www.w3.org/2000/svg" display={"none"} >
            <image  xlinkHref={companyLogoUrl}/> : 
        </svg>
    )
}

export const ArrowSmallIcon = (): JSX.Element => (
    <svg className="inlined-svg inlined-svg--arrow-small" width="12.149" height="7.489">
        <use href="#inlined-svg--arrow-small"></use>
    </svg>
)

export const ArrowIcon = (): JSX.Element => (
    <svg className="inlined-svg inlined-svg--arrow-button" width="40" height="40">
        <use href="#inlined-svg--arrow-button"></use>
    </svg>
)

export const FilterIcon = (): JSX.Element => (
    <svg
        className="inlined-svg inlined-svg--filter"
        style={{ maxWidth: "-webkit-fill-available", maxHeight: "-webkit-fill-available" }}
    >
        <use href="#inlined-svg--filter"></use>
    </svg>
)

export const ManageColumnsIcon = (): JSX.Element => (
    <svg className="inlined-svg inlined-svg--manage-columns" width="40" height="40">
        <use href="#inlined-svg--manage-columns"></use>
    </svg>
)

export const BanIcon = (): JSX.Element => (
    <svg className="inlined-svg inlined-svg--ban" height="40" width="40" transform="translate(10,11)">
        <use href="#inlined-svg--ban"></use>
    </svg>
)
export const SaveIcon = (): JSX.Element => (
    <svg className="inlined-svg inlined-svg--save" width="40" height="40">
        <use href="#inlined-svg--save"></use>
    </svg>
)

export const WidgetSettingsIcon = (): JSX.Element => (
    <svg className="inlined-svg inlined-svg--widget-settings" width="40" height="40">
        <use href="#inlined-svg--widget-settings"></use>
    </svg>
)

export const SearchIcon = (): JSX.Element => (
    <svg className="inlined-svg inlined-svg--search" width="17" height="17">
        <use href="#inlined-svg--search"></use>
    </svg>
)

export const PencilIcon = (): JSX.Element => (
    <svg className="inlined-svg inlined-svg--pencil" width="19" height="19">
        <use href="#inlined-svg--pencil"></use>
    </svg>
)
