import { GlobalKeyListener } from "@lib/GlobalKeyListener"
import { classNames } from "@lib/HtmlUtil"
import React, { useEffect, useRef } from "react"
import { CSSTransition } from "react-transition-group"
import { Button, ButtonProps } from "./buttons/Button"
import { LoadingIndicator } from "./LoadingIndicator"

export type ModalProps = {
    isVisible: boolean
    preTitle?: string | React.ReactNode
    title?: string | React.ReactNode
    subTitle?: React.ReactNode
    isClosable?: boolean
    closeOnExternalClick?: boolean
    className?: string
    onClose?: () => void
    buttons?: ButtonProps[]
    children?: React.ReactNode
    focusRef?: React.RefObject<any>
    size?: ModalSize
    useContentTag?: boolean
    isLoading?: boolean,
    modalRef?: React.RefObject<any>
}

export enum ModalSize {
    Small = "small",
    Normal = "normal",
    Medium = "medium",
    Large = "large",
    Larger = "larger",
    Full = "full",
}

export function Modal({
    className,
    isVisible,
    isClosable = true,
    closeOnExternalClick = false,
    preTitle,
    title,
    subTitle,
    children,
    buttons,
    onClose,
    focusRef,
    size = ModalSize.Normal,
    useContentTag = false,
    isLoading = false,
    modalRef,
}: ModalProps): JSX.Element | null {
    const selfRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (!isVisible) return

        const listener = (ev: KeyboardEvent) => {
            if (ev.key !== "Escape" || !isClosable) return false
            onClose?.()
            return true
        }

        GlobalKeyListener.enqueue(listener)
        return () => GlobalKeyListener.dequeue(listener)
    }, [isVisible])

    return (
        <CSSTransition
            classNames="modal-"
            in={isVisible}
            timeout={250}
            appear={true}
            unmountOnExit={true}
            onEntered={() =>
                focusRef !== undefined && focusRef.current !== null ? focusRef.current?.focus() : selfRef.current?.focus()
            }            
        >
            <div
                className="modal-cont"
                onMouseDown={(ev) => {
                    const target = ev.target as HTMLElement
                    if (!target.classList.contains("modal-backdrop") && !target.classList.contains("modal-wrapper")) {
                        return
                    }
                    if (isClosable && closeOnExternalClick) onClose?.()
                }}

                ref={modalRef}
            >
                <div className="modal-wrapper" data-size={size}>
                    <div
                        className={classNames("modal", className)}
                        data-closable={isClosable}
                        data-size={size}
                        tabIndex={0}
                        ref={selfRef}
                    >
                        {isClosable ? (
                            <button className="modal-close-button" {...(isClosable ? { onClick: onClose } : undefined)}>
                                Close
                            </button>
                        ) : null}
                        {useContentTag ? (
                            children
                        ) : (
                            <ModalContent isLoading={isLoading}>
                                <ModalHeader>
                                    {(preTitle ?? "") !== "" && <ModalHeaderPreTitle>{preTitle}</ModalHeaderPreTitle>}
                                    <ModalHeaderTitle>{title}</ModalHeaderTitle>
                                    {(subTitle ?? "") !== "" && <ModalHeaderSubTitle>{subTitle}</ModalHeaderSubTitle>}
                                </ModalHeader>
                                <ModalBody>{children}</ModalBody>
                                <ModalButtons>
                                    {buttons?.map((button) => (
                                        <Button key={button.label} {...button} />
                                    ))}
                                </ModalButtons>
                            </ModalContent>
                        )}
                    </div>
                </div>
            </div>
        </CSSTransition>
    )
}

/**
 * Optional container tag for Modals. Can also use <ModalHeader>/<ModalBody>/<ModalButtons> directly. Only useful if
 * using useContentTag === true
 */
export function ModalContent({
    children,
    isLoading = false,
}: {
    children?: React.ReactNode
    isLoading?: boolean
}): JSX.Element {
    return (
        <>
            {children}
            <LoadingIndicator className="modal-loader" isLoading={isLoading} />
        </>
    )
}

export function ModalHeader({ children }: { children?: React.ReactNode }): JSX.Element {
    return (
        <div className="modal-header">
            <div className="modal-title-cont">{children}</div>
        </div>
    )
}

export function ModalHeaderPreTitle({ children }: { children?: React.ReactNode }): JSX.Element {
    return <div className="modal-pre-title">{children}</div>
}

export function ModalHeaderTitle({ children }: { children?: React.ReactNode }): JSX.Element {
    return <div className="modal-title">{children}</div>
}

export function ModalHeaderSubTitle({ children }: { children?: React.ReactNode }): JSX.Element {
    return <div className="modal-sub-title">{children}</div>
}

export function ModalBody({ children }: { children?: React.ReactNode }): JSX.Element {
    return <div className="modal-body">{children}</div>
}

export function ModalButtons({ children }: { children?: React.ReactNode }): JSX.Element {
    return <div className="modal-button-cont">{children}</div>
}
