import { arrayOfNotNull, isBoolean } from "@lib/TypeUtil"
import { Filter } from "@model/filters/Filter"
import { Chip } from "@ui/common/Chip"
import React from "react"

export function FilterChip<T extends string>({
    filter,
    onRemoveClicked,
    filterLabelOverride,
    filterValueOverride,
}: {
    filter: Filter<T>
    filterLabelOverride?: string
    filterValueOverride?: string
    onRemoveClicked: () => void
}): JSX.Element {
    return (
        <Chip
            category={filterLabelOverride ?? filter.definition.categoryLabel}
            values={
                filterValueOverride
                    ? [filterValueOverride]
                    : arrayOfNotNull(
                          filter.values.map((it) => {
                              if (it.label) return it.label
                              if (isBoolean(it.value)) return null
                              return it.value.toString()
                          })
                      )
            }
            onRemoveClicked={onRemoveClicked}
            maxValueLength={20}
            isEnabled={true}
        />
    )
}
