import { JobFunc } from "@ethossoftworks/job"
import { Outcome } from "@ethossoftworks/outcome"
import { Bloc } from "@lib/bloc/Bloc"
import { Filter } from "@model/filters/Filter"
import { FilterPreset, FilterPresetForm, FilterPresets, FilterPresetType } from "@model/filters/FilterPresets"
import {
    defaultServiceQuotesTableColumns,
    ServiceQuotesTableColumn,
    sortServiceQuoteColumns,
} from "@model/serviceQuotes/ServiceQuote"
import { TableColumnDef } from "@model/tables/Table"
import { TableColumnSettings } from "@model/tables/TableColumnSettings"
import { defaultWorkOrdersTableColumns, sortWorkOrderColumns, WorkOrderTableColumn } from "@model/workOrders/WorkOrder"
import { WorkOrdersTableColumnDefinitions } from "@model/workOrders/WorkOrdersTableColumnDefinitions"
import {
    defaultUserWorkRequestsTableColumns,
    defaultWorkRequestsTableColumns,
    sortUserWorkRequestColumns,
    sortWorkRequestColumns,
    UserWorkRequestsTableColumn,
    WorkRequestTableColumn,
} from "@model/workRequests/WorkRequest"
import { WorkRequestsTableColumnDefinitions } from "@model/workRequests/WorkRequestsTableColumnDefinitions"
import { UserPreferencesService } from "@service/userPreferences/UserPreferencesService"

export type UserPreferencesState = {
    readonly tableSettings: UserTableSettings
    readonly filterPresets: FilterPresets
}

export type UserTableSettings = {
    readonly workRequestsTableColumns: TableColumnSettings<WorkRequestTableColumn>
    readonly workOrdersTableColumns: TableColumnSettings<WorkOrderTableColumn>
    readonly userWorkRequestsTableColumns: TableColumnSettings<UserWorkRequestsTableColumn>
    readonly serviceQuotesTableColumns: TableColumnSettings<ServiceQuotesTableColumn>
}

export class UserPreferencesBloc extends Bloc<UserPreferencesState> {
    constructor(private userPreferencesService: UserPreferencesService) {
        super(newUserPreferencesState(), { persistStateOnDispose: true })
    }

    fetchFilterPresets = () =>
        this.effect({
            id: this.fetchFilterPresets,
            block: async (job): Promise<Outcome<FilterPresets>> => {
                const presets = await job.pause(this.userPreferencesService.fetchFilterPresets())
                if (presets.isError()) return presets

                this.update({ filterPresets: presets.value })

                return Outcome.ok(presets.value)
            },
        })

    fetchWorkRequestsTableColumns = () =>
        this.effect({
            id: this.fetchWorkRequestsTableColumns,
            block: async (job): Promise<Outcome<WorkRequestTableColumn[]>> => {
                const columnsOutcome = await job.pause(this.userPreferencesService.fetchWorkRequestsTableColumns())
                if (columnsOutcome.isError()) return columnsOutcome
                if (columnsOutcome.value === null) return Outcome.ok(defaultWorkRequestsTableColumns)

                this.update({
                    tableSettings: {
                        ...this.state.tableSettings,
                        workRequestsTableColumns: {
                            ...columnsOutcome.value,
                            columns: sortWorkRequestColumns(
                                this.mergeTableColumnSettingsWithRequiredColumns(
                                    columnsOutcome.value.columns,
                                    Object.values(WorkRequestsTableColumnDefinitions)
                                )
                            ),
                        },
                    },
                })

                return Outcome.ok(columnsOutcome.value.columns)
            },
        })

    updateWorkRequestsTableColumns = (columns: WorkRequestTableColumn[]) =>
        this.effect({
            id: this.updateWorkRequestsTableColumns,
            block: async (job) => {
                this.update({
                    tableSettings: {
                        ...this.state.tableSettings,
                        workRequestsTableColumns: {
                            ...this.state.tableSettings.workRequestsTableColumns,
                            columns: sortWorkRequestColumns(columns),
                        },
                    },
                })

                const outcome = await job.pause(
                    this.userPreferencesService.updateWorkRequestsTableColumns(
                        this.state.tableSettings.workRequestsTableColumns
                    )
                )
                return outcome
            },
        })

    fetchWorkOrdersTableColumns = () =>
        this.effect({
            id: this.fetchWorkOrdersTableColumns,
            block: async (job): Promise<Outcome<WorkOrderTableColumn[]>> => {
                const columnsOutcome = await job.pause(this.userPreferencesService.fetchWorkOrdersTableColumns())
                if (columnsOutcome.isError()) return columnsOutcome
                if (columnsOutcome.value === null) return Outcome.ok(defaultWorkOrdersTableColumns)

                this.update({
                    tableSettings: {
                        ...this.state.tableSettings,
                        workOrdersTableColumns: {
                            ...columnsOutcome.value,
                            columns: sortWorkOrderColumns(
                                this.mergeTableColumnSettingsWithRequiredColumns(
                                    columnsOutcome.value.columns,
                                    Object.values(WorkOrdersTableColumnDefinitions)
                                )
                            ),
                        },
                    },
                })

                return Outcome.ok(columnsOutcome.value.columns)
            },
        })

    updateWorkOrdersTableColumns = (columns: WorkOrderTableColumn[]) =>
        this.effect({
            id: this.updateWorkOrdersTableColumns,
            block: async (job) => {
                this.update({
                    tableSettings: {
                        ...this.state.tableSettings,
                        workOrdersTableColumns: {
                            ...this.state.tableSettings.workOrdersTableColumns,
                            columns: sortWorkOrderColumns(columns),
                        },
                    },
                })

                const outcome = await job.pause(
                    this.userPreferencesService.updateWorkOrdersTableColumns(
                        this.state.tableSettings.workOrdersTableColumns
                    )
                )
                return outcome
            },
        })

    updateServiceQuotesTableColumns = (columns: ServiceQuotesTableColumn[]) =>
        this.effect({
            id: this.updateServiceQuotesTableColumns,
            block: async (job) => {
                this.update({
                    tableSettings: {
                        ...this.state.tableSettings,
                        serviceQuotesTableColumns: {
                            ...this.state.tableSettings.serviceQuotesTableColumns,
                            columns: sortServiceQuoteColumns(columns),
                        },
                    },
                })

                const outcome = await job.pause(
                    this.userPreferencesService.updateServiceQuotesTableColumns(
                        this.state.tableSettings.serviceQuotesTableColumns
                    )
                )
                return outcome
            },
        })

    // Make sure that the required table columns are in state
    private mergeTableColumnSettingsWithRequiredColumns<T extends string>(
        settings: T[],
        columnDefs: TableColumnDef<T>[]
    ): T[] {
        return [
            ...columnDefs.filter((it) => it.isRequired && !settings.includes(it.type)).map((it) => it.type),
            ...settings,
        ]
    }

    createFilterPreset = (preset: FilterPreset<any>) =>
        this.effect({
            id: this.createFilterPreset,
            block: async (job) => {
                const outcome = await job.pause(this.userPreferencesService.createFilterPreset(preset))
                if (outcome.isError()) return outcome

                if (preset.isDefault) await job.launchAndRun(this.unsetDefaultPresetForType(preset))

                this.update({
                    filterPresets: {
                        workRequests:
                            preset.type !== FilterPresetType.WorkRequest
                                ? this.state.filterPresets.workRequests
                                : [...this.state.filterPresets.workRequests, outcome.value],
                        workOrders:
                            preset.type !== FilterPresetType.WorkOrder
                                ? this.state.filterPresets.workOrders
                                : [...this.state.filterPresets.workOrders, outcome.value],
                        dashboard:
                            preset.type !== FilterPresetType.Dashboard
                                ? this.state.filterPresets.dashboard
                                : [...this.state.filterPresets.dashboard, outcome.value],
                        serviceQuotes:
                            preset.type !== FilterPresetType.ServiceQuote
                                ? this.state.filterPresets.serviceQuotes
                                : [...this.state.filterPresets.serviceQuotes, outcome.value],
                    },
                })

                return outcome
            },
        })

    updateFilterPresetFilters = (preset: FilterPreset<any>, filters: Filter<any>[]) =>
        this.updateFilterPreset({ ...preset, filters: filters })

    updateFilterPresetForm = (preset: FilterPreset<any>, form: FilterPresetForm) =>
        this.updateFilterPreset({ ...preset, isDefault: form.isDefault, name: form.name })

    private updateFilterPreset = (preset: FilterPreset<any>) =>
        this.effect({
            id: this.updateFilterPreset,
            block: this._updateFilterPreset(preset),
        })

    private _updateFilterPreset =
        (preset: FilterPreset<any>): JobFunc<FilterPreset<any>> =>
        async (job) => {
            const outcome = await job.pause(this.userPreferencesService.updateFilterPreset(preset))
            if (outcome.isError()) return outcome

            if (preset.isDefault) await job.launchAndRun(this.unsetDefaultPresetForType(preset))

            this.update({
                filterPresets: {
                    workRequests:
                        preset.type !== FilterPresetType.WorkRequest
                            ? this.state.filterPresets.workRequests
                            : this.state.filterPresets.workRequests
                                  .filter((it) => it.id !== preset.id)
                                  .concat(outcome.value),
                    workOrders:
                        preset.type !== FilterPresetType.WorkOrder
                            ? this.state.filterPresets.workOrders
                            : this.state.filterPresets.workOrders
                                  .filter((it) => it.id !== preset.id)
                                  .concat(outcome.value),
                    dashboard:
                        preset.type !== FilterPresetType.Dashboard
                            ? this.state.filterPresets.dashboard
                            : this.state.filterPresets.dashboard
                                  .filter((it) => it.id !== preset.id)
                                  .concat(outcome.value),
                    serviceQuotes:
                        preset.type !== FilterPresetType.ServiceQuote
                            ? this.state.filterPresets.serviceQuotes
                            : this.state.filterPresets.serviceQuotes,
                    // filter((it) => it.id !== preset.id)
                    // .concat(outcome.value),
                },
            })

            return outcome
        }

    private unsetDefaultPresetForType =
        (currentPreset: FilterPreset<any>): JobFunc<void> =>
        async (job) => {
            const presets: FilterPreset<any>[] | null = (() => {
                switch (currentPreset.type) {
                    case FilterPresetType.WorkRequest:
                        return this.state.filterPresets.workRequests
                    case FilterPresetType.WorkOrder:
                        return this.state.filterPresets.workOrders
                    case FilterPresetType.Dashboard:
                        return this.state.filterPresets.dashboard
                    default:
                        return null
                }
            })()

            if (!presets) return Outcome.ok(undefined)

            await job.pause(
                Promise.all(
                    presets
                        .filter((it) => it.isDefault && it.id != currentPreset.id)
                        .map((it) => job.launchAndRun(this._updateFilterPreset({ ...it, isDefault: false })))
                )
            )

            return Outcome.ok(undefined)
        }

    deleteFilterPreset = (preset: FilterPreset<any>) =>
        this.effect({
            id: this.deleteFilterPreset,
            block: async (job) => {
                const outcome = await job.pause(this.userPreferencesService.deleteFilterPreset(preset))
                if (outcome.isError()) return outcome

                this.update({
                    filterPresets: {
                        workRequests:
                            preset.type !== FilterPresetType.WorkRequest
                                ? this.state.filterPresets.workRequests
                                : this.state.filterPresets.workRequests.filter((it) => it.id !== preset.id),
                        workOrders:
                            preset.type !== FilterPresetType.WorkOrder
                                ? this.state.filterPresets.workOrders
                                : this.state.filterPresets.workOrders.filter((it) => it.id !== preset.id),
                        dashboard:
                            preset.type !== FilterPresetType.Dashboard
                                ? this.state.filterPresets.dashboard
                                : this.state.filterPresets.dashboard.filter((it) => it.id !== preset.id),
                        serviceQuotes:
                            preset.type !== FilterPresetType.ServiceQuote
                                ? this.state.filterPresets.serviceQuotes
                                : this.state.filterPresets.serviceQuotes.filter((it) => it.id !== preset.id),
                    },
                })

                return outcome
            },
        })
}

function newUserPreferencesState(): UserPreferencesState {
    return {
        tableSettings: newUserTableSettings(),
        filterPresets: newUserFilterPresets(),
    }
}

function newUserTableSettings(): UserTableSettings {
    return {
        workRequestsTableColumns: {
            id: -1,
            columns: sortWorkRequestColumns(defaultWorkRequestsTableColumns),
        },
        workOrdersTableColumns: {
            id: -1,
            columns: sortWorkOrderColumns(defaultWorkOrdersTableColumns),
        },
        userWorkRequestsTableColumns: {
            id: -1,
            columns: sortUserWorkRequestColumns(defaultUserWorkRequestsTableColumns),
        },
        serviceQuotesTableColumns: {
            id: -1,
            columns: sortServiceQuoteColumns(defaultServiceQuotesTableColumns),
        },
    }
}

function newUserFilterPresets(): FilterPresets {
    return {
        workRequests: [],
        workOrders: [],
        dashboard: [],
        serviceQuotes: [],
    }
}
