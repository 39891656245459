const asyncStatusSymbol = Symbol()

class AsyncIdle {
    private asyncStatusSymbol = asyncStatusSymbol

    isIdle = (): this is AsyncIdle => true
    isBusy = (): this is AsyncBusy => false
    isError = (): this is AsyncError<any> => false
    isSuccess = (): this is AsyncSuccess<any> => false
    toJSON = () => ({ type: "Idle" })
}

class AsyncBusy {
    private asyncStatusSymbol = asyncStatusSymbol

    isIdle = (): this is AsyncIdle => false
    isBusy = (): this is AsyncBusy => true
    isError = (): this is AsyncError<any> => false
    isSuccess = (): this is AsyncSuccess<any> => false
    toJSON = () => ({ type: "Busy" })
}

class AsyncError<E> {
    private asyncStatusSymbol = asyncStatusSymbol

    constructor(public error: E) {}

    isIdle = (): this is AsyncIdle => false
    isBusy = (): this is AsyncBusy => false
    isError = (): this is AsyncError<E> => true
    isSuccess = (): this is AsyncSuccess<any> => false
    toJSON = () => ({ type: "Error", error: this.error })
}

class AsyncSuccess<S> {
    private asyncStatusSymbol = asyncStatusSymbol

    constructor(public value: S) {}

    isIdle = (): this is AsyncIdle => false
    isBusy = (): this is AsyncBusy => false
    isError = (): this is AsyncError<any> => false
    isSuccess = (): this is AsyncSuccess<S> => true
    toJSON = () => ({ type: "Success", value: this.value })
}

export type AsyncStatus<S = unknown, E = unknown> = AsyncIdle | AsyncBusy | AsyncError<E> | AsyncSuccess<S>

export const AsyncStatus = {
    idle: () => new AsyncIdle(),
    busy: () => new AsyncBusy(),
    success: <S>(value: S) => new AsyncSuccess(value),
    error: <E>(error: E) => new AsyncError(error),
    isAsyncStatus: (other: any): other is AsyncStatus =>
        other !== undefined && other.asyncStatusSymbol === asyncStatusSymbol,
}
