import { useBlocCoordinator } from "@lib/bloc/hooks"
import { DashboardDataType } from "@state/dashboard/DashboardScreenBloc"
import { ChipCont } from "@ui/common/Chip"
import { Page } from "@ui/common/page/Page"
import { DI } from "@ui/DI"
import { StatusWidget } from "@ui/widgets/StatusWidget"
import React, { useEffect } from "react"
import { DashboardFilterButton, DashboardFilterOverlay } from "./DashboardFilterOverlay"
import { RedYellowTagsWidget } from "./RedYellowTagsWidget"
import { StatusWidgetSettingsModal } from "./StatusWidgetSettingsModal"
import { WorkOrderStatusWidget } from "./WorkOrderStatusWidget"
import { WorkRequestsCompletedWidget } from "./WorkRequestsCompletedWidget"
import { Filter, isEmptyFilterValue } from "@model/filters/Filter"
import { DashboardFilter } from "@model/dashboard/DashboardFilter"
import { FilterChip } from "@ui/common/tables/FilterChip"
export function DashboardScreen(): JSX.Element {
    const [state, viewModel] = useBlocCoordinator(() => DI.dashboardScreenViewModel())

    useEffect(() => {
        viewModel.onMounted()
    }, [])

    return (
        <Page className="page--dashboard" filterButton={DashboardFilterButton}>
            <DashboardFilterOverlay />
            <StatusWidget
                isLoading={!state.hasLoaded[DashboardDataType.StatusWidget] ?? false}
                items={state.statusWidgetCounts}
                onSettingsButtonClick={() => {}}
            />
            <ChipCont className="sortable-table-filters-cont">
                    {(Object.values<Filter<DashboardFilter>>(state.filters) ?? [])
                        .filter((filter) => !isEmptyFilterValue(filter))
                        .map((filter) => (
                            <FilterChip                                
                                filter={filter}
                                key={filter.definition.type}
                                onRemoveClicked={() => viewModel.onFilterRemoveClicked?.(filter)}
                            />
                        ))}
                </ChipCont>
            <div className="dashboard-widget-cont">                
                <WorkRequestsCompletedWidget />
                <RedYellowTagsWidget />
                <WorkOrderStatusWidget />
            </div>
            <StatusWidgetSettingsModal isOpen={state.isStatusWidgetSettingsModalOpen} onClose={() => {}} />
        </Page>
    )
}
