import { Outcome } from "@ethossoftworks/outcome"
import { SortOrder } from "@lib/Comparable"
import { PDFAsset } from "@lib/pdf/models/PDFAsset"
import { PDFAssetPart } from "@lib/pdf/models/PDFAssetPart"
import { PDFWorkRequest } from "@lib/pdf/models/PDFWorkRequest"
import { pdfAssetFromModel } from "@model/assets/Asset"
import { pdfAssetPartFromModel } from "@model/assets/AssetPart"
import { AsyncStatus } from "@model/AsyncStatus"
import { thresholdSettingsHasValue } from "@model/company/CompanySettings"
import { Filter, FilterUpdateType, isEmptyFilterValue, updateFilter } from "@model/filters/Filter"
import { PagedResponse, Pagination } from "@model/Pagination"
import { ServiceCode } from "@model/serviceRequests/ServiceRequest"
import { pdfWorkRequestFromModel } from "@model/workOrders/WorkOrder"
import {
    defaultWorkRequestsTableColumns,
    WorkRequestFilter,
    WorkRequestQuickFilterCount,
    WorkRequestsTableRow,
    WorkRequestTableColumn,
} from "@model/workRequests/WorkRequest"
import { WorkRequestPrintOption } from "@model/workRequests/WorkRequestPrintOption"
import { WorkRequestsFilterDefinitions } from "@model/workRequests/WorkRequestsFilterDefinitions"
import { downloadBlob } from "@service/ApiService"
import { AssetService } from "@service/assets/AssetService"
import { WorkRequestService } from "@service/workRequests/WorkRequestService"
import { CompanyState } from "@state/company/CompanyBloc"
import { SortableTableBloc, SortableTableBlocEffect, SortableTableState } from "@state/SortableTableBloc"

export type WorkRequestsTableState = SortableTableState<
    WorkRequestTableColumn,
    WorkRequestsTableRow,
    WorkRequestFilter,
    ExtraState
>

type ExtraState = {
    counts: Record<WorkRequestQuickFilterCount, number>
    ignoreUserPresetFilterOnLoad: boolean
    assetToPrint: number | null
    assetToPrintIsLoading: boolean
    pdfAsset?: PDFAsset
    pdfAssetParts?: PDFAssetPart[]
    pdfWorkRequests?: PDFWorkRequest[]
    pdfCompanyName?: string
    selectedPrintOption?: WorkRequestPrintOption
}

export class WorkRequestsTableBloc extends SortableTableBloc<
    WorkRequestTableColumn,
    WorkRequestsTableRow,
    WorkRequestFilter,
    ExtraState
> {
    constructor(
        private workRequestService: WorkRequestService,
        private assetService: AssetService,
        companyState: () => CompanyState
    ) {
        super({
            filters: Object.values(WorkRequestFilter),

            filterDefinitions: WorkRequestsFilterDefinitions,

            defaultManagedColumns: defaultWorkRequestsTableColumns,

            readOnlyManagedColumns: [WorkRequestTableColumn.Asset],

            dataFetcher: async (
                filters?: Filter<WorkRequestFilter>[],
                sort?: { column: WorkRequestTableColumn; order: SortOrder },
                pagination?: Pagination
            ): Promise<Outcome<PagedResponse<WorkRequestsTableRow[]>>> => {
                if (!sort) sort = { column: WorkRequestTableColumn.Asset, order: SortOrder.Asc }
                const results = await workRequestService.fetchWorkRequestsByAssets(filters, sort, pagination)
                if (results.isOk()) this.update({ counts: results.value.counts })
                return results
            },

            initialExtraState: {
                counts: {
                    [WorkRequestQuickFilterCount.Assets]: 0,
                    [WorkRequestQuickFilterCount.Immediate]: 0,
                    [WorkRequestQuickFilterCount.Inspection]: 0,
                    [WorkRequestQuickFilterCount.Overdue]: 0,
                    [WorkRequestQuickFilterCount.Preventative]: 0,
                    [WorkRequestQuickFilterCount.RedTag]: 0,
                    [WorkRequestQuickFilterCount.Service]: 0,
                    [WorkRequestQuickFilterCount.YellowTag]: 0,
                    [WorkRequestQuickFilterCount.Upcoming]: 0,
                },
                ignoreUserPresetFilterOnLoad: false,
                assetToPrint: null,
                assetToPrintIsLoading: false,
                pdfAsset: undefined,
                pdfAssetParts: undefined,
                pdfCompanyName: undefined,
                selectedPrintOption: undefined,
                pdfWorkRequests: undefined,
            },

            persistStateOnDispose: true,

            defaultFiltersOverride: (filters) => {
                if (!thresholdSettingsHasValue(companyState().settings.thresholds)) {
                    return {
                        [WorkRequestFilter.Upcoming]: updateFilter(
                            filters[WorkRequestFilter.Upcoming],
                            [{ value: false }],
                            FilterUpdateType.Set
                        ),
                    }
                }
                return {}
            },
        })
    }

    exportData = () => {
        console.log(this.state.filters)
        this.effect({
            id: SortableTableBlocEffect.Fetch,
            block: async (job) => {
                this.effectStatusChanged(SortableTableBlocEffect.Fetch, AsyncStatus.busy())
                const filtersArray = Object.values(this.state.filters ?? []).filter(
                    (filter) => !isEmptyFilterValue(filter)
                )

                const dataOutcome = await job.pause(this.workRequestService.fetchWorkRequestXls(filtersArray))

                if (!dataOutcome.isOk()) {
                    this.effectStatusChanged(SortableTableBlocEffect.Fetch, AsyncStatus.error(dataOutcome))
                    return dataOutcome
                }

                const blob = dataOutcome.value
                if (blob) {
                    downloadBlob(blob, "ServiceRequestsList.xlsx")
                }

                this.effectStatusChanged(SortableTableBlocEffect.Fetch, AsyncStatus.idle())
                return dataOutcome
            },
        })
    }

    assetToPrintChanged = (assetId: number | null) =>
        this.update({
            assetToPrint: assetId,
        })

    printWorkRequestOption = async (workRequestPrintOption: WorkRequestPrintOption, serviceCodes: ServiceCode[]) => {
        let pdfAsset: PDFAsset | null
        let pdfAssetParts: PDFAssetPart[] | undefined = undefined
        let pdfWorkRequests: PDFWorkRequest[] | undefined = undefined
        let pdfCompanyName: string = ""
        this.update({
            assetToPrintIsLoading: true,
        })

        const assetOutcome = await this.assetService.fetchAsset(this.state.assetToPrint!)
        if (assetOutcome.isError()) {
            return assetOutcome
        }
        pdfAsset = pdfAssetFromModel(assetOutcome.value)
        pdfCompanyName = assetOutcome.value.companyName
        if (workRequestPrintOption == WorkRequestPrintOption.PendingWorkRequest) {
            const workRequestsOutcome = await this.workRequestService.fetchUnassignedWorkRequestsForAsset(assetOutcome.value.id)
            if (workRequestsOutcome.isError()) {
                return workRequestsOutcome
            }
            pdfWorkRequests = workRequestsOutcome.value.map((i) => pdfWorkRequestFromModel(i, serviceCodes))
        }

        if (workRequestPrintOption == WorkRequestPrintOption.Parts) {
            const assetPartsOutcome = await this.assetService.fetchParts(assetOutcome.value.id)
            if (assetPartsOutcome.isError()) {
                return assetPartsOutcome
            }

            pdfAssetParts = assetPartsOutcome.value.map(pdfAssetPartFromModel)
        }

        this.update({
            pdfAsset: pdfAsset,
            pdfAssetParts: pdfAssetParts,
            pdfCompanyName: pdfCompanyName,
            pdfWorkRequests: pdfWorkRequests,
            selectedPrintOption: workRequestPrintOption,
            assetToPrintIsLoading: false,
            assetToPrint: null, // This hides the printing options dialog
        })

        return Outcome.ok(undefined)
    }
}
