import React from "react"
import { Page } from "@ui/common/page/Page"
import { ServiceQuotesFilterButton, ServiceQuotesFilterOverlay } from "./ServiceQuotesFilterOverlay"
import { ServiceQuotesQuickFilters } from "./ServiceQuotesQuickFilters"
import { ServiceQuotesTable } from "./ServiceQuotesTable"

export function ServiceQuotesScreen(): JSX.Element {
    return (
        <Page className="page--service-quotes" filterButton={ServiceQuotesFilterButton}>
            <ServiceQuotesFilterOverlay />
            <ServiceQuotesQuickFilters />
            <ServiceQuotesTable />
        </Page>
    )
}