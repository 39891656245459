import { useBlocCoordinator } from "@lib/bloc/hooks"
import { useRoute } from "@lib/router/hooks"
import { safeParseInt } from "@lib/TypeUtil"
import { SortableTableHeadColumn, TableColumn, TableColumnAlignment, TableColumnSize, TableRow } from "@model/tables/Table"
import { UserWorkRequestsTableColumnDefinitions } from "@model/workRequests/UserWorkRequestsTableColumnDefinitions"
import {
    UserWorkRequestsTableColumn,
    UserWorkRequestsTableRow,
} from "@model/workRequests/WorkRequest"
import { DI } from "@ui/DI"
import React, { useEffect } from "react"
import { UserWorkRequestsViewModel } from "./UserWorkRequestsViewModel"
import { SortableTable } from "@ui/common/tables/SortableTable"
import { SortOrder } from "@lib/Comparable"
import { Routes } from "@lib/Routes"
import { Page } from "@ui/common/page/Page"
import { WorkRequestDetailsModal } from "./WorkRequestDetailsModal"
import { withRouter } from "@lib/router/router"
import { Button, ButtonCont, ButtonType } from "@ui/common/buttons/Button"

export function UserWorkRequestsScreen(): JSX.Element {
    const route = useRoute()
    const userId = route.data.params.userId
    const [state, viewModel] = useBlocCoordinator(() => DI.userWorkRequestsViewModel())

    useEffect(() => {
        viewModel.onMounted(safeParseInt(userId))
    }, [])

    return (
        <Page className="page--work-requests" >
                    <SortableTable
                    className="table--work-requests"
                    head={createTableHead(state.columns, state.sort)}
                    data={createTableData(state.columns, state.tableData, viewModel)}
                    emptyMessage="No Service Requests Found"
                    actionBar={{
                        pagination: state.pagination,
                        onNextPageClicked: () => viewModel.onNextPageClicked(),
                        onPreviousPageClicked: () => viewModel.onPreviousPageClicked(),
                        onPageClicked: (page) => viewModel.onPageClicked(page),                    
                    }}
                    
                    isLoading={state.isTableDataLoading}
                    onColumnSortClicked={(column) => viewModel.onColumnSortClicked(column)}
                />
            <WorkRequestDetailsModal /> 
            <ButtonCont className="user-work-requests-button-cont" >
                <Button 
                        type={ButtonType.Contained}
                        label="View Work Orders"
                        onClick={() => withRouter((router) => router.navigate(Routes.WorkOrders()))  }
                    />
            </ButtonCont>           
        </Page>
    )
}

function createTableHead(
    columns: UserWorkRequestsTableColumn[],
    sort?: { column: UserWorkRequestsTableColumn; order: SortOrder },
    columnLabelOverrides?: Partial<Record<UserWorkRequestsTableColumn, string>>
): SortableTableHeadColumn[] {
    return columns
        .map(
            (column): SortableTableHeadColumn => ({
                size: UserWorkRequestsTableColumnDefinitions[column].size,
                alignment: UserWorkRequestsTableColumnDefinitions[column].alignment,
                isSortable: UserWorkRequestsTableColumnDefinitions[column].isSortable,
                sortOrder: column === sort?.column ? sort.order : SortOrder.None,
                content: (
                    <div>{columnLabelOverrides?.[column] ?? UserWorkRequestsTableColumnDefinitions[column].label}</div>
                ),
            })
        )
}

function createTableData(
    columns: UserWorkRequestsTableColumn[],
    assetWorkRequests: UserWorkRequestsTableRow[],
    viewModel: UserWorkRequestsViewModel
): TableRow[] {
    return assetWorkRequests.map((asset) => ({
        entryId: asset.id,
        columns: columns
            .map(
                (column): TableColumn => ({
                    size: UserWorkRequestsTableColumnDefinitions[column].size,
                    alignment: UserWorkRequestsTableColumnDefinitions[column].alignment,
                    content: createColumnContent(column, asset, viewModel),
                    className: UserWorkRequestsTableColumnDefinitions[column].className,
                })
            ),
        onClick:() => withRouter((router) => router.navigate(Routes.UserWorkRequest(asset.assetId.toString(), asset.id.toString())))
    }))
}

function createColumnContent(
    type: UserWorkRequestsTableColumn,
    workRequest: UserWorkRequestsTableRow,
    viewModel: UserWorkRequestsViewModel
): JSX.Element | string | null {
    switch (type) {
        case UserWorkRequestsTableColumn.Asset:
            return (
                <div className="table-cell--work-requests-name">
                        {workRequest.assetName}
                </div>
            )                
        case UserWorkRequestsTableColumn.Name:
            return (
                <div className="table-cell--work-requests-name">
                        {workRequest.name}
                </div>
            )                
    }
}