import React from "react"
import { PDFSection } from "./PDFSection"

export function PDFPageHeader({ title, companyName }: { title: string; companyName: string }): JSX.Element {
    return (
        <PDFSection>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px",
                    paddingBottom: "24px",
                    fontSize: "1.4rem",
                }}
            >
                <div>{title}</div>
                <div>{companyName}</div>
            </div>
        </PDFSection>
    )
}
