import { useIsInIframe, useIsMobile } from "@lib/Hooks"
import { classNames } from "@lib/HtmlUtil"
import { FilterIcon } from "@ui/common/images/InlinedSvgs"
import React from "react"

export type FilterButtonProps = {
    onClick: () => void
    isFilterOverlayVisible: boolean
}
const isMobile = useIsMobile || useIsInIframe;

export function FilterButton({ onClick, isFilterOverlayVisible }: FilterButtonProps): JSX.Element {
    return (
        <button
            className={classNames(
                "header-filter-button",
                isFilterOverlayVisible ? "header-filter-button--close" : null
            )}
            onClick={onClick}
            title={`${isFilterOverlayVisible ? "Close" : "Open"} Filter Tray`}
        >
            {isFilterOverlayVisible ? (
                <>
                {!isMobile && (
                    <label className="header-filter-button-label closed">Open Filter Tray</label>  
                )}                 
                </>
            ) : (
                <>
                {!isMobile && (
                    <label className="header-filter-button-label">Open Filter Tray</label>
                )}
                    <FilterIcon />
                </>
            )}
        </button>
    )
}
