import { isNumber, isString } from "./TypeUtil"

export const compare = <T>(a: Comparable<T>, b: Comparable<T>, order: SortOrder): number => {
    if (order === "none") return 0
    if (Object.getPrototypeOf(a) !== Object.getPrototypeOf(b)) return 0

    const first = order == "asc" ? a : b
    const second = order == "asc" ? b : a

    if (isString(first)) {
        return first.localeCompare(second as string)
    } else if (isNumber(first)) {
        if (first < second) {
            return -1
        } else if (first > second) {
            return 1
        }
    } else {
        return first.compare(second as unknown as T)
    }
    return 0
}

export type Comparable<T> = string | number | IComparable<T>

export interface IComparable<T> {
    compare(other: T): number
}

export enum SortOrder {
    None = "none",
    Asc = "asc",
    Desc = "desc",
}

export let compareAssets = (identifierA: string, identifierB: string): number => {
    if (identifierA.length !== identifierB.length) return identifierA.length - identifierB.length

    return identifierA.localeCompare(identifierB)
}
