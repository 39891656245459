import { ThresholdUnit } from "@model/Threshold"
import { CompanyAlias, defaultCompanyAlias } from "@model/company/CompanyAlias"
import { FilterDef } from "@model/filters/Filter"
import { WorkOrderFilter } from "./WorkOrder"

export const WorkOrderFilterDefinitions: Record<WorkOrderFilter, FilterDef<WorkOrderFilter>> = {
    [WorkOrderFilter.Search]: {
        type: WorkOrderFilter.Search,
        categoryLabel: "Search",
        defaultValue: [{ value: "" }], // string
    },
    [WorkOrderFilter.SubCompany]: {
        type: WorkOrderFilter.SubCompany,
        categoryLabel: defaultCompanyAlias(CompanyAlias.SubCompany),
        defaultValue: [],
    },
    [WorkOrderFilter.District]: {
        type: WorkOrderFilter.District,
        categoryLabel: defaultCompanyAlias(CompanyAlias.District),
        defaultValue: [],
        dependencies: [WorkOrderFilter.SubCompany],
    },
    [WorkOrderFilter.SubDistrict]: {
        type: WorkOrderFilter.SubDistrict,
        categoryLabel: defaultCompanyAlias(CompanyAlias.SubDistrict),
        defaultValue: [],
        dependencies: [WorkOrderFilter.SubCompany, WorkOrderFilter.District],
    },
    [WorkOrderFilter.Unit]: {
        type: WorkOrderFilter.Unit,
        categoryLabel: defaultCompanyAlias(CompanyAlias.Unit),
        defaultValue: [],
        dependencies: [WorkOrderFilter.SubCompany, WorkOrderFilter.District, WorkOrderFilter.SubDistrict],
    },
    [WorkOrderFilter.Group]: {
        type: WorkOrderFilter.Group,
        categoryLabel: defaultCompanyAlias(CompanyAlias.Group),
        defaultValue: [],
        dependencies: [WorkOrderFilter.SubCompany],
    },
    [WorkOrderFilter.Site]: { type: WorkOrderFilter.Site, categoryLabel: "Site", defaultValue: [] },
    [WorkOrderFilter.AssetType]: { type: WorkOrderFilter.AssetType, categoryLabel: "Type", defaultValue: [] },
    [WorkOrderFilter.Category]: {
        type: WorkOrderFilter.Category,
        categoryLabel: "Category",
        defaultValue: [],
        dependencies: [WorkOrderFilter.AssetType],
    },
    [WorkOrderFilter.Class]: {
        type: WorkOrderFilter.Class,
        categoryLabel: "Class",
        defaultValue: [],
        dependencies: [WorkOrderFilter.Category, WorkOrderFilter.AssetType],
    },
    [WorkOrderFilter.Make]: {
        type: WorkOrderFilter.Make,
        categoryLabel: "Make",
        defaultValue: [],
    },
    [WorkOrderFilter.Model]: {
        type: WorkOrderFilter.Model,
        categoryLabel: "Model",
        defaultValue: [],
        dependencies: [WorkOrderFilter.Make],
    },
    [WorkOrderFilter.Urgency]: {
        type: WorkOrderFilter.Urgency,
        categoryLabel: "Urgency",
        defaultValue: [], // string[]
    },
    [WorkOrderFilter.Schedule]: { type: WorkOrderFilter.Schedule, categoryLabel: "Schedule", defaultValue: [] },
    [WorkOrderFilter.HoursUntil]: {
        type: WorkOrderFilter.HoursUntil,
        categoryLabel: "Hours Until",
        defaultValue: [{ value: "" }], // string
    },
    [WorkOrderFilter.HoursUntilUnit]: {
        type: WorkOrderFilter.HoursUntilUnit,
        categoryLabel: "Hours Until Unit",
        defaultValue: [{ value: ThresholdUnit.Unit }],
    },
    [WorkOrderFilter.MilesUntil]: {
        type: WorkOrderFilter.MilesUntil,
        categoryLabel: "Miles Until",
        defaultValue: [{ value: "" }], // string
    },
    [WorkOrderFilter.MilesUntilUnit]: {
        type: WorkOrderFilter.MilesUntilUnit,
        categoryLabel: "Miles Until Unit",
        defaultValue: [{ value: ThresholdUnit.Unit }],
    },
    [WorkOrderFilter.DaysUntil]: {
        type: WorkOrderFilter.DaysUntil,
        categoryLabel: "Days Until",
        defaultValue: [{ value: "" }], // string
    },
    [WorkOrderFilter.DaysUntilUnit]: {
        type: WorkOrderFilter.DaysUntilUnit,
        categoryLabel: "Days Until Unit",
        defaultValue: [{ value: ThresholdUnit.Unit }],
    },
    [WorkOrderFilter.ServiceCode]: {
        type: WorkOrderFilter.ServiceCode,
        categoryLabel: "Service Code",
        defaultValue: [],
    },
    [WorkOrderFilter.RedTag]: {
        type: WorkOrderFilter.RedTag,
        categoryLabel: "Red Tag",
        defaultValue: [{ value: false }],
    },
    [WorkOrderFilter.YellowTag]: {
        type: WorkOrderFilter.YellowTag,
        categoryLabel: "Yellow Tag",
        defaultValue: [{ value: false }],
    },
    [WorkOrderFilter.Overdue]: {
        type: WorkOrderFilter.Overdue,
        categoryLabel: "Overdue",
        defaultValue: [{ value: false }],
    },
    [WorkOrderFilter.Upcoming]: {
        type: WorkOrderFilter.Upcoming,
        categoryLabel: "Upcoming",
        defaultValue: [{ value: false }],
    },
    [WorkOrderFilter.Status]: {
        type: WorkOrderFilter.Status,
        categoryLabel: "Status",
        defaultValue: [],
    },
    [WorkOrderFilter.AssignedTo]: {
        type: WorkOrderFilter.AssignedTo,
        categoryLabel: "Assigned To",
        defaultValue: [],
    },
    [WorkOrderFilter.WorkType]: {
        type: WorkOrderFilter.WorkType,
        categoryLabel: "Service Type",
        defaultValue: [], // string[]
    },
    [WorkOrderFilter.WOSearch]: {
        type: WorkOrderFilter.WOSearch,
        categoryLabel: "Work Order Number",
        defaultValue: [{ value: "" }], // string
    },
}
