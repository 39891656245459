import { useLastNonNull } from "@lib/Hooks"
import { Tag, TagChange, tagChangeFromTagType, tagChangeToTagType, TagType } from "@model/Tag"
import { Button, ButtonType } from "@ui/common/buttons/Button"
import { FormField } from "@ui/common/form/FormField"
import { FormFieldGroup } from "@ui/common/form/FormFieldGroup"
import { Radio } from "@ui/common/form/Radio"
import { TextArea, TextAreaType } from "@ui/common/form/TextArea"
import { Grid, GridGap } from "@ui/common/grids/Grid"
import { Modal, ModalBody, ModalButtons, ModalContent, ModalHeader, ModalHeaderTitle } from "@ui/common/Modal"
import React from "react"

export function UpdateTagModal({
    onCancel,
    onNoTagChangeClicked,
    onConfirm,
    onReasonChanged,
    onTagChanged,
    assetTag,
    isVisible,
    disableSelection = false,
    disableNoneOption = false,
    ...props
}: {
    data: {        
        reason: string
        tagChange: TagChange
    } | null
    assetTag: Tag
    isVisible: boolean
    onReasonChanged: (value: string) => void
    onCancel: () => void
    onNoTagChangeClicked: () => void
    onConfirm: () => void
    onTagChanged: (change: TagChange) => void
    disableSelection: boolean 
    disableNoneOption: boolean
}): JSX.Element {
     const data = useLastNonNull(props.data)
    if (data === null) return <Modal isVisible={isVisible} />

    return (
        <Modal isVisible={isVisible} useContentTag={true} onClose={onCancel}>
            <ModalContent>
                <ModalHeader>
                    <ModalHeaderTitle>Change Tag Status?</ModalHeaderTitle>
                </ModalHeader>
                <ModalBody>
                    <Grid columns={1}>
                        <div>
                            Would you like to change the tag status of this asset?
                        </div>
                        <FormFieldGroup label="Change Tag">
                            <FormField>
                                <Grid columns={3} gap={GridGap.Normal}>
                                    <Radio
                                        label={"Remove Tag"}
                                        isChecked={data.tagChange === TagChange.RemoveTag}
                                        onSelected={() => onTagChanged(TagChange.RemoveTag)}
                                        isEnabled={!disableSelection && !disableNoneOption}
                                    />
                                    <Radio
                                        label={"Red Tag"}
                                        isChecked={data.tagChange === TagChange.RedTag}
                                        onSelected={() => onTagChanged(TagChange.RedTag)}
                                        isEnabled={!disableSelection}
                                    />
                                    <Radio
                                        label={"Yellow Tag"}
                                        isChecked={data.tagChange === TagChange.YellowTag}
                                        onSelected={() => onTagChanged(TagChange.YellowTag)}
                                        isEnabled={!disableSelection}
                                    />
                                </Grid>
                            </FormField>
                            <FormField label="Reason" >
                                <TextArea
                                    type={TextAreaType.Empty}
                                    value={data?.reason ?? ""}
                                    onChange={(value) => onReasonChanged(value)}
                                    maxLength={250}
                                    disabled={tagChangeFromTagType(assetTag.type) === data.tagChange}                                            
                                />
                            </FormField>                            
                        </FormFieldGroup>
                    </Grid>
                </ModalBody>
                <ModalButtons>
                    <Button label="No Tag Change" type={ButtonType.Outlined} onClick={onNoTagChangeClicked} />
                    <Button
                        label="Save"
                        isEnabled={(( tagChangeFromTagType(assetTag.type) !== data.tagChange) && data.reason.trim() !== "")}
                        onClick={() => onConfirm()}
                    />
                </ModalButtons>
            </ModalContent>
        </Modal>
    )
}
