import React from "react"

import {
    DatePicker as MuiDatePicker,
    DateTimePicker as MuiDateTimePicker,
    KeyboardDatePicker,
    KeyboardDateTimePicker,
} from "@material-ui/pickers"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import { TextInputType } from "./TextInput"
import { classNames } from "@lib/HtmlUtil"

export type DatePickerProps = {
    value: Date | null
    onChange?: (date: Date) => void
    theme?: DatePickerTheme
    disablePast?: boolean
    disabled?: boolean
    disableFuture?: boolean
    outlineInvalid?: boolean
}

export enum DatePickerTheme {
    Light = "light",
    Normal = "normal",
    Dark = "dark",
}

export function DatePicker({
    value = new Date(),
    theme = DatePickerTheme.Normal,
    disablePast = true,
    disabled,
    onChange,
    outlineInvalid,
}: DatePickerProps): JSX.Element {
    return (
        <div className={"date-picker"} data-theme={theme} {...(disabled ? { "data-disabled": true } : {})}>
            <MuiDatePicker
                format={"MM/dd/yyyy"}
                disablePast={disablePast}
                variant="dialog"
                value={value}
                disabled={disabled}
                minDateMessage={null}
                maxDateMessage={null}
                onChange={(date) => {
                    if (date === null) return
                    onChange?.(date)
                }}
                className={outlineInvalid ? "outlineInvalid" : ""}
            />
        </div>
    )
}

export function TextInputDatePicker({
    value = new Date(),
    theme = DatePickerTheme.Normal,
    disablePast = true,
    disabled,
    onChange,
    outlineInvalid,
}: DatePickerProps): JSX.Element {
    return (
        <div className={"date-picker"} data-theme={theme} {...(disabled ? { "data-disabled": true } : {})}>
            <KeyboardDatePicker
                format={"MM/dd/yyyy"}
                variant="dialog"
                value={value}
                type={TextInputType.Integer}
                disabled={disabled}
                minDateMessage={null}
                maxDateMessage={null}
                shouldDisableDate={(date: MaterialUiPickersDate) => {
                    if (date && disablePast && date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) return true

                    return false
                }}
                onChange={(date) => {
                    if (date === null) return
                    onChange?.(date)
                }}
                className={outlineInvalid ? "outlineInvalid" : ""}
            />
        </div>
    )
}

export function TextInputDateTimePicker({
    value = new Date(),
    theme = DatePickerTheme.Normal,
    disablePast = true,
    disableFuture = false,
    disabled,
    onChange,
    outlineInvalid,
}: DatePickerProps): JSX.Element {
    return (
        <div className={"date-picker"} data-theme={theme} {...(disabled ? { "data-disabled": true } : {})}>
            <KeyboardDateTimePicker
                format={"MM/dd/yyyy h:mma"}
                variant="dialog"
                value={value}
                type={TextInputType.Integer}
                disabled={disabled}
                minDateMessage={null}
                maxDateMessage={null}
                shouldDisableDate={(date: MaterialUiPickersDate) => {
                    if (date && disablePast && date.getTime() < new Date().getTime()) return true

                    if (date && disableFuture && date.getTime() > new Date().getTime()) return true

                    return false
                }}
                onChange={(date) => {
                    if (date === null) return
                    onChange?.(date)
                }}
                className={outlineInvalid ? "outlineInvalid" : ""}
            />
        </div>
    )
}

export function DateTimePicker({
    value = new Date(),
    theme = DatePickerTheme.Normal,
    disablePast = true,
    disableFuture = false,
    disabled,
    onChange,
    outlineInvalid,
}: DatePickerProps): JSX.Element {
    return (
        <div className={"date-picker"} data-theme={theme} {...(disabled ? { "data-disabled": true } : {})}>
            <MuiDateTimePicker
                format={"MM/dd/yyyy h:mma"}
                disablePast={disablePast}
                disableFuture={disableFuture}
                variant="dialog"
                value={value}
                disabled={disabled}
                minDateMessage={null}
                maxDateMessage={null}
                onChange={(date) => {
                    if (date === null) return
                    onChange?.(date)
                }}
                className={outlineInvalid ? "outlineInvalid" : ""}
            />
        </div>
    )
}
