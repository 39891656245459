import { Outcome } from "@ethossoftworks/outcome"
import { Bloc } from "@lib/bloc/Bloc"
import { Strings } from "@lib/Strings"
import { safeParseFloat } from "@lib/TypeUtil"
import { Asset, AssetDataSource } from "@model/assets/Asset"
import {
    newServiceRecordForm,
    newServiceRequestForm,
    ServiceFormType,
    ServiceRecordForm,
    ServiceRequestForm,
} from "@model/serviceRequests/ServiceForm"
import { ServiceCode, ServiceRequestType } from "@model/serviceRequests/ServiceRequest"
import { Site } from "@model/site/Site"
import { tagChangeFromTag, TagType } from "@model/Tag"
import { Urgency } from "@model/Urgency"
import { WorkOrder, WorkOrderStatus } from "@model/workOrders/WorkOrder"
import { labelForWorkRequest, WorkRequest, WorkRequestType } from "@model/workRequests/WorkRequest"
import { AssetService } from "@service/assets/AssetService"
import { WorkRequestService } from "@service/workRequests/WorkRequestService"
import cloneDeep from "lodash/cloneDeep"
import { v4 as uuidv4 } from "uuid"

export type ServiceRequestCreationState = {
    isModalVisible: boolean
    isSearchingForAssets: boolean
    availableAssets: Asset[]
    selectedAsset: Asset | null
    isFormValid: boolean
    requestForm: ServiceRequestForm
    recordForm: ServiceRecordForm
    formType: ServiceFormType
    isSaving: boolean
    followUpWorkRequest?: WorkRequest
    callerFromMobileApp?: string
    isUnplanned: boolean
}

export enum ServiceRequestCreationEffects {
    SearchingForAsset = "searchingForAsset",
}

type RequestFieldType<T extends keyof ServiceRequestForm> = ServiceRequestForm[T]
type RecordFieldType<T extends keyof ServiceRecordForm> = ServiceRecordForm[T]

export class ServiceRequestCreationBloc extends Bloc<ServiceRequestCreationState> {
    constructor(private assetService: AssetService, private workRequestService: WorkRequestService) {
        super(newServiceRequestCreationState())
    }

    override computed = (state: ServiceRequestCreationState): Partial<ServiceRequestCreationState> => ({
        requestForm: {
            ...state.requestForm,
            estimatedTotalCost: Strings.formatMoney(
                safeParseFloat(state.requestForm.estimatedLaborCost ?? "") +
                    safeParseFloat(state.requestForm.estimatedPartsCost ?? "")
            ),
        },
        recordForm: {
            ...state.recordForm,
            totalCost: Strings.formatMoney(
                safeParseFloat(state.recordForm.laborCost ?? "") + safeParseFloat(state.recordForm.partsCost ?? "")
            ),
            createManualEntry: state.selectedAsset
                ? this.requiresManualEntry(state.selectedAsset, state.formType)
                : false,
        },
        isFormValid: (() => {
            if (state.selectedAsset === null) return false
            if (state.formType === ServiceFormType.Request) {
                if ((state.requestForm.workToBePerformed?.trim() ?? "") === "") return false
                if (state.requestForm.tag !== null && (state.requestForm.tag.reason?.trim() ?? "") === "") return false
            } else if (state.formType === ServiceFormType.Record) {
                if (state.recordForm.serviceDate === null) return false
                if ((state.recordForm.workPerformed?.trim() ?? "") === "") return false
                if (new Date(state.recordForm.serviceDate) > new Date()) return false
            }
            return true
        })(),
    })

    requiresManualEntry = (asset: Asset, formType: ServiceFormType) => {
        let endOfAssetLabel = asset?.label?.substring(asset?.label?.length - 5)

        return (
            formType === ServiceFormType.Record &&
            !endOfAssetLabel?.includes("+") /*UnPaired*/ &&
            asset.AssetDataSource !== AssetDataSource.OEMDevice
        )
    }

    searchAssets = (search: string) =>
        this.effect({
            id: ServiceRequestCreationEffects.SearchingForAsset,
            block: async (job) => {
                this.isSearchingForAssetsChanged(true)
                const outcome = await job.pause(this.assetService.searchAssets(search, true))

                if (outcome.isOk()) {
                    this.availableAssetsChanged(outcome.value)
                }

                this.isSearchingForAssetsChanged(false)
                return outcome
            },
        })

    createServiceRequest = (userId: number) =>
        this.effect({
            id: this.createServiceRequest,
            block: async (job) => {
                if (!this.state.selectedAsset) return Outcome.error("No selected asset")

                var form = cloneDeep(this.state.requestForm)
                if (this.state.followUpWorkRequest!) {
                    form.workOrderId = null
                }

                const formTagChange = tagChangeFromTag(this.state.requestForm.tag)
                const originalTagChange = tagChangeFromTag(this.state.selectedAsset.tag ?? null)

                if (originalTagChange === formTagChange) {
                    form.tag = null
                }

                const response = await job.pause(this.workRequestService.createServiceRequest(form, userId))

                return response
            },
        })

    createServiceRecord = (userId: number) =>
        this.effect({
            id: this.createServiceRecord,
            block: async (job) => {
                if (!this.state.selectedAsset) return Outcome.error("No selected asset")
                const response = await job.pause(
                    this.workRequestService.createServiceRecord(this.state.recordForm, userId)
                )

                return response
            },
        })

    updateIsSaving = (value: boolean) => this.update({ isSaving: value })

    showModal = (formType: ServiceFormType, workRequest?: WorkRequest, workOrder?: WorkOrder) => {
        let workToBePerformedText: string = this.state.requestForm.workToBePerformed ?? ""
        let specialInstructionsText: string = this.state.requestForm.specialInstructions ?? ""
        let serviceType: ServiceRequestType = this.state.requestForm.type ?? ""
        if (workRequest! && workOrder!) {
            //It's for follow up service Request. We pre-populate data for it:
            switch (workRequest.workRequestType) {
                case WorkRequestType.Service:
                    workToBePerformedText =
                        "Incomplete Service Request - Work Order " +
                        workOrder.number +
                        ": " +
                        labelForWorkRequest(workRequest)
                    specialInstructionsText = workRequest.notes
                case WorkRequestType.Inspection:
                    workToBePerformedText =
                        "Incomplete Service Request - Work Order " +
                        workOrder.number +
                        ": " +
                        labelForWorkRequest(workRequest)
                    specialInstructionsText =
                        workRequest.tasks
                            ?.filter((i) => i.value == "Fail")
                            .map((i) => "- " + i.label)
                            .join("\n") ?? ""
                    break
                case WorkRequestType.Preventative:
                    workToBePerformedText =
                        "Incomplete Service Request - Work Order " +
                        workOrder.number +
                        ": " +
                        labelForWorkRequest(workRequest)
                    specialInstructionsText =
                        workRequest.tasks
                            ?.filter((i) => i.value == "Not Performed")
                            .map((i) => "- " + i.label)
                            .join("\n") ?? ""
                    break
            }

            serviceType = ServiceRequestType.FollowUp
        }

        this.update({
            isModalVisible: true,
            followUpWorkRequest: workRequest,
            formType,
            requestForm: {
                ...this.state.requestForm,
                workToBePerformed: workToBePerformedText,
                specialInstructions: specialInstructionsText,
                followUpWorkRequestId: workRequest! ? workRequest.id : null,
                type: serviceType,
            },
        })
    }

    hideModalAndResetForm = () => {
        this.update({
            isModalVisible: false,
            formType: ServiceFormType.Request,
            requestForm: newServiceRequestForm(),
            recordForm: newServiceRecordForm(),
            selectedAsset: null,
        })
    }

    clearAttachmentsOnCancel = () => {
        if (this.state.formType == ServiceFormType.Request) {
            if (this.state.requestForm.filesId) {
                this.clearAttachments(
                    this.state.requestForm.filesId,
                    this.state.requestForm.attachments.map((i) => i.name)
                )
            }
        } else {
            if (this.state.recordForm.filesId) {
                this.clearAttachments(
                    this.state.recordForm.filesId,
                    this.state.recordForm.attachments.map((i) => i.name)
                )
            }
        }
    }

    private clearAttachments = (id: string, fileNames: string[]) => {
        this.effect({
            id: this.clearAttachments,
            block: async (job) => {
                this.update({ isSaving: true })
                const response = await job.pause(this.workRequestService.deleteAttachments(id, fileNames))
                return response
            },
            onDone: () => this.update({ isSaving: false }),
        })
    }

    private availableAssetsChanged = (assets: Asset[]) => this.update({ availableAssets: assets })

    loadDefaultSelectedAsset = (assetId: number) => {
        this.effect({
            id: ServiceRequestCreationEffects.SearchingForAsset,
            block: async (job) => {
                this.isSearchingForAssetsChanged(true)
                const outcome = await job.pause(this.assetService.fetchAsset(assetId))
                if (outcome.isOk()) {
                    this.selectedAssetChanged(outcome.value)
                }
                this.isSearchingForAssetsChanged(false)
                return outcome
            },
        })
    }

    selectedAssetChanged = (asset: Asset | null) =>
        this.update({
            selectedAsset: asset,
            requestForm: {
                ...this.state.requestForm,
                assetId: asset ? asset.id : null,
                tag: asset?.tag ? asset?.tag : null,
            },
            recordForm: { ...this.state.recordForm, assetId: asset ? asset.id : null },
        })

    private isSearchingForAssetsChanged = (isSearching: boolean) => this.update({ isSearchingForAssets: isSearching })

    callerFromMobileAppChanged = (callerFromMobileApp: string) =>
        this.update({ callerFromMobileApp: callerFromMobileApp })
    requestServiceTypeChanged = (value: ServiceRequestType) => this.requestFormFieldChanged("type", value)
    requestServiceCodeChanged = (value: ServiceCode | null) => this.requestFormFieldChanged("serviceCode", value)
    requestUrgencyChanged = (value: Urgency) => this.requestFormFieldChanged("urgency", value)
    requestDueDateChanged = (value: Date | null) => this.requestFormFieldChanged("dueDate", value)
    requestDueHourChanged = (value: string) => this.requestFormFieldChanged("dueHourMeter", value)
    requestDueOdometerChanged = (value: string) => this.requestFormFieldChanged("dueOdometer", value)
    requestEstimatedLaborHoursChanged = (value: string) => this.requestFormFieldChanged("estimatedLaborHours", value)
    requestEstimatedLaborCostChanged = (value: string) => this.requestFormFieldChanged("estimatedLaborCost", value)
    requestEstimatedPartsCostChanged = (value: string) => this.requestFormFieldChanged("estimatedPartsCost", value)
    requestWorkToBePerformedChanged = (value: string) =>
        this.requestFormFieldChanged("workToBePerformed", value.trim() === "" ? null : value)
    requestSpecialInstructionsChanged = (value: string) =>
        this.requestFormFieldChanged("specialInstructions", value.trim() === "" ? null : value)
    requestTagChanged = (value: TagType | null) => {
        if (value === null || value === TagType.None) {
            this.requestFormFieldChanged("tag", null)
            return
        }
        this.requestFormFieldChanged("tag", {
            type: value,
            dateTagged:
                this.state.selectedAsset?.tag?.type !== value ? new Date() : this.state.selectedAsset?.tag?.dateTagged,
            daysTagged: this.state.selectedAsset?.tag?.type !== value ? 0 : this.state.selectedAsset?.tag?.daysTagged,
            reason: this.state.requestForm.tag?.reason ?? "",
        })
    }
    requestTagReasonChanged = (value: string) => {
        if (this.state.requestForm.tag === null) return

        this.requestFormFieldChanged("tag", {
            ...this.state.requestForm.tag,
            reason: value,
        })
    }
    requestNotifyContactChanged = (contactIds: number[]) => this.requestFormFieldChanged("notifyContacts", contactIds)
    requestCreateWorkOrderChanged = (value: boolean) => {
        this.requestFormFieldChanged("createWorkOrder", value)
        if (!value) {
            this.requestFormFieldChanged("workOrderStatus", null)
            this.requestFormFieldChanged("workOrderAssignedTo", null)
            this.requestFormFieldChanged("workOrderSpecialInstructions", null)
        }
    }

    requestMechanicsNotesChanged = (value: string) => this.requestFormFieldChanged("mechanicsNotes", value)


    requestIsUnplannedChanged = (value: boolean) => {
        this.requestFormFieldChanged("isUnplanned", value)
        if (!value) {
            this.requestFormFieldChanged("workOrderStatus", null)
            this.requestFormFieldChanged("workOrderAssignedTo", null)
            this.requestFormFieldChanged("workOrderSpecialInstructions", null)
        }
    }
    requestWorkOrderStatusChanged = (value: WorkOrderStatus) => this.requestFormFieldChanged("workOrderStatus", value)
    requestWorkOrderAssignedToChanged = (value: number | null) =>
        this.requestFormFieldChanged("workOrderAssignedTo", value)
    requestWorkOrderSpecialInstructionsChanged = (value: string) =>
        this.requestFormFieldChanged("workOrderSpecialInstructions", value)

    private requestFormFieldChanged = <T extends keyof ServiceRequestForm>(field: T, value: RequestFieldType<T>) =>
        this.update({
            requestForm: {
                ...this.state.requestForm,
                [field]: value,
            },
        })

    recordServiceCodeChanged = (value: ServiceCode | null) => this.recordFormFieldChanged("serviceCode", value)
    recordServiceDateChanged = (value: Date | null) => this.recordFormFieldChanged("serviceDate", value)
    recordSiteChanged = (value: Site | null) => this.recordFormFieldChanged("site", value)
    recordWorkPerformedByChanged = (value: number[] | null) => this.recordFormFieldChanged("workPerformedBy", value)
    recordHourMeterChanged = (value: string) => this.recordFormFieldChanged("hourMeter", value)
    recordOdometerChanged = (value: string) => this.recordFormFieldChanged("odometer", value)
    recordIsUnplannedChanged = (value: boolean) => {
        this.recordFormFieldChanged("isUnplanned", value)
    }
    recordLaborHoursChanged = (value: string) => this.recordFormFieldChanged("laborHours", value)
    recordLaborCostChanged = (value: string) => this.recordFormFieldChanged("laborCost", value)
    recordPartsCostChanged = (value: string) => this.recordFormFieldChanged("partsCost", value)
    recordWorkPerformedChanged = (value: string) => this.recordFormFieldChanged("workPerformed", value)
    recordMechanicsNotesChanged = (value: string) => this.recordFormFieldChanged("mechanicsNotes", value)
    recordExternalWorkOrderChanged = (value: string) => this.recordFormFieldChanged("externalWorkOrder", value)
    recordExternalVendorChanged = (value: string) => this.recordFormFieldChanged("externalVendor", value)
    recordExternalInvoiceNumberChanged = (value: string) => this.recordFormFieldChanged("externalInvoice", value)
    recordExternalNoteChanged = (value: string) => this.recordFormFieldChanged("externalNote", value)

    private recordFormFieldChanged = <T extends keyof ServiceRecordForm>(field: T, value: RecordFieldType<T>) =>
        this.update({
            recordForm: {
                ...this.state.recordForm,
                [field]: value,
            },
        })

    attachmentsChanged = async (files: File[], formData: FormData, userId: number, companyId: number) => {
        const fileId: string = this.getFilesId()
        this.update({ isSaving: true })

        const response = await this.workRequestService.uploadAttachments(fileId, Array.from(files), userId, companyId)

        if (response.isOk()) {
            if (this.state.formType === ServiceFormType.Request) {
                this.requestFormFieldChanged("attachments", this.state.requestForm.attachments.concat(response.value))
            } else {
                this.recordFormFieldChanged("attachments", this.state.recordForm.attachments.concat(response.value))
            }
        }

        this.update({ isSaving: false })
        return response
    }

    attachmentsDelete = (attachmentName: string, userId: number, companyId: number) => {
        const filesId: string = this.getFilesId()
        this.effect({
            id: this.attachmentsDelete,
            block: async (job) => {
                if (!attachmentName || !filesId) return Outcome.error(undefined)
                const response = await job.pause(this.workRequestService.deleteAttachments(filesId, [attachmentName]))
                if (response.isOk()) {
                    if (this.state.formType == ServiceFormType.Request) {
                        this.requestFormFieldChanged(
                            "attachments",
                            this.state.requestForm.attachments.filter((i) => i.name != attachmentName)
                        )
                    } else {
                        this.recordFormFieldChanged(
                            "attachments",
                            this.state.recordForm.attachments.filter((i) => i.name != attachmentName)
                        )
                    }
                }
                return response
            },
        })
    }
    rotateImage = (attachmentName: string, angle: number, userId: number, companyId: number) => {
        const fileId: string = this.getFilesId()
        this.effect({
            id: this.rotateImage,
            block: async (job) => {
                if (!attachmentName || !fileId) return Outcome.error(undefined)
                const response = await job.pause(this.workRequestService.rotateImage(fileId, attachmentName, angle))
                let attachments = JSON.parse(
                    JSON.stringify(
                        this.state.formType == ServiceFormType.Request
                            ? this.state.requestForm.attachments
                            : this.state.recordForm.attachments
                    )
                )
                let modified = attachments.find(
                    (x: any) => x.name.toLowerCase().trim() === attachmentName.toLowerCase().trim()
                )
                modified.url = modified.url.split("&rotated=")[0] + "&rotated=" + new Date().getTime()

                if (this.state.formType == ServiceFormType.Request) {
                    this.requestFormFieldChanged("attachments", [...attachments, { ...modified }])
                    this.requestFormFieldChanged("attachments", [...attachments])
                } else {
                    this.recordFormFieldChanged("attachments", [...attachments, { ...modified }])
                    this.recordFormFieldChanged("attachments", [...attachments])
                }

                return response
            },
        })
    }
    private getFilesId = (): string => {
        if (this.state.formType == ServiceFormType.Request) {
            if (!this.state.requestForm.filesId) {
                this.state.requestForm.filesId = uuidv4()
            }
            return this.state.requestForm.filesId
        } else {
            if (!this.state.recordForm.filesId) {
                this.state.recordForm.filesId = uuidv4()
            }
            return this.state.recordForm.filesId
        }
    }

    fetchAssetUsageData = (assetId: number, date: Date) =>
        this.effect({
            id: this.fetchAssetUsageData,
            block: async (job) => {
                const assetUsageOutcome = await job.pause(this.assetService.fetchAssetUsage(assetId, date))
                if (assetUsageOutcome.isOk()) {
                    return Outcome.ok(assetUsageOutcome.value)
                }

                return Outcome.error(undefined)
            },
        })
}

function newServiceRequestCreationState(): ServiceRequestCreationState {
    return {
        isSaving: false,
        isModalVisible: false,
        isSearchingForAssets: false,
        availableAssets: [],
        requestForm: newServiceRequestForm(),
        recordForm: newServiceRecordForm(),
        selectedAsset: null,
        isFormValid: false,
        formType: ServiceFormType.Request,
        isUnplanned: false,
    }
}
