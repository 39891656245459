import { compare, SortOrder } from "@lib/Comparable"
import { shortDateFormat } from "@lib/DateUtil"
import { PDFWorkOrder, PDFWorkOrderStatus } from "@lib/pdf/models/PDFWorkOrder"
import { PDFWorkRequest, PDFWorkRequestTask, PDFWorkRequestType } from "@lib/pdf/models/PDFWorkRequest"
import { Attachment } from "@model/Attachment"
import { Contact } from "@model/contacts/Contact"
import { HistoryItem } from "@model/HistoryItem"
import { labelForServiceCode, ServiceCode } from "@model/serviceRequests/ServiceRequest"
import { Site } from "@model/site/Site"
import { Tag } from "@model/Tag"
import { Task } from "@model/Task"
import { labelForUrgency, Urgency } from "@model/Urgency"
import { WorkRequest, WorkRequestType } from "@model/workRequests/WorkRequest"
import { CBACode } from "./CbaCode"

export type WorkOrder = {
    id: number
    assetId: number
    number: string | null
    status: WorkOrderStatus
    urgency: Urgency
    attachments: Attachment[]
    tag: Tag | null
    daysOpen: number
    daysInactive: number
    dueDate: Date | null
    dueHourMeter: number | null
    dueOdometer: number | null
    assignedTo: number
    specialInstructions: string
    history: HistoryItem<WorkOrderStatus>[]
    workPerformedById: number[] | null
    siteId: number | null
    workCompletedDate: Date | null
    completedHourMeter: number | null
    completedOdometer: number | null
    openedDate: Date | null
    closedDate: Date | null
    travelDistance: string | null
    travelTime: string | null
    nightWork: boolean
    cbaCode: CBACode | null
    notifyContacts: number[]
    serviceRecord: boolean | null
}

export function newWorkOrder(): WorkOrder {
    return {
        id: 0,
        assetId: 0,
        number: null,
        status: WorkOrderStatus.Pending,
        urgency: Urgency.Medium,
        attachments: [],
        tag: null,
        daysOpen: 0,
        daysInactive: 0,
        dueDate: new Date(),
        dueHourMeter: 0,
        dueOdometer: 0,
        assignedTo: 0,
        specialInstructions: "",
        history: [],
        workPerformedById: null,
        siteId: null,
        workCompletedDate: null,
        completedHourMeter: null,
        completedOdometer: null,
        openedDate: null,
        closedDate: null,
        travelDistance: null,
        travelTime: null,
        nightWork: false,
        cbaCode: null,
        notifyContacts: [],
        serviceRecord: null,
    }
}

export enum WorkOrderStatus {
    Deleted = "deleted",
    Pending = "pending",
    Open = "open",
    InProgress = "inProgress",
    WorkCompleted = "workCompleted",
    Closed = "closed",
}

export function workOrderStatusForValue(value: string): WorkOrderStatus {
    switch (value) {
        case WorkOrderStatus.Deleted:
            return WorkOrderStatus.Deleted
        case WorkOrderStatus.Pending:
            return WorkOrderStatus.Pending
        case WorkOrderStatus.InProgress:
            return WorkOrderStatus.InProgress
        case WorkOrderStatus.Open:
            return WorkOrderStatus.Open
        case WorkOrderStatus.WorkCompleted:
            return WorkOrderStatus.WorkCompleted
        case WorkOrderStatus.Closed:
            return WorkOrderStatus.Closed
        default:
            return WorkOrderStatus.Pending
    }
}

export function labelForWorkOrderStatus(status: WorkOrderStatus): string {
    switch (status) {
        case WorkOrderStatus.Deleted:
            return "Deleted"
        case WorkOrderStatus.Pending:
            return "Pending"
        case WorkOrderStatus.Open:
            return "Open"
        case WorkOrderStatus.InProgress:
            return "In Progress"
        case WorkOrderStatus.WorkCompleted:
            return "Work Completed"
        case WorkOrderStatus.Closed:
            return "Closed"
    }
}
export function numberForWorkOrderStatus(status: WorkOrderStatus): number {
    switch (status) {
        case WorkOrderStatus.Deleted:
            return 0
        case WorkOrderStatus.Pending:
            return 1
        case WorkOrderStatus.Open:
            return 2
        case WorkOrderStatus.InProgress:
            return 3
        case WorkOrderStatus.WorkCompleted:
            return 5
        case WorkOrderStatus.Closed:
            return 4
    }
}
export enum WorkOrderFilter {
    Search = "search",
    SubCompany = "subCompany",
    District = "district",
    SubDistrict = "subDistrict",
    Unit = "unit",
    Group = "group",
    Site = "site",
    AssetType = "assetType",
    Category = "category",
    Class = "class",
    Make = "make",
    Model = "model",
    Status = "activeStatus",
    Urgency = "urgency",
    Schedule = "schedule",
    HoursUntil = "hoursUntil",
    HoursUntilUnit = "hoursUntilUnit",
    MilesUntil = "milesUntil",
    MilesUntilUnit = "milesUntilUnit",
    DaysUntil = "daysUntil",
    DaysUntilUnit = "daysUntilUnit",
    ServiceCode = "serviceCode",
    RedTag = "redTag",
    YellowTag = "yellowTag",
    Overdue = "overdue",
    Upcoming = "upcoming",
    AssignedTo = "assignedTo",
    WorkType = "workType",
    WOSearch = "woSearch",
}

export enum WorkOrderQuickFilterCount {
    WorkOrders = "workOrders",
    Pending = "pending",
    Open = "open",
    InProgress = "inProgress",
    Completed = "completed",
    RedTag = "redTag",
    YellowTag = "yellowTag",
    Immediate = "immediate",
    Overdue = "overdue",
    High = "high",
    Medium = "medium",
    Low = "low",
}

export type Mechanic = {
    id: number
    name: string
}

export type WorkOrderTableRow = {
    id: number
    status: WorkOrderStatus
    number: string | null
    assetName: string
    assetId: number
    tag: Tag | null
    urgency: Urgency
    daysOpen: number
    daysInactive: number
    location: string
    site: {
        id: number
        name: string
    } | null
    workToBePerformed: string[]
    dueDate: Date | null
    dueOdometer: number | null
    dueHourMeter: number | null
    hoursUntil: number | null
    milesUntil: number | null
    daysUntil: number | null

    hourMeter: number
    odometer: number
    serviceCode: string[]
    class: string
    subCompany: string
    district: string
    subDistrict: string
    unit: string
    group: string
    city: string
    state: string
    assignedTo: Mechanic[]
    closedDate: Date | null
}

// Note: These values are used to store column preferences
export enum WorkOrderTableColumn {
    Tag = "tag",
    WorkOrder = "workOrder",
    Asset = "asset",
    Status = "status",
    Urgency = "urgency",
    DaysOpen = "daysOpen",
    Location = "location",
    WorkToBePerformed = "workToBePerformed",
    DueDate = "dueDate",
    HoursUntil = "hoursUntil",
    MilesUntil = "milesUntil",
    DaysUntil = "daysUntil",

    AssignedTo = "assignedTo",
    HourMeter = "hourMeter",
    Odometer = "odometer",
    DaysInactive = "daysInactive",
    ServiceCode = "serviceCode",
    Class = "class",
    SubCompany = "subCompany",
    District = "district",
    SubDistrict = "subDistrict",
    Unit = "unit",
    Group = "group",
    City = "city",
    State = "state",
    ClosedDate = "closedDate",
}

export const defaultWorkOrdersTableColumns = [
    WorkOrderTableColumn.Tag,
    WorkOrderTableColumn.WorkOrder,
    WorkOrderTableColumn.Asset,
    WorkOrderTableColumn.Status,
    WorkOrderTableColumn.Urgency,
    WorkOrderTableColumn.DaysOpen,
    WorkOrderTableColumn.Location,
    WorkOrderTableColumn.WorkToBePerformed,
    WorkOrderTableColumn.DueDate,
    WorkOrderTableColumn.HoursUntil,
    WorkOrderTableColumn.MilesUntil,
    WorkOrderTableColumn.DaysUntil,
]

export function sortWorkOrderColumns(columns: WorkOrderTableColumn[]): WorkOrderTableColumn[] {
    return columns.sort((a, b) => {
        const order = Object.values(WorkOrderTableColumn)
        return compare(order.indexOf(a), order.indexOf(b), SortOrder.Asc)
    })
}

export function pdfWorkOrderFromModels(
    workOrder: WorkOrder,
    workRequests: WorkRequest[],
    contacts: Contact[],
    serviceCodes: ServiceCode[],
    sites: Site[]
): PDFWorkOrder {
    const assignedToContact = contacts.find(contact => contact.id === workOrder.assignedTo);
    const assignedToDisplayName = assignedToContact ? assignedToContact.displayName : "";

    let workPerformedByIds: number[] = [];
    if (workOrder.workPerformedById && Array.isArray(workOrder.workPerformedById)) {
        workPerformedByIds = [...new Set(workOrder.workPerformedById)]; // Remove duplicates
    }

    const allContactIds = [...new Set([workOrder.assignedTo, ...workPerformedByIds])]; // Combine and remove duplicates
    const allContactDisplayNames = allContactIds
        .map(id => {
            const contact = contacts.find(contact => contact.id === id);
            return contact ? contact.displayName : "";
        })
        .filter(displayName => displayName !== ""); // Remove undefined display names

    const assignedTo = allContactDisplayNames.join(", ");

    return {
        originalWorkOrder: workOrder,
        originalContacts: contacts,
        workOrderNumber: workOrder.number ?? "",
        dueDate: workOrder.dueDate ? shortDateFormat(workOrder.dueDate) : "",
        dueHourMeter: workOrder.dueHourMeter != null ? workOrder.dueHourMeter : null,
        dueOdometer: workOrder.dueOdometer,
        urgency: labelForUrgency(workOrder.urgency),
        assignedTo: assignedTo,
        specialInstructions: workOrder.specialInstructions,
        workRequests: workRequests.map((i) => pdfWorkRequestFromModel(i, serviceCodes)),
        status: pdfWorkOrderStatusFromModelStatus(workOrder.status),
        siteName: sites?.find((x) => workOrder.siteId && x.id === workOrder.siteId)?.name,
        closedDate: workOrder.closedDate ? shortDateFormat(workOrder.closedDate) : "",
    };
}

export function pdfWorkRequestFromModel(workRequest: WorkRequest, serviceCodes: ServiceCode[]): PDFWorkRequest {
    return {
        originalWorkRequest: workRequest,
        type: pdfWorkRequestTypeFromModelType(workRequest.workRequestType),

        serviceCode:
            workRequest.serviceCodeId && serviceCodes.find((i) => i.id == workRequest.serviceCodeId) != undefined
                ? labelForServiceCode(serviceCodes.find((i) => i.id == workRequest.serviceCodeId)!)
                : "",
        workToBePerformed: workRequest.workToBePerformed,
        specialInstructions: workRequest.specialInstructions,
        tasks: workRequest.tasks?.map((it: Task): PDFWorkRequestTask => {
            return {
                name: it.label,
                description: it.description,
                status: it.value.toString(),
                measurement: it.measurement,
            }
        }),
        estimatedLaborHours: workRequest.estimatedLaborHours,
        estimatedLaborCost: workRequest.estimatedLaborCost,
        estimatedPartsCost: workRequest.estimatedPartsCost,
        estimatedTotalCost: workRequest.estimatedPartsCost + workRequest.estimatedLaborCost,
    }
}

export function pdfWorkOrderStatusFromModelStatus(workOrderStatus: WorkOrderStatus): PDFWorkOrderStatus {
    switch (workOrderStatus) {
        case WorkOrderStatus.Deleted:
            return PDFWorkOrderStatus.Deleted
        case WorkOrderStatus.Pending:
            return PDFWorkOrderStatus.Pending
        case WorkOrderStatus.InProgress:
            return PDFWorkOrderStatus.InProgress
        case WorkOrderStatus.Open:
            return PDFWorkOrderStatus.Open
        case WorkOrderStatus.WorkCompleted:
            return PDFWorkOrderStatus.WorkCompleted
        case WorkOrderStatus.Closed:
            return PDFWorkOrderStatus.Closed
    }
}

export function pdfWorkRequestTypeFromModelType(workRequestType: WorkRequestType): PDFWorkRequestType {
    switch (workRequestType) {
        case WorkRequestType.Inspection:
            return PDFWorkRequestType.Inspection
        case WorkRequestType.Preventative:
            return PDFWorkRequestType.Preventative
        case WorkRequestType.Service:
            return PDFWorkRequestType.Service
    }
}
