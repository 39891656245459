import { Bloc } from "@lib/bloc/Bloc"
import { arrayToObject } from "@lib/TypeUtil"
import {
    createDefaultFilter,
    Filter,
    FilterDef,
    FilterUpdateType,
    FilterValue,
    newDefaultFiltersObj,
    textFilterValue,
    updateFilter,
} from "@model/filters/Filter"
import { createFilterGroupFromPreset, FilterPreset } from "@model/filters/FilterPresets"

export type FilterOverlayState<T extends string> = {
    readonly isOverlayVisible: boolean
    readonly originalFilters: Record<T, Filter<T>>
    readonly filters: Record<T, Filter<T>>
    readonly originalPreset: FilterPreset<T> | null
    readonly preset: FilterPreset<T> | null
    readonly isPresetModified: boolean
    readonly isCreatePresetVisible: boolean
    readonly isManagePresetsVisible: boolean
    readonly isSearchFilterValid: boolean
    readonly isSearching: boolean
    readonly isSaving: boolean
    readonly isSecondaryFilterValid: boolean
}

export abstract class FilterOverlayBloc<T extends string> extends Bloc<FilterOverlayState<T>> {
    constructor(private filterTypes: T[], private filterDefinitions: Record<T, FilterDef<T>>) {
        super(newFilterOverlayState(filterTypes, filterDefinitions))
    }

    override computed(state: FilterOverlayState<T>): Partial<FilterOverlayState<T>> {
        return {
            ...state,
            isPresetModified: this.isPresetModified(state),
            isSearchFilterValid: this.computedIsSearchFilterValid(state),
            isSecondaryFilterValid: this.computedIsSecondaryFilterValid(state),
        }
    }

    abstract isPresetModified(state: FilterOverlayState<T>): boolean
    abstract getSearchFilter(): T
    abstract getSecondaryFilter?(): T

    toggleOverlayVisibility = () =>
        this.update({
            isOverlayVisible: this.state.isSearching ? false : !this.state.isOverlayVisible,
            filters: this.state.isOverlayVisible ? this.state.originalFilters : this.state.filters,
            preset: this.state.isOverlayVisible ? this.state.originalPreset : this.state.preset,
        })

    resetFilters = () => {
        this.update({
            originalFilters: undefined,
            preset: null,
            filters: newDefaultFiltersObj(this.filterTypes, this.filterDefinitions),
        })
    }
    applyFilters = () =>
        this.update({
            originalFilters: this.state.filters,
            originalPreset: this.state.preset,
            isOverlayVisible: false,
        })

    filterPresetChanged = (preset: FilterPreset<T> | null) =>
        this.filterPresetSelected(preset, {
            shouldUpdateOriginal: false,
            shouldUpdateFilters: true,
        })

    filterPresetFiltersUpdated = (preset: FilterPreset<T>) =>
        this.filterPresetSelected(preset, {
            shouldUpdateOriginal: this.state.preset?.id === this.state.originalPreset?.id,
            shouldUpdateFilters: true,
        })

    filterPresetFormUpdated = (preset: FilterPreset<T>) => {
        if (preset.id !== this.state.preset?.id) return
        this.filterPresetSelected(preset, {
            shouldUpdateOriginal: this.state.preset?.id === this.state.originalPreset?.id,
            shouldUpdateFilters: false,
        })
    }

    filterPresetDeleted = (preset: FilterPreset<T>) => {
        if (this.state.preset?.id !== preset.id) return
        this.filterPresetSelected(null, { shouldUpdateOriginal: true, shouldUpdateFilters: false })
    }

    defaultFilterPresetLoaded = (preset: FilterPreset<T>) =>
        this.filterPresetSelected(preset, { shouldUpdateOriginal: true, shouldUpdateFilters: true })

    filterPresetCreated = (preset: FilterPreset<T>) =>
        this.filterPresetSelected(preset, {
            shouldUpdateOriginal: this.state.preset?.id === this.state.originalPreset?.id && this.state.preset !== null,
            shouldUpdateFilters: true,
        })

    updateFilters = (filters: Record<T, Filter<T>>) => this.update({ originalFilters: filters, filters })

    updateFilter(type: T, value: FilterValue[]) {
        this.update({
            filters: {
                ...this.state.filters,
                ...(() => {
                    // Reset filter if one of its dependencies has changed
                    const dependencyUpdates: Partial<Record<T, Filter<T>>> = {}
                    for (const filter of Object.values(this.state.filters) as Filter<T>[]) {
                        if (!filter.definition.dependencies || !filter.definition.dependencies.includes(type)) continue
                        dependencyUpdates[filter.definition.type] = updateFilter(filter, [], FilterUpdateType.Set)
                    }
                    return dependencyUpdates
                })(),
                [type]: updateFilter(this.state.filters[type], value, FilterUpdateType.Set),
            },
        })
    }

    showCreatePreset = () => this.update({ isCreatePresetVisible: true })

    hideCreatePreset = () => this.update({ isCreatePresetVisible: false })

    showManagePresets = () => this.update({ isManagePresetsVisible: true })

    hideManagePresets = () => this.update({ isManagePresetsVisible: false })

    private filterPresetSelected = (
        preset: FilterPreset<T> | null,
        {
            shouldUpdateFilters = true,
            shouldUpdateOriginal = false,
        }: { shouldUpdateOriginal?: boolean; shouldUpdateFilters?: boolean }
    ) => {
        const filters = createFilterGroupFromPreset(preset, this.filterDefinitions)

        this.update({
            preset: preset,
            originalPreset: shouldUpdateOriginal ? preset : this.state.originalPreset,
            ...(shouldUpdateFilters ? { filters: filters } : {}),
            ...(shouldUpdateFilters && shouldUpdateOriginal ? { originalFilters: filters } : {}),
        })
    }

    isSearchFilterValid = () => this.computedIsSearchFilterValid(this.state)

    isSecondaryFilterValid = () => this.computedIsSecondaryFilterValid(this.state)

    isSaving = () => this.state.isSaving

    updateIsSaving = (value: boolean) =>
        this.update({
            isSaving: value,
        })

    updateIsSearching = (value: boolean) =>
        this.update({
            isSearching: value,
        })

    private computedIsSearchFilterValid(state: FilterOverlayState<T>): boolean {
        const searchFilter = textFilterValue(state.filters[this.getSearchFilter()])
        return (searchFilter.length === 0 || searchFilter.length >= 3) && !state.isSearching && !state.isSearching
    }
    private computedIsSecondaryFilterValid(state: FilterOverlayState<T>): boolean {
        let secondaryFilter
        if (this.getSecondaryFilter) {
            secondaryFilter = this.getSecondaryFilter();
        } else {
            
            return true;
        }
        const searchFilter = textFilterValue(state.filters[secondaryFilter])
        return (searchFilter.length === 0 || searchFilter.length >= 3) && !state.isSearching && !state.isSearching
    }
}

function newFilterOverlayState<T extends string>(
    type: T[],
    definitions: Record<T, FilterDef<T>>
): FilterOverlayState<T> {
    const filters = arrayToObject(
        Object.values(type).map((filter) => createDefaultFilter(definitions[filter])),
        (item) => [item.definition.type, item]
    )

    return {
        isOverlayVisible: false,
        originalFilters: arrayToObject(
            Object.values(type).map((filter) => createDefaultFilter(definitions[filter])),
            (item) => [item.definition.type, item]
        ),
        filters: filters,
        preset: null,
        originalPreset: null,
        isPresetModified: false,
        isCreatePresetVisible: false,
        isManagePresetsVisible: false,
        isSearchFilterValid: false,
        isSearching: false,
        isSaving: false,
        isSecondaryFilterValid: false,
    }
}
