import { compare, SortOrder } from "@lib/Comparable"

export type CompanyUrgency = {
    type: Urgency
    id: number
    companyId: number
}

export enum Urgency {
    Immediate = "immediate",
    High = "high",
    Medium = "medium",
    Low = "low",
}

export function compareUrgency(a: Urgency, b: Urgency, order: SortOrder = SortOrder.Desc): number {
    return compare(ordinalForUrgency(a), ordinalForUrgency(b), order)
}

export function ordinalForUrgency(urgency: Urgency): number {
    switch (urgency) {
        case Urgency.Immediate:
            return 3
        case Urgency.High:
            return 2
        case Urgency.Medium:
            return 1
        case Urgency.Low:
            return 0
    }
}

export function urgencyForValue(value: string): Urgency {
    switch (value) {
        case Urgency.Immediate:
            return Urgency.Immediate
        case Urgency.High:
            return Urgency.High
        case Urgency.Low:
            return Urgency.Low
        case Urgency.Medium:
            return Urgency.Medium
        default:
            return Urgency.Medium
    }
}

export function labelForUrgency(urgency: Urgency): string {
    switch (urgency) {
        case Urgency.Immediate:
            return "Immediate"
        case Urgency.High:
            return "High"
        case Urgency.Low:
            return "Low"
        case Urgency.Medium:
            return "Medium"
        default:
            return ""
    }
}

export function colorForUrgency(urgency: Urgency): string {
    switch (urgency) {
        case Urgency.Immediate:
            return "#FF644B"
        case Urgency.High:
            return "#F4B824"
        case Urgency.Low:
            return "#F5F7F7"
        case Urgency.Medium:
            return "#E3E7E8"
    }
}
