import { safeParseFloat, safeParseInt } from "@lib/TypeUtil"
import { Urgency } from "@model/Urgency"
import { newWorkOrder, WorkOrder, WorkOrderStatus } from "./WorkOrder"

export type CreateWorkOrderForm = {
    status: WorkOrderStatus
    assignedTo: number
    urgency: Urgency
    dueDate: Date | null
    dueHourMeter: string
    dueOdometer: string
    specialInstructions: string
    notifyContacts: number[]
}

export type ApiCreateWorkOrderForm = {
    workRequestIds: number[]
    assetId: number
    workOrderStatusId: number
    assignedMechanicIds: number[] | null
    urgencyId: number
    targetDueDate: string | null
    dueHourMeter: number | null
    dueOdometer: number | null
    specialNotes: string
    notifiedContacts: string
}

export function newCreateWorkOrderForm(): CreateWorkOrderForm {
    return {
        status: WorkOrderStatus.Pending,
        assignedTo: -1,
        urgency: Urgency.Medium,
        dueDate: null,
        dueHourMeter: "",
        dueOdometer: "",
        specialInstructions: "",
        notifyContacts: [],
    }
}

export function newWorkOrderFromCreateWorkOrderForm(form: CreateWorkOrderForm): WorkOrder {
    return {
        ...newWorkOrder(),
        status: form.status,
        assignedTo: form.assignedTo,
        urgency: form.urgency,
        dueDate: form.dueDate ?? new Date(),
        dueHourMeter: safeParseFloat(form.dueHourMeter),
        dueOdometer: safeParseInt(form.dueOdometer),
        specialInstructions: form.specialInstructions,
    }
}
