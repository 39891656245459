import { useBlocCoordinator } from "@lib/bloc/hooks"
import { useIsInIframe, useIsMobile } from "@lib/Hooks"
import { Color } from "@model/Color"
import { labelForContact } from "@model/contacts/Contact"
import { filterContainsValue, textFilterValue } from "@model/filters/Filter"
import { ServiceQuoteFilter, ServiceQuoteQuickFilterCount } from "@model/serviceQuotes/ServiceQuote"
import { ServiceQuoteFilterDefinitions } from "@model/serviceQuotes/ServiceQuoteFilterDefinitions"
import { Button } from "@ui/common/buttons/Button"
import { CircleButton } from "@ui/common/buttons/CircleButton"
import { QuickFilters, QuickFiltersButtons } from "@ui/common/filters/QuickFilters"
import { FormField, FormFieldDirection } from "@ui/common/form/FormField"
import { MultiSelect, MultiSelectTheme } from "@ui/common/form/MultiSelect"
import { TextInput, TextInputType } from "@ui/common/form/TextInput"
import { Grid } from "@ui/common/grids/Grid"
import { FilterIcon } from "@ui/common/images/InlinedSvgs"
import { LoadingIndicator } from "@ui/common/LoadingIndicator"
import { Modal, ModalContent } from "@ui/common/Modal"
import { DI } from "@ui/DI"
import React, { useEffect } from "react"

export function ServiceQuotesQuickFilters(): JSX.Element {
    const isMobile = useIsMobile() || useIsInIframe()
    const [state, viewModel] = useBlocCoordinator(() => DI.serviceQuotesQuickFiltersViewModel())

    return (
        <QuickFilters>
            <div className={`widget status-widget service-quote-filters`}>
            <div className="status-widget-group-cont">
                <div className="status-widget-group">
                    <div className="status-widget-group-name">Days Open</div>
                    <div className="status-widget-group-items">
            
            
                <CircleButton
                    bottomLabel="Under 7 Days"
                    isActive={state.isDateReceivedToggleActive}
                    circleLabel={state.counts[ServiceQuoteQuickFilterCount.DaysOpenSubSeven]}                    
                    {...(state.counts[ServiceQuoteQuickFilterCount.DaysOpenSubSeven] > 0 ? { color: Color.Green } : {})}
                    onClick={() =>
                        viewModel.toggleQuickFilter(ServiceQuoteQuickFilterCount.DaysOpenSubSeven, state.isDateReceivedToggleActive)
                    }
                />
                <CircleButton
                    bottomLabel="7-30 Days"
                    isActive={state.isDateReceivedToggleActive}
                    circleLabel={state.counts[ServiceQuoteQuickFilterCount.DaysOpenSevenToThirty]}
                    {...(state.counts[ServiceQuoteQuickFilterCount.DaysOpenSevenToThirty] > 0 ? { color: Color.Yellow } : {})}
                    onClick={() =>
                        viewModel.toggleQuickFilter(ServiceQuoteQuickFilterCount.DaysOpenSevenToThirty, state.isDateReceivedToggleActive)
                    }
                />
                <CircleButton
                    bottomLabel="30-90 Days"
                    isActive={state.isDateReceivedToggleActive}
                    circleLabel={state.counts[ServiceQuoteQuickFilterCount.DaysOpenThirtyToNinety]}                    
                    {...(state.counts[ServiceQuoteQuickFilterCount.DaysOpenThirtyToNinety] > 0 ? { color: Color.Orange } : {})}
                    onClick={() =>
                        viewModel.toggleQuickFilter(ServiceQuoteQuickFilterCount.DaysOpenThirtyToNinety, state.isDateReceivedToggleActive)
                    }
                />
                <CircleButton
                    bottomLabel="Over 90 Days"
                    isActive={state.isDateReceivedToggleActive}
                    circleLabel={state.counts[ServiceQuoteQuickFilterCount.DaysOpenOverNinety]}                    
                    {...(state.counts[ServiceQuoteQuickFilterCount.DaysOpenOverNinety] > 0 ? { color: Color.Red } : {})}
                    onClick={() =>
                        viewModel.toggleQuickFilter(ServiceQuoteQuickFilterCount.DaysOpenOverNinety, state.isDateReceivedToggleActive)
                    }
                />
                
                </div>
                </div>
                <div className="status-widget-group">
                        <div className="status-widget-group-name">Total Cost</div>
                        <div className="status-widget-group-items">
                        
                <CircleButton
                    bottomLabel="Under $1000"
                    isActive={state.isTotalCostToggleActive}
                    circleLabel={state.counts[ServiceQuoteQuickFilterCount.TotalCostSubOneThousand]}
                    {...(state.counts[ServiceQuoteQuickFilterCount.TotalCostSubOneThousand] > 0 ? { color: Color.Green } : {})}
                    onClick={() =>
                        viewModel.toggleQuickFilter(ServiceQuoteQuickFilterCount.TotalCostSubOneThousand, state.isTotalCostToggleActive)
                    }
                />
                <CircleButton
                    bottomLabel="$1000 - $10,000"
                    isActive={state.isTotalCostToggleActive}
                    circleLabel={state.counts[ServiceQuoteQuickFilterCount.TotalCostOneToTenThousand]}
                    {...(state.counts[ServiceQuoteQuickFilterCount.TotalCostOneToTenThousand] > 0 ? { color: Color.Yellow } : {})}
                    onClick={() =>
                        viewModel.toggleQuickFilter(ServiceQuoteQuickFilterCount.TotalCostOneToTenThousand, state.isTotalCostToggleActive)
                    }
                />
                <CircleButton
                    bottomLabel="$10,000 - $25,000"
                    isActive={state.isTotalCostToggleActive}
                    circleLabel={state.counts[ServiceQuoteQuickFilterCount.TotalCostTenToTwentyFiveThousand]}
                    {...(state.counts[ServiceQuoteQuickFilterCount.TotalCostTenToTwentyFiveThousand] > 0 ? { color: Color.Orange } : {})}
                    onClick={() =>
                        viewModel.toggleQuickFilter(ServiceQuoteQuickFilterCount.TotalCostTenToTwentyFiveThousand, state.isTotalCostToggleActive)
                    }
                />
                <CircleButton
                    bottomLabel="Over $25,000"
                    isActive={state.isTotalCostToggleActive}
                    circleLabel={state.counts[ServiceQuoteQuickFilterCount.TotalCostOverTentyFiveThousand]}
                    {...(state.counts[ServiceQuoteQuickFilterCount.TotalCostOverTentyFiveThousand] > 0 ? { color: Color.Red } : {})}
                    onClick={() =>
                        viewModel.toggleQuickFilter(ServiceQuoteQuickFilterCount.TotalCostOverTentyFiveThousand, state.isTotalCostToggleActive)
                    }
                />
                
                
                    </div>
            </div>
                    </div>
                </div>
            {!isMobile ? (
                <ServiceQuoteStatusFilter isMobile={false} />
            ) : (
                <>
                    <div
                        className="service-quotes-quick-filters-mobile-filter-button"
                        onClick={() => viewModel.showMobileFilterModal(true)}
                    >
                        Filters
                    </div>
                    <Modal
                        isVisible={state.isMobileFilterModalOpen}
                        title={"Filters"}
                        closeOnExternalClick={true}
                        onClose={() => viewModel.showMobileFilterModal(false)}
                        buttons={[
                            {
                                isEnabled: viewModel.isSearchFilterValid(),
                                onClick: () => {
                                    viewModel.applySearchFilter()
                                    viewModel.showMobileFilterModal(false)
                                },
                                label: "Done",
                            },
                        ]}
                    >
                        <ModalContent>
                            <Grid columns={1}>
                                <ServiceQuoteStatusFilter isMobile={true} />
                                <FormField label={ServiceQuoteFilterDefinitions[ServiceQuoteFilter.AssignedTo].categoryLabel}>
                                    <MultiSelect
                                        className="assigned-to-filter"
                                        options={state.mechanics.map((value) => ({
                                            key: value.id,
                                            value: value,
                                            label: labelForContact(value),
                                            isChecked: filterContainsValue(state.assignedToFilter, [value.id]),
                                        }))}
                                        onSelectionChanged={(selected) =>
                                            viewModel.updateAssignedToFilter(
                                                selected.map((it) => ({ value: it.id, label: labelForContact(it) }))
                                            )
                                        }
                                    />
                                </FormField>
                                <FormField label="Asset ID, VIN, or Serial*" direction={FormFieldDirection.Column}>
                                    <div style={{ display: "flex", width: "100%", gap: "8px" }}>
                                        <TextInput
                                            onChange={(value) => viewModel.updateSearchFilter([{ value }])}
                                            type={TextInputType.Search}
                                            value={textFilterValue(state.searchFilter)}
                                            style={{ flex: "1 1 auto" }}
                                            pattern="[a-zA-Z0-9 ]*"
                                            inputMode="text"
                                        />
                                        <Button label="Search" onClick={() => viewModel.applySearchFilter()} />
                                    </div>
                                    {!viewModel.isSearchFilterValid() && (
                                        <div className="filter-overlay-search-error">
                                            Search must contain at least 4 characters
                                        </div>
                                    )}
                                </FormField>
                            </Grid>
                        </ModalContent>
                    </Modal>
                </>
            )}
            <LoadingIndicator isLoading={state.isLoading} backgroundColor={"#191e27bf"} loaderColor="#FFFFFF" />
        </QuickFilters>
    )
}

function ServiceQuoteStatusFilter({ isMobile }: { isMobile: boolean }): JSX.Element {
    const [state, viewModel] = useBlocCoordinator(() => DI.serviceQuotesQuickFiltersViewModel())
    const isInIframe = window !== window.parent;
    return (
        <FormField direction={FormFieldDirection.Column} label={isMobile ? "Quick Filters" : ""}>
            <MultiSelect
                className={`${isMobile || isInIframe ? 'quick-filter-dropdown' : ''}`}
                theme={isMobile ? MultiSelectTheme.Normal : MultiSelectTheme.Dark}
                options={[
                    {
                        isChecked: state.isDateReceivedToggleActive,
                        label: "Days Open < 7",
                        value: ServiceQuoteQuickFilterCount.DaysOpenSubSeven,
                    },
                    {
                        isChecked: state.isDateReceivedToggleActive,
                        label: "Days Open 7-30",
                        value: ServiceQuoteQuickFilterCount.DaysOpenSevenToThirty,
                    },
                    {
                        isChecked: state.isDateReceivedToggleActive,
                        label: "Days open 30-90",
                        value: ServiceQuoteQuickFilterCount.DaysOpenThirtyToNinety,
                    },
                    {
                        isChecked: state.isDateReceivedToggleActive,
                        label: "Days Open > 90",
                        value: ServiceQuoteQuickFilterCount.DaysOpenOverNinety,
                    },
                    {
                        isChecked: state.isTotalCostToggleActive,
                        label: "Total Cost Sub $1000",
                        value: ServiceQuoteQuickFilterCount.TotalCostSubOneThousand,
                    },
                    {
                        isChecked: state.isTotalCostToggleActive,
                        label: "Total Cost $1000-$10,000",
                        value: ServiceQuoteQuickFilterCount.TotalCostOneToTenThousand,
                    },
                    {
                        isChecked: state.isTotalCostToggleActive,
                        label: "Total Cost $10,000-$25,000",
                        value: ServiceQuoteQuickFilterCount.TotalCostTenToTwentyFiveThousand,
                    },
                    {
                        isChecked: state.isTotalCostToggleActive,
                        label: "Total Cost > $25,000",
                        value: ServiceQuoteQuickFilterCount.TotalCostOverTentyFiveThousand,
                    },
                ]}
                onSelectionChanged={(selection) =>
                    viewModel.updateQuickFilterSelection(selection.map((count) => count))
                }
            />
        </FormField>
    )
}
