import { safeParseFloat } from "./TypeUtil"

export class Strings {
    private static moneyFormatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
    })

    static formatMoney(value: string | number): string {
        return this.moneyFormatter.format(safeParseFloat(value)).substr(1)
    }
}
