import { useBlocCoordinator } from "@lib/bloc/hooks"
import { compare, SortOrder } from "@lib/Comparable"
import { Link } from "@lib/router/components"
import { CompanyAlias } from "@model/company/CompanyAlias"
import { labelForContact } from "@model/contacts/Contact"
import { booleanFilterValue, filterContainsValue, textFilterValue } from "@model/filters/Filter"
import { labelForServiceQuoteStatus, numberForServiceQuoteStatus, ServiceQuoteFilter, ServiceQuoteStatus } from "@model/serviceQuotes/ServiceQuote"
import { ServiceQuoteFilterDefinitions } from "@model/serviceQuotes/ServiceQuoteFilterDefinitions"
import { labelForServiceCode, labelForServiceSchedule } from "@model/serviceRequests/ServiceRequest"
import { Threshold, ThresholdGroup, ThresholdType, ThresholdUnit } from "@model/Threshold"
import { labelForUrgency, Urgency } from "@model/Urgency"
import {
    labelForWorkOrderStatus,
    numberForWorkOrderStatus,
    WorkOrderFilter,
    WorkOrderStatus,
} from "@model/workOrders/WorkOrder"
import { WorkOrderFilterDefinitions } from "@model/workOrders/WorkOrderFilterDefinitions"
import { labelForWorkRequestType, WorkRequestType } from "@model/workRequests/WorkRequest"
import { FilterButton } from "@ui/common/buttons/FilterButton"
import { EditFilterPresetModal } from "@ui/common/filters/EditFilterPresetModal"
import { FilterOverlay, FilterOverlayGroup, FilterOverlayGroupColumn } from "@ui/common/filters/FilterOverlay"
import { FilterOverlayPresetsGroup } from "@ui/common/filters/FilterOverlayPresetsGroup"
import { ManageFilterPresetsModal } from "@ui/common/filters/ManageFilterPresetsModal"
import { CheckBox } from "@ui/common/form/CheckBox"
import { FormField } from "@ui/common/form/FormField"
import { MultiSelect } from "@ui/common/form/MultiSelect"
import { Select } from "@ui/common/form/Select"
import { TextInput, TextInputType } from "@ui/common/form/TextInput"
import { DI } from "@ui/DI"
import { Routes } from "@lib/Routes"
import React from "react"

export function ServiceQuotesFilterOverlay(): JSX.Element {
    const [state, viewModel] = useBlocCoordinator(() => DI.serviceQuotesFilterOverlayViewModel())
    const tableBloc = DI.serviceQuotesTableBloc()

    return (
        <>
            <FilterOverlay
                isOverlayVisible={state.isOverlayVisible}
                className="filter-overlay--service-quotes"
                hasSearch={true}
                onCancel={() => {
                    viewModel.updateFilters(tableBloc.state.filters)
                    viewModel.toggleOverlayVisibility()
                }}
            >
                <FilterOverlayGroup label="Asset Assignment" columns={1}>
                    <FilterOverlayGroupColumn>
                        {(state.subCompanies.length > 0 || state.parentCompany) && (
                            <FormField label={state.aliases[CompanyAlias.SubCompany]}>
                                <MultiSelect
                                    options={[state.parentCompany, ...state.subCompanies].map((it) => ({
                                        label: it.name,
                                        value: it,
                                        key: it.id,
                                        isChecked: filterContainsValue(state.filters[ServiceQuoteFilter.SubCompany], [
                                            it.id,
                                        ]),
                                    }))}
                                    onSelectionChanged={(selected) =>
                                        viewModel.updateFilter(
                                            ServiceQuoteFilter.SubCompany,
                                            selected.map((it) => ({ value: it.id, label: it.name }))
                                        )
                                    }
                                />
                            </FormField>
                        )}
                        {state.districts.length > 0 && (
                            <FormField label={state.aliases[CompanyAlias.District]}>
                                <MultiSelect
                                    options={state.districts.map((it) => ({
                                        label: it.name,
                                        value: it,
                                        key: it.id,
                                        isChecked: filterContainsValue(state.filters[WorkOrderFilter.District], [
                                            it.id,
                                        ]),
                                    }))}
                                    onSelectionChanged={(selected) =>
                                        viewModel.updateFilter(
                                            ServiceQuoteFilter.District,
                                            selected.map((it) => ({ value: it.id, label: it.name }))
                                        )
                                    }
                                />
                            </FormField>
                        )}
                        {state.subDistricts.length > 0 && (
                            <FormField label={state.aliases[CompanyAlias.SubDistrict]}>
                                <MultiSelect
                                    options={state.subDistricts.map((it) => ({
                                        label: it.name,
                                        value: it,
                                        key: it.id,
                                        isChecked: filterContainsValue(state.filters[ServiceQuoteFilter.SubDistrict], [
                                            it.id,
                                        ]),
                                    }))}
                                    onSelectionChanged={(selected) =>
                                        viewModel.updateFilter(
                                            ServiceQuoteFilter.SubDistrict,
                                            selected.map((it) => ({ value: it.id, label: it.name }))
                                        )
                                    }
                                />
                            </FormField>
                        )}
                        {state.units.length > 0 && (
                            <FormField label={state.aliases[CompanyAlias.Unit]}>
                                <MultiSelect
                                    options={state.units.map((it) => ({
                                        label: it.name,
                                        value: it,
                                        key: it.id,
                                        isChecked: filterContainsValue(state.filters[ServiceQuoteFilter.Unit], [it.id]),
                                    }))}
                                    onSelectionChanged={(selected) =>
                                        viewModel.updateFilter(
                                            ServiceQuoteFilter.Unit,
                                            selected.map((it) => ({ value: it.id, label: it.name }))
                                        )
                                    }
                                />
                            </FormField>
                        )}
                        {state.groups.length > 0 && (
                            <FormField label={state.aliases[CompanyAlias.Group]}>
                                <MultiSelect
                                    options={state.groups.map((it) => ({
                                        label: it.name,
                                        value: it,
                                        key: it.id,
                                        isChecked: filterContainsValue(state.filters[ServiceQuoteFilter.Group], [it.id]),
                                    }))}
                                    onSelectionChanged={(selected) =>
                                        viewModel.updateFilter(
                                            ServiceQuoteFilter.Group,
                                            selected.map((it) => ({ value: it.id, label: it.name }))
                                        )
                                    }
                                />
                            </FormField>
                        )}
                        {state.sites.length > 0 && (
                            <FormField label={WorkOrderFilterDefinitions[ServiceQuoteFilter.Site].categoryLabel}>
                                <MultiSelect
                                    options={state.sites.map((it) => ({
                                        label: it.name,
                                        value: it,
                                        key: it.id,
                                        isChecked: filterContainsValue(state.filters[ServiceQuoteFilter.Site], [it.id]),
                                    }))}
                                    onSelectionChanged={(selected) =>
                                        viewModel.updateFilter(
                                            ServiceQuoteFilter.Site,
                                            selected.map((it) => ({ value: it.id, label: it.name }))
                                        )
                                    }
                                />
                            </FormField>
                        )}
                    </FilterOverlayGroupColumn>
                </FilterOverlayGroup>
                <FilterOverlayGroup label="Asset Properties" columns={1}>
                    <FilterOverlayGroupColumn>
                        <FormField label={ServiceQuoteFilterDefinitions[ServiceQuoteFilter.AssetType].categoryLabel}>
                            <MultiSelect
                                options={state.types.map((it) => ({
                                    label: it.name,
                                    value: it,
                                    key: it.id,
                                    isChecked: filterContainsValue(state.filters[ServiceQuoteFilter.AssetType], [it.id]),
                                }))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        ServiceQuoteFilter.AssetType,
                                        selected.map((it) => ({ value: it.id, label: it.name }))
                                    )
                                }
                            />
                        </FormField>
                        <FormField label={ServiceQuoteFilterDefinitions[ServiceQuoteFilter.Category].categoryLabel}>
                            <MultiSelect
                                options={state.categories.map((it) => ({
                                    label: it.name,
                                    value: it,
                                    key: it.id,
                                    isChecked: filterContainsValue(state.filters[ServiceQuoteFilter.Category], [it.id]),
                                }))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        ServiceQuoteFilter.Category,
                                        selected.map((it) => ({ value: it.id, label: it.name }))
                                    )
                                }
                            />
                        </FormField>
                        <FormField label={ServiceQuoteFilterDefinitions[ServiceQuoteFilter.Class].categoryLabel}>
                            <MultiSelect
                                options={state.classes.map((it) => ({
                                    label: it.name,
                                    value: it,
                                    key: it.id,
                                    isChecked: filterContainsValue(state.filters[ServiceQuoteFilter.Class], [it.id]),
                                }))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        ServiceQuoteFilter.Class,
                                        selected.map((it) => ({ value: it.id, label: it.name }))
                                    )
                                }
                            />
                        </FormField>
                        <FormField label={ServiceQuoteFilterDefinitions[ServiceQuoteFilter.Make].categoryLabel}>
                            <MultiSelect
                                options={state.makes.map((it) => ({
                                    label: it.name,
                                    value: it,
                                    key: it.id,
                                    isChecked: filterContainsValue(state.filters[ServiceQuoteFilter.Make], [it.id]),
                                }))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        ServiceQuoteFilter.Make,
                                        selected.map((it) => ({ value: it.id, label: it.name }))
                                    )
                                }
                            />
                        </FormField>
                        <FormField label={ServiceQuoteFilterDefinitions[ServiceQuoteFilter.Model].categoryLabel}>
                            <MultiSelect
                                options={state.models.map((it) => ({
                                    label: it.name,
                                    value: it,
                                    key: it.id,
                                    isChecked: filterContainsValue(state.filters[ServiceQuoteFilter.Model], [it.id]),
                                }))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        ServiceQuoteFilter.Model,
                                        selected.map((it) => ({ value: it.id, label: it.name }))
                                    )
                                }
                            />
                        </FormField>
                    </FilterOverlayGroupColumn>
                </FilterOverlayGroup>
                <FilterOverlayGroup label="Service Quote" columns={1}>
                    <FilterOverlayGroupColumn>
                    <FormField label={ServiceQuoteFilterDefinitions[ServiceQuoteFilter.VendorID].categoryLabel}>
                            <MultiSelect
                                options={state.vendor.map((it) => ({
                                    key: it.id,
                                    value: it,
                                    label: it.VendorName,
                                    isChecked: filterContainsValue(state.filters[ServiceQuoteFilter.VendorID], [it.id]),
                                }))}
                                onSelectionChanged={(selected) => {
                                    viewModel.updateFilter(
                                        ServiceQuoteFilter.VendorID,
                                        selected.map((it) => ({ value: it.id, label: it.VendorName }))
                                    )
                                }}
                            />
                        </FormField>
                        <FormField label={ServiceQuoteFilterDefinitions[ServiceQuoteFilter.AssignedTo].categoryLabel}>
                            <MultiSelect
                                options={state.mechanics.map((value) => ({
                                    key: value.id,
                                    value: value,
                                    label: labelForContact(value),
                                    isChecked: filterContainsValue(state.filters[ServiceQuoteFilter.AssignedTo], [
                                        value.id,
                                    ]),
                                }))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        ServiceQuoteFilter.AssignedTo,
                                        selected.map((it) => ({ value: it.id, label: labelForContact(it) }))
                                    )
                                }
                            />
                        </FormField>
                        <FormField label={ServiceQuoteFilterDefinitions[ServiceQuoteFilter.Status].categoryLabel}>
                            <MultiSelect
                                options={Object.values(ServiceQuoteStatus)
                                    
                                    .map((it) => ({
                                        label: labelForServiceQuoteStatus(it),
                                        value: it,
                                        isChecked: filterContainsValue(state.filters[ServiceQuoteFilter.Status], [
                                            numberForServiceQuoteStatus(it),
                                        ]),
                                    }))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        ServiceQuoteFilter.Status,
                                        selected.map((it) => ({
                                            value: numberForServiceQuoteStatus(it),
                                            label: labelForServiceQuoteStatus(it),
                                        }))
                                    )
                                }
                            />
                        </FormField>

                    </FilterOverlayGroupColumn>
                </FilterOverlayGroup>
                <FilterOverlayPresetsGroup
                    presets={state.filterPresets}
                    searchFilter={ServiceQuoteFilter.Search}
                    searchLabel={"Asset Search"}
                    secondarySearchLabel={"Work Order Search"}
                    inputType={TextInputType.Text}
                    viewModel={viewModel}
                    overlayState={state}
                />
            </FilterOverlay>
            <EditFilterPresetModal
                isVisible={state.isCreatePresetVisible}
                unavailableNames={state.filterPresets.map((it) => it.name)}
                onCancel={() => viewModel.hideCreatePreset()}
                onSave={(form) => viewModel.createPreset(form)}
                isSaving={state.isSaving}
            />
            <ManageFilterPresetsModal
                isVisible={state.isManagePresetsVisible}
                presets={state.filterPresets}
                onCancel={() => viewModel.hideManagePresets()}
                onPresetDelete={(preset) => viewModel.deletePreset(preset)}
                onPresetEdit={(preset, form) => viewModel.updatePresetForm(preset, form)}
                isSaving={state.isSaving}
            />
        </>
    )
}

function labelForThresholdGroup(group: ThresholdGroup): React.ReactNode {
    return (
        <>
            {labelForThresholdType(group.hours)} Hr | {labelForThresholdType(group.miles)} Mi |{" "}
            {labelForThresholdType(group.days)} Day
        </>
    )
}

function labelForThresholdType(threshold: Threshold): string {
    if (threshold.value === null) return "N/A"
    return `${threshold.value}${threshold.unit === ThresholdUnit.Percent ? "%" : ""}`
}

export function ServiceQuotesFilterButton(): JSX.Element {
    const [state, viewModel] = useBlocCoordinator(() => DI.serviceQuotesFilterOverlayViewModel())

    return (
        <FilterButton
            onClick={() => viewModel.toggleOverlayVisibility()}
            isFilterOverlayVisible={state.isOverlayVisible}
        />
    )
}
