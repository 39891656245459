import { useBlocCoordinator } from "@lib/bloc/hooks"
import {  safeParseInt  } from "@lib/TypeUtil"
import { labelForContact } from "@model/contacts/Contact"
import { RequestRevisionServiceQuoteForm, ServiceQuote } from "@model/serviceQuotes/ServiceQuote"
import { User } from "@model/user/User"
import { Button, ButtonType } from "@ui/common/buttons/Button"
import { FormDivider, FormDividerMargin } from "@ui/common/form/FormDivider"
import { FormField } from "@ui/common/form/FormField"
import { MultiSelect } from "@ui/common/form/MultiSelect"
import { TextArea } from "@ui/common/form/TextArea"
import { Grid, GridCont, GridGap } from "@ui/common/grids/Grid"
import {
    Modal,
    ModalBody,
    ModalButtons,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalSize,
} from "@ui/common/Modal"
import { DI } from "@ui/DI"
import { xor } from "lodash"
import React, { useEffect } from "react"



export type RequestRevisionServiceQuoteModalProps = {
    onRequestRevisionClicked: (RequestRevisionServiceQuoteForm: RequestRevisionServiceQuoteForm) => void
    onCancelClicked: () => void
    serviceQuote: ServiceQuote | null
    isVisible: boolean
}

export function RequestRevisionServiceQuoteModal(props: RequestRevisionServiceQuoteModalProps): JSX.Element {
    return (
        <Modal isVisible={props.isVisible} useContentTag={true} onClose={props.onCancelClicked} size={ModalSize.Large}>
            <RequestRevisionServiceQuoteModalContent {...props} />
        </Modal>
    )
}

function RequestRevisionServiceQuoteModalContent(props: RequestRevisionServiceQuoteModalProps): JSX.Element {
    const [state, viewModel] = useBlocCoordinator(() => DI.requestRevisionServiceQuoteViewModel())

    useEffect(() => {
        
    }, [])

    return (
        <ModalContent>
            <ModalHeader>
                <ModalHeaderTitle>{`Service Quote - ${props.serviceQuote?.number} (${props.serviceQuote?.vendor?.VendorName})`}  </ModalHeaderTitle>
            </ModalHeader>
            <ModalBody>
            <Grid columns={1} gap={GridGap.Small}>
                    <GridCont>
                        <Grid columns={1}>  
                            <FormField label="Instructions to Vendor regarding quote revision(s) requested.*">
                                <TextArea
                                    maxLength={1000}
                                    value={state.vendorMessage}
                                    onChange={(value) => viewModel.vendorMessageChanged(value)}
                                />
                            </FormField>
                        </Grid>
                        <Grid columns={2}>
                            <FormField label="Notify Contacts">
                                <MultiSelect
                                    options={state.contacts.map((contact) => ({
                                        key: contact.id,
                                        value: `${contact.id}`,
                                        label: labelForContact(contact),
                                        isChecked: state.notifyContacts.includes(contact.id),
                                        checkAll: false,
                                    }))}
                                    onSelectionChanged={(selection) =>
                                        viewModel.notifyContactsChanged(selection.map((contactId) => safeParseInt(contactId)))
                                    }
                                />
                            </FormField>
                        </Grid>
                    </GridCont>
                </Grid>
            </ModalBody>
            <ModalButtons>
                <Button label="Cancel" type={ButtonType.Text} onClick={props.onCancelClicked} />
                <Button
                    label={"Request Revision"}
                    type={ButtonType.Contained}
                    onClick={() => props.onRequestRevisionClicked(state)}
                    isEnabled={state.isFormValid}
                />
            </ModalButtons>
        </ModalContent>
    )
}