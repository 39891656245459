import { BlocCoordinator } from "@lib/bloc/BlocCoordinator"
import { withRouter } from "@lib/router/router"
import { Routes } from "@lib/Routes"
import { CompanyAlias } from "@model/company/CompanyAlias"
import { FilterUpdateType } from "@model/filters/Filter"
import { UserWorkRequestsTableColumn, WorkRequestFilter } from "@model/workRequests/WorkRequest"
import { CompanyBloc, CompanyState } from "@state/company/CompanyBloc"
import { SortableTableBlocEffect } from "@state/SortableTableBloc"
import { UserBloc, UserState } from "@state/user/UserBloc"
import { UserPreferencesBloc, UserPreferencesState } from "@state/UserPreferencesBloc"
import { UserWorkRequestsBloc, UserWorkRequestTableState } from "@state/workRequests/UserWorkRequestsBloc"

type Dependencies = [UserPreferencesState, UserWorkRequestTableState, CompanyState, UserState]

type StateSelection = {
    aliases: Record<CompanyAlias, string>
    columns: UserWorkRequestsTableColumn[]
    availableColumns: UserWorkRequestsTableColumn[]
    isTableDataLoading: boolean
} & UserWorkRequestTableState

export class UserWorkRequestsViewModel extends BlocCoordinator<Dependencies, StateSelection> {
    constructor(
        private userWorkRequestsBloc: UserWorkRequestsBloc,
        private userPreferencesBloc: UserPreferencesBloc,
        private companyBloc: CompanyBloc,
        private userBlock: UserBloc
    ) {
        super([userPreferencesBloc, userWorkRequestsBloc, companyBloc, userBlock])
    }

    transform = ([userPreferences, tableState, companyState, userState]: Dependencies): StateSelection => ({
        aliases: companyState.aliases,
        columns: userPreferences.tableSettings.userWorkRequestsTableColumns.columns.filter(
            this.availableColumnFilter(companyState)
        ),
        availableColumns: Object.values(UserWorkRequestsTableColumn).filter(this.availableColumnFilter(companyState)),
        isTableDataLoading: tableState.effectStatus[SortableTableBlocEffect.Fetch].isBusy(),
        ...tableState,
    })

    async onMounted(userId: number) {
        const updatedFilter = this.userWorkRequestsBloc.createUpdatedFilter(
            WorkRequestFilter.RequestedBy,
            [{ value: userId, label: "" }],
            FilterUpdateType.Set
        )
        this.userWorkRequestsBloc.applyFiltersAndFetch({ ...this.userWorkRequestsBloc.state.filters, ...updatedFilter })
    }

    getUserId = () => this.userBlock.state.user.id.toString()

    viewWorkRequestsClicked = withRouter((router) => () => router.navigate(Routes.WorkRequests()))

    private availableColumnFilter = (state: CompanyState) => (it: UserWorkRequestsTableColumn) => {
        switch (it) {
            case UserWorkRequestsTableColumn.Asset:
                return state.subCompanies.length > 0
            case UserWorkRequestsTableColumn.Name:
                return state.districts.length > 0
            default:
                return true
        }
    }

    onNextPageClicked = () => this.userWorkRequestsBloc.fetchNextPage()
    onPreviousPageClicked = () => this.userWorkRequestsBloc.fetchPreviousPage()
    onPageClicked = (page: number) => this.userWorkRequestsBloc.fetchPage(page)
    onColumnSortClicked = (columnIndex: number) =>
        this.userWorkRequestsBloc.toggleSort(
            this.userPreferencesBloc.state.tableSettings.userWorkRequestsTableColumns.columns[columnIndex]
        )
}
