import { useLastNonNull } from "@lib/Hooks"
import { labelForWorkRequest, WorkRequest } from "@model/workRequests/WorkRequest"
import { Button, ButtonType } from "@ui/common/buttons/Button"
import { FormField } from "@ui/common/form/FormField"
import { TextArea } from "@ui/common/form/TextArea"
import { Grid } from "@ui/common/grids/Grid"
import { Modal, ModalBody, ModalButtons, ModalContent, ModalHeader, ModalHeaderTitle } from "@ui/common/Modal"
import React from "react"

export function WorkNotPerformedWorkRequestModal({
    onCancel,
    onConfirm,
    onReasonChanged,
    ...props
}: {
    data: {
        request: WorkRequest
        reason: string
    } | null
    title: string
    onReasonChanged: (workRequest: WorkRequest, value: string) => void
    onCancel: () => void
    onConfirm: () => void
}): JSX.Element {
    const data = useLastNonNull(props.data)
    const title = props.title
    const isVisible = props.data !== null
    if (data === null) return <Modal isVisible={isVisible} />
    return (
        <Modal
            isVisible={isVisible}
            useContentTag={true}
            onClose={() => {
                onCancel()
            }}
        >
            <ModalContent>
                <ModalHeader>
                    <ModalHeaderTitle>{title}</ModalHeaderTitle>
                </ModalHeader>
                <ModalBody>
                    <Grid columns={1}>
                        <div>Add reason work was declined.</div>
                        <i>{labelForWorkRequest(data.request)}</i>
                        <FormField label={"Reason*"}>
                            <TextArea
                                onChange={(value) => {
                                    data.reason = value
                                    onReasonChanged(data.request, value)
                                }}
                                value={data.reason}
                                maxLength={250}
                            />
                        </FormField>
                    </Grid>
                </ModalBody>
                <ModalButtons>
                    <Button
                        label="Cancel"
                        type={ButtonType.Text}
                        onClick={() => {
                            onCancel()
                        }}
                    />
                    <Button
                        label="Save"
                        isEnabled={data.reason.trim() !== ""}
                        onClick={() => {
                            onConfirm()
                        }}
                    />
                </ModalButtons>
            </ModalContent>
        </Modal>
    )
}
