export type PriorAssetUsage = {
    assetUsageId: number
    assetId: number
    eventDateTime: Date
    utcOffset: number
    assetReading: PriorAssetUsageReading
}

export type PriorAssetUsageReading = {
    assetReadingId: number
    odometer: number
    hourMeter: number
}

export function newPriorAssetUsageReading(): PriorAssetUsageReading {
    return {
        assetReadingId: 0,
        odometer: 0,
        hourMeter: 0,
    }
}

export function newPriorAssetUsage(): PriorAssetUsage {
    return {
        assetId: 0,
        assetUsageId: 0,
        eventDateTime: new Date(),
        utcOffset: 0,
        assetReading: newPriorAssetUsageReading(),
    }
}
