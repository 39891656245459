import { Outcome } from "@ethossoftworks/outcome"
import { Bloc } from "@lib/bloc/Bloc"
import { SortOrder } from "@lib/Comparable"
import { arrayToObject } from "@lib/TypeUtil"
import { AsyncStatus } from "@model/AsyncStatus"
import {
    Filter,
    FilterDef,
    FilterUpdateType,
    FilterValue,
    isEmptyFilterValue,
    newDefaultFiltersObj,
    updateFilter,
} from "@model/filters/Filter"
import { PagedResponse, Pagination } from "@model/Pagination"
import { IFilterOverlayMixinBloc } from "./filters/IFilterOverlayMixinBloc"

export type SortableTableState<C, D, F extends string, S> = {
    readonly effectStatus: Record<SortableTableBlocEffect, AsyncStatus>
    readonly pagination: Pagination
    readonly filters: Record<F, Filter<F>>
    readonly tableData: D[]
    readonly sort?: {
        readonly column: C
        readonly order: SortOrder
    }
    readonly viewAllColumnsSelectedRow: D | null

    readonly isManageColumnsModalVisible: boolean
    readonly manageColumnsModalSelectedColumns: C[]
    readonly isDefaultColumnsChecked: boolean
} & S

export enum SortableTableBlocEffect {
    Fetch,
    FetchUserWorkRequests,
}

export abstract class SortableTableBloc<C, D, F extends string, S extends {}>
    extends Bloc<SortableTableState<C, D, F, S>>
    implements IFilterOverlayMixinBloc<F>
{
    constructor(
        private options: {
            filters: F[] // The enum of filters available
            filterDefinitions: Record<F, FilterDef<F>> // All filter definitions
            defaultManagedColumns: C[] // Default columns for manage columns modal
            readOnlyManagedColumns: C[] // Readonly columns for manage columns modal
            dataFetcher: (
                filters?: Filter<F>[],
                sort?: { column: C; order: SortOrder },
                pagination?: Pagination
            ) => Promise<Outcome<PagedResponse<D[]>>> // Fetches data from external source
            persistStateOnDispose?: boolean
            initialExtraState: S
            defaultFiltersOverride?: (filters: Record<F, Filter<F>>) => Partial<Record<F, Filter<F>>>
        }
    ) {
        super(
            newSortableTableState(
                options.defaultManagedColumns,
                options.filters,
                options.filterDefinitions,
                options.initialExtraState,
                options.defaultFiltersOverride
            ),
            {
                persistStateOnDispose: options.persistStateOnDispose,
            }
        )
    }

    override onDispose() {
        this.hideViewAllColumnsModal()
    }

    manageColumnsModalVisibilityChanged = (isVisible: boolean) =>
        this.update({ isManageColumnsModalVisible: isVisible } as Partial<SortableTableState<C, D, F, S>>)

    fetchData = (filters?: Record<F, Filter<F>>, sort?: { column: C; order: SortOrder }, pagination?: Pagination) =>
        this.effect({
            id: SortableTableBlocEffect.Fetch,
            block: async (job) => {
                this.effectStatusChanged(SortableTableBlocEffect.Fetch, AsyncStatus.busy())
                const filtersArray = Object.values(filters ?? []).filter((filter) => !isEmptyFilterValue(filter))

                const dataOutcome = await job.pause(this.options.dataFetcher(filtersArray, sort, pagination))

                if (!dataOutcome.isOk()) {
                    this.effectStatusChanged(SortableTableBlocEffect.Fetch, AsyncStatus.error(dataOutcome))
                    return dataOutcome
                }

                this.effectStatusChanged(SortableTableBlocEffect.Fetch, AsyncStatus.idle())
                this.update({
                    tableData: dataOutcome.value.data,
                    pagination: dataOutcome.value.pagination,
                    filters: filters ?? newDefaultFiltersObj(this.options.filters, this.options.filterDefinitions),
                    sort,
                } as Partial<SortableTableState<C, D, F, S>>)
                return dataOutcome
            },
        })

    fetchPage = (page: number) => {
        if (page >= this.state.pagination.pageCount || page < 0) return
        this.fetchData(this.state.filters, this.state.sort, {
            ...this.state.pagination,
            currentPage: page,
        })
    }

    fetchNextPage = () => {
        if (this.state.pagination.currentPage + 1 >= this.state.pagination.pageCount) return
        this.fetchData(this.state.filters, this.state.sort, {
            ...this.state.pagination,
            currentPage: Math.min(this.state.pagination.currentPage + 1, this.state.pagination.pageCount - 1),
        })
    }

    fetchPreviousPage = () => {
        if (this.state.pagination.currentPage - 1 < 0) return
        this.fetchData(this.state.filters, this.state.sort, {
            ...this.state.pagination,
            currentPage: Math.max(this.state.pagination.currentPage - 1, 0),
        })
    }

    fetchAllPages = () =>
        this.fetchData(this.state.filters, this.state.sort, {
            ...this.state.pagination,
            currentPage: 0,
            rowsPerPage: this.state.pagination.totalRows,
        })

    resetViewAll = () =>
        this.fetchData(this.state.filters, this.state.sort, {
            ...this.state.pagination,
            currentPage: 0,
            rowsPerPage: 10,
        })

    toggleSort = (column: C) => {
        const order = (() => {
            if (!this.state.sort || this.state.sort.column !== column) return SortOrder.Asc

            switch (this.state.sort.order) {
                case SortOrder.Asc:
                    return SortOrder.Desc
                case SortOrder.Desc:
                    return SortOrder.Asc
                case SortOrder.None:
                    return SortOrder.Asc
            }
        })()

        this.fetchData(this.state.filters, { column, order }, this.state.pagination)
    }

    manageColumnsColumnsSelectionChanged = (columns: C[]) =>
        this.update({
            manageColumnsModalSelectedColumns: columns,
            isDefaultColumnsChecked: this.isDefaultColumnsChecked(columns),
        } as Partial<SortableTableState<C, D, F, S>>)

    manageColumnsColumnChanged = (column: C, isChecked: boolean) => {
        const updatedColumns = (() => {
            if (isChecked) {
                if (this.state.manageColumnsModalSelectedColumns.includes(column))
                    return this.state.manageColumnsModalSelectedColumns
                return [...this.state.manageColumnsModalSelectedColumns, column]
            } else {
                return this.state.manageColumnsModalSelectedColumns.filter((col) => col !== column)
            }
        })()

        return this.update({
            isDefaultColumnsChecked: this.isDefaultColumnsChecked(updatedColumns),
            manageColumnsModalSelectedColumns: updatedColumns,
        } as Partial<SortableTableState<C, D, F, S>>)
    }

    manageColumnsDefaultsToggled = () => {
        const isDefaultColumnsChecked = this.isDefaultColumnsChecked(this.state.manageColumnsModalSelectedColumns)
        const updateColumns = isDefaultColumnsChecked
            ? this.state.manageColumnsModalSelectedColumns.filter(
                  (col) =>
                      this.options.readOnlyManagedColumns.includes(col) ||
                      !this.options.defaultManagedColumns.includes(col)
              )
            : this.options.defaultManagedColumns

        return this.update({
            isDefaultColumnsChecked: this.isDefaultColumnsChecked(updateColumns),
            manageColumnsModalSelectedColumns: updateColumns,
        } as Partial<SortableTableState<C, D, F, S>>)
    }

    removeFilter = (type: F) =>
        this.fetchData(
            {
                ...this.state.filters,
                [type]: updateFilter(
                    this.state.filters[type],
                    this.state.filters[type].values,
                    FilterUpdateType.Remove
                ),
            },
            this.state.sort,
            this.state.pagination
        )

    applyFiltersAndFetch = async (filters: Record<F, Filter<F>> | null): Promise<void> => {
        await this.fetchData(filters ?? undefined, this.state.sort, {
            ...this.state.pagination,
            currentPage: 0,
        })
    }

    applyFilters = (filters: Record<F, Filter<F>>, sort?: { column: C; order: SortOrder }) =>
        this.update({
            filters,
            sort,
        } as Partial<SortableTableState<C, D, F, S>>)

    createUpdatedFilter = (
        filterType: F,
        value: FilterValue[],
        updateType: FilterUpdateType
    ): { [key in F]?: Filter<F> } => {
        return {
            [filterType]: updateFilter(this.state.filters[filterType], value, updateType),
        } as { [key in F]?: Filter<F> }
    }

    showViewAllColumnsModal = (row: D) =>
        this.update({ viewAllColumnsSelectedRow: row } as Partial<SortableTableState<C, D, F, S>>)

    hideViewAllColumnsModal = () =>
        this.update({ viewAllColumnsSelectedRow: null } as Partial<SortableTableState<C, D, F, S>>)

    protected effectStatusChanged = (effect: SortableTableBlocEffect, status: AsyncStatus) =>
        this.update({
            effectStatus: {
                ...this.state.effectStatus,
                [effect]: status,
            },
        } as Partial<SortableTableState<C, D, F, S>>)

    private isDefaultColumnsChecked = (state: C[]) => {
        if (state.length !== this.options.defaultManagedColumns.length) return false
        return state.every((col) => this.options.defaultManagedColumns.includes(col))
    }

    newDefaultFilters(): Record<F, Filter<F>> {
        const defaultFilters = newDefaultFiltersObj(this.options.filters, this.options.filterDefinitions)
        return { ...defaultFilters, ...this.options.defaultFiltersOverride?.(defaultFilters) }
    }
}

export function newSortableTableState<C, R, F extends string, S extends {}>(
    defaultColumns: C[],
    filters: F[],
    filterDefinitions: Record<F, FilterDef<F>>,
    extraState: S,
    defaultFilterOverride?: (filters: Record<F, Filter<F>>) => Partial<Record<F, Filter<F>>>
): SortableTableState<C, R, F, S> {
    const defaultFilters = newDefaultFiltersObj(filters, filterDefinitions)
    return {
        filters: { ...defaultFilters, ...defaultFilterOverride?.(defaultFilters) },
        effectStatus: arrayToObject(Object.values(SortableTableBlocEffect), (it) => [it, AsyncStatus.idle()]),
        tableData: [],
        sort: undefined,
        pagination: {
            currentPage: 0,
            rowsPerPage: 10,
            pageCount: 0,
            totalRows: 0,
        },
        isManageColumnsModalVisible: false,
        manageColumnsModalSelectedColumns: defaultColumns,
        isDefaultColumnsChecked: true,
        viewAllColumnsSelectedRow: null,
        ...extraState,
    }
}
