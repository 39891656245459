import { useBlocCoordinator } from "@lib/bloc/hooks"
import { SortOrder } from "@lib/Comparable"
import { useIsInIframe, useIsMobile, useLastNonNull } from "@lib/Hooks"
import { classNames } from "@lib/HtmlUtil"
import { Link } from "@lib/router/components/Link"
import { useRouteQuery, useRouter } from "@lib/router/hooks"
import { Strings } from "@lib/Strings"
import { Color } from "@model/Color"
import { CompanyAlias } from "@model/company/CompanyAlias"
import { Filter, textFilterValue } from "@model/filters/Filter"
import {
    SortableTableHeadColumn,
    TableColumn,
    TableColumnAlignment,
    TableColumnSize,
    TableRow,
} from "@model/tables/Table"
import { Threshold, ThresholdType, thresholdUnitForValue } from "@model/Threshold"
import { PermissionObject, PermissionType, User } from "@model/user/User"
import { labelForWorkOrderStatus, WorkOrderFilter, WorkOrderStatus } from "@model/workOrders/WorkOrder"
import { WorkOrderFilterDefinitions } from "@model/workOrders/WorkOrderFilterDefinitions"
import {
    WorkRequestFilter,
    WorkRequestsTableRow,
    WorkRequestTableColumn,
    WorkRequestTableRowPriority,
    WorkRequestType,
} from "@model/workRequests/WorkRequest"
import { WorkRequestsTableColumnDefinitions } from "@model/workRequests/WorkRequestsTableColumnDefinitions"
import { ButtonTheme } from "@ui/common/buttons/Button"
import { Popover, PopoverItem } from "@ui/common/buttons/Popover"
import { DataField } from "@ui/common/DataField"
import { Grid } from "@ui/common/grids/Grid"
import { ImageUrls } from "@ui/common/images/ImageUrls"
import { Modal, ModalBody, ModalContent, ModalHeader, ModalHeaderTitle, ModalSize } from "@ui/common/Modal"
import { ManageColumnsModal } from "@ui/common/tables/ManageColumnsModal"
import { SortableTable } from "@ui/common/tables/SortableTable"
import { Tag } from "@ui/common/Tag"
import { DI } from "@ui/DI"
import { ExternalRoutes, Routes } from "@lib/Routes"
import React, { useEffect } from "react"
import { AssetLocation } from "./AssetLocation"
import { PrintWorkRequestModal } from "./PrintWorkRequestModal"
import { WorkRequestsTableViewModel } from "./WorkRequestsTableViewModel"
import {
    newEmptyFiltersObj,
} from "@model/filters/Filter"

export function WorkRequestsTable(): JSX.Element {
    const [state, viewModel] = useBlocCoordinator(() => DI.workRequestsTableViewModel())
    
    const { filter } = useRouteQuery()
    let filters: Record<WorkRequestFilter, Filter<WorkRequestFilter>>

    if (filter) {
        filters = JSON.parse(filter)
    }

        
    useEffect(() => viewModel.onMounted(filters), [])

    const aliasedColumnLabels = createLabelsForAliasedColumns(state.aliases)
    const aliasedFilterLabels = createLabelsForAliasedFilters(state.aliases)
    const thresholdFilterValueOverrides = createThresholdFilterValueOverrides(state.filters)

    return (
        <>
            <SortableTable
                className="table--work-requests"
                head={createTableHead(state.columns, state.sort, aliasedColumnLabels)}
                data={createTableData(state.columns, state.tableData, viewModel)}
                emptyMessage="No Assets Found"
                actionBar={{
                    pagination: state.pagination,
                    filters: Object.values(state.filters).filter(
                        (it) =>
                            it.definition.type !== WorkRequestFilter.DaysUntilUnit &&
                            it.definition.type !== WorkRequestFilter.MilesUntilUnit &&
                            it.definition.type !== WorkRequestFilter.HoursUntilUnit
                    ),
                    filterLabelOverrides: aliasedFilterLabels,
                    filterValueOverrides: thresholdFilterValueOverrides,
                    onFilterRemoveClicked: (filter) => viewModel.onFilterRemoveClicked(filter),
                    onExportClicked: () => viewModel.onExportClicked(),
                    onResetFiltersClicked: () => viewModel.onResetFiltersClicked(),
                    onManageColumnsClicked: () => viewModel.onManageColumnsClicked(),
                    onNextPageClicked: () => viewModel.onNextPageClicked(),
                    onPreviousPageClicked: () => viewModel.onPreviousPageClicked(),
                    onViewAllClicked: () => viewModel.onViewAllPagesClicked(),
                    onResetViewAllClicked: () => viewModel.onResetViewAllClicked(),
                    onPageClicked: (page) => viewModel.onPageClicked(page),
                }}
                isLoading={state.isTableDataLoading}
                onColumnSortClicked={(column) => viewModel.onColumnSortClicked(column)}
            />
            <ManageColumnsModal
                columnDefinitions={WorkRequestsTableColumnDefinitions}
                availableColumns={state.availableColumns}
                selectedColumns={state.manageColumnsModalSelectedColumns}
                isDefaultColumnsChecked={state.isDefaultColumnsChecked}
                isVisible={state.isManageColumnsModalVisible}
                onApply={viewModel.onManageColumnsApply}
                onCancel={viewModel.onManageColumnsCancel}
                onColumnCheckChanged={viewModel.onManageColumnsColumnCheckChanged}
                onDefaultsToggled={viewModel.onManageColumnsDefaultsToggled}
                columnLabelOverrides={aliasedColumnLabels}
            />
            <ViewAllColumnsModal
                row={state.viewAllColumnsSelectedRow}
                viewModel={viewModel}
                availableColumns={state.availableColumns}
                columnLabelOverrides={aliasedColumnLabels}
            />
            <PrintWorkRequestModal
                isVisible={state.assetToPrint !== null}
                isLoading={state.assetToPrintIsLoading}
                onClose={() => viewModel.assetToPrintChanged(null)}
                onOptionSelected={(option) => viewModel.assetPrintOptionSelected(option)}
                companyName={state.pdfCompanyName}
                assetParts={state.pdfAssetParts}
                workRequests={state.pdfWorkRequests}
                asset={state.pdfAsset}
                selectedPrintOption={state.selectedPrintOption}
                companyLogoUrl={state.companyLogoUrl}
            />
        </>
    )
}

function ViewAllColumnsModal({
    row,
    viewModel,
    availableColumns,
    columnLabelOverrides,
}: {
    row: WorkRequestsTableRow | null
    viewModel: WorkRequestsTableViewModel
    availableColumns: WorkRequestTableColumn[]
    columnLabelOverrides?: Partial<Record<WorkRequestTableColumn, string>>
}): JSX.Element {
    const data = useLastNonNull(row)
    if (data === null) return <Modal isVisible={row !== null} size={ModalSize.Large} />

    return (
        <Modal
            isVisible={row !== null}
            useContentTag={true}
            onClose={() => viewModel.onViewAllColumnsModalClosed()}
            size={ModalSize.Large}
        >
            <ModalContent>
                <ModalHeader>
                    <ModalHeaderTitle>
                        {data.assetName} {data.tag && <Tag tag={data.tag} />}
                    </ModalHeaderTitle>
                </ModalHeader>
                <ModalBody>
                    <Grid columns={4}>
                        {availableColumns
                            .filter((it) => ![WorkRequestTableColumn.Tag, WorkRequestTableColumn.Asset].includes(it))
                            .map((it) => (
                                <DataField
                                    key={it}
                                    label={columnLabelOverrides?.[it] ?? WorkRequestsTableColumnDefinitions[it].label}
                                    value={createColumnContent(it, data, viewModel.hasWorkOrderViewPermission())}
                                />
                            ))}
                    </Grid>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

function createLabelsForAliasedColumns(
    aliases: Record<CompanyAlias, string>
): Partial<Record<WorkRequestTableColumn, string>> {
    return {
        [WorkRequestTableColumn.District]: aliases[CompanyAlias.District],
        [WorkRequestTableColumn.Group]: aliases[CompanyAlias.Group],
        [WorkRequestTableColumn.SubCompany]: aliases[CompanyAlias.SubCompany],
        [WorkRequestTableColumn.SubDistrict]: aliases[CompanyAlias.SubDistrict],
        [WorkRequestTableColumn.Unit]: aliases[CompanyAlias.Unit],
    }
}

function createLabelsForAliasedFilters(
    aliases: Record<CompanyAlias, string>
): Partial<Record<WorkRequestFilter, string>> {
    return {
        [WorkRequestFilter.District]: aliases[CompanyAlias.District],
        [WorkRequestFilter.Group]: aliases[CompanyAlias.Group],
        [WorkRequestFilter.SubCompany]: aliases[CompanyAlias.SubCompany],
        [WorkRequestFilter.SubDistrict]: aliases[CompanyAlias.SubDistrict],
        [WorkRequestFilter.Unit]: aliases[CompanyAlias.Unit],
    }
}

function createThresholdFilterValueOverrides(
    filters: Record<WorkRequestFilter, Filter<WorkRequestFilter>>
): Partial<Record<WorkRequestFilter, string>> {
    const unitForFilter = (type: ThresholdType, filter: Filter<any>): string =>
        Threshold.labelForUnit(type, thresholdUnitForValue(textFilterValue(filter)))

    return {
        [WorkRequestFilter.DaysUntil]: `${textFilterValue(filters[WorkRequestFilter.DaysUntil])} ${unitForFilter(
            ThresholdType.Days,
            filters[WorkRequestFilter.DaysUntilUnit]
        )}`,
        [WorkRequestFilter.HoursUntil]: `${textFilterValue(filters[WorkRequestFilter.HoursUntil])} ${unitForFilter(
            ThresholdType.Hours,
            filters[WorkRequestFilter.HoursUntilUnit]
        )}`,
        [WorkRequestFilter.MilesUntil]: `${textFilterValue(filters[WorkRequestFilter.MilesUntil])} ${unitForFilter(
            ThresholdType.Miles,
            filters[WorkRequestFilter.MilesUntilUnit]
        )}`,
    }
}

function createTableHead(
    columns: WorkRequestTableColumn[],
    sort?: { column: WorkRequestTableColumn; order: SortOrder },
    columnLabelOverrides?: Partial<Record<WorkRequestTableColumn, string>>
): SortableTableHeadColumn[] {
    return columns
        .map(
            (column): SortableTableHeadColumn => ({
                size: WorkRequestsTableColumnDefinitions[column].size,
                alignment: WorkRequestsTableColumnDefinitions[column].alignment,
                isSortable: WorkRequestsTableColumnDefinitions[column].isSortable,
                sortOrder: column === sort?.column ? sort.order : SortOrder.None,
                content: (
                    <div>{columnLabelOverrides?.[column] ?? WorkRequestsTableColumnDefinitions[column].label}</div>
                ),
            })
        )
        .concat({
            alignment: TableColumnAlignment.Center,
            size: TableColumnSize.MinContent,
            isSortable: false,
            sortOrder: SortOrder.None,
        })
}

function createTableData(
    columns: WorkRequestTableColumn[],
    assetWorkRequests: WorkRequestsTableRow[],
    viewModel: WorkRequestsTableViewModel
): TableRow[] {
    return assetWorkRequests.map((asset) => ({
        entryId: asset.assetId,
        columns: columns
            .map(
                (column): TableColumn => ({
                    size: WorkRequestsTableColumnDefinitions[column].size,
                    alignment: WorkRequestsTableColumnDefinitions[column].alignment,
                    content: createColumnContent(column, asset, viewModel.hasWorkOrderViewPermission()),
                    className: WorkRequestsTableColumnDefinitions[column].className,
                })
            )
            .concat([
                {
                    alignment: TableColumnAlignment.Center,
                    size: TableColumnSize.MinContent,
                    content: <MoreColumn row={asset} viewModel={viewModel} />,
                    className: "table-cell--more",
                },
            ]),
    }))
}

function createColumnContent(
    column: WorkRequestTableColumn,
    asset: WorkRequestsTableRow,
    workOrderViewPermission: boolean
): JSX.Element {
    switch (column) {
        case WorkRequestTableColumn.Tag:
            return <TagColumn asset={asset} />
        case WorkRequestTableColumn.Asset:
            return <AssetColumn asset={asset} />
        case WorkRequestTableColumn.Service:
            return <ServiceColumn asset={asset} />
        case WorkRequestTableColumn.PreventativeMaintenance:
            return <PreventativeColumn asset={asset} />
        case WorkRequestTableColumn.Inspection:
            return <InspectionColumn asset={asset} />
        case WorkRequestTableColumn.Total:
            return <TotalColumn asset={asset} />
        case WorkRequestTableColumn.Location:
            return <LocationColumn asset={asset} />
        case WorkRequestTableColumn.WorkOrder:
            return <WorkOrderColumn asset={asset} enableLink={workOrderViewPermission} />
        case WorkRequestTableColumn.HourMeter:
            return <HourMeterColumn asset={asset} />
        case WorkRequestTableColumn.Odometer:
            return <OdometerColumn asset={asset} />
        case WorkRequestTableColumn.DaysInactive:
            return <DaysInactiveColumn asset={asset} />
        case WorkRequestTableColumn.Class:
            return <ClassColumn asset={asset} />
        case WorkRequestTableColumn.SubCompany:
            return <SubCompanyColumn asset={asset} />
        case WorkRequestTableColumn.District:
            return <DistrictColumn asset={asset} />
        case WorkRequestTableColumn.SubDistrict:
            return <SubDistrictColumn asset={asset} />
        case WorkRequestTableColumn.Unit:
            return <UnitColumn asset={asset} />
        case WorkRequestTableColumn.Group:
            return <GroupColumn asset={asset} />
        case WorkRequestTableColumn.EstimatedLaborHours:
            return <EstimatedLaborHoursColumn asset={asset} />
        case WorkRequestTableColumn.EstimatedLaborCost:
            return <EstimatedLaborCostColumn asset={asset} />
        case WorkRequestTableColumn.EstimatedPartsCost:
            return <EstimatedPartsCostColumn asset={asset} />
        case WorkRequestTableColumn.EstimatedTotalCost:
            return <EstimatedTotalCostColumn asset={asset} />
        case WorkRequestTableColumn.City:
            return <CityColumn asset={asset} />
        case WorkRequestTableColumn.State:
            return <StateColumn asset={asset} />
    }
}

const HourMeterColumn = ({ asset }: { asset: WorkRequestsTableRow }) => <>{Strings.formatDecimal(asset.hourMeter)}</>
const OdometerColumn = ({ asset }: { asset: WorkRequestsTableRow }) => <>{Strings.formatInteger(asset.odometer)}</>
const DaysInactiveColumn = ({ asset }: { asset: WorkRequestsTableRow }) => (
    <>{asset.daysInactive != -1 ? Strings.formatInteger(asset.daysInactive) : "N/A"}</>
)
const ClassColumn = ({ asset }: { asset: WorkRequestsTableRow }) => <>{asset.class?.name}</>
const SubCompanyColumn = ({ asset }: { asset: WorkRequestsTableRow }) => (
    <>{asset.subCompany?.name != "" ? asset.subCompany?.name : asset.company?.name}</>
)
const DistrictColumn = ({ asset }: { asset: WorkRequestsTableRow }) => <>{asset.district?.name}</>
const SubDistrictColumn = ({ asset }: { asset: WorkRequestsTableRow }) => <>{asset.subDistrict?.name}</>
const UnitColumn = ({ asset }: { asset: WorkRequestsTableRow }) => <>{asset.unit?.name}</>
const GroupColumn = ({ asset }: { asset: WorkRequestsTableRow }) => <>{asset.group?.name}</>
const EstimatedLaborHoursColumn = ({ asset }: { asset: WorkRequestsTableRow }) => (
    <>{Strings.formatFloat(asset.estimatedLaborHours)}</>
)
const EstimatedLaborCostColumn = ({ asset }: { asset: WorkRequestsTableRow }) => (
    <>$ {Strings.formatMoney(asset.estimatedLaborCost)}</>
)
const EstimatedPartsCostColumn = ({ asset }: { asset: WorkRequestsTableRow }) => (
    <>$ {Strings.formatMoney(asset.estimatedPartsCost)}</>
)
const EstimatedTotalCostColumn = ({ asset }: { asset: WorkRequestsTableRow }) => (
    <>$ {Strings.formatMoney(asset.estimatedTotalCost)}</>
)
const MoreColumn = ({ row, viewModel }: { row: WorkRequestsTableRow; viewModel: WorkRequestsTableViewModel }) => {
    const router = useRouter()
    const isMobile = useIsMobile() || useIsInIframe()

    return (
        <Popover button={{ iconUrl: ImageUrls.More, theme: ButtonTheme.Light }}>
            <PopoverItem
                icon={ImageUrls.Print}
                label="Print"
                onClick={() => viewModel.assetToPrintChanged(row.assetId)}
            />
            <PopoverItem
  icon={ImageUrls.Wrench}
  label="Service Requests"
  onClick={() => {
    (isMobile) ? router.navigate(Routes.AssetWorkRequests(row.assetId.toString())) :
    window.open("work-requests/asset/" + row.assetId.toString(), "_blank");
  }}
/>

            <PopoverItem
                icon={ImageUrls.Glasses}
                label="View All Columns"
                onClick={() => viewModel.onViewAllColumnClicked(row)}
            />
        </Popover>
    )
}

const WorkOrderColumn = ({ asset, enableLink = true }: { asset: WorkRequestsTableRow; enableLink: boolean }) =>{
    const isMobile = useIsMobile() || useIsInIframe()

    return (!asset.workOrders ? null : (
        <div className="table-cell--work-request-work-order__value">
            {asset.workOrders
                .filter((x) => ![WorkOrderStatus.Closed, WorkOrderStatus.Deleted].includes(x.status))
                .slice(0, 4)
                .map((it) => (
                    <div className="work-request-work-order" key={it.id}>
                        {enableLink ? <a href={"/work-orders/" + it.id} target={isMobile ? "_self" : "_blank"}>{it.label}</a> : it.label}

                        <div className="work-order-status-label">{labelForWorkOrderStatus(it.status)}</div>
                    </div>
                ))}
            {asset.workOrders.filter((x) => ![WorkOrderStatus.Closed, WorkOrderStatus.Deleted].includes(x.status))
                .length > 4 && enableLink
                ? plusIconLink(asset)
                : ""}
        </div>
    ))

}

const getAssetFilter = (asset: WorkRequestsTableRow) => {
    let filters: Record<WorkOrderFilter, Filter<WorkOrderFilter>> = newEmptyFiltersObj(
        Object.values(WorkOrderFilter),
        WorkOrderFilterDefinitions
    )
    filters.search.values.push( { value: asset.assetName })
    return JSON.stringify(filters)
}

const plusIconLink = (asset: WorkRequestsTableRow) => (
    <Link title="Show all Work Orders" to={Routes.WorkOrders(getAssetFilter(asset))}>
        +
    </Link>
)

const AssetColumn = ({ asset }: { asset: WorkRequestsTableRow }) => (
    <a href={"/work-requests/asset/" + asset.assetId} target="_blank">
        {asset.assetName}
    </a>
)

const TagColumn = ({ asset }: { asset: WorkRequestsTableRow }) => {
    if (!asset.tag) return null
    return <Tag tag={asset.tag} showDayCount={true} />
}

const LocationColumn = ({ asset }: { asset: WorkRequestsTableRow }): JSX.Element => (
    <AssetLocation location={asset.location} site={asset.site} hasLinks={true} />
)

const ServiceColumn = ({ asset }: { asset: WorkRequestsTableRow }) => {
    const count = asset.workRequestTypeInfo[WorkRequestType.Service].count
    const color = count === 0 ? Color.Transparent : flagColor(asset, WorkRequestType.Service)
    const title = count.toString()
    const isMobile = useIsMobile() || useIsInIframe()
    return (
        <a
        className={classNames(
            "table-cell--work-request-type__value",
            color !== Color.Transparent ? "table-cell-work-request-type--has-tag" : null
        )}
        style={{ background: color }}
        title={title}
        href={"/work-requests/asset/" + asset.assetId}
        target={isMobile ? "_self" : "_blank"}
        >
        {count}
        </a>
    )
}

const PreventativeColumn = ({ asset }: { asset: WorkRequestsTableRow }) => {
    const count = asset.workRequestTypeInfo[WorkRequestType.Preventative].count
    const color = count === 0 ? Color.Transparent : flagColor(asset, WorkRequestType.Preventative)
    const title = count === 0 ? "This asset is not on a service schedule of this type" : count.toString()
    const isMobile = useIsMobile() || useIsInIframe()
    return (
        <a
        className={classNames(
            "table-cell--work-request-type__value",
            color !== Color.Transparent
                ? "table-cell-work-request-type--has-tag"
                : "table-cell-work-request-type--has-no-tag"
        )}
        style={{ background: color }}
        title={title}
        href={"/work-requests/asset/" + asset.assetId}
        target={isMobile ? "_self" : "_blank"}
        >
        {count === 0 ? "N/A" : count}
        </a>
    )
}

const InspectionColumn = ({ asset }: { asset: WorkRequestsTableRow }) => {
    const count = asset.workRequestTypeInfo[WorkRequestType.Inspection].count
    const color = count === 0 ? Color.Transparent : flagColor(asset, WorkRequestType.Inspection)
    const title = count === 0 ? "This asset is not on a service schedule of this type" : count.toString()
    const isMobile = useIsMobile() || useIsInIframe()
    return (
        <a
  className={classNames(
    "table-cell--work-request-type__value",
    color !== Color.Transparent
        ? "table-cell-work-request-type--has-tag"
        : "table-cell-work-request-type--has-no-tag"
  )}
  style={{ background: color }}
  title={title}
  href={"/work-requests/asset/" + asset.assetId}
  target={isMobile ? "_self" : "_blank"}
>
  {count === 0 ? "N/A" : count}
</a>

    )
}

const TotalColumn = ({ asset }: { asset: WorkRequestsTableRow }) => {
    const value = Object.values(asset.workRequestTypeInfo).reduce((accum, it) => accum + it.count, 0)
    const title = value.toString()
    const isMobile = useIsMobile() || useIsInIframe()
    return (
        <a
  className="table-cell--work-request-type__value"
  title={title}
  href={"/work-requests/asset/" + asset.assetId}
  target={isMobile ? "_self" : "_blank"}
>
  {value}
</a>

    )
}
const CityColumn = ({ asset }: { asset: WorkRequestsTableRow }) => <>{asset.city}</>
const StateColumn = ({ asset }: { asset: WorkRequestsTableRow }) => <>{asset.state}</>

function flagColor(asset: WorkRequestsTableRow, type: WorkRequestType): Color {
    switch (asset.workRequestTypeInfo[type].highestPriority) {
        case WorkRequestTableRowPriority.Immediate:
            return Color.Salmon
        case WorkRequestTableRowPriority.Overdue:
            return Color.Transparent
        case WorkRequestTableRowPriority.Upcoming:
            return Color.Transparent
        case WorkRequestTableRowPriority.High:
            return Color.Yellow
        case WorkRequestTableRowPriority.Medium:
            return Color.Grey
        case WorkRequestTableRowPriority.Low:
            return Color.GreyLight
        default:
            return Color.Transparent
    }
}
