import React, { CSSProperties } from "react"
import { isClosed, PDFWorkOrder, PDFWorkOrderStatus } from "../models/PDFWorkOrder"
import { PDFWorkRequest } from "../models/PDFWorkRequest"
import { Strings } from "../util/Strings"
import { PDFSection } from "./PDFSection"
import { PDFTable, PDFTLabel, PDFtd } from "./PDFTable"

export function EstimatedCostPDFSection({
    workRequest,
    workOrder,
}: {
    workRequest: PDFWorkRequest
    workOrder: PDFWorkOrder | undefined
}): JSX.Element {
    const estimatedCostAmountStyle: CSSProperties = {
        fontWeight: 700,
        textAlign: "end",
    }

    return (
        <PDFSection style={{ display: "grid", gridTemplateColumns: "1fr auto 2fr", gap: 80, alignItems: "start" }}>
            <PDFTable style={{ gridTemplateColumns: "1fr" }}>
                <PDFTLabel>Service Code:</PDFTLabel>
                <PDFtd>{workRequest.serviceCode}</PDFtd>
            </PDFTable>
            <div style={{ display: "grid", gridTemplateColumns: "auto auto", gap: "4px 24px", fontSize: "1.4rem" }}>
                <div>Estimated Labor Hours:</div>
                <div style={estimatedCostAmountStyle}>{workRequest.estimatedLaborHours}</div>
                <div>Estimated Labor Cost:</div>
                <div style={estimatedCostAmountStyle}>$ {Strings.formatMoney(workRequest.estimatedLaborCost ?? 0)}</div>
                <div>Estimated Parts Cost:</div>
                <div style={estimatedCostAmountStyle}>$ {Strings.formatMoney(workRequest.estimatedPartsCost ?? 0)}</div>
                <div>Estimated Total Cost:</div>
                <div style={estimatedCostAmountStyle}>$ {Strings.formatMoney(workRequest.estimatedTotalCost ?? 0)}</div>
            </div>
            <PDFTable style={{ gridTemplateColumns: "auto 1fr" }}>
                <PDFTLabel>Labor Hours:</PDFTLabel>
                <PDFtd  style={{ textAlign: "right" }}>
                    {workOrder && (workRequest.originalWorkRequest.actualLaborHours ?? "")}
                </PDFtd>
                <PDFTLabel>Labor Cost:</PDFTLabel>
                <PDFtd  style={{ textAlign: "right" }}>
                    {" "}
                    {workOrder &&
                        "$ " + Strings.formatMoney(workRequest.originalWorkRequest.actualLaborCost ?? "")}
                </PDFtd>
                <PDFTLabel>Parts Cost:</PDFTLabel>
                <PDFtd  style={{ textAlign: "right" }}>
                    {" "}
                    {workOrder &&
                        "$ " + Strings.formatMoney(workRequest.originalWorkRequest.actualPartsCost ?? "")}
                </PDFtd>
                <PDFTLabel>Total Cost:</PDFTLabel>
                <PDFtd  style={{ textAlign: "right" }}>
                    {" "}
                    {workOrder &&
                        "$ " +
                            Strings.formatMoney(
                                Number(workRequest.originalWorkRequest.actualLaborCost ?? 0) +
                                    Number(workRequest.originalWorkRequest.actualPartsCost ?? 0)
                            )}
                </PDFtd>
            </PDFTable>
        </PDFSection>
    )
}
