import { Outcome } from "@ethossoftworks/outcome"
import { Bloc } from "@lib/bloc/Bloc"
import { Asset } from "@model/assets/Asset"
import { AssetService } from "@service/assets/AssetService"

export type HeaderState = {
    isSearching: boolean
    foundAssets: Asset[]
}

export class HeaderBloc extends Bloc<HeaderState> {
    constructor(private assetService: AssetService) {
        super(newHeaderState())
    }

    searchForAssets = (search: string) =>
        this.effect({
            id: this.searchForAssets,
            block: async (job) => {
                this.update({ isSearching: true })

                const assets = await job.pause(this.assetService.searchAssets(search, false))
                if (assets.isError()) {
                    this.update({ isSearching: false })
                    return assets
                }

                this.update({ foundAssets: assets.value, isSearching: false })
                return Outcome.ok(undefined)
            },
        })
}

function newHeaderState(): HeaderState {
    return {
        isSearching: false,
        foundAssets: [],
    }
}
