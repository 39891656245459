import { Outcome } from "@ethossoftworks/outcome"
import { Bloc } from "@lib/bloc/Bloc"
import { arrayToObject } from "@lib/TypeUtil"
import { Asset } from "@model/assets/Asset"
import { AsyncStatus } from "@model/AsyncStatus"
import { AssetService } from "@service/assets/AssetService"
import { ServiceQuoteService } from "@service/serviceQuotes/ServiceQuoteService"

export type ServiceQuoteVerifyAssetState = {
    isModalVisible: boolean
    isSearchingForAssets: boolean
    availableAssets: Asset[]
    selectedAsset: Asset | null
    isFormValid: boolean
    isSaving: boolean
    showSearchAssetsInput: boolean
    showConfirmVerifyDialog: boolean
    effectStatus: Record<ServiceQuoteEffect, AsyncStatus>
    serviceQuoteId: number
}
export enum ServiceQuoteEffect {
    Fetch = "fetch",
    Save = "save",
    SaveRepairRequest = "saveRepairRequest",
    CreateServiceQuote = "createServiceQuote",
    SearchingForAsset = "searchingForAsset",
}

export class ServiceQuoteVerifyAssetBloc extends Bloc<ServiceQuoteVerifyAssetState> {
    constructor(private serviceQuoteService: ServiceQuoteService, private assetService: AssetService) {
        super(newServiceQuoteViewState(), { persistStateOnDispose: false })
    }

    showModal = () => {
        this.update({
            isModalVisible: true,
        })
    }

    hideModalAndResetForm = () => {
        this.update({
            isModalVisible: false,
            selectedAsset: null,
        })
    }

    private availableAssetsChanged = (assets: Asset[]) => this.update({ availableAssets: assets })

    loadInitialData = (serviceQuoteId: number, assetId: number) => {
        this.showSearchAssetsInputChanged(false)
        this.update({ serviceQuoteId: serviceQuoteId })
        this.effect({
            id: ServiceQuoteEffect.SearchingForAsset,
            block: async (job) => {
                this.isSearchingForAssetsChanged(true)
                const outcome = await job.pause(this.assetService.fetchAsset(assetId))
                if (outcome.isOk()) {
                    this.selectedAssetChanged(outcome.value)
                }
                this.isSearchingForAssetsChanged(false)
                return outcome
            },
        })
    }

    searchAssets = (search: string) =>
        this.effect({
            id: ServiceQuoteEffect.SearchingForAsset,
            block: async (job) => {
                this.isSearchingForAssetsChanged(true)
                const outcome = await job.pause(this.assetService.searchAssets(search, true))

                if (outcome.isOk()) {
                    this.availableAssetsChanged(outcome.value)
                }

                this.isSearchingForAssetsChanged(false)
                return outcome
            },
        })

    private isSearchingForAssetsChanged = (isSearching: boolean) => this.update({ isSearchingForAssets: isSearching })

    showSearchAssetsInputChanged = (showSearchAssetsInput: boolean) =>
        this.update({ showSearchAssetsInput: showSearchAssetsInput })

    showConfirmVerifyDialogChanged = (showConfirmVerifyDialog: boolean) =>
        this.update({ showConfirmVerifyDialog: showConfirmVerifyDialog })

    selectedAssetChanged = (asset: Asset | null) =>
        this.update({
            selectedAsset: asset,
            isFormValid: true,
        })

    assetChanged = (asset: Asset) => this.update({ selectedAsset: asset })

    verifyAssetClicked = () =>
        this.effect({
            id: ServiceQuoteEffect.Save,
            block: async (job) => {
                if (!this.state.selectedAsset) return Outcome.error(undefined)

                var response = await job.pause(
                    this.serviceQuoteService.verifyServiceQuoteAsset(
                        this.state.serviceQuoteId,
                        this.state.selectedAsset!.id!
                    )
                )

                if (response.isError()) {
                    return Outcome.error(response)
                }

                this.update({
                    isSaving: false,
                })

                return Outcome.ok(response)
            },
        })

    private effectStatusChanged = (effect: ServiceQuoteEffect, status: AsyncStatus) =>
        this.update({
            effectStatus: {
                ...this.state.effectStatus,
                [effect]: status,
            },
        })
}

function newServiceQuoteViewState(): ServiceQuoteVerifyAssetState {
    return {
        isModalVisible: false,
        isSearchingForAssets: false,
        availableAssets: [],
        selectedAsset: null,
        isFormValid: false,
        isSaving: false,
        effectStatus: arrayToObject(Object.values(ServiceQuoteEffect), (it) => [it, AsyncStatus.idle()]),
        serviceQuoteId: 0,
        showSearchAssetsInput: false,
        showConfirmVerifyDialog: false,
    }
}
