import { useBlocCoordinator } from "@lib/bloc/hooks"
import { compare, SortOrder } from "@lib/Comparable"
import { Link } from "@lib/router/components"
import { CompanyAlias } from "@model/company/CompanyAlias"
import { labelForContact } from "@model/contacts/Contact"
import { booleanFilterValue, Filter, filterContainsValue, textFilterValue } from "@model/filters/Filter"
import { labelForServiceCode, labelForServiceSchedule } from "@model/serviceRequests/ServiceRequest"
import { Threshold, ThresholdGroup, ThresholdType, ThresholdUnit } from "@model/Threshold"
import { labelForUrgency, Urgency } from "@model/Urgency"
import {
    labelForWorkOrderStatus,
    numberForWorkOrderStatus,
    WorkOrderFilter,
    WorkOrderStatus,
} from "@model/workOrders/WorkOrder"
import { WorkOrderFilterDefinitions } from "@model/workOrders/WorkOrderFilterDefinitions"
import { labelForWorkRequestType, WorkRequestType } from "@model/workRequests/WorkRequest"
import { FilterButton } from "@ui/common/buttons/FilterButton"
import { EditFilterPresetModal } from "@ui/common/filters/EditFilterPresetModal"
import { FilterOverlay, FilterOverlayGroup, FilterOverlayGroupColumn } from "@ui/common/filters/FilterOverlay"
import { FilterOverlayPresetsGroup } from "@ui/common/filters/FilterOverlayPresetsGroup"
import { ManageFilterPresetsModal } from "@ui/common/filters/ManageFilterPresetsModal"
import { CheckBox } from "@ui/common/form/CheckBox"
import { FormField } from "@ui/common/form/FormField"
import { MultiSelect } from "@ui/common/form/MultiSelect"
import { Select } from "@ui/common/form/Select"
import { TextInput, TextInputType } from "@ui/common/form/TextInput"
import { DI } from "@ui/DI"
import { Routes } from "@lib/Routes"
import React from "react"

export function WorkOrdersFilterOverlay(): JSX.Element {
    const [state, viewModel] = useBlocCoordinator(() => DI.workOrdersFilterOverlayViewModel())
    const tableBloc = DI.workOrdersTableBloc()

    return (
        <>
            <FilterOverlay
                isOverlayVisible={state.isOverlayVisible}
                className="filter-overlay--work-orders"
                hasSearch={true}
                onCancel={() => {
                    viewModel.updateFilters(tableBloc.state.filters)
                    viewModel.toggleOverlayVisibility()
                }}
            >
                <FilterOverlayGroup label="Asset Assignment" columns={1}>
                    <FilterOverlayGroupColumn>
                        {(state.subCompanies.length > 0 || state.parentCompany) && (
                            <FormField label={state.aliases[CompanyAlias.SubCompany]}>
                                <MultiSelect
                                    options={[state.parentCompany, ...state.subCompanies].map((it) => ({
                                        label: it.name,
                                        value: it,
                                        key: it.id,
                                        isChecked: filterContainsValue(state.filters[WorkOrderFilter.SubCompany], [
                                            it.id,
                                        ]),
                                    }))}
                                    onSelectionChanged={(selected) =>
                                        viewModel.updateFilter(
                                            WorkOrderFilter.SubCompany,
                                            selected.map((it) => ({ value: it.id, label: it.name }))
                                        )
                                    }
                                />
                            </FormField>
                        )}
                        {state.districts.length > 0 && (
                            <FormField label={state.aliases[CompanyAlias.District]}>
                                <MultiSelect
                                    options={state.districts.map((it) => ({
                                        label: it.name,
                                        value: it,
                                        key: it.id,
                                        isChecked: filterContainsValue(state.filters[WorkOrderFilter.District], [
                                            it.id,
                                        ]),
                                    }))}
                                    onSelectionChanged={(selected) =>
                                        viewModel.updateFilter(
                                            WorkOrderFilter.District,
                                            selected.map((it) => ({ value: it.id, label: it.name }))
                                        )
                                    }
                                />
                            </FormField>
                        )}
                        {state.subDistricts.length > 0 && (
                            <FormField label={state.aliases[CompanyAlias.SubDistrict]}>
                                <MultiSelect
                                    options={state.subDistricts.map((it) => ({
                                        label: it.name,
                                        value: it,
                                        key: it.id,
                                        isChecked: filterContainsValue(state.filters[WorkOrderFilter.SubDistrict], [
                                            it.id,
                                        ]),
                                    }))}
                                    onSelectionChanged={(selected) =>
                                        viewModel.updateFilter(
                                            WorkOrderFilter.SubDistrict,
                                            selected.map((it) => ({ value: it.id, label: it.name }))
                                        )
                                    }
                                />
                            </FormField>
                        )}
                        {state.units.length > 0 && (
                            <FormField label={state.aliases[CompanyAlias.Unit]}>
                                <MultiSelect
                                    options={state.units.map((it) => ({
                                        label: it.name,
                                        value: it,
                                        key: it.id,
                                        isChecked: filterContainsValue(state.filters[WorkOrderFilter.Unit], [it.id]),
                                    }))}
                                    onSelectionChanged={(selected) =>
                                        viewModel.updateFilter(
                                            WorkOrderFilter.Unit,
                                            selected.map((it) => ({ value: it.id, label: it.name }))
                                        )
                                    }
                                />
                            </FormField>
                        )}
                        {state.groups.length > 0 && (
                            <FormField label={state.aliases[CompanyAlias.Group]}>
                                <MultiSelect
                                    options={state.groups.map((it) => ({
                                        label: it.name,
                                        value: it,
                                        key: it.id,
                                        isChecked: filterContainsValue(state.filters[WorkOrderFilter.Group], [it.id]),
                                    }))}
                                    onSelectionChanged={(selected) =>
                                        viewModel.updateFilter(
                                            WorkOrderFilter.Group,
                                            selected.map((it) => ({ value: it.id, label: it.name }))
                                        )
                                    }
                                />
                            </FormField>
                        )}
                        {state.sites.length > 0 && (
                            <FormField label={WorkOrderFilterDefinitions[WorkOrderFilter.Site].categoryLabel}>
                                <MultiSelect
                                    options={state.sites.map((it) => ({
                                        label: it.name,
                                        value: it,
                                        key: it.id,
                                        isChecked: filterContainsValue(state.filters[WorkOrderFilter.Site], [it.id]),
                                    }))}
                                    onSelectionChanged={(selected) =>
                                        viewModel.updateFilter(
                                            WorkOrderFilter.Site,
                                            selected.map((it) => ({ value: it.id, label: it.name }))
                                        )
                                    }
                                />
                            </FormField>
                        )}
                    </FilterOverlayGroupColumn>
                </FilterOverlayGroup>
                <FilterOverlayGroup label="Asset Properties" columns={1}>
                    <FilterOverlayGroupColumn>
                        <FormField label={WorkOrderFilterDefinitions[WorkOrderFilter.AssetType].categoryLabel}>
                            <MultiSelect
                                options={state.types.map((it) => ({
                                    label: it.name,
                                    value: it,
                                    key: it.id,
                                    isChecked: filterContainsValue(state.filters[WorkOrderFilter.AssetType], [it.id]),
                                }))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        WorkOrderFilter.AssetType,
                                        selected.map((it) => ({ value: it.id, label: it.name }))
                                    )
                                }
                            />
                        </FormField>
                        <FormField label={WorkOrderFilterDefinitions[WorkOrderFilter.Category].categoryLabel}>
                            <MultiSelect
                                options={state.categories.map((it) => ({
                                    label: it.name,
                                    value: it,
                                    key: it.id,
                                    isChecked: filterContainsValue(state.filters[WorkOrderFilter.Category], [it.id]),
                                }))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        WorkOrderFilter.Category,
                                        selected.map((it) => ({ value: it.id, label: it.name }))
                                    )
                                }
                            />
                        </FormField>
                        <FormField label={WorkOrderFilterDefinitions[WorkOrderFilter.Class].categoryLabel}>
                            <MultiSelect
                                options={state.classes.map((it) => ({
                                    label: it.name,
                                    value: it,
                                    key: it.id,
                                    isChecked: filterContainsValue(state.filters[WorkOrderFilter.Class], [it.id]),
                                }))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        WorkOrderFilter.Class,
                                        selected.map((it) => ({ value: it.id, label: it.name }))
                                    )
                                }
                            />
                        </FormField>
                        <FormField label={WorkOrderFilterDefinitions[WorkOrderFilter.Make].categoryLabel}>
                            <MultiSelect
                                options={state.makes.map((it) => ({
                                    label: it.name,
                                    value: it,
                                    key: it.id,
                                    isChecked: filterContainsValue(state.filters[WorkOrderFilter.Make], [it.id]),
                                }))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        WorkOrderFilter.Make,
                                        selected.map((it) => ({ value: it.id, label: it.name }))
                                    )
                                }
                            />
                        </FormField>
                        <FormField label={WorkOrderFilterDefinitions[WorkOrderFilter.Model].categoryLabel}>
                            <MultiSelect
                                options={state.models.map((it) => ({
                                    label: it.name,
                                    value: it,
                                    key: it.id,
                                    isChecked: filterContainsValue(state.filters[WorkOrderFilter.Model], [it.id]),
                                }))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        WorkOrderFilter.Model,
                                        selected.map((it) => ({ value: it.id, label: it.name }))
                                    )
                                }
                            />
                        </FormField>
                    </FilterOverlayGroupColumn>
                </FilterOverlayGroup>
                <FilterOverlayGroup label="Work order" columns={2}>
                    <FilterOverlayGroupColumn>
                        <FormField label={WorkOrderFilterDefinitions[WorkOrderFilter.Status].categoryLabel}>
                            <MultiSelect
                                options={Object.values(WorkOrderStatus)
                                    .filter((it) => it !== WorkOrderStatus.Deleted)
                                    .map((it) => ({
                                        label: labelForWorkOrderStatus(it),
                                        value: it,
                                        checkAll: false,
                                        uncheckAll: false,
                                        isEnabled: isWorkOrderOptionEnabled(
                                            state.filters[WorkOrderFilter.Status],
                                            numberForWorkOrderStatus(it)
                                        ),
                                        isChecked: filterContainsValue(state.filters[WorkOrderFilter.Status], [
                                            numberForWorkOrderStatus(it),
                                        ]),
                                    }))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateWorkOrderStatusFilter(
                                        selected.map((it) => ({
                                            value: numberForWorkOrderStatus(it),
                                            label: labelForWorkOrderStatus(it),
                                        }))
                                    )
                                }
                            />
                        </FormField>
                        <FormField label={WorkOrderFilterDefinitions[WorkOrderFilter.ServiceCode].categoryLabel}>
                            <MultiSelect
                                options={state.serviceCodes.map((it) => ({
                                    key: it.id,
                                    value: it,
                                    label: labelForServiceCode(it),
                                    isChecked: filterContainsValue(state.filters[WorkOrderFilter.ServiceCode], [it.id]),
                                }))}
                                onSelectionChanged={(selected) => {
                                    viewModel.updateFilter(
                                        WorkOrderFilter.ServiceCode,
                                        selected.map((it) => ({ value: it.id, label: labelForServiceCode(it) }))
                                    )
                                }}
                            />
                        </FormField>
                        <FormField label={WorkOrderFilterDefinitions[WorkOrderFilter.Urgency].categoryLabel}>
                            <MultiSelect
                                options={Object.values(Urgency).map((value) => ({
                                    value: value,
                                    label: labelForUrgency(value),
                                    isChecked: filterContainsValue(state.filters[WorkOrderFilter.Urgency], [value]),
                                }))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        WorkOrderFilter.Urgency,
                                        selected.map((urgency) => ({ value: urgency, label: labelForUrgency(urgency) }))
                                    )
                                }
                            />
                        </FormField>
                        <FormField label={WorkOrderFilterDefinitions[WorkOrderFilter.WorkType].categoryLabel}>
                            <MultiSelect
                                options={Object.values(WorkRequestType)
                                    .map((value) => ({
                                        value: value,
                                        label: labelForWorkRequestType(value),
                                        isChecked: filterContainsValue(state.filters[WorkOrderFilter.WorkType], [
                                            value,
                                        ]),
                                    }))
                                    .sort((a, b) => compare(a.label, b.label, SortOrder.Asc))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        WorkOrderFilter.WorkType,
                                        selected.map((it) => ({ value: it, label: labelForWorkRequestType(it) }))
                                    )
                                }
                            />
                        </FormField>
                        <FormField label={WorkOrderFilterDefinitions[WorkOrderFilter.Schedule].categoryLabel}>
                            <MultiSelect
                                options={state.serviceSchedules.map((it) => ({
                                    key: it.id,
                                    value: it,
                                    label: labelForServiceSchedule(it),
                                    isChecked: filterContainsValue(state.filters[WorkOrderFilter.Schedule], [it.id]),
                                }))}
                                onSelectionChanged={(selected) => {
                                    viewModel.updateFilter(
                                        WorkOrderFilter.Schedule,
                                        selected.map((it) => ({ value: it.id, label: labelForServiceSchedule(it) }))
                                    )
                                }}
                            />
                        </FormField>
                        <FormField label={WorkOrderFilterDefinitions[WorkOrderFilter.AssignedTo].categoryLabel}>
                            <MultiSelect
                                options={state.mechanics
                                    .filter((it) => !it.deleted)
                                    .map((value) => ({
                                        key: value.id,
                                        value: value,
                                        label: labelForContact(value),
                                        isChecked: filterContainsValue(state.filters[WorkOrderFilter.AssignedTo], [
                                            value.id,
                                        ]),
                                    }))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        WorkOrderFilter.AssignedTo,
                                        selected
                                            .filter((it) => !it.deleted || it.hasActiveWorkOrders)
                                            .map((it) => ({ value: it.id, label: labelForContact(it) }))
                                    )
                                }
                            />
                        </FormField>
                        <FormField label={WorkOrderFilterDefinitions[WorkOrderFilter.HoursUntil].categoryLabel}>
                            <TextInput
                                type={TextInputType.Integer}
                                value={textFilterValue(state.filters[WorkOrderFilter.HoursUntil])}
                                isEnabled={
                                    textFilterValue(state.filters[WorkOrderFilter.DaysUntil]) == "" &&
                                    textFilterValue(state.filters[WorkOrderFilter.MilesUntil]) == "" &&
                                    !filterContainsValue(state.filters[WorkOrderFilter.Status], [
                                        numberForWorkOrderStatus(WorkOrderStatus.Closed),
                                    ])
                                }
                                onChange={(value) => viewModel.updateFilter(WorkOrderFilter.HoursUntil, [{ value }])}
                            />
                        </FormField>
                        <FormField label={WorkOrderFilterDefinitions[WorkOrderFilter.MilesUntil].categoryLabel}>
                            <TextInput
                                type={TextInputType.Integer}
                                value={textFilterValue(state.filters[WorkOrderFilter.MilesUntil])}
                                isEnabled={
                                    textFilterValue(state.filters[WorkOrderFilter.DaysUntil]) == "" &&
                                    textFilterValue(state.filters[WorkOrderFilter.HoursUntil]) == "" &&
                                    !filterContainsValue(state.filters[WorkOrderFilter.Status], [
                                        numberForWorkOrderStatus(WorkOrderStatus.Closed),
                                    ])
                                }
                                onChange={(value) => viewModel.updateFilter(WorkOrderFilter.MilesUntil, [{ value }])}
                            />
                        </FormField>
                        <FormField label={WorkOrderFilterDefinitions[WorkOrderFilter.DaysUntil].categoryLabel}>
                            <TextInput
                                type={TextInputType.Integer}
                                value={textFilterValue(state.filters[WorkOrderFilter.DaysUntil])}
                                isEnabled={
                                    textFilterValue(state.filters[WorkOrderFilter.HoursUntil]) == "" &&
                                    textFilterValue(state.filters[WorkOrderFilter.MilesUntil]) == "" &&
                                    !filterContainsValue(state.filters[WorkOrderFilter.Status], [
                                        numberForWorkOrderStatus(WorkOrderStatus.Closed),
                                    ])
                                }
                                onChange={(value) => viewModel.updateFilter(WorkOrderFilter.DaysUntil, [{ value }])}
                            />
                        </FormField>
                    </FilterOverlayGroupColumn>
                    <FilterOverlayGroupColumn noTopLabelOnFirstChild={true}>
                        <FormField>
                            <CheckBox
                                label={WorkOrderFilterDefinitions[WorkOrderFilter.RedTag].categoryLabel}
                                isChecked={booleanFilterValue(state.filters[WorkOrderFilter.RedTag])}
                                onCheckChanged={(isChecked) => {
                                    viewModel.updateFilter(WorkOrderFilter.RedTag, [{ value: isChecked }])
                                }}
                            />
                        </FormField>
                        <FormField>
                            <CheckBox
                                label={WorkOrderFilterDefinitions[WorkOrderFilter.YellowTag].categoryLabel}
                                isChecked={booleanFilterValue(state.filters[WorkOrderFilter.YellowTag])}
                                onCheckChanged={(isChecked) => {
                                    viewModel.updateFilter(WorkOrderFilter.YellowTag, [{ value: isChecked }])
                                }}
                            />
                        </FormField>
                        <FormField>
                            <CheckBox
                                label={WorkOrderFilterDefinitions[WorkOrderFilter.Overdue].categoryLabel}
                                isChecked={booleanFilterValue(state.filters[WorkOrderFilter.Overdue])}
                                isEnabled={
                                    !filterContainsValue(state.filters[WorkOrderFilter.Status], [
                                        numberForWorkOrderStatus(WorkOrderStatus.Closed),
                                    ])
                                }
                                onCheckChanged={(isChecked) => {
                                    viewModel.updateFilter(WorkOrderFilter.Overdue, [{ value: isChecked }])
                                }}
                            />
                        </FormField>
                    </FilterOverlayGroupColumn>
                </FilterOverlayGroup>
                <FilterOverlayPresetsGroup
                    presets={state.filterPresets}
                    searchFilter={WorkOrderFilter.Search}
                    searchLabel={"Asset Search"}
                    secondaryFilter={WorkOrderFilter.WOSearch}
                    secondarySearchLabel={"Work Order Search"}
                    inputType={TextInputType.Text}
                    viewModel={viewModel}
                    overlayState={state}
                />
            </FilterOverlay>
            <EditFilterPresetModal
                isVisible={state.isCreatePresetVisible}
                unavailableNames={state.filterPresets.map((it) => it.name)}
                onCancel={() => viewModel.hideCreatePreset()}
                onSave={(form) => viewModel.createPreset(form)}
                isSaving={state.isSaving}
            />
            <ManageFilterPresetsModal
                isVisible={state.isManagePresetsVisible}
                presets={state.filterPresets}
                onCancel={() => viewModel.hideManagePresets()}
                onPresetDelete={(preset) => viewModel.deletePreset(preset)}
                onPresetEdit={(preset, form) => viewModel.updatePresetForm(preset, form)}
                isSaving={state.isSaving}
            />
        </>
    )
}

function labelForThresholdGroup(group: ThresholdGroup): React.ReactNode {
    return (
        <>
            {labelForThresholdType(group.hours)} Hr | {labelForThresholdType(group.miles)} Mi |{" "}
            {labelForThresholdType(group.days)} Day
        </>
    )
}

function labelForThresholdType(threshold: Threshold): string {
    if (threshold.value === null) return "N/A"
    return `${threshold.value}${threshold.unit === ThresholdUnit.Percent ? "%" : ""}`
}

export function WorkOrdersFilterButton(): JSX.Element {
    const [state, viewModel] = useBlocCoordinator(() => DI.workOrdersFilterOverlayViewModel())

    return (
        <FilterButton
            onClick={() => viewModel.toggleOverlayVisibility()}
            isFilterOverlayVisible={state.isOverlayVisible}
        />
    )
}

function isWorkOrderOptionEnabled(selectedFilterValues: Filter | null, currentValue: number): boolean {
    let closedStatusValue: number = numberForWorkOrderStatus(WorkOrderStatus.Closed)
    if (!selectedFilterValues) return false
    if (currentValue == closedStatusValue) return true
    if (
        selectedFilterValues.values.findIndex((it) => it.value === closedStatusValue) !== -1 &&
        currentValue !== closedStatusValue
    ) {
        return false
    } else {
        return true
    }
}