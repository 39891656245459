import { useBlocCoordinator } from "@lib/bloc/hooks"
import { compare, SortOrder } from "@lib/Comparable"
import { Link } from "@lib/router/components"
import { CompanyAlias } from "@model/company/CompanyAlias"
import { booleanFilterValue, filterContainsValue, textFilterValue } from "@model/filters/Filter"
import { labelForServiceCode, labelForServiceSchedule } from "@model/serviceRequests/ServiceRequest"
import { Threshold, ThresholdGroup, ThresholdType, ThresholdUnit, thresholdUnitForValue } from "@model/Threshold"
import { labelForUrgency, Urgency } from "@model/Urgency"
import { labelForWorkRequestType, WorkRequestFilter, WorkRequestType } from "@model/workRequests/WorkRequest"
import { WorkRequestsFilterDefinitions } from "@model/workRequests/WorkRequestsFilterDefinitions"
import { UserBloc } from "@state/user/UserBloc"
import { FilterButton } from "@ui/common/buttons/FilterButton"
import { EditFilterPresetModal } from "@ui/common/filters/EditFilterPresetModal"
import { FilterOverlay, FilterOverlayGroup, FilterOverlayGroupColumn } from "@ui/common/filters/FilterOverlay"
import { FilterOverlayPresetsGroup } from "@ui/common/filters/FilterOverlayPresetsGroup"
import { ManageFilterPresetsModal } from "@ui/common/filters/ManageFilterPresetsModal"
import { CheckBox } from "@ui/common/form/CheckBox"
import { FormField } from "@ui/common/form/FormField"
import { MultiSelect } from "@ui/common/form/MultiSelect"
import { Select } from "@ui/common/form/Select"
import { TextInput, TextInputType } from "@ui/common/form/TextInput"
import { DI } from "@ui/DI"
import { Routes } from "@lib/Routes"
import React from "react"

export function WorkRequestsFilterOverlay(): JSX.Element {
    const [state, viewModel] = useBlocCoordinator(() => DI.workRequestsFilterOverlayViewModel())
    const workRequestsBloc = DI.workRequestsTableBloc()

    return (
        <>
            <FilterOverlay
                isOverlayVisible={state.isOverlayVisible}
                className="filter-overlay--work-requests"
                hasSearch={true}
                onCancel={() => {
                    viewModel.updateFilters(workRequestsBloc.state.filters)
                    viewModel.toggleOverlayVisibility()
                }}
            >
                <FilterOverlayGroup label="Asset Assignment" columns={1}>
                    <FilterOverlayGroupColumn>
                        {(state.subCompanies.length > 0 || state.parentCompany) && (
                            <FormField label={state.aliases[CompanyAlias.SubCompany]}>
                                <MultiSelect
                                    options={[state.parentCompany, ...state.subCompanies].map((it) => ({
                                        label: it.name,
                                        value: it,
                                        key: it.id,
                                        isChecked: filterContainsValue(state.filters[WorkRequestFilter.SubCompany], [
                                            it.id,
                                        ]),
                                    }))}
                                    onSelectionChanged={(selected) =>
                                        viewModel.updateFilter(
                                            WorkRequestFilter.SubCompany,
                                            selected.map((it) => ({ value: it.id, label: it.name }))
                                        )
                                    }
                                />
                            </FormField>
                        )}
                        {state.districts.length > 0 && (
                            <FormField label={state.aliases[CompanyAlias.District]}>
                                <MultiSelect
                                    options={state.districts.map((it) => ({
                                        label: it.name,
                                        value: it,
                                        key: it.id,
                                        isChecked: filterContainsValue(state.filters[WorkRequestFilter.District], [
                                            it.id,
                                        ]),
                                    }))}
                                    onSelectionChanged={(selected) =>
                                        viewModel.updateFilter(
                                            WorkRequestFilter.District,
                                            selected.map((it) => ({ value: it.id, label: it.name }))
                                        )
                                    }
                                />
                            </FormField>
                        )}
                        {state.subDistricts.length > 0 && (
                            <FormField label={state.aliases[CompanyAlias.SubDistrict]}>
                                <MultiSelect
                                    options={state.subDistricts.map((it) => ({
                                        label: it.name,
                                        value: it,
                                        key: it.id,
                                        isChecked: filterContainsValue(state.filters[WorkRequestFilter.SubDistrict], [
                                            it.id,
                                        ]),
                                    }))}
                                    onSelectionChanged={(selected) =>
                                        viewModel.updateFilter(
                                            WorkRequestFilter.SubDistrict,
                                            selected.map((it) => ({ value: it.id, label: it.name }))
                                        )
                                    }
                                />
                            </FormField>
                        )}
                        {state.units.length > 0 && (
                            <FormField label={state.aliases[CompanyAlias.Unit]}>
                                <MultiSelect
                                    options={state.units.map((it) => ({
                                        label: it.name,
                                        value: it,
                                        key: it.id,
                                        isChecked: filterContainsValue(state.filters[WorkRequestFilter.Unit], [it.id]),
                                    }))}
                                    onSelectionChanged={(selected) =>
                                        viewModel.updateFilter(
                                            WorkRequestFilter.Unit,
                                            selected.map((it) => ({ value: it.id, label: it.name }))
                                        )
                                    }
                                />
                            </FormField>
                        )}
                        {state.groups.length > 0 && (
                            <FormField label={state.aliases[CompanyAlias.Group]}>
                                <MultiSelect
                                    options={state.groups.map((it) => ({
                                        label: it.name,
                                        value: it,
                                        key: it.id,
                                        isChecked: filterContainsValue(state.filters[WorkRequestFilter.Group], [it.id]),
                                    }))}
                                    onSelectionChanged={(selected) =>
                                        viewModel.updateFilter(
                                            WorkRequestFilter.Group,
                                            selected.map((it) => ({ value: it.id, label: it.name }))
                                        )
                                    }
                                />
                            </FormField>
                        )}
                        {state.sites.length > 0 && (
                            <FormField label={WorkRequestsFilterDefinitions[WorkRequestFilter.Site].categoryLabel}>
                                <MultiSelect
                                    options={state.sites.map((it) => ({
                                        label: it.name,
                                        value: it,
                                        key: it.id,
                                        isChecked: filterContainsValue(state.filters[WorkRequestFilter.Site], [it.id]),
                                    }))}
                                    onSelectionChanged={(selected) =>
                                        viewModel.updateFilter(
                                            WorkRequestFilter.Site,
                                            selected.map((it) => ({ value: it.id, label: it.name }))
                                        )
                                    }
                                />
                            </FormField>
                        )}
                    </FilterOverlayGroupColumn>
                </FilterOverlayGroup>
                <FilterOverlayGroup label="Asset Properties" columns={1}>
                    <FilterOverlayGroupColumn>
                        <FormField label={WorkRequestsFilterDefinitions[WorkRequestFilter.AssetType].categoryLabel}>
                            <MultiSelect
                                options={state.types.map((it) => ({
                                    label: it.name,
                                    value: it,
                                    key: it.id,
                                    isChecked: filterContainsValue(state.filters[WorkRequestFilter.AssetType], [it.id]),
                                }))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        WorkRequestFilter.AssetType,
                                        selected.map((it) => ({ value: it.id, label: it.name }))
                                    )
                                }
                            />
                        </FormField>
                        <FormField label={WorkRequestsFilterDefinitions[WorkRequestFilter.Category].categoryLabel}>
                            <MultiSelect
                                options={state.categories.map((it) => ({
                                    label: it.name,
                                    value: it,
                                    key: it.id,
                                    isChecked: filterContainsValue(state.filters[WorkRequestFilter.Category], [it.id]),
                                }))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        WorkRequestFilter.Category,
                                        selected.map((it) => ({ value: it.id, label: it.name }))
                                    )
                                }
                            />
                        </FormField>
                        <FormField label={WorkRequestsFilterDefinitions[WorkRequestFilter.Class].categoryLabel}>
                            <MultiSelect
                                options={state.classes.map((it) => ({
                                    label: it.name,
                                    value: it,
                                    key: it.id,
                                    isChecked: filterContainsValue(state.filters[WorkRequestFilter.Class], [it.id]),
                                }))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        WorkRequestFilter.Class,
                                        selected.map((it) => ({ value: it.id, label: it.name }))
                                    )
                                }
                            />
                        </FormField>
                        <FormField label={WorkRequestsFilterDefinitions[WorkRequestFilter.Make].categoryLabel}>
                            <MultiSelect
                                options={state.makes.map((it) => ({
                                    label: it.name,
                                    value: it,
                                    key: it.id,
                                    isChecked: filterContainsValue(state.filters[WorkRequestFilter.Make], [it.id]),
                                }))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        WorkRequestFilter.Make,
                                        selected.map((it) => ({ value: it.id, label: it.name }))
                                    )
                                }
                            />
                        </FormField>
                        <FormField label={WorkRequestsFilterDefinitions[WorkRequestFilter.Model].categoryLabel}>
                            <MultiSelect
                                options={state.models.map((it) => ({
                                    label: it.name,
                                    value: it,
                                    key: it.id,
                                    isChecked: filterContainsValue(state.filters[WorkRequestFilter.Model], [it.id]),
                                }))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        WorkRequestFilter.Model,
                                        selected.map((it) => ({ value: it.id, label: it.name }))
                                    )
                                }
                            />
                        </FormField>
                    </FilterOverlayGroupColumn>
                </FilterOverlayGroup>
                <FilterOverlayGroup label="Service Request" columns={2}>
                    <FilterOverlayGroupColumn>
                        <FormField label={WorkRequestsFilterDefinitions[WorkRequestFilter.WorkType].categoryLabel}>
                            <MultiSelect
                                options={Object.values(WorkRequestType)
                                    .map((value) => ({
                                        value: value,
                                        label: labelForWorkRequestType(value),
                                        isChecked: filterContainsValue(state.filters[WorkRequestFilter.WorkType], [
                                            value,
                                        ]),
                                    }))
                                    .sort((a, b) => compare(a.label, b.label, SortOrder.Asc))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        WorkRequestFilter.WorkType,
                                        selected.map((type) => ({ value: type, label: labelForWorkRequestType(type) }))
                                    )
                                }
                            />
                        </FormField>
                        <FormField label={WorkRequestsFilterDefinitions[WorkRequestFilter.Urgency].categoryLabel}>
                            <MultiSelect
                                options={Object.values(Urgency).map((value) => ({
                                    value: value,
                                    label: labelForUrgency(value),
                                    isChecked: filterContainsValue(state.filters[WorkRequestFilter.Urgency], [value]),
                                }))}
                                onSelectionChanged={(selected) =>
                                    viewModel.updateFilter(
                                        WorkRequestFilter.Urgency,
                                        selected.map((urgency) => ({ value: urgency, label: labelForUrgency(urgency) }))
                                    )
                                }
                            />
                        </FormField>
                        <FormField label={WorkRequestsFilterDefinitions[WorkRequestFilter.Schedule].categoryLabel}>
                            <MultiSelect
                                options={state.serviceSchedules.map((it) => ({
                                    key: it.id,
                                    value: it,
                                    label: labelForServiceSchedule(it),
                                    isChecked: filterContainsValue(state.filters[WorkRequestFilter.Schedule], [it.id]),
                                }))}
                                onSelectionChanged={(selected) => {
                                    viewModel.updateFilter(
                                        WorkRequestFilter.Schedule,
                                        selected.map((it) => ({ value: it.id, label: labelForServiceSchedule(it) }))
                                    )
                                }}
                            />
                        </FormField>
                        <FormField
                            className="form-field--threshold"
                            label={WorkRequestsFilterDefinitions[WorkRequestFilter.HoursUntil].categoryLabel}
                        >
                            <TextInput
                                type={TextInputType.Integer}
                                value={textFilterValue(state.filters[WorkRequestFilter.HoursUntil])}
                                isEnabled={
                                    textFilterValue(state.filters[WorkRequestFilter.DaysUntil]) == "" &&
                                    textFilterValue(state.filters[WorkRequestFilter.MilesUntil]) == ""
                                }
                                onChange={(value) => viewModel.updateFilter(WorkRequestFilter.HoursUntil, [{ value }])}
                            />
                            <Select
                                value={textFilterValue(state.filters[WorkRequestFilter.HoursUntilUnit])}
                                options={Object.values(ThresholdUnit).map((it) => ({
                                    label: Threshold.labelForUnit(ThresholdType.Hours, it),
                                    value: it,
                                }))}
                                onChange={(value) =>
                                    viewModel.updateFilter(WorkRequestFilter.HoursUntilUnit, [{ value }])
                                }
                            />
                        </FormField>
                        <FormField
                            className="form-field--threshold"
                            label={WorkRequestsFilterDefinitions[WorkRequestFilter.MilesUntil].categoryLabel}
                        >
                            <TextInput
                                type={TextInputType.Integer}
                                value={textFilterValue(state.filters[WorkRequestFilter.MilesUntil])}
                                isEnabled={
                                    textFilterValue(state.filters[WorkRequestFilter.DaysUntil]) == "" &&
                                    textFilterValue(state.filters[WorkRequestFilter.HoursUntil]) == ""
                                }
                                onChange={(value) => viewModel.updateFilter(WorkRequestFilter.MilesUntil, [{ value }])}
                            />
                            <Select
                                value={textFilterValue(state.filters[WorkRequestFilter.MilesUntilUnit])}
                                options={Object.values(ThresholdUnit).map((it) => ({
                                    label: Threshold.labelForUnit(ThresholdType.Miles, it),
                                    value: it,
                                }))}
                                onChange={(value) =>
                                    viewModel.updateFilter(WorkRequestFilter.MilesUntilUnit, [{ value }])
                                }
                            />
                        </FormField>
                        <FormField
                            className="form-field--threshold"
                            label={WorkRequestsFilterDefinitions[WorkRequestFilter.DaysUntil].categoryLabel}
                        >
                            <TextInput
                                type={TextInputType.Integer}
                                value={textFilterValue(state.filters[WorkRequestFilter.DaysUntil])}
                                isEnabled={
                                    textFilterValue(state.filters[WorkRequestFilter.HoursUntil]) == "" &&
                                    textFilterValue(state.filters[WorkRequestFilter.MilesUntil]) == ""
                                }
                                onChange={(value) => viewModel.updateFilter(WorkRequestFilter.DaysUntil, [{ value }])}
                            />
                            <Select
                                value={textFilterValue(state.filters[WorkRequestFilter.DaysUntilUnit])}
                                options={Object.values(ThresholdUnit).map((it) => ({
                                    label: Threshold.labelForUnit(ThresholdType.Days, it),
                                    value: it,
                                }))}
                                onChange={(value) =>
                                    viewModel.updateFilter(WorkRequestFilter.DaysUntilUnit, [{ value }])
                                }
                            />
                        </FormField>
                        <FormField label={WorkRequestsFilterDefinitions[WorkRequestFilter.ServiceCode].categoryLabel}>
                            <MultiSelect
                                options={state.serviceCodes.map((it) => ({
                                    key: it.id,
                                    value: it,
                                    label: labelForServiceCode(it),
                                    isChecked: filterContainsValue(state.filters[WorkRequestFilter.ServiceCode], [
                                        it.id,
                                    ]),
                                }))}
                                onSelectionChanged={(selected) => {
                                    viewModel.updateFilter(
                                        WorkRequestFilter.ServiceCode,
                                        selected.map((it) => ({ value: it.id, label: labelForServiceCode(it) }))
                                    )
                                }}
                            />
                        </FormField>
                    </FilterOverlayGroupColumn>
                    <FilterOverlayGroupColumn noTopLabelOnFirstChild={true}>
                        <FormField>
                            <CheckBox
                                label={WorkRequestsFilterDefinitions[WorkRequestFilter.RedTag].categoryLabel}
                                isChecked={booleanFilterValue(state.filters[WorkRequestFilter.RedTag])}
                                onCheckChanged={(isChecked) => {
                                    viewModel.updateFilter(WorkRequestFilter.RedTag, [{ value: isChecked }])
                                }}
                            />
                        </FormField>
                        <FormField>
                            <CheckBox
                                label={WorkRequestsFilterDefinitions[WorkRequestFilter.YellowTag].categoryLabel}
                                isChecked={booleanFilterValue(state.filters[WorkRequestFilter.YellowTag])}
                                onCheckChanged={(isChecked) => {
                                    viewModel.updateFilter(WorkRequestFilter.YellowTag, [{ value: isChecked }])
                                }}
                            />
                        </FormField>
                        <FormField>
                            <CheckBox
                                label={WorkRequestsFilterDefinitions[WorkRequestFilter.Overdue].categoryLabel}
                                isChecked={booleanFilterValue(state.filters[WorkRequestFilter.Overdue])}
                                onCheckChanged={(isChecked) => {
                                    viewModel.updateFilter(WorkRequestFilter.Overdue, [{ value: isChecked }])
                                }}
                            />
                        </FormField>
                        <FormField>
                            <CheckBox
                                label={
                                    <>
                                        {WorkRequestsFilterDefinitions[WorkRequestFilter.Upcoming].categoryLabel}
                                        <div className="upcoming-label-threshold-settings-cont">
                                            <div className="upcoming-threshold-setting">
                                                <div className="upcoming-threshold-setting-label">
                                                    Preventative Maintenance
                                                </div>
                                                <div className="upcoming-threshold-setting-value">
                                                    {labelForThresholdGroup(
                                                        state.upcomingThresholds[WorkRequestType.Preventative]
                                                    )}
                                                </div>
                                            </div>
                                            <div className="upcoming-threshold-setting">
                                                <div className="upcoming-threshold-setting-label">Inspection</div>
                                                <div className="upcoming-threshold-setting-value">
                                                    {labelForThresholdGroup(
                                                        state.upcomingThresholds[WorkRequestType.Inspection]
                                                    )}
                                                </div>
                                            </div>
                                            <div className="upcoming-threshold-setting">
                                                <div className="upcoming-threshold-setting-label">Repair</div>
                                                <div className="upcoming-threshold-setting-value">
                                                    {labelForThresholdGroup(
                                                        state.upcomingThresholds[WorkRequestType.Service]
                                                    )}
                                                </div>
                                            </div>
                                            <Link className="upcoming-threshold-edit-link" to={Routes.GlobalSettings()}>
                                                Edit
                                            </Link>
                                        </div>
                                    </>
                                }
                                isChecked={booleanFilterValue(state.filters[WorkRequestFilter.Upcoming])}
                                onCheckChanged={(isChecked) => {
                                    viewModel.updateFilter(WorkRequestFilter.Upcoming, [{ value: isChecked }])
                                }}
                            />
                        </FormField>
                    </FilterOverlayGroupColumn>
                </FilterOverlayGroup>
                <FilterOverlayPresetsGroup
                    presets={state.filterPresets}
                    searchFilter={WorkRequestFilter.Search}
                    searchLabel={"Asset Search"}
                    viewModel={viewModel}
                    overlayState={state}
                />
            </FilterOverlay>
            <EditFilterPresetModal
                isVisible={state.isCreatePresetVisible}
                unavailableNames={state.filterPresets.map((it) => it.name)}
                onCancel={() => viewModel.hideCreatePreset()}
                onSave={(form) => viewModel.createPreset(form)}
                isSaving={state.isSaving}
            />
            <ManageFilterPresetsModal
                isVisible={state.isManagePresetsVisible}
                presets={state.filterPresets}
                onCancel={() => viewModel.hideManagePresets()}
                onPresetDelete={(preset) => viewModel.deletePreset(preset)}
                onPresetEdit={(preset, form) => viewModel.updatePresetForm(preset, form)}
                isSaving={state.isSaving}
            />
        </>
    )
}

function labelForThresholdGroup(group: ThresholdGroup): React.ReactNode {
    return (
        <>
            {labelForThresholdType(group.hours)} Hr | {labelForThresholdType(group.miles)} Mi |{" "}
            {labelForThresholdType(group.days)} Day
        </>
    )
}

function labelForThresholdType(threshold: Threshold): string {
    if (threshold.value === null) return "N/A"
    return `${threshold.value}${threshold.unit === ThresholdUnit.Percent ? "%" : ""}`
}

export function WorkRequestsFilterButton(): JSX.Element {
    const [state, viewModel] = useBlocCoordinator(() => DI.workRequestsFilterOverlayViewModel())

    return (
        <FilterButton
            onClick={() => viewModel.toggleOverlayVisibility()}
            isFilterOverlayVisible={state.isOverlayVisible}
        />
    )
}
