import { Outcome } from "@ethossoftworks/outcome"
import { SortOrder } from "@lib/Comparable"
import {
    arrayOfNotNull,
    isString,
    nullNumOrDefault,
    numOrDefault,
    safeParseFloat,
    safeParseInt,
    stringOrDefault,
} from "@lib/TypeUtil"
import {
    booleanFilterValue,
    Filter,
    isEmptyFilterValue,
    numberArrayFilterValue,
    textFilterValue
} from "@model/filters/Filter"
import { HistoryItem } from "@model/HistoryItem"
import { PagedResponse, Pagination } from "@model/Pagination"
import { TagType } from "@model/Tag"
import { ThresholdType, thresholdUnitForValue } from "@model/Threshold"
import { ordinalForUrgency, Urgency, urgencyForValue } from "@model/Urgency"
import { CBACode } from "@model/workOrders/CbaCode"
import { CreateWorkOrderForm } from "@model/workOrders/CreateWorkOrderForm"
import {
    numberForWorkOrderStatus,
    WorkOrder,
    WorkOrderFilter,
    WorkOrderQuickFilterCount,
    WorkOrderStatus,
    WorkOrderTableColumn,
    WorkOrderTableRow,
} from "@model/workOrders/WorkOrder"
import { AddWorkRequestToWorkOrderForm } from "@model/workRequests/AddWorkRequestToWorkOrderForm"
import { WorkRequest, workRequestTypeForValue } from "@model/workRequests/WorkRequest"
import {
    ApiService,
    dateFromApi,
    HttpMethod,
    notifiedContactsFromApi,
    notifiedContactsToApi,
    nullableDateFromApi,
    paginationFromApi,
    paginationToApi,
    sortToApi,
    tagFromApi,
    tagTypeToApi,
    thresholdUnitToApi,
    UrgencyFromApi,
} from "@service/ApiService"
import { CompanyFieldIdMapper } from "@service/company/CompanyService"
import { WorkOrderService } from "@service/workOrders/WorkOrderService"

export type CloseWorkOrderDto = {
    completedHourMeter: number | null
    completedOdometer: number | null
    specialInstructions: string
    serviceDateTime: Date | null
    siteId: number | null
    workPerformedByPersonId: number[] | null
    dueOdometer: number
    dueHourMeter: number
    dueDate: Date | null
    urgency: Urgency
    assignedToPersonId: number | null
    reasonTagged: string | null
    tagStatus: TagType | null
    assetId: number
    id: number
    meterSync: boolean
    workRequests: WorkRequest[]
    travelDistance: string | null
    travelTime: string | null
    nightWork: boolean
    cbaCode: CBACode | null
    serviceRecord: boolean
}

export class MainWorkOrderService implements WorkOrderService {
    constructor(private apiService: ApiService, private idMapper: CompanyFieldIdMapper) {}

    async fetchWorkOrders(
        filters?: Filter<WorkOrderFilter>[],
        sort?: { column: WorkOrderTableColumn; order: SortOrder },
        pagination?: Pagination
    ): Promise<Outcome<PagedResponse<WorkOrderTableRow[]> & { counts: Record<WorkOrderQuickFilterCount, number> }>> {
        const body = {
            ...paginationToApi(pagination),
            ...this.createWorkOrderListFilterBody(filters),
            ...this.sortWorkOrderToApi(sort),
        }

        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/maintenance/WorkOrderGrid",
            body,
        })
        if (!ApiService.isValidPagedResponse(response)) return Outcome.error(response)

        return Outcome.ok({
            data: response.value.results.map((it) => workOrderTableRowFromApi(it)),
            pagination: paginationFromApi(response.value),
            counts: workOrderCountsFromApi(response.value.summary),
        })
    }

    async fetchWorkOrderXls(filters?: Filter<WorkOrderFilter>[]): Promise<Outcome<Blob>> {
        const body = {
            ...this.createWorkOrderListFilterBody(filters),
        }
        const response = await this.apiService.makeDownloadRequest({
            method: HttpMethod.Post,
            path: "/Maintenance/WorkOrderGrid/AsXLS",
            body,
        })
        if (!ApiService.isValidObjectResponse(response) || response.value == undefined) return Outcome.error(response)
        return Outcome.ok(response.value)
    }

    private sortWorkOrderToApi = (sort?: any): Record<string, any> | void => {
        if (sort)
            return sortToApi({
                column: WorkOrderTableColumnToWorkOrderTableRowField(sort.column),
                order: sort.order,
            })
    }

    private createWorkOrderListFilterBody(filters?: Filter<WorkOrderFilter>[]): any {
        const filterBody: Record<string, any> = {}
        const statusFilter = filters?.find((filter) => filter.definition.type === WorkOrderFilter.Status)

        filters?.forEach((filter) => {
            if (isEmptyFilterValue(filter)) return

            switch (filter.definition.type) {
                // Asset Assignment
                case WorkOrderFilter.SubCompany:
                    filterBody.companyId = numberArrayFilterValue(filter)
                    break
                case WorkOrderFilter.District:
                    filterBody.districtId = Array.isArray(filterBody.districtId)
                        ? [...filterBody.districtId, ...numberArrayFilterValue(filter)]
                        : numberArrayFilterValue(filter)
                    break
                case WorkOrderFilter.SubDistrict:
                    filterBody.districtId = Array.isArray(filterBody.districtId)
                        ? [...filterBody.districtId, ...numberArrayFilterValue(filter)]
                        : numberArrayFilterValue(filter)
                    break
                case WorkOrderFilter.Unit:
                    filterBody.districtId = Array.isArray(filterBody.districtId)
                        ? [...filterBody.districtId, ...numberArrayFilterValue(filter)]
                        : numberArrayFilterValue(filter)
                    break
                case WorkOrderFilter.Group:
                    filterBody.groupId = numberArrayFilterValue(filter)
                    break
                case WorkOrderFilter.Site:
                    filterBody.siteId = numberArrayFilterValue(filter)
                    break

                // Asset Properties
                case WorkOrderFilter.AssetType:
                    filterBody.typeId = numberArrayFilterValue(filter)
                    break
                case WorkOrderFilter.Category:
                    filterBody.categoryId = numberArrayFilterValue(filter)
                    break
                case WorkOrderFilter.Class:
                    filterBody.classId = numberArrayFilterValue(filter)
                    break
                case WorkOrderFilter.Make:
                    filterBody.assetMakeId = numberArrayFilterValue(filter)
                    break
                case WorkOrderFilter.Model:
                    filterBody.assetModelId = numberArrayFilterValue(filter)
                    break

                // Work Request
                case WorkOrderFilter.WorkType:
                    filterBody.serviceTypeId = filter.values
                        .map((it) => it.value)
                        .flatMap((it) => {
                            if (!isString(it)) return []
                            return this.idMapper.fromWorkRequestType(workRequestTypeForValue(it))
                        })
                    break
                case WorkOrderFilter.Urgency:
                    filterBody.urgencyRanks = filter.values
                        .map((it) => it.value)
                        .flatMap((it) => {
                            if (!isString(it)) return []
                            return ordinalForUrgency(urgencyForValue(it))
                        })
                    break
                case WorkOrderFilter.Schedule:
                    filterBody.serviceScheduleId = numberArrayFilterValue(filter)
                    break
                case WorkOrderFilter.HoursUntil:
                    filterBody.hoursUntil = safeParseInt(textFilterValue(filter))
                    break
                case WorkOrderFilter.HoursUntilUnit:
                    filterBody.hoursUntilUnit = thresholdUnitToApi(
                        ThresholdType.Hours,
                        thresholdUnitForValue(textFilterValue(filter))
                    )
                    break
                case WorkOrderFilter.MilesUntil:
                    filterBody.milesUntil = safeParseInt(textFilterValue(filter))
                    break
                case WorkOrderFilter.MilesUntilUnit:
                    filterBody.milesUntilUnit = thresholdUnitToApi(
                        ThresholdType.Miles,
                        thresholdUnitForValue(textFilterValue(filter))
                    )
                    break
                case WorkOrderFilter.DaysUntil:
                    filterBody.daysUntil = safeParseInt(textFilterValue(filter))
                    break
                case WorkOrderFilter.DaysUntilUnit:
                    filterBody.daysUntilUnit = thresholdUnitToApi(
                        ThresholdType.Days,
                        thresholdUnitForValue(textFilterValue(filter))
                    )
                    break
                case WorkOrderFilter.ServiceCode:
                    filterBody.serviceCode = numberArrayFilterValue(filter)
                    break

                // Other
                case WorkOrderFilter.RedTag:
                    filterBody.tagStatus = Array.isArray(filterBody.tagStatus) ? filterBody.tagStatus : []
                    if (booleanFilterValue(filter)) filterBody.tagStatus.push(tagTypeToApi(TagType.Red))
                    break
                case WorkOrderFilter.YellowTag:
                    filterBody.tagStatus = Array.isArray(filterBody.tagStatus) ? filterBody.tagStatus : []
                    if (booleanFilterValue(filter)) filterBody.tagStatus.push(tagTypeToApi(TagType.Yellow))
                    break
                case WorkOrderFilter.Overdue:
                    filterBody.overdue = booleanFilterValue(filter)
                    break
                case WorkOrderFilter.Upcoming:
                    filterBody.upcoming = booleanFilterValue(filter)
                    break
                // Search
                case WorkOrderFilter.Search:
                    filterBody.globalSearchQuery = textFilterValue(filter)
                    break
                case WorkOrderFilter.WOSearch:
                    filterBody.WorkOrderNumber = textFilterValue(filter)
                    if (!statusFilter) {
                        // If status filter exists and doesn't have values, set workOrderStatus to [1, 2, 3, 4, 5]
                        filterBody.workOrderStatus = [1, 2, 3, 4, 5]
                    }
                    break
                case WorkOrderFilter.AssignedTo:
                    filterBody.mechanics = numberArrayFilterValue(filter)
                    break
                case WorkOrderFilter.Status:
                    filterBody.workOrderStatus = numberArrayFilterValue(filter)
                    break
            }
        })
        //this parameter is hardcoded here because
        //even adding this as a default parameter
        //is filtered as an empty value by line 84 (in isEmptyFilterValue) in
        //SortableTableBlocs.ts in the fetchData method
        //filterBody.dismissed = false

        return filterBody
    }

    async addWorkRequestsToWorkOrder(
        workOrderId: number,
        assetId: number,
        form: AddWorkRequestToWorkOrderForm,
        selectedWorkRequests: number[]
    ): Promise<Outcome<void, unknown>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/Maintenance/WorkOrder/AssignWorkRequest",
            body: WorkOrderFormToAssignWorkRequestsToWorkOrderApi(
                this.idMapper,
                workOrderId,
                assetId,
                form,
                selectedWorkRequests
            ),
        })

        if (response.isError()) return response
        return Outcome.ok(undefined)
    }

    async createWorkOrder(
        assetId: number,
        form: CreateWorkOrderForm,
        selectedWorkRequests: number[]
    ): Promise<Outcome<void>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/Maintenance/WorkOrder/WorkOrderCreate",
            body: workOrderFormToApi(this.idMapper, assetId, form, selectedWorkRequests),
        })

        if (response.isError()) return response
        return Outcome.ok(undefined)
    }

    async updateWorkOrder(workOrder: WorkOrder): Promise<Outcome<WorkOrder, unknown>> {
        const body = updateWorkOrderToApi(workOrder, this.idMapper)
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Patch,
            path: `/WorkOrder/WorkOrder/${workOrder.id}`,
            body,
        })
        if (!ApiService.isValidObjectResponse(response)) return Outcome.error(response)

        return Outcome.ok(workOrderFromApi(response.value, this.idMapper))
    }

    async closeWorkOrder(workOrder: CloseWorkOrderDto): Promise<Outcome<WorkOrder, unknown>> {
        const body = closeWorkOrderToApi(workOrder, this.idMapper)
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: `/Maintenance/WorkOrder/CloseWorkOrder`,
            body,
        })
        if (!ApiService.isValidObjectResponse(response)) return Outcome.error(response)

        return Outcome.ok(workOrderFromApi(response.value, this.idMapper))
    }

    async deleteWorkOrder(
        workOrderId: number,
        reason: string,
        workRequestsToDelete: number[]
    ): Promise<Outcome<void, unknown>> {
        const body = {
            workOrderId: workOrderId,
            changeReason: reason,
            workRequestToDismissIds: workRequestsToDelete,
        }
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Delete,
            path: `/WorkOrder/WorkOrder/`,
            body,
        })

        if (response.isError()) return Outcome.error(response)
        return Outcome.ok(undefined)
    }

    async fetchWorkOrdersForAsset(assetId: number): Promise<Outcome<WorkOrder[], unknown>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/WorkOrder/WorkOrder/GetWorkOrders",
            body: { assetId: [assetId], workOrderStatus: [1, 2, 3, 5] },
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)
        // if (response.value.length !== 1) return Outcome.error("Work Order Not Found")
        const workOrder = response.value.map((it) => workOrderFromApi(it, this.idMapper))

        if (response.isError()) return response
        return Outcome.ok(workOrder)
    }

    async fetchWorkOrderCounts(
        filters: Filter<WorkOrderFilter>[]
    ): Promise<Outcome<Record<WorkOrderQuickFilterCount, number>, unknown>> {
        const body = { ...this.createWorkOrderListFilterBody(filters) }
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/Maintenance/WorkOrderListSummary",
            body: body,
        })
        if (!ApiService.isValidObjectResponse(response)) return Outcome.error(response)
        return Outcome.ok(workOrderCountsFromApi(response.value))
    }

    async fetchWorkOrder(workOrderId: number): Promise<Outcome<WorkOrder, unknown>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/WorkOrder/WorkOrder/GetWorkOrders",
            body: {
                Id: workOrderId,
                WorkOrderStatus: [
                    numberForWorkOrderStatus(WorkOrderStatus.InProgress),
                    numberForWorkOrderStatus(WorkOrderStatus.Open),
                    numberForWorkOrderStatus(WorkOrderStatus.Pending),
                    numberForWorkOrderStatus(WorkOrderStatus.WorkCompleted),
                    numberForWorkOrderStatus(WorkOrderStatus.Closed),
                ],
            },
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)
        if (response.value.length !== 1) return Outcome.error("Work Order Not Found")
        const workOrder = workOrderFromApi(response.value[0], this.idMapper)

        if (response.isError()) return response
        return Outcome.ok(workOrder)
    }

    async fetchWorkOrderHistory(id: number): Promise<Outcome<HistoryItem<WorkOrderStatus>[]>> {
        const historyResponse = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/WorkOrder/WorkOrderHistoryView",
            query: { WorkOrderId: [id] },
        })

        if (!ApiService.isValidArrayResponse(historyResponse)) return Outcome.error(historyResponse)

        return Outcome.ok(historyListFromApi(historyResponse.value, this.idMapper))
    }

    async rollBackWorkOrder(workOrderId: number, meterSyncRecordId: number): Promise<Outcome<void, unknown>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: `/WorkOrder/WorkOrder/RollbackWorkOrder/`,
            body: {
                WorkOrderId: workOrderId,
                MeterSyncRecordIdToRollback: meterSyncRecordId,
            },
        })

        if (response.isError()) return Outcome.error(response)
        return Outcome.ok(undefined)
    }
}

function historyListFromApi(obj: any[], idMapper: CompanyFieldIdMapper): HistoryItem<WorkOrderStatus>[] {
    const result: HistoryItem<WorkOrderStatus>[] = Array(obj.length)
    let lastStatus: string | null = null

    for (let i = 0, l = result.length; i < l; i++) {
        var res = historyItemFromApi(obj[i], lastStatus, i === 0, idMapper)
        if (res.description.length > 0) {
            result[i] = res
            lastStatus = result[i].status
        }
    }

    return result.reverse()
}

function historyItemFromApi(
    obj: any,
    lastStatus: string | null,
    isCreatedEntry: boolean,
    idMapper: CompanyFieldIdMapper
): HistoryItem<WorkOrderStatus> {
    return {
        id: obj.id,
        primaryKey: obj.primaryKey,
        dateString: obj.dateTimeChangedString,
        date: obj.dateTimeChanged,
        description: obj.description,
        status: obj.status == null || obj.status == "" ? lastStatus : obj.status,
        userName: obj.user,
    }
}

function updateWorkOrderToApi(form: WorkOrder, idMapper: CompanyFieldIdMapper): any {
    return {
        assetId: form.assetId,
        workOrderStatusId: idMapper.fromWorkOrderStatus(form.status),
        mechanics: form.assignedTo && form.assignedTo !== -1 ? [{ personId: form.assignedTo }] : null,
        priority: idMapper.fromParentUrgency(form.urgency),
        targetDueDate: form.dueDate != null ? form.dueDate.toISOString() : null,
        dueHourMeter: nullNumOrDefault(form.dueHourMeter, null),
        dueOdometer: nullNumOrDefault(form.dueOdometer, null),
        specialInstructions: form.specialInstructions,
        siteId: form.siteId,
        workPerformedBy:
            form.workPerformedById && form.workPerformedById != null
                ? form.workPerformedById.map((i) => {
                      return { personId: i }
                  })
                : null,
        workCompletedDate: form.workCompletedDate != null ? form.workCompletedDate.toISOString() : null,
        completedHourMeter: nullNumOrDefault(form.completedHourMeter, null),
        completedOdometer: nullNumOrDefault(form.completedOdometer, null),
        openedDate: form.openedDate != null ? form.openedDate.toISOString() : null,
        travelTime: form.travelTime,
        travelDistance: form.travelDistance,
        nightWork: form.nightWork,
        cbaCodeID: form.cbaCode?.cbaCodeId,
        notifiedContacts: notifiedContactsToApi(form.notifyContacts),
    }
}

function closeWorkOrderToApi(closeWorkOrderDto: CloseWorkOrderDto, idMapper: CompanyFieldIdMapper): any {
    return {
        completedHourMeter: nullNumOrDefault(closeWorkOrderDto.completedHourMeter, null),
        completedOdometer: nullNumOrDefault(closeWorkOrderDto.completedOdometer, null),
        specialInstructions: closeWorkOrderDto.specialInstructions,
        ServiceDateTime: closeWorkOrderDto.serviceDateTime,
        siteId: closeWorkOrderDto.siteId,
        workPerformedByPersonId: closeWorkOrderDto.workPerformedByPersonId,
        dueOdometer: nullNumOrDefault(closeWorkOrderDto.dueOdometer, null),
        dueHourMeter: nullNumOrDefault(closeWorkOrderDto.dueHourMeter, null),
        dueDate: closeWorkOrderDto.dueDate != null ? closeWorkOrderDto.dueDate.toISOString() : null,
        priority: idMapper.fromParentUrgency(closeWorkOrderDto.urgency),
        assignedToPersonId: closeWorkOrderDto.assignedToPersonId,
        reasonTagged: closeWorkOrderDto.reasonTagged,
        tagStatus: closeWorkOrderDto.tagStatus,
        assetId: closeWorkOrderDto.assetId,
        workOrderId: closeWorkOrderDto.id,
        meterSync: closeWorkOrderDto.meterSync,
        workRequests: closeWorkOrderDto.workRequests,
        travelTime: closeWorkOrderDto.travelTime,
        travelDistance: closeWorkOrderDto.travelDistance,
        nightWork: closeWorkOrderDto.nightWork,
        cbaCodeID: closeWorkOrderDto.cbaCode?.cbaCodeId,
    }
}

function WorkOrderFormToAssignWorkRequestsToWorkOrderApi(
    idMapper: CompanyFieldIdMapper,
    workOrderId: number,
    assetId: number,
    form: AddWorkRequestToWorkOrderForm,
    selectedWorkRequests: number[]
): any {
    return {
        WorkOrderId: workOrderId,
        workRequestIds: selectedWorkRequests,
        assetId: assetId,
        urgencyId: idMapper.fromParentUrgency(form.urgency),
        dueDate: form.dueDate?.toISOString(),
        dueHourMeter: form.dueHourMeter != null && form.dueHourMeter != "" ? safeParseFloat(form.dueHourMeter) : null,
        dueOdometer: form.dueOdometer != null && form.dueOdometer != "" ? safeParseInt(form.dueOdometer) : null,
        specialInstructions: form.specialInstructions,
    }
}

function workOrderFormToApi(
    idMapper: CompanyFieldIdMapper,
    assetId: number,
    form: CreateWorkOrderForm,
    selectedWorkRequests: number[]
): any {
    return {
        workRequestIds: selectedWorkRequests,
        assetId: assetId,
        workOrderStatusId: idMapper.fromWorkOrderStatus(form.status),
        assignedMechanicIds: form.assignedTo != -1 ? [form.assignedTo] : null,
        urgencyId: idMapper.fromParentUrgency(form.urgency),
        targetDueDate: form.dueDate != null ? form.dueDate?.toISOString() : null,
        dueHourMeter: form.dueHourMeter != null && form.dueHourMeter != "" ? safeParseFloat(form.dueHourMeter) : null,
        dueOdometer: form.dueOdometer != null && form.dueOdometer != "" ? safeParseInt(form.dueOdometer) : null,
        specialNotes: form.specialInstructions,
        notifiedContacts: notifiedContactsToApi(form.notifyContacts),
    }
}

function workOrderTableRowFromApi(obj: any): WorkOrderTableRow {
    return {
        id: numOrDefault(obj.workOrderID, 0),
        assetName: stringOrDefault(obj.assetLabel, ""),
        assetId: numOrDefault(obj.assetID, 0),
        number: stringOrDefault(obj.workOrderNumber, ""),
        tag: tagFromApi(obj.tag),
        location: stringOrDefault(obj.location, ""),
        site: (() => {
            if (!obj.siteID || !obj.site) return null
            return {
                id: numOrDefault(obj.siteID, 0),
                name: stringOrDefault(obj.site, ""),
            }
        })(),
        hourMeter: numOrDefault(obj.hourMeter, 0),
        odometer: numOrDefault(obj.odometer, 0),
        daysInactive: numOrDefault(obj.daysInactive, 0),
        city: stringOrDefault(obj.city, ""),
        class: stringOrDefault(obj.assetClass, ""),
        daysOpen: numOrDefault(obj.daysOpen, 0),
        status: WorkOrderStatusFromApi(obj.status), //Re check - The value from the Api it's not correct
        urgency: UrgencyFromApi(obj.urgency),
        workToBePerformed: obj.workToBePerformed,
        dueDate: obj.dueDate != null ? dateFromApi(obj.dueDate) : null,
        dueHourMeter: obj.dueHourMeter,
        dueOdometer: obj.dueOdometer,
        hoursUntil: obj.hoursUntil,
        milesUntil: obj.milesUntil,
        daysUntil: numOrDefault(obj.daysUntil, 0),
        serviceCode: obj.serviceCode,
        subCompany: stringOrDefault(obj.subCompanyName ?? obj.companyName, ""),
        district: stringOrDefault(obj.district, ""),
        subDistrict: stringOrDefault(obj.subDistrict, ""),
        unit: stringOrDefault(obj.unit, ""),
        group: stringOrDefault(obj.group, ""),
        state: stringOrDefault(obj.state, ""),
        assignedTo: MechanicsFromApi(obj.mechanicsAssignedTo),
        closedDate: obj.closedDate != null ? dateFromApi(obj.closedDate) : null,
    }
}

function MechanicsFromApi(mechanics: any[]) {
    if (!mechanics || !Array.isArray(mechanics)) return []

    return arrayOfNotNull(
        mechanics.map((it: any) => {
            if (it.personId == null) return

            return {
                id: it.personId != null ? it.personId.toString() : "",
                name: it.firstName && it.lastName ? `${it.firstName} ${it.lastName}` : it.companyName,
            }
        })
    )
}

function WorkOrderStatusFromApi(value: any): WorkOrderStatus {
    switch (numOrDefault(value, 0)) {
        case 0:
            return WorkOrderStatus.Deleted
        case 1:
            return WorkOrderStatus.Pending
        case 2:
            return WorkOrderStatus.Open
        case 3:
            return WorkOrderStatus.InProgress
        case 4:
            return WorkOrderStatus.Closed
        case 5:
            return WorkOrderStatus.WorkCompleted
        default:
            return WorkOrderStatus.Open
    }
}

function workOrderFromApi(obj: any, idMapper: CompanyFieldIdMapper): WorkOrder {
    return {
        id: numOrDefault(obj.workOrderId, 0),
        assetId: numOrDefault(obj.assetId, 0),
        status: WorkOrderStatusFromApi(obj.workOrderStatusId),
        number: stringOrDefault(obj.number, ""),
        urgency: idMapper.toUrgency(numOrDefault(obj.priority, 0)),
        attachments: [], // TODO: API needs to implement this
        tag: null, // TODO: API needs to implement this
        daysOpen: numOrDefault(obj.daysOpen, 0), // TODO: API needs to implement this
        daysInactive: numOrDefault(obj.daysInactive, 0), // TODO: API needs to implement this
        dueDate: nullableDateFromApi(obj.targetDueDate),
        dueHourMeter: nullNumOrDefault(obj.dueHourMeter, null),
        dueOdometer: nullNumOrDefault(obj.dueOdometer, null),
        assignedTo: obj.mechanics && obj.mechanics.length !== 0 ? obj.mechanics[0].personId : 0,
        specialInstructions: stringOrDefault(obj.specialInstructions, ""),
        history: [], // TODO: API needs to implement this
        workPerformedById:
            obj.workPerformedBy && obj.workPerformedBy.length !== 0
                ? obj.workPerformedBy.map((i: any) => i.personId)
                : null,
        siteId: nullNumOrDefault(obj.siteId, null),
        workCompletedDate: nullableDateFromApi(obj.workCompletedDate),
        completedHourMeter: nullNumOrDefault(obj.completedHourMeter, null),
        completedOdometer: nullNumOrDefault(obj.completedOdometer, null),
        openedDate: dateFromApi(obj.openedDate),
        closedDate: obj.closedDate !== null ? dateFromApi(obj.closedDate) : null,
        cbaCode: { code: "", name: "", cbaCodeId: obj.cbaCodeID },
        travelDistance: obj.travelDistance,
        travelTime: obj.travelTime,
        nightWork: obj.nightWork,
        notifyContacts: notifiedContactsFromApi(obj.notifiedContacts),
        serviceRecord: obj.serviceRecord,
    }
}

function workOrderCountsFromApi(obj: any): Record<WorkOrderQuickFilterCount, number> {
    return {
        [WorkOrderQuickFilterCount.WorkOrders]: numOrDefault(obj.quantityOfWorkOrders, 0),
        [WorkOrderQuickFilterCount.Open]: numOrDefault(obj.quantityOfOpen, 0),
        [WorkOrderQuickFilterCount.Pending]: numOrDefault(obj.quantityOfPending, 0),
        [WorkOrderQuickFilterCount.InProgress]: numOrDefault(obj.quantityOfInProgress, 0),
        [WorkOrderQuickFilterCount.Completed]: numOrDefault(obj.quantityOfCompleted, 0),
        [WorkOrderQuickFilterCount.Overdue]: numOrDefault(obj.quantityOfOverdues, 0),
        [WorkOrderQuickFilterCount.RedTag]: numOrDefault(obj.quantityOfRedTags, 0),
        [WorkOrderQuickFilterCount.YellowTag]: numOrDefault(obj.quantityOfYellowTags, 0),
        [WorkOrderQuickFilterCount.Immediate]: numOrDefault(obj.quantityOfImmediates, 0),
        [WorkOrderQuickFilterCount.High]: numOrDefault(obj.quantityOfHigh, 0),
        [WorkOrderQuickFilterCount.Medium]: numOrDefault(obj.quantityOfMedium, 0),
        [WorkOrderQuickFilterCount.Low]: numOrDefault(obj.quantityOfLow, 0),
    }
}

function WorkOrderTableColumnToWorkOrderTableRowField(column: any): any {
    switch (column) {
        case WorkOrderTableColumn.Tag:
            return "Tag.Type,Tag.DateTagged"
        case WorkOrderTableColumn.WorkOrder:
            return "WorkOrderNumber"
        case WorkOrderTableColumn.Asset:
            return "AssetIdentifier.Length,AssetIdentifier"
        case WorkOrderTableColumn.Status:
            return "Status"
        case WorkOrderTableColumn.Urgency:
            return "UrgencyName"
        case WorkOrderTableColumn.DaysOpen:
            return "DaysOpen"
        case WorkOrderTableColumn.Location:
            return "LastKnownLocation"
        case WorkOrderTableColumn.WorkToBePerformed:
            return "WorkToBePerformedSort"
        case WorkOrderTableColumn.DueDate:
            return "DueDate"
        case WorkOrderTableColumn.HoursUntil:
            return "HoursUntil"
        case WorkOrderTableColumn.MilesUntil:
            return "MilesUntil"
        case WorkOrderTableColumn.DaysUntil:
            return "DaysUntil"
        case WorkOrderTableColumn.HourMeter:
            return "HourMeter"
        case WorkOrderTableColumn.Odometer:
            return "Odometer"
        case WorkOrderTableColumn.DaysInactive:
            return "DaysInactive"
        case WorkOrderTableColumn.ServiceCode:
            return "ServiceCodeSort"
        case WorkOrderTableColumn.Class:
            return "AssetClass"
        case WorkOrderTableColumn.SubCompany:
            return "SubCompanyName"
        case WorkOrderTableColumn.District:
            return "District"
        case WorkOrderTableColumn.SubDistrict:
            return "SubDistrict"
        case WorkOrderTableColumn.Unit:
            return "Unit"
        case WorkOrderTableColumn.Group:
            return "Group"
        case WorkOrderTableColumn.City:
            return "City"
        case WorkOrderTableColumn.State:
            return "State"
        case WorkOrderTableColumn.AssignedTo:
            return "MechanicsAssignedToSort"
        case WorkOrderTableColumn.ClosedDate:
            return "ClosedDate"
    }
}
