export type Tag = {
    type: TagType
    dateTagged: Date
    daysTagged: number
    reason: string
}

export enum TagType {
    None = "none",
    Red = "redTag",
    Yellow = "yellowTag",
}

export function mostSignificantTag(a: Tag | null, b: Tag | null): Tag | null {
    if (a === null && b === null) return null
    if (a === null) return b
    if (b === null) return a

    if (a.type === b.type) {
        return a.daysTagged > b.daysTagged ? a : b
    } else {
        if (a.type === TagType.Red) return a
        if (b.type === TagType.Red) return b
    }

    return null
}

export enum TagChange {
    RemoveTag = "noTag",
    RedTag = "redTag",
    YellowTag = "YellowTag",
}

export function tagChangeFromTag(tag: Tag | null): TagChange {
    if (tag === null) return TagChange.RemoveTag
    switch (tag.type) {
        case TagType.None:
            return TagChange.RemoveTag
        case TagType.Red:
            return TagChange.RedTag
        case TagType.Yellow:
            return TagChange.YellowTag
    }
}

export function tagChangeFromTagType(type: TagType | null): TagChange {
    if (type === null) return TagChange.RemoveTag
    switch (type) {
        case TagType.None:
            return TagChange.RemoveTag
        case TagType.Red:
            return TagChange.RedTag
        case TagType.Yellow:
            return TagChange.YellowTag
    }
}

export function tagChangeToTagType(change: TagChange): TagType {
    if (change === TagChange.RemoveTag) return TagType.None
    switch (change) {
        case TagChange.YellowTag:
            return TagType.Yellow
        case TagChange.RedTag:
            return TagType.Red
    }
}
