import { classNames } from "@lib/HtmlUtil"
import React from "react"

export type PageInfoSectionProps = {
    title?: string | React.ReactNode
    children?: React.ReactNode
    className?: string
}

export function PageInfoSection(props: PageInfoSectionProps): JSX.Element {
    return (
        <div className={classNames("page-info-section", props.className)}>
            {props.title && <div className="page-info-section-title">{props.title}</div>}
            {props.children}
        </div>
    )
}

export function PageInfoSectionDataFields({ children }: { children?: React.ReactNode }): JSX.Element {
    return <div className="page-info-section-data-fields">{children}</div>
}
