import { Outcome } from "@ethossoftworks/outcome"
import { SortOrder } from "@lib/Comparable"
import {
    arrayOfNotNull,
    boolOrDefault,
    nullNumOrDefault,
    numOrDefault,
    safeParseDate,
    safeParseFloat,
    safeParseInt,
    stringOrDefault,
} from "@lib/TypeUtil"
import { Attachment, AttachmentType } from "@model/Attachment"
import {
    Filter,
    isEmptyFilterValue,
    numberArrayFilterValue,
    textArrayFilterValue,
    textFilterValue,
} from "@model/filters/Filter"
import { HistoryItem } from "@model/HistoryItem"
import { PagedResponse, Pagination } from "@model/Pagination"
import {
    ApiApproveServiceQuote,
    ApiServiceQuote,
    ApiServiceQuoteItem,
    ApiServiceQuoteStatus,
    ApiServiceQuoteVendor,
    ApproveServiceQuoteForm,
    numberForServiceQuoteStatus,
    ServiceQuote,
    ServiceQuoteFilter,
    ServiceQuoteItem,
    ServiceQuoteQuickFilterCount,
    ServiceQuotesTableColumn,
    ServiceQuoteStatus,
    ServiceQuoteTableRow,
    ServiceQuoteVendor,
} from "@model/serviceQuotes/ServiceQuote"
import {
    ApiService,
    dateFromApi,
    HttpMethod,
    notifiedContactsFromApi,
    notifiedContactsToApi,
    paginationFromApi,
    paginationToApi,
    sortToApi,
} from "@service/ApiService"
import { CompanyFieldIdMapper } from "@service/company/CompanyService"
import { ServiceQuoteService } from "./ServiceQuoteService"
export class MainServiceQuoteService implements ServiceQuoteService {
    readonly serviceQuotefileUploadContainerName: string = "ServiceQuote"
    readonly serviceQuoteItemfileUploadContainerName: string = "ServiceQuoteItem"

    constructor(private apiService: ApiService, private idMapper: CompanyFieldIdMapper) {}

    async fetchServiceQuotes(
        filters?: Filter<ServiceQuoteFilter>[],
        sort?: { column: ServiceQuotesTableColumn; order: SortOrder },
        pagination?: Pagination
    ): Promise<
        Outcome<PagedResponse<ServiceQuoteTableRow[]> & { counts: Record<ServiceQuoteQuickFilterCount, number> }>
    > {
        const body = {
            ...paginationToApi(pagination),
            ...this.createServiceQuoteListFilterBody(filters),
            ...this.sortServiceQuoteToApi(sort),
        }

        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/maintenance/ServiceQuoteGrid",
            body,
        })
        if (!ApiService.isValidPagedResponse(response)) return Outcome.error(response)

        return Outcome.ok({
            data: response.value.results.map((it) => this.serviceQuoteTableRowFromApi(it)),
            pagination: paginationFromApi(response.value),
            counts: this.serviceQuoteCountsFromApi(response.value.summary),
        })
    }
    async updateServiceQuote(serviceQuote: ServiceQuote): Promise<Outcome<ServiceQuote, unknown>> {
        const body = this.updateServiceQuoteToApi(serviceQuote, this.idMapper)
        const response = await this.apiService.makeRequest<ApiServiceQuote>({
            method: HttpMethod.Put,
            path: `/WorkOrder/ServiceQuote/UpdateServiceQuote/${serviceQuote.id}`,
            body,
        })
        if (!ApiService.isValidObjectResponse(response)) return Outcome.error(response)

        return Outcome.ok(this.serviceQuoteFromApi(response.value, this.idMapper))
    }

    async verifyServiceQuoteAsset(serviceQuoteID: number, assetId: number): Promise<Outcome<unknown, unknown>> {
        const response = await this.apiService.makeRequest<ApiServiceQuote[]>({
            method: HttpMethod.Post,
            path: "/WorkOrder/ServiceQuote/VerifyServiceQuoteAsset",
            body: {
                ServiceQuoteID: serviceQuoteID,
                AssetId: assetId,
            },
        })
        if (!ApiService.isValidObjectResponse(response)) return Outcome.error(response)
        return Outcome.ok(response.value)
    }

    async fetchServiceQuote(id: number): Promise<Outcome<ServiceQuote, unknown>> {
        const response = await this.apiService.makeRequest<ApiServiceQuote[]>({
            method: HttpMethod.Post,
            path: "/WorkOrder/ServiceQuote/GetServiceQuotes",
            body: { Id: id },
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)
        if (response.value.length !== 1) return Outcome.error("Work Order Not Found")
        const workOrder = this.serviceQuoteFromApi(response.value[0], this.idMapper)

        if (response.isError()) return response
        return Outcome.ok(workOrder)
    }

    async getServiceQuotAssociatedSchedules(workOrderId: number): Promise<Outcome<number[], unknown>> {
        const response = await this.apiService.makeRequest<ApiServiceQuote[]>({
            method: HttpMethod.Post,
            path: "/WorkOrder/ServiceQuote/GetServiceQuotes",
            body: { workOrderId: workOrderId },
        })

        if (response.isError()) return Outcome.error(response)
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        if (response.value.length !== 0) {
            const serviceQuote = this.serviceQuoteFromApi(response.value[0], this.idMapper)

            if (serviceQuote.assetSchedules === undefined) return Outcome.ok([])

            return Outcome.ok(serviceQuote.assetSchedules)
        }

        return Outcome.ok([])
    }

    async ApproveServiceQuote(
        approveServiceQuoteForm: ApproveServiceQuoteForm
    ): Promise<Outcome<ServiceQuote, unknown>> {
        const body = this.ApproveServiceQuoteToApi(approveServiceQuoteForm, this.idMapper)
        const response = await this.apiService.makeRequest<ApiServiceQuote>({
            method: HttpMethod.Post,
            path: `/WorkOrder/ServiceQuote/ApproveServiceQuote`,
            body,
        })
        if (!ApiService.isValidObjectResponse(response)) return Outcome.error(response)

        return Outcome.ok(this.serviceQuoteFromApi(response.value, this.idMapper))
    }

    async fetchServiceQuoteXls(serviceQuoteId: number): Promise<Outcome<Blob>> {
        const response = await this.apiService.makeDownloadRequest({
            method: HttpMethod.Get,
            path: "/WorkOrder/ServiceQuote/ServiceQuoteDetailsAsXLS",
            query: { serviceQuoteID: serviceQuoteId },
        })
        if (!ApiService.isValidObjectResponse(response) || response.value == undefined) return Outcome.error(response)
        return Outcome.ok(response.value)
    }

    private updateServiceQuoteToApi(form: ServiceQuote, idMapper: CompanyFieldIdMapper): ApiServiceQuote {
        return {
            serviceQuoteID: form.id,
            companyID: form.companyId,
            workOrderID: form.workOrderId,
            serviceQuoteStatusID: numberForServiceQuoteStatus(form.Status),
            purchaseOrderNumber: form.purchaseOrderNumber,
            vendorID: form.vendorId,
            assetId: form.assetId,
            serviceQuoteNumber: form.number,
            name: form.name,
            creationDateTime: form.creationDateTime,
            processedDateTime: form.processedDateTime,
            quoteAssetVerified: form.quoteAssetVerified,
            serviceQuoteDate: form.serviceQuoteDate,
            serviceQuoteExpirationDate: form.serviceQuoteExpirationDate,
            taxRate: form.taxRate,
            subtotal: form.subtotal,
            taxes: form.taxes,
            otherCharges: form.otherCharges,
            fileID: form.fileId,
            totalLaborHours: form.totalLaborHours,
            totalCost: form.totalCost,
            totalLaborCost: form.totalLaborCost,
            assetEquipmentNumber: form.assetEquipmentNumber,
            assetSerialNumber: form.assetSerialNumber,
            responseEmailAddress: form.responseEmailAddress,
            rejectionReason: form.rejectionReason,
            serviceQuoteItems: form.serviceQuoteItems?.map((x) => this.serviceQuoteItemToApi(x)),
            assignedTo: form.assignedTo,
            vendor: null, //this isn't needed to be sent back to the API
            serviceQuoteStatus: form.serviceQuoteStatusId,
            notifiedContacts: notifiedContactsToApi(form.notifyContacts),
            vendorMessage: form.vendorMessage,
            associatedSchedules: this.assetScheduleToApi(form.assetSchedules),
        }
    }

    private ApproveServiceQuoteToApi(
        form: ApproveServiceQuoteForm,
        idMapper: CompanyFieldIdMapper
    ): ApiApproveServiceQuote {
        return {
            serviceQuoteId: form.serviceQuoteId,
            purchaseOrderNumber: form.purchaseOrderNumber,
            serviceQuoteItems: form.serviceQuoteItems.map((x) => this.serviceQuoteItemToApi(x)),
            vendorMessage: form.vendorMessage,
            notifiedContacts: notifiedContactsToApi(form.notifyContacts),
            createWorkOrder: {
                assetId: form.assetId,
                workRequestIds: [],
                workOrderStatusId: idMapper.fromWorkOrderStatus(form.workOrderStatus),
                assignedMechanicIds: form.workOrderAssignedTo != -1 ? [form.workOrderAssignedTo] : null,
                urgencyId: idMapper.fromParentUrgency(form.workOrderUrgency),
                targetDueDate: form.workOrderDueDate != null ? form.workOrderDueDate?.toISOString() : null,
                dueHourMeter:
                    form.workOrderDueHourMeter != null && form.workOrderDueHourMeter != ""
                        ? safeParseFloat(form.workOrderDueHourMeter)
                        : null,
                dueOdometer:
                    form.workOrderDueOdometer != null && form.workOrderDueOdometer != ""
                        ? safeParseInt(form.workOrderDueOdometer)
                        : null,
                specialNotes: form.workOrderSpecialInstructions,
                notifiedContacts: "",
            },
        }
    }

    private serviceQuoteFromApi(obj: ApiServiceQuote, idMapper: CompanyFieldIdMapper): ServiceQuote {
        return {
            id: numOrDefault(obj.serviceQuoteID, 0),
            companyId: numOrDefault(obj.companyID, 0),
            workOrderId: nullNumOrDefault(obj.workOrderID, null),
            assetId: numOrDefault(obj.assetId, 0),
            name: stringOrDefault(obj.name, ""),
            vendorId: numOrDefault(obj.vendorID, 0),
            number: stringOrDefault(obj.serviceQuoteNumber, ""),
            assetEquipmentNumber: stringOrDefault(obj.assetEquipmentNumber, ""),
            assetSerialNumber: stringOrDefault(obj.assetSerialNumber, ""),
            creationDateTime: obj.creationDateTime,
            fileId: stringOrDefault(obj.fileID, ""),
            otherCharges: null,
            processedDateTime: null,
            purchaseOrderNumber: "",
            quoteAssetVerified: boolOrDefault(obj.quoteAssetVerified, false),
            quoteHistory: null,
            rejectionReason: stringOrDefault(obj.rejectionReason, ""),
            vendor: this.serviceQuoteVendorFromApi(obj.vendor),
            responseEmailAddress: stringOrDefault(obj.responseEmailAddress, ""),
            serviceQuoteDate: obj.serviceQuoteDate,
            serviceQuoteExpirationDate: obj.serviceQuoteExpirationDate,
            serviceQuoteStatusId: numOrDefault(obj.serviceQuoteStatusID, 0),
            subtotal: 0,
            taxes: numOrDefault(obj.vendorID, 0),
            taxRate: numOrDefault(obj.vendorID, 0),
            totalCost: numOrDefault(obj.totalCost, 0),
            totalLaborCost: numOrDefault(obj.totalLaborCost, 0),
            totalLaborHours: numOrDefault(obj.totalLaborHours, 0),
            Status: this.serviceQuoteStatusFromApi(obj.serviceQuoteStatusID),
            serviceQuoteItems: obj.serviceQuoteItems.map((it) => this.serviceQuoteItemFromApi(it)),
            assignedTo: obj.assignedTo,
            notifyContacts: notifiedContactsFromApi(obj.notifiedContacts),
            vendorMessage: obj.vendorMessage,
            assetSchedules: this.assetScheduleFromApi(obj.associatedSchedules),
        }
    }

    private serviceQuoteVendorFromApi(obj: ApiServiceQuoteVendor | null): ServiceQuoteVendor | null {
        if (obj == null) {
            return null
        }
        return {
            id: numOrDefault(obj.vendorId, 0),
            VendorName: obj.vendorName,
            Description: obj.description,
            PrimaryContactEmail: obj.primaryContactEmail,
            PrimaryContactName: obj.primaryContactName,
            PrimaryContactPhoneNumber: obj.primaryContactPhoneNumber,
            PrimaryResponseEmailAddress: obj.primaryResponseEmailAddress,
        }
    }

    private serviceQuoteItemFromApi(obj: ApiServiceQuoteItem): ServiceQuoteItem {
        return {
            id: obj.serviceQuoteItemID,
            status: this.serviceQuoteStatusFromApi(obj.serviceQuoteStatusID),
            serviceQuoteId: obj.serviceQuoteID,
            serviceCodeId: obj.serviceCodeID,
            lineItemNumber: obj.lineItemNumber,
            descriptionWorkToBePerformed: stringOrDefault(obj.descriptionWorkToBePerformed, ""),
            mechanicsNotes: obj.mechanicsNotes,
            laborHours: numOrDefault(obj.laborHours, 0),
            laborRate: numOrDefault(obj.laborRate, 0),
            totalLaborCost: numOrDefault(obj.totalLaborCost, 0),
            totalMiscCost: numOrDefault(obj.totalMiscCost, 0),
            lineItemSubtotal: numOrDefault(obj.lineItemSubtotal, 0),
            taxExemptAmount: numOrDefault(obj.taxExemptAmount, 0),
            environmentalCharge: obj.environmentalCharge,
            workRequestId: obj.workRequestID,
            rejectionReason: obj.rejectionReason,
            totalPartsCost: obj.totalPartsCost,
            rejectionDate: safeParseDate(obj.rejectionDate),
            fileId: obj.fileID,
        }
    }

    private serviceQuoteItemToApi(obj: ServiceQuoteItem): ApiServiceQuoteItem {
        return {
            serviceQuoteItemID: obj.id,
            serviceQuoteStatusID: numberForServiceQuoteStatus(obj.status),
            serviceQuoteID: obj.serviceQuoteId,
            serviceCodeID: obj.serviceCodeId,
            lineItemNumber: obj.lineItemNumber,
            descriptionWorkToBePerformed: stringOrDefault(obj.descriptionWorkToBePerformed, ""),
            mechanicsNotes: obj.mechanicsNotes,
            laborHours: numOrDefault(obj.laborHours, 0),
            laborRate: numOrDefault(obj.laborRate, 0),
            totalLaborCost: numOrDefault(obj.totalLaborCost, 0),
            totalMiscCost: numOrDefault(obj.totalMiscCost, 0),
            lineItemSubtotal: numOrDefault(obj.lineItemSubtotal, 0),
            taxExemptAmount: numOrDefault(obj.taxExemptAmount, 0),
            environmentalCharge: obj.environmentalCharge,
            workRequestID: obj.workRequestId,
            rejectionReason: obj.status !== ServiceQuoteStatus.Declined ? null : obj.rejectionReason,
            totalPartsCost: obj.totalPartsCost,
            rejectionDate: obj.status !== ServiceQuoteStatus.Declined ? null : obj.rejectionDate,
            fileID: obj.fileId,
        }
    }

    private serviceQuoteStatusFromApi(value: ApiServiceQuoteStatus): ServiceQuoteStatus {
        switch (value) {
            case ApiServiceQuoteStatus.Pending:
                return ServiceQuoteStatus.Pending
            case ApiServiceQuoteStatus.Declined:
                return ServiceQuoteStatus.Declined
            case ApiServiceQuoteStatus.RevisionRequested:
                return ServiceQuoteStatus.RevisionRequested
            case ApiServiceQuoteStatus.ConvertedToWO:
                return ServiceQuoteStatus.Approved
            default:
                return ServiceQuoteStatus.Pending
        }
    }

    async fetchServiceQuoteHistory(id: number): Promise<Outcome<HistoryItem<ServiceQuoteStatus>[]>> {
        const historyResponse = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/WorkOrder/ServiceQuoteHistoryView",
            query: { ServiceQuoteId: [id] },
        })

        if (!ApiService.isValidArrayResponse(historyResponse)) return Outcome.error(historyResponse)

        return Outcome.ok(this.historyListFromApi(historyResponse.value, this.idMapper))
    }

    async fetchServiceQuoteAttachments(id: string): Promise<Outcome<Attachment[]>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/FileManagement/GetFileUris",
            query: { id: id, containerName: this.serviceQuotefileUploadContainerName },
        })

        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        if (response.isError()) return response
        const result: Attachment[] = this.attachmentsFromApi(response)
        return Outcome.ok(result)
    }

    async fetchServiceQuoteItemAttachments(id: string): Promise<Outcome<Attachment[]>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/FileManagement/GetFileUris",
            query: { id: id, containerName: this.serviceQuoteItemfileUploadContainerName },
        })

        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        if (response.isError()) return response
        const result: Attachment[] = this.attachmentsFromApi(response)
        return Outcome.ok(result)
    }

    attachmentsFromApi(obj: any): Attachment[] {
        let attachments: Attachment[] = []
        attachments = obj.value.map((attachmentUrl: any): Attachment => {
            const isImgLink = attachmentUrl.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gim) !== null
            let attachmentName = decodeURIComponent(attachmentUrl.split("/").pop().split("#")[0].split("?")[0])
            return {
                name: attachmentName,
                url: attachmentUrl,
                type: isImgLink ? AttachmentType.Image : AttachmentType.File,
            }
        })
        return attachments
    }

    private historyListFromApi(obj: any[], idMapper: CompanyFieldIdMapper): HistoryItem<ServiceQuoteStatus>[] {
        const result: HistoryItem<ServiceQuoteStatus>[] = Array(obj.length)
        let lastStatus: string | null = null

        for (let i = 0, l = result.length; i < l; i++) {
            var res = this.historyItemFromApi(obj[i], lastStatus, i === 0, idMapper)
            if (res.description.length > 0) {
                result[i] = res
                lastStatus = result[i].status
            }
        }

        return result.reverse()
    }

    private historyItemFromApi(
        obj: any,
        lastStatus: string | null,
        isCreatedEntry: boolean,
        idMapper: CompanyFieldIdMapper
    ): HistoryItem<ServiceQuoteStatus> {
        return {
            id: obj.id,
            primaryKey: obj.primaryKey,
            dateString: obj.dateTimeChangedString,
            date: obj.dateTimeChanged,
            description: obj.description,
            status: obj.status == null || obj.status == "" ? lastStatus : obj.status,
            userName: obj.user,
        }
    }

    private assetScheduleToApi(schedulesIds: number[] | undefined): string | null {
        return schedulesIds && schedulesIds.length > 0 ? JSON.stringify(schedulesIds) : null
    }

    private assetScheduleFromApi(schedulesIds: string | null): number[] | undefined {
        return schedulesIds ? JSON.parse(schedulesIds) : null
    }

    private sortServiceQuoteToApi = (sort?: any): Record<string, any> | void => {
        if (sort)
            return sortToApi({
                column: this.serviceQuoteTableColumnToWorkOrderTableRowField(sort.column),
                order: sort.order,
            })
    }

    private createServiceQuoteListFilterBody(filters?: Filter<ServiceQuoteFilter>[]): Record<string, any> {
        const filterBody: Record<string, any> = {}
        filters?.forEach((filter) => {
            if (isEmptyFilterValue(filter)) return

            switch (filter.definition.type) {
                case ServiceQuoteFilter.ServiceQuoteId:
                    filterBody.serviceQuoteNumber = textArrayFilterValue(filter)
                    break
                case ServiceQuoteFilter.Asset:
                    filterBody.asset = numberArrayFilterValue(filter)
                    break
                case ServiceQuoteFilter.VendorID:
                    filterBody.vendorID = numberArrayFilterValue(filter)
                    break

                case ServiceQuoteFilter.Contact:
                    filterBody.contact = numberArrayFilterValue(filter)
                    break
                case ServiceQuoteFilter.AssignedTo:
                    filterBody.assignedTo = textFilterValue(filter)
                    break

                case ServiceQuoteFilter.TotalCostFilterOption:
                    filterBody.totalCost = textArrayFilterValue(filter)
                    break
                case ServiceQuoteFilter.CreationDateTimeFilterOption:
                    filterBody.creationDateTimeFilterOption = textArrayFilterValue(filter)
                    break
                case ServiceQuoteFilter.Status:
                    filterBody.ServiceQuoteStatusID = numberArrayFilterValue(filter)
                    break

                case ServiceQuoteFilter.LastKnownLocation:
                    filterBody.lastKnownLocation = numberArrayFilterValue(filter)
                    break
                case ServiceQuoteFilter.Search:
                    filterBody.globalSearchQuery = textFilterValue(filter)

                    break
                case ServiceQuoteFilter.WOSearch:
                    filterBody.woSearch = textFilterValue(filter)
                    break
            }
        })

        return filterBody
    }

    private serviceQuoteTableRowFromApi(obj: any): ServiceQuoteTableRow {
        return {
            id: numOrDefault(obj.serviceQuoteId, 0),
            asset: stringOrDefault(obj.assetName, ""),
            assetId: numOrDefault(obj.fK_AssetID, 0),
            workOrderId: numOrDefault(obj.fk_WorkOrderID, 0),
            serviceQuoteStatusId: numOrDefault(obj.fK_ServiceQuoteStatusID, 0),
            purchaseOrderNumber: stringOrDefault(obj.purchaseOrderNumber, ""),
            vendorId: numOrDefault(obj.vendorID, 0),
            name: stringOrDefault(obj.name, ""),
            processedDateTime: obj.processedDateTime != null ? dateFromApi(obj.processedDateTime) : null,
            quoteAssetVerified: boolOrDefault(obj.quoteAssetVerified, false),
            quoteHistory: stringOrDefault(obj.quoteHistory, ""),
            creationDateTime: obj.creationDateTime != null ? dateFromApi(obj.creationDateTime) : null,
            serviceQuoteExpirationDate:
                obj.serviceQuoteExpirationDate != null ? dateFromApi(obj.serviceQuoteExpirationDate) : null,
            taxRate: numOrDefault(obj.taxRate, 0),
            subtotal: numOrDefault(obj.subtotal, 0),
            taxes: numOrDefault(obj.taxes, 0),
            otherCharges: numOrDefault(obj.otherCharges, 0),
            fileId: stringOrDefault(obj.fileID, ""),
            totalLaborHours: numOrDefault(obj.totalLaborHours, 0),
            totalLaborCost: numOrDefault(obj.totalLaborCost, 0),
            assetEquipmentNumber: stringOrDefault(obj.assetEquipmentNumber, ""),
            assetSerialNumber: stringOrDefault(obj.assetSerialNumber, ""),
            responseEmailAddress: stringOrDefault(obj.responseEmailAddress, ""),
            rejectionReason: stringOrDefault(obj.rejectionReason, ""),
            number: numOrDefault(obj.number, 0),
            companyId: numOrDefault(obj.fK_CompanyID, 0),
            assignedToId: numOrDefault(obj.assignedTo, 0),
            vendor: obj.vendor !== "" ? this.extractVendorProperty(obj.vendor, "VendorName") : "",
            contact: obj.vendor !== "" ? this.extractVendorProperty(obj.vendor, "PrimaryContactEmail") : "",
            assignedTo: this.MechanicsFromApi(obj.assignedTo)[0]?.name || "",
            serviceQuoteStatus: this.serviceQuoteStatusFromApi(obj.fK_ServiceQuoteStatusID),
            totalCost: numOrDefault(obj.totalCost, 0),
            dateReceived: obj.serviceQuoteDate != null ? dateFromApi(obj.serviceQuoteDate) : null,
            lastKnownLocation: stringOrDefault(obj.lastKnownLocation, ""),
            assignedToName: stringOrDefault(obj.assignedToName, ""),
        }
    }

    private serviceQuoteTableColumnToWorkOrderTableRowField(column: ServiceQuotesTableColumn): string | null {
        switch (column) {
            case ServiceQuotesTableColumn.ServiceQuote:
                return "ServiceQuoteId"
            case ServiceQuotesTableColumn.Vendor:
                return "VendorID"
            case ServiceQuotesTableColumn.Contact:
                return "Contact"
            case ServiceQuotesTableColumn.ServiceQuoteStatus:
                return "ServiceQuoteStatusID"
            case ServiceQuotesTableColumn.TotalCost:
                return "TotalCost"
            case ServiceQuotesTableColumn.DateReceived:
                return "DateRecieved"
            case ServiceQuotesTableColumn.LastKnownLocation:
                return "LastKnownLocation"
            case ServiceQuotesTableColumn.Asset:
                return "AssetId"
            case ServiceQuotesTableColumn.AssignedTo:
                return "AssignedTo"
            default:
                return null
        }
    }

    private serviceQuoteCountsFromApi(obj: any): Record<ServiceQuoteQuickFilterCount, number> {
        return {
            [ServiceQuoteQuickFilterCount.ServiceQuotes]: numOrDefault(obj.quantityOfServiceQuotes, 0),

            [ServiceQuoteQuickFilterCount.DaysOpenSubSeven]: numOrDefault(obj.quantityOfLessThanSeven, 0),
            [ServiceQuoteQuickFilterCount.DaysOpenSevenToThirty]: numOrDefault(obj.quantityOfSevenToThirty, 0),
            [ServiceQuoteQuickFilterCount.DaysOpenThirtyToNinety]: numOrDefault(obj.quantityOfThirtyToNinety, 0),
            [ServiceQuoteQuickFilterCount.DaysOpenOverNinety]: numOrDefault(obj.quantityOfGreaterThanNinety, 0),
            [ServiceQuoteQuickFilterCount.TotalCostSubOneThousand]: numOrDefault(obj.quantityOfSubOneThousand, 0),
            [ServiceQuoteQuickFilterCount.TotalCostOneToTenThousand]: numOrDefault(obj.quantityOfOneToTenThousand, 0),
            [ServiceQuoteQuickFilterCount.TotalCostTenToTwentyFiveThousand]: numOrDefault(
                obj.quantityOfTenToTwentyFiveThousand,
                0
            ),
            [ServiceQuoteQuickFilterCount.TotalCostOverTentyFiveThousand]: numOrDefault(
                obj.quantityOfGreaterThanTwentyFiveThousand,
                0
            ),
        }
    }

    private MechanicsFromApi(mechanics: any[]): { id: string; name: string }[] {
        if (!mechanics || !Array.isArray(mechanics)) return []

        return arrayOfNotNull(
            mechanics.map((it: any) => {
                if (it.personId == null) return

                return {
                    id: it.personId != null ? it.personId.toString() : "",
                    name: it.firstName && it.lastName ? `${it.firstName} ${it.lastName}` : it.companyName,
                }
            })
        )
    }

    private extractVendorProperty(vendorString: string, propertyName: string): string {
        const regex = new RegExp(`${propertyName}: ([^,]*)`)
        const match = vendorString.match(regex)
        return match ? match[1].trim() : ""
    }
}
