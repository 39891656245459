import React, { CSSProperties } from "react"
import { EstimatedCostPDFSection } from "./components/EstimatedCostPDFSection"
import { NotesPDFSection } from "./components/NotesPDFSection"
import { PDFPage, PDFSectionBorder } from "./components/PDF"
import { PDFCheck } from "./components/PDFCheck"
import { PDFHeader } from "./components/PDFHeader"
import { PDFPageHeader } from "./components/PDFPageHeader"
import { PDFHeadedSection } from "./components/PDFSection"
import { WorkRequestStatusPDFSection } from "./components/WorkRequestStatusPDFSection"
import { PDFAsset } from "./models/PDFAsset"
import { isClosed, pdfHeaderTitleForWorkOrder, PDFWorkOrder, PDFWorkOrderStatus } from "./models/PDFWorkOrder"
import { PDFWorkRequest, PDFWorkRequestTask } from "./models/PDFWorkRequest"

export type InspectionWorkRequestPDFProps = {
    workRequest: PDFWorkRequest
    asset: PDFAsset
    workOrder?: PDFWorkOrder
    companyName: string
    companyLogoUrl: string | null
}

export function InspectionWorkRequestPDF({
    workRequest,
    asset,
    workOrder,
    companyName,
    companyLogoUrl,
}: InspectionWorkRequestPDFProps): JSX.Element {
    const taskCheckStyle: CSSProperties = {
        transform: "rotate(-45deg)",
        height: 12,
        lineHeight: "14px",
        whiteSpace: "nowrap",
        fontSize: "1.2rem",
    }

    return (
        <PDFPage>
            <PDFPageHeader title={workOrder ? pdfHeaderTitleForWorkOrder(workOrder) : ""} companyName={companyName} />
            <PDFHeader
                title={`Inspection Service Request`}
                asset={asset}
                workRequest={workRequest}
                workOrder={workOrder}
                showAssignedTo={false}
                showLastKnownLocation={false}
                showSerialNumber={false}
                companyLogoUrl={companyLogoUrl}
            />
            <PDFHeadedSection title={"Work To Be Performed"}>{workRequest.workToBePerformed}</PDFHeadedSection>
            <PDFHeadedSection title={"Tasks To Be Performed"}>
                <PDFCheck
                    label="Pass All"
                    checked={workOrder && workRequest.tasks?.every((x) => x.status == "Pass" || x.status == "Completed" || x.status == "Performed")}
                />
                <div
                    style={{
                        display: "grid",
                        marginTop: 16,
                        marginBottom: 8,
                        gap: 16,
                        gridTemplateColumns: "repeat(3, 12px) 100px",
                        height: 20,
                        alignItems: "end",
                    }}
                >
                    <div style={taskCheckStyle}>Pass</div>
                    <div style={taskCheckStyle}>Fail</div>
                    <div style={taskCheckStyle}>N/A</div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",   
                            justifyContent: "center",
                            fontSize: "1.2rem",
                            lineHeight: 1,
                        }}
                    >
                        Measurement
                    </div>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "auto auto auto 100px 1fr", gap: "4px 16px" }}>
                    {workRequest.tasks?.sort((a,b)=> a.name.localeCompare(b.name)).map((it, i) => (
                        <WorkRequestTask task={it} workOrder={workOrder} key={i} />
                    ))}
                </div>
            </PDFHeadedSection>
            <EstimatedCostPDFSection workRequest={workRequest} workOrder={workOrder} />
            <WorkRequestStatusPDFSection workRequest={workRequest} workOrder={workOrder} />
            <NotesPDFSection
                text={workOrder ? workRequest?.originalWorkRequest.notes : undefined} 
            />
        </PDFPage>
    )
}

function WorkRequestTask({
    task,
    workOrder,
}: {
    task: PDFWorkRequestTask
    workOrder: PDFWorkOrder | undefined
}): JSX.Element {
    return (
        <>
            <PDFCheck
                checked={workOrder && (task.status == "Pass" || task.status == "Completed" || task.status == "Performed")}
            />
            <PDFCheck
                checked={workOrder && (task.status == "Fail" || task.status == "Not Performed" || task.status == "Not Completed")}
            />
            <PDFCheck checked={workOrder && task.status == "N/A"} />
            <div style={{ borderBottom: PDFSectionBorder }}>{task.measurement}</div>
            <div>
                <strong>{task.name}</strong> {task.description ? `(${task.description})` : ""}
            </div>
        </>
    )
}
