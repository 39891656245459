import { Outcome } from "@ethossoftworks/outcome"
import { Strings } from "@lib/Strings"
import {
    arrayOfNotNull,
    boolOrDefault,
    isString,
    nullBoolOrDefault,
    nullNumOrDefault,
    nullStringOrDefault,
    numArrayOrDefault,
    numOrDefault,
    safeParseInt,
    stringArrayOrDefault,
    stringOrDefault,
} from "@lib/TypeUtil"
import { TagType } from "@model/Tag"
import { ThresholdType, thresholdUnitForValue } from "@model/Threshold"
import { Urgency, labelForUrgency, urgencyForValue } from "@model/Urgency"
import { labelForContact } from "@model/contacts/Contact"
import { DashboardFilter } from "@model/dashboard/DashboardFilter"
import { DashboardFilterDefinitions } from "@model/dashboard/DashboardFilterDefinitions"
import {
    Filter,
    FilterValue,
    booleanFilterValue,
    isEmptyFilter,
    isEmptyFilterValue,
    numberArrayFilterValue,
    numberArrayFromStringFilterValue,
    textArrayFilterValue,
    textFilterValue,
} from "@model/filters/Filter"
import {
    FilterPreset,
    FilterPresetType,
    FilterPresets,
    isDashboardFilterPreset,
    isServiceQuoteFilterPreset,
    isWorkOrderFilterPreset,
    isWorkRequestFilterPreset,
} from "@model/filters/FilterPresets"
import { ServiceQuoteFilter, ServiceQuotesTableColumn } from "@model/serviceQuotes/ServiceQuote"
import { ServiceQuoteFilterDefinitions } from "@model/serviceQuotes/ServiceQuoteFilterDefinitions"
import { labelForServiceCode, labelForServiceSchedule } from "@model/serviceRequests/ServiceRequest"
import { TableColumnSettings } from "@model/tables/TableColumnSettings"
import { TableIdentifier } from "@model/tables/TableIdentifier"
import {
    WorkOrderFilter,
    WorkOrderTableColumn,
    labelForWorkOrderStatus
} from "@model/workOrders/WorkOrder"
import { WorkOrderFilterDefinitions } from "@model/workOrders/WorkOrderFilterDefinitions"
import {
    WorkRequestFilter,
    WorkRequestTableColumn,
    labelForWorkRequestType,
    workRequestTypeForValue,
} from "@model/workRequests/WorkRequest"
import { WorkRequestsFilterDefinitions } from "@model/workRequests/WorkRequestsFilterDefinitions"
import { ApiService, HttpMethod, tagTypeFromApi, tagTypeToApi, thresholdUnitToApi } from "@service/ApiService"
import { CompanyFieldIdMapper } from "@service/company/CompanyService"
import { UserPreferencesService } from "@service/userPreferences/UserPreferencesService"

export class MainUserPreferencesService implements UserPreferencesService {
    constructor(private apiService: ApiService, private idMapper: CompanyFieldIdMapper) {}

    async fetchFilterPresets(): Promise<Outcome<FilterPresets>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: "/User/UserPreference/FilterPreset",
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        return Outcome.ok(filterPresetsFromApi(response.value, this.idMapper))
    }

    async createFilterPreset<T extends string>(preset: FilterPreset<T>): Promise<Outcome<FilterPreset<T>>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/User/UserPreference/FilterPreset",
            body: filterPresetToApi(preset, this.idMapper),
        })
        if (!ApiService.isValidObjectResponse(response)) return Outcome.error(response)

        return Outcome.ok(filterPresetFromApi(response.value, this.idMapper))
    }

    async updateFilterPreset<T extends string>(preset: FilterPreset<T>): Promise<Outcome<FilterPreset<T>>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Put,
            path: `/User/UserPreference/FilterPreset/${preset.id}`,
            body: filterPresetToApi(preset, this.idMapper),
        })
        if (!ApiService.isValidObjectResponse(response)) return Outcome.error(response)

        return Outcome.ok(filterPresetFromApi(response.value, this.idMapper))
    }

    async deleteFilterPreset<T extends string>(preset: FilterPreset<T>): Promise<Outcome<void>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Delete,
            path: `/User/UserPreference/FilterPreset/${preset.id}`,
        })

        if (response.isError()) return Outcome.error(response)
        return Outcome.ok(undefined)
    }

    async fetchWorkRequestsTableColumns(): Promise<Outcome<TableColumnSettings<WorkRequestTableColumn> | null>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: `/User/UserPreference/ColumnsPreset`,
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)
        return Outcome.ok(workRequestColumnSettingsFromApi(response.value))
    }

    async updateWorkRequestsTableColumns(
        settings: TableColumnSettings<WorkRequestTableColumn>
    ): Promise<Outcome<TableColumnSettings<WorkRequestTableColumn> | null>> {
        const response = await this.apiService.makeRequest({
            method: settings.id === -1 ? HttpMethod.Post : HttpMethod.Put,
            path:
                settings.id === -1
                    ? `/User/UserPreference/ColumnsPreset`
                    : `/User/UserPreference/ColumnsPreset/${settings.id}`,
            body: workRequestColumnSettingsToApi(settings),
        })

        if (!ApiService.isValidObjectResponse(response)) return Outcome.error(response)
        return Outcome.ok(workRequestColumnSettingsFromApi([response.value]))
    }

    async fetchWorkOrdersTableColumns(): Promise<Outcome<TableColumnSettings<WorkOrderTableColumn> | null>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Get,
            path: `/User/UserPreference/ColumnsPreset`,
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)
        return Outcome.ok(workOrderColumnSettingsFromApi(response.value))
    }

    async updateWorkOrdersTableColumns(
        settings: TableColumnSettings<WorkOrderTableColumn>
    ): Promise<Outcome<TableColumnSettings<WorkOrderTableColumn> | null>> {
        const response = await this.apiService.makeRequest({
            method: settings.id === -1 ? HttpMethod.Post : HttpMethod.Put,
            path:
                settings.id === -1
                    ? `/User/UserPreference/ColumnsPreset`
                    : `/User/UserPreference/ColumnsPreset/${settings.id}`,
            body: workOrderColumnSettingsToApi(settings),
        })

        if (!ApiService.isValidObjectResponse(response)) return Outcome.error(response)
        return Outcome.ok(workOrderColumnSettingsFromApi([response.value]))
    }
    async updateServiceQuotesTableColumns(
        settings: TableColumnSettings<ServiceQuotesTableColumn>
    ): Promise<Outcome<TableColumnSettings<ServiceQuotesTableColumn> | null>> {
        const response = await this.apiService.makeRequest({
            method: settings.id === -1 ? HttpMethod.Post : HttpMethod.Put,
            path:
                settings.id === -1
                    ? `/User/UserPreference/ColumnsPreset`
                    : `/User/UserPreference/ColumnsPreset/${settings.id}`,
            body:serviceQuoteColumnSettingsToApi(settings),
        })

        if (!ApiService.isValidObjectResponse(response)) return Outcome.error(response)
        return Outcome.ok(serviceQuoteColumnSettingsFromApi([response.value]))
    }
}

function filterPresetsFromApi(obj: any[], idMapper: CompanyFieldIdMapper): FilterPresets {
    const mapped = obj.map((it) => filterPresetFromApi(it, idMapper))

    return {
        workRequests: mapped.filter(isWorkRequestFilterPreset),
        workOrders: mapped.filter(isWorkOrderFilterPreset),
        dashboard: mapped.filter(isDashboardFilterPreset),
        serviceQuotes: mapped.filter(isServiceQuoteFilterPreset),
    }
}

function filterPresetToApi(preset: FilterPreset<any>, idMapper: CompanyFieldIdMapper): any {
    return {
        filterName: preset.name,
        isDefault: preset.isDefault,
        sectionIdentifier: filterPresetTypeToApi(preset.type),
        filterSettings: (() => {
            const filters: any = {}

            if (isDashboardFilterPreset(preset)) {
                assetFiltersToApi(DashboardFilter, filters, preset)
            } else if (isWorkRequestFilterPreset(preset)) {
                assetFiltersToApi(WorkRequestFilter, filters, preset)
                workRequestFiltersToApi(WorkRequestFilter, filters, preset, idMapper)
            } else if (isWorkOrderFilterPreset(preset)) {
                assetFiltersToApi(WorkOrderFilter, filters, preset)
                workRequestFiltersToApi(WorkOrderFilter, filters, preset, idMapper)
                workOrderFiltersToApi(filters, preset, idMapper)
            }
            return filters
        })(),
    }
}

function filterPresetFromApi<T extends string>(obj: any, idMapper: CompanyFieldIdMapper): FilterPreset<T> {
    const type = filterPresetTypeFromApi(obj.sectionIdentifier)

    return {
        id: numOrDefault(obj.filterPresetID, -1),
        isDefault: boolOrDefault(obj.isDefault, false),
        name: stringOrDefault(obj.filterName, ""),
        type: type,
        filters: ((): Filter<T>[] => {
            const filters: Filter<T>[] = []

            if (type === FilterPresetType.Dashboard) {
                assetFiltersFromApi(DashboardFilter, filters, obj.filterSettings, idMapper)
            } else if (type === FilterPresetType.WorkRequest) {
                assetFiltersFromApi(WorkRequestFilter, filters, obj.filterSettings, idMapper)
                workRequestFiltersFromApi(WorkRequestFilter, filters, obj.filterSettings, idMapper)
            } else if (type === FilterPresetType.WorkOrder) {
                assetFiltersFromApi(WorkOrderFilter, filters, obj.filterSettings, idMapper)
                workRequestFiltersFromApi(WorkOrderFilter, filters, obj.filterSettings, idMapper)
                workOrderFiltersFromApi(filters, obj.filterSettings, idMapper)
            }
            return filters
        })(),
    }
}

function assetFiltersToApi(
    filterType: typeof DashboardFilter | typeof WorkRequestFilter | typeof WorkOrderFilter | typeof ServiceQuoteFilter,
    filters: any,
    preset: FilterPreset<string>
) {
    const filterDefinitions = (() => {
        switch (preset.type) {
            case FilterPresetType.Dashboard:
                return DashboardFilterDefinitions
            case FilterPresetType.WorkRequest:
                return WorkRequestsFilterDefinitions
            case FilterPresetType.WorkOrder:
                return WorkOrderFilterDefinitions
            case FilterPresetType.ServiceQuote:
                return ServiceQuoteFilterDefinitions
        }
    })()

    for (const filter of preset.filters) {
        if (isEmptyFilterValue(filter)) continue

        if (filter.definition === filterDefinitions[filterType.District]) {
            filters.DistrictIds = numberArrayFilterValue(filter)
        } else if (filter.definition === filterDefinitions[filterType.SubCompany]) {
            filters.SubCompanyIds = numberArrayFilterValue(filter)
        } else if (filter.definition === filterDefinitions[filterType.SubDistrict]) {
            filters.SubDistrictIds = numberArrayFilterValue(filter)
        } else if (filter.definition === filterDefinitions[filterType.Unit]) {
            filters.UnitIds = numberArrayFilterValue(filter)
        } else if (filter.definition === filterDefinitions[filterType.Group]) {
            filters.GroupIds = numberArrayFilterValue(filter)
        } else if (filter.definition === filterDefinitions[filterType.Site]) {
            filters.SiteIds = numberArrayFilterValue(filter)
        } else if (filter.definition === filterDefinitions[filterType.AssetType]) {
            filters.TypeIds = numberArrayFilterValue(filter)
        } else if (filter.definition === filterDefinitions[filterType.Category]) {
            filters.CategoryIds = numberArrayFilterValue(filter)
        } else if (filter.definition === filterDefinitions[filterType.Class]) {
            filters.ClassIds = numberArrayFilterValue(filter)
        } else if (filter.definition === filterDefinitions[filterType.Make]) {
            filters.MakeIds = numberArrayFilterValue(filter)
        } else if (filter.definition === filterDefinitions[filterType.Model]) {
            filters.ModelIds = numberArrayFilterValue(filter)
        } else if (filter.definition === filterDefinitions[filterType.Search]) {
            filters.GlobalSearchQuery = textFilterValue(filter)
        }
    }
}

function assetFiltersFromApi<T extends typeof DashboardFilter | typeof WorkRequestFilter | typeof WorkOrderFilter>(
    filterType: T,
    filters: Filter<string>[],
    obj: any,
    idMapper: CompanyFieldIdMapper
): void {
    const filterDefinitions = (() => {
        switch (filterType) {
            case DashboardFilter:
                return DashboardFilterDefinitions
            case WorkRequestFilter:
                return WorkRequestsFilterDefinitions
            case WorkOrderFilter:
                return WorkOrderFilterDefinitions
            default:
                throw Error("Invalid Filter Type")
        }
    })()

    const districtIds = numArrayOrDefault(obj.DistrictIds)
    if (districtIds.length > 0) {
        filters.push({
            definition: filterDefinitions[filterType.District],
            values: districtIds.map((it): FilterValue => ({ value: it, label: idMapper.toDistrict(it)?.name ?? "" })),
        })
    }

    const subDistrictIds = numArrayOrDefault(obj.SubDistrictIds)
    if (subDistrictIds.length > 0) {
        filters.push({
            definition: filterDefinitions[filterType.SubDistrict],
            values: subDistrictIds.map(
                (it): FilterValue => ({ value: it, label: idMapper.toSubDistrict(it)?.name ?? "" })
            ),
        })
    }

    const subCompanyIds = numArrayOrDefault(obj.SubCompanyIds)
    if (subCompanyIds.length > 0) {
        filters.push({
            definition: filterDefinitions[filterType.SubCompany],
            values: subCompanyIds.map(
                (it): FilterValue => ({ value: it, label: idMapper.toSubCompany(it)?.name ?? "" })
            ),
        })
    }
    const unitIds = numArrayOrDefault(obj.UnitIds)
    if (unitIds.length > 0) {
        filters.push({
            definition: filterDefinitions[filterType.Unit],
            values: unitIds.map((it): FilterValue => ({ value: it, label: idMapper.toUnit(it)?.name ?? "" })),
        })
    }

    const groupIds = numArrayOrDefault(obj.GroupIds)
    if (groupIds.length > 0) {
        filters.push({
            definition: filterDefinitions[filterType.Group],
            values: groupIds.map((it): FilterValue => ({ value: it, label: idMapper.toGroup(it)?.name ?? "" })),
        })
    }

    const siteIds = numArrayOrDefault(obj.SiteIds)
    if (siteIds.length > 0) {
        filters.push({
            definition: filterDefinitions[filterType.Site],
            values: siteIds.map((it): FilterValue => ({ value: it, label: idMapper.toSite(it)?.name ?? "" })),
        })
    }

    const typeIds = numArrayOrDefault(obj.TypeIds)
    if (typeIds.length > 0) {
        filters.push({
            definition: filterDefinitions[filterType.AssetType],
            values: typeIds.map((it): FilterValue => ({ value: it, label: idMapper.toAssetType(it)?.name ?? "" })),
        })
    }

    const categoryIds = numArrayOrDefault(obj.CategoryIds)
    if (categoryIds.length > 0) {
        filters.push({
            definition: filterDefinitions[filterType.Category],
            values: categoryIds.map(
                (it): FilterValue => ({ value: it, label: idMapper.toAssetCategory(it)?.name ?? "" })
            ),
        })
    }

    const classIds = numArrayOrDefault(obj.ClassIds)
    if (classIds.length > 0) {
        filters.push({
            definition: filterDefinitions[filterType.Class],
            values: classIds.map((it): FilterValue => ({ value: it, label: idMapper.toAssetClass(it)?.name ?? "" })),
        })
    }

    const makeIds = numArrayOrDefault(obj.MakeIds)
    if (makeIds.length > 0) {
        filters.push({
            definition: filterDefinitions[filterType.Make],
            values: makeIds.map((it): FilterValue => ({ value: it, label: idMapper.toAssetMake(it)?.name ?? "" })),
        })
    }

    const modelIds = numArrayOrDefault(obj.ModelIds)
    if (modelIds.length > 0) {
        filters.push({
            definition: filterDefinitions[filterType.Model],
            values: modelIds.map((it): FilterValue => ({ value: it, label: idMapper.toAssetModel(it)?.name ?? "" })),
        })
    }

    const search = nullStringOrDefault(obj.GlobalSearchQuery, null)
    if (search !== null) {
        filters.push({
            definition: filterDefinitions[filterType.Search],
            values: [{ value: search }],
        })
    }
}

function workRequestFiltersToApi(
    filterType: typeof WorkRequestFilter | typeof WorkOrderFilter,
    filters: any,
    preset: FilterPreset<WorkRequestFilter | WorkOrderFilter>,
    idMapper: CompanyFieldIdMapper
): void {
    const filterDefinitions = (() => {
        switch (preset.type) {
            case FilterPresetType.WorkRequest:
                return WorkRequestsFilterDefinitions
            case FilterPresetType.WorkOrder:
                return WorkOrderFilterDefinitions
            default:
                throw Error("Invalid Filter Type")
        }
    })()

    const milesUnitFilter = preset.filters.find((it) => it.definition.type === filterType.MilesUntilUnit)
    const daysUnitFilter = preset.filters.find((it) => it.definition.type === filterType.DaysUntilUnit)
    const hoursUnitFilter = preset.filters.find((it) => it.definition.type === filterType.HoursUntilUnit)

    for (const filter of preset.filters) {
        if (isEmptyFilter(filter)) continue

        if (filter.definition === filterDefinitions[filterType.WorkType]) {
            filters.WorkTypeIds = textArrayFilterValue(filter).map((it) =>
                idMapper.fromWorkRequestType(workRequestTypeForValue(it))
            )
        } else if (filter.definition === filterDefinitions[filterType.Urgency]) {
            filters.UrgencyIds = filter.values
                .map((it) => it.value)
                .flatMap((it) => {
                    if (!isString(it)) return []
                    return idMapper.fromUrgency(urgencyForValue(it))
                })
        } else if (filter.definition === filterDefinitions[filterType.Schedule]) {
            filters.ScheduleIds = numberArrayFilterValue(filter)
        } else if (filter.definition === filterDefinitions[filterType.HoursUntil]) {
            if (!hoursUnitFilter) continue
            filters.HoursUntil = safeParseInt(textFilterValue(filter))
            filters.HoursUntilUnit = thresholdUnitToApi(
                ThresholdType.Hours,
                thresholdUnitForValue(textFilterValue(hoursUnitFilter))
            )
        } else if (filter.definition === filterDefinitions[filterType.MilesUntil]) {
            if (!milesUnitFilter) continue
            filters.MilesUntil = safeParseInt(textFilterValue(filter))
            filters.MilesUntilUnit = thresholdUnitToApi(
                ThresholdType.Miles,
                thresholdUnitForValue(textFilterValue(milesUnitFilter))
            )
        } else if (filter.definition === filterDefinitions[filterType.DaysUntil]) {
            if (!daysUnitFilter) continue
            filters.DaysUntil = safeParseInt(textFilterValue(filter))
            filters.DaysUntilUnit = thresholdUnitToApi(
                ThresholdType.Days,
                thresholdUnitForValue(textFilterValue(daysUnitFilter))
            )
        } else if (filter.definition === filterDefinitions[filterType.ServiceCode]) {
            filters.ServiceCodeIds = numberArrayFromStringFilterValue(filter)
        } else if (filter.definition === filterDefinitions[filterType.RedTag]) {
            if (!booleanFilterValue(filter)) continue
            if (!filters.TagStatus) filters.TagStatus = []
            filters.TagStatus.push(tagTypeToApi(TagType.Red))
        } else if (filter.definition === filterDefinitions[filterType.YellowTag]) {
            if (!booleanFilterValue(filter)) continue
            if (!filters.TagStatus) filters.TagStatus = []
            filters.TagStatus.push(tagTypeToApi(TagType.Yellow))
        } else if (filter.definition === filterDefinitions[filterType.Overdue]) {
            filters.Overdue = booleanFilterValue(filter)
        } else if (filter.definition === filterDefinitions[filterType.Upcoming]) {
            filters.Upcoming = booleanFilterValue(filter)
        } else if (filter.definition === filterDefinitions[filterType.Search]) {
            filters.GlobalSearchQuery = textFilterValue(filter)
        }
    }
}

function workRequestFiltersFromApi<T extends typeof WorkRequestFilter | typeof WorkOrderFilter>(
    filterType: T,
    filters: Filter<string>[],
    obj: any,
    idMapper: CompanyFieldIdMapper
): void {
    const filterDefinitions = (() => {
        switch (filterType) {
            case WorkRequestFilter:
                return WorkRequestsFilterDefinitions
            case WorkOrderFilter:
                return WorkOrderFilterDefinitions
            default:
                throw Error("Invalid Filter Type")
        }
    })()

    const workTypeIds = numArrayOrDefault(obj.WorkTypeIds)
    if (workTypeIds.length > 0) {
        filters.push({
            definition: filterDefinitions[filterType.WorkType],
            values: workTypeIds.map((it): FilterValue => {
                const workType = idMapper.toWorkRequestType(it)
                return { value: workType, label: labelForWorkRequestType(workType) }
            }),
        })
    }

    const urgencyIds = numArrayOrDefault(obj.UrgencyIds)
    if (urgencyIds.length > 0) {
        const urgencies: Map<Urgency, boolean> = new Map()
        urgencyIds.forEach((it) => urgencies.set(idMapper.toUrgency(it), true))

        filters.push({
            definition: filterDefinitions[filterType.Urgency],
            values: Array.from(urgencies.keys()).map((it): FilterValue => ({ value: it, label: labelForUrgency(it) })),
        })
    }

    const scheduleIds = numArrayOrDefault(obj.ScheduleIds)
    if (scheduleIds.length > 0) {
        filters.push({
            definition: filterDefinitions[filterType.Schedule],
            values: scheduleIds.map((it): FilterValue => {
                const serviceSchedule = idMapper.toServiceSchedule(it)
                return { value: it, label: serviceSchedule ? labelForServiceSchedule(serviceSchedule) : "" }
            }),
        })
    }

    const hoursUntil = nullNumOrDefault(obj.HoursUntil, null)
    const hoursUntilUnit = nullStringOrDefault(obj.HoursUntilUnit, null)
    if (hoursUntil !== null && hoursUntilUnit !== null) {
        filters.push(
            {
                definition: filterDefinitions[filterType.HoursUntil],
                values: [{ value: Strings.formatInteger(hoursUntil) }],
            },
            {
                definition: filterDefinitions[filterType.HoursUntilUnit],
                values: [{ value: thresholdUnitForValue(hoursUntilUnit) }],
            }
        )
    }

    const milesUntil = nullNumOrDefault(obj.MilesUntil, null)
    const milesUntilUnit = nullStringOrDefault(obj.MilesUntilUnit, null)
    if (milesUntil !== null && milesUntilUnit !== null) {
        filters.push(
            {
                definition: filterDefinitions[filterType.MilesUntil],
                values: [{ value: Strings.formatInteger(milesUntil) }],
            },
            {
                definition: filterDefinitions[filterType.MilesUntilUnit],
                values: [{ value: thresholdUnitForValue(milesUntilUnit) }],
            }
        )
    }

    const daysUntil = nullNumOrDefault(obj.DaysUntil, null)
    const daysUntilUnit = nullStringOrDefault(obj.DaysUntilUnit, null)
    if (daysUntil !== null && daysUntilUnit !== null) {
        filters.push(
            {
                definition: filterDefinitions[filterType.DaysUntil],
                values: [{ value: Strings.formatInteger(daysUntil) }],
            },
            {
                definition: filterDefinitions[filterType.DaysUntilUnit],
                values: [{ value: thresholdUnitForValue(daysUntilUnit) }],
            }
        )
    }

    const serviceCodeIds = numArrayOrDefault(obj.ServiceCodeIds)
    if (serviceCodeIds.length > 0) {
        filters.push({
            definition: filterDefinitions[filterType.ServiceCode],
            values: serviceCodeIds.map((it): FilterValue => {
                const serviceCode = idMapper.toServiceCode(it)
                return { value: it, label: serviceCode ? labelForServiceCode(serviceCode) : "" }
            }),
        })
    }

    const tagStatus = stringArrayOrDefault(obj.TagStatus)
    tagStatus.forEach((it) => {
        const type = tagTypeFromApi(it)
        if (!type) return

        filters.push({
            definition:
                type === TagType.Red ? filterDefinitions[filterType.RedTag] : filterDefinitions[filterType.YellowTag],
            values: [{ value: true }],
        })
    })

    const overdue = nullBoolOrDefault(obj.Overdue, null)
    if (overdue !== null) {
        filters.push({
            definition: filterDefinitions[filterType.Overdue],
            values: [{ value: overdue }],
        })
    }

    const upcoming = nullBoolOrDefault(obj.Upcoming, null)
    if (upcoming !== null) {
        filters.push({
            definition: filterDefinitions[filterType.Upcoming],
            values: [{ value: upcoming }],
        })
    }
}

function workOrderFiltersToApi(
    filters: any,
    preset: FilterPreset<WorkOrderFilter>,
    idMapper: CompanyFieldIdMapper
): void {
    for (const filter of preset.filters) {
        if (isEmptyFilterValue(filter)) continue

        if (filter.definition === WorkOrderFilterDefinitions[WorkOrderFilter.Status]) {
            filters.WorkOrderStatusIds = numberArrayFilterValue(filter).map((it) =>
                it
            )
        }

        if (filter.definition === WorkOrderFilterDefinitions[WorkOrderFilter.AssignedTo]) {
            filters.AssignedToIds = numberArrayFilterValue(filter)
        }
    }
}

function workOrderFiltersFromApi(filters: Filter<string>[], obj: any, idMapper: CompanyFieldIdMapper): void {
    const workOrderStatuses = numArrayOrDefault(obj.WorkOrderStatusIds)
    if (workOrderStatuses.length > 0) {
        filters.push({
            definition: WorkOrderFilterDefinitions[WorkOrderFilter.Status],
            values: workOrderStatuses.map((it): FilterValue => {
                const status = idMapper.toWorkOrderStatus(it)
                return { value: it, label: labelForWorkOrderStatus(status) }
            }),
        })
    }

    const assignedToIds = numArrayOrDefault(obj.AssignedToIds)
    if (assignedToIds.length > 0) {
        filters.push({
            definition: WorkOrderFilterDefinitions[WorkOrderFilter.AssignedTo],
            values: assignedToIds.map((it): FilterValue => {
                const contact = idMapper.toContact(it)
                return { value: it, label: contact ? labelForContact(contact) : "" }
            }),
        })
    }
}

function filterPresetTypeFromApi(value: string): FilterPresetType {
    switch (value) {
        case "Dashboard":
            return FilterPresetType.Dashboard
        case "WorkRequests":
            return FilterPresetType.WorkRequest
        case "WorkOrders":
            return FilterPresetType.WorkOrder
        default:
            return FilterPresetType.Dashboard
    }
}

function filterPresetTypeToApi(value: FilterPresetType): string {
    switch (value) {
        case FilterPresetType.Dashboard:
            return "Dashboard"
        case FilterPresetType.WorkRequest:
            return "WorkRequests"
        case FilterPresetType.WorkOrder:
            return "WorkOrders"
        case FilterPresetType.ServiceQuote:
            return "serviceQuotes"
    }
}

const workRequestColumnSettingsFromApi = (obj: any[]): TableColumnSettings<WorkRequestTableColumn> | null =>
    tableColumnSettingsFromApi(obj, TableIdentifier.WorkRequests, Object.values(WorkRequestTableColumn))

const workOrderColumnSettingsFromApi = (obj: any[]): TableColumnSettings<WorkOrderTableColumn> | null =>
    tableColumnSettingsFromApi(obj, TableIdentifier.WorkOrders, Object.values(WorkOrderTableColumn))

const serviceQuoteColumnSettingsFromApi = (obj: any[]): TableColumnSettings<ServiceQuotesTableColumn> | null =>
    tableColumnSettingsFromApi(obj, TableIdentifier.ServiceQuotes, Object.values(ServiceQuotesTableColumn))

function tableColumnSettingsFromApi<T extends string>(
    obj: any[],
    tableIdentifier: TableIdentifier,
    columns: T[]
): TableColumnSettings<T> | null {
    const settings = obj.find((it) => stringOrDefault(it.sectionIdentifier, "") === tableIdentifier)
    if (!settings || !settings.columnsSettings || !settings.columnsSettings.values) return null

    const columnSettings = settings.columnsSettings.values
    if (!Array.isArray(columnSettings)) return null

    return {
        id: numOrDefault(settings.columnsPresetID, -1),
        columns: arrayOfNotNull(
            columnSettings.map(
                (it) => columns.find((search) => search === stringOrDefault(it.propertyName, "")) ?? null
            )
        ),
    }
}

function workRequestColumnSettingsToApi(settings: TableColumnSettings<WorkRequestTableColumn>): any {
    return {
        columnsPresetID: settings.id,
        sectionIdentifier: TableIdentifier.WorkRequests,
        columnsSettings: {
            values: settings.columns.map((it) => ({ propertyName: it })),
        },
    }
}

function workOrderColumnSettingsToApi(settings: TableColumnSettings<WorkOrderTableColumn>): any {
    return {
        columnsPresetID: settings.id,
        sectionIdentifier: TableIdentifier.WorkOrders,
        columnsSettings: {
            values: settings.columns.map((it) => ({ propertyName: it })),
        },
    }
}

function serviceQuoteColumnSettingsToApi(settings: TableColumnSettings<ServiceQuotesTableColumn>): any {
    return {
        columnsPresetID: settings.id,
        sectionIdentifier: TableIdentifier.ServiceQuotes,
        columnsSettings: {
            values: settings.columns.map((it) => ({ propertyName: it })),
        },
    }
}
