import { useBloc } from "@lib/bloc/hooks"
import { FilterPresetForm } from "@model/filters/FilterPresets"
import { DI } from "@ui/DI"
import React, { useEffect, useRef } from "react"
import { Button, ButtonType } from "../buttons/Button"
import { CheckBox } from "../form/CheckBox"
import { FormField, FormFieldDirection, FormFieldNote, FormFieldNoteType } from "../form/FormField"
import { TextInput } from "../form/TextInput"
import { Grid } from "../grids/Grid"
import {
    Modal,
    ModalBody,
    ModalButtons,
    ModalContent,
    ModalHeader,
    ModalHeaderSubTitle,
    ModalHeaderTitle,
} from "../Modal"

export type EditFilterPresetModalProps = {
    isVisible: boolean
    form?: FilterPresetForm
    unavailableNames: string[]
    isSaving: boolean
    onSave: (form: FilterPresetForm) => void
    onCancel: () => void
}

export function EditFilterPresetModal(props: EditFilterPresetModalProps): JSX.Element {
    const inputRef = useRef<HTMLInputElement>(null)

    return (
        <Modal isVisible={props.isVisible} onClose={props.onCancel} useContentTag={true} focusRef={inputRef}>
            <EditFilterPresetModalContent {...props} inputRef={inputRef} />
        </Modal>
    )
}

function EditFilterPresetModalContent({
    onSave,
    onCancel,
    form,
    unavailableNames,
    inputRef,
    isSaving
}: EditFilterPresetModalProps & { inputRef: React.RefObject<HTMLInputElement> }): JSX.Element {
    const [state, bloc] = useBloc(() => DI.createFilterPresetBloc())

    useEffect(() => {
        bloc.unavailableNamesChanged(unavailableNames)
        if (!form) return
        bloc.formChanged(form)
    }, [])

    return (
        <ModalContent>
            <ModalHeader>
                <ModalHeaderTitle>
                    {form !== undefined ? "Edit Filter Preset" : "Create Filter Preset"}
                </ModalHeaderTitle>
                {form === undefined && <ModalHeaderSubTitle>Save your filters for quick access</ModalHeaderSubTitle>}
            </ModalHeader>
            <ModalBody>
                <form
                    onSubmit={(ev) => {
                        ev.preventDefault()
                        if (!state.isFormValid) return
                        onSave(state.form)
                    }}
                >
                    <Grid columns={1}>
                        <FormField label="Name*" direction={FormFieldDirection.Column}>
                            <TextInput
                                value={state.form.name}
                                onChange={(value) => bloc.formChanged({ name: value })}
                                focusRef={inputRef}
                                maxLength={100}
                            />
                            {state.isNameUsed && (
                                <FormFieldNote type={FormFieldNoteType.Error}>Name already in use</FormFieldNote>
                            )}
                        </FormField>
                        <CheckBox
                            label="Default"
                            isChecked={state.form.isDefault}
                            onCheckChanged={(isChecked) => bloc.formChanged({ isDefault: isChecked })}
                        />
                    </Grid>
                </form>
            </ModalBody>
            <ModalButtons>
                <Button type={ButtonType.Text} label="Cancel" onClick={onCancel} />
                <Button
                    type={ButtonType.Contained}
                    label={form === undefined ? "Create" : "Update"}
                    onClick={() => onSave(state.form)}
                    isEnabled={state.isFormValid && !isSaving}
                />
            </ModalButtons>
        </ModalContent>
    )
}
