import { Tag, TagType } from "@model/Tag"
import React from "react"
import { FormField } from "./FormField"
import { FormFieldGroup } from "./FormFieldGroup"
import { Radio } from "./Radio"
import { TextArea, TextAreaType } from "./TextArea"

export type TagFormFieldProps = {
    tag: Tag | null
    onTagTypeChanged: (type: TagType | null) => void
    onReasonChanged: (reason: string) => void
    formFieldLabel?: string
    disabled?: boolean
    disableSelection?: boolean
    disableNoneOption?: boolean
}

export function TagFormField({
    tag,
    onTagTypeChanged,
    onReasonChanged,
    formFieldLabel,
    disabled = false,
    disableSelection = false,
    disableNoneOption = false,
}: TagFormFieldProps): JSX.Element {
    return (
        <FormFieldGroup label={formFieldLabel ?? "Tag"} className={"tag-form-field-group"}>
            <FormField className="form-field--tag">
                <Radio
                    isEnabled={!disableSelection && !disableNoneOption}
                    isChecked={tag === null || tag.type == TagType.None}
                    label="None"
                    onSelected={() => onTagTypeChanged(TagType.None)}
                />
                <Radio
                    isChecked={tag?.type === TagType.Red}
                    label="Red Tag"
                    onSelected={() => onTagTypeChanged(TagType.Red)}
                    isEnabled={!disableSelection}
                />
                <Radio
                    isChecked={tag?.type === TagType.Yellow}
                    label="Yellow Tag"
                    onSelected={() => onTagTypeChanged(TagType.Yellow)}
                    isEnabled={!disableSelection}
                />
            </FormField>
            {tag !== null && tag.type !== TagType.None && (
                <FormField
                    label={"Reason" + (disabled ? "" : "*")}
                    hasRedStroke={!disabled && (tag?.reason?.length == 0 ?? true)}
                >
                    <TextArea
                        type={TextAreaType.Empty}
                        data-type={"padded"}
                        value={tag?.reason ?? ""}
                        onChange={(value) => onReasonChanged(value)}
                        maxLength={250}
                        disabled={disabled}
                    />
                </FormField>
            )}
        </FormFieldGroup>
    )
}
