import { Outcome } from "@ethossoftworks/outcome"
import { Bloc } from "@lib/bloc/Bloc"
import { PDFAsset } from "@lib/pdf/models/PDFAsset"
import { PDFAssetPart } from "@lib/pdf/models/PDFAssetPart"
import { PDFWorkOrder } from "@lib/pdf/models/PDFWorkOrder"
import { Strings } from "@lib/Strings"
import { arrayToObject, safeParseAndRoundFloat, safeParseFloat, safeParseInt } from "@lib/TypeUtil"
import { Asset, MeterSyncRequiredUseCases, pdfAssetFromModel } from "@model/assets/Asset"
import { AssetPart, pdfAssetPartFromModel } from "@model/assets/AssetPart"
import { MeterSyncRecord } from "@model/assets/MeterSyncRecord"
import { PriorAssetUsage } from "@model/assets/PriorAssetUsage"
import { AsyncStatus } from "@model/AsyncStatus"
import { Attachment } from "@model/Attachment"
import { BillingCode } from "@model/company/BillingCode"
import { Contact } from "@model/contacts/Contact"
import { HistoryItem } from "@model/HistoryItem"
import { ServiceCode, ServiceRequestType } from "@model/serviceRequests/ServiceRequest"
import { ServiceSchedule } from "@model/ServiceSchedule"
import { Site } from "@model/site/Site"
import { Step } from "@model/Step"
import { Tag, TagChange, tagChangeFromTag, tagChangeFromTagType, TagType } from "@model/Tag"
import { CompletableTaskValue, InspectionTaskValue, Task, TaskValue } from "@model/Task"
import { Urgency } from "@model/Urgency"
import { User } from "@model/user/User"
import { pdfWorkOrderFromModels, WorkOrder, WorkOrderStatus } from "@model/workOrders/WorkOrder"
import { WorkOrderPrintOption } from "@model/workOrders/WorkOrderPrintOption"
import { WorkRequest, WorkRequestStatus, WorkRequestType } from "@model/workRequests/WorkRequest"
import { WorkRequestStatusFlag } from "@model/workRequests/WorkRequestStatusFlag"
import { AssetService } from "@service/assets/AssetService"
import { ServiceQuoteService } from "@service/serviceQuotes/ServiceQuoteService"
import { WorkOrderService } from "@service/workOrders/WorkOrderService"
import { WorkRequestService } from "@service/workRequests/WorkRequestService"
import isEqual from "lodash.isequal"
import { v4 as uuidv4 } from "uuid"

export type WorkOrderScreenState = {
    effectStatus: Record<WorkOrderEffect, AsyncStatus>
    workOrder: null | WorkOrder
    asset: null | Asset
    form: WorkOrderForm
    workRequestForms: Record<number, WorkOrderWorkRequestForm>
    workRequests: WorkRequest[]
    formHasChanged: boolean
    showTaskDescriptions: boolean
    workRequestToRemove: WorkRequest | null
    workRequestToDismiss: {
        request: WorkRequest
        reason: string
        tagChange: TagChange
        tagReason: string
    } | null
    workRequestWorkNotPerformed: {
        request: WorkRequest
        reason: string
        previousStatus: WorkRequestStatus | null
        newStatus: WorkRequestStatus | null
    } | null
    workOrderDismissal: {
        reason: string
        workRequestsToDelete: number[]
    } | null
    workRequestDetailsModal: number | null
    renderCreateServiceRequest: boolean
    assetUsage: PriorAssetUsage | null
    showTagUpdate: boolean
    showSyncMeter: boolean
    showSyncDoneAfterServiceDateModal: boolean
    hasBeenShownSyncDoneAfterServiceDateModal: boolean
    syncMeterShown: boolean
    tagUpdateShown: boolean
    workOrderToPrint: number | null
    workOrderToPrintIsLoading: boolean
    partsListIsLoading: boolean
    pdfAsset?: PDFAsset
    pdfWorkOrder?: PDFWorkOrder
    pdfAssetParts?: PDFAssetPart[]
    pdfCompanyName?: string
    selectedPrintOption?: WorkOrderPrintOption
    showPartsList: boolean
    showMeterSyncInfo: boolean
    AssetParts?: AssetPart[]
    rollbackModalVisible: boolean
    rollbackModalIsLoading: boolean
    rollbackModalCanPerformRollback: boolean
    latestAssetUsage: PriorAssetUsage | null
    meterSyncRecordForRollback: MeterSyncRecord[]
    workRequestToRemoveWhenRollback: WorkRequest[]
    adjustServiceRequestModalVisible: boolean
    serviceRequestHasBeenAdjusted: boolean
    workRequestToBeAdjustedBySchedule: WorkRequest[]
}

export type WorkOrderForm = {
    status: WorkOrderStatus
    assignedTo: number
    cbaCodeId: number
    travelTime: string | null
    travelDistance: string | null
    nightWork: boolean
    urgency: Urgency
    dueDate: Date | null
    dueHourMeter: string
    dueOdometer: string
    specialInstructions: string
    workPerformedById: number[] | null
    siteId: number | null
    workCompletedDate: Date | null
    completedHourMeter: string | null
    completedOdometer: string | null
    openedDate: Date | null
    tagStatus: TagType
    tagReason: string
    meterSync: boolean
    closedWorkOrderEditMode: boolean
}

export type WorkOrderWorkRequestForm = {
    billingCodeId: number | null
    step: Step | null
    schedule: ServiceSchedule | null
    workRequestType: WorkRequestType
    urgency: Urgency
    dueDate: Date | null
    dueHourMeter: string
    dueOdometer: string
    estimatedLaborHours: string
    estimatedPartsCost: string
    actualLaborHours: string
    actualLaborCost: string
    actualPartsCost: string
    actualTotalCost: string
    specialInstructions: string
    notes: string
    workToBePerformed: string
    attachments: Attachment[]
    status: WorkRequestStatus
    tasks: Task[] | null
    notifyContacts: number[]
    tag: Tag | null
    serviceType: ServiceRequestType | null
    serviceCodeId: number | null
    statusFlagReason: string
    filesId: string | null
    hadFilesId: boolean
    newAttachmentsNames: string[]
    workRequestStatusFlags: WorkRequestStatusFlag[]
    manualAdjustment: boolean
    advanceNextStep: boolean
    adjustedDueDate: Date | null
    adjustedDueHourMeter: string
    adjustedDueOdometer: string
}

export enum WorkOrderEffect {
    Fetch = "fetch",
    Save = "save",
    SaveRequest = "saveRequest",
    RemoveWorkRequest = "removeWorkRequest",
    DismissWorkRequest = "dismissWorkRequest",
    DismissWorkOrder = "dismissWorkOrder",
    MeterSync = "meterSync",
    AddedAssetRequireMeterSyncFlag = "addedAssetRequireMeterSyncFlag",
    FetchWorkRequestHistory = "fetchWorkRequestHistory",
    FetchWorkOrderHistory = "fetchWorkOrderHistory",
}

export class WorkOrderScreenBloc extends Bloc<WorkOrderScreenState> {
    constructor(
        private workOrderService: WorkOrderService,
        private assetService: AssetService,
        private workRequestService: WorkRequestService,
        private serviceQuoteService: ServiceQuoteService
    ) {
        super(newWorkOrderViewState(), { persistStateOnDispose: false })
    }

    override computed = (state: WorkOrderScreenState): Partial<WorkOrderScreenState> => {
        return {
            formHasChanged:
                state.workOrder !== null &&
                !isEqual(
                    { ...state.form, tagStatus: TagType.None, tagReason: "", closedWorkOrderEditMode: false },
                    newWorkOrderForm(state.workOrder)
                ),
            workRequestForms: arrayToObject(Object.entries(state.workRequestForms), ([key, it]) => [
                safeParseInt(key),
                {
                    ...it,
                    actualTotalCost: Strings.formatMoney(
                        safeParseFloat(it.actualLaborCost) + safeParseFloat(it.actualPartsCost)
                    ),
                },
            ]),
        }
    }

    fetchData = (workOrderId: number) =>
        this.effect({
            id: WorkOrderEffect.Fetch,
            block: async (job) => {
                this.effectStatusChanged(WorkOrderEffect.Fetch, AsyncStatus.busy())

                const [workOrderOutcome, workRequestsOutcome] = await job.pause(
                    Promise.all([
                        this.workOrderService.fetchWorkOrder(workOrderId),
                        this.workRequestService.fetchWorkRequestsForWorkOrder(workOrderId),
                    ])
                )

                if (workOrderOutcome.isError() || workRequestsOutcome.isError()) {
                    this.effectStatusChanged(WorkOrderEffect.Fetch, AsyncStatus.error(undefined))
                    return Outcome.error(undefined)
                }

                const [assetOutcome, assetUsageOutcome, latestAssetUsageOutcome] = await job.pause(
                    Promise.all([
                        this.assetService.fetchAsset(workOrderOutcome.value.assetId),
                        this.assetService.fetchAssetUsage(
                            workOrderOutcome.value.assetId,
                            workOrderOutcome.value.workCompletedDate
                                ? workOrderOutcome.value.workCompletedDate
                                : new Date()
                        ),
                        this.assetService.fetchAssetUsage(workOrderOutcome.value.assetId, new Date()), //this always brings the latest asset usage
                    ])
                )

                if (assetOutcome.isError() || assetUsageOutcome.isError() || latestAssetUsageOutcome.isError()) {
                    this.effectStatusChanged(WorkOrderEffect.Fetch, AsyncStatus.error(undefined))
                    return Outcome.error(undefined)
                }

                /* In case it's necesary to load all the history when loading the work request
                const histoyOutcome = await job.pause(
                    this.workRequestService.fetchWorkRequestsHistory(workRequestsOutcome.value.map((it) => it.id))
                )
                if (histoyOutcome.isError()) {
                    this.effectStatusChanged(WorkOrderEffect.Fetch, AsyncStatus.error(undefined))
                    return histoyOutcome
                }

                workRequestsOutcome.value.forEach((workRequest) => {
                    var history = histoyOutcome.value.filter((x) => x.primaryKey == workRequest.id)
                    if (history && history.length > 0) workRequest.history = history
                })
                */

                this.effectStatusChanged(WorkOrderEffect.Fetch, AsyncStatus.idle())

                this.update({
                    workOrder: workOrderOutcome.value,
                    asset: assetOutcome.value,
                    workRequests: workRequestsOutcome.value,
                    form: newWorkOrderForm(workOrderOutcome.value, assetOutcome.value),
                    workRequestForms: arrayToObject(workRequestsOutcome.value, (it) => [
                        it.id,
                        newWorkRequestForm(it, assetOutcome.value),
                    ]),
                    assetUsage: assetUsageOutcome.value,
                    latestAssetUsage: latestAssetUsageOutcome.value,
                })

                return Outcome.ok(undefined)
            },
        })

    fetchWorkOrderHistoryData = (workOrderId: number) =>
        this.effect({
            id: WorkOrderEffect.FetchWorkOrderHistory,
            block: async (job) => {
                this.effectStatusChanged(WorkOrderEffect.FetchWorkOrderHistory, AsyncStatus.busy())
                const [workOrderHistory] = await job.pause(
                    Promise.all([this.workOrderService.fetchWorkOrderHistory(workOrderId)])
                )

                if (workOrderHistory.isError()) {
                    this.effectStatusChanged(WorkOrderEffect.FetchWorkOrderHistory, AsyncStatus.error(undefined))
                    return Outcome.error(undefined)
                }

                this.workOrderhistoryLoaded(workOrderHistory.value, workOrderId)
                this.effectStatusChanged(WorkOrderEffect.FetchWorkOrderHistory, AsyncStatus.idle())
                return Outcome.ok(undefined)
            },
        })

    private workOrderhistoryLoaded = (workOrderHistory: HistoryItem<WorkOrderStatus>[], id: number) => {
        let tempWorkOrder = this.state.workOrder

        if (tempWorkOrder) tempWorkOrder.history = workOrderHistory

        this.update({ workOrder: tempWorkOrder })
    }

    fetchHistoryData = (workRequestId: number) =>
        this.effect({
            id: WorkOrderEffect.FetchWorkRequestHistory,
            block: async (job) => {
                this.effectStatusChanged(WorkOrderEffect.FetchWorkRequestHistory, AsyncStatus.busy())
                const [workRequestHistory] = await job.pause(
                    Promise.all([this.workRequestService.fetchWorkRequestHistory(workRequestId)])
                )

                if (workRequestHistory.isError()) {
                    this.effectStatusChanged(WorkOrderEffect.FetchWorkRequestHistory, AsyncStatus.error(undefined))
                    return Outcome.error(undefined)
                }

                this.workRequestHistoryLoaded(workRequestHistory.value, workRequestId)
                this.effectStatusChanged(WorkOrderEffect.FetchWorkRequestHistory, AsyncStatus.idle())
                return Outcome.ok(undefined)
            },
        })

    private workRequestHistoryLoaded = (workRequestHistory: HistoryItem<WorkRequestStatus>[], id: number) => {
        let temWorkRequests = this.state.workRequests
        temWorkRequests = temWorkRequests.map((el, i) => {
            if (el.id === id) {
                el.history = workRequestHistory
                return el
            } else {
                return el
            }
        })

        this.update({ workRequests: temWorkRequests })
    }

    fetchAssetUsageData = (assetId: number, date: Date) =>
        this.effect({
            id: WorkOrderEffect.Fetch,
            block: async (job) => {
                this.effectStatusChanged(WorkOrderEffect.Fetch, AsyncStatus.busy())

                const assetUsageOutcome = await job.pause(this.assetService.fetchAssetUsage(assetId, date))
                if (assetUsageOutcome.isError()) {
                    this.effectStatusChanged(WorkOrderEffect.Fetch, AsyncStatus.error(undefined))
                    return assetUsageOutcome
                }

                this.effectStatusChanged(WorkOrderEffect.Fetch, AsyncStatus.idle())

                this.update({
                    assetUsage: assetUsageOutcome.value,
                })

                return Outcome.ok(undefined)
            },
        })

    createManualEntry = (
        assetId: number,
        eventDate: Date,
        odometer: number,
        hourMeter: number,
        siteId: number | null
    ) => {
        this.assetService.createManualEntry(assetId, eventDate, odometer, hourMeter, siteId)
    }

    showWorkRequestDetails = (workRequestId: number) =>
        this.effect({
            id: WorkOrderEffect.Fetch,
            block: async (job) => {
                this.effectStatusChanged(WorkOrderEffect.Fetch, AsyncStatus.busy())

                let attachments: Attachment[] = []
                const workRequest = this.state.workRequests.find((i) => i.id == workRequestId)
                if (workRequest?.filesId) {
                    const atachmentsResponse = await this.workRequestService.fetchAttachments(workRequest?.filesId)
                    if (atachmentsResponse.isError()) return Outcome.error(atachmentsResponse)
                    attachments = atachmentsResponse.value
                }
                this.refreshWorkRequestAttachments(workRequestId, attachments)

                var workRequests = this.state.workRequests
                const workRequestForm = this.state.workRequestForms[workRequestId]

                workRequests = workRequests.map((it) => {
                    if (it.id == workRequestId) {
                        it.attachments = attachments
                        it.filesId = workRequest?.filesId as string
                        it.status = workRequestForm.status
                        it.workRequestStatusFlags = workRequestForm.workRequestStatusFlags
                        it.actualLaborHours = safeParseFloat(workRequestForm.actualLaborHours)
                        it.actualPartsCost = safeParseFloat(workRequestForm.actualPartsCost)
                        it.actualLaborCost = safeParseFloat(workRequestForm.actualLaborCost)
                    }
                    return it
                })

                this.update({ workRequestDetailsModal: workRequestId, workRequests: workRequests })

                this.effectStatusChanged(WorkOrderEffect.Fetch, AsyncStatus.idle())

                return Outcome.ok(undefined)
            },
        })

    hideWorkRequestDetails = () => this.update({ workRequestDetailsModal: null })

    dismissWorkOrder = (reason: string, workRequestsToDelete: number[]) =>
        this.effect({
            id: WorkOrderEffect.DismissWorkOrder,
            block: async (job) => {
                if (!this.state.workOrder?.id) {
                    return Outcome.error(undefined)
                }
                const response = await job.pause(
                    this.workOrderService.deleteWorkOrder(this.state.workOrder?.id, reason, workRequestsToDelete)
                )
                if (response.isError()) return response

                await new Promise((resolve) => setTimeout(resolve, 3000)) // wait for 3 seconds for the indexes to process the update before redirecting to the work order list page

                return Outcome.ok(undefined)
            },
        })

    workOrderDismissChanged = (reason: string, workRequestsToDelete: number[]) =>
        this.update({
            workOrderDismissal: {
                reason: reason,
                workRequestsToDelete: workRequestsToDelete,
            },
        })

    workOrderDismissCancelled = () => this.update({ workOrderDismissal: null })

    dismissWorkRequest = (
        workRequest: WorkRequest,
        reason: string,
        userId: number,
        tagChange: TagChange,
        tagReason: string
    ) =>
        this.effect({
            id: WorkOrderEffect.DismissWorkRequest,
            block: async (job) => {
                const asset = this.state.asset
                const originalTagChange = tagChangeFromTag(asset?.tag ?? null)
                var updatedAsset = null

                if (asset !== null && originalTagChange !== tagChange) {
                    updatedAsset = await job.pause(this.assetService.updateTag(asset.id, tagChange, tagReason))
                    if (updatedAsset.isError()) return updatedAsset

                    this.assetChanged(updatedAsset.value)
                }

                const response = await job.pause(
                    this.workRequestService.dismissWorkRequest(
                        workRequest.id,
                        reason,
                        userId,
                        updatedAsset ? updatedAsset.value.tag : null
                    )
                )
                if (response.isError()) return response
                this._removeWorkRequest(workRequest.id)

                return Outcome.ok(undefined)
            },
        })

    addMeterSyncRequiredFlag = (assetId: number, useCase: MeterSyncRequiredUseCases) =>
        this.effect({
            id: WorkOrderEffect.AddedAssetRequireMeterSyncFlag,
            block: async (job) => {
                const response = await job.pause(this.assetService.UpdateMeterSyncRequiredFlag(assetId, true, useCase))

                if (response.isError()) return response

                this.assetChanged(response.value)

                return Outcome.ok(undefined)
            },
        })

    assetChanged = (asset: Asset) => this.update({ asset: asset })

    workRequestToDismissChanged = (
        request: WorkRequest | null,
        change: { reason?: string; tagChange?: TagChange; tagReason?: string }
    ) =>
        this.update({
            workRequestToDismiss:
                request === null
                    ? null
                    : {
                          request: request,
                          reason: change.reason ?? this.state.workRequestToDismiss?.reason ?? "",
                          tagChange:
                              change.tagChange ??
                              this.state.workRequestToDismiss?.tagChange ??
                              tagChangeFromTag(this.state.asset?.tag ?? null),
                          tagReason:
                              change.tagReason ??
                              this.state.workRequestToDismiss?.tagReason ??
                              this.state.asset?.tag?.reason ??
                              "",
                      },
        })

    workNotPerformedReasonChanged = (request: WorkRequest | null, change: { reason?: string }) => {
        if (request)
            this.requestFormFieldChanged(request.id, (state) => {
                const statusFlagReason = state.statusFlagReason
                if (statusFlagReason === null) return {}

                return {
                    statusFlagReason: change.reason,
                }
            })
    }

    workRequestWithWorkNotPerformedSet = (
        request: WorkRequest | null,
        previousStatus: WorkRequestStatus | null,
        newStatus: WorkRequestStatus | null
    ) => {
        //Sets the workRequestWorkNotPerformed to trigger opening Reason Modal on List
        this.update({
            workRequestWorkNotPerformed:
                request === null
                    ? null
                    : {
                          request: request,
                          reason: "",
                          previousStatus: previousStatus,
                          newStatus: newStatus,
                      },
        })
    }
    removeWorkRequest = (workRequest: WorkRequest) =>
        this.effect({
            id: WorkOrderEffect.RemoveWorkRequest,
            block: async (job) => {
                await job.delay(500)

                workRequest.workOrderId = null
                var response = await this.workRequestService.updateWorkRequest(workRequest)
                if (response.isError()) return Outcome.error(undefined)

                this._removeWorkRequest(workRequest.id)
                return Outcome.ok(undefined)
            },
        })

    updateScheduleWorkRequests = (workRequests: WorkRequest[]) =>
        this.update({
            workRequestToBeAdjustedBySchedule: workRequests,
        })

    workRequestToRemoveChanged = (workRequest: WorkRequest | null) =>
        this.update({
            workRequestToRemove: workRequest,
        })

    saveForm = () =>
        this.effect({
            id: WorkOrderEffect.Save,
            block: async (job) => {
                if (!this.state.workOrder) return Outcome.error(undefined)

                if (
                    !(
                        this.state.form?.status == WorkOrderStatus.InProgress ||
                        this.state.form?.status == WorkOrderStatus.WorkCompleted ||
                        this.state.form?.status == WorkOrderStatus.Closed
                    )
                ) {
                    this.state.form.siteId = null
                    this.state.form.workPerformedById = null
                }

                if (this.state.form?.status == WorkOrderStatus.Closed) {
                    //Update tag
                    const formTagChange = tagChangeFromTagType(this.state.form.tagStatus)
                    const originalTagChange = tagChangeFromTag(this.state.asset?.tag ?? null)

                    var response = await job.pause(
                        this.workOrderService.closeWorkOrder({
                            ...this.state.workOrder,
                            assignedToPersonId:
                                this.state.form.assignedTo && this.state.form.assignedTo !== 0
                                    ? this.state.form.assignedTo
                                    : null,
                            urgency: this.state.form.urgency,
                            dueDate: this.state.form.dueDate != null ? this.state.form.dueDate : null,
                            dueHourMeter: safeParseFloat(this.state.form.dueHourMeter),
                            dueOdometer: safeParseInt(this.state.form.dueOdometer),
                            workPerformedByPersonId: this.state.form.workPerformedById,
                            siteId: this.state.form.siteId,
                            specialInstructions: this.state.form.specialInstructions,
                            serviceDateTime:
                                this.state.form.workCompletedDate != null ? this.state.form.workCompletedDate : null,
                            completedHourMeter:
                                this.state.form.completedHourMeter != ""
                                    ? safeParseFloat(this.state.form.completedHourMeter)
                                    : null,
                            completedOdometer:
                                this.state.form.completedOdometer != ""
                                    ? safeParseInt(this.state.form.completedOdometer)
                                    : null,
                            tagStatus: !(originalTagChange === formTagChange || this.state.asset === null)
                                ? this.state.form.tagStatus
                                : null,
                            reasonTagged: !(originalTagChange === formTagChange || this.state.asset === null)
                                ? this.state.form.tagReason
                                : null,
                            meterSync: this.state.form.meterSync,
                            workRequests: this.state.workRequests,
                            travelDistance: this.state.form.travelDistance,
                            travelTime: this.state.form.travelTime,
                            nightWork: this.state.form.nightWork,
                            cbaCode: { cbaCodeId: this.state.form.cbaCodeId, name: "", code: "" },
                            serviceRecord: false,
                        })
                    )

                    if (response.isError()) {
                        return Outcome.error(response)
                    }
                    //WorkOrderClose method returns a session Id, we still do not have the necesarry service to get the Work Order object using the session Id.
                    //So, we asume that the process ended correctly and emulate the response whit the properties modified on the form.

                    let workOrder = {
                        ...this.state.workOrder,
                        status: this.state.form.status,
                        assignedTo: this.state.form.assignedTo,
                        urgency: this.state.form.urgency,
                        dueDate: this.state.form.dueDate,
                        dueHourMeter:
                            this.state.form.dueHourMeter != "" ? safeParseFloat(this.state.form.dueHourMeter) : null,
                        dueOdometer:
                            this.state.form.dueOdometer != "" ? safeParseInt(this.state.form.dueOdometer) : null,
                        workPerformedById: this.state.form.workPerformedById,
                        siteId: this.state.form.siteId,
                        specialInstructions: this.state.form.specialInstructions,
                        workCompletedDate: this.state.form.workCompletedDate,
                        completedHourMeter:
                            this.state.form.completedHourMeter != ""
                                ? safeParseFloat(this.state.form.completedHourMeter)
                                : null,
                        completedOdometer:
                            this.state.form.completedOdometer != ""
                                ? safeParseInt(this.state.form.completedOdometer)
                                : null,
                        openedDate: this.state.form.openedDate,
                    }

                    if (!workOrder) return Outcome.error(undefined)

                    const responseWOH = await this.workOrderService.fetchWorkOrderHistory(workOrder?.id)
                    if (responseWOH.isError()) return Outcome.error(undefined)

                    if (workOrder) workOrder.history = responseWOH.value

                    this.update({
                        workOrder: workOrder,
                    })

                    if (originalTagChange !== formTagChange && this.state.asset != null) {
                        const [assetOutcome] = await job.pause(
                            Promise.all([this.assetService.fetchAsset(this.state.asset.id)])
                        )

                        if (assetOutcome.isError()) {
                            this.effectStatusChanged(WorkOrderEffect.Fetch, AsyncStatus.error(undefined))
                            return Outcome.error(undefined)
                        }

                        this.update({
                            asset: assetOutcome.value,
                        })
                    }
                } else {
                    var response = await job.pause(
                        this.workOrderService.updateWorkOrder({
                            ...this.state.workOrder,
                            status: this.state.form.status,
                            assignedTo: this.state.form.assignedTo,
                            urgency: this.state.form.urgency,
                            dueDate: this.state.form.dueDate,
                            dueHourMeter:
                                this.state.form.dueHourMeter != ""
                                    ? safeParseFloat(this.state.form.dueHourMeter)
                                    : null,
                            dueOdometer:
                                this.state.form.dueOdometer != "" ? safeParseInt(this.state.form.dueOdometer) : null,
                            workPerformedById: this.state.form.workPerformedById,
                            siteId: this.state.form.siteId,
                            specialInstructions: this.state.form.specialInstructions,
                            workCompletedDate: this.state.form.workCompletedDate,
                            completedHourMeter:
                                this.state.form.completedHourMeter != ""
                                    ? safeParseFloat(this.state.form.completedHourMeter)
                                    : null,
                            completedOdometer:
                                this.state.form.completedOdometer != ""
                                    ? safeParseInt(this.state.form.completedOdometer)
                                    : null,
                            openedDate: this.state.form.openedDate,
                            travelDistance: this.state.form.travelDistance,
                            travelTime: this.state.form.travelTime,
                            nightWork: this.state.form.nightWork,
                            cbaCode: { code: "", name: "", cbaCodeId: this.state.form.cbaCodeId },
                        })
                    )

                    if (response.isError()) {
                        return Outcome.error(response)
                    }

                    let workOrder = response.value

                    if (!workOrder) return Outcome.error(undefined)

                    const responseWOH = await this.workOrderService.fetchWorkOrderHistory(workOrder?.id)
                    if (responseWOH.isError()) return Outcome.error(undefined)

                    if (workOrder) workOrder.history = responseWOH.value

                    this.update({
                        workOrder: workOrder,
                    })
                }

                return Outcome.ok(response)
            },
        })

    resetForm = () =>
        this.update({
            form: newWorkOrderForm(this.state.workOrder),
        })

    saveRequestForm = (requestId: number, user: User | null) =>
        this.effect({
            id: WorkOrderEffect.SaveRequest,
            block: async (job) => {
                const workRequest = this.state.workRequests.find((it) => it.id === requestId)
                const workRequestFormData = this.state.workRequestForms[requestId]
                if (!workRequest) return Outcome.error(undefined)

                // Update tag
                const formTagChange = tagChangeFromTag(workRequestFormData.tag)

                const tagReason = workRequestFormData.tag?.reason ?? ""
                var updatedTag = null
                if (this.state.asset && this.state.asset.tag?.type !== workRequestFormData.tag?.type) {
                    const updatedAsset = await job.pause(
                        this.assetService.updateTag(this.state.asset?.id, formTagChange, tagReason)
                    )
                    if (updatedAsset.isOk()) updatedTag = updatedAsset.value.tag
                }

                const response = await job.pause(
                    this.workRequestService.updateWorkRequest({
                        ...workRequest,
                        status: workRequestFormData.status,
                        serviceCodeId: workRequestFormData.serviceCodeId,
                        urgency: workRequestFormData.urgency,
                        dueDate: workRequestFormData.dueDate,
                        dueHourMeter: safeParseFloat(workRequestFormData.dueHourMeter),
                        dueOdometer: safeParseInt(workRequestFormData.dueOdometer),
                        estimatedLaborHours: safeParseAndRoundFloat(workRequestFormData.estimatedLaborHours),
                        estimatedPartsCost: safeParseFloat(workRequestFormData.estimatedPartsCost),
                        actualLaborCost: safeParseFloat(workRequestFormData.actualLaborCost),
                        actualLaborHours: safeParseAndRoundFloat(workRequestFormData.actualLaborHours),
                        actualPartsCost: safeParseFloat(workRequestFormData.actualPartsCost),
                        statusFlagReason: workRequestFormData.statusFlagReason,
                        step: workRequestFormData.step,
                        workToBePerformed: workRequestFormData.workToBePerformed,
                        specialInstructions: workRequestFormData.specialInstructions,
                        notes: workRequestFormData.notes,
                        tag: updatedTag ?? this.state.asset?.tag ?? null,
                        notifyContacts: workRequestFormData.notifyContacts,
                        serviceType: workRequest.serviceType,
                        serviceSubTypeId:
                            this.state.asset?.company != user?.companyId ? workRequest.serviceSubTypeId : null,
                        filesId: workRequestFormData.filesId as string,
                        tasks: workRequestFormData.tasks,
                    })
                )

                if (response.isError()) return Outcome.error(response)
                this.refreshWorkRequestFilesId(requestId, workRequestFormData.filesId as string)

                this.update({
                    workRequests: this.state.workRequests.map((i) => {
                        if (i.id == response.value.id) {
                            i = response.value
                        }
                        return i
                    }),
                    workRequestForms: {
                        ...this.state.workRequestForms,
                        [requestId]: newWorkRequestForm(response.value, this.state.asset),
                    },
                })

                return Outcome.ok(response)
            },
        })

    advanceServiceRequestsToNextStep = (serviceRequests: WorkRequest[]) =>
        this.effect({
            id: WorkOrderEffect.DismissWorkRequest,
            block: async (job) => {
                if (serviceRequests.length > 0) {
                    var manualAdjustedServiceRequests = serviceRequests.map((it) => it.id)

                    const response = await job.pause(
                        this.workRequestService.advanceWorkRequestToNextScheduleStep(manualAdjustedServiceRequests)
                    )

                    if (response.isError()) return response
                }

                return Outcome.ok(undefined)
            },
        })

    saveScheduleRequestForm = (workRequests: WorkRequest[]) =>
        this.effect({
            id: WorkOrderEffect.SaveRequest,
            block: async (job) => {
                var arrayOfExecution: Promise<Outcome<WorkRequest, unknown>>[] = []

                workRequests.forEach(async (workRequest) => {
                    arrayOfExecution.push(this.workRequestService.updateWorkRequest(workRequest))
                })

                await job.pause(Promise.all(arrayOfExecution))

                return Outcome.ok(undefined)
            },
        })

    fetchAndRefreshWorkRequestHistory = (requestId: number) =>
        this.effect({
            id: WorkOrderEffect.SaveRequest,
            block: async (job) => {
                const HistoryResponse = await job.pause(this.workRequestService.fetchWorkRequestHistory(requestId))

                if (HistoryResponse.isError()) return Outcome.error(HistoryResponse)

                this.refreshWorkRequestHistory(requestId, HistoryResponse.value)

                return Outcome.ok(HistoryResponse)
            },
        })

    subscribeUser = (requestId: number, personID: number) =>
        this.effect({
            id: WorkOrderEffect.SaveRequest,
            block: async (job) => {
                const workRequest = this.state.workRequests.find((it) => it.id === requestId)
                const workRequestFormData = this.state.workRequestForms[requestId]

                workRequestFormData.notifyContacts.push(personID)

                if (!workRequest) return Outcome.error(undefined)

                const response = await job.pause(
                    this.workRequestService.updateWorkRequest({
                        ...workRequest,
                        notifyContacts: workRequestFormData.notifyContacts,
                    })
                )

                if (response.isError()) return Outcome.error(response)
                this.refreshWorkRequestFilesId(requestId, workRequestFormData.filesId as string)

                this.update({
                    workRequests: this.state.workRequests.map((i) => {
                        if (i.id == response.value.id) {
                            i = response.value
                        }
                        return i
                    }),
                    workRequestForms: {
                        ...this.state.workRequestForms,
                        [requestId]: newWorkRequestForm(response.value, this.state.asset),
                    },
                })

                var HistoryResponse = await job.pause(this.workRequestService.fetchWorkRequestHistory(workRequest.id))

                if (HistoryResponse.isError()) return Outcome.error(HistoryResponse)

                //This was added to refresh the History on the workRequest state.
                this.refreshWorkRequestHistory(response.value.id, HistoryResponse.value)

                return Outcome.ok(response)
            },
        })

    refreshWorkRequestHistory = (id: number, history: HistoryItem<WorkRequestStatus>[]) => {
        var workRequests = this.state.workRequests
        this.update({
            workRequests: workRequests.map((it) => {
                if (it.id == id) it.history = history

                return it
            }),
        })
    }

    refreshWorkRequestFilesId = (id: number, filesId: string) => {
        var workRequests = this.state.workRequests
        this.update({
            workRequests: workRequests.map((it) => {
                if (it.id == id) it.filesId = filesId
                return it
            }),
        })
    }

    refreshWorkRequestAttachments = (workRequestId: number, attachments: Attachment[]) => {
        const workRequest = this.state.workRequests.find((it) => it.id === workRequestId)
        if (!workRequest) return this.state
        workRequest.attachments = attachments
        return this.update({
            workRequestForms: {
                ...this.state.workRequestForms,
                [workRequestId]: {
                    ...this.state.workRequestForms[workRequestId],
                    attachments: attachments,
                },
            },
        })
    }

    clearWorkRequestAttachmentsOnCancel = (workRequestId: number) => {
        const workRequestForm = this.state.workRequestForms[workRequestId]
        if (workRequestForm.filesId && workRequestForm.newAttachmentsNames.length > 0) {
            let filesId: string = workRequestForm.filesId
            this.effect({
                id: this.clearWorkRequestAttachmentsOnCancel,
                block: async (job) => {
                    return await job.pause(
                        this.workRequestService.deleteAttachments(filesId, workRequestForm.newAttachmentsNames)
                    )
                },
            })
        }
    }

    workRequestAttachmentsChanged = async (workRequestId: number, files: File[], userId: number, companyId: number) => {
        const workRequestForm = this.state.workRequestForms[workRequestId]
        workRequestForm.newAttachmentsNames = files
            .filter((fileToUpload) => {
                return workRequestForm.attachments.findIndex((file) => file.name == fileToUpload.name) == -1
            })
            .map((i) => i.name)

        if (!workRequestForm.filesId) {
            workRequestForm.filesId = uuidv4()
        }

        try {
            const response = await this.workRequestService.uploadAttachments(
                workRequestForm.filesId,
                files,
                userId,
                companyId
            )
            if (response.isOk()) {
                this.refreshWorkRequestAttachments(workRequestId, workRequestForm.attachments.concat(response.value))
                this.requestFormFieldChanged(workRequestId, (state) => ({ filesId: workRequestForm.filesId }))
                this.requestFormFieldChanged(workRequestId, (state) => ({
                    newAttachmentsNames: workRequestForm.newAttachmentsNames,
                }))
            }
            return response
        } catch (error) {
            console.error("Error in workRequestAttachmentsChanged:", error)
            return error
        }
    }

    workRequestAttachmentsDelete = (
        workRequestId: number,
        attachmentName: string,
        userId: number,
        companyId: number
    ) => {
        const workRequestForm = this.state.workRequestForms[workRequestId]
        this.effect({
            id: this.workRequestAttachmentsDelete,
            block: async (job) => {
                if (!attachmentName || !workRequestForm.filesId) return Outcome.error(undefined)
                const response = await job.pause(
                    this.workRequestService.deleteAttachments(workRequestForm.filesId, [attachmentName])
                )
                if (response.isOk()) {
                    workRequestForm.newAttachmentsNames = workRequestForm.newAttachmentsNames.filter(
                        (i) => i != attachmentName
                    )
                    this.refreshWorkRequestAttachments(
                        workRequestId,
                        workRequestForm.attachments.filter((i) => i.name != attachmentName)
                    )
                    this.requestFormFieldChanged(workRequestId, (state) => ({
                        newAttachmentsNames: workRequestForm.newAttachmentsNames,
                    }))
                }
                return response
            },
        })
    }

    rotateImage = (attachmentName: string, angle: number, userId: number, companyId: number, workRequestId: number) => {
        const workRequestForm = this.state.workRequestForms[workRequestId]
        this.effect({
            id: this.rotateImage,
            block: async (job) => {
                if (!attachmentName || !workRequestForm.filesId) return Outcome.error(undefined)
                const response = await job.pause(
                    this.workRequestService.rotateImage(workRequestForm.filesId, attachmentName, angle)
                )
                if (response.isOk()) {
                    let attachments = JSON.parse(JSON.stringify(workRequestForm.attachments))
                    let modified = attachments.find(
                        (x: any) => x.name.toLowerCase().trim() === attachmentName.toLowerCase().trim()
                    )
                    modified.url = modified.url.split("&rotated=")[0] + "&rotated=" + new Date().getTime()

                    this.requestFormFieldChanged(workRequestId, (state) => ({
                        attachments: [...attachments, { ...modified }],
                    }))
                    this.requestFormFieldChanged(workRequestId, (state) => ({
                        attachments: [...attachments],
                    }))
                }
                return response
            },
        })
    }

    resetRequestForm = (requestId: number) => {
        const workRequest = this.state.workRequests.find((it) => it.id === requestId)
        if (!workRequest) return this.state

        return this.update({
            workRequestForms: {
                ...this.state.workRequestForms,
                [requestId]: newWorkRequestForm(workRequest, this.state.asset),
            },
        })
    }

    statusChanged = (status: WorkOrderStatus) => this.formFieldChanged({ status: status })

    openedDateChanged = (openedDate: Date) => this.formFieldChanged({ openedDate: openedDate })

    assignedToChanged = (contactId: number) => this.formFieldChanged({ assignedTo: contactId })

    workPerformedByChanged = (contactIds: number[]) => this.formFieldChanged({ workPerformedById: contactIds })

    siteIdChanged = (siteId: number) => this.formFieldChanged({ siteId: siteId })

    specialInstructionsChanged = (specialInstructions: string) =>
        this.formFieldChanged({ specialInstructions: specialInstructions })

    cbaCodeChanged = (cbaCodeId: number) => this.formFieldChanged({ cbaCodeId: cbaCodeId })

    travelTimeChanged = (travelTime: string) => this.formFieldChanged({ travelTime: travelTime })

    travelDistanceChanged = (travelDistance: string) => this.formFieldChanged({ travelDistance: travelDistance })

    nightWorkChanged = (nightWork: boolean) => this.formFieldChanged({ nightWork: nightWork })

    urgencyChanged = (urgency: Urgency) => this.formFieldChanged({ urgency: urgency })

    dueDateChanged = (dueDate: Date) => this.formFieldChanged({ dueDate: dueDate })

    dueOdometerChanged = (dueOdometer: string) => this.formFieldChanged({ dueOdometer: dueOdometer })

    dueHourMeterChanged = (dueHourMeter: string) => this.formFieldChanged({ dueHourMeter: dueHourMeter })

    completedHourMeterChanged = (completedHourMeter: string) =>
        this.formFieldChanged({ completedHourMeter: completedHourMeter })

    workCompletedDateChanged = (workCompletedDate: Date) =>
        this.formFieldChanged({ workCompletedDate: workCompletedDate })

    completedOdometerChanged = (completedOdometer: string) =>
        this.formFieldChanged({ completedOdometer: completedOdometer })

    requestStatusChanged = (requestId: number, status: WorkRequestStatus) =>
        this.requestFormFieldChanged(requestId, (state) => ({ status: status }))

    requestStatusFlagReasonChanged = (requestId: number, statusFlagReason: string) =>
        this.requestFormFieldChanged(requestId, (state) => ({ statusFlagReason: statusFlagReason }))

    requestStepChanged = async (requestId: number, value: Step) => {
        this.requestFormFieldChanged(requestId, (state) => ({ step: value, workToBePerformed: value?.label }))

        let unmodifiedWorkRequest = this.state.workRequests.find((wr) => wr.id === requestId)

        // When WR is not added to a WO, it should get the tasks from the service schedule step
        if (value && unmodifiedWorkRequest) {
            if (unmodifiedWorkRequest.step?.id != value.id) {
                let tasks = await this.workRequestService.fetchServiceScheduleStepTasks(
                    value.id,
                    unmodifiedWorkRequest.workRequestType
                )

                this.requestFormFieldChanged(requestId, (state) => ({ tasks: tasks }))
            } else if (unmodifiedWorkRequest.step?.id == value.id) {
                this.requestFormFieldChanged(requestId, (state) => ({ tasks: unmodifiedWorkRequest?.tasks }))
            }
        }
    }

    requestUrgencyChanged = (requestId: number, urgency: Urgency) =>
        this.requestFormFieldChanged(requestId, (state) => ({ urgency: urgency }))

    requestDueDateChanged = (requestId: number, date: Date) =>
        this.requestFormFieldChanged(requestId, (state) => ({ dueDate: date }))

    requestDueHourMeterChanged = (requestId: number, cost: string) =>
        this.requestFormFieldChanged(requestId, (state) => ({ dueHourMeter: cost }))

    requestDueOdometerChanged = (requestId: number, cost: string) =>
        this.requestFormFieldChanged(requestId, (state) => ({ dueOdometer: cost }))

    requestBillingCodeChanged = (requestId: number, value: BillingCode) =>
        this.requestFormFieldChanged(requestId, (state) => ({ billingCodeId: value.id }))

    requestServiceCodeChanged = (requestId: number, value: ServiceCode | null) =>
        this.requestFormFieldChanged(requestId, (state) => ({ serviceCodeId: value?.id ?? null }))

    requestManualAdjustmentChanged = (requestId: number, value: boolean) =>
        this.requestFormFieldChanged(requestId, (state) => ({ manualAdjustment: value }))

    requestAdvanceNextStepChanged = (requestId: number, value: boolean) =>
        this.requestFormFieldChanged(requestId, (state) => ({ advanceNextStep: value }))

    requestServiceTypeChanged = (requestId: number, serviceType: ServiceRequestType) =>
        this.requestFormFieldChanged(requestId, (state) => ({ serviceType }))

    requestActualLaborHoursChanged = (requestId: number, hours: string) =>
        this.requestFormFieldChanged(requestId, (state) => ({ actualLaborHours: hours }))

    requestEstimatedPartsCostChanged = (requestId: number, cost: string) =>
        this.requestFormFieldChanged(requestId, (state) => ({ estimatedPartsCost: cost }))

    requestEstimatedLaborHoursChanged = (requestId: number, hours: string) =>
        this.requestFormFieldChanged(requestId, (state) => ({ estimatedLaborHours: hours }))

    requestActualLaborCostChanged = (requestId: number, cost: string) =>
        this.requestFormFieldChanged(requestId, (state) => ({ actualLaborCost: cost }))

    requestActualPartsCostChanged = (requestId: number, cost: string) =>
        this.requestFormFieldChanged(requestId, (state) => ({ actualPartsCost: cost }))

    requestSpecialInstructionsChanged = (requestId: number, value: string) =>
        this.requestFormFieldChanged(requestId, (state) => ({ specialInstructions: value }))

    requestNotesChanged = (requestId: number, value: string) =>
        this.requestFormFieldChanged(requestId, (state) => ({ notes: value }))

    requestWorkToBePerformedChanged = (requestId: number, value: string) =>
        this.requestFormFieldChanged(requestId, (state) => ({ workToBePerformed: value }))

    requestNotifyContactChanged = (requestId: number, contactIds: number[]) =>
        this.requestFormFieldChanged(requestId, (state) => ({ notifyContacts: contactIds }))

    adjustServiceRequestModalVisibleChanged = (value: boolean) =>
        this.update({ adjustServiceRequestModalVisible: value })

    serviceRequestHasBeenAdjustedChanged = (value: boolean) => this.update({ serviceRequestHasBeenAdjusted: value })

    tagStatusChanged = (tagStatus: TagType) => this.formFieldChanged({ tagStatus: tagStatus })

    tagReasonChanged = (tagReason: string) => this.formFieldChanged({ tagReason: tagReason })

    showTagUpdate = (showTagUpdate: boolean) => this.update({ showTagUpdate: showTagUpdate })

    meterSyncUpdate = (meterSync: boolean) => this.formFieldChanged({ meterSync: meterSync })

    showSyncDoneAfterServiceDateModal = (showSyncDoneAfterServiceDate: boolean) =>
        this.update({ showSyncDoneAfterServiceDateModal: showSyncDoneAfterServiceDate })

    hasBeenShownSyncDoneAfterServiceDateModal = (value: boolean) =>
        this.update({ hasBeenShownSyncDoneAfterServiceDateModal: value })

    showSyncMeters = (showSyncMeter: boolean) => this.update({ showSyncMeter: showSyncMeter })

    syncMetersBeenShown = (syncMeterShown: boolean) => this.update({ syncMeterShown: syncMeterShown })

    tagUpdateBeenShown = (tagUpdateShown: boolean) => this.update({ tagUpdateShown: tagUpdateShown })

    requestTaskChanged = (requestId: number, taskId: number, value: TaskValue, measurement?: string) =>
        this.requestFormFieldChanged(requestId, (state) => ({
            tasks: state.tasks?.map((it) => {
                if (it.id !== taskId) return it
                return { ...it, value, measurement }
            }),
        }))

    requestSelectAllTasksToggled = (requestId: number) =>
        this.requestFormFieldChanged(requestId, (state): Partial<WorkOrderWorkRequestForm> => {
            const request = this.state.workRequests.find((it) => it.id === requestId)
            if (!request) return {}

            const allCompleted = state.tasks?.every(
                (it) => it.value === CompletableTaskValue.Performed || it.value === InspectionTaskValue.Pass
            )

            return {
                tasks: state.tasks?.map((it) => ({
                    ...it,
                    value: (() => {
                        if (request.workRequestType === WorkRequestType.Inspection) {
                            return !allCompleted ? InspectionTaskValue.Pass : InspectionTaskValue.NotPerformed
                        } else {
                            return !allCompleted ? CompletableTaskValue.Performed : CompletableTaskValue.NotPerformed
                        }
                    })(),
                })),
            }
        })

    requestTagReasonChanged = (requestId: number, value: string) =>
        this.requestFormFieldChanged(requestId, (state) => {
            const tag = state.tag
            if (tag === null) return {}

            return {
                tag: {
                    ...tag,
                    reason: value,
                },
            }
        })

    requestTagTypeChanged = (requestId: number, value: TagType | null) => {
        this.requestFormFieldChanged(requestId, (state) => {
            const tag = state.tag
            if (value === null) return { tag: null }

            return {
                tag: {
                    type: value,
                    dateTagged: tag?.type !== value ? new Date() : tag?.dateTagged,
                    daysTagged: tag?.type !== value ? 0 : tag?.daysTagged,
                    reason: tag?.reason ?? "",
                },
            }
        })
        if (value == this.state.asset!.tag?.type) {
            //Discard tag reason changes in case the user updates the reason and come back to the original state.
            this.requestTagReasonChanged(requestId, this.state.asset!.tag?.reason ?? "")
        }
    }

    requestNotifyContactsChanged = (requestId: number, value: Contact[]) =>
        this.requestFormFieldChanged(requestId, (state) => ({
            notifyContacts: value.map((it) => it.id),
        }))

    toggleShowTaskDescriptions = () => this.update({ showTaskDescriptions: !this.state.showTaskDescriptions })

    renderCreateServiceRequest = (render: boolean) => this.update({ renderCreateServiceRequest: render })

    showPartsList = (show: boolean) => this.update({ showPartsList: show })

    showMeterSyncInfo = (show: boolean) => this.update({ showMeterSyncInfo: show })

    isWorkRequestFormChanged(requestId: number): boolean {
        const workRequest = this.state.workRequests.find((it) => it.id === requestId)
        if (!workRequest) return false
        return !isEqual(this.state.workRequestForms[requestId], newWorkRequestForm(workRequest, this.state.asset))
    }

    ClosedWorkOrderEditModeChanged = (editMode: boolean) => this.formFieldChanged({ closedWorkOrderEditMode: editMode })

    private formFieldChanged = (form: Partial<WorkOrderForm>) =>
        this.update({
            form: {
                ...this.state.form,
                ...form,
            },
        })

    private requestFormFieldChanged = (
        requestId: number,
        block: (state: WorkOrderWorkRequestForm) => Partial<WorkOrderWorkRequestForm>
    ) => {
        const form = this.state.workRequestForms[requestId]
        if (!form) return this.state

        return this.update({
            workRequestForms: {
                ...this.state.workRequestForms,
                [requestId]: {
                    ...form,
                    ...block(form),
                },
            },
        })
    }

    private effectStatusChanged = (effect: WorkOrderEffect, status: AsyncStatus) =>
        this.update({
            effectStatus: {
                ...this.state.effectStatus,
                [effect]: status,
            },
        })

    private _removeWorkRequest = (workRequestId: number) =>
        this.update({
            workRequests: this.state.workRequests.filter((it) => it.id !== workRequestId),
            workRequestForms: (() => {
                const copy: Record<number, WorkOrderWorkRequestForm> = { ...this.state.workRequestForms }
                delete copy[workRequestId]
                return copy
            })(),
        })

    workOrderToPrintChanged = (workOrderId: number | null) =>
        this.update({
            workOrderToPrint: workOrderId,
        })

    printWorkOrder = async (
        workOrderPrintOption: WorkOrderPrintOption,
        contacts: Contact[],
        serviceCodes: ServiceCode[],
        sites: Site[]
    ) => {
        let pdfAsset: PDFAsset | null
        let pdfWorkOrder: PDFWorkOrder
        let pdfAssetParts: PDFAssetPart[] | undefined = undefined
        let pdfCompanyName: string = ""
        this.update({
            workOrderToPrintIsLoading: true,
        })

        const workOrderOutcome = await this.workOrderService.fetchWorkOrder(this.state.workOrderToPrint!)
        if (workOrderOutcome.isError()) {
            return workOrderOutcome
        }

        const assetOutcome = await this.assetService.fetchAsset(workOrderOutcome.value.assetId)
        if (assetOutcome.isError()) {
            return assetOutcome
        }
        pdfAsset = pdfAssetFromModel(assetOutcome.value)
        pdfCompanyName = assetOutcome.value.companyName
        if (
            workOrderPrintOption == WorkOrderPrintOption.Summary ||
            workOrderPrintOption == WorkOrderPrintOption.WorkOrder ||
            workOrderPrintOption == WorkOrderPrintOption.SummaryWithParts ||
            workOrderPrintOption == WorkOrderPrintOption.WorkOrderWithParts
        ) {
            const workRequestsOutcome = await this.workRequestService.fetchWorkRequestsForWorkOrder(
                workOrderOutcome.value.id
            )
            if (workRequestsOutcome.isError()) {
                return workRequestsOutcome
            }
            pdfWorkOrder = pdfWorkOrderFromModels(
                workOrderOutcome.value,
                workRequestsOutcome.value,
                contacts,
                serviceCodes,
                sites
            )
        } else {
            pdfWorkOrder = pdfWorkOrderFromModels(workOrderOutcome.value, [], contacts, serviceCodes, sites)
        }

        if (
            workOrderPrintOption == WorkOrderPrintOption.Parts ||
            workOrderPrintOption == WorkOrderPrintOption.SummaryWithParts ||
            workOrderPrintOption == WorkOrderPrintOption.WorkOrderWithParts
        ) {
            const assetPartsOutcome = await this.assetService.fetchParts(assetOutcome.value.id)
            if (assetPartsOutcome.isError()) {
                return assetPartsOutcome
            }

            pdfAssetParts = assetPartsOutcome.value.map(pdfAssetPartFromModel)
        }

        this.update({
            pdfAsset: pdfAsset,
            pdfWorkOrder: pdfWorkOrder,
            pdfAssetParts: pdfAssetParts,
            pdfCompanyName: pdfCompanyName,
            selectedPrintOption: workOrderPrintOption,
            workOrderToPrintIsLoading: false,
            workOrderToPrint: null, // This hides the printing options dialog
        })

        return Outcome.ok(undefined)
    }

    loadParts = async (assetId: number) => {
        let pdfAssetParts: PDFAssetPart[] | undefined = undefined

        this.update({
            partsListIsLoading: true,
        })

        const assetPartsOutcome = await this.assetService.fetchParts(assetId)
        if (assetPartsOutcome.isError()) {
            return assetPartsOutcome
        }

        this.update({
            AssetParts: assetPartsOutcome.value,
            partsListIsLoading: false,
        })

        return Outcome.ok(undefined)
    }

    showRollbackModal = async () => {
        this.update({ rollbackModalVisible: true })
        this.update({ rollbackModalIsLoading: true })

        // Check if the asset DOES NOT have another Work Order with a more recent ClosedDate
        // AND if the asset DOES NOT have any other non-Closed Work Orders.
        const workOrdersOutcome = await this.workOrderService.fetchWorkOrdersForAsset(this.state.asset!.id)
        if (workOrdersOutcome.isError()) {
            return workOrdersOutcome
        }

        let nonClosedworkOrders = workOrdersOutcome.value.filter((item) => {
            return (
                item.id != this.state.workOrder?.id &&
                item.status != WorkOrderStatus.Closed &&
                item.status != WorkOrderStatus.Deleted
            )
        })

        let workOrdersWithMoreRecentClosedDate = workOrdersOutcome.value.filter((item) => {
            return (
                item.id != this.state.workOrder?.id &&
                item.closedDate &&
                item.closedDate > this.state.workOrder?.closedDate!
            )
        })

        // Check if the asset DOES NOT have a Meter Sync record with a more recent date than the Work Order to be rolled back
        const meterSyncRecordsOutcome = await this.assetService.fetchMeterSyncRecords(this.state.asset!.id)
        if (meterSyncRecordsOutcome.isError()) {
            return meterSyncRecordsOutcome
        }

        let meterSyncRecordsWithMoreRecentDate = meterSyncRecordsOutcome.value.filter((item) => {
            return (
                item.workOrderId != this.state.workOrder?.id &&
                item.datePerformedUTC > this.state.workOrder?.closedDate!
            )
        })

        if (
            nonClosedworkOrders.length > 0 ||
            workOrdersWithMoreRecentClosedDate.length > 0 ||
            meterSyncRecordsWithMoreRecentDate.length > 0
        ) {
            this.update({ rollbackModalCanPerformRollback: false })
        } else {
            this.update({ rollbackModalCanPerformRollback: true })
            const meterSyncRecordOutcome = await this.assetService.fetchMeterSyncRecord(
                this.state.asset!.id,
                this.state.workOrder!.id
            )
            if (meterSyncRecordOutcome.isError()) {
                return meterSyncRecordOutcome
            }

            this.update({ meterSyncRecordForRollback: [meterSyncRecordOutcome.value] })
            const workRequestsOutcome = await this.workRequestService.fetchWorkRequestsForAsset(this.state.asset!.id)

            if (workRequestsOutcome.isError()) {
                return workRequestsOutcome
            }

            this.update({
                workRequestToRemoveWhenRollback: workRequestsOutcome.value.filter((item) => {
                    return item.dateCreated > (this.state.workOrder?.closedDate ?? new Date(0))
                }),
            })
        }

        this.update({ rollbackModalIsLoading: false })

        return Outcome.ok(undefined)
    }

    workOrderRollbackModalConfirmed = async () =>
        this.effect({
            id: WorkOrderEffect.Save,
            block: async (job) => {
                this.hideRollbackModal()
                this.effectStatusChanged(WorkOrderEffect.Fetch, AsyncStatus.busy())

                const workOrderRollbackOutcome = await this.workOrderService.rollBackWorkOrder(
                    this.state.workOrder?.id!,
                    this.state.meterSyncRecordForRollback[0].meterSyncRecordId
                )
                if (workOrderRollbackOutcome.isError()) {
                    return workOrderRollbackOutcome
                }

                setTimeout(() => {
                    this.fetchData(this.state.workOrder?.id!)
                }, 5000)

                return Outcome.ok(undefined)
            },
        })

    hideRollbackModal = () => {
        this.update({ rollbackModalVisible: false })
        this.update({ rollbackModalIsLoading: false })
    }

    checkHaveBeenSyncBeforeServiceDate = () =>
        this.effect({
            id: WorkOrderEffect.Fetch,
            block: async (job) => {
                if (!this.state.workOrder) return Outcome.error(undefined)

                let response = await job.pause(
                    this.assetService.fetchMeterSyncRecordsAfterDate(
                        this.state.workOrder.assetId,
                        this.state.form.workCompletedDate!
                    )
                )
                if (response.isError()) {
                    return Outcome.error(response)
                }

                return Outcome.ok(response)
            },
        })

    checkHaveScheduleAssociation = () =>
        this.effect({
            id: WorkOrderEffect.Fetch,
            block: async (job) => {
                if (!this.state.workOrder) return Outcome.error(undefined)

                let response = await job.pause(
                    this.serviceQuoteService.getServiceQuotAssociatedSchedules(this.state.workOrder.id)
                )
                if (response.isError()) {
                    return Outcome.error(response)
                }

                return Outcome.ok(response.value)
            },
        })

    fetchWorkRequestBySchedule = (schuduleIds: number[]) =>
        this.effect({
            id: WorkOrderEffect.Fetch,
            block: async (job) => {
                if (!this.state.workOrder) return Outcome.error(undefined)

                let response = await job.pause(
                    this.workRequestService.fetchWorkRequestByAssetAndSchedule(
                        this.state.workOrder.assetId,
                        schuduleIds
                    )
                )

                return response
            },
        })
}

function newWorkOrderViewState(): WorkOrderScreenState {
    return {
        effectStatus: arrayToObject(Object.values(WorkOrderEffect), (it) => [it, AsyncStatus.idle()]),
        workOrder: null,
        asset: null,
        form: newWorkOrderForm(),
        workRequestForms: {},
        formHasChanged: false,
        workRequests: [],
        showTaskDescriptions: true,
        workRequestToRemove: null,
        workRequestToDismiss: null,
        workRequestWorkNotPerformed: null,
        workOrderDismissal: null,
        workRequestDetailsModal: null,
        renderCreateServiceRequest: false,
        assetUsage: null,
        showSyncMeter: false,
        showTagUpdate: false,
        syncMeterShown: false,
        tagUpdateShown: false,
        workOrderToPrint: null,
        workOrderToPrintIsLoading: false,
        showPartsList: false,
        showMeterSyncInfo: false,
        partsListIsLoading: false,
        showSyncDoneAfterServiceDateModal: false,
        latestAssetUsage: null,
        rollbackModalVisible: false,
        rollbackModalIsLoading: false,
        meterSyncRecordForRollback: [],
        workRequestToRemoveWhenRollback: [],
        hasBeenShownSyncDoneAfterServiceDateModal: false,
        rollbackModalCanPerformRollback: false,
        adjustServiceRequestModalVisible: false,
        serviceRequestHasBeenAdjusted: false,
        workRequestToBeAdjustedBySchedule: [],
    }
}

function newWorkOrderForm(workOrder?: WorkOrder | null, asset?: Asset | null): WorkOrderForm {
    return {
        status: workOrder?.status ?? WorkOrderStatus.Pending,
        assignedTo: workOrder?.assignedTo ?? 0,
        urgency: workOrder?.urgency ?? Urgency.Medium,
        dueDate: workOrder?.dueDate ?? null,
        dueHourMeter: workOrder?.dueHourMeter !== null ? Strings.formatDecimal(workOrder?.dueHourMeter ?? 0) : "",
        dueOdometer: workOrder?.dueOdometer !== null ? Strings.formatInteger(workOrder?.dueOdometer ?? 0) : "",
        specialInstructions: workOrder?.specialInstructions ?? "",
        workPerformedById: workOrder?.workPerformedById ?? null,
        siteId: workOrder?.siteId ?? null,
        workCompletedDate: workOrder?.workCompletedDate ?? null,
        completedHourMeter:
            workOrder?.completedHourMeter !== null && workOrder?.completedHourMeter !== undefined
                ? Strings.formatDecimal(workOrder?.completedHourMeter ?? 0)
                : "",
        completedOdometer:
            workOrder?.completedOdometer !== null && workOrder?.completedOdometer !== undefined
                ? Strings.formatInteger(workOrder?.completedOdometer ?? 0)
                : "",
        openedDate: workOrder?.openedDate ?? null,
        tagStatus: asset?.tag?.type ?? TagType.None,
        tagReason: asset?.tag?.reason ?? "",
        meterSync: false,
        closedWorkOrderEditMode: false,
        travelDistance: workOrder?.travelDistance ?? null,
        travelTime: workOrder?.travelTime ?? null,
        nightWork: workOrder?.nightWork ?? false,
        cbaCodeId: workOrder?.cbaCode?.cbaCodeId ?? -1,
    }
}

function newWorkRequestForm(workRequest: WorkRequest, asset: Asset | null): WorkOrderWorkRequestForm {
    return {
        status: workRequest.status,
        tasks: workRequest.tasks,
        notifyContacts: workRequest.notifyContacts,
        urgency: workRequest?.urgency ?? Urgency.Medium,
        dueDate: workRequest.dueDate,
        dueHourMeter: workRequest?.dueHourMeter !== null ? Strings.formatDecimal(workRequest?.dueHourMeter ?? 0) : "",
        dueOdometer: workRequest?.dueOdometer !== null ? Strings.formatInteger(workRequest?.dueOdometer ?? 0) : "",
        estimatedPartsCost: Strings.formatMoney(workRequest.estimatedPartsCost),
        estimatedLaborHours: Strings.formatFloat(workRequest.estimatedLaborHours),
        actualLaborCost: Strings.formatMoney(workRequest.actualLaborCost),
        actualLaborHours: Strings.formatFloat(workRequest.actualLaborHours),
        actualPartsCost: Strings.formatMoney(workRequest.actualPartsCost),
        actualTotalCost: Strings.formatMoney(workRequest.actualLaborCost + workRequest.actualPartsCost),
        attachments: workRequest.attachments,
        specialInstructions: workRequest.specialInstructions,
        notes: workRequest.notes,
        workToBePerformed: workRequest.workToBePerformed,
        billingCodeId: workRequest.billingCodeId,
        step: workRequest.step,
        schedule: workRequest.schedule,
        workRequestType: workRequest.workRequestType,
        tag: asset?.tag ?? null,
        serviceCodeId: workRequest.serviceCodeId,
        serviceType: workRequest.serviceType,
        statusFlagReason: workRequest.statusFlagReason,
        filesId: workRequest.filesId,
        hadFilesId: workRequest.filesId != null && workRequest.filesId.length > 0 ? true : false,
        newAttachmentsNames: [],
        workRequestStatusFlags: workRequest.workRequestStatusFlags,
        manualAdjustment: false,
        advanceNextStep: false,
        adjustedDueDate: null,
        adjustedDueHourMeter: "",
        adjustedDueOdometer: "",
    }
}
