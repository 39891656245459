export type Task = {
    id: number
    label: string
    value: TaskValue
    measurement?: string
    description: string
}

export type TaskValue = CompletableTaskValue | InspectionTaskValue

export enum CompletableTaskValue {
    NA = "N/A",
    Performed = "Completed",
    NotPerformed = "Not Performed",
}

export enum InspectionTaskValue {
    NA = "N/A",
    NotPerformed = "Not Performed",
    Fail = "Fail",
    Pass = "Pass",
}

export function inspectionTaskValueForValue(value: string): InspectionTaskValue {
    switch (value) {
        case InspectionTaskValue.NA:
            return InspectionTaskValue.NA
        case InspectionTaskValue.NotPerformed:
            return InspectionTaskValue.NotPerformed
        case InspectionTaskValue.Pass:
            return InspectionTaskValue.Pass
        case InspectionTaskValue.Fail:
            return InspectionTaskValue.Fail
        default:
            return InspectionTaskValue.NotPerformed
    }
}
export function completableTaskValueForValue(value: string): CompletableTaskValue {
    switch (value) {
        case CompletableTaskValue.NA:
            return CompletableTaskValue.NA
        case CompletableTaskValue.Performed:
        case "Performed":
        case "Completed":
            return CompletableTaskValue.Performed
        case CompletableTaskValue.NotPerformed:
            return CompletableTaskValue.NotPerformed
        default:
            return CompletableTaskValue.NotPerformed
    }
}

export function taskValueToApi(value: string): number {
    switch (value) {
        case InspectionTaskValue.NA:
            return 3
        case InspectionTaskValue.NotPerformed:
            return 0
        case InspectionTaskValue.Pass:
            return 1
        case InspectionTaskValue.Fail:
            return 2
        case CompletableTaskValue.Performed:
        case "Performed":
        case "Completed":
            return 5
        case CompletableTaskValue.NotPerformed:
            return 4
        default:
            return 0
    }
}

export function taskValueFromApi(value: number): string {
    switch (value) {
        case 3:
            return InspectionTaskValue.NA
        case 0:
            return InspectionTaskValue.NotPerformed
        case 1:
            return InspectionTaskValue.Pass
        case 2:
            return InspectionTaskValue.Fail
        case 5:
            return CompletableTaskValue.Performed
        case 4:
            return CompletableTaskValue.NotPerformed
        default:
            return InspectionTaskValue.NotPerformed
    }
}
