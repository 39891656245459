import { Job } from "@ethossoftworks/job"
import { BlocCoordinator } from "@lib/bloc/BlocCoordinator"
import { CompanyAlias } from "@model/company/CompanyAlias"
import { Contact, labelForContact } from "@model/contacts/Contact"
import { Filter, FilterUpdateType } from "@model/filters/Filter"
import { createFilterGroupFromPreset, FilterPresetType } from "@model/filters/FilterPresets"
import { ServiceQuoteFilter, ServiceQuotesTableColumn, ServiceQuoteTableRow } from "@model/serviceQuotes/ServiceQuote"
import { ServiceQuoteFilterDefinitions } from "@model/serviceQuotes/ServiceQuoteFilterDefinitions"
import { User } from "@model/user/User"
import { CompanyBloc, CompanyState } from "@state/company/CompanyBloc"
import { FilterOverlayBlocCoordinatorMixin } from "@state/filters/FilterOverlayBlocCoordinatorMixin"
import { ServiceQuotesFilterOverlayBloc } from "@state/serviceQuotes/ServiceQuotesFilterOverlayBloc"
import { ServiceQuotesTableBloc, ServiceQuotesTableState } from "@state/serviceQuotes/ServiceQuotesTableBloc"
import { ServiceQuoteVerifyAssetBloc } from "@state/serviceQuotes/ServiceQuoteVerifyAssetBloc"
import { SortableTableBlocEffect } from "@state/SortableTableBloc"
import { StatusBloc } from "@state/StatusBloc"
import { UserBloc } from "@state/user/UserBloc"
import { UserPreferencesBloc, UserPreferencesState } from "@state/UserPreferencesBloc"
/**
 * Service Quotes Table ViewModel
 */
type Dependencies = [UserPreferencesState, ServiceQuotesTableState, CompanyState]

type StateSelection = {
    aliases: Record<CompanyAlias, string>
    columns: ServiceQuotesTableColumn[]
    availableColumns: ServiceQuotesTableColumn[]
    isTableDataLoading: boolean
    user: User
    hasUserWorkRequest: boolean
    companyLogoUrl: string | null
} & ServiceQuotesTableState

export class ServiceQuotesTableViewModel extends BlocCoordinator<Dependencies, StateSelection> {
    private filterOverlayMixin: FilterOverlayBlocCoordinatorMixin<ServiceQuoteFilter>
    private isMobile = () => window.matchMedia && window.matchMedia("(max-width: 640px)").matches

    constructor(
        private userPreferencesBloc: UserPreferencesBloc,
        private serviceQuotesBloc: ServiceQuotesTableBloc,
        private filterOverlayBloc: ServiceQuotesFilterOverlayBloc,
        private companyBloc: CompanyBloc,
        private userBloc: UserBloc,
        private statusBloc: StatusBloc,
        private serviceQuoteVerifyAssetBloc: ServiceQuoteVerifyAssetBloc
    ) {
        super([userPreferencesBloc, serviceQuotesBloc, companyBloc])

        this.filterOverlayMixin = new FilterOverlayBlocCoordinatorMixin(
            FilterPresetType.ServiceQuote,
            filterOverlayBloc,
            serviceQuotesBloc,
            userPreferencesBloc,
            statusBloc
        )
    }

    protected transform = ([userPreferences, tableState, companyState]: Dependencies): StateSelection => ({
        aliases: companyState.aliases,
        columns: userPreferences.tableSettings.serviceQuotesTableColumns.columns.filter(
            this.availableColumnFilter(companyState)
        ),
        isTableDataLoading: tableState.effectStatus[SortableTableBlocEffect.Fetch].isBusy(),
        availableColumns: Object.values(ServiceQuotesTableColumn).filter(this.availableColumnFilter(companyState)),
        user: this.userBloc.state.user,
        hasUserWorkRequest: false,
        companyLogoUrl: companyState.companyLogoUrl,
        ...tableState,
    })

    private availableColumnFilter = (state: CompanyState) => (it: ServiceQuotesTableColumn) => {
        switch (it) {
           //Can use this function to reveal additional columns if they are added
            default:
                return true
        }
    }

    onMounted = (filters: Record<ServiceQuoteFilter, Filter<ServiceQuoteFilter>> | null) => {
        if (filters) {
            this.filterOverlayMixin.updateFilters(filters)
            this.filterOverlayMixin.applyFilters()
        } else {
            let filters = this.serviceQuotesBloc.state.filters
            if (this.serviceQuotesBloc.state.ignoreUserPresetFilterOnLoad) {
                this.filterOverlayBloc.updateFilters(filters)
                this.serviceQuotesBloc.state.ignoreUserPresetFilterOnLoad = false
            } else {
                const defaultPreset = this.userPreferencesBloc.state.filterPresets.serviceQuotes.find(
                    (it) => it.isDefault
                )
                filters = defaultPreset
                    ? createFilterGroupFromPreset(defaultPreset, ServiceQuoteFilterDefinitions)
                    : this.serviceQuotesBloc.state.filters

                if (defaultPreset) {
                    this.filterOverlayBloc.defaultFilterPresetLoaded(defaultPreset)
                } else {
                    this.filterOverlayBloc.updateFilters(filters)
                }
            }

            this.reloadFiltersData()
            this.serviceQuotesBloc.fetchData(
                filters,
                this.serviceQuotesBloc.state.sort,
                this.serviceQuotesBloc.state.pagination
            )
        }
        if (this.isMobile()) {
            let selectedMechanic: Contact | undefined = this.companyBloc.state.settings.mechanics.find(
                (i) => i.id == this.userBloc.state.user.personID
            )
            if (selectedMechanic) {
                const updatedFilter = this.serviceQuotesBloc.createUpdatedFilter(
                    ServiceQuoteFilter.AssignedTo,
                    [{ value: selectedMechanic.id, label: labelForContact(selectedMechanic) }],
                    FilterUpdateType.Set
                )
                this.serviceQuotesBloc.applyFiltersAndFetch({
                    ...this.serviceQuotesBloc.state.filters,
                    ...updatedFilter,
                })
            }

        } 
    }

    private reloadFiltersData = () => {
        this.companyBloc.fetchModels()
        this.companyBloc.fetchTypes()
        this.companyBloc.fetchCategories()
        this.companyBloc.fetchMakes()
        this.companyBloc.fetchClasses()
        this.companyBloc.fetchClasses()
        this.companyBloc.fetchSubCompanies()
        this.companyBloc.fetchGroups()
        this.companyBloc.fetchDistricts()
        this.companyBloc.fetchSubDistricts()
        this.companyBloc.fetchUnits()
        this.companyBloc.fetchServiceCodes()
        this.companyBloc.fetchSitesInUse()
        this.companyBloc.fetchContacts()
        this.companyBloc.fetchCbaCodes()
        this.companyBloc.fetchVendors(this.companyBloc.state.companyId)
    }

    onResetFiltersClicked = () => {
        this.filterOverlayBloc.resetFilters()
        this.serviceQuotesBloc.applyFiltersAndFetch(null)
    }
    onNextPageClicked = () => this.serviceQuotesBloc.fetchNextPage()
    onPreviousPageClicked = () => this.serviceQuotesBloc.fetchPreviousPage()
    onViewAllPagesClicked = () => this.serviceQuotesBloc.fetchAllPages()
    onResetViewAllClicked = () => this.serviceQuotesBloc.resetViewAll()
    onPageClicked = (page: number) => this.serviceQuotesBloc.fetchPage(page)
    onManageColumnsClicked = () => {
        this.serviceQuotesBloc.manageColumnsColumnsSelectionChanged(
            this.userPreferencesBloc.state.tableSettings.serviceQuotesTableColumns.columns
        )
        this.serviceQuotesBloc.manageColumnsModalVisibilityChanged(true)
    }

    onColumnSortClicked = (columnIndex: number) =>
        this.serviceQuotesBloc.toggleSort(
            this.userPreferencesBloc.state.tableSettings.serviceQuotesTableColumns.columns[columnIndex]
        )

    onFilterRemoveClicked = (filter: Filter<ServiceQuoteFilter>) =>
        this.serviceQuotesBloc.removeFilter(filter.definition.type)

    onViewAllColumnClicked = (row: ServiceQuoteTableRow) => this.serviceQuotesBloc.showViewAllColumnsModal(row)
    onViewAllColumnsModalClosed = () => this.serviceQuotesBloc.hideViewAllColumnsModal()

    onVerifyServiceQuoteAssetClicked = (row: ServiceQuoteTableRow) => {
        this.serviceQuotesBloc.selectedServiceQuoteChanged(row)
        this.serviceQuoteVerifyAssetBloc.showModal()
        if (row.assetId) {
            this.serviceQuoteVerifyAssetBloc.loadInitialData(row.id, row.assetId!)
        }
    }

    onShowServiceQuoteListModalClicked = (row: ServiceQuoteTableRow) => {
        this.serviceQuotesBloc.selectedServiceQuoteChanged(row)
        this.serviceQuotesBloc.showServiceQuoteListModalChanged(true)
    }

    onServiceQuoteListModalCloseClicked = () => {
        this.serviceQuotesBloc.showServiceQuoteListModalChanged(false)
    }

    onManageColumnsCancel = () => {
        this.serviceQuotesBloc.manageColumnsModalVisibilityChanged(false)
        this.serviceQuotesBloc.manageColumnsColumnsSelectionChanged(
            this.userPreferencesBloc.state.tableSettings.serviceQuotesTableColumns.columns
        )
    }

    onManageColumnsApply = async () => {
        this.serviceQuotesBloc.manageColumnsModalVisibilityChanged(false)
        const loaderId = this.statusBloc.enqueueInfoMessage("Saving preferences")
        const outcome = await this.userPreferencesBloc.updateServiceQuotesTableColumns(
            this.serviceQuotesBloc.state.manageColumnsModalSelectedColumns
        )
        if (!Job.isCancelled(outcome) && outcome.isError())
            this.statusBloc.enqueueErrorMessage("Error saving preferences")
        this.statusBloc.hideInfoMessage(loaderId)
    }

    onManageColumnsDefaultsToggled = () => this.serviceQuotesBloc.manageColumnsDefaultsToggled()

    onManageColumnsColumnCheckChanged = (column: ServiceQuotesTableColumn, isChecked: boolean) =>
        this.serviceQuotesBloc.manageColumnsColumnChanged(column, isChecked)

    onExportToExcelServiceQuoteDetailsClicked = (row: ServiceQuoteTableRow) => {
        this.serviceQuotesBloc.exportDataToExcel(row.id)
    }
}
