import { useBlocCoordinator } from "@lib/bloc/hooks"
import { SortOrder } from "@lib/Comparable"
import { useIsInIframe, useIsMobile, useLastNonNull } from "@lib/Hooks"
import { Link } from "@lib/router/components"
import { Strings } from "@lib/Strings"
import { safeParseFloat, safeParseInt } from "@lib/TypeUtil"
import { CompanyAlias } from "@model/company/CompanyAlias"
import { Filter, textFilterValue } from "@model/filters/Filter"
import {
    SortableTableHeadColumn,
    TableColumn,
    TableColumnAlignment,
    TableColumnSize,
    TableRow,
} from "@model/tables/Table"
import { Threshold, ThresholdType, thresholdUnitForValue } from "@model/Threshold"
import { colorForUrgency, labelForUrgency } from "@model/Urgency"
import {
    labelForServiceQuoteStatus,
    ServiceQuoteFilter,
    ServiceQuotesTableColumn,
    ServiceQuoteTableRow,
} from "@model/serviceQuotes/ServiceQuote"
import { ServiceQuoteTableColumnDefinitions } from "@model/serviceQuotes/ServiceQuoteTableColumnDefinitions"
import { Button, ButtonCont, ButtonTheme, ButtonType } from "@ui/common/buttons/Button"
import { Popover, PopoverItem } from "@ui/common/buttons/Popover"
import { DataField } from "@ui/common/DataField"

import { Grid } from "@ui/common/grids/Grid"
import { ImageUrls } from "@ui/common/images/ImageUrls"
import { Modal, ModalBody, ModalContent, ModalHeader, ModalHeaderTitle, ModalSize } from "@ui/common/Modal"
import { ManageColumnsModal } from "@ui/common/tables/ManageColumnsModal"
import { SortableTable } from "@ui/common/tables/SortableTable"

import { DI } from "@ui/DI"
import { Routes } from "@lib/Routes"
import React, { useEffect } from "react"
import { ServiceQuotesTableViewModel } from "../serviceQuotes/ServiceQuotesTableViewModel"
import { useRouteQuery } from "@lib/router/hooks"
import { withRouter } from "@lib/router/router"
import { ServiceQuoteVerifyAssetModal } from "@ui/serviceQuotes/ServiceQuoteVerifyAssetModal"
import { ServiceQuoteListModal } from "./ServiceQuoteListModal"
import { SaveIcon } from "@ui/common/images/InlinedSvgs"

export function ServiceQuotesTable(): JSX.Element {
    const isMobile = useIsMobile() || useIsInIframe()
    const [state, viewModel] = useBlocCoordinator(() => DI.serviceQuotesTableViewModel())

    const { filter } = useRouteQuery()
    let filters: Record<ServiceQuoteFilter, Filter<ServiceQuoteFilter>>

    if (filter) {
        filters = JSON.parse(filter)
    }

    useEffect(() => {
        viewModel.onMounted( filters)
    }, [])

    // const aliasedColumnLabels = createLabelsForAliasedColumns(state.aliases)
    // const aliasedFilterLabels = createLabelsForAliasedFilters(state.aliases)
    // const thresholdFilterValueOverrides = createThresholdFilterValueOverrides(state.filters)
    const filteredColumns = state.columns.filter(
        (it) =>
            !isMobile ||
            it === ServiceQuotesTableColumn.Contact
    )

    return (
        <>
            <SortableTable
                className="table--service-quotes"
                head={createTableHead(filteredColumns, isMobile, state.sort)}
                data={createTableData(isMobile, filteredColumns, state.tableData, viewModel)}
                emptyMessage="No Service Quotes Found"
                actionBar={{
                    pagination: state.pagination,
                    filters: Object.values(state.filters).filter(
                        (it) =>
                            it.definition.type !== ServiceQuoteFilter.Asset
                    ),
                    onFilterRemoveClicked: (filter) => viewModel.onFilterRemoveClicked(filter),
                    onResetFiltersClicked: () => viewModel.onResetFiltersClicked(),
                    onManageColumnsClicked: () => viewModel.onManageColumnsClicked(),
                    onNextPageClicked: () => viewModel.onNextPageClicked(),
                    onPreviousPageClicked: () => viewModel.onPreviousPageClicked(),
                    onViewAllClicked: () => viewModel.onViewAllPagesClicked(),
                    onResetViewAllClicked: () => viewModel.onResetViewAllClicked(),
                    onPageClicked: (page) => viewModel.onPageClicked(page),
                }}
                isLoading={state.isTableDataLoading}
                onColumnSortClicked={(column) => viewModel.onColumnSortClicked(column)}
            />
            <ManageColumnsModal
                columnDefinitions={ServiceQuoteTableColumnDefinitions}
                availableColumns={state.availableColumns}
                selectedColumns={state.manageColumnsModalSelectedColumns}
                isDefaultColumnsChecked={state.isDefaultColumnsChecked}
                isVisible={state.isManageColumnsModalVisible}
                onApply={viewModel.onManageColumnsApply}
                onCancel={viewModel.onManageColumnsCancel}
                onColumnCheckChanged={viewModel.onManageColumnsColumnCheckChanged}
                onDefaultsToggled={viewModel.onManageColumnsDefaultsToggled}
            />
            <ViewAllColumnsModal
                row={state.viewAllColumnsSelectedRow}
                viewModel={viewModel}
                availableColumns={state.availableColumns}
            />

            <ServiceQuoteVerifyAssetModal serviceQuoteId={state.selectedServiceQuote?.id ?? undefined} />
            {(isMobile ?
            <ButtonCont className="user-work-orders-button-cont" >
                <Button 
                        type={ButtonType.Contained}
                        label="Edit Service Requests"
                        onClick={() => withRouter((router) => router.navigate(Routes.UserWorkRequests( viewModel.state.user.id.toString())))  }
                        isEnabled={state.hasUserWorkRequest}
                    />
            </ButtonCont> : <></> ) }
            <ServiceQuoteListModal ServiecQuoteId={state.selectedServiceQuote?.id?? undefined} isVisible={state.showServiceQuoteListModal} onClose={viewModel.onServiceQuoteListModalCloseClicked} />
        </>
    )
}

function ViewAllColumnsModal({
    row,
    viewModel,
    availableColumns,
    columnLabelOverrides,
}: {
    row: ServiceQuoteTableRow | null
    viewModel: ServiceQuotesTableViewModel
    availableColumns: ServiceQuotesTableColumn[]
    columnLabelOverrides?: Partial<Record<ServiceQuotesTableColumn, string>>
}): JSX.Element {
    const data = useLastNonNull(row)
    if (data === null) return <Modal isVisible={row !== null} size={ModalSize.Large} />

    return (
        <Modal
            isVisible={row !== null}
            useContentTag={true}
            onClose={() => viewModel.onViewAllColumnsModalClosed()}
            size={ModalSize.Large}
        >
            <ModalContent>
                <ModalHeader>
                    <ModalHeaderTitle>
                        {data.id} {data.assignedTo}
                    </ModalHeaderTitle>
                </ModalHeader>
                <ModalBody>
                    <Grid columns={4}>
                        {availableColumns
                            .filter((it) => ![ServiceQuotesTableColumn.AssignedTo, ServiceQuotesTableColumn.AssignedTo].includes(it))
                            .map((it) => (
                                <DataField
                                    key={it}
                                    label={columnLabelOverrides?.[it] ?? ServiceQuoteTableColumnDefinitions[it].label}
                                    value={createColumnContent(false, it, data, true)}
                                />
                            ))}
                    </Grid>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}


function createTableHead(
    columns: ServiceQuotesTableColumn[],
    isMobile: boolean,
    sort?: { column: ServiceQuotesTableColumn; order: SortOrder },
    columnLabelOverrides?: Partial<Record<ServiceQuotesTableColumn, string>>
): SortableTableHeadColumn[] {
    return columns
        .map(
            (column): SortableTableHeadColumn => ({
                size: isMobile
                    ? ServiceQuoteTableColumnDefinitions[column].mobileSize ??
                      ServiceQuoteTableColumnDefinitions[column].size
                    : ServiceQuoteTableColumnDefinitions[column].size,
                alignment: ServiceQuoteTableColumnDefinitions[column].alignment,
                isSortable: ServiceQuoteTableColumnDefinitions[column].isSortable,
                sortOrder: column === sort?.column ? sort.order : SortOrder.None,
                content: <div>{columnLabelOverrides?.[column] ?? ServiceQuoteTableColumnDefinitions[column].label}</div>,
            })
        )
        .concat({
            alignment: TableColumnAlignment.Center,
            size: TableColumnSize.MinContent,
            isSortable: false,
            sortOrder: SortOrder.None,
        })
}

function createTableData(
    isMobile: boolean,
    columns: ServiceQuotesTableColumn[],
    tableData: ServiceQuoteTableRow[],
    viewModel:ServiceQuotesTableViewModel
): TableRow[] {
    return tableData.map((row) => ({
        entryId: row.id,
        columns: columns
            .map(
                (column): TableColumn => ({
                    size: ServiceQuoteTableColumnDefinitions[column].size,
                    alignment: ServiceQuoteTableColumnDefinitions[column].alignment,
                    content: createColumnContent(isMobile, column, row),
                    className: ServiceQuoteTableColumnDefinitions[column].className,
                })
            )
            .concat([
                {
                    alignment: TableColumnAlignment.Center,
                    size: TableColumnSize.MinContent,
                    content: isMobile ? (
                        <Button
                            onClick={() => viewModel.onViewAllColumnClicked(row)}
                            tooltip={"Show All Columns"}
                            type={ButtonType.Icon}
                            iconUrl={ImageUrls.GlassesDark}
                        />
                    ) : (
                        <MoreColumn row={row} viewModel={viewModel} />
                    ),
                    className: "table-cell--more",
                },
            ]),
    }))
}

function createColumnContent(
    isMobile: boolean,
    column: ServiceQuotesTableColumn,
    row: ServiceQuoteTableRow,
    isViewAllColumns: boolean = false
): JSX.Element | null {
    switch (column) {
        case ServiceQuotesTableColumn.ServiceQuote:
            return <div>{row.id}</div>
        case ServiceQuotesTableColumn.Asset:
                return !isMobile ? (
                    <a href={"/service-quotes/" + row.id.toString()} target="_blank">{row.asset}</a>
                ) : (
                    <div>{row.asset}</div>
                )
        case ServiceQuotesTableColumn.AssignedTo:
            return <div>{row.assignedToName}</div>
        case ServiceQuotesTableColumn.Contact:
            return <div>{row.contact}</div>
        case ServiceQuotesTableColumn.DateReceived:
            return (<div>
            {row.dateReceived  != null ? Strings.formatDate(row.dateReceived ) : "-"}
        </div>)
        case ServiceQuotesTableColumn.LastKnownLocation:
            return <div>{row.lastKnownLocation}</div>
        case ServiceQuotesTableColumn.ServiceQuoteStatus:
            return <div>{labelForServiceQuoteStatus(row.serviceQuoteStatus)}</div>
        case ServiceQuotesTableColumn.TotalCost:
            return <div>{'$'+ row.totalCost?.toString()}</div>
        case ServiceQuotesTableColumn.Vendor:
            return <div>{row.vendor}</div>
    }
}



const MoreColumn = ({ row, viewModel }: { row: ServiceQuoteTableRow; viewModel: ServiceQuotesTableViewModel }) => {
    return (
        <Popover button={{ iconUrl: ImageUrls.More, theme: ButtonTheme.Light }}>
            {/* <PopoverItem
                icon={ImageUrls.Print}
                label="Print"
                //onClick={() => viewModel.workOrderToPrintChanged(row.id)}
            /> */}
            <PopoverItem
                icon={ImageUrls.Glasses}
                label="View All Columns"
                onClick={() => viewModel.onViewAllColumnClicked(row)}
            />
            <PopoverItem
                icon={ImageUrls.CheckMark}
                label="Verify Asset"
                onClick={() => viewModel.onVerifyServiceQuoteAssetClicked(row)}
            />
            <PopoverItem
                icon={ImageUrls.Wrench}
                label="Show All Requests"
                onClick={() => viewModel.onShowServiceQuoteListModalClicked(row)}
            />
            <PopoverItem
                label="Export to Excel"
                onClick={() => viewModel.onExportToExcelServiceQuoteDetailsClicked(row)}
            >
                <div className="popover-item-icon">
                    <SaveIcon />
                </div>
            </PopoverItem>
        </Popover>
    )
}
