import { BlocCoordinator } from "@lib/bloc/BlocCoordinator"
import { Router, withRouter } from "@lib/router/router"
import { Routes } from "@lib/Routes"
import { DashboardFilter } from "@model/dashboard/DashboardFilter"
import { DashboardFilterDefinitions } from "@model/dashboard/DashboardFilterDefinitions"
import {
    Filter,
    FilterValue,
    filterValueForUrgency,
    filterValueForWorkOrderStatus,
    filterValueForWorkRequestType,
    isEmptyFilterValue,
    newEmptyFiltersObj,
} from "@model/filters/Filter"
import { createFilterGroupFromPreset } from "@model/filters/FilterPresets"
import { Urgency } from "@model/Urgency"
import { PermissionObject, PermissionType, User } from "@model/user/User"
import { WorkOrderFilter, WorkOrderStatus } from "@model/workOrders/WorkOrder"
import { WorkOrderFilterDefinitions } from "@model/workOrders/WorkOrderFilterDefinitions"
import { WorkRequestFilter, WorkRequestType } from "@model/workRequests/WorkRequest"
import { WorkRequestsFilterDefinitions } from "@model/workRequests/WorkRequestsFilterDefinitions"
import { DashboardScreenBloc } from "@state/dashboard/DashboardScreenBloc"
import { UserBloc, UserState } from "@state/user/UserBloc"
import { UserPreferencesBloc } from "@state/UserPreferencesBloc"
import { WorkOrdersTableBloc } from "@state/workOrders/WorkOrdersTableBloc"
import { WorkRequestsTableBloc } from "@state/workRequests/WorkRequestsTableBloc"

type Dependencies = [UserState]

type StateSelection = {
    user: User
}

export class StatusWidgetViewModel extends BlocCoordinator<Dependencies, StateSelection> {
    private router: Router

    constructor(
        private workRequestsTableBloc: WorkRequestsTableBloc,
        private workOrdersTableBloc: WorkOrdersTableBloc,
        private userPreferencesBloc: UserPreferencesBloc,
        private dashboardBloc: DashboardScreenBloc,
        private userBlock: UserBloc
    ) {
        super([userBlock])
        this.router = withRouter((router) => router)
    }

    protected transform = ([userState]: Dependencies): StateSelection => ({
        user: userState.user,
    })

    hasWorkRequestViewPermissions = () => this.state.user.hasAccess(PermissionObject.WorkRequest, PermissionType.View)
    hasWorkOrderViewPermissions = () =>
        this.state.user.hasAccess(PermissionObject.MaintenanceWorkOrder, PermissionType.View)

    workRequestAssetsCountClicked = () => this.applyWorkRequestFiltersAndNavigate([])

    workRequestTypeCountClicked = (type: WorkRequestType) =>
        this.applyWorkRequestFiltersAndNavigate([
            { type: WorkRequestFilter.WorkType, filterValue: filterValueForWorkRequestType(type) },
        ])

    workRequestOverdueClicked = () =>
        this.applyWorkRequestFiltersAndNavigate([{ type: WorkRequestFilter.Overdue, filterValue: { value: true } }])

    workRequestRedTagClicked = () =>
        this.applyWorkRequestFiltersAndNavigate([{ type: WorkRequestFilter.RedTag, filterValue: { value: true } }])

    workRequestYellowTagClicked = () =>
        this.applyWorkRequestFiltersAndNavigate([{ type: WorkRequestFilter.YellowTag, filterValue: { value: true } }])

    workRequestImmediateClicked = () =>
        this.applyWorkRequestFiltersAndNavigate([
            { type: WorkRequestFilter.Urgency, filterValue: filterValueForUrgency(Urgency.Immediate) },
        ])

    private applyWorkRequestFiltersAndNavigate(values: { type: WorkRequestFilter; filterValue: FilterValue }[]) {
        const dashboardFiltersArray = this.getDashboardFilters()
        const workRequestFilters: Record<
            WorkRequestFilter,
            Filter<WorkRequestFilter>
        > = this.getWorkRequestFilterFromDashboardFilters(dashboardFiltersArray)
        values.forEach(
            (it) => (workRequestFilters[it.type] = { ...workRequestFilters[it.type], values: [it.filterValue] })
        )

        this.router.navigate(Routes.WorkRequests(JSON.stringify(workRequestFilters)), undefined, true)
    }

    private getDashboardFilters = (): Filter<DashboardFilter>[] => {
        const defaultPreset = this.userPreferencesBloc.state.filterPresets.dashboard.find((it) => it.isDefault)
        const dashboardFilters = defaultPreset
            ? createFilterGroupFromPreset(defaultPreset, DashboardFilterDefinitions)
            : this.dashboardBloc.state.filters
        return Object.values(dashboardFilters ?? []).filter((filter) => !isEmptyFilterValue(filter))
    }

    private getWorkRequestFilterFromDashboardFilters = (
        filters: Filter<DashboardFilter>[]
    ): Record<WorkRequestFilter, Filter<WorkRequestFilter>> => {
        let workRequestFilters: Record<WorkRequestFilter, Filter<WorkRequestFilter>> = newEmptyFiltersObj(
            Object.values(WorkRequestFilter),
            WorkRequestsFilterDefinitions
        )
        const filterDefinitions = DashboardFilterDefinitions

        for (const filter of filters) {
            if (isEmptyFilterValue(filter)) continue
            if (filter.definition === filterDefinitions[DashboardFilter.District]) {
                this.addWorkRequestFilterItemObject(workRequestFilters, WorkRequestFilter.District, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.SubCompany]) {
                this.addWorkRequestFilterItemObject(workRequestFilters, WorkRequestFilter.SubCompany, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.SubDistrict]) {
                this.addWorkRequestFilterItemObject(workRequestFilters, WorkRequestFilter.SubDistrict, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Unit]) {
                this.addWorkRequestFilterItemObject(workRequestFilters, WorkRequestFilter.Unit, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Group]) {
                this.addWorkRequestFilterItemObject(workRequestFilters, WorkRequestFilter.Group, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Site]) {
                this.addWorkRequestFilterItemObject(workRequestFilters, WorkRequestFilter.Site, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.AssetType]) {
                this.addWorkRequestFilterItemObject(workRequestFilters, WorkRequestFilter.AssetType, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Category]) {
                this.addWorkRequestFilterItemObject(workRequestFilters, WorkRequestFilter.Category, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Class]) {
                this.addWorkRequestFilterItemObject(workRequestFilters, WorkRequestFilter.Class, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Make]) {
                this.addWorkRequestFilterItemObject(workRequestFilters, WorkRequestFilter.Make, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Model]) {
                this.addWorkRequestFilterItemObject(workRequestFilters, WorkRequestFilter.Model, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Search]) {
                this.addWorkRequestFilterItemObject(workRequestFilters, WorkRequestFilter.Search, filter.values)
            }
        }
        return workRequestFilters
    }

    private addWorkRequestFilterItemObject = (
        filters: Record<WorkRequestFilter, Filter<WorkRequestFilter>>,
        definition: WorkRequestFilter,
        values: FilterValue[]
    ): void => {
        values.forEach((it) => (filters[definition] = { ...filters[definition], values: [it] }))
    }

    workOrdersCountClicked = () => this.applyWorkOrderFiltersAndNavigate([])

    workOrderStatusCountClicked = (status: WorkOrderStatus) =>
        this.applyWorkOrderFiltersAndNavigate([
            { type: WorkOrderFilter.Status, filterValue: filterValueForWorkOrderStatus(status) },
        ])

    workOrderImmediateClicked = () =>
        this.applyWorkOrderFiltersAndNavigate([
            { type: WorkOrderFilter.Urgency, filterValue: filterValueForUrgency(Urgency.Immediate) },
        ])

    workOrderOverdueClicked = () =>
        this.applyWorkOrderFiltersAndNavigate([{ type: WorkOrderFilter.Overdue, filterValue: { value: true } }])

    workOrderRedTagClicked = () =>
        this.applyWorkOrderFiltersAndNavigate([{ type: WorkOrderFilter.RedTag, filterValue: { value: true } }])

    workOrderYellowTagClicked = () =>
        this.applyWorkOrderFiltersAndNavigate([{ type: WorkOrderFilter.YellowTag, filterValue: { value: true } }])

    private applyWorkOrderFiltersAndNavigate(values: { type: WorkOrderFilter; filterValue: FilterValue }[]) {
        const dashboardFiltersArray = this.getDashboardFilters()
        const workOrderFilters: Record<
            WorkOrderFilter,
            Filter<WorkOrderFilter>
        > = this.getWorkOrderFilterFromDashboardFilters(dashboardFiltersArray)
        values.forEach((it) => (workOrderFilters[it.type] = { ...workOrderFilters[it.type], values: [it.filterValue] }))

        this.router.navigate(Routes.WorkOrders(JSON.stringify(workOrderFilters)), undefined, true)
    }

    private getWorkOrderFilterFromDashboardFilters = (
        filters: Filter<DashboardFilter>[]
    ): Record<WorkOrderFilter, Filter<WorkOrderFilter>> => {
        let workOrderFilters: Record<WorkOrderFilter, Filter<WorkOrderFilter>> = newEmptyFiltersObj(
            Object.values(WorkOrderFilter),
            WorkOrderFilterDefinitions
        )
        const filterDefinitions = DashboardFilterDefinitions

        for (const filter of filters) {
            if (isEmptyFilterValue(filter)) continue
            if (filter.definition === filterDefinitions[DashboardFilter.District]) {
                this.addWorkOrderFilterItemObject(workOrderFilters, WorkOrderFilter.District, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.SubCompany]) {
                this.addWorkOrderFilterItemObject(workOrderFilters, WorkOrderFilter.SubCompany, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.SubDistrict]) {
                this.addWorkOrderFilterItemObject(workOrderFilters, WorkOrderFilter.SubDistrict, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Unit]) {
                this.addWorkOrderFilterItemObject(workOrderFilters, WorkOrderFilter.Unit, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Group]) {
                this.addWorkOrderFilterItemObject(workOrderFilters, WorkOrderFilter.Group, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Site]) {
                this.addWorkOrderFilterItemObject(workOrderFilters, WorkOrderFilter.Site, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.AssetType]) {
                this.addWorkOrderFilterItemObject(workOrderFilters, WorkOrderFilter.AssetType, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Category]) {
                this.addWorkOrderFilterItemObject(workOrderFilters, WorkOrderFilter.Category, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Class]) {
                this.addWorkOrderFilterItemObject(workOrderFilters, WorkOrderFilter.Class, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Make]) {
                this.addWorkOrderFilterItemObject(workOrderFilters, WorkOrderFilter.Make, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Model]) {
                this.addWorkOrderFilterItemObject(workOrderFilters, WorkOrderFilter.Model, filter.values)
            } else if (filter.definition === filterDefinitions[DashboardFilter.Search]) {
                this.addWorkOrderFilterItemObject(workOrderFilters, WorkOrderFilter.Search, filter.values)
            }
        }
        return workOrderFilters
    }

    private addWorkOrderFilterItemObject = (
        filters: Record<WorkOrderFilter, Filter<WorkOrderFilter>>,
        definition: WorkOrderFilter,
        values: FilterValue[]
    ): void => {
        values.forEach((it) => (filters[definition] = { ...filters[definition], values: [it] }))
    }
}
