import React from "react"
import { Page } from "@ui/common/page/Page"

export function PageNotFoundScreen(): JSX.Element {
    return (
        <Page className="page--page-not-found">
            <h1>404 - Page Not Found</h1>
        </Page>
    )
}
