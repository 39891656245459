import useMediaQuery from "@material-ui/core/useMediaQuery"
import { DependencyList, useCallback, useEffect, useMemo, useRef, useState } from "react"

export const useLastNonNull = <T>(value: T | null): T | null => {
    const ref = useRef<T | null>(value)
    if (value !== null) ref.current = value
    return ref.current
}

export const usePrevious = <T>(value: T): T => {
    const ref = useRef<T>(value)

    useEffect(() => {
        ref.current = value
    }, [value])

    return ref.current
}

/**
 * Use State with dependencies. This is useful when you have an initial transformed value that you want
 * the user to be able to modify and then transform the value when the dependency has changed
 *
 * @returns [currentValue, setCurrentValue, forceTransform]
 * Calling the return forceTransform function will signal to the hook that you want the transformation to run on the
 * next render
 *
 * @note forceTransform may not transform properly if the component re-renders before the state change can be made
 */
export function useDerivedState<T>(data: T, dependencies: DependencyList = []): [T, (data: T) => void, () => void] {
    const state = useRef(data)
    const [_, forceUpdate] = useState({})
    const forceRecalculateVal = useRef({})

    useMemo(() => (state.current = data), [...dependencies, forceRecalculateVal.current])

    const setState = useCallback((data: T) => {
        state.current = data
        forceUpdate({})
    }, [])

    const forceRecalculate = useCallback(() => (forceRecalculateVal.current = {}), [])

    return [state.current, setState, forceRecalculate]
}

export function useIsMobile(): boolean {
    return useMediaQuery("(max-width: 640px), (max-height: 640px)")
}

export function useIsInIframe(): boolean {
    try {
        return window.self !== window.top
    } catch (e) {
        return true
    }
}
