import { SortableTableColumnDef, TableColumnAlignment, TableColumnSize } from "@model/tables/Table"
import { UserWorkRequestsTableColumn } from "./WorkRequest"

export const UserWorkRequestsTableColumnDefinitions: Record<
    UserWorkRequestsTableColumn,
    SortableTableColumnDef<UserWorkRequestsTableColumn>
> = {
    [UserWorkRequestsTableColumn.Asset]: {
        type: UserWorkRequestsTableColumn.Asset,
        label: "Asset",
        size: TableColumnSize.Normal,
        alignment: TableColumnAlignment.Start,
        className: "table-cell--Asset",
        isRequired: true,
        isSortable: true,
    },
    [UserWorkRequestsTableColumn.Name]: {
        type: UserWorkRequestsTableColumn.Name,
        label: "Service Request Name",
        size: TableColumnSize.Normal,
        alignment: TableColumnAlignment.Start,
        className: "table-cell--name",
        isRequired: true,
        isSortable: true,
    },
}
