import React from "react"
import { Page } from "@ui/common/page/Page"

export function NoAccessScreen(): JSX.Element {
    return (
        <Page className="page--page-not-found">
            <h1>You do not have permissions to access this item.</h1>
        </Page>
    )
}
