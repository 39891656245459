import { BlocCoordinator } from "@lib/bloc/BlocCoordinator"
import { safeParseFloat, safeParseInt } from "@lib/TypeUtil"
import { AdjustmentAction } from "@model/workOrders/AdjustServiceRequestsForm"
import { WorkRequest } from "@model/workRequests/WorkRequest"
import { AdjustServiceRequestsBloc, AdjustServiceRequestsState } from "@state/workOrders/AdjustServiceRequestsBloc"

type Dependencies = [AdjustServiceRequestsState]
type StateSelection = AdjustServiceRequestsState & {
    isFormValid: boolean
}

export class AdjustServiceRequestsViewModel extends BlocCoordinator<Dependencies, StateSelection> {
    constructor(private AdjustServiceRequestsBloc: AdjustServiceRequestsBloc) {
        super([AdjustServiceRequestsBloc])
    }

    transform = ([AdjustServiceRequestsState]: Dependencies): StateSelection => ({
        ...AdjustServiceRequestsState,
        isFormValid: true,
        requiresManualAdjustment:
            AdjustServiceRequestsState.scheduleServiceRequests.filter(
                (it) =>
                    AdjustServiceRequestsState.scheduleServiceRequestForms[it.id] !== undefined &&
                    AdjustServiceRequestsState.scheduleServiceRequestForms[it.id].adjustmentAction ===
                        AdjustmentAction.ManualAdjustment
            ).length > 0,
    })

    requestStepChanged = (requestId: number, value: string) => {
        const workRequest = this.state.scheduleServiceRequests.find((wr) => wr.id === requestId)
        const step = workRequest?.schedule?.steps.find((it) => it.id === safeParseInt(value))
        if (!step) return
        this.AdjustServiceRequestsBloc.requestStepChanged(requestId, step)
    }

    isSecondStepChanged = (value: boolean) => {
        this.AdjustServiceRequestsBloc.isSecondStepChanged(value)
    }

    setscheduleServiceRequests = (workRequests: WorkRequest[]) => {
        this.AdjustServiceRequestsBloc.setScheduleWorkRequests(workRequests)
    }

    setScheduleServiceRequestForms = (workRequests: WorkRequest[]) => {
        this.AdjustServiceRequestsBloc.setScheduleWorkRequestForms(workRequests)
    }

    requestAdjustmentActionChanged = (requestId: number, value: AdjustmentAction) => {
        this.AdjustServiceRequestsBloc.requestAdjustmentActionChanged(requestId, value)
    }

    requestAdjustedDueHourMeterChanged = (requestId: number, value: string) => {
        this.AdjustServiceRequestsBloc.requestAdjustedDueHourMeterChanged(requestId, value)
    }

    requestAdjustedDueDateChanged = (requestId: number, value: Date) => {
        this.AdjustServiceRequestsBloc.requestAdjustedDueDateChanged(requestId, value)
    }

    requestAdjustedDueOdometerChanged = (requestId: number, value: string) => {
        this.AdjustServiceRequestsBloc.requestAdjustedDueOdometerChanged(requestId, value)
    }

    requestApplyChangeChanged = (requestId: number, value: boolean) => {
        this.AdjustServiceRequestsBloc.requestApplyChangeChanged(requestId, value)
    }

    getServiceRequestToAdvanceStep = () => {
        return this.state.scheduleServiceRequests.filter((x) => {
            let form = this.state.scheduleServiceRequestForms[x.id]
            return form != undefined && form.adjustmentAction === AdjustmentAction.AdvanceNextStep
        })
    }

    filterServiceRequestToManualAdjust = () => {
        return this.state.scheduleServiceRequests.filter((x) => {
            let form = this.state.scheduleServiceRequestForms[x.id]
            return (
                form != undefined &&
                form.adjustmentAction === AdjustmentAction.ManualAdjustment &&
                form.applyManualAdjustment
            )
        })
    }

    getServiceRequestToManualAdjust = () => {
        return this.filterServiceRequestToManualAdjust().map((it) => {
            let form = this.state.scheduleServiceRequestForms[it.id]

            it.step = form.step
            it.dueDate = form.adjustedDueDate
            it.dueOdometer = safeParseInt(form.adjustedDueOdometer)
            it.dueHourMeter = safeParseFloat(form.adjustedDueHourMeter)

            return it
        })
    }
}
