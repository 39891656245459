import { CompanyAlias, defaultCompanyAlias } from "@model/company/CompanyAlias"
import { FilterDef } from "@model/filters/Filter"
import { DashboardFilter } from "./DashboardFilter"

export const DashboardFilterDefinitions: Record<DashboardFilter, FilterDef<DashboardFilter>> = {
    [DashboardFilter.Search]: {
        type: DashboardFilter.Search,
        categoryLabel: "Search",
        defaultValue: [{ value: "" }],
    },
    [DashboardFilter.SubCompany]: {
        type: DashboardFilter.SubCompany,
        categoryLabel: defaultCompanyAlias(CompanyAlias.SubCompany),
        defaultValue: [],
    },
    [DashboardFilter.District]: {
        type: DashboardFilter.District,
        categoryLabel: defaultCompanyAlias(CompanyAlias.District),
        defaultValue: [],
        dependencies: [DashboardFilter.SubCompany],
    },
    [DashboardFilter.SubDistrict]: {
        type: DashboardFilter.SubDistrict,
        categoryLabel: defaultCompanyAlias(CompanyAlias.SubDistrict),
        defaultValue: [],
        dependencies: [DashboardFilter.SubCompany, DashboardFilter.District],
    },
    [DashboardFilter.Unit]: {
        type: DashboardFilter.Unit,
        categoryLabel: defaultCompanyAlias(CompanyAlias.Unit),
        defaultValue: [],
        dependencies: [DashboardFilter.SubCompany, DashboardFilter.District, DashboardFilter.SubDistrict],
    },
    [DashboardFilter.Group]: {
        type: DashboardFilter.Group,
        categoryLabel: defaultCompanyAlias(CompanyAlias.Group),
        defaultValue: [],
        dependencies: [DashboardFilter.SubCompany],
    },
    [DashboardFilter.Site]: { type: DashboardFilter.Site, categoryLabel: "Site", defaultValue: [] },
    [DashboardFilter.AssetType]: { type: DashboardFilter.AssetType, categoryLabel: "Type", defaultValue: [] },
    [DashboardFilter.Category]: {
        type: DashboardFilter.Category,
        categoryLabel: "Category",
        defaultValue: [],
        dependencies: [DashboardFilter.AssetType],
    },
    [DashboardFilter.Class]: {
        type: DashboardFilter.Class,
        categoryLabel: "Class",
        defaultValue: [],
        dependencies: [DashboardFilter.Category, DashboardFilter.AssetType],
    },
    [DashboardFilter.Make]: {
        type: DashboardFilter.Make,
        categoryLabel: "Make",
        defaultValue: [],
    },
    [DashboardFilter.Model]: {
        type: DashboardFilter.Model,
        categoryLabel: "Model",
        defaultValue: [],
        dependencies: [DashboardFilter.Make],
    },
    [DashboardFilter.WorkType]: {
        type: DashboardFilter.WorkType,
        categoryLabel: "Service Type",
        defaultValue: [], // string[]
    },
    [DashboardFilter.AssignedTo]: {
        type: DashboardFilter.AssignedTo,
        categoryLabel: "Assigned To",
        defaultValue: [],
    },
    [DashboardFilter.MechanicTypes]: {
        type: DashboardFilter.MechanicTypes,
        categoryLabel: "Assigned To",
        defaultValue: [],
    },
}
