import { BlocCoordinator } from "@lib/bloc/BlocCoordinator"
import { Contact } from "@model/contacts/Contact"
import { Filter, filterContainsValue, FilterUpdateType, FilterValue, filterValueForServiceQuoteStatus, filterValueForUrgency, filterValueForWorkOrderStatus } from "@model/filters/Filter"
import { FilterPresetType } from "@model/filters/FilterPresets"

import { CompanyBloc, CompanyState } from "@state/company/CompanyBloc"
import { FilterOverlayBlocCoordinatorMixin } from "@state/filters/FilterOverlayBlocCoordinatorMixin"
import { StatusBloc } from "@state/StatusBloc"
import { UserPreferencesBloc } from "@state/UserPreferencesBloc"
import { SortableTableBlocEffect } from "@state/SortableTableBloc"
import { QuickFilterStatus, ServiceQuoteFilter, ServiceQuoteQuickFilterCount, ServiceQuoteStatus } from "@model/serviceQuotes/ServiceQuote"
import { ServiceQuotesQuickFiltersBloc, ServiceQuotesQuickFiltersState } from "@state/serviceQuotes/ServiceQuotesQuickFiltersBloc"
import { ServiceQuotesTableBloc, ServiceQuotesTableState } from "@state/serviceQuotes/ServiceQuotesTableBloc"
import { ServiceQuotesFilterOverlayBloc, ServiceQuotesFilterOverlayState } from "@state/serviceQuotes/ServiceQuotesFilterOverlayBloc"

type Dependencies = [ServiceQuotesQuickFiltersState, ServiceQuotesTableState, CompanyState, ServiceQuotesFilterOverlayState]

export type ServiceQuotesQuickFiltersViewModelState = {
    counts: Record<ServiceQuoteQuickFilterCount, number>
    isPendingToggleActive: boolean
    isApprovedToggleActive: boolean
    isDeclinedToggleActive: boolean
    isRevisionToggleActive: boolean
    isAssetToggleActive: boolean
    isDateReceivedToggleActive: boolean
    isLocationToggleActive: boolean
    isServiceQuoteToggleActive: boolean
    isVendorToggleActive: boolean
    isTotalCostToggleActive: boolean
    isLoading: boolean
    assignedToFilter: Filter<ServiceQuoteFilter>
    searchFilter: Filter<ServiceQuoteFilter>
    mechanics: Contact[]
    isMobileFilterModalOpen: boolean
}

export class ServiceQuotesQuickFiltersViewModel extends BlocCoordinator<
    Dependencies,
    ServiceQuotesQuickFiltersViewModelState
> {
    private filterOverlayMixin: FilterOverlayBlocCoordinatorMixin<ServiceQuoteFilter>

    constructor(
        private quickFiltersBloc: ServiceQuotesQuickFiltersBloc,
        private tableBloc: ServiceQuotesTableBloc,
        private companyBloc: CompanyBloc,
        private filterOverlayBloc: ServiceQuotesFilterOverlayBloc,
        userPreferencesBloc: UserPreferencesBloc,
        statusBloc: StatusBloc
    ) {
        super([quickFiltersBloc, tableBloc, companyBloc, filterOverlayBloc])

        this.filterOverlayMixin = new FilterOverlayBlocCoordinatorMixin(
            FilterPresetType.ServiceQuote,
            filterOverlayBloc,
            tableBloc,
            userPreferencesBloc,
            statusBloc
        )
    }

    protected transform = ([
        quickFiltersState,
        tableState,
        companyState,
        filterOverlayState,
    ]: Dependencies): ServiceQuotesQuickFiltersViewModelState => ({
        counts: tableState.counts,
        isPendingToggleActive: filterContainsValue(tableState.filters[ServiceQuoteFilter.Status], [
            ServiceQuoteStatus.Pending,
        ]),
        isApprovedToggleActive: filterContainsValue(tableState.filters[ServiceQuoteFilter.Status], [ServiceQuoteStatus.Approved]),
        isDeclinedToggleActive: filterContainsValue(tableState.filters[ServiceQuoteFilter.Status], [
            ServiceQuoteStatus.Declined,
        ]),
        isRevisionToggleActive: filterContainsValue(tableState.filters[ServiceQuoteFilter.Status], [
            ServiceQuoteStatus.RevisionRequested,
        ]),
        isAssetToggleActive: filterContainsValue(tableState.filters[ServiceQuoteFilter.Asset], [true]),
        isDateReceivedToggleActive: filterContainsValue(tableState.filters[ServiceQuoteFilter.CreationDateTimeFilterOption], [true]),
        isLocationToggleActive: filterContainsValue(tableState.filters[ServiceQuoteFilter.LastKnownLocation], [true]),
        isServiceQuoteToggleActive: filterContainsValue(tableState.filters[ServiceQuoteFilter.ServiceQuoteId], [true]),
        isVendorToggleActive: filterContainsValue(tableState.filters[ServiceQuoteFilter.VendorID], [true]),
        isTotalCostToggleActive: filterContainsValue(tableState.filters[ServiceQuoteFilter.TotalCostFilterOption], [true]),
        isLoading: tableState.effectStatus[SortableTableBlocEffect.Fetch].isBusy(),
        assignedToFilter: tableState.filters[ServiceQuoteFilter.AssignedTo],
        searchFilter: filterOverlayState.filters[ServiceQuoteFilter.Search],
        mechanics: companyState.settings.mechanics,
        isMobileFilterModalOpen: quickFiltersState.isMobileFilterModalOpen,
    })

    updateQuickFilterSelection(selection: ServiceQuoteQuickFilterCount[]) {
        const updatedFilters: Record<ServiceQuoteFilter, Filter<ServiceQuoteFilter>> = {
            ...this.tableBloc.state.filters,
            ...this.tableBloc.createUpdatedFilter(
                ServiceQuoteFilter.CreationDateTimeFilterOption,
                [{ value: selection.includes(ServiceQuoteQuickFilterCount.DaysOpenSubSeven) }],
                FilterUpdateType.Set
            ),
            ...this.tableBloc.createUpdatedFilter(
                ServiceQuoteFilter.CreationDateTimeFilterOption,
                [{ value: selection.includes(ServiceQuoteQuickFilterCount.DaysOpenSevenToThirty) }],
                FilterUpdateType.Set
            ),
            ...this.tableBloc.createUpdatedFilter(
                ServiceQuoteFilter.CreationDateTimeFilterOption,
                [{ value: selection.includes(ServiceQuoteQuickFilterCount.DaysOpenThirtyToNinety) }],
                FilterUpdateType.Set
            ),
            ...this.tableBloc.createUpdatedFilter(
                ServiceQuoteFilter.CreationDateTimeFilterOption,
                [{ value: selection.includes(ServiceQuoteQuickFilterCount.DaysOpenOverNinety) }],
                FilterUpdateType.Set
            ),
            ...this.tableBloc.createUpdatedFilter(
                ServiceQuoteFilter.TotalCostFilterOption,
                [{ value: selection.includes(ServiceQuoteQuickFilterCount.TotalCostSubOneThousand) }],
                FilterUpdateType.Set
            ),
            ...this.tableBloc.createUpdatedFilter(
                ServiceQuoteFilter.TotalCostFilterOption,
                [{ value: selection.includes(ServiceQuoteQuickFilterCount.TotalCostOneToTenThousand) }],
                FilterUpdateType.Set
            ),
            ...this.tableBloc.createUpdatedFilter(
                ServiceQuoteFilter.TotalCostFilterOption,
                [{ value: selection.includes(ServiceQuoteQuickFilterCount.TotalCostTenToTwentyFiveThousand) }],
                FilterUpdateType.Set
            ),
            ...this.tableBloc.createUpdatedFilter(
                ServiceQuoteFilter.TotalCostFilterOption,
                [{ value: selection.includes(ServiceQuoteQuickFilterCount.TotalCostOverTentyFiveThousand) }],
                FilterUpdateType.Set
            ),
        }

        this.tableBloc.applyFiltersAndFetch(updatedFilters)
    }

    toggleQuickFilter(countType: ServiceQuoteQuickFilterCount, isActive: boolean) {
        const updatedFilter = (() => {
            switch (countType) {
                case ServiceQuoteQuickFilterCount.DaysOpenSubSeven:
                    return this.tableBloc.createUpdatedFilter(
                        ServiceQuoteFilter.CreationDateTimeFilterOption,
                        [{label:"Under 7 Days", value: QuickFilterStatus.SubSevenDays}],
                        FilterUpdateType.Set
                    )
                    case ServiceQuoteQuickFilterCount.DaysOpenSevenToThirty:
                        return this.tableBloc.createUpdatedFilter(
                            ServiceQuoteFilter.CreationDateTimeFilterOption,
                            [{label:"7-30 Days", value: QuickFilterStatus.SevenToThirtyDays}],
                            FilterUpdateType.Set
                        )
                case ServiceQuoteQuickFilterCount.DaysOpenThirtyToNinety:
                    return this.tableBloc.createUpdatedFilter(
                        ServiceQuoteFilter.CreationDateTimeFilterOption,
                        [{label:"30-90 Days", value: QuickFilterStatus.ThirtyToNinetyDays}],
                        FilterUpdateType.Set
                    )
                case ServiceQuoteQuickFilterCount.DaysOpenOverNinety:
                    return this.tableBloc.createUpdatedFilter(
                        ServiceQuoteFilter.CreationDateTimeFilterOption,
                        [{label: "Over 90 Days", value: QuickFilterStatus.OverNinteyDays}],
                        FilterUpdateType.Set
                    )
                case ServiceQuoteQuickFilterCount.TotalCostSubOneThousand:
                    return this.tableBloc.createUpdatedFilter(
                        ServiceQuoteFilter.TotalCostFilterOption,
                        [{ label:"Under $1000", value: QuickFilterStatus.SubOneThousand }],
                        FilterUpdateType.Set
                    )
                 case ServiceQuoteQuickFilterCount.TotalCostOneToTenThousand:
                    return this.tableBloc.createUpdatedFilter(
                        ServiceQuoteFilter.TotalCostFilterOption,
                        [{ label:"$1000-$10,000", value: QuickFilterStatus.OneToTenThousand}],
                        FilterUpdateType.Set
                    )
                 case ServiceQuoteQuickFilterCount.TotalCostTenToTwentyFiveThousand:
                    return this.tableBloc.createUpdatedFilter(
                        ServiceQuoteFilter.TotalCostFilterOption,
                        [{ label:"$10,000-$25,000", value: QuickFilterStatus.TenToTwentyFiveThousand }],
                        FilterUpdateType.Set
                    )
                 case ServiceQuoteQuickFilterCount.TotalCostOverTentyFiveThousand:
                    return this.tableBloc.createUpdatedFilter(
                        ServiceQuoteFilter.TotalCostFilterOption,
                        [{ label:"Over $25,000", value: QuickFilterStatus.OverTwentyFiveThousand }],
                        FilterUpdateType.Set
                    )
                
                default:
                    return undefined
            }
        })()

        this.tableBloc.applyFiltersAndFetch({ ...this.tableBloc.state.filters, ...updatedFilter })
    }

    updateAssignedToFilter = (value: FilterValue[]) => {
        const updatedFilter = this.tableBloc.createUpdatedFilter(
            ServiceQuoteFilter.AssignedTo,
            value,
            FilterUpdateType.Set
        )
        this.tableBloc.applyFiltersAndFetch({ ...this.tableBloc.state.filters, ...updatedFilter })
    }

    updateSearchFilter = (value: FilterValue[]) => {
        this.filterOverlayMixin.updateFilter(ServiceQuoteFilter.Search, value)
    }

    applySearchFilter = () => {
        const updatedFilter = this.tableBloc.createUpdatedFilter(
            ServiceQuoteFilter.Search,
            this.filterOverlayBloc.state.filters[ServiceQuoteFilter.Search].values,
            FilterUpdateType.Set
        )
        this.tableBloc.applyFiltersAndFetch({ ...this.tableBloc.state.filters, ...updatedFilter })
    }

    isSearchFilterValid = () => this.filterOverlayMixin.isSearchFilterValid()

    showMobileFilterModal = (isOpen: boolean) => this.quickFiltersBloc.showMobileFilterModal(isOpen)
}
