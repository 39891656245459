import { classNames } from "@lib/HtmlUtil"
import React from "react"

export type FormSectionProps = {
    header: string
    subHeader?: string
    children?: React.ReactNode
    className?: string
    headerAlignment?: FormSectionHeaderAlignment
}

export enum FormSectionHeaderAlignment {
    Top = "top",
    Side = "side",
}

export function FormSection({
    headerAlignment = FormSectionHeaderAlignment.Side,
    ...props
}: FormSectionProps): JSX.Element {
    return (
        <div className={classNames("form-section", props.className)} data-header-alignment={headerAlignment}>
            <div className="form-section-header-cont">
                <div className="form-section-header">{props.header}</div>
                {props.subHeader && <div className="form-section-sub-header">{props.subHeader}</div>}
            </div>
            <div className="form-section-content">{props.children}</div>
        </div>
    )
}
