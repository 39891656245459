import { Outcome } from "@ethossoftworks/outcome"
import { boolOrDefault, nullNumOrDefault, numOrDefault, safeParseInt, stringOrDefault } from "@lib/TypeUtil"
import { Asset, AssetDataSource, newAsset } from "@model/assets/Asset"
import { newAssetCategory } from "@model/assets/AssetCategory"
import { newAssetClass } from "@model/assets/AssetClass"
import { newAssetMake } from "@model/assets/AssetMake"
import { newAssetModel } from "@model/assets/AssetModel"
import { AssetPart, labelForAssetPartUnit, newAssetPart } from "@model/assets/AssetPart"
import { MeterSyncRecord } from "@model/assets/MeterSyncRecord"
import { newPriorAssetUsageReading, PriorAssetUsage, PriorAssetUsageReading } from "@model/assets/PriorAssetUsage"
import { Tag, TagChange, tagChangeToTagType } from "@model/Tag"
import { ApiService, dateFromApi, HttpMethod, tagFromApi, tagTypeToApi } from "@service/ApiService"
import { AssetService } from "@service/assets/AssetService"

export class MainAssetService implements AssetService {
    constructor(private apiService: ApiService) {}

    async searchAssets(search: string, onlyActives: boolean = false): Promise<Outcome<Asset[]>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/Asset/Assets",
            body: onlyActives ? { GlobalSearchQuery: search, Active: true } : { GlobalSearchQuery: search },
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)
        return Outcome.ok(response.value.map(assetFromObj))
    }

    async fetchAsset(id: number): Promise<Outcome<Asset>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/Asset/Assets",
            body: { Id: id },
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)
        if (response.value.length !== 1) return Outcome.error("Asset Not Found")

        return Outcome.ok(assetFromObj(response.value[0]))
    }

    async fetchAssetUsage(id: number, date: Date): Promise<Outcome<PriorAssetUsage>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/Asset/AssetUsage/GetPriorAssetUsage",
            body: { AssetId: id, DateTime: date },
        })
        if (!ApiService.isValidObjectResponse(response)) return Outcome.error(response)

        return Outcome.ok(assetUsageFromObj(response.value))
    }

    async createManualEntry(
        assetId: number,
        eventDate: Date,
        odometer: number,
        hourMeter: number,
        siteId: number | null = null
    ): Promise<Outcome<null>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/Asset/AssetUsage/CreateManualEntryAsync",
            body: {
                AssetId: assetId,
                EventDateTime: eventDate,
                Odometer: odometer,
                HourMeter: hourMeter,
                SiteId: siteId,
            },
        })
        if (!ApiService.isValidObjectResponse(response)) return Outcome.error(response)

        return Outcome.ok(null)
    }

    async fetchMeterSyncRecords(assetId: number): Promise<Outcome<MeterSyncRecord[]>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/Asset/MeterSync/GetMeterSyncRecord",
            body: { AssetId: [assetId] },
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        return Outcome.ok(response.value.map(assetMeterSyncRecordFromObj))
    }

    async fetchMeterSyncRecord(assetId: number, workOrderId: number): Promise<Outcome<MeterSyncRecord>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/Asset/MeterSync/GetMeterSyncRecord",
            body: { AssetId: [assetId], WorkOrderId: [workOrderId] },
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)
        if (response.value.length !== 1) return Outcome.error("MeterSyncRecord Not Found")

        return Outcome.ok(assetMeterSyncRecordFromObj(response.value[0]))
    }

    async fetchMeterSyncRecordsAfterDate(assetId: number, datePerformed: Date): Promise<Outcome<MeterSyncRecord[]>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/Asset/MeterSync/GetMeterSyncRecord",
            body: { AssetId: [assetId], DatePerformed: datePerformed },
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)

        return Outcome.ok(response.value.map(assetMeterSyncRecordFromObj))
    }

    async updateTag(assetId: number, change: TagChange, reason: string): Promise<Outcome<Asset>> {
        const tagType = tagChangeToTagType(change)
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Put,
            path: "/Asset/Assets/UpdateRedYellowTag",
            body: { assetId: assetId, reasonTagged: reason, tagStatus: tagTypeToApi(tagType) },
        })
        if (!ApiService.isValidObjectResponse(response)) return Outcome.error(response)
        return Outcome.ok(assetFromObj(response.value))
    }

    async UpdateMeterSyncRequiredFlag(assetId: number, isRequired: boolean, reason: string): Promise<Outcome<Asset>> {
        // const response = await this.apiService.makeRequest({
        //     method: HttpMethod.Put,
        //     path: "/Asset/Assets/UpdateAssetMeterSyncRequiredFlag",
        //     body: { assetId: assetId, meterSyncRequired: isRequired, reasonTagged: reason },
        // })
        // if (!ApiService.isValidObjectResponse(response)) return Outcome.error(response)
        // return Outcome.ok(assetFromObj(response.value))

        return Outcome.ok(assetFromObj({}))
    }

    async fetchParts(assetId: number): Promise<Outcome<AssetPart[]>> {
        const response = await this.apiService.makeRequest({
            method: HttpMethod.Post,
            path: "/Asset/Parts",
            body: {
                assetId: [assetId],
            },
        })
        if (!ApiService.isValidArrayResponse(response)) return Outcome.error(response)
        return Outcome.ok(response.value.map(assetPartFromObj))
    }
}

function assetFromObj(obj: any): Asset {
    return {
        ...newAsset(),
        id: numOrDefault(obj.assetId, 0),
        identifier: stringOrDefault(obj.assetIdentifier, ""),
        district: numOrDefault(obj.districtID, 0),
        subDistrict: numOrDefault(obj.subDistrictID, 0),
        unit: numOrDefault(obj.unitID, 0),
        label: stringOrDefault(obj.assetLabel, ""),
        serialNumber: stringOrDefault(obj.serialNumber, ""),
        company: obj.companyID,
        subCompany: obj.companyID,
        category: {
            ...newAssetCategory(),
            id: numOrDefault(obj.assetCategoryID, 0),
            name: stringOrDefault(obj.assetCategory, ""),
            typeId: numOrDefault(obj.assetTypeID, 0),
        },
        class: {
            ...newAssetClass(),
            id: numOrDefault(obj.assetClassID, 0),
            name: stringOrDefault(obj.assetClass, ""),
        },
        model: {
            ...newAssetModel(),
            id: numOrDefault(obj.assetModelID, 0),
            name: stringOrDefault(obj.assetModel, ""),
        },
        make: {
            ...newAssetMake(),
            id: numOrDefault(obj.assetMakeID, 0),
            name: stringOrDefault(obj.assetMake, ""),
        },
        modelYear: safeParseInt(obj.modelYear),
        group: obj.groupID
            ? {
                  id: numOrDefault(obj.groupID, 0),
                  name: stringOrDefault(obj.group, ""),
              }
            : null,
        daysInactive: numOrDefault(obj.daysInactive, 0),
        location: stringOrDefault(obj.location, ""),
        site: obj.siteID
            ? {
                  id: numOrDefault(obj.siteID, 0),
                  name: stringOrDefault(obj.site, ""),
              }
            : null,
        odometer: nullNumOrDefault(obj.odometer, null),
        hourMeter: nullNumOrDefault(obj.engine1Hours, null),
        lifetimeHourMeter: nullNumOrDefault(obj.lifetimeHourMeter, null),
        lifetimeOdometer: nullNumOrDefault(obj.lifetimeOdometer, null),
        tag: tagFromAssetApi(obj),
        billingCodeId: nullNumOrDefault(obj.billingCodeId, null),
        billingCodeName: stringOrDefault(obj.billingCodeName, ""),
        billingCodeCode: stringOrDefault(obj.billingCodeCode, ""),
        companyName: stringOrDefault(obj.companyName, ""),
        displayOdometer: boolOrDefault(obj.displayOdometer, false),
        displayHourMeter: boolOrDefault(obj.displayHourMeter, false),
        AssetDatasourceID: nullNumOrDefault(obj.fK_AssetDatasourceID, null),
        AssetDataSource: AssetDataSourceFromAssetApi(obj.fK_AssetDatasourceID),
    }
}

function AssetDataSourceFromAssetApi(assetObj: any): AssetDataSource | null {
    if (assetObj === null || assetObj === undefined) return null

    if (assetObj == AssetDataSource.NoDevice) return AssetDataSource.NoDevice
    if (assetObj == AssetDataSource.GaugeDevice) return AssetDataSource.GaugeDevice
    if (assetObj == AssetDataSource.OEMDevice) return AssetDataSource.OEMDevice
    if (assetObj == AssetDataSource.RFID) return AssetDataSource.RFID

    return null
}

function tagFromAssetApi(assetObj: any): Tag | null {
    if (assetObj === null || assetObj === undefined) return null

    return tagFromApi({
        type: assetObj.tagStatusDescription,
        dateTagged: assetObj.dateTagged,
        reasonTagged: assetObj.reasonTagged,
    })
}

function assetUsageFromObj(obj: any): PriorAssetUsage {
    return {
        assetId: numOrDefault(obj.assetId, 0),
        assetUsageId: numOrDefault(obj.assetUsageId, 0),
        eventDateTime: dateFromApi(obj.eventDateTime),
        utcOffset: numOrDefault(obj.utcOffset, 0),
        assetReading: obj.assetReading ? assetReadingFromObj(obj.assetReading) : newPriorAssetUsageReading(),
    }
}

function assetMeterSyncRecordFromObj(obj: any): MeterSyncRecord {
    return {
        meterSyncRecordId: numOrDefault(obj.meterSyncRecordId, 0),
        assetId: numOrDefault(obj.assetId, 0),
        workOrderId: numOrDefault(obj.workOrderId, 0),
        assetUsageId: numOrDefault(obj.assetUsageId, 0),
        userId: numOrDefault(obj.userId, 0),
        datePerformedUTC: dateFromApi(obj.datePerformedUTC),
        meterSyncAssetType: numOrDefault(obj.meterSyncAssetType, 0),
        oldHourMeter: nullNumOrDefault(obj.oldHourMeter, null),
        newHourmeter: nullNumOrDefault(obj.newHourmeter, null),
        oldOdometer: nullNumOrDefault(obj.oldOdometer, null),
        newOdometer: nullNumOrDefault(obj.newOdometer, null),
        meterSyncType: nullNumOrDefault(obj.meterSyncType, null),
    }
}

function assetReadingFromObj(obj: any): PriorAssetUsageReading {
    return {
        assetReadingId: obj.assetReadingId,
        hourMeter: numOrDefault(obj.hourMeter, 0),
        odometer: numOrDefault(obj.odometer, 0),
    }
}

function assetPartFromObj(obj: any): AssetPart {
    return {
        ...newAssetPart(),
        id: numOrDefault(obj.partId, 0),
        quantity: numOrDefault(obj.quantity, 0),
        unit: obj.unit ? labelForAssetPartUnit(numOrDefault(obj.unit, 0)) : "",
        partNumber: stringOrDefault(obj.number, ""),
        description: stringOrDefault(obj.name, ""),
        note: "",
        vendorId: numOrDefault(obj.vendorId, 0),
        vendor: obj.vendor ? obj.vendor.companyName : "",
        companyId: numOrDefault(obj.companyId, 0),
    }
}
